import { message, Layout, Image, Button, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import minLogo from "../../assets/logo/favicon.png";
import { Switch, Route, useHistory } from "react-router-dom";
import AppDrawer from "../../components/shared/Drawers/AppDrawer";
import FileViewer from "../../components/shared/Files/FileViewer";
import Navbar from "../../components/shared/navbar/Navbar";
import {
  getAuthToken,
  removeAuthToken,
} from "../../config/helpers/auth/authToken";
import routes from "../../config/routes/routes";
import authThunks from "../../config/thunks/auth/auth.thunk";
import SideBar from "../../components/shared/SideBar";
import { isEmpty, toUpper } from "lodash";
import PageNotFound from "../App/404";
import { useAppStore } from "../../config/stores";
import usePrevious from "../../components/hooks/usePrevious";
import AddRejections from "../../components/shared/Rejections/AddRejections";

const { Footer, Sider } = Layout;

const MainLayout = () => {
  const { authenticated, authenticateUser } = useSelector(
    (state) => state.authState
  );
  const [collapsed, setCollapsed] = useState(false);
  const history = useHistory();
  const authToken = getAuthToken();

  const dispatch = useDispatch();
  const appStore = useAppStore();
  const { serverSuccess, serverError } = appStore;
  const prevState = usePrevious({ serverSuccess, serverError });

  useEffect(() => {
    if (!isEmpty(prevState)) {
      if (
        !isEmpty(serverSuccess) &&
        prevState.serverSuccess !== serverSuccess
      ) {
        notification.success({
          message:
            serverSuccess?.server?.message ||
            serverSuccess?.message ||
            "Action Successful",
          placement: "top",
        });
      }
      if (!isEmpty(serverError) && prevState.serverError !== serverError) {
        notification.error({
          message: serverError?.server?.message || serverError.error,
          description: serverError?.message,
          placement: "top",
        });
      }
    }
  }, [prevState, serverSuccess, serverError]);

  useEffect(() => {
    if (!navigator.onLine) {
      message.error("There seems to be a connection problem");
    }
    dispatch(authThunks.authenticateUser());
  }, [dispatch]);
  const { path } = routes.authentication.login;
  useEffect(() => {
    if (!authToken) history.push(path);
    else if (!authenticated && authenticateUser.error) {
      message.error(authenticateUser.error);
      removeAuthToken();
      history.push("/login");
    }
  }, [authenticated, authenticateUser.error, authToken, history, path]);

  return (
    <div className="bg-light min-vh-100">
      <Layout
        style={{
          minHeight: "100vh",
        }}
      >
        <AppDrawer />
        <Sider
          collapsible
          collapsed={collapsed}
          theme="light"
          className="d-none bg-white d-md-block border"
          onCollapse={(value) => setCollapsed(value)}
          color="black"
          breakpoint="xs"
          style={{
            overflow: "auto",
            height: "100vh",
            position: "sticky",
            top: 0,
            left: 0,
          }}
        >
          <div className="bg-light text-center m-0">
            <div className="py-2 px-1 bg-white text-sm border-bottom border-right-0 fw-bold">
              {toUpper("AMANI INITIATIVE").trim()}
            </div>
            <div className="text-center px-2 py-4">
              <Image
                src={minLogo}
                fallback={minLogo}
                preview={false}
                height={60}
                draggable={false}
                alt="Logo"
              />
            </div>
          </div>
          <Button block className="rounded-0 fw-bold" type="primary">
            {!collapsed ? "FINANCE" : ""} MIS
          </Button>
          <SideBar />
        </Sider>
        <Layout>
          <AddRejections />
          <Navbar />

          <FileViewer />
          <Switch>
            <Route
              exact
              path={routes.home.dashboard.path}
              component={routes.home.dashboard.component}
            />
            <Route
              path={routes.settings.path}
              component={routes.settings.component}
            />
            <Route
              path={routes.administration.path}
              component={routes.administration.component}
            />
            <Route
              path={routes.businessDevelopmentAndPartnerships.path}
              component={routes.businessDevelopmentAndPartnerships.component}
            />
            <Route
              path={routes.finance.path}
              component={routes.finance.component}
            />
            <Route
              path={routes.procurement.path}
              component={routes.procurement.component}
            />
            <Route
              exact
              path={routes.myProjects.path}
              component={routes.myProjects.component}
            />
            <Route
              exact
              path={routes.myTimeSheets.path}
              component={routes.myTimeSheets.component}
            />
            <Route
              exact
              path={routes.myProjects.myProjectDetails.path}
              component={routes.myProjects.myProjectDetails.component}
            />
            <Route>
              <PageNotFound />
            </Route>
          </Switch>
          <Footer
            style={{
              textAlign: "center",
            }}
          >
            Amani Initiative ©2021 Created by Amani Initiative
          </Footer>
        </Layout>
      </Layout>
    </div>
  );
};

export default MainLayout;
