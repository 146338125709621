export const userConstants = {
  CURRRENT_USER_INFO_REQUEST: "CURRRENT_USER_INFO_REQUEST",
  CURRRENT_USER_INFO_ERROR: "CURRRENT_USER_INFO_ERROR",
  CURRRENT_USER_INFO_SUCCESS: "CURRRENT_USER_INFO_SUCCESS",

  SET_PROFILE_REQUEST: "SET_PROFILE_REQUEST",
  SET_PROFILE_SUCCESS: "SET_PROFILE_SUCCESS",
  SET_PROFILE_ERROR: "SET_PROFILE_ERROR",

  CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_ERROR: "CHANGE_PASSWORD_ERROR",
  CHANGE_PASSWORD_COMPLETED: "CHANGE_PASSWORD_COMPLETED",
};

export const userActions = {
  currentUserInfoRequest: () => ({
    type: userConstants.CURRRENT_USER_INFO_REQUEST,
  }),
  currentUserInfoError: (payload) => ({
    type: userConstants.CURRRENT_USER_INFO_ERROR,
    payload,
  }),
  currentUserInfoSuccess: (payload) => ({
    type: userConstants.CURRRENT_USER_INFO_SUCCESS,
    payload,
  }),
  setProfileRequest: () => ({
    type: userConstants.SET_PROFILE_REQUEST,
  }),
  setProfileSuccess: (payload) => ({
    type: userConstants.SET_PROFILE_SUCCESS,
    payload,
  }),
  setProfileError: (payload) => ({
    type: userConstants.SET_PROFILE_ERROR,
    payload,
  }),
  changePasswordRequest: () => ({
    type: userConstants.CHANGE_PASSWORD_REQUEST,
  }),
  changePasswordSuccess: (payload) => ({
    type: userConstants.CHANGE_PASSWORD_SUCCESS,
    payload,
  }),
  changePasswordError: (payload) => ({
    type: userConstants.CHANGE_PASSWORD_ERROR,
    payload,
  }),
  changePasswordCompleted: () => ({
    type: userConstants.CHANGE_PASSWORD_COMPLETED,
  }),
};
