import { Modal, Form, Button, message, Alert, Input, InputNumber } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { requisitionMemosActions } from "../../../../config/actions/finance/requisitionMemo/requsitionMemo.actions";
import {
  getCurrency,
  numberWithCommas,
} from "../../../../config/helpers/app/numberFormater";

const EditRequsitionMemoActivity = ({
  visible,
  handleEditRecord,
  handleCloseModal,
}) => {
  const {
    editRequisitionMemosActitvitySuccess,
    editRequisitionMemosActitvityLoading,
    editRequisitionMemosActitvityError,
    editRequisitionMemosActitvityMessage,
    editRequisitionMemosActitvityData: data,
  } = useSelector((state) => state.requisitionMemoState);
  const { budgetDetails } = useSelector((state) => state.budgetState);
  const { requisitionMemosDetails } = useSelector(
    (state) => state.requisitionMemoState
  );
  const currency = getCurrency(budgetDetails, "approved_budget");
  const closeModal = () => handleCloseModal(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const onFinish = (values) => {
    values.updated_at = new Date();
    values.id = data.id;
    values.activity = data.activity;
    values.requisition_memo = requisitionMemosDetails.id;
    values.budget_line = requisitionMemosDetails.budget_line;
    values.total_cost = data.total_cost;
    values.old_total_cost = data.total_cost;
    values.activityName = data.activityName;
    dispatch(requisitionMemosActions.editRequisitionMemosAcitvityData(values));
    handleEditRecord(values);
  };
  useEffect(() => {
    if (
      editRequisitionMemosActitvitySuccess &&
      editRequisitionMemosActitvityMessage
    ) {
      message.success(editRequisitionMemosActitvityMessage);
      handleCloseModal(false);
    }
  }, [
    editRequisitionMemosActitvitySuccess,
    editRequisitionMemosActitvityMessage,
    handleCloseModal,
  ]);
  const fields = [
    { name: "units", value: data.units },
    { name: "unit_cost", value: data.unit_cost },
  ];
  return (
    <div>
      <Modal
        title={`EDIT - ${data.activityName} `}
        onCancel={closeModal}
        footer={null}
        open={visible}
      >
        <Form
          fields={fields}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          layout="horizontal"
          form={form}
          onFinish={onFinish}
        >
          {!editRequisitionMemosActitvitySuccess &&
            editRequisitionMemosActitvityError && (
              <Alert
                className="m-2"
                showIcon
                type="error"
                message={editRequisitionMemosActitvityError}
              />
            )}
          <Form.Item
            label="Units"
            name={"units"}
            fieldKey={"units"}
            hasFeedback
            rules={[{ required: true, message: "Please add Units" }]}
          >
            <InputNumber precision={0} className="w-100" placeholder="Units" />
          </Form.Item>
          <Form.Item
            label="Unit Cost"
            name={"unit_cost"}
            fieldKey={"unit_cost"}
            hasFeedback
            rules={[{ required: true, message: "Please add a Unit Cost" }]}
          >
            <InputNumber
              precision={0}
              className="w-100"
              placeholder="Unit Cost"
            />
          </Form.Item>
          <Form.Item label="Total" shouldUpdate>
            {({ getFieldValue }) => {
              const unitCost = getFieldValue("unit_cost");
              const units = getFieldValue("units");
              const total_cost = parseInt(unitCost) * parseInt(units);
              return (
                <Input
                  readOnly
                  value={
                    !isNaN(total_cost)
                      ? `${currency === "D" ? "USD" : "UGX"} ${numberWithCommas(
                          total_cost
                        )}`
                      : 0
                  }
                ></Input>
              );
            }}
          </Form.Item>
          <Form.Item name="details" label="Details">
            <Input.TextArea />
          </Form.Item>
          <Form.Item className={"mt-3"} wrapperCol={{ offset: 8, span: 16 }}>
            <Button
              loading={editRequisitionMemosActitvityLoading}
              htmlType="submit"
              type="primary"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default EditRequsitionMemoActivity;
