import { message, Table } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { numberWithCommas } from "../../../config/helpers/app/numberFormater";

const PendingStoresRegisterIssues = ({ data }) => {
  const {
    success: checkIssueSuccess,
    message: checkIssueMessage,
    error: checkIssueError,
  } = useSelector(
    (state) => state.storesRegisterState.checkStoresRegisterIssue
  );

  const columns = [
    {
      title: "Issued to",
      key: "issued_to_name",
      render: (text) => (
        <div>
          <span className="d-md-none">Issued To:</span> {text.issued_to_name}{" "}
          <br />
          <div className="d-md-none">
            Quantity: {`UGX ${numberWithCommas(parseInt(text.quantity))}`}{" "}
            <br />
            Item: {text.name}
            <br />
            <br />
          </div>
        </div>
      ),
    },
    {
      title: "Items",
      dataIndex: "name",
      key: "name",
      responsive: ["md"],
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      responsive: ["md"],
    },
  ];

  useEffect(() => {
    //check issue
    if (checkIssueSuccess && checkIssueMessage)
      message.success(checkIssueMessage);
    else if (checkIssueError && !checkIssueSuccess)
      message.error(checkIssueError);
  }, [checkIssueError, checkIssueMessage, checkIssueSuccess]);
  return (
    <div className="my-4  mx-2 rounded">
      <Table
        title={() => <h5>Pending Stores Register Issues</h5>}
        size="small"
        columns={columns}
        dataSource={data?.storesRegister?.storesRegisterIssues}
      />
    </div>
  );
};

export default PendingStoresRegisterIssues;
