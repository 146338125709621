import { storesRegisterActions } from "../../../actions/administration/storesRegister/storesRegister.actions";
import { appUiActions } from "../../../actions/app/appUi.actions";
import storesRegisterApiRequests from "../../../api/administration/storesRegister/storesRegister.api";
const errorMessage =
  "Sorry there seems to been an error. Please try again in a few minutes";

const storesRegisterThunks = {
  getStoresRegister: () => async (dispatch) => {
    dispatch(storesRegisterActions.setStoresRegisterRequest());
    try {
      const res = await storesRegisterApiRequests.fetchStoresRegister();
      if (res.success)
        dispatch(storesRegisterActions.setStoresRegisterSuccess(res.data));
      else dispatch(storesRegisterActions.setStoresRegisterError(res.error));
    } catch (error) {
      console.error("Stores register thunk error:", error);
      dispatch(storesRegisterActions.setStoresRegisterError(errorMessage));
    }
  },
  addStoresRegisterReceipt: (body) => async (dispatch) => {
    try {
      dispatch(storesRegisterActions.addStoresRegisterReceiptRequest());
      const res = await storesRegisterApiRequests.addStoresRegisterReceipt(
        body
      );
      if (res.success) {
        dispatch(
          storesRegisterActions.addStoresRegisterReceiptSuccess({
            message: res.message,
            data: res.data,
          })
        );
        dispatch(storesRegisterActions.addStoresRegisterReceiptCompleted());
      } else
        dispatch(
          storesRegisterActions.addStoresRegisterReceiptError(res.error)
        );
    } catch (error) {}
  },
  updateStoresRegisterReceipt:
    (body, storesRegisterArray, issues) => async (dispatch) => {
      issues?.length < 1 && delete body?.issues;
      try {
        dispatch(storesRegisterActions.updateStoresRegisterReceiptRequest());
        const res = await storesRegisterApiRequests.updateStoresRegisterReceipt(
          body,
          body.id
        );
        if (res.success) {
          dispatch(
            storesRegisterActions.updateStoresRegisterReceiptSuccess({
              message: res.message,
              data: [{ ...res.data, issues }, ...storesRegisterArray],
            })
          );
          dispatch(appUiActions.toggleEditStoresRegisterReceiptModal(false));
          dispatch(
            storesRegisterActions.updateStoresRegisterReceiptCompleted()
          );
        } else
          dispatch(
            storesRegisterActions.updateStoresRegisterReceiptError(res.error)
          );
      } catch (error) {
        console.error("Update Stores Register Receipt Error:", error);
        dispatch(
          storesRegisterActions.updateStoresRegisterReceiptError(errorMessage)
        );
      }
    },
  deleteStoresRegisterReceipt:
    (id, storesRegisterArray) => async (dispatch) => {
      try {
        dispatch(storesRegisterActions.deleteStoresRegisterReceiptRequest());
        const res = await storesRegisterApiRequests.deleteStoresRegisterReceipt(
          id
        );
        if (res.success) {
          dispatch(
            storesRegisterActions.deleteStoresRegisterReceiptSuccess({
              message: res.message,
              data: storesRegisterArray,
            })
          );
          dispatch(
            storesRegisterActions.deleteStoresRegisterReceiptCompleted()
          );
        } else
          dispatch(
            storesRegisterActions.deleteStoresRegisterReceiptError(res.error)
          );
      } catch (error) {
        console.error("Delete stores Register Receipt error:", error);
        dispatch(
          storesRegisterActions.deleteStoresRegisterReceiptError(errorMessage)
        );
      }
    },
  storesRegisterReceiptDetails: (id) => async (dispatch) => {
    try {
      dispatch(storesRegisterActions.storesRegisterrReceiptDetailsRequest());
      const res = await storesRegisterApiRequests.storesRegisterReceiptsDetails(
        id
      );
      if (res.success) {
        dispatch(
          storesRegisterActions.storesRegisterrReceiptDetailsSuccess(res.data)
        );
      } else
        dispatch(
          storesRegisterActions.storesRegisterrReceiptDetailsError(res.error)
        );
    } catch (error) {
      console.error(error);
      dispatch(
        storesRegisterActions.storesRegisterrReceiptDetailsError(errorMessage)
      );
    }
  },
  addStoresRegisterIssuess: (body, storesRegisterArray) => async (dispatch) => {
    try {
      dispatch(storesRegisterActions.addStoresRegisterIssueRequest());
      const res = await storesRegisterApiRequests.addStoresRegisterIssue(body);
      if (res.success) {
        dispatch(
          storesRegisterActions.addStoresRegisterIssueSuccess({
            message: res.message,
          })
        );
        dispatch(storesRegisterThunks.getStoresRegister());
        dispatch(storesRegisterActions.addStoresRegisterIssueCompleted());
      } else
        dispatch(storesRegisterActions.addStoresRegisterIssueError(res.error));
    } catch (error) {
      console.error(error);
      dispatch(storesRegisterActions.addStoresRegisterIssueError(errorMessage));
    }
  },
  deleteStoresRegisterIssues: (id, storesRegisterArray) => async (dispatch) => {
    try {
      dispatch(storesRegisterActions.deleteStoresRegisterIssueRequest());
      const res = await storesRegisterApiRequests.deleteStoresRegisterIssues(
        id
      );
      if (res.success) {
        dispatch(
          storesRegisterActions.deleteStoresRegisterIssueSuccess({
            message: res.message,
          })
        );
        dispatch(storesRegisterThunks.getStoresRegister());
      } else
        dispatch(
          storesRegisterActions.deleteStoresRegisterIssueError(res.error)
        );
    } catch (error) {
      console.error(error);
      dispatch(
        storesRegisterActions.deleteStoresRegisterIssueError(errorMessage)
      );
    }
    dispatch(storesRegisterActions.deleteStoresRegisterIssueCompleted());
  },
  updateStoresRegisterIssues: (body) => async (dispatch) => {
    try {
      dispatch(storesRegisterActions.updateStoresRegisterIssueRequest());
      const res = await storesRegisterApiRequests.updateStoresRegisterIssue(
        body,
        body.id
      );
      if (res.success) {
        dispatch(
          storesRegisterActions.updateStoresRegisterIssueSuccess({
            message: res.message,
          })
        );
        dispatch(storesRegisterThunks.getStoresRegister());
        dispatch(appUiActions.toggleEditStoresRegisterIssuesModal(false));
        dispatch(storesRegisterActions.updateStoresRegisterIssueCompleted());
      } else
        dispatch(
          storesRegisterActions.updateStoresRegisterIssueError(res.error)
        );
    } catch (error) {
      console.error("Update Stores Register Issues thunk error:", error);
      dispatch(
        storesRegisterActions.updateStoresRegisterIssueError(errorMessage)
      );
    }
  },
  storesRegisterIssuesDetails: (id) => async (dispatch) => {
    try {
      dispatch(storesRegisterActions.storesRegisterIssuesDetailsRequest());
      const res = await storesRegisterApiRequests.storesRegisterIssuesDetails(
        id
      );
      if (res.success) {
        dispatch(
          storesRegisterActions.storesRegisterIssuesDetailsSuccess(res.data)
        );
      } else
        dispatch(
          storesRegisterActions.storesRegisterIssuesDetailsError(res.error)
        );
    } catch (error) {
      console.error(error);
      dispatch(
        storesRegisterActions.storesRegisterIssuesDetailsError(errorMessage)
      );
    }
  },
  approveStoresRegisterReceipt: (id) => async (dispatch) => {
    try {
      dispatch(storesRegisterActions.approveStoresRegisterReceiptRequest());
      const res = await storesRegisterApiRequests.approveStoresRegisterReceipt(
        id
      );
      if (res.success) {
        dispatch(
          storesRegisterActions.approveStoresRegisterReceiptSuccess({
            message: res.message,
          })
        );
      } else
        dispatch(
          storesRegisterActions.approveStoresRegisterReceiptError(res.error)
        );
    } catch (error) {
      console.error("Approve stores register thunk error:", error);
      dispatch(
        storesRegisterActions.approveStoresRegisterReceiptError(errorMessage)
      );
    }
    dispatch(storesRegisterActions.approveStoresRegisterReceiptCompleted());
  },
  approveStoresRegisterIssues: (id) => async (dispatch) => {
    try {
      dispatch(storesRegisterActions.approveStoresRegisterIssuesRequest());
      const res = await storesRegisterApiRequests.approveStoresRegisterIssue(
        id
      );
      if (res.success) {
        dispatch(
          storesRegisterActions.approveStoresRegisterIssuesSuccess(res.message)
        );
        dispatch(storesRegisterThunks.storesRegisterIssuesDetails(id));
      } else {
        dispatch(
          storesRegisterActions.approveStoresRegisterIssuesError(res.error)
        );
      }
    } catch (error) {
      console.error("Approve stores register thunk error:", error);
      dispatch(
        storesRegisterActions.approveStoresRegisterIssuesError(errorMessage)
      );
    }
    dispatch(storesRegisterActions.approveStoresRegisterIssuesCompleted());
  },
  checkStoresRegisterReceipt: (id) => async (dispatch) => {
    try {
      dispatch(storesRegisterActions.checkStoresRegisterReceiptRequest());
      const res = await storesRegisterApiRequests.checkStoresRegisterReceipt(
        id
      );
      if (res.success) {
        dispatch(
          storesRegisterActions.checkStoresRegisterReceiptSuccess({
            message: res.message,
          })
        );
      } else
        dispatch(
          storesRegisterActions.checkStoresRegisterReceiptError(res.error)
        );
    } catch (error) {
      console.error("Approve stores register thunk error:", error);
      dispatch(
        storesRegisterActions.checkStoresRegisterReceiptError(errorMessage)
      );
    }
    dispatch(storesRegisterActions.checkStoresRegisterReceiptCompleted());
  },
  checkStoresRegisterIssue: (id) => async (dispatch) => {
    try {
      dispatch(storesRegisterActions.checkStoresRegisterIssuesRequest());
      const res = await storesRegisterApiRequests.checkStoresRegisterIssue(id);
      if (res.success) {
        dispatch(
          storesRegisterActions.checkStoresRegisterIssuesSuccess(res.message)
        );
        dispatch(storesRegisterThunks.storesRegisterIssuesDetails(id));
      } else
        dispatch(
          storesRegisterActions.checkStoresRegisterIssuesError(res.error)
        );
    } catch (error) {
      console.error("Approve stores register thunk error:", error);
      dispatch(
        storesRegisterActions.checkStoresRegisterIssuesError(errorMessage)
      );
    }
    dispatch(storesRegisterActions.checkStoresRegisterIssuesCompleted());
  },
};

export default storesRegisterThunks;
