export const advanceAccountabilityFormConstants = {
  SET_ADVANCE_ACCOUNTABILITY_FORMS_LOADING:
    "SET_ADVANCE_ACCOUNTABILITY_FORMS_LOADING",
  SET_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS:
    "SET_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS",
  SET_ADVANCE_ACCOUNTABILITY_FORMS_ERROR:
    "SET_ADVANCE_ACCOUNTABILITY_FORMS_ERROR",

  SET_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_LOADING:
    "SET_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_LOADING",
  SET_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_SUCCESS:
    "SET_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_SUCCESS",
  SET_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_ERROR:
    "SET_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_ERROR",

  SET_PROJECT_ADVANCE_ACCOUNTABILITY_FORMS_LOADING:
    "SET_PROJECT_ADVANCE_ACCOUNTABILITY_FORMS_LOADING",
  SET_PROJECT_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS:
    "SET_PROJECT_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS",
  SET_PROJECT_ADVANCE_ACCOUNTABILITY_FORMS_ERROR:
    "SET_PROJECT_ADVANCE_ACCOUNTABILITY_FORMS_ERROR",

  SET_ADVANCE_ACCOUNTABILITY_FORMS_DETAILS_LOADING:
    "SET_ADVANCE_ACCOUNTABILITY_FORMS_DETAILS_LOADING",
  SET_ADVANCE_ACCOUNTABILITY_FORMS_DETAILS_SUCCESS:
    "SET_ADVANCE_ACCOUNTABILITY_FORMS_DETAILS_SUCCESS",
  SET_ADVANCE_ACCOUNTABILITY_FORMS_DETAILS_ERROR:
    "SET_ADVANCE_ACCOUNTABILITY_FORMS_DETAILS_ERROR",

  SET_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_DETAILS_LOADING:
    "SET_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_DETAILS_LOADING",
  SET_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_DETAILS_SUCCESS:
    "SET_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_DETAILS_SUCCESS",
  SET_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_DETAILS_ERROR:
    "SET_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_DETAILS_ERROR",

  ADD_ADVANCE_ACCOUNTABILITY_FORMS_LOADING:
    "ADD_ADVANCE_ACCOUNTABILITY_FORMS_LOADING",
  ADD_ADVANCE_ACCOUNTABILITY_FORMS_ERROR:
    "ADD_ADVANCE_ACCOUNTABILITY_FORMS_ERROR",
  ADD_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS:
    "ADD_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS",
  ADD_ADVANCE_ACCOUNTABILITY_FORMS_COMPLETE:
    "ADD_ADVANCE_ACCOUNTABILITY_FORMS_COMPLETE",

  ADD_ADVANCE_ACCOUNTABILITY_FORMS_DOCUMENT_LOADING:
    "ADD_ADVANCE_ACCOUNTABILITY_FORMS_DOCUMENT_LOADING",
  ADD_ADVANCE_ACCOUNTABILITY_FORMS_DOCUMENT_ERROR:
    "ADD_ADVANCE_ACCOUNTABILITY_FORMS_DOCUMENT_ERROR",
  ADD_ADVANCE_ACCOUNTABILITY_FORMS_DOCUMENT_SUCCESS:
    "ADD_ADVANCE_ACCOUNTABILITY_FORMS_DOCUMENT_SUCCESS",
  ADD_ADVANCE_ACCOUNTABILITY_FORMS_DOCUMENT_COMPLETE:
    "ADD_ADVANCE_ACCOUNTABILITY_FORMS_DOCUMENT_COMPLETE",

  ADD_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_LOADING:
    "ADD_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_LOADING",
  ADD_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_ERROR:
    "ADD_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_ERROR",
  ADD_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_SUCCESS:
    "ADD_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_SUCCESS",
  ADD_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_COMPLETE:
    "ADD_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_COMPLETE",

  EDIT_ADVANCE_ACCOUNTABILITY_FORMS_LOADING:
    "EDIT_ADVANCE_ACCOUNTABILITY_FORMS_LOADING",
  EDIT_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS:
    "EDIT_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS",
  EDIT_ADVANCE_ACCOUNTABILITY_FORMS_ERROR:
    "EDIT_ADVANCE_ACCOUNTABILITY_FORMS_ERROR",
  EDIT_ADVANCE_ACCOUNTABILITY_FORMS_COMPLETE:
    "EDIT_ADVANCE_ACCOUNTABILITY_FORMS_COMPLETE",
  EDIT_ADVANCE_ACCOUNTABILITY_FORMS_DATA:
    "EDIT_ADVANCE_ACCOUNTABILITY_FORMS_DATA",

  EDIT_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_LOADING:
    "EDIT_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_LOADING",
  EDIT_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_SUCCESS:
    "EDIT_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_SUCCESS",
  EDIT_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_ERROR:
    "EDIT_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_ERROR",
  EDIT_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_COMPLETE:
    "EDIT_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_COMPLETE",
  EDIT_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_DATA:
    "EDIT_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_DATA",

  DELETE_ADVANCE_ACCOUNTABILITY_FORMS_LOADING:
    "DELETE_ADVANCE_ACCOUNTABILITY_FORMS_LOADING",
  DELETE_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS:
    "DELETE_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS",
  DELETE_ADVANCE_ACCOUNTABILITY_FORMS_ERROR:
    "DELETE_ADVANCE_ACCOUNTABILITY_FORMS_ERROR",
  DELETE_ADVANCE_ACCOUNTABILITY_FORMS_COMPLETE:
    "DELETE_ADVANCE_ACCOUNTABILITY_FORMS_COMPLETE",

  DELETE_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_LOADING:
    "DELETE_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_LOADING",
  DELETE_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_SUCCESS:
    "DELETE_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_SUCCESS",
  DELETE_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_ERROR:
    "DELETE_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_ERROR",
  DELETE_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_COMPLETE:
    "DELETE_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_COMPLETE",

  DELETE_ADVANCE_ACCOUNTABILITY_FORMS_DOCUMENT_LOADING:
    "DELETE_ADVANCE_ACCOUNTABILITY_FORMS_DOCUMENT_LOADING",
  DELETE_ADVANCE_ACCOUNTABILITY_FORMS_DOCUMENT_SUCCESS:
    "DELETE_ADVANCE_ACCOUNTABILITY_FORMS_DOCUMENT_SUCCESS",
  DELETE_ADVANCE_ACCOUNTABILITY_FORMS_DOCUMENT_ERROR:
    "DELETE_ADVANCE_ACCOUNTABILITY_FORMS_DOCUMENT_ERROR",
  DELETE_ADVANCE_ACCOUNTABILITY_FORMS_DOCUMENT_COMPLETE:
    "DELETE_ADVANCE_ACCOUNTABILITY_FORMS_DOCUMENT_COMPLETE",

  APPROVE_ADVANCE_ACCOUNTABILITY_FORMS_LOADING:
    "APPROVE_ADVANCE_ACCOUNTABILITY_FORMS_LOADING",
  APPROVE_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS:
    "APPROVE_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS",
  APPROVE_ADVANCE_ACCOUNTABILITY_FORMS_ERROR:
    "APPROVE_ADVANCE_ACCOUNTABILITY_FORMS_ERROR",
  APPROVE_ADVANCE_ACCOUNTABILITY_FORMS_COMPLETE:
    "APPROVE_ADVANCE_ACCOUNTABILITY_FORMS_COMPLETE",

  CHECK_ADVANCE_ACCOUNTABILITY_FORMS_LOADING:
    "CHECK_ADVANCE_ACCOUNTABILITY_FORMS_LOADING",
  CHECK_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS:
    "CHECK_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS",
  CHECK_ADVANCE_ACCOUNTABILITY_FORMS_ERROR:
    "CHECK_ADVANCE_ACCOUNTABILITY_FORMS_ERROR",
  CHECK_ADVANCE_ACCOUNTABILITY_FORMS_COMPLETE:
    "CHECK_ADVANCE_ACCOUNTABILITY_FORMS_COMPLETE",

  SUBMIT_ADVANCE_ACCOUNTABILITY_FORMS_LOADING:
    "SUBMIT_ADVANCE_ACCOUNTABILITY_FORMS_LOADING",
  SUBMIT_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS:
    "SUBMIT_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS",
  SUBMIT_ADVANCE_ACCOUNTABILITY_FORMS_ERROR:
    "SUBMIT_ADVANCE_ACCOUNTABILITY_FORMS_ERROR",
  SUBMIT_ADVANCE_ACCOUNTABILITY_FORMS_COMPLETE:
    "SUBMIT_ADVANCE_ACCOUNTABILITY_FORMS_COMPLETE",

  META_DATA: "META_DATA",
  META_DATA_LOADING: "META_DATA_LOADING",
};

export const advanceAccountabilityFormActions = {
  setAdvanceAccountabilityFormsLoading: () => ({
    type: advanceAccountabilityFormConstants.SET_ADVANCE_ACCOUNTABILITY_FORMS_LOADING,
  }),
  setAdvanceAccountabilityFormsSucess: (payload) => ({
    type: advanceAccountabilityFormConstants.SET_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS,
    payload,
  }),
  setAdvanceAccountabilityFormsError: (payload) => ({
    type: advanceAccountabilityFormConstants.SET_ADVANCE_ACCOUNTABILITY_FORMS_ERROR,
    payload,
  }),
  setProjectAdvanceAccountabilityFormsLoading: () => ({
    type: advanceAccountabilityFormConstants.SET_PROJECT_ADVANCE_ACCOUNTABILITY_FORMS_LOADING,
  }),
  setProjectAdvanceAccountabilityFormsSucess: (payload) => ({
    type: advanceAccountabilityFormConstants.SET_PROJECT_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS,
    payload,
  }),
  setProjectAdvanceAccountabilityFormsError: (payload) => ({
    type: advanceAccountabilityFormConstants.SET_PROJECT_ADVANCE_ACCOUNTABILITY_FORMS_ERROR,
    payload,
  }),
  setAdvanceAccountabilityFormsExpenditureLoading: () => ({
    type: advanceAccountabilityFormConstants.SET_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_LOADING,
  }),
  setAdvanceAccountabilityFormsExpenditureSucess: (payload) => ({
    type: advanceAccountabilityFormConstants.SET_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_SUCCESS,
    payload,
  }),
  setAdvanceAccountabilityFormsExpenditureError: (payload) => ({
    type: advanceAccountabilityFormConstants.SET_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_ERROR,
    payload,
  }),
  setAdvanceAccountabilityFormsDetailsLoading: () => ({
    type: advanceAccountabilityFormConstants.SET_ADVANCE_ACCOUNTABILITY_FORMS_DETAILS_LOADING,
  }),
  setAdvanceAccountabilityFormsDetailsSucess: (payload) => ({
    type: advanceAccountabilityFormConstants.SET_ADVANCE_ACCOUNTABILITY_FORMS_DETAILS_SUCCESS,
    payload,
  }),
  setAdvanceAccountabilityFormsDetailsError: (payload) => ({
    type: advanceAccountabilityFormConstants.SET_ADVANCE_ACCOUNTABILITY_FORMS_DETAILS_ERROR,
    payload,
  }),
  setAdvanceAccountabilityFormsExpenditureDetailsLoading: () => ({
    type: advanceAccountabilityFormConstants.SET_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_DETAILS_LOADING,
  }),
  setAdvanceAccountabilityFormsExpenditureDetailsSucess: (payload) => ({
    type: advanceAccountabilityFormConstants.SET_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_DETAILS_SUCCESS,
    payload,
  }),
  setAdvanceAccountabilityFormsExpenditureDetailsError: (payload) => ({
    type: advanceAccountabilityFormConstants.SET_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_DETAILS_ERROR,
    payload,
  }),
  addAdvanceAccountabilityFormsLoading: () => ({
    type: advanceAccountabilityFormConstants.ADD_ADVANCE_ACCOUNTABILITY_FORMS_LOADING,
  }),
  addAdvanceAccountabilityFormsSuccess: (payload) => ({
    type: advanceAccountabilityFormConstants.ADD_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS,
    payload,
  }),
  addAdvanceAccountabilityFormsError: (payload) => ({
    type: advanceAccountabilityFormConstants.ADD_ADVANCE_ACCOUNTABILITY_FORMS_ERROR,
    payload,
  }),
  addAdvanceAccountabilityFormsComplete: () => ({
    type: advanceAccountabilityFormConstants.ADD_ADVANCE_ACCOUNTABILITY_FORMS_COMPLETE,
  }),
  addAdvanceAccountabilityFormsExpenditureLoading: () => ({
    type: advanceAccountabilityFormConstants.ADD_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_LOADING,
  }),
  addAdvanceAccountabilityFormsExpenditureSuccess: (payload) => ({
    type: advanceAccountabilityFormConstants.ADD_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_SUCCESS,
    payload,
  }),
  addAdvanceAccountabilityFormsExpenditureError: (payload) => ({
    type: advanceAccountabilityFormConstants.ADD_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_ERROR,
    payload,
  }),
  addAdvanceAccountabilityFormsExpenditureComplete: () => ({
    type: advanceAccountabilityFormConstants.ADD_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_COMPLETE,
  }),
  addAdvanceAccountabilityFormsDocumentLoading: () => ({
    type: advanceAccountabilityFormConstants.ADD_ADVANCE_ACCOUNTABILITY_FORMS_DOCUMENT_LOADING,
  }),
  addAdvanceAccountabilityFormsDocumentSuccess: (payload) => ({
    type: advanceAccountabilityFormConstants.ADD_ADVANCE_ACCOUNTABILITY_FORMS_DOCUMENT_SUCCESS,
    payload,
  }),
  addAdvanceAccountabilityFormsDocumentError: (payload) => ({
    type: advanceAccountabilityFormConstants.ADD_ADVANCE_ACCOUNTABILITY_FORMS_DOCUMENT_ERROR,
    payload,
  }),
  addAdvanceAccountabilityFormsDocumentComplete: () => ({
    type: advanceAccountabilityFormConstants.ADD_ADVANCE_ACCOUNTABILITY_FORMS_DOCUMENT_COMPLETE,
  }),
  editAdvanceAccountabilityFormsLoading: () => ({
    type: advanceAccountabilityFormConstants.EDIT_ADVANCE_ACCOUNTABILITY_FORMS_LOADING,
  }),
  editAdvanceAccountabilityFormsSuccess: (payload) => ({
    type: advanceAccountabilityFormConstants.EDIT_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS,
    payload,
  }),
  editAdvanceAccountabilityFormsError: (payload) => ({
    type: advanceAccountabilityFormConstants.EDIT_ADVANCE_ACCOUNTABILITY_FORMS_ERROR,
    payload,
  }),
  editAdvanceAccountabilityFormsComplete: () => ({
    type: advanceAccountabilityFormConstants.EDIT_ADVANCE_ACCOUNTABILITY_FORMS_COMPLETE,
  }),
  editAdvanceAccountabilityFormsData: (payload) => ({
    type: advanceAccountabilityFormConstants.EDIT_ADVANCE_ACCOUNTABILITY_FORMS_DATA,
    payload,
  }),

  editAdvanceAccountabilityFormsExpenditureLoading: () => ({
    type: advanceAccountabilityFormConstants.EDIT_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_LOADING,
  }),
  editAdvanceAccountabilityFormsExpenditureSuccess: (payload) => ({
    type: advanceAccountabilityFormConstants.EDIT_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_SUCCESS,
    payload,
  }),
  editAdvanceAccountabilityFormsExpenditureError: (payload) => ({
    type: advanceAccountabilityFormConstants.EDIT_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_ERROR,
    payload,
  }),
  editAdvanceAccountabilityFormsExpenditureComplete: () => ({
    type: advanceAccountabilityFormConstants.EDIT_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_COMPLETE,
  }),
  editAdvanceAccountabilityFormsExpenditureData: (payload) => ({
    type: advanceAccountabilityFormConstants.EDIT_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_DATA,
    payload,
  }),
  deleteAdvanceAccountabilityFormsLoading: () => ({
    type: advanceAccountabilityFormConstants.DELETE_ADVANCE_ACCOUNTABILITY_FORMS_LOADING,
  }),
  deleteAdvanceAccountabilityFormsSuccess: (payload) => ({
    type: advanceAccountabilityFormConstants.DELETE_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS,
    payload,
  }),
  deleteAdvanceAccountabilityFormsError: (payload) => ({
    type: advanceAccountabilityFormConstants.DELETE_ADVANCE_ACCOUNTABILITY_FORMS_ERROR,
    payload,
  }),
  deleteAdvanceAccountabilityFormsComplete: () => ({
    type: advanceAccountabilityFormConstants.DELETE_ADVANCE_ACCOUNTABILITY_FORMS_COMPLETE,
  }),
  deleteAdvanceAccountabilityFormsExpenditureLoading: () => ({
    type: advanceAccountabilityFormConstants.DELETE_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_LOADING,
  }),
  deleteAdvanceAccountabilityFormsExpenditureSuccess: (payload) => ({
    type: advanceAccountabilityFormConstants.DELETE_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_SUCCESS,
    payload,
  }),
  deleteAdvanceAccountabilityFormsExpenditureError: (payload) => ({
    type: advanceAccountabilityFormConstants.DELETE_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_ERROR,
    payload,
  }),
  deleteAdvanceAccountabilityFormsExpenditureComplete: () => ({
    type: advanceAccountabilityFormConstants.DELETE_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_COMPLETE,
  }),
  deleteAdvanceAccountabilityFormsDocumentLoading: () => ({
    type: advanceAccountabilityFormConstants.DELETE_ADVANCE_ACCOUNTABILITY_FORMS_DOCUMENT_LOADING,
  }),
  deleteAdvanceAccountabilityFormsDocumentSuccess: (payload) => ({
    type: advanceAccountabilityFormConstants.DELETE_ADVANCE_ACCOUNTABILITY_FORMS_DOCUMENT_SUCCESS,
    payload,
  }),
  deleteAdvanceAccountabilityFormsDocumentError: (payload) => ({
    type: advanceAccountabilityFormConstants.DELETE_ADVANCE_ACCOUNTABILITY_FORMS_DOCUMENT_ERROR,
    payload,
  }),
  deleteAdvanceAccountabilityFormsDocumentComplete: () => ({
    type: advanceAccountabilityFormConstants.DELETE_ADVANCE_ACCOUNTABILITY_FORMS_DOCUMENT_COMPLETE,
  }),
  approveAdvanceAccountabilityFormsLoading: () => ({
    type: advanceAccountabilityFormConstants.APPROVE_ADVANCE_ACCOUNTABILITY_FORMS_LOADING,
  }),
  approveAdvanceAccountabilityFormsSuccess: (payload) => ({
    type: advanceAccountabilityFormConstants.APPROVE_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS,
    payload,
  }),
  approveAdvanceAccountabilityFormsError: (payload) => ({
    type: advanceAccountabilityFormConstants.APPROVE_ADVANCE_ACCOUNTABILITY_FORMS_ERROR,
    payload,
  }),
  approveAdvanceAccountabilityFormsComplete: (payload) => ({
    type: advanceAccountabilityFormConstants.APPROVE_ADVANCE_ACCOUNTABILITY_FORMS_COMPLETE,
    payload,
  }),
  checkAdvanceAccountabilityFormsLoading: () => ({
    type: advanceAccountabilityFormConstants.CHECK_ADVANCE_ACCOUNTABILITY_FORMS_LOADING,
  }),
  checkAdvanceAccountabilityFormsSuccess: (payload) => ({
    type: advanceAccountabilityFormConstants.CHECK_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS,
    payload,
  }),
  checkAdvanceAccountabilityFormsError: (payload) => ({
    type: advanceAccountabilityFormConstants.CHECK_ADVANCE_ACCOUNTABILITY_FORMS_ERROR,
    payload,
  }),
  checkAdvanceAccountabilityFormsComplete: (payload) => ({
    type: advanceAccountabilityFormConstants.CHECK_ADVANCE_ACCOUNTABILITY_FORMS_COMPLETE,
    payload,
  }),
  submitAdvanceAccountabilityFormsLoading: () => ({
    type: advanceAccountabilityFormConstants.SUBMIT_ADVANCE_ACCOUNTABILITY_FORMS_LOADING,
  }),
  submitAdvanceAccountabilityFormsSuccess: (payload) => ({
    type: advanceAccountabilityFormConstants.SUBMIT_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS,
    payload,
  }),
  submitAdvanceAccountabilityFormsError: (payload) => ({
    type: advanceAccountabilityFormConstants.SUBMIT_ADVANCE_ACCOUNTABILITY_FORMS_ERROR,
    payload,
  }),
  submitAdvanceAccountabilityFormsComplete: (payload) => ({
    type: advanceAccountabilityFormConstants.SUBMIT_ADVANCE_ACCOUNTABILITY_FORMS_COMPLETE,
    payload,
  }),
  metaData: (payload) => ({
    type: advanceAccountabilityFormConstants.META_DATA,
    payload,
  }),
  metaDataLoading: () => ({
    type: advanceAccountabilityFormConstants.META_DATA_LOADING,
  }),
};
