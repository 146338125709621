import { appUiActions } from "../../actions/app/appUi.actions";
import { userActions } from "../../actions/users/users.actions";
import {
  changePasswordRequest,
  currentUserInfoRequest,
  fetchUserProfile,
} from "../../api/users/users.api";

const errorMessage =
  "Sorry there seems to be an error please try again in a few minutes";
const userThunks = {
  currentUserInfo: () => async (dispatch) => {
    const res = await currentUserInfoRequest();
    try {
      if (res.success)
        console.info(
          "Action",
          dispatch(userActions.currentUserInfoSuccess(res.data))
        );
      else
        console.info(
          "Action",
          dispatch(
            userActions.currentUserInfoError({ error: res.error, data: {} })
          )
        );
    } catch (error) {
      console.error(error);
      console.info(
        "Action",
        dispatch(
          userActions.currentUserInfoError({
            error: errorMessage,
            data: {},
          })
        )
      );
    }
  },
  getUserProfile: () => async (dispatch) => {
    try {
      dispatch(userActions.setProfileRequest());
      const res = await fetchUserProfile();

      if (res.success) dispatch(userActions.setProfileSuccess(res.data));
      else dispatch(userActions.setProfileError(res.error));
    } catch (error) {
      console.error("User profile thunk error:", error);
      dispatch(userActions.setProfileError());
    }
  },
  changePassword: (body) => async (dispatch) => {
    try {
      dispatch(userActions.changePasswordRequest());
      const res = await changePasswordRequest(body);
      if (res.success) {
        dispatch(userActions.changePasswordSuccess(res.data));
        dispatch(appUiActions.toggleChangePasswordModal(false));
        dispatch(userActions.changePasswordCompleted());
      } else dispatch(userActions.changePasswordError(res.error));
    } catch (error) {
      console.error(error);
      dispatch(userActions.changePasswordError(errorMessage));
    }
  },
};

export default userThunks;
