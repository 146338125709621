const initialState = {
  staffRecords: {
    loading: false,
    success: false,
    data: [],
    error: "",
  },
  addStaffRecords: {
    loading: false,
    success: false,
    error: "",
    message: "",
  },
  updateStaffRecords: {
    loading: false,
    success: false,
    error: "",
    message: "",
    data: {},
  },
  deleteStaffRecords: {
    loading: false,
    success: false,
    error: "",
    message: "",
  },
  staffAccountStatus: {
    loading: false,
    success: false,
    error: "",
    message: "",
  },
  staffDetails: {
    loading: false,
    success: false,
    error: "",
    data: {
      first_name: "",
      other_names: "",
      gender: "",
      email: "",
      img_url: "",
      role: "",
      contact: "",
      department: "",
    },
  },
  updateProfilePicture: {
    loading: false,
    success: false,
    error: "",
    message: "",
  },
  generateNewConfirmToken: {
    loading: false,
    success: false,
    error: "",
    message: "",
  },
  uploadStaffSignatureLoading: false,
  uploadStaffSignatureSuccess: false,
  uploadStaffSignatureError: "",
  uploadStaffSignatureMessage: "",
};

export default initialState;
