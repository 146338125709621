/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { Button, message, Popconfirm, Table } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import appConfig from "../../../../config/config.json";

const AdvanceAccountabilityFormDocumentsTable = ({ handleDelete, data }) => {
  const {
    deleteAdvanceAccountabilityFormsDocumentLoading,
    deleteAdvanceAccountabilityFormsDocumentSuccess,
    deleteAdvanceAccountabilityFormsDocumentError,
    deleteAdvanceAccountabilityFormsDocumentMessage,
  } = useSelector((state) => state.advanceAccountabilityFormsState);
  const columns = [
    {
      title: "Document",
      key: "name",
      render: (text) => {
        return (
          <a target="_blank" href={`${appConfig.server_url}${text.file_path}`}>
            {text.name}
          </a>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (text) => {
        return (
          <Popconfirm
            onConfirm={() =>
              handleDelete(text.id, text.advance_accountability_form)
            }
            title="Are you sure ?"
          >
            <Button
              loading={deleteAdvanceAccountabilityFormsDocumentLoading}
              danger
            >
              Delete
            </Button>
          </Popconfirm>
        );
      },
    },
  ];

  useEffect(() => {
    if (
      deleteAdvanceAccountabilityFormsDocumentSuccess &&
      deleteAdvanceAccountabilityFormsDocumentMessage
    )
      message.success(deleteAdvanceAccountabilityFormsDocumentMessage);
    else if (
      !deleteAdvanceAccountabilityFormsDocumentSuccess &&
      deleteAdvanceAccountabilityFormsDocumentError
    )
      message.error(deleteAdvanceAccountabilityFormsDocumentError);
  }, [
    deleteAdvanceAccountabilityFormsDocumentSuccess,
    deleteAdvanceAccountabilityFormsDocumentMessage,
    deleteAdvanceAccountabilityFormsDocumentError,
  ]);
  return (
    <div>
      <Table columns={columns} dataSource={data} rowKey={(r) => r.id} />
    </div>
  );
};

export default AdvanceAccountabilityFormDocumentsTable;
