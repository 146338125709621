import { Alert, Button, message, Space, Spin, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BudgetActivityDetails from "../../../components/Finance/Budget/Details/BudgetActivityDetails";
import BudgetLinesDetails from "../../../components/Finance/Budget/Details/BudgetLinesDetailsModal";
import OtherBudgetDetails from "../../../components/Finance/Budget/Details/OtherBudgetDetails";
import AddBudgetActivity from "../../../components/Finance/Budget/Forms/AddBudgetActivity";
import AddBudgetLine from "../../../components/Finance/Budget/Forms/AddBudgetLine";
import EditBudgetActivity from "../../../components/Finance/Budget/Forms/EditBudgetActivity";
import EditBudgetLine from "../../../components/Finance/Budget/Forms/EditBudgetLine";
import ApprovedBudget from "../../../components/Finance/Budget/Tables/ApprovedBudget";
import { getIdFromPath } from "../../../config/helpers/app/url";
import budgetThunks from "../../../config/thunks/finance/budgets/budgets.thunks";
import appConfig from "../../../config/config.json";
import logDetailThunks from "../../../config/thunks/settings/logDetails.thunks";
import BudgetMonitoringStatement from "../../../components/Finance/Budget/Tables/BudgetMonitoringStatement";
import AddRejections from "../../../components/shared/Rejections/AddRejections";
import { rejectionsActions } from "../../../config/actions/rejections/rejections.actions";
import BudgetLinesGraph from "../../../components/Finance/Budget/Details/BudgetLinesGraph";
import { FcRefresh } from "react-icons/fc";
import { AiOutlineArrowLeft } from "react-icons/ai";

const { TabPane } = Tabs;

const BudgetDetails = () => {
  const {
    approvedBudgetSuccess,
    approvedBudgetError,
    budgetDetailsLoading,
    budgetDetailsSuccess,
    budgetDetailsError,
    budgetDetails,
    submitBudgetLoading,
    submitBudgetSuccess,
    submitBudgetError,
    submitBudgetMessage,
    checkBudgetLoading,
    checkBudgetSuccess,
    checkBudgetError,
    checkBudgetMessage,
    approveBudgetLoading,
    approveBudgetSuccess,
    approveBudgetError,
    approveBudgetMessage,
  } = useSelector((state) => state.budgetState);
  const [budgetLineDetailsModalVisible, setBudgetLineDetailsModalVisible] =
    useState(false);
  const [
    budgetActivityDetailsModalVisible,
    setBudgetActivityDetailsModalVisible,
  ] = useState(false);
  const [addBudgetActivityModalVisible, setAddBudgetActivityModalVisible] =
    useState(false);
  const [editBudgetActivityModalVisible, setEditBudgetActivityModalVisible] =
    useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const id = getIdFromPath(history.location.pathname);

  //ui functions
  const toggleBudgetLineDetailsModalVisible = (value) =>
    setBudgetLineDetailsModalVisible(value);
  const toggleBudgetActivityDetailsModalVisible = (value) =>
    setBudgetActivityDetailsModalVisible(value);
  const toggleAddBudgetActivityModalVisible = (value) =>
    setAddBudgetActivityModalVisible(value);
  const toggleEditBudgetActivityModalVisible = (value) =>
    setEditBudgetActivityModalVisible(value);

  /* thunks Functions */
  const addBudgetLine = (values) =>
    dispatch(budgetThunks.addBudgetLine(values));
  const deleteBudgetLine = (budgetId, budget) =>
    dispatch(budgetThunks.deleteBudgetLine(budgetId, id));
  const refreshApprovedBudget = () =>
    dispatch(budgetThunks.getApprovedBudget(id));
  const refreshBudgetMonitoringStatement = () =>
    dispatch(budgetThunks.getBudgetMonitoringStatement(id));
  const editBudgetLine = (values) =>
    dispatch(budgetThunks.editBudgetLine(values));
  const viewBudgetLineDetails = (id) => {
    setBudgetLineDetailsModalVisible(true);
    dispatch(budgetThunks.getBudgetLineDetails(id));
  };
  const addBudgetActivity = (values) =>
    dispatch(budgetThunks.addBudgetActivtities(values));
  const deleteBudgetActivity = (activityId) => {
    dispatch(budgetThunks.deleteBudgetActivity(activityId, id));
  };
  const editBudgetActivity = (values) =>
    dispatch(budgetThunks.editBudgetActivity(values));
  const budgetActivityDetails = (id) => {
    setBudgetActivityDetailsModalVisible(true);
    dispatch(budgetThunks.getBudgetAcitvityDetails(id));
  };
  const checkBudget = () => dispatch(budgetThunks.checkBudget(id));
  const submitBudget = () => dispatch(budgetThunks.submitBudget(id));
  const approveBudget = () => dispatch(budgetThunks.approveBudget(id));
  const exportData = () => {
    dispatch(
      logDetailThunks.addLogDetails({
        created_at: new Date(),
        activity: `Exported ${budgetDetails.name} Approved Budget Records`,
        records: "approvedBudget",
      })
    );
  };
  const exportBudgetMonitoringStatement = () => {
    dispatch(
      logDetailThunks.addLogDetails({
        created_at: new Date(),
        activity: `Exported ${budgetDetails.name} Budget Monitoring Statement`,
        records: "budgetMonitoringStatement",
      })
    );
  };
  const reject = () => {
    dispatch(
      rejectionsActions.toggleRejectionsModal({
        visible: true,
        data: {
          user_to_id: budgetDetails?.created_by,
          item_application: "Budget",
          item_id: budgetDetails?.id,
          itemName: budgetDetails.name + "Budget",
        },
      })
    );
  };
  const reload = () => dispatch(budgetThunks.getBudgetDetails(id));

  /* Use effects */
  useEffect(() => {
    dispatch(budgetThunks.getBudgetDetails(id));
    dispatch(budgetThunks.metaData());
    dispatch(budgetThunks.getApprovedBudget(id));
    dispatch(budgetThunks.getBudgetMonitoringStatement(id));
    dispatch(budgetThunks.getBudgetGraphData(id));
  }, [dispatch, id]);

  useEffect(() => {
    //submit budget
    if (submitBudgetSuccess && submitBudgetMessage)
      message.success(submitBudgetMessage);
    else if (!submitBudgetSuccess && submitBudgetError)
      message.error(submitBudgetError);
    //check budget
    if (checkBudgetSuccess && checkBudgetMessage)
      message.success(checkBudgetMessage);
    else if (!checkBudgetSuccess && checkBudgetError)
      message.error(checkBudgetError);
    //approve budget
    if (approveBudgetSuccess && approveBudgetMessage)
      message.success(approveBudgetMessage);
    else if (!approveBudgetSuccess && approveBudgetError)
      message.error(approveBudgetError);
  }, [
    approveBudgetError,
    approveBudgetMessage,
    approveBudgetSuccess,
    checkBudgetError,
    checkBudgetMessage,
    checkBudgetSuccess,
    submitBudgetError,
    submitBudgetMessage,
    submitBudgetSuccess,
  ]);
  return (
    <div className="container-fluid p-2">
      <h3>{budgetDetails.name || "Loading Details ..."}</h3>
      <Space>
        {budgetDetails.status !== appConfig.status.approved && (
          <Space>
            {budgetDetails.submitted && (
              <Space>
                <Button
                  onClick={approveBudget}
                  loading={approveBudgetLoading}
                  type="primary"
                >
                  Approve
                </Button>
                {budgetDetails.status !== appConfig.status.checked && (
                  <Button onClick={checkBudget} loading={checkBudgetLoading}>
                    Check
                  </Button>
                )}
                <Button onClick={reject} danger>
                  Reject
                </Button>
              </Space>
            )}
            {!budgetDetails.submitted && (
              <Button
                loading={submitBudgetLoading}
                className="bg-light"
                type="dashed"
                onClick={submitBudget}
              >
                Submit
              </Button>
            )}
          </Space>
        )}
        <Button onClick={reload}>
          <Space>
            <FcRefresh /> <span>Reload </span>
          </Space>
        </Button>
        <Button
          className="d-none d-md-block"
          onClick={() => history.goBack()}
          danger
        >
          <Space>
            <AiOutlineArrowLeft /> <span>Return </span>
          </Space>
        </Button>
      </Space>
      <Tabs defaultActiveKey="1">
        <TabPane
          tab={
            budgetDetails.status === appConfig.status.approved
              ? "Approved Budget"
              : "Prepare Budget"
          }
          key="1"
        >
          {!approvedBudgetSuccess && approvedBudgetError ? (
            <Alert
              showIcon
              className="my-2"
              message={approvedBudgetError}
              type="error"
            />
          ) : (
            <ApprovedBudget
              handleExportRecords={exportData}
              handleViewBudgetActivitiesDetails={budgetActivityDetails}
              handleOpenBudgetEditActivityModal={
                toggleEditBudgetActivityModalVisible
              }
              handleDeleteBudgetActivity={deleteBudgetActivity}
              handleViewBudgetLineDetails={viewBudgetLineDetails}
              handleRefreshTable={refreshApprovedBudget}
              handleDeleteBudgetLine={deleteBudgetLine}
            />
          )}
        </TabPane>
        <TabPane tab="Budget Monitoring Statement" key="2">
          <BudgetMonitoringStatement
            handleExportRecords={exportBudgetMonitoringStatement}
            handleRefreshTable={refreshBudgetMonitoringStatement}
          />
        </TabPane>
        <TabPane tab="Budget Analysis" key="4">
          <BudgetLinesGraph />
        </TabPane>
        <TabPane tab="Other Budget Details" key="3">
          {budgetDetailsLoading ? (
            <div className="m-5 d-flex justify content center">
              <Spin size="large" />
            </div>
          ) : (
            <div className="w-100">
              {!budgetDetailsSuccess && budgetDetailsError ? (
                <Alert
                  message={budgetDetailsError}
                  showIcon
                  className="my-2"
                  type="error"
                />
              ) : (
                <OtherBudgetDetails data={budgetDetails} />
              )}
            </div>
          )}
        </TabPane>
      </Tabs>
      <AddBudgetLine handleAddRecord={addBudgetLine} />
      <EditBudgetLine handleEditRecord={editBudgetLine} />
      <BudgetLinesDetails
        handleOpenAddAcitvityModal={toggleAddBudgetActivityModalVisible}
        open={budgetLineDetailsModalVisible}
        handleCloseModal={toggleBudgetLineDetailsModalVisible}
      />
      <AddBudgetActivity
        handleAddActivity={addBudgetActivity}
        handleCloseModal={toggleAddBudgetActivityModalVisible}
        open={addBudgetActivityModalVisible}
      />
      <EditBudgetActivity
        handleEditActivity={editBudgetActivity}
        handleCloseModal={toggleEditBudgetActivityModalVisible}
        open={editBudgetActivityModalVisible}
      />
      <BudgetActivityDetails
        open={budgetActivityDetailsModalVisible}
        handleCloseModal={toggleBudgetActivityDetailsModalVisible}
      />
      <AddRejections />
    </div>
  );
};

export default BudgetDetails;
