const initialState = {
  recordOfBidders: [],
  recordOfBiddersLoading: false,
  recordOfBiddersError: "",
  recordOfBiddersSuccess: false,

  bidders: [],
  biddersLoading: false,
  biddersError: "",
  biddersSuccess: false,

  addRecordOfBiddersLoading: false,
  addRecordOfBiddersMessage: "",
  addRecordOfBiddersSuccess: false,
  addRecordOfBiddersError: "",

  addBiddersLoading: false,
  addBiddersMessage: "",
  addBiddersSuccess: false,
  addBiddersError: "",

  recordOfBiddersDetails: {},
  recordOfBiddersDetailsSuccess: false,
  recordOfBiddersDetailsLoading: false,
  recordOfBiddersDetailsError: "",

  biddersDetails: {},
  biddersDetailsSuccess: false,
  biddersDetailsLoading: false,
  biddersDetailsError: "",

  editRecordOfBiddersSuccess: false,
  editRecordOfBiddersLoading: false,
  editRecordOfBiddersData: {},
  editRecordOfBiddersError: "",
  editRecordOfBiddersMessage: "",

  editBiddersSuccess: false,
  editBiddersLoading: false,
  editBiddersData: {},
  editBiddersError: "",
  editBiddersMessage: "",

  deleteRecordOfBiddersLoading: false,
  deleteRecordOfBiddersSuccess: false,
  deleteRecordOfBiddersMessage: "",
  deleteRecordOfBiddersError: "",

  deleteBiddersLoading: false,
  deleteBiddersSuccess: false,
  deleteBiddersMessage: "",
  deleteBiddersError: "",

  approveRecordOfBiddersLoading: false,
  approveRecordOfBiddersError: "",
  approveRecordOfBiddersSuccess: false,
  approveRecordOfBiddersMessage: "",

  checkRecordOfBiddersLoading: false,
  checkRecordOfBiddersSuccess: false,
  checkRecordOfBiddersMessage: "",
  checkRecordOfBiddersError: "",

  submitRecordOfBiddersLoading: false,
  submitRecordOfBiddersSuccess: false,
  submitRecordOfBiddersMessage: "",
  submitRecordOfBiddersError: "",

  recordOfBiddersMetaDataLoading: false,
  recordOfBiddersMetaData: {},
};

export default initialState;
