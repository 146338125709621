import React, { useEffect } from "react";
import { useAssetIncidentFormStore } from "../../../config/stores";
import { isEmpty } from "lodash";
import AssetIncidentFormTable from "./assetIncidentForms/AssetIncidentFormTable";
import AddAssetIncidentForm from "./assetIncidentForms/AssetIncidentForm";
import AssetIncidentFormDetails from "./assetIncidentForms/Details/AssetIncidentFormDetails";

const AssetIncidentForm = () => {
  const assetIncidentFormStore = useAssetIncidentFormStore();

  useEffect(() => {
    if (isEmpty(assetIncidentFormStore.assetIncidentForms)) {
      assetIncidentFormStore.getAssetIncidentForms();
    }
    if (isEmpty(assetIncidentFormStore.metaData)) {
      assetIncidentFormStore.getMetaData();
    }
  }, []);
  return (
    <div className="container-fluid p-2">
      <h5 className="fw-bold">ASSET INCIDENT FORMS</h5>
      <AssetIncidentFormTable />
      <AddAssetIncidentForm />
      <AssetIncidentFormDetails />
    </div>
  );
};

export default AssetIncidentForm;
