import { donorsConstants } from "../../../actions/businesDevAndPartnerships/donors/donors.actions";
import initialState from "../../../initialState/businessDevAndPartnerships/Donors/donors";

const donorsState = (state = initialState, { type, payload }) => {
  switch (type) {
    case donorsConstants.SET_DONORS_REQUEST:
      return {
        ...state,
        donors: { ...state.donors, loading: true },
      };
    case donorsConstants.SET_DONORS_SUCCESS:
      return {
        ...state,
        donors: {
          loading: false,
          data: payload,
          success: true,
          error: "",
        },
      };
    case donorsConstants.SET_DONORS_ERROR:
      return {
        ...state,
        donors: {
          ...state.donors,
          loading: false,
          error: payload,
          success: false,
        },
      };
    case donorsConstants.SET_DONORS_DETAILS_REQUEST:
      return {
        ...state,
        donorDetails: { ...state.donorDetails, loading: true },
      };
    case donorsConstants.SET_DONORS_GRAPH_DATA_REQUEST:
      return {
        ...state,
        donorsGraphDataLoading: true,
      };
    case donorsConstants.SET_DONORS_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        donorsGraphDataLoading: false,
        donorsGraphDataSuccess: true,
        donorsGraphDataError: "",
        donorsGraphData: payload,
      };
    case donorsConstants.SET_DONORS_GRAPH_DATA_ERROR:
      return {
        ...state,
        donorsGraphDataLoading: false,
        donorsGraphDataSuccess: false,
        donorsGraphDataError: payload,
      };
    case donorsConstants.SET_DONORS_DETAILS_SUCCESS:
      return {
        ...state,
        donorDetails: {
          success: true,
          error: "",
          loading: false,
          data: payload,
        },
      };
    case donorsConstants.SET_DONORS_DETAILS_ERROR:
      return {
        ...state,
        donorDetails: {
          data: {},
          success: false,
          error: payload,
          loading: false,
        },
      };
    case donorsConstants.ADD_DONOR_REQUEST:
      return {
        ...state,
        addDonor: { ...state.addDonor, loading: true },
      };
    case donorsConstants.ADD_DONOR_SUCCESS:
      return {
        ...state,
        addDonor: {
          loading: false,
          success: true,
          error: "",
          message: payload.message,
        },
        donors: {
          ...state.donors,
          data: payload.data,
        },
      };
    case donorsConstants.ADD_DONOR_ERROR:
      return {
        ...state,
        addDonor: {
          loading: false,
          error: payload,
          message: "",
          success: false,
        },
      };
    case donorsConstants.ADD_DONOR_COMPLETE:
      return {
        ...state,
        addDonor: {
          ...state.addDonor,
          success: false,
        },
      };
    case donorsConstants.EDIT_DONOR_UPDATE_DATA:
      return {
        ...state,
        updateDonor: { ...state.updateDonor, data: payload },
      };
    case donorsConstants.EDIT_DONOR_REQUEST:
      return {
        ...state,
        updateDonor: { ...state.updateDonor, loading: true },
      };
    case donorsConstants.EDIT_DONOR_SUCCESS:
      return {
        ...state,
        updateDonor: {
          data: {},
          message: payload.message,
          success: true,
          error: "",
          loading: false,
        },
        donors: {
          ...state.donors,
          data: payload.data,
        },
      };
    case donorsConstants.EDIT_DONOR_ERROR:
      return {
        ...state,
        updateDonor: {
          ...state.updateDonor,
          success: false,
          error: payload,
          loading: false,
          message: "",
        },
      };
    case donorsConstants.EDIT_DONOR_COMPLETE:
      return {
        ...state,
        updateDonor: {
          ...state.updateDonor,
          success: false,
        },
      };
    case donorsConstants.DELETE_DONOR_REQUEST:
      return {
        ...state,
        deleteDonor: { ...state.deleteDonor, loading: true },
      };
    case donorsConstants.DELETE_DONOR_SUCCESS:
      return {
        ...state,
        deleteDonor: {
          ...state.deleteDonor,
          loading: false,
          success: true,
          error: "",
          message: payload.message,
        },
        donors: {
          ...state.donors,
          data: payload.data,
        },
      };
    case donorsConstants.DELETE_DONOR_ERROR:
      return {
        ...state,
        deleteDonor: {
          ...state.deleteDonor,
          loading: false,
          success: false,
          error: payload,
          message: "",
        },
      };
    case donorsConstants.DELETE_DONOR_COMPLETE:
      return {
        ...state,
        deleteDonor: {
          ...state.deleteDonor,
          success: false,
          error: "",
        },
      };
    case donorsConstants.APPROVE_DONOR_REQUEST:
      return {
        ...state,
        approveDonor: { ...state.approveDonor, loading: true },
      };
    case donorsConstants.APPROVE_DONOR_SUCCESS:
      return {
        ...state,
        approveDonor: {
          success: true,
          loading: false,
          error: "",
          message: payload,
        },
      };
    case donorsConstants.APPROVE_DONOR_ERROR:
      return {
        ...state,
        approveDonor: {
          success: false,
          loading: false,
          message: "",
          error: payload,
        },
      };
    case donorsConstants.APPROVE_DONOR_COMPLETE:
      return {
        ...state,
        approveDonor: {
          ...state.approveDonor,
          success: false,
          error: "",
        },
      };
    case donorsConstants.CHECK_DONOR_REQUEST:
      return {
        ...state,
        checkDonor: { ...state.checkDonor, loading: true },
      };
    case donorsConstants.CHECK_DONOR_SUCCESS:
      return {
        ...state,
        checkDonor: {
          success: true,
          error: "",
          loading: false,
          message: payload,
        },
      };
    case donorsConstants.CHECK_DONOR_ERROR:
      return {
        ...state,
        checkDonor: {
          success: false,
          error: payload,
          message: "",
          loading: false,
        },
      };
    case donorsConstants.CHECK_DONOR_COMPLETE:
      return {
        ...state,
        checkDonor: {
          ...state.checkDonor,
          success: false,
          error: "",
        },
      };
    case donorsConstants.SET_PERSON_INCHARGE_LOADING:
      return {
        ...state,
        setPersonInchargeLoading: true,
      };
    case donorsConstants.SET_PERSON_INCHARGE_SUCCESS:
      return {
        ...state,
        setPersonInchargeLoading: false,
        setPersonInchargeMessage: payload,
        setPersonInchargeSuccess: true,
        setPersonInchargeError: "",
      };
    case donorsConstants.SET_PERSON_INCHARGE_ERROR:
      return {
        ...state,
        setPersonInchargeLoading: false,
        setPersonInchargeSuccess: false,
        setPersonInchargeError: payload,
      };
    case donorsConstants.SET_PERSON_INCHARGE_COMPLETE:
      return {
        ...state,
        setPersonInchargeSuccess: false,
      };
    case donorsConstants.SUBMIT_PROPOSAL_STATUS_LOADING:
      return {
        ...state,
        submitProposalStatusLoading: true,
      };
    case donorsConstants.SUBMIT_PROPOSAL_STATUS_SUCCESS:
      return {
        ...state,
        submitProposalStatusLoading: false,
        submitProposalStatusMessage: payload,
        submitProposalStatusSuccess: true,
      };
    case donorsConstants.SUBMIT_PROPOSAL_STATUS_ERROR:
      return {
        ...state,
        submitProposalStatusLoading: false,
        submitProposalStatusSuccess: false,
        submitProposalStatusError: payload,
      };
    case donorsConstants.SUBMIT_PROPOSAL_STATUS_COMPLETE:
      return {
        ...state,
        submitProposalStatusSuccess: false,
        submitProposalStatusError: "",
      };
    default:
      return state;
  }
};
export default donorsState;
