export const projectsConstants = {
  SET_PROJECTS_REQUEST: "SET_PROJECTS_REQUEST",
  SET_PROJECTS_SUCCESS: "SET_PROJECTS_SUCCESS",
  SET_PROJECTS_ERROR: "SET_PROJECTS_ERROR",

  SET_PROJECTS_GRAPH_DATA_REQUEST: "SET_PROJECTS_GRAPH_DATA_REQUEST",
  SET_PROJECTS_GRAPH_DATA_SUCCESS: "SET_PROJECTS_GRAPH_DATA_SUCCESS",
  SET_PROJECTS_GRAPH_DATA_ERROR: "SET_PROJECTS_GRAPH_DATA_ERROR",

  SET_MY_PROJECTS_REQUEST: "SET_MY_PROJECTS_REQUEST",
  SET_MY_PROJECTS_SUCCESS: "SET_MY_PROJECTS_SUCCESS",
  SET_MY_PROJECTS_ERROR: "SET_MY_PROJECTS_ERROR",

  SET_PROJECTS_DETAILS_REQUEST: "SET_PROJECTS_DETAILS_REQUEST",
  SET_PROJECTS_DETAILS_SUCCESS: "SET_PROJECTS_DETAILS_SUCCESS",
  SET_PROJECTS_DETAILS_ERROR: "SET_PROJECTS_DETAILS_ERROR",

  ADD_PROJECT_REQUEST: "ADD_PROJECT_REQUEST",
  ADD_PROJECT_SUCCESS: "ADD_PROJECT_SUCCESS",
  ADD_PROJECT_ERROR: "ADD_PROJECT_ERROR",
  ADD_PROJECT_COMPLETE: "ADD_PROJECT_COMPLETE",

  EDIT_PROJECTS_REQUEST: "EDIT_PROJECTS_REQUEST",
  EDIT_PROJECTS_SUCCESS: "EDIT_PROJECTS_SUCCESS",
  EDIT_PROJECTS_ERROR: "EDIT_PROJECTS_ERROR",
  EDIT_PROJECTS_COMPLETE: "EDIT_PROJECTS_COMPLETE",
  EDIT_PROJECTS_DATA: "EDIT_PROJECTS_DATA",

  DELETE_PROJECTS_REQUEST: "DELETE_PROJECTS_REQUEST",
  DELETE_PROJECTS_SUCCESS: "DELETE_PROJECTS_SUCCESS",
  DELETE_PROJECTS_ERROR: "DELETE_PROJECTS_ERROR",
  DELETE_PROJECTS_COMPLETE: "DELETE_PROJECTS_COMPLETE",

  APPROVE_PROJECTS_SUCCESS: "APPROVE_PROJECTS_SUCCESS",
  APPROVE_PROJECTS_REQUEST: "APPROVE_PROJECTS_REQUEST",
  APPROVE_PROJECTS_ERROR: "APPROVE_PROJECTS_ERROR",
  APPROVE_PROJECTS_COMPLETE: "APPROVE_PROJECTS_COMPLETE",

  CHECK_PROJECTS_REQUEST: "CHECK_PROJECTS_REQUEST",
  CHECK_PROJECTS_SUCCESS: "CHECK_PROJECTS_SUCCESS",
  CHECK_PROJECTS_ERROR: "CHECK_PROJECTS_ERROR",
  CHECK_PROJECTS_COMPLETE: "CHECK_PROJECTS_COMPLETE",

  SET_PROJECTS_META_DATA_REQUEST: "SET_PROJECTS_META_DATA_REQUEST",
  SET_PROJECTS_META_DATA: "SET_PROJECTS_META_DATA",
};

export const projectActions = {
  setProjectsRequest: () => ({
    type: projectsConstants.SET_PROJECTS_REQUEST,
  }),
  setProjectsSuccess: (payload) => ({
    type: projectsConstants.SET_PROJECTS_SUCCESS,
    payload,
  }),
  setProjectsError: (payload) => ({
    type: projectsConstants.SET_PROJECTS_ERROR,
    payload,
  }),
  setProjectsGraphDataRequest: () => ({
    type: projectsConstants.SET_PROJECTS_GRAPH_DATA_REQUEST,
  }),
  setProjectsGraphDataSuccess: (payload) => ({
    type: projectsConstants.SET_PROJECTS_GRAPH_DATA_SUCCESS,
    payload,
  }),
  setProjectsGraphDataError: (payload) => ({
    type: projectsConstants.SET_PROJECTS_GRAPH_DATA_ERROR,
    payload,
  }),
  setMyProjectsRequest: () => ({
    type: projectsConstants.SET_MY_PROJECTS_REQUEST,
  }),
  setMyProjectsSuccess: (payload) => ({
    type: projectsConstants.SET_MY_PROJECTS_SUCCESS,
    payload,
  }),
  setMyProjectsError: (payload) => ({
    type: projectsConstants.SET_MY_PROJECTS_ERROR,
    payload,
  }),
  setProjectDetailsRequest: () => ({
    type: projectsConstants.SET_PROJECTS_DETAILS_REQUEST,
  }),
  setProjectDetailsSuccess: (payload) => ({
    type: projectsConstants.SET_PROJECTS_DETAILS_SUCCESS,
    payload,
  }),
  setProjectDetailsError: (payload) => ({
    type: projectsConstants.SET_PROJECTS_DETAILS_ERROR,
    payload,
  }),
  addProjectRequest: () => ({
    type: projectsConstants.ADD_PROJECT_REQUEST,
  }),
  addProjectSuccess: (payload) => ({
    type: projectsConstants.ADD_PROJECT_SUCCESS,
    payload,
  }),
  addProjectError: (payload) => ({
    type: projectsConstants.ADD_PROJECT_ERROR,
    payload,
  }),
  addProjectComplete: () => ({
    type: projectsConstants.ADD_PROJECT_COMPLETE,
  }),
  editProjectRequest: () => ({
    type: projectsConstants.EDIT_PROJECTS_REQUEST,
  }),
  editProjectSuccess: (payload) => ({
    type: projectsConstants.EDIT_PROJECTS_SUCCESS,
    payload,
  }),
  editProjectError: (payload) => ({
    type: projectsConstants.EDIT_PROJECTS_ERROR,
    payload,
  }),
  editProjectComplete: () => ({
    type: projectsConstants.EDIT_PROJECTS_COMPLETE,
  }),
  editProjectData: (payload) => ({
    type: projectsConstants.EDIT_PROJECTS_DATA,
    payload,
  }),
  deleteProjectRequest: () => ({
    type: projectsConstants.DELETE_PROJECTS_REQUEST,
  }),
  deleteProjectSuccess: (payload) => ({
    type: projectsConstants.DELETE_PROJECTS_SUCCESS,
    payload,
  }),
  deleteProjectError: (payload) => ({
    type: projectsConstants.DELETE_PROJECTS_ERROR,
    payload,
  }),
  deleteProjectComplete: () => ({
    type: projectsConstants.DELETE_PROJECTS_COMPLETE,
  }),
  checkProjectRequest: () => ({
    type: projectsConstants.CHECK_PROJECTS_REQUEST,
  }),
  checkProjectSuccess: (payload) => ({
    type: projectsConstants.CHECK_PROJECTS_SUCCESS,
    payload,
  }),
  checkProjectError: (payload) => ({
    type: projectsConstants.CHECK_PROJECTS_ERROR,
    payload,
  }),
  checkProjectComplete: () => ({
    type: projectsConstants.CHECK_PROJECTS_COMPLETE,
  }),
  approveProjectRequest: () => ({
    type: projectsConstants.APPROVE_PROJECTS_REQUEST,
  }),
  approveProjectSuccess: (payload) => ({
    type: projectsConstants.APPROVE_PROJECTS_SUCCESS,
    payload,
  }),
  approveProjectError: (payload) => ({
    type: projectsConstants.APPROVE_PROJECTS_ERROR,
    payload,
  }),
  approveProjectComplete: () => ({
    type: projectsConstants.APPROVE_PROJECTS_COMPLETE,
  }),
  setProjectsMetaDataRequest: () => ({
    type: projectsConstants.SET_PROJECTS_META_DATA_REQUEST,
  }),
  setProjectsMetaData: (payload) => ({
    type: projectsConstants.SET_PROJECTS_META_DATA,
    payload,
  }),
};
