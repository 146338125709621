import { Alert, Button, Form, Input, InputNumber, message, Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";

const AddAccountTypeModal = ({ handleAddRecord }) => {
  const {
    addAccountTypeSuccess,
    addAccountTypeError,
    addAccountTypeMessage,
    addAccountTypeLoading: loading,
  } = useSelector((state) => state.chartOfAccountsState);
  const [form] = Form.useForm();
  const { addCOAAccountTypeModal: visible } = useSelector(
    (state) => state.AppUi
  );
  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch(appUiActions.toggleAddChartOfAccountsAccountTypeModal(false));
  };
  const onFinish = (values) => {
    values.created_at = new Date();
    handleAddRecord(values);
  };
  useEffect(() => {
    if (addAccountTypeSuccess && addAccountTypeMessage) {
      message.success(addAccountTypeMessage);
      form.resetFields();
    }
  }, [addAccountTypeSuccess, addAccountTypeMessage, form]);
  return (
    <div>
      <Modal
        onCancel={closeModal}
        footer={null}
        open={visible}
        title="ADD ACCOUNT TYPE"
      >
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          validateTrigger="onBlur"
          layout="horizontal"
          form={form}
          onFinish={onFinish}
        >
          {!addAccountTypeSuccess && addAccountTypeError && (
            <Alert
              className="m-2"
              showIcon
              type="error"
              message={addAccountTypeError}
            />
          )}
          <Form.Item
            name="name"
            label="Account Name"
            rules={[{ required: true, message: "Please add an account name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="account_number"
            label="Code"
            rules={[
              { required: true, message: "Please add an account number" },
            ]}
          >
            <InputNumber precision={0} className="w-100" />
          </Form.Item>
          <Form.Item name="details" label="Details">
            <TextArea />
          </Form.Item>
          <Form.Item className={"mt-3"} wrapperCol={{ offset: 8, span: 16 }}>
            <Button loading={loading} htmlType="submit" type="primary">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddAccountTypeModal;
