import { rfqActions } from "../../../actions/procument/rfq/rfq.actions";
import apiRequests from "../../../api/api";

const rfqThunks = {
  getRfqs: () => async (dispatch) => {
    dispatch(rfqActions.setRfqsRequest());
    const res = await apiRequests.getRequest("/procurement/rfqs/");
    if (res.success) dispatch(rfqActions.setRfqsSuccess(res.data));
    else dispatch(rfqActions.setRfqsError(res.error));
  },
  getRfqsDetails: (id) => async (dispatch) => {
    dispatch(rfqActions.setRfqsDetailsRequest());
    const res = await apiRequests.getRequest(`/procurement/rfqs/${id}`);
    if (res.success) dispatch(rfqActions.setRfqsDetailsSuccess(res.data));
    else dispatch(rfqActions.setRfqsDetailsError(res.error));
  },
  addRfqs: (body, rfqs) => async (dispatch) => {
    dispatch(rfqActions.addRfqsRequest());
    const res = await apiRequests.postRequest("/procurement/rfqs/", body);
    if (res.success)
      dispatch(
        rfqActions.addRfqsSuccess({
          data: [res.data, ...rfqs],
          message: res.message,
        })
      );
    else dispatch(rfqActions.addRfqsError(res.error));
    dispatch(rfqActions.addRfqsComplete());
  },
  editRfqs: (body, rfqs) => async (dispatch) => {
    dispatch(rfqActions.editRfqsRequest());
    const res = await apiRequests.putRequest(
      `/procurement/rfqs/${body.id}`,
      body
    );
    if (res.success)
      dispatch(
        rfqActions.editRfqsSuccess({
          data: [res.data, ...rfqs],
          message: res.message,
        })
      );
    else dispatch(rfqActions.editRfqsError(res.error));
    dispatch(rfqActions.editRfqsComplete());
  },
  deleteRfqs: (id, rfqs) => async (dispatch) => {
    dispatch(rfqActions.deleteRfqsRequest());
    const res = await apiRequests.deleteRequest(`/procurement/rfqs/${id}`);
    if (res.success)
      dispatch(
        rfqActions.deleteRfqsSuccess({
          message: res.message,
          data: rfqs,
        })
      );
    else dispatch(rfqActions.deleteRfqsError(res.error));
    dispatch(rfqActions.deleteRfqsComplete());
  },
  checkRfqs: (id) => async (dispatch) => {
    dispatch(rfqActions.checkRfqsRequest());
    const res = await apiRequests.patchRequest(
      `/procurement/rfqs/check/${id}`,
      { checkedAt: new Date() }
    );
    if (res.success) {
      dispatch(rfqActions.checkRfqsSuccess(res.message));
      dispatch(rfqThunks.getRfqsDetails(id));
    } else dispatch(rfqActions.checkRfqsError(res.error));
    dispatch(rfqActions.checkRfqsComplete());
  },
  approveRfqs: (id) => async (dispatch) => {
    dispatch(rfqActions.approveRfqsRequest());
    const res = await apiRequests.patchRequest(
      `/procurement/rfqs/approve/${id}`,
      { approvedAt: new Date() }
    );
    if (res.success) {
      dispatch(rfqActions.approveRfqsSuccess(res.message));
      dispatch(rfqThunks.getRfqsDetails(id));
    } else dispatch(rfqActions.approveRfqsError(res.error));
    dispatch(rfqActions.approveRfqsComplete());
  },
  rfqsMetaData: () => async (dispatch) => {
    dispatch(rfqActions.setRfqsMetaDataRequest());
    const res = await apiRequests.getRequest("/procurement/rfqs/metaData");
    dispatch(rfqActions.setRfqsMetaData(res.data || {}));
  },
};

export default rfqThunks;
