import React, { useState } from "react";
import PropTypes, { any, array } from "prop-types";
import { Table } from "antd";

const AntdTable = ({
  columns = [],
  selectableRow = false,
  selectedRows = [],
  setSelectedRows = () => {},
  pageSize = 15,
  data = [],
  title = () => null,
  scrollX = "100%",
  scrollY = 600,
  loading = false,
  serialNumber = false,
  size = "small",
  ...props
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [defaultPageSize, setDefaultPageSize] = useState(pageSize);
  const updateChangeOfPage = (page, size) => {
    setDefaultPageSize(size);
    setCurrentPage(page);
  };
  const rowSelection = {
    selectedRowKeys: selectedRows,
    onChange: setSelectedRows,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((key, index) => {
            if (index % 2 !== 0) {
              return false;
            }
            return true;
          });
          setSelectedRows(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((key, index) => {
            if (index % 2 !== 0) {
              return true;
            }
            return false;
          });
          setSelectedRows(newSelectedRowKeys);
        },
      },
    ],
  };
  return (
    <Table
      tableLayout="fixed"
      scroll={{ x: scrollX ? scrollX : "100%", y: scrollY ? scrollY : 600 }}
      title={title}
      bordered
      size={size ? size : "small"}
      loading={loading}
      dataSource={data}
      rowSelection={selectableRow ? rowSelection : null}
      columns={
        serialNumber
          ? [
              {
                title: "S/N",
                key: "index_no",
                fixed: "left",
                width: 60,
                render: (text, row, index) => {
                  return (currentPage - 1) * defaultPageSize + index + 1;
                },
              },
              ...columns,
            ]
          : columns
      }
      pagination={
        data.length <= defaultPageSize
          ? false
          : {
              defaultPageSize,
              pageSize: defaultPageSize,
              total: data.length,
              pageSizeOptions: ["10", "20", "50", "100", "200", "500"],
              showSizeChanger: true,
              onChange: updateChangeOfPage,
              onShowSizeChange: updateChangeOfPage,
            }
      }
      rowKey="id"
      {...props}
    />
  );
};

AntdTable.propTypes = {
  loading: PropTypes.bool,
  size: PropTypes.string,
  columns: PropTypes.oneOfType([array]).isRequired,
  data: PropTypes.oneOfType([any]),
  selectableRow: PropTypes.bool,
  pageSize: PropTypes.number,
  selectedRows: PropTypes.oneOfType([array]),
  setSelectedRows: PropTypes.func,
  title: PropTypes.func,
  scrollX: PropTypes.any,
  scrollY: PropTypes.any,
  serialNumber: PropTypes.bool,
};

export default AntdTable;
