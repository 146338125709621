import { message, Spin } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import OtherDetails from "../../../components/administration/StaffRecords/Details/OtherDetails";
import UserInfo from "../../../components/administration/StaffRecords/Details/UserInfo";
import ChangeProfilePictureForm from "../../../components/administration/StaffRecords/Forms/ChangeProfilePictureForm";
import UploadStaffSignature from "../../../components/administration/StaffRecords/Forms/UploadStaffSignature";
import LogDetailsTable from "../../../components/settings/LogDetails/Tables/LogDetailsTable";
import { appUiActions } from "../../../config/actions/app/appUi.actions";
import { getIdFromPath } from "../../../config/helpers/app/url";
import routes from "../../../config/routes/routes";
import staffRecordsThunks from "../../../config/thunks/administration/staffRecords/staffRecords.thunks";
import logDetailThunks from "../../../config/thunks/settings/logDetails.thunks";

const StaffDetails = () => {
  const history = useHistory();
  const id = getIdFromPath(history.location.pathname);
  const dispatch = useDispatch();
  const { data, success, error, loading } = useSelector(
    (state) => state.staffRecordsState.staffDetails
  );
  const {
    success: changeProfilePictureSuccess,
    error: changeProfilePictureError,
    message: changeProfilePictureMessage,
  } = useSelector((state) => state.staffRecordsState.updateProfilePicture);
  const {
    success: accountStatusSuccess,
    error: accountStatusError,
    message: accountStatusMessage,
  } = useSelector((state) => state.staffRecordsState.staffAccountStatus);
  const {
    success: generateNewConfirmTokenSuccess,
    error: generateNewConfirmTokenError,
    message: generateNewConfirmTokenMessage,
  } = useSelector((state) => state.staffRecordsState.generateNewConfirmToken);
  const { uploadStaffSignatureSuccess, uploadStaffSignatureMessage } =
    useSelector((state) => state.staffRecordsState);
  /** functions */
  const activateAccount = (id) =>
    dispatch(staffRecordsThunks.activateStaffAccount(id));
  const deactivateAccount = (id) =>
    dispatch(staffRecordsThunks.deactivateStaffAccount(id));
  const changeProfilePicture = (values) =>
    dispatch(staffRecordsThunks.updateProfilePicture(id, values));
  const generateNewConfirmToken = (values) =>
    dispatch(staffRecordsThunks.generateNewConfirmToken(values));
  const refreshDataLogDetails = () =>
    dispatch(logDetailThunks.getLogDetails(id));
  const uploadStaffSignature = (values) =>
    dispatch(staffRecordsThunks.uploadStaffSignature(values, id));

  //get staff details
  useEffect(() => {
    document.title = routes.administration.staffRecords.staffDetails.title;
    dispatch(staffRecordsThunks.getStaffDetails(id));
    dispatch(logDetailThunks.getLogDetails(id));
  }, [dispatch, id]);
  //account status
  useEffect(() => {
    if (accountStatusSuccess) message.success(accountStatusMessage);
    else if (!accountStatusSuccess && accountStatusError)
      message.error(accountStatusError);
  }, [accountStatusError, accountStatusMessage, accountStatusSuccess]);
  //change profile picture
  useEffect(() => {
    if (changeProfilePictureSuccess)
      message.success(changeProfilePictureMessage);
    else if (!changeProfilePictureSuccess && changeProfilePictureError)
      message.error(changeProfilePictureError);
  }, [
    changeProfilePictureError,
    changeProfilePictureMessage,
    changeProfilePictureSuccess,
  ]);
  // upload staff signature
  useEffect(() => {
    if (uploadStaffSignatureSuccess) {
      message.success(uploadStaffSignatureMessage);
      dispatch(appUiActions.toggleSignatureModal(false));
    }
  }, [uploadStaffSignatureSuccess, uploadStaffSignatureMessage, dispatch]);
  //generate new confirm token
  useEffect(() => {
    if (generateNewConfirmTokenSuccess && generateNewConfirmTokenMessage)
      message.success(generateNewConfirmTokenMessage);
    else if (!generateNewConfirmTokenSuccess && generateNewConfirmTokenError)
      message.error(generateNewConfirmTokenError);
  }, [
    generateNewConfirmTokenError,
    generateNewConfirmTokenMessage,
    generateNewConfirmTokenSuccess,
  ]);
  return (
    <div className="container-fluid p-2">
      {loading ? (
        <div className="m-5 d-flex justify-content-center">
          <Spin size="large" />
        </div>
      ) : (
        <>
          {!success ? (
            <div className="alert alert-danger">
              {error || "Sorry there seems to be an error "}
            </div>
          ) : (
            <div className="profile-container w-100  d-md-flex flex-row flex-wrap ">
              <UserInfo
                handleActivateAccount={activateAccount}
                handleDeactivateAccount={deactivateAccount}
                handleGenerateNewConfirmToken={generateNewConfirmToken}
                data={data}
              />
              <OtherDetails data={data} />
              <ChangeProfilePictureForm handleSubmit={changeProfilePicture} />
              <UploadStaffSignature handleSubmit={uploadStaffSignature} />
              <div className="w-100">
                <h3>Audit Trail</h3>
                <LogDetailsTable handleRefresh={refreshDataLogDetails} />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default StaffDetails;
