import React, { useState } from "react";
import dayjs from "dayjs";
import { useActivityNoteStore } from "../../../../config/stores";
import { searchTable } from "../../../../config/constants";
import TableButtonActions from "../../../../components/common/TableButtonActions";
import RecordStatus from "../../../../components/common/RecordStatus";
import AntdTable from "../../../../components/common/AntdTable";
import TableTitle from "../../../../components/common/TableTitle";

const ActivityConceptNotesTable = ({
  activityConceptNotes = [],
  canEdit = false,
  refreshTable = () => {},
}) => {
  const [filterTable, setFilterTableNull] = useState(null);
  const [deleteId, setDeleteId] = useState(null);

  const activityConceptNotesStore = useActivityNoteStore();
  const { gettingProjectActivityNotes } = activityConceptNotesStore;

  const search = (value) => {
    const filterTable = searchTable(activityConceptNotes, value);
    setFilterTableNull(filterTable);
  };

  const confirmDelete = () => {
    activityConceptNotesStore.deleteActivityNote(deleteId);
    setDeleteId(null);
  };
  const viewDetails = (id) => {
    activityConceptNotesStore.setShowViewActivityNoteDetails(true);
    activityConceptNotesStore.getActivityNoteDetails(id);
  };

  const deleteRecord = (id) => setDeleteId(id);

  const updateRecord = (record) => {
    activityConceptNotesStore.setCreating(true, false, record);
  };

  const columns = [
    {
      title: "Title",
      render: (record) => {
        return (
          <div>
            <span className="d-md-none">title:</span>
            {record.title} <br />
            <div className="d-md-none">
              Prepared By :{" "}
              {`${record?.created_by?.first_name} ${record?.created_by?.other_names}`}{" "}
              <br />
              Project : {record.project?.name} <br />
              Donor: {record.project?.donor?.name} <br />
              Actions:
              <TableButtonActions
                record={record}
                confirmDelete={confirmDelete}
                deleteLoading={activityConceptNotesStore.deletingActivityNote}
                viewDetails={viewDetails}
                updateRecord={updateRecord}
                deleteId={deleteId}
                deleteRecord={deleteRecord}
              />
            </div>
          </div>
        );
      },
    },
    {
      title: "Thematic Area",
      dataIndex: "thematicArea",
      responsive: ["md"],
      render: (thematicArea) => thematicArea?.thematic_area,
    },
    {
      title: "Activity",
      dataIndex: "activity",
      responsive: ["md"],
      render: (activity) => activity?.activity,
    },
    {
      title: "Date",
      dataIndex: "date",
      responsive: ["md"],
      width: 100,
      render: (date) => dayjs(`${date}`).format("DD-MM-YYYY"),
    },
    {
      title: "Prepared By",
      dataIndex: "created_by",
      responsive: ["md"],
      render: (created_by) => {
        return `${created_by?.first_name} ${created_by?.other_names}`;
      },
    },
    {
      title: "Project",
      dataIndex: "project",
      responsive: ["md"],
      render: (project) => project?.name,
    },
    {
      title: "Status",
      dataIndex: "status",
      responsive: ["md"],
      render: (text) => <RecordStatus status={text} />,
    },
    {
      title: "Actions",
      key: "actions",
      responsive: ["md"],
      render: (record) => {
        return (
          <TableButtonActions
            record={record}
            confirmDelete={confirmDelete}
            deleteLoading={activityConceptNotesStore.deletingActivityNote}
            viewDetails={viewDetails}
            updateRecord={canEdit && updateRecord}
            deleteId={deleteId}
            deleteRecord={canEdit && deleteRecord}
          />
        );
      },
    },
  ];

  const openAddModal = () => {
    activityConceptNotesStore.setCreating(true, true, {});
  };

  return (
    <div>
      <AntdTable
        loading={gettingProjectActivityNotes}
        title={() => (
          <TableTitle
            setFilterTableNull={setFilterTableNull}
            openAddModal={canEdit && openAddModal}
            refreshTable={refreshTable}
            search={search}
            exportRecords={null}
          />
        )}
        pageSize={20}
        data={filterTable === null ? activityConceptNotes || [] : filterTable}
        columns={columns}
      />
    </div>
  );
};

export default ActivityConceptNotesTable;
