import axios from "axios";

const storesRegisterApiRequests = {
  fetchStoresRegister: async () => {
    try {
      const res = await axios.get("/administration/storesRegister/");
      return res;
    } catch (error) {
      console.error(error);
    }
  },

  addStoresRegisterReceipt: async (body) => {
    try {
      const res = await axios.post(
        "/administration/storesRegister/receipts",
        body
      );
      return res;
    } catch (error) {
      console.error(error);
    }
  },

  addStoresRegisterIssue: async (body) => {
    try {
      const res = await axios.post(
        "/administration/storesRegister/issues",
        body
      );
      return res;
    } catch (error) {
      console.error(error);
    }
  },

  updateStoresRegisterReceipt: async (body, id) => {
    try {
      const res = await axios.put(
        `/administration/storesRegister/receipts/${id}`,
        body
      );
      return res;
    } catch (error) {
      console.error(error);
    }
  },

  updateStoresRegisterIssue: async (body, id) => {
    try {
      const res = await axios.put(
        `/administration/storesRegister/issues/${id}`,
        body
      );
      return res;
    } catch (error) {
      console.error(error);
    }
  },

  deleteStoresRegisterReceipt: async (id) => {
    try {
      const res = await axios.delete(
        `/administration/storesRegister/receipts/${id}`
      );
      return res;
    } catch (error) {
      console.error(error);
    }
  },

  deleteStoresRegisterIssues: async (id) => {
    try {
      const res = await axios.delete(
        `/administration/storesRegister/issues/${id}`
      );
      return res;
    } catch (error) {
      console.error(error);
    }
  },

  approveStoresRegisterReceipt: async (id) => {
    try {
      const res = await axios.patch(
        `/administration/storesRegister/receipts/approve/${id}`,
        {
          approvedAt: new Date(),
        }
      );
      return res;
    } catch (error) {
      console.error(error);
    }
  },

  approveStoresRegisterIssue: async (id) => {
    try {
      const res = await axios.patch(
        `/administration/storesRegister/issues/approve/${id}`,
        {
          approvedAt: new Date(),
        }
      );
      return res;
    } catch (error) {
      console.error(error);
    }
  },

  checkStoresRegisterIssue: async (id) => {
    try {
      const res = await axios.patch(
        `/administration/storesRegister/issues/check/${id}`,
        {
          checkedAt: new Date(),
        }
      );
      return res;
    } catch (error) {
      console.error(error);
    }
  },

  checkStoresRegisterReceipt: async (id) => {
    try {
      const res = await axios.patch(
        `/administration/storesRegister/receipts/check/${id}`,
        {
          checkedAt: new Date(),
        }
      );
      return res;
    } catch (error) {
      console.error(error);
    }
  },

  storesRegisterReceiptsDetails: async (id) => {
    try {
      const res = await axios.get(
        `/administration/storesRegister/receipts/${id}`
      );
      return res;
    } catch (error) {
      console.error(error);
    }
  },

  storesRegisterIssuesDetails: async (id) => {
    try {
      const res = await axios.get(
        `/administration/storesRegister/issues/${id}`
      );
      return res;
    } catch (error) {
      console.error(error);
    }
  },
};

export default storesRegisterApiRequests;
