import axios from "axios";

export const fetchAssetsRequest = async () => {
  try {
    const res = await axios.get(`/administration/assetRegister/`);
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const addAssetRequest = async (body) => {
  try {
    const res = await axios.post(`/administration/assetRegister/`, body, {
      headers: {
        accept: "application/json",
        "Content-type": "application/json",
      },
    });
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const updateAssetRequest = async (body, id) => {
  try {
    const res = await axios.patch(`/administration/assetRegister/${id}`, body, {
      headers: {
        "Content-type": "application/json",
      },
    });
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const deleteAssetRequest = async (id) => {
  try {
    const res = await axios.delete(`/administration/assetRegister/${id}`, {
      headers: {
        "Content-type": "application/json",
      },
    });
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const fetchAssetsCategoryRequest = async () => {
  try {
    const res = await axios.get(`/administration/assetRegister/categories`);
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const addAssetCategoryRequest = async (body) => {
  try {
    const res = await axios.post(
      `/administration/assetRegister/categories`,
      body,
      {
        headers: {
          accept: "application/json",
          "Content-type": "application/json",
        },
      }
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const updateAssetCategoryRequest = async (body, id) => {
  try {
    const res = await axios.patch(
      `/administration/assetRegister/categories/${id}`,
      body,
      {
        headers: {
          "Content-type": "application/json",
        },
      }
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const deleteAssetCategoryRequest = async (id) => {
  try {
    const res = await axios.delete(
      `/administration/assetRegister/categories/${id}`,
      {
        headers: {
          "Content-type": "application/json",
        },
      }
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const fetchAssetDetailsRequest = async (id) => {
  try {
    const res = await axios.get(`/administration/assetRegister/${id}`);
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const fetchAssetTypesDetailsRequest = async (id) => {
  try {
    const res = await axios.get(
      `/administration/assetRegister/categories/${id}`
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const approveAssetRequest = async (id) => {
  try {
    const res = await axios.patch(
      `/administration/assetRegister/approve/${id}`,
      {
        approvedAt: new Date(),
      }
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const approveAssetTypeRequest = async (id) => {
  try {
    const res = await axios.patch(
      `/administration/assetRegister/categories/approve/${id}`,
      {
        approvedAt: new Date(),
      }
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const checkAssetTypesRequest = async (id) => {
  try {
    const res = await axios.patch(
      `/administration/assetRegister/categories/check/${id}`,
      {
        checkedAt: new Date(),
      }
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const checkAssetRequest = async (id) => {
  try {
    const res = await axios.patch(`/administration/assetRegister/check/${id}`, {
      checkedAt: new Date(),
    });
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const getStaffRecordsRequest = async () => {
  try {
    const res = await axios.get(`/administration/assetRegister/metaData`);
    return res;
  } catch (error) {
    console.error(error);
    return { success: false };
  }
};
