import appConfig from "../../config.json";
import { getAuthToken } from "../../helpers/auth/authToken";

const authorization = `Bearer ${getAuthToken()}`;

const errorResponseObject = {
  success: false,
  error: "Sorry there seems to be an error",
};
const rejectionsApiRequests = {
  addRejection: async (body) => {
    try {
      const res = await fetch(`${appConfig.server_api_url}/rejections`, {
        method: "Post",
        headers: {
          authorization,
          accept: "application/json",
          "content-type": "application/json",
        },
        body: JSON.stringify(body),
      });
      return res.json();
    } catch (error) {
      console.error("Add Rejection request error", error);
      return errorResponseObject;
    }
  },
};

export default rejectionsApiRequests;
