import { message, Table } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import LogDetailsTableTitle from "./LogDetailsTableTitle";

const LogDetailsTable = ({ handleRefresh }) => {
  const [filterTable, setfilterTable] = useState(null);

  const { loading, data, error, success } = useSelector(
    (state) => state.logDetailsState.logDetails
  );

  const search = (value) => {
    const filterTable = data.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(value.toLowerCase())
      )
    );
    setfilterTable(filterTable);
  };
  const setfilterTableNull = (e) => {
    if (!e.target.value) setfilterTable(null);
  };
  const refreshTable = () => {
    handleRefresh();
    if (error && !success) message.error(error);
  };
  const showTotal = (total) => `Total: ${total}`;

  const columns = [
    {
      title: "First Name",
      key: "first_name",
      render: (text) => (
        <div>
          <span className="d-md-none">Name: </span>
          {text.first_name}{" "}
          <span className="d-md-none">{text.other_names}</span> <br />
          <div className="d-md-none">
            Activity: {text.activity} <br />
            TimeStamp:
            {`${new Date(text.created_at).toDateString()} ${new Date(
              text.created_at
            ).toLocaleTimeString()}`}
          </div>
        </div>
      ),
    },
    {
      title: "Other Names",
      dataIndex: "other_names",
      key: "other_names",
      responsive: ["md"],
    },
    {
      title: "Activity",
      dataIndex: "activity",
      key: "activity",
      responsive: ["md"],
    },
    {
      title: "Timestamp",
      dataIndex: "created_at",
      key: "activity",
      render: (text) => (
        <>{`${new Date(text).toDateString()} ${new Date(
          text
        ).toLocaleTimeString()}`}</>
      ),
      responsive: ["md"],
    },
  ];

  return (
    <div className="">
      <Table
        pagination={{ total: data.length, showSizeChanger: true, showTotal }}
        loading={loading}
        title={() =>
          LogDetailsTableTitle({
            search,
            setfilterTableNull,
            refreshTable,
          })
        }
        bordered={true}
        dataSource={filterTable === null ? data : filterTable}
        columns={columns}
      />
    </div>
  );
};

export default LogDetailsTable;
