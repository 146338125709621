const intialState = {
  logDetails: {
    loading: false,
    success: false,
    error: "",
    data: [],
  },
  addLogDetails: {
    loading: false,
    success: false,
    error: "",
    records: "",
  },
};

export default intialState;
