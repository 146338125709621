import { Alert, Button, Form, Input, message, Modal, Select } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";
import { permissionsActions } from "../../../../config/actions/settings/permissions.actions";
import appConfig from "../../../../config/config.json";

const { Option } = Select;

const EditPermission = ({ handleEditPermission }) => {
  const dispatch = useDispatch();
  const {
    error,
    success,
    loading,
    data,
    message: updatePermissionsMessage,
  } = useSelector((state) => state.permissionsState.updatePermission);
  const roles = useSelector((state) => state.permissionsState.roles);

  const onFinish = (values) => {
    values.updatedAt = new Date();
    values.id = data.id;
    for (const key in values) {
      if (!values[key]) values[key] = false;
      else if (values[key] === "true") values[key] = true;
    }
    values.create_record = values.create;
    values.delete = values.deleteRecord;
    values.check_record = values.check;
    values.function_id = values.appFunction;
    values.role_id = values.roleId;
    dispatch(permissionsActions.setUpdatePermissionsData(values));
    handleEditPermission(values);
  };
  const visible = useSelector(
    (state) => state.AppUi.settings.editPermissonsModal
  );
  const closeAddRoleModal = () => {
    dispatch(appUiActions.toggleEditPermissionsModal(false));
  };
  const fields = [
    { name: "name", value: data.name },
    { name: "appFunction", value: data.function_id },
    { name: "roleId", value: data.role_id },
    { name: "access", value: data.access },
    { name: "create", value: data.create_record },
    { name: "read", value: data.read },
    { name: "update", value: data.update },
    { name: "check", value: data.check_record },
    { name: "deleteRecord", value: data.delete },
    { name: "approve", value: data.approve },
  ];

  useEffect(() => {
    if (success) message.success(updatePermissionsMessage);
  }, [success, updatePermissionsMessage]);

  return (
    <div>
      <Modal
        onCancel={closeAddRoleModal}
        open={visible}
        title="EDIT PERMISSIONS"
        footer={null}
      >
        <Form
          fields={fields}
          layout="vertical"
          validateTrigger="onBlur"
          onFinish={onFinish}
        >
          {!success && error && <Alert message={error} type="error" showIcon />}
          <div className="form-inputs">
            <div className="input-fields p-1">
              <Form.Item
                name="name"
                label="Permission Name"
                rules={[
                  { required: true, message: "Please add permission name " },
                ]}
              >
                <Input disabled />
              </Form.Item>
              <Form.Item
                name="roleId"
                label="Role"
                rules={[{ required: true, message: "Please select a role" }]}
              >
                <Select
                  disabled
                  loading={roles.loading}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.value
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {roles.data.map((e, idx) => {
                    return (
                      <Option value={e.id} key={idx}>
                        {e.role}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                name="appFunction"
                label="System application"
                rules={[
                  { required: true, message: "Please select a permission" },
                ]}
              >
                <Select
                  disabled
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.value
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {appConfig.applicationFunctions.map((e, idx) => {
                    return (
                      <Option value={e} key={idx}>
                        {e}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
            <div className="checkboxes p-1">
              <h3>Permissions</h3>
              <div className="d-md-flex">
                <div className="w-50 w-sm-100 m-1">
                  <Form.Item
                    label="Can Create Records"
                    rules={[
                      { required: true, message: "Please fill in this field" },
                    ]}
                    name="create"
                  >
                    <Select>
                      <Option value={true}>Granted</Option>
                      <Option value={false}>Denied</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    rules={[
                      { required: true, message: "Please fill in this field" },
                    ]}
                    label="Can View records"
                    name="read"
                  >
                    <Select>
                      <Option value={true}> Granted </Option>
                      <Option value={false}> Denied </Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    rules={[
                      { required: true, message: "Please fill in this field" },
                    ]}
                    label="Can Update records"
                    name="update"
                  >
                    <Select>
                      <Option key="update_granted" value={true}>
                        Granted
                      </Option>
                      <Option key="update_denied" value={false}>
                        Denied
                      </Option>
                    </Select>
                  </Form.Item>
                </div>
                <div className="w-50 w-sm-100 m-1">
                  <Form.Item
                    rules={[
                      { required: true, message: "Please fill in this field" },
                    ]}
                    label="Can Delete Records"
                    name="deleteRecord"
                  >
                    <Select>
                      <Option value={true}> Granted </Option>
                      <Option value={false}> Denied </Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    rules={[
                      { required: true, message: "Please fill in this field" },
                    ]}
                    label="Can Check records"
                    name="check"
                  >
                    <Select>
                      <Option value={true}> Granted </Option>
                      <Option value={false}> Denied </Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    rules={[
                      { required: true, message: "Please fill in this field" },
                    ]}
                    label="Can Approve records"
                    name="approve"
                  >
                    <Select>
                      <Option value={true}> Granted </Option>
                      <Option value={false}> Denied </Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
          <Form.Item>
            <Button loading={loading} htmlType="submit" type="primary">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default EditPermission;
