import { Button, message, Popover, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { rfqActions } from "../../../../config/actions/procument/rfq/rfq.actions";
import generatePDF from "../../../../config/services/generatePdf";
import tableButtonActions from "../../../shared/Tables/TableButtonActions";
import TableTitle from "../../../shared/Tables/TableTitle";

const RfqsTable = ({
  handleDelete,
  handleRefreshTable,
  handleExportRecords,
  handleOpenAddModal,
  handleOpenEditModal,
  handleViewDetails,
}) => {
  const [deleteId, setDeleteId] = useState("");
  const [filterTable, setfilterTable] = useState(null);
  const {
    rfqs: data,
    rfqsLoading: loading,
    rfqsSuccess: success,
    rfqsError: error,
    deleteRfqsLoading: deleteLoading,
    deleteRfqsSuccess: deleteSuccess,
    deleteRfqsError: deleteError,
    deleteRfqsMessage: deleteMessage,
  } = useSelector((state) => state.rfqsState);
  const {
    success: logDetailsSuccess,
    error: logDetailsError,
    records,
  } = useSelector((state) => state.logDetailsState.addLogDetails);
  const dispatch = useDispatch();
  const columns = [
    {
      title: "RFQ No.",
      key: "rfqno",
      render: (text) => (
        <div>
          <span className="d-md-none">RFQ No.:</span> {text.rfqno} <br />
          <div className="d-md-none">
            Deadline: {new Date(text.deadline_of_submission).toDateString()}{" "}
            <br />
            Requisition Form: {text.requisition_memo} <br />
            Subject: {text.subject} <br />
            <Popover
              className=""
              trigger="click"
              placement="bottom"
              content={() =>
                tableButtonActions(text, {
                  viewDetails,
                  updateRecord,
                  confirmDelete,
                  deleteRecord,
                  deleteLoading,
                  deleteId,
                })
              }
            >
              Actions: <Button type="link">Actions</Button>
            </Popover>
          </div>
        </div>
      ),
    },
    {
      title: "Requisition Form",
      key: "requisition_memo",
      dataIndex: "requisition_memo",
      responsive: ["md"],
    },
    {
      title: "Deadline",
      dataIndex: "deadline_of_submission",
      key: "type",
      render: (text) => new Date(text).toDateString(),
      responsive: ["md"],
    },
    {
      title: "Action",
      key: "operation",
      responsive: ["md"],
      render: (text) => (
        <Popover
          trigger="click"
          placement="bottom"
          content={() =>
            tableButtonActions(text, {
              viewDetails,
              updateRecord,
              confirmDelete,
              deleteRecord,
              deleteLoading,
              deleteId,
            })
          }
        >
          <Button>Actions</Button>
        </Popover>
      ),
    },
  ];
  /* Functions */
  const deleteRecord = (e) => setDeleteId(e.target.id);
  const confirmDelete = () => {
    const rfqsArray = data.filter((e) => e.id !== deleteId);
    handleDelete(deleteId, rfqsArray);
  };
  const refreshTable = () => {
    handleRefreshTable();
    if (!success && error) message.error(error);
  };
  const updateRecord = (e) => {
    const rfqsArray = data.filter((el) => el.id === e.target.id);
    dispatch(rfqActions.editRfqsData(rfqsArray[0]));
    handleOpenEditModal(true);
  };
  const viewDetails = (e) => handleViewDetails(e.target.id);

  const search = (value) => {
    const filterTable = data.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(value.toLowerCase())
      )
    );
    setfilterTable(filterTable);
  };
  const setfilterTableNull = (e) => {
    if (!e.target.value) setfilterTable(null);
  };

  const showTotal = (total) => `Total: ${total}`;
  const openAddModal = () => {
    handleOpenAddModal(true);
  };
  const exportRecords = () => {
    message.loading("Proccessing document for export please wait ", 3);
    handleExportRecords();
  };
  const generateRecordsPdf = () => {
    const pdfColumns = ["RFQ No.", "Requisition Form", "Deadline", "Subject"];
    let pdfRows = [];
    data?.forEach((record) => {
      const row = [
        record.rfqno,
        record.requisition_memo,
        new Date(record.deadline_of_submission).toDateString(),
        record.subject,
      ];
      pdfRows.push(row);
    });
    generatePDF(
      pdfRows,
      pdfColumns,
      `Amani Intiative Request For Qoutations - ${new Date().toLocaleDateString()}`,
      "Amani Intiatice Request For Qoutations.pdf"
    );
  };
  /* Use effect */
  useEffect(() => {
    if (logDetailsSuccess && records === "rfqs") {
      message.info("Started download");
      generateRecordsPdf();
    } else if (!logDetailsSuccess && logDetailsError)
      message.error(logDetailsError);
    if (deleteSuccess && deleteMessage) message.success(deleteMessage);
    else if (!deleteSuccess && deleteError) message.error(deleteError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    logDetailsSuccess,
    logDetailsError,
    deleteSuccess,
    deleteMessage,
    deleteError,
  ]);

  return (
    <div>
      <Table
        pagination={{ total: data.length, showSizeChanger: true, showTotal }}
        loading={loading}
        title={() =>
          TableTitle({
            search,
            setfilterTableNull,
            refreshTable,
            exportRecords,
            openAddModal,
          })
        }
        bordered={true}
        dataSource={filterTable === null ? data : filterTable}
        columns={columns}
      />
    </div>
  );
};

export default RfqsTable;
