export const centrePdfText = (doc, text, y) => {
  const fontSize = doc.internal.getFontSize();

  // Get page width
  const pageWidth = doc.internal.pageSize.width;

  // Get the actual text's width
  /* You multiply the unit width of your string by your font size and divide
   * by the internal scale factor. The division is necessary
   * for the case where you use units other than 'pt' in the constructor
   * of jsPDF.
   */
  let txtWidth =
    (doc.getStringUnitWidth(text) * fontSize) / doc.internal.scaleFactor;

  // Calculate text's x coordinate
  let x = (pageWidth - txtWidth) / 2;
  doc.text(text, x, y);
};
