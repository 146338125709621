import { Modal, Form, Select, Button, message, Alert, Input } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import appConfig from "../../../../config/config.json";

const { Option } = Select;
const AddRequsitionMemo = ({ visible, handleAddRecord, handleCloseModal }) => {
  const {
    addRequisitionMemosSuccess,
    addRequisitionMemosLoading,
    addRequisitionMemosError,
    addRequisitionMemosMessage,
    requisitionMemos,
  } = useSelector((state) => state.requisitionMemoState);
  const { approvedBudget, approvedBudgetLoading } = useSelector(
    (state) => state.budgetState
  );
  const { projectsDetails, projectsDetailsLoading, projectsDetailsSuccess } =
    useSelector((state) => state.projectsState);
  const closeModal = () => handleCloseModal(false);
  const [form] = Form.useForm();
  const onFinish = (values) => {
    if (!projectsDetailsLoading && projectsDetailsSuccess) {
      values.created_at = new Date();
      values.project = projectsDetails.id;
      values.budget = projectsDetails.projectBudget;
    } else if (!projectsDetailsSuccess)
      message.error(
        "There seems to be an error loading meta data please refresh the page to reload it"
      );
    else if (projectsDetailsLoading)
      message.info("Loading  meta data please hold on for abit then try again");
    handleAddRecord(values, requisitionMemos);
  };
  useEffect(() => {
    if (addRequisitionMemosSuccess && addRequisitionMemosMessage) {
      message.success(addRequisitionMemosMessage);
      form.resetFields();
    }
  }, [addRequisitionMemosSuccess, addRequisitionMemosMessage, form]);
  return (
    <div>
      <Modal
        title="CREATE REQUISITION FORM"
        onCancel={closeModal}
        footer={null}
        open={visible}
      >
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          layout="horizontal"
          form={form}
          onFinish={onFinish}
        >
          {!addRequisitionMemosSuccess && addRequisitionMemosError && (
            <Alert
              className="m-2"
              showIcon
              type="error"
              message={addRequisitionMemosError}
            />
          )}
          <Form.Item
            name="budget_line"
            label="Budget Line"
            rules={[{ required: true, message: "Please select a budget line" }]}
          >
            <Select
              defaultValue=""
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              loading={approvedBudgetLoading}
              allowClear
            >
              {approvedBudget.map((budgetline) => (
                <Option key={budgetline.id} value={budgetline.id}>
                  {budgetline.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="type"
            label="Type"
            rules={[{ required: true, message: "Please select a budget line" }]}
          >
            <Select loading={approvedBudgetLoading} allowClear>
              {appConfig.requisitionMemoTypes.map((type) => (
                <Option key={type} value={type}>
                  {type}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: "Please add details" }]}
            name="details"
            label="Details"
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item className={"mt-3"} wrapperCol={{ offset: 8, span: 16 }}>
            <Button
              loading={addRequisitionMemosLoading}
              htmlType="submit"
              type="primary"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddRequsitionMemo;
