import React, { useState } from "react";
import TableButtonActions from "../../../../components/common/TableButtonActions";
import { useAccountsStore } from "../../../../config/stores";
import AntdTable from "../../../../components/common/AntdTable";
import TableTitle from "../../../../components/common/TableTitle";
import { searchTable } from "../../../../config/constants";

const AccountsTable = () => {
  const [filterTable, setFilterTableNull] = useState(null);
  const [deleteId, setDeleteId] = useState(null);

  const accountStore = useAccountsStore();

  const search = (value) => {
    const filterTable = searchTable(accountStore.accounts?.accounts, value);
    setFilterTableNull(filterTable);
  };
  const confirmDelete = () => {
    accountStore.deleteAccount(deleteId);
    setDeleteId(null);
  };
  const viewDetails = (id) => {
    accountStore.setShowViewAccountDetails(true);
    accountStore.getAccountDetails(id);
  };

  const deleteRecord = (id) => setDeleteId(id);

  const updateRecord = (record) => {
    accountStore.setUpdateAccountData(record);
    accountStore.setCreating(true, false, record);
  };
  const columns = [
    {
      title: "Account Name",
      dataIndex: "name",
    },
    {
      title: "Type",
      dataIndex: "type",
    },
    {
      title: "Account Number",
      dataIndex: "account_number",
    },
    {
      title: "Actions",
      key: "actions",
      responsive: ["md"],
      render: (record) => {
        return (
          <TableButtonActions
            record={record}
            confirmDelete={confirmDelete}
            deleteLoading={accountStore.deletingAccount}
            viewDetails={viewDetails}
            updateRecord={updateRecord}
            deleteId={deleteId}
            deleteRecord={deleteRecord}
          />
        );
      },
    },
  ];

  const openAddModal = () => {
    accountStore.setCreating(true, true, {});
  };

  const refreshTable = () => {
    accountStore.getAccounts();
  };
  return (
    <div>
      <AntdTable
        loading={accountStore.gettingAccounts}
        title={() => (
          <TableTitle
            setFilterTableNull={setFilterTableNull}
            openAddModal={openAddModal}
            refreshTable={refreshTable}
            search={search}
            exportRecords={null}
          />
        )}
        pageSize={20}
        data={
          filterTable === null
            ? accountStore?.accounts?.accounts || []
            : filterTable
        }
        columns={columns}
      />
    </div>
  );
};

export default AccountsTable;
