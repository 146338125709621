import { Alert, Button, Form, Input, message, Modal } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";
import { rolesActions } from "../../../../config/actions/settings/roles.actions";
import appConfig from "../../../../config/config.json";

const EditRole = ({ handleEditRole }) => {
  const visible = useSelector((state) => state.AppUi.settings.editRoleModal);
  const dispatch = useDispatch();
  const { error, success, loading, data } = useSelector(
    (state) => state.rolesState.updateRole
  );
  const onFinish = (values) => {
    values.updatedAt = new Date();
    values.id = data.id;
    dispatch(rolesActions.setUpdateRolesData(values));
    handleEditRole(values);
  };

  const closeEditRoleModal = () => {
    dispatch(appUiActions.toggleEditRolesModal(false));
  };

  const fields = [{ name: "role", value: data.role }];

  useEffect(() => {
    if (success) {
      message.success("Role has been added");
      dispatch(appUiActions.toggleEditRolesModal(false));
    }
    //if (updateRoleSuccess) message.success("Role has been updated");
  }, [success, dispatch]);
  return (
    <div>
      <Modal
        onCancel={closeEditRoleModal}
        open={visible}
        title="EDIT ROLE"
        footer={null}
      >
        <Form
          fields={fields}
          validateTrigger="onBlur"
          layout="vertical"
          onFinish={onFinish}
        >
          {!success && error && (
            <Alert type="error" message={error} showIcon className="m-2" />
          )}
          <Form.Item
            name="role"
            label="Role"
            rules={[{ required: true, message: "Please add a role" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button
              disabled={data.role === appConfig.systemAdminAccount}
              loading={loading}
              htmlType="submit"
              type="primary"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default EditRole;
