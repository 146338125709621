const initialState = {
  metaData: {
    loading: false,
    success: false,
    data: {},
  },
  fetchAssetCategory: {
    loading: false,
    success: false,
    error: "",
    data: [],
  },
  addAssetTypes: {
    loading: false,
    success: false,
    error: "",
  },
  editAssetTypes: {
    loading: false,
    success: false,
    error: "",
    data: {},
  },
  deleteAssetTypes: {
    loading: false,
    success: false,
    error: "",
  },
  fetchAssets: {
    loading: false,
    success: false,
    error: "",
    data: [],
  },
  addAsset: {
    loading: false,
    success: false,
    error: "",
  },
  editAsset: {
    loading: false,
    success: false,
    error: "",
    data: {},
  },
  deleteAsset: {
    loading: false,
    success: false,
    error: "",
  },
  assetTypesDetails: {
    loading: false,
    success: false,
    error: "",
    data: {},
  },
  assetsDetails: {
    loading: false,
    success: false,
    error: "",
    data: {},
  },
  approveAsset: {
    loading: false,
    succcess: false,
    error: "",
    message: "",
  },
  approveAssetType: {
    loading: false,
    succcess: false,
    error: "",
    message: "",
  },
  checkAsset: {
    loading: false,
    success: false,
    error: "",
    message: "",
  },
  checkAssetType: {
    loading: false,
    success: false,
    error: "",
    message: "",
  },
  assetRegisterGraphData: {
    loading: false,
    success: false,
    error: "",
    data: {},
  },
};

export default initialState;
