import React from "react";
import { Form, Input, Button } from "antd";
import { useSelector } from "react-redux";
import { FcHighPriority } from "react-icons/fc";

const ResetPasswordForm = ({ handlePasswordReset }) => {
  const { loading, success, error } = useSelector(
    (state) => state.authState.resetPassword
  );
  const onFinish = (values) => {
    handlePasswordReset(values);
  };
  return (
    <div className="card-responsive card bg-white shadow-sm w-25  mt-3 ">
      <div className="card-header text-info fw-bolder  bg-white">
        <p className="m-0">RESET YOUR PASSWORD</p>
      </div>
      {!success && error && (
        <div className=" m-1 alert alert-danger">
          <FcHighPriority /> &nbsp;
          {error}
        </div>
      )}
      <div className="card-body">
        <Form onFinish={onFinish} className="text-start" layout="vertical">
          <Form.Item
            hasFeedback
            name="password"
            label="Password"
            rules={[
              { required: true, message: "Please enter a password" },
              {
                min: 8,
                message: "Your password should have more than 8 characted ",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label="Confirm Password"
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item>
            <Button
              htmlType="submit"
              loading={loading}
              className="w-100"
              type="primary"
            >
              SUBMIT
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
