import { Alert, Button, message, Modal, Space, Spin, Tabs } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import RequisitionMemoActivitesTable from "./RequisitionMemoActivitesTable";
import RequisitionMemoOtherDetails from "./RequisitionMemoOtherDetails";
import appConfig from "../../../../config/config.json";
import generateRequisitionMemoPdf from "../../../../config/services/requisitionMemoPdf";
import { getCurrency } from "../../../../config/helpers/app/numberFormater";
import { rejectionsActions } from "../../../../config/actions/rejections/rejections.actions";

const { TabPane } = Tabs;

const RequisitionMemoDetails = ({
  visible,
  handleCloseModal,
  handleApproveRequisitionMemo,
  handleSubmitRequisititionMemo,
  handleCheckRequisitionMemo,
  handleOpenAddActivityModal,
  handleRefreshData,
  handleDeleteActivity,
  handleOpenEditModal,
  handleOpenViewDetails,
}) => {
  const {
    requisitionMemosDetailsLoading,
    requisitionMemosDetailsSuccess,
    requisitionMemosDetailsError,
    requisitionMemosDetails,
    approveRequisitionMemosLoading,
    approveRequisitionMemosSuccess,
    approveRequisitionMemosError,
    approveRequisitionMemosMessage,
    checkRequisitionMemosLoading,
    checkRequisitionMemosSuccess,
    checkRequisitionMemosError,
    checkRequisitionMemosMessage,
    submitRequisitionMemosLoading,
    submitRequisitionMemosSuccess,
    submitRequisitionMemosError,
    submitRequisitionMemosMessage,
  } = useSelector((state) => state.requisitionMemoState);
  const {
    //  addPaymentVouchersLoading,
    addPaymentVouchersSuccess,
    addPaymentVouchersError,
    addPaymentVouchersMessage,
  } = useSelector((state) => state.paymentVouchersState);
  const currency = getCurrency(
    requisitionMemosDetails?.projectDetails,
    "approved_budget"
  );
  const dispatch = useDispatch();
  /* funcs */
  const closeModal = () => handleCloseModal(false);
  const refreshActivities = () => handleRefreshData(requisitionMemosDetails.id);
  const submit = () =>
    handleSubmitRequisititionMemo(requisitionMemosDetails.id);
  const check = () => handleCheckRequisitionMemo(requisitionMemosDetails.id);
  const approve = () =>
    handleApproveRequisitionMemo(requisitionMemosDetails.id);
  const exportRequisitionMemo = () => {
    requisitionMemosDetails.status === appConfig.status.approved
      ? generateRequisitionMemoPdf(requisitionMemosDetails, currency)
      : message.info("Requisition form has not yet been approved");
  };
  const reject = () => {
    dispatch(
      rejectionsActions.toggleRejectionsModal({
        visible: true,
        data: {
          user_to_id: requisitionMemosDetails?.created_by,
          item_application: "Requisition Memos",
          item_id: requisitionMemosDetails?.id,
          itemName: `Requisition Memo ${requisitionMemosDetails.memo_number}`,
        },
      })
    );
  };
  /* use effects */
  useEffect(() => {
    if (approveRequisitionMemosSuccess && approveRequisitionMemosMessage)
      message.success(approveRequisitionMemosMessage);
    else if (!approveRequisitionMemosSuccess && approveRequisitionMemosError)
      message.error(approveRequisitionMemosError);
    if (submitRequisitionMemosSuccess && submitRequisitionMemosMessage)
      message.success(submitRequisitionMemosMessage);
    else if (!submitRequisitionMemosSuccess && submitRequisitionMemosError)
      message.error(submitRequisitionMemosError);
    if (checkRequisitionMemosSuccess && checkRequisitionMemosMessage)
      message.success(checkRequisitionMemosMessage);
    else if (!checkRequisitionMemosSuccess && checkRequisitionMemosError)
      message.error(checkRequisitionMemosError);
    if (addPaymentVouchersSuccess && addPaymentVouchersMessage)
      message.success(addPaymentVouchersMessage);
  }, [
    addPaymentVouchersMessage,
    addPaymentVouchersSuccess,
    approveRequisitionMemosError,
    approveRequisitionMemosMessage,
    approveRequisitionMemosSuccess,
    checkRequisitionMemosError,
    checkRequisitionMemosMessage,
    checkRequisitionMemosSuccess,
    submitRequisitionMemosError,
    submitRequisitionMemosMessage,
    submitRequisitionMemosSuccess,
  ]);
  return (
    <div>
      <Modal
        zIndex={500}
        width={800}
        onCancel={closeModal}
        open={visible}
        title={`REQUISITION FORM - ${
          requisitionMemosDetails.memo_number || "LOADING..."
        }`}
        footer={[
          requisitionMemosDetails.status !== appConfig.status.rejected &&
            requisitionMemosDetails.status !== appConfig.status.approved && (
              <Space>
                {requisitionMemosDetails.submitted && (
                  <Space>
                    <Button
                      onClick={approve}
                      loading={approveRequisitionMemosLoading}
                      type="primary"
                    >
                      Approve
                    </Button>
                    {requisitionMemosDetails.status !==
                      appConfig.status.checked && (
                      <Button
                        onClick={check}
                        loading={checkRequisitionMemosLoading}
                      >
                        Check
                      </Button>
                    )}
                    <Button onClick={reject} danger>
                      Reject
                    </Button>
                  </Space>
                )}
                {!requisitionMemosDetails.submitted && (
                  <Button
                    loading={submitRequisitionMemosLoading}
                    className="bg-light"
                    type="dashed"
                    onClick={submit}
                  >
                    Submit
                  </Button>
                )}
                {/* {requisitionMemosDetails.status !== appConfig.status.approved && (
                <Space>
                  <Button loading={addPaymentVouchersLoading}>
                    Generate Funds Advance/Withdraw Form
                  </Button>
                </Space>
              )} */}
              </Space>
            ),
        ]}
      >
        {requisitionMemosDetailsLoading ? (
          <div className="justify-content-center d-flex m-5">
            <Spin />
          </div>
        ) : (
          <div>
            {!requisitionMemosDetailsSuccess ? (
              <Alert
                type="error"
                showIcon
                message={requisitionMemosDetailsError}
              />
            ) : (
              <div>
                {!addPaymentVouchersSuccess && addPaymentVouchersError && (
                  <Alert showIcon message={addPaymentVouchersError} />
                )}
                <Tabs defaultActiveKey="1">
                  <TabPane tab="Activities" key="1">
                    <RequisitionMemoActivitesTable
                      handleExportRecords={exportRequisitionMemo}
                      handleViewDetails={handleOpenViewDetails}
                      handleOpenEditModal={handleOpenEditModal}
                      handleDelete={handleDeleteActivity}
                      handleRefreshTable={refreshActivities}
                      handleOpenAddModal={handleOpenAddActivityModal}
                    />
                  </TabPane>
                  <TabPane tab="Other Details" key="2">
                    <RequisitionMemoOtherDetails
                      data={requisitionMemosDetails}
                    />
                  </TabPane>
                </Tabs>
              </div>
            )}
          </div>
        )}
      </Modal>
    </div>
  );
};

export default RequisitionMemoDetails;
