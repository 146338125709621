import { message } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AddStaffRecordsForm from "../../../components/administration/StaffRecords/Forms/AddStaffRecords";
import EditStaffRecordsForm from "../../../components/administration/StaffRecords/Forms/EditStaffRecords";
import StaffRecordsTable from "../../../components/administration/StaffRecords/Tables/StaffRecordsTable";
import routes from "../../../config/routes/routes";
import staffRecordsThunks from "../../../config/thunks/administration/staffRecords/staffRecords.thunks";
import logDetailThunks from "../../../config/thunks/settings/logDetails.thunks";

const StaffRecords = () => {
  const {
    success: fetchStaffRecordsSuccess,
    data: StaffRecordsArray,
    error: fetchStaffRecordsError,
  } = useSelector((state) => state.staffRecordsState.staffRecords);
  const { success: addStaffSuccess, message: addStaffSuccessMessage } =
    useSelector((state) => state.staffRecordsState.addStaffRecords);
  const { success: editStaffSuccess, message: editStaffSuccessMessage } =
    useSelector((state) => state.staffRecordsState.updateStaffRecords);
  const {
    success: deleteStaffRecordSuccess,
    error: deleteStaffRecordError,
    message: deleteStaffSuccessMessage,
  } = useSelector((state) => state.staffRecordsState.deleteStaffRecords);
  const history = useHistory();
  const dispatch = useDispatch();
  /** functions  */
  const refreshStaffRecordsTable = () => {
    dispatch(staffRecordsThunks.getStaffRecords());
    if (!fetchStaffRecordsSuccess && fetchStaffRecordsError)
      message.error(fetchStaffRecordsError);
  };
  const exportRecords = () => {
    dispatch(
      logDetailThunks.addLogDetails({
        created_at: new Date(),
        activity: "Exported Staff Records",
        records: "staffRecords",
      })
    );
  };
  const viewStaffDetails = (id) => {
    history.push(`${routes.administration.staffRecords.path}/${id}`);
  };
  const addStaffRecords = (body) => {
    dispatch(staffRecordsThunks.addStaffRecord(body));
  };
  const deleteStaffRecord = (id) => {
    const staffRecords = StaffRecordsArray.filter((e) => e.id !== id);
    dispatch(staffRecordsThunks.deleteStaffRecords(id, staffRecords));
  };
  const editStaffRecords = (body) => {
    const staffRecords = StaffRecordsArray.filter((e) => e.id !== body.id);
    dispatch(staffRecordsThunks.updateStaffRecords(body, staffRecords));
  };
  useEffect(() => {
    document.title = routes.administration.staffRecords.title;
    //get staff records
    if (!fetchStaffRecordsSuccess)
      dispatch(staffRecordsThunks.getStaffRecords());
    //add staff Record
    if (addStaffSuccess && addStaffSuccessMessage)
      message.success(addStaffSuccessMessage);
    //delete Staff Record
    if (deleteStaffRecordSuccess) message.success(deleteStaffSuccessMessage);
    else if (!deleteStaffRecordSuccess && deleteStaffRecordError)
      message.error(deleteStaffRecordError);
    //edit Staff Record
    if (editStaffSuccess && editStaffSuccessMessage)
      message.success(editStaffSuccessMessage);
  }, [
    dispatch,
    fetchStaffRecordsSuccess,
    addStaffSuccess,
    addStaffSuccessMessage,
    deleteStaffSuccessMessage,
    deleteStaffRecordSuccess,
    deleteStaffRecordError,
    editStaffSuccess,
    editStaffSuccessMessage,
  ]);
  return (
    <div className="container-fluid p-2">
      <h5 className="fw-bold">STAFF RECORDS</h5>
      <StaffRecordsTable
        handleDeleteStaffRecords={deleteStaffRecord}
        handleRefreshTable={refreshStaffRecordsTable}
        handleViewDetails={viewStaffDetails}
        handleExportRecords={exportRecords}
      />
      <AddStaffRecordsForm handleAddStaffRecord={addStaffRecords} />
      <EditStaffRecordsForm handleEditStaffRecord={editStaffRecords} />
    </div>
  );
};

export default StaffRecords;
