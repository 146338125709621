import { appUiConstants } from "../../actions/app/appUi.actions";
import initialState from "../../initialState/App/AppUi";

const AppUi = (state = initialState, { type, payload }) => {
  switch (type) {
    case appUiConstants.TOGGLE_FORGOT_PASSWORD_MODAL:
      return {
        ...state,
        auth: {
          ...state.authentication,
          forgotPasswordModalVisible: payload,
        },
      };
    case appUiConstants.TOGGLE_APP_DRAWER:
      return {
        ...state,
        appDrawerVisible: payload,
      };
    case appUiConstants.TOGGLE_ADD_ROLES_MODAL:
      return {
        ...state,
        settings: {
          ...state.settings,
          addRolesModal: payload,
        },
      };
    case appUiConstants.TOGGLE_EDIT_ROLES_MODAL:
      return {
        ...state,
        settings: {
          ...state.settings,
          editRoleModal: payload,
        },
      };
    case appUiConstants.TOGGLE_ADD_ASSET_MODAL:
      return {
        ...state,
        admisnistration: {
          ...state.admisnistration,
          assetsRegister: {
            ...state.admisnistration.assetsRegister,
            addAssetModal: payload,
          },
        },
      };
    case appUiConstants.TOGGLE_EDIT_ASSET_MODAL:
      return {
        ...state,
        admisnistration: {
          ...state.admisnistration,
          assetsRegister: {
            ...state.admisnistration.assetsRegister,
            editAssetModal: payload,
          },
        },
      };
    case appUiConstants.TOGGLE_ADD_PERMISSIONS_MODAL:
      return {
        ...state,
        settings: {
          ...state.settings,
          addPermissonsModal: payload,
        },
      };
    case appUiConstants.TOGGLE_EDIT_PERMISSIONS_MODAL:
      return {
        ...state,
        settings: {
          ...state.settings,
          editPermissonsModal: payload,
        },
      };
    case appUiConstants.TOGGLE_PERMISSION_DETAILS:
      return {
        ...state,
        settings: {
          ...state.settings,
          permissionsDetailsModal: payload,
        },
      };
    case appUiConstants.TOGGLE_CHANGE_PASSWORD_MODAL:
      return {
        ...state,
        settings: {
          ...state.settings,
          changePasswordModal: payload,
        },
      };
    case appUiConstants.TOGGLE_ASSET_TYPES_MODAL:
      return {
        ...state,
        admisnistration: {
          ...state.admisnistration,
          assetsRegister: {
            ...state.admisnistration.assetsRegister,
            assetTypesModal: payload,
          },
        },
      };
    case appUiConstants.TOGGLE_ADD_ASSET_TYPES_MODAL:
      return {
        ...state,
        admisnistration: {
          ...state.admisnistration,
          assetsRegister: {
            ...state.admisnistration.assetsRegister,
            addAssetTypesModal: payload,
          },
        },
      };
    case appUiConstants.TOGGLE_EDIT_ASSET_TYPES_MODAL:
      return {
        ...state,
        admisnistration: {
          ...state.admisnistration,
          assetsRegister: {
            ...state.admisnistration.assetsRegister,
            editAssetTypesModal: payload,
          },
        },
      };
    case appUiConstants.TOGGLE_EDIT_STAFF_RECORDS_MODAL:
      return {
        ...state,
        admisnistration: {
          ...state.admisnistration,
          staffRecords: {
            ...state.admisnistration.staffRecords,
            editStaffModal: payload,
          },
        },
      };
    case appUiConstants.TOGGLE_ADD_STAFF_RECORDS_MODAL:
      return {
        ...state,
        admisnistration: {
          ...state.admisnistration,
          staffRecords: {
            ...state.admisnistration.staffRecords,
            addStaffModal: payload,
          },
        },
      };
    case appUiConstants.TOGGLE_ADD_MANUALS_AND_POLICIES_MODAL:
      return {
        ...state,
        admisnistration: {
          ...state.admisnistration,
          manualsAndPolicies: {
            ...state.admisnistration.manualsAndPolicies,
            addManualsAndPoliciesModal: payload,
          },
        },
      };
    case appUiConstants.TOGGLE_EDIT_MANUALS_AND_POLICIES_MODAL:
      return {
        ...state,
        admisnistration: {
          ...state.admisnistration,
          manualsAndPolicies: {
            ...state.admisnistration.manualsAndPolicies,
            editManualsAndPoliciesModal: payload,
          },
        },
      };
    case appUiConstants.TOGGLE_ADD_STORES_REGISTER_RECEIPT_MODAL:
      return {
        ...state,
        admisnistration: {
          ...state.admisnistration,
          storesRegister: {
            ...state.admisnistration.storesRegister,
            addStoresRegisterReceiptModal: payload,
          },
        },
      };
    case appUiConstants.TOGGLE_EDIT_STORES_REGISTER_RECEIPT_MODAL:
      return {
        ...state,
        admisnistration: {
          ...state.admisnistration,
          storesRegister: {
            ...state.admisnistration.storesRegister,
            editStoresRegisterReceiptModal: payload,
          },
        },
      };
    case appUiConstants.TOGGLE_ADD_STORES_REGISTER_ISSUES_MODAL:
      return {
        ...state,
        admisnistration: {
          ...state.admisnistration,
          storesRegister: {
            ...state.admisnistration.storesRegister,
            addStoresRegisterIssuesModal: payload,
          },
        },
      };
    case appUiConstants.TOGGLE_EDIT_STORES_REGISTER_ISSUES_MODAL:
      return {
        ...state,
        admisnistration: {
          ...state.admisnistration,
          storesRegister: {
            ...state.admisnistration.storesRegister,
            editStoresRegisterIssuesModal: payload,
          },
        },
      };
    case appUiConstants.TOGGLE_VIEW_STORE_REGISTER_RECEIPT_DETAILS_MODAL:
      return {
        ...state,
        admisnistration: {
          ...state.admisnistration,
          storesRegister: {
            ...state.admisnistration.storesRegister,
            viewReceiptDetails: payload,
          },
        },
      };
    case appUiConstants.TOGGLE_VIEW_STORE_REGISTER_ISSUES_DETAILS_MODAL:
      return {
        ...state,
        admisnistration: {
          ...state.admisnistration,
          storesRegister: {
            ...state.admisnistration.storesRegister,
            viewIssuesDetails: payload,
          },
        },
      };
    case appUiConstants.TOGGLE_VIEW_ROLES_MODAL:
      return {
        ...state,
        settings: { ...state.settings, rolesDetailsModal: payload },
      };
    case appUiConstants.TOGGLE_VIEW_ASSET_DETAILS_MODAL:
      return {
        ...state,
        admisnistration: {
          ...state.admisnistration,
          assetsRegister: {
            ...state.admisnistration.assetsRegister,
            viewAssetsDetailsModal: payload,
          },
        },
      };
    case appUiConstants.TOGGLE_VIEW_ASSET_TYPES_DETAILS_MODAL:
      return {
        ...state,
        admisnistration: {
          ...state.admisnistration,
          assetsRegister: {
            ...state.admisnistration.assetsRegister,
            viewAssetTypeDetails: payload,
          },
        },
      };
    case appUiConstants.TOGGLE_VIEW_MANUALS_AND_POLICIES_DETAILS:
      return {
        ...state,
        admisnistration: {
          ...state.admisnistration,
          manualsAndPolicies: {
            ...state.admisnistration.manualsAndPolicies,
            manualsAndPoliciesDetailsModal: payload,
          },
        },
      };
    case appUiConstants.TOGGLE_CHANGE_PROFILE_PICTURE_MODAL:
      return {
        ...state,
        admisnistration: {
          ...state.admisnistration,
          staffRecords: {
            ...state.admisnistration.staffRecords,
            profilePictureModal: payload,
          },
        },
      };
    case appUiConstants.TOGGLE_FILE_VIEWER:
      return {
        ...state,
        fileModalOpen: payload.visible,
        fileSrc: payload.src,
      };
    case appUiConstants.TOGGLE_ADD_PROJECTS_MODAL:
      return {
        ...state,
        addProjectsModal: payload,
      };
    case appUiConstants.TOGGLE_EDIT_PROJECTS_MODAL:
      return {
        ...state,
        editProjectsModal: payload,
      };
    case appUiConstants.TOGGLE_ADD_DONORS_MODAL:
      return {
        ...state,
        addDonorsModal: payload,
      };
    case appUiConstants.TOGGLE_EDIT_DONORS_MODAL:
      return {
        ...state,
        editDonorsModal: payload,
      };
    case appUiConstants.TOGGLE_ADD_CHART_OF_ACCOUNTS_ACCOUNT_MODAL:
      return {
        ...state,
        addCOAAccountModal: payload,
      };
    case appUiConstants.TOGGLE_ADD_CHART_OF_ACCOUNT_ACCOUNT_TYPE_MODAL:
      return {
        ...state,
        addCOAAccountTypeModal: payload,
      };
    case appUiConstants.TOGGLE_EDIT_CHART_OF_ACCOUNTS_ACCOUNT_MODAL:
      return {
        ...state,
        editAccountsModal: payload,
      };
    case appUiConstants.TOGGLE_EDIT_CHART_OF_ACCOUNT_ACCOUNT_TYPE_MODAL:
      return {
        ...state,
        editAccountTypesModal: payload,
      };
    case appUiConstants.TOGGLE_CHART_OF_ACCOUNTS_ACCOUNT_DETAILS_MODAL:
      return {
        ...state,
        accountDetailsModal: payload,
      };
    case appUiConstants.TOGGLE_CHART_OF_ACCOUNT_ACCOUNT_TYPE_DETAILS_MODAL:
      return {
        ...state,
        accountTypeDetailsModal: payload,
      };
    case appUiConstants.TOGGLE_SEARCH_CHART_OF_ACCOUNTS_MODAL:
      return {
        ...state,
        searchChartOfAccountsModal: payload,
      };
    case appUiConstants.TOGGLE_SET_PERSON_INCHARGE_MODAL:
      return {
        ...state,
        setPersonInchargeModal: payload,
      };
    case appUiConstants.TOGGLE_ADD_BUDGET_MODAL:
      return {
        ...state,
        addBudgetModal: payload,
      };
    case appUiConstants.TOGGLE_ADD_BUDGET_LINE_MODAL:
      return {
        ...state,
        addBudgetLineModal: payload,
      };
    case appUiConstants.TOGGLE_ADD_BUDGET_ACTIVITY_MODAL:
      return {
        ...state,
        addBudgetActivityModal: payload,
      };
    case appUiConstants.TOGGLE_EDIT_BUDGET_LINE_MODAL:
      return {
        ...state,
        editBudgetLineModal: payload,
      };
    case appUiConstants.TOGGLE_EDIT_BUDGET_ACTIVITY_MODAL:
      return {
        ...state,
        editBudgetActivity: payload,
      };
    case appUiConstants.TOGGLE_UPLOAD_SIGNATURE_MODAL:
      return {
        ...state,
        admisnistration: {
          ...state.admisnistration,
          staffRecords: {
            ...state.admisnistration.staffRecords,
            signatureModal: payload,
          },
        },
      };
    default:
      return state;
  }
};

export default AppUi;
