import { Table } from "antd";
import React from "react";
import { numberWithCommas } from "../../../../config/helpers/app/numberFormater";

const DonorProjects = ({ data }) => {
  const columns = [
    {
      title: "Project",
      key: "projects",
      render: (text) => (
        <div>
          <span className="d-md-none">Project:</span> {text.name} <br />
          <div className="d-md-none">
            Approved Budget: {numberWithCommas(parseInt(text.approved_budget))}{" "}
            <br />
            Start Date: {`${new Date(text.start_date).toDateString()}`} <br />
            End Date: {`${new Date(text.end_date).toDateString()}`} <br />
            Bank: {text.bank} <br />
            Account Number: {text.account_number} <br />
          </div>
        </div>
      ),
    },
    {
      title: "Approved Budget",
      dataIndex: "approved_budget",
      key: "approved_budget",
      responsive: ["md"],
      render: (text) => {
        try {
          let currency = text[text?.length - 1];
          return (
            <>{`${currency === "D" ? "USD" : "UGX"} ${numberWithCommas(
              parseInt(text)
            )}`}</>
          );
        } catch (error) {
          return "";
        }
      },
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      render: (text) => `${new Date(text).toDateString()}`,
      responsive: ["md"],
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
      render: (text) => `${new Date(text).toDateString()}`,
      responsive: ["md"],
    },
    { title: "Bank", dataIndex: "bank", key: "bank", responsive: ["lg"] },
    { title: "Status", dataIndex: "status", key: "status", responsive: ["lg"] },
    {
      title: "Account Number",
      dataIndex: "account_number",
      key: "account_number",
      responsive: ["md"],
    },
  ];
  return (
    <div>
      <Table dataSource={data} columns={columns} bordered={true} />
    </div>
  );
};

export default DonorProjects;
