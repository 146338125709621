export const donorsConstants = {
  SET_DONORS_REQUEST: "SET_DONORS_REQUEST",
  SET_DONORS_SUCCESS: "SET_DONORS_SUCCESS",
  SET_DONORS_ERROR: "SET_DONORS_ERROR",

  SET_DONORS_GRAPH_DATA_REQUEST: "SET_DONORS_GRAPH_DATA_REQUEST",
  SET_DONORS_GRAPH_DATA_SUCCESS: "SET_DONORS_GRAPH_DATA_SUCCESS",
  SET_DONORS_GRAPH_DATA_ERROR: "SET_DONORS_GRAPH_DATA_ERROR",

  SET_DONORS_DETAILS_REQUEST: "SET_DONORS_DETAILS_REQUEST",
  SET_DONORS_DETAILS_SUCCESS: "SET_DONORS_DETAILS_SUCCESS",
  SET_DONORS_DETAILS_ERROR: "SET_DONORS_DETAILS_ERROR",

  ADD_DONOR_REQUEST: "ADD_PROJECT_REQUEST",
  ADD_DONOR_SUCCESS: "ADD_PROJECT_SUCCESS",
  ADD_DONOR_ERROR: "ADD_PROJECT_ERROR",
  ADD_DONOR_COMPLETE: "ADD_PROJECT_COMPLETE",

  EDIT_DONOR_REQUEST: "EDIT_DONORS_REQUEST",
  EDIT_DONOR_ERROR: "EDIT_DONORS_ERROR",
  EDIT_DONOR_SUCCESS: "EDIT_DONORS_SUCCESS",
  EDIT_DONOR_COMPLETE: "EDIT_DONORS_COMPLETE",
  EDIT_DONOR_UPDATE_DATA: "EDIT_DONORS_UPDATE_DATA",

  DELETE_DONOR_REQUEST: "DELETE_DONOR_REQUEST",
  DELETE_DONOR_SUCCESS: "DELETE_DONOR_SUCCESS",
  DELETE_DONOR_ERROR: "DELETE_DONOR_ERROR",
  DELETE_DONOR_COMPLETE: "DELETE_DONOR_COMPLETE",

  APPROVE_DONOR_REQUEST: "APPROVE_DONOR_REQUEST",
  APPROVE_DONOR_SUCCESS: "APPROVE_DONOR_SUCCESS",
  APPROVE_DONOR_ERROR: "APPROVE_DONOR_ERROR",
  APPROVE_DONOR_COMPLETE: "APPROVE_DONOR_COMPLETE",

  CHECK_DONOR_REQUEST: "CHECK_DONOR_REQUEST",
  CHECK_DONOR_SUCCESS: "CHECK_DONOR_SUCCESS",
  CHECK_DONOR_ERROR: "CHECK_DONOR_ERROR",
  CHECK_DONOR_COMPLETE: "CHECK_DONOR_COMPLETE",

  SET_PERSON_INCHARGE_LOADING: "SET_PERSON_INCHARGE_LOADING",
  SET_PERSON_INCHARGE_SUCCESS: "SET_PERSON_INCHARGE_SUCCESS",
  SET_PERSON_INCHARGE_ERROR: "SET_PERSON_INCHARGE_ERROR",
  SET_PERSON_INCHARGE_COMPLETE: "SET_PERSON_INCHARGE_COMPLETE",

  SUBMIT_PROPOSAL_STATUS_LOADING: "SUBMIT_PROPOSAL_STATUS_LOADING",
  SUBMIT_PROPOSAL_STATUS_SUCCESS: "SUBMIT_PROPOSAL_STATUS_SUCCESS",
  SUBMIT_PROPOSAL_STATUS_ERROR: "SUBMIT_PROPOSAL_STATUS_ERROR",
  SUBMIT_PROPOSAL_STATUS_COMPLETE: "SUBMIT_PROPOSAL_STATUS_COMPLETE",
};

export const donorsActions = {
  setDonorsRequest: () => ({
    type: donorsConstants.SET_DONORS_REQUEST,
  }),
  setDonorsSuccess: (payload) => ({
    type: donorsConstants.SET_DONORS_SUCCESS,
    payload,
  }),
  setDonorsError: (payload) => ({
    type: donorsConstants.SET_DONORS_ERROR,
    payload,
  }),
  setDonorsGraphDataRequest: () => ({
    type: donorsConstants.SET_DONORS_GRAPH_DATA_REQUEST,
  }),
  setDonorsGraphDataSuccess: (payload) => ({
    type: donorsConstants.SET_DONORS_GRAPH_DATA_SUCCESS,
    payload,
  }),
  setDonorsGraphDataError: (payload) => ({
    type: donorsConstants.SET_DONORS_GRAPH_DATA_ERROR,
    payload,
  }),
  setDonorDetailsRequest: () => ({
    type: donorsConstants.SET_DONORS_DETAILS_REQUEST,
  }),
  setDonorDetailsSuccess: (payload) => ({
    type: donorsConstants.SET_DONORS_DETAILS_SUCCESS,
    payload,
  }),
  setDonorDetailsError: (payload) => ({
    type: donorsConstants.SET_DONORS_DETAILS_ERROR,
    payload,
  }),
  addDonorsRequest: () => ({
    type: donorsConstants.ADD_DONOR_REQUEST,
  }),
  addDonorSuccess: (payload) => ({
    type: donorsConstants.ADD_DONOR_SUCCESS,
    payload,
  }),
  addDonorsError: (payload) => ({
    type: donorsConstants.ADD_DONOR_ERROR,
    payload,
  }),
  addDonorsComplete: () => ({
    type: donorsConstants.ADD_DONOR_COMPLETE,
  }),
  editDonorsRequest: () => ({
    type: donorsConstants.EDIT_DONOR_REQUEST,
  }),
  editDonorSuccess: (payload) => ({
    type: donorsConstants.EDIT_DONOR_SUCCESS,
    payload,
  }),
  editDonorsError: (payload) => ({
    type: donorsConstants.EDIT_DONOR_ERROR,
    payload,
  }),
  editDonorsComplete: () => ({
    type: donorsConstants.EDIT_DONOR_COMPLETE,
  }),
  setEditData: (payload) => ({
    type: donorsConstants.EDIT_DONOR_UPDATE_DATA,
    payload,
  }),
  approveDonorsRequest: () => ({
    type: donorsConstants.APPROVE_DONOR_REQUEST,
  }),
  approveDonorSuccess: (payload) => ({
    type: donorsConstants.APPROVE_DONOR_SUCCESS,
    payload,
  }),
  approveDonorsError: (payload) => ({
    type: donorsConstants.APPROVE_DONOR_ERROR,
    payload,
  }),
  approveDonorsComplete: () => ({
    type: donorsConstants.APPROVE_DONOR_COMPLETE,
  }),
  checkDonorsRequest: () => ({
    type: donorsConstants.CHECK_DONOR_REQUEST,
  }),
  checkDonorSuccess: (payload) => ({
    type: donorsConstants.CHECK_DONOR_SUCCESS,
    payload,
  }),
  checkDonorsError: (payload) => ({
    type: donorsConstants.CHECK_DONOR_ERROR,
    payload,
  }),
  checkDonorsComplete: () => ({
    type: donorsConstants.CHECK_DONOR_COMPLETE,
  }),
  deleteDonorsRequest: () => ({
    type: donorsConstants.DELETE_DONOR_REQUEST,
  }),
  deleteDonorSuccess: (payload) => ({
    type: donorsConstants.DELETE_DONOR_SUCCESS,
    payload,
  }),
  deleteDonorsError: (payload) => ({
    type: donorsConstants.DELETE_DONOR_ERROR,
    payload,
  }),
  deleteDonorsComplete: () => ({
    type: donorsConstants.DELETE_DONOR_COMPLETE,
  }),
  setPersonInchargeDonorsLoading: () => ({
    type: donorsConstants.SET_PERSON_INCHARGE_LOADING,
  }),
  setPersonInchargeDonorSuccess: (payload) => ({
    type: donorsConstants.SET_PERSON_INCHARGE_SUCCESS,
    payload,
  }),
  setPersonInchargeDonorsError: (payload) => ({
    type: donorsConstants.SET_PERSON_INCHARGE_ERROR,
    payload,
  }),
  setPersonInchargeDonorsComplete: () => ({
    type: donorsConstants.SET_PERSON_INCHARGE_COMPLETE,
  }),
  submitProposalLoading: () => ({
    type: donorsConstants.SUBMIT_PROPOSAL_STATUS_LOADING,
  }),
  submitProposalSuccess: (payload) => ({
    type: donorsConstants.SUBMIT_PROPOSAL_STATUS_SUCCESS,
    payload,
  }),
  submitProposalComplete: () => ({
    type: donorsConstants.SUBMIT_PROPOSAL_STATUS_COMPLETE,
  }),
  submitProposalError: (payload) => ({
    type: donorsConstants.SUBMIT_PROPOSAL_STATUS_ERROR,
    payload,
  }),
};
