const initialState = {
  requisitionMemos: [],
  requisitionMemosLoading: false,
  requisitionMemosSuccess: false,
  requisitionMemosError: "",

  requisitionMemosDetailsLoading: false,
  requisitionMemosDetailsSuccess: false,
  requisitionMemosDetailsError: "",
  requisitionMemosDetails: {},

  requisitionMemosActivityDetailsLoading: false,
  requisitionMemosActivityDetailsSuccess: false,
  requisitionMemosActivityDetailsError: "",
  requisitionMemosActivityDetails: {},

  addRequisitionMemosSuccess: false,
  addRequisitionMemosLoading: false,
  addRequisitionMemosError: "",
  addRequisitionMemosMessage: "",

  addRequisitionMemosActivitySuccess: false,
  addRequisitionMemosActitvityError: "",
  addRequisitionMemosactivityLoading: false,
  addRequisitionMemosActivityMessage: "",

  editRequisitionMemoSuccess: false,
  editRequisitionMemoError: "",
  editRequisitionMemoLoading: false,
  editRequisitionMemoMessage: "",
  editRequisitionMemoData: {},

  editRequisitionMemosActitvitySuccess: false,
  editRequisitionMemosActitvityError: "",
  editRequisitionMemosActitvityLoading: false,
  editRequisitionMemosActitvityMessage: "",
  editRequisitionMemosActitvityData: {},

  deleteRequisitionMemosActivityLoading: false,
  deleteRequisitionMemosActivitySuccess: false,
  deleteRequisitionMemosActivityError: "",
  deleteRequisitionMemosActivityMessage: "",

  deleteRequisitionMemosLoading: false,
  deleteRequisitionMemosSuccess: false,
  deleteRequisitionMemosError: "",
  deleteRequisitionMemosMessage: "",

  approveRequisitionMemosLoading: false,
  approveRequisitionMemosSuccess: false,
  approveRequisitionMemosError: "",
  approveRequisitionMemosMessage: "",

  checkRequisitionMemosLoading: false,
  checkRequisitionMemosSuccess: false,
  checkRequisitionMemosError: "",
  checkRequisitionMemosMessage: "",

  submitRequisitionMemosLoading: false,
  submitRequisitionMemosSuccess: false,
  submitRequisitionMemosError: "",
  submitRequisitionMemosMessage: "",

  projectRequistionMemos: [],
  projectRequistionMemosLoading: false,
  projectRequistionMemosSuccess: false,
  projectRequistionMemosError: "",

  metaData: {},
  metaDataLoading: false,
};

export default initialState;
