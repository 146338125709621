import { create } from "zustand";
import { devtools, subscribeWithSelector } from "zustand/middleware";
import { produce } from "immer";
import axios from "axios";

const initialState = {
  gettingAccounts: false,
  accountsError: null,
  accounts: [],

  creatingAccount: false,
  createAccountError: null,
  createdAccount: {},
  showCreateAccount: false,

  updatingAccount: false,
  updateAccountError: null,
  updatedAccount: null,
  accountToEdit: null,
  showUpdateAccounts: false,

  deletingAccount: false,
  deleteAccountError: null,
  deletedAccount: null,

  gettingAccountDetails: false,
  accountDetailsError: null,
  accountDetails: null,
  showAccountDetails: false,

  creating: false,
};

const store = (set) => ({
  ...initialState,

  // actions
  async getAccounts() {
    set(
      produce((state) => {
        state.gettingAccounts = true;
        state.accountsError = null;
      })
    );
    try {
      const res = await axios({
        method: "GET",
        url: "/finance/accounts",
      });

      set(
        produce((state) => {
          state.accounts = res;
          state.gettingAccounts = false;
        })
      );
    } catch (error) {
      set(
        produce((state) => {
          state.accountsError = error;
          state.gettingAccounts = false;
        })
      );
    }
  },

  async createAccount(body) {
    set(
      produce((state) => {
        state.creatingAccount = true;
        state.createAccountError = null;
        state.createdAccount = null;
      })
    );
    try {
      const res = await axios({
        method: "POST",
        url: "/finance/accounts",
        data: body,
      });

      set(
        produce((state) => {
          state.createdAccount = res || null;
          state.creatingAccount = false;
        })
      );
    } catch (error) {
      set(
        produce((state) => {
          state.createAccountError = error;
          state.creatingAccount = false;
        })
      );
    }
  },

  async updateAccount(body, id) {
    set(
      produce((state) => {
        state.updatingAccount = true;
        state.updateAccountError = null;
        state.updatedAccount = null;
      })
    );
    try {
      const res = await axios({
        method: "PUT",
        url: `/finance/accounts/${id}`,
        data: body,
      });

      set(
        produce((state) => {
          state.updatedAccount = res || null;
          state.updatingAccount = false;
        })
      );
    } catch (error) {
      set(
        produce((state) => {
          state.updateAccountError = error;
          state.updatingAccount = false;
        })
      );
    }
  },

  async deleteAccount(id) {
    set(
      produce((state) => {
        state.deletingAccount = true;
        state.deleteAccountError = null;
        state.deletedAccount = null;
      })
    );
    try {
      const res = await axios({
        method: "DELETE",
        url: `/finance/accounts/${id}`,
      });

      console.log({ res });
      set(
        produce((state) => {
          state.deletedAccount = res || null;
          state.deletingAccount = false;
        })
      );
    } catch (error) {
      set(
        produce((state) => {
          state.deleteAccountError = error;
          state.deletingAccount = false;
        })
      );
    }
  },

  async getAccountDetails(id) {
    set(
      produce((state) => {
        state.gettingAccountDetails = true;
        state.accountDetailsError = null;
      })
    );
    try {
      const res = await axios({
        method: "GET",
        url: `/finance/accounts/${id}`,
      });

      set(
        produce((state) => {
          state.accountDetails = res || null;
          state.gettingAccountDetails = false;
        })
      );
    } catch (error) {
      set(
        produce((state) => {
          state.accountDetailsError = error;
          state.gettingAccountDetails = false;
        })
      );
    }
  },

  setShowCreateAccounts(show) {
    set(
      produce((state) => {
        state.showCreateAccount = show;
      })
    );
  },
  setUpdateAccountData(data) {
    set(
      produce((state) => {
        state.accountToEdit = data;
      })
    );
  },
  setCreating(show = false, creating = false, dataToEdit = {}) {
    set(
      produce((state) => {
        state.creating = creating;
        state.showCreateAccount = show;
        state.accountToEdit = dataToEdit;
      })
    );
  },
  setShowViewAccountDetails(show) {
    set(
      produce((state) => {
        state.showAccountDetails = show;
      })
    );
  },
});

const storeName = "ACCOUNTS STORE";

const useAccountsStore = create(
  devtools(subscribeWithSelector(store), { name: storeName }),
  {
    name: storeName,
  }
);

useAccountsStore.subscribe(
  (state) => state.createdAccount,
  (account) => {
    console.log(account);
    if (account) {
      useAccountsStore.getState().getAccounts();
    }
  }
);

useAccountsStore.subscribe(
  (state) => state.deletedAccount,
  (account) => {
    if (account) {
      useAccountsStore.getState().getAccounts();
    }
  }
);

useAccountsStore.subscribe(
  (state) => state.updatedAccount,
  (account) => {
    if (account) {
      useAccountsStore.getState().getAccounts();
      useAccountsStore.getState().setCreating(false);
    }
  }
);

export default useAccountsStore;
