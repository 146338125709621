import React, { useEffect } from "react";
import { Button } from "antd";
import AuthLogo from "../../components/auth/images/AuthLogo";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import authThunks from "../../config/thunks/auth/auth.thunk";
import { useHistory } from "react-router";
import { FcApproval, FcHighPriority } from "react-icons/fc";

const ConfirmAccount = () => {
  const { loading, success, error, message } = useSelector(
    (state) => state.authState.confirmAccount
  );
  const history = useHistory();
  const pathArray = history.location.pathname.split("/");
  const token = pathArray[pathArray.length - 1];
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authThunks.confirmAccount(token));
  }, [dispatch, token]);

  const gotoLogin = () => {
    history.push("/login");
  };

  return (
    <div className="bg-light p-4 min-vh-100 text-center ">
      <div className="container-fluid">
        <div className="d-flex flex-column align-items-center justify-content-center">
          <AuthLogo />
          {loading ? (
            <Spin className="mt-5" size="large" />
          ) : (
            <>
              <div className="card card-responsive bg-white w-25">
                {success ? (
                  <>
                    <h3 className="text-success">
                      <FcApproval /> <br /> {message}
                    </h3>
                    <div className="card card-responsive body p-1 text-center">
                      Click the Button below to login in to your account
                      <Button
                        onClick={gotoLogin}
                        size="large"
                        className="m-4"
                        type="primary"
                      >
                        Login
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <h3 className="text-danger p-3">
                      <FcHighPriority /> <br /> {error}
                    </h3>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConfirmAccount;
