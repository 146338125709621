import { Button, message, Popover, Table } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { storesRegisterActions } from "../../../../config/actions/administration/storesRegister/storesRegister.actions";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";
import StoresRegisterExpandedTable from "./StoresRegisterExpandedTable";
import storesRegisterThunks from "../../../../config/thunks/administration/storesRegister/storesRegister.thunks";
import generatePDF from "../../../../config/services/generatePdf";
import TableTitle from "../../../shared/Tables/TableTitle";
import { numberWithCommas } from "../../../../config/helpers/app/numberFormater";
import tableButtonActions from "../../../shared/Tables/TableButtonActions";

function StoresRegisterTable({
  handleRefreshTable,
  handleDeleteStoresReceipt,
  handleDeleteStoresRegisterIssue,
  handleExportRecords,
}) {
  const { loading, data } = useSelector(
    (state) => state.storesRegisterState.storesRegister
  );
  const [deleteId, setDeleteId] = useState("");
  const deleteLoading = useSelector(
    (state) => state.storesRegisterState.deleteStoresRegisterReceipt.loading
  );
  const deleteStoresRegisterIssueLoading = useSelector(
    (state) => state.storesRegisterState.deleteStoresRegisterIssue.loading
  );
  const {
    success: logDetailsSuccess,
    error: logDetailsError,
    records,
  } = useSelector((state) => state.logDetailsState.addLogDetails);
  const [filterTable, setfilterTable] = useState(null);
  const dispatch = useDispatch();
  /** fucntions */
  const search = (value) => {
    const filterTable = data.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(value.toLowerCase())
      )
    );
    setfilterTable(filterTable);
  };
  const deleteRecord = (e) => {
    setDeleteId(e.target.id);
  };
  const confirmDelete = () => handleDeleteStoresReceipt(deleteId);
  const refreshTable = () => handleRefreshTable();
  const viewDetails = (e) => {
    dispatch(appUiActions.toggleStoresRegisterReceptDetailsModal(true));
    dispatch(storesRegisterThunks.storesRegisterReceiptDetails(e.target.id));
  };

  const updateRecord = (e) => {
    const updateRecordArray = data.filter((r) => r.id === e.target.id);
    dispatch(
      storesRegisterActions.setStoresRegisterReceiptUpdateData(
        updateRecordArray[0]
      )
    );
    dispatch(appUiActions.toggleEditStoresRegisterReceiptModal(true));
  };
  const expandedTableConfig = {
    deleteRecord,
    viewDetails: (id) => {
      dispatch(appUiActions.toggleStoresRegisterIssuesDetailsModal(true));
      dispatch(storesRegisterThunks.storesRegisterIssuesDetails(id));
    },
    confirmDelete: () => handleDeleteStoresRegisterIssue(deleteId),
    updateRecord: (record, id) => {
      const updateRecordArray = data.filter((r) => r.id === record.id);
      const issues = updateRecordArray[0].issues;
      const issue = issues.filter((issue) => {
        return issue.id === id;
      });
      issue[0].name = record.name;
      dispatch(appUiActions.toggleEditStoresRegisterIssuesModal(true));
      dispatch(
        storesRegisterActions.setStoresRegisterIssueUpdateData(issue[0])
      );
    },
    deleteStoresRegisterIssueLoading,
  };
  const exportRecords = () => {
    message.loading("Proccessing document for export please wait ", 3);
    handleExportRecords();
  };
  const openAddModal = () =>
    dispatch(appUiActions.toggleAddStoresRegisterReceiptModal(true));
  useEffect(() => {
    if (logDetailsSuccess && records === "storesRegister") {
      message.info("Started download");
      generateRecordsPdf();
    } else if (!logDetailsSuccess && logDetailsError)
      message.error(logDetailsError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logDetailsSuccess, logDetailsError]);
  const generateRecordsPdf = () => {
    const pdfColumns = [
      "Item",
      "Rate",
      "Balance",
      "Issued To",
      "Units Delivered",
      "Date Of Issue",
      "Total Cost",
      "Remarks",
    ];
    let pdfRows = [];
    data.forEach((record) => {
      const row = [
        record.name,
        record.rate,
        record.balance,
        "Amani Intiative",
        record.quantity,
        new Date(record.issue_date).toLocaleDateString(),
        record.total_price,
        record.remarks,
      ];
      pdfRows.push(row);
      record.issues.forEach((r) => {
        const row = [
          "",
          "",
          "",
          r.issued_to_name,
          r.quantity,
          new Date(r.issue_date).toLocaleDateString(),
          r.total_price,
          r.remarks,
        ];
        pdfRows.push(row);
      });
    });
    generatePDF(
      pdfRows,
      pdfColumns,
      `Amani Intitiative Stores Register - ${new Date().toLocaleDateString()} `,
      "Amani Intiative Stores Register"
    );
  };
  const showTotal = (total) => `Total: ${total}`;
  const columns = [
    {
      title: "Item",
      key: "name",
      render: (text) => (
        <div>
          <span className="d-md-none">Item:</span> {text.name} <br />
          <div className="d-md-none">
            Units Delivered: {text.quantity} <br />
            Balance: {text.balance} <br />
            Date Of Issue: {new Date(text.issue_date).toDateString()} <br />
            Rate: {`${numberWithCommas(parseInt(text.rate))}`} <br />
            Total Cost: {`${numberWithCommas(parseInt(text.total_price))}`}{" "}
            <br />
            <Popover
              className=""
              trigger="click"
              placement="bottom"
              content={() =>
                tableButtonActions(text, {
                  viewDetails,
                  updateRecord,
                  confirmDelete,
                  deleteRecord,
                  deleteLoading,
                  deleteId,
                })
              }
            >
              Actions: <Button type="link">Actions</Button>
            </Popover>
          </div>
        </div>
      ),
    },
    {
      title: "Units Delivered",
      dataIndex: "quantity",
      key: "quantity",
      responsive: ["md"],
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      responsive: ["md"],
    },
    {
      title: "Date Of Issue",
      dataIndex: "issue_date",
      key: "issue_date",
      render: (text) => <>{new Date(text).toDateString()}</>,
      responsive: ["md"],
    },
    {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",
      responsive: ["md"],
      render: (text) => `${numberWithCommas(text)}`,
    },
    {
      title: "Total Cost",
      dataIndex: "total_price",
      key: "total_price",
      render: (text) => numberWithCommas(text),
      responsive: ["md"],
    },
    {
      title: "Action",
      key: "operation",
      render: (text) => (
        <Popover
          trigger="click"
          placement="bottom"
          content={() =>
            tableButtonActions(text, {
              viewDetails,
              updateRecord,
              confirmDelete,
              deleteRecord,
              deleteLoading,
              deleteId,
            })
          }
        >
          <Button>Actions</Button>
        </Popover>
      ),
      responsive: ["md"],
    },
  ];

  return (
    <Table
      rowKey={(r) => r.id}
      pagination={{ total: data.length, showSizeChanger: true, showTotal }}
      bordered
      title={() =>
        TableTitle({
          search,
          setfilterTable,
          refreshTable,
          exportRecords,
          openAddModal,
        })
      }
      loading={loading}
      className="components-table-demo-nested"
      columns={columns}
      expandable={{
        expandedRowRender: (record) =>
          StoresRegisterExpandedTable(record, expandedTableConfig),
      }}
      dataSource={filterTable === null ? data : filterTable}
    />
  );
}

export default StoresRegisterTable;
