import React from "react";
import PrintOutHeader from "../../../shared/PrintOutHeader";
import { Table } from "antd";
import PrintOutUserDetails from "../../../shared/PrintOutUserDetails";

const PrintRecords = ({ data = {} }) => {
  const columns = [
    {
      title: "Name",
      key: "id",
      dataIndex: "name",
    },
    {
      title: "Date Of Submission",
      key: "time_of_submission",
      dataIndex: "time_of_submission",
      render: (text) => `${new Date(text).toDateString()}`,
      responsive: ["md"],
    },
    {
      title: "Time Of Submission",
      key: "time_of_submission",
      dataIndex: "time_of_submission",
      render: (text) => `${new Date(text).toLocaleTimeString()}`,
      responsive: ["md"],
    },
  ];
  return (
    <div className="border p-2 print-table">
      <PrintOutHeader />
      <Table
        title={() => (
          <div className="w-100 d-flex justify-content-center">
            <h6 className="fw-bold w-100 text-center my-1">
              RECORD OF BIDDERS
            </h6>
          </div>
        )}
        size="small"
        bordered
        className="my-2"
        showHeader={false}
        pagination={false}
        columns={[
          {
            dataIndex: "title",
            key: "title",
          },
          {
            dataIndex: "value",
            key: "value",
            render: (text) => <strong>{text}</strong>,
          },
        ]}
        dataSource={[
          {
            title: "RFQ No.",
            value: data.rfqDetails?.rfqno,
          },
          {
            title: "DEADLINE OF SUBMISSION",
            value: new Date(
              data.rfqDetails?.deadline_of_submission
            ).toDateString(),
          },
          {
            title: "SUBJECT OF PROCUREMENT",
            value: data.rfqDetails?.subject,
          },
        ]}
      />
      <Table
        size="small"
        pagination={false}
        title={() => <span className="fw-bold">BIDDERS</span>}
        bordered={true}
        dataSource={data.bidders}
        columns={columns}
      />
      <PrintOutUserDetails data={data} />
    </div>
  );
};

export default PrintRecords;
