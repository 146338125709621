const initialState = {
  paymentVouchers: [],
  paymentVouchersLoading: false,
  paymentVouchersError: "",
  paymentVouchersSuccess: false,

  addPaymentVouchersLoading: false,
  addPaymentVouchersMessage: "",
  addPaymentVouchersSuccess: false,
  addPaymentVouchersError: "",

  paymentVouchersDetails: {},
  paymentVouchersDetailsSuccess: false,
  paymentVouchersDetailsLoading: false,
  paymentVouchersDetailsError: "",

  editPaymentVouchersSuccess: false,
  editPaymentVouchersLoading: false,
  editPaymentVouchersData: {},
  editPaymentVouchersError: "",
  editPaymentVouchersMessage: "",

  deletePaymentVouchersLoading: false,
  deletePaymentVouchersSuccess: false,
  deletePaymentVouchersMessage: "",
  deletePaymentVouchersError: "",

  approvePaymentVouchersLoading: false,
  approvePaymentVouchersError: "",
  approvePaymentVouchersSuccess: false,
  approvePaymentVouchersMessage: "",

  checkPaymentVouchersLoading: false,
  checkPaymentVouchersSuccess: false,
  checkPaymentVouchersMessage: "",
  checkPaymentVouchersError: "",

  paymentVouchersMetaDataLoading: false,
  paymentVouchersMetaData: {},
};

export default initialState;
