import { Table } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { bidEvaluationsActions } from "../../../../config/actions/procument/bidEvaluations/bidEvaluations.actions";
import { numberWithCommas } from "../../../../config/helpers/app/numberFormater";

const FinancialBidderEvaluationsTable = ({
  evaluationItemDetails,
  handleOpenEvaluationModal,
}) => {
  const dispatch = useDispatch();
  const openEvaluateModal = (e) => {
    const evaluation = evaluationItemDetails?.evaluations?.filter(
      (evaluation) => evaluation.id === e.target.id
    );
    handleOpenEvaluationModal(true);
    dispatch(bidEvaluationsActions.editFinancialEvaluationsData(evaluation[0]));
  };

  const columns = [
    { title: "Bidder", dataIndex: "name", key: "name" },
    {
      title: "Units",
      dataIndex: "units",
      key: "units",
      render: (text) => numberWithCommas(parseInt(text)),
    },
    {
      title: "Unit Cost",
      dataIndex: "unit_cost",
      key: "unit_cost",
      render: (text) => numberWithCommas(parseInt(text)),
    },
    {
      title: "Total Cost",
      dataIndex: "total_cost",
      key: "total_cost",
      render: (text) => numberWithCommas(parseInt(text)),
    },
    {
      title: "Actions",
      render: (text) => (
        <button onClick={openEvaluateModal} id={text.id} className="ant-btn">
          Evaluate
        </button>
      ),
    },
  ];
  return (
    <div>
      <Table
        bordered
        columns={columns}
        rowKey={(r) => r.id}
        dataSource={evaluationItemDetails?.evaluations}
        scroll={{ x: true }}
      />
    </div>
  );
};

export default FinancialBidderEvaluationsTable;
