import { create } from "zustand";
import { devtools, subscribeWithSelector } from "zustand/middleware";
import { produce } from "immer";
import axios from "axios";

const initialState = {
  gettingReceipts: false,
  receiptsError: null,
  receipts: [],

  creatingReceipt: false,
  createReceiptError: null,
  createdReceipt: {},
  showCreateReceipt: false,

  updatingReceipt: false,
  updateReceiptError: null,
  updatedReceipt: null,
  receiptToEdit: null,
  showUpdateReceipt: false,

  deletingReceipt: false,
  deleteReceiptError: null,
  deletedReceipt: null,

  gettingReceiptDetails: false,
  receiptDetailsError: null,
  receiptDetails: null,
  showReceiptDetails: false,

  creating: false,

  metaData: {},
  gettingMetaData: false,

  filteringReceipts: false,
  filteredReceipts: [],
  filteredReceiptsError: null,
};

const store = (set) => ({
  ...initialState,

  // actions
  async getReceipts() {
    set(
      produce((state) => {
        state.gettingReceipts = true;
        state.receiptsError = null;
      })
    );
    try {
      const res = await axios({
        method: "GET",
        url: "/finance/receipts",
      });

      set(
        produce((state) => {
          state.receipts = res;
          state.gettingReceipts = false;
        })
      );
    } catch (error) {
      set(
        produce((state) => {
          state.receiptsError = error;
          state.gettingReceipts = false;
        })
      );
    }
  },

  async createReceipt(data) {
    set(
      produce((state) => {
        state.creatingReceipt = true;
        state.createdReceipt = null;
        state.createReceiptError = null;
      })
    );
    try {
      await axios({
        method: "POST",
        url: "/finance/receipts",
        data,
      });

      set(
        produce((state) => {
          state.createdReceipt = data;
          state.creatingReceipt = false;
          state.showCreateReceipt = false;
        })
      );
    } catch (error) {
      set(
        produce((state) => {
          state.createReceiptError = error;
          state.creatingReceipt = false;
        })
      );
    }
  },

  async updateReceipt(data, id) {
    set(
      produce((state) => {
        state.updatingReceipt = true;
        state.updatedReceipt = null;
        state.updateReceiptError = null;
      })
    );
    try {
      const res = await axios({
        method: "PUT",
        url: `/finance/receipts/${id}`,
        data,
      });

      set(
        produce((state) => {
          state.updatedReceipt = res;
          state.updatingReceipt = false;
          state.showUpdateReceipt = false;
        })
      );
    } catch (error) {
      set(
        produce((state) => {
          state.updateReceiptError = error;
          state.updatingReceipt = false;
        })
      );
    }
  },

  async deleteReceipt(id) {
    set(
      produce((state) => {
        state.deletingReceipt = true;
        state.deleteReceiptError = null;
      })
    );
    try {
      await axios({
        method: "DELETE",
        url: `/finance/receipts/${id}`,
      });
      set(
        produce((state) => {
          state.deletedReceipt = id;
          state.deletingReceipt = false;
        })
      );
    } catch (error) {
      set(
        produce((state) => {
          state.deleteReceiptError = error;
          state.deletingReceipt = false;
        })
      );
    }
  },

  async getReceiptsMetaData() {
    set(
      produce((state) => {
        state.gettingMetaData = true;
      })
    );
    try {
      const res = await axios({
        method: "GET",
        url: "/finance/receipts/meta-data",
      });

      set(
        produce((state) => {
          state.metaData = res;
          state.gettingMetaData = false;
        })
      );
    } catch (error) {
      set(
        produce((state) => {
          state.gettingMetaData = false;
        })
      );
    }
  },

  async filteredReceipts(filterBy) {
    try {
      const res = await axios({
        method: "POST",
        url: "/finance/receipts/filter",
        data: filterBy,
      });

      set(
        produce((state) => {
          state.filteredReceipts = res;
          state.filteringReceipts = false;
        })
      );
    } catch (error) {
      set(
        produce((state) => {
          state.filteringReceipts = false;
          state.filteredReceiptsError = null;
        })
      );
    }
  },

  async getReceiptDetails(id) {
    set(
      produce((state) => {
        state.gettingReceiptDetails = true;
        state.receiptDetailsError = null;
      })
    );
    try {
      const res = await axios({
        method: "GET",
        url: `/finance/receipts/${id}`,
      });

      set(
        produce((state) => {
          state.receiptDetails = res;
          state.gettingReceiptDetails = false;
        })
      );
    } catch (error) {
      set(
        produce((state) => {
          state.receiptDetailsError = error;
          state.gettingReceiptDetails = false;
        })
      );
    }
  },

  setShowCreateReceipts(show) {
    set(
      produce((state) => {
        state.showCreateReceipt = show;
      })
    );
  },
  setUpdateReceiptData(data) {
    set(
      produce((state) => {
        state.receiptToEdit = data;
      })
    );
  },
  setCreating(show = false, creating = false, dataToEdit = {}) {
    set(
      produce((state) => {
        state.creating = creating;
        state.showCreateReceipt = show;
        state.receiptToEdit = dataToEdit;
      })
    );
  },
  setShowViewReceiptDetails(show) {
    set(
      produce((state) => {
        state.showReceiptDetails = show;
      })
    );
  },
});

const storeName = "RECEIPTS STORE";

const useReceiptsStore = create(
  subscribeWithSelector(devtools(store, { name: storeName })),
  { name: storeName }
);

useReceiptsStore.subscribe(
  (state) => state.createdReceipt,
  (receipt) => {
    if (receipt) {
      useReceiptsStore.getState().getReceipts();
    }
  }
);

useReceiptsStore.subscribe(
  (state) => state.deletedReceipt,
  (receipt) => {
    if (receipt) {
      useReceiptsStore.getState().getReceipts();
    }
  }
);

useReceiptsStore.subscribe(
  (state) => state.updatedReceipt,
  (receipt) => {
    if (receipt) {
      useReceiptsStore.getState().getReceipts();

      useReceiptsStore.getState().setCreating(false);
    }
  }
);

export default useReceiptsStore;
