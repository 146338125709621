import { message, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getCurrency,
  numberWithCommas,
} from "../../../../config/helpers/app/numberFormater";
import generatePDF from "../../../../config/services/generatePdf";
import TableTitle from "../../../shared/Tables/TableTitle";

const BudgetMonitoringStatement = ({
  handleRefreshTable,
  handleExportRecords,
}) => {
  const [filterTable, setfilterTable] = useState(null);
  const {
    budgetMonitoringStatement: data,
    budgetMonitoringStatementLoading: loading,
    budgetMonitoringStatementSuccess: success,
    budgetMonitoringStatementError: error,
    budgetDetails,
  } = useSelector((state) => state.budgetState);
  const { projectsDetails } = useSelector((state) => state.projectsState);
  const {
    success: logDetailsSuccess,
    error: logDetailsError,
    records,
  } = useSelector((state) => state.logDetailsState.addLogDetails);
  const currency = getCurrency(budgetDetails, "approved_budget");
  //format data from server to fit table
  const formatBudgetMonitoringStatement = (data) => {
    let budgetMonitoringStatement = [];
    data?.forEach((item) => {
      let activitiesActualExpenditureSubTotal = 0;
      let activitiesVarianceSubtotal = 0;
      let budgettedSubTotal = 0;
      //push budget lines
      budgetMonitoringStatement.push({ ...item, isBudgetLine: true });
      //push activitiies and calcualte sub total budget, variance for each budget lien
      item?.activities?.forEach((activity) => {
        budgettedSubTotal += activity.total_cost;
        activitiesActualExpenditureSubTotal += activity.totalActualExpenditure;
        activitiesVarianceSubtotal += activity.variance;
        budgetMonitoringStatement.push({ ...activity, isBudgetActivity: true });
      });
      //caclualte % variance for each
      let percentageVariance = Math.floor(
        (activitiesVarianceSubtotal / budgettedSubTotal) * 100
      );
      budgetMonitoringStatement.push({
        name: "Subtotal",
        id:
          budgettedSubTotal +
          activitiesActualExpenditureSubTotal +
          Math.floor(Math.random() * 10000),
        total_cost: budgettedSubTotal,
        totalActualExpenditure: activitiesActualExpenditureSubTotal,
        variance: activitiesVarianceSubtotal,
        isSubTotal: true,
        percentageVariance,
      });
    });
    return budgetMonitoringStatement;
  };
  const budgetMonitoringStatement = formatBudgetMonitoringStatement(
    data.budgetMonitoringStatement
  );
  //calculate grand total from api data
  let grandtotal = 0;
  let actualExpenditure = 0;
  let varianceTotal = 0;
  data?.budgetMonitoringStatement?.forEach((item) => {
    item.activities?.forEach((activity) => {
      grandtotal += activity.total_cost;
      actualExpenditure += activity.totalActualExpenditure;
      varianceTotal += activity.variance;
    });
  });
  let variancePercentage = Math.floor((actualExpenditure / grandtotal) * 100);

  //get budget monitoring statement project period
  const budgetMonthlyExpenditureColumns = [];
  data?.projectPeriod?.forEach((element) => {
    budgetMonthlyExpenditureColumns.push({
      title: element,
      responsive: ["md"],
      key: element,
      dataIndex: element,
      render: (text) => {
        return (
          !isNaN(text) &&
          `${currency === "D" ? "USD" : "UGX"} ${
            !isNaN(text) && numberWithCommas(parseInt(text))
          }`
        );
      },
    });
  });
  const columns = [
    {
      title: "Programs And Activities",
      key: "name",
      fixed: "left",
      render: (text) => {
        return (
          <div>
            <span className="d-sm-none">Activity:</span> {text.name} <br />
            <div className="d-sm-none">
              {text.total_cost && "Total Budget: "}
              {!isNaN(text.total_cost) &&
                `${currency === "D" ? "USD" : "UGX"} ${numberWithCommas(
                  parseInt(text.total_cost || 0)
                )}`}{" "}
              <br />
              {/* total expenditure  */}
              {!isNaN(text.totalActualExpenditure) &&
                "Total Actual Expenditure: "}
              {!isNaN(text.totalActualExpenditure) &&
                `${currency === "D" ? "USD" : "UGX"} ${numberWithCommas(
                  parseInt(text.totalActualExpenditure || 0)
                )}`}{" "}
              <br />
              {/* variance */}
              {text.variance && "Variance: "}
              {!isNaN(text.variance) &&
                `${currency === "D" ? "USD" : "UGX"} ${numberWithCommas(
                  parseInt(text.variance || 0)
                )}`}{" "}
              <br />
              {/* %variance */}
              {text.percentageVariance && "Variance: "}
              {!isNaN(text.percentageVariance) &&
                `${text.percentageVariance} %`}{" "}
              <br />
            </div>
          </div>
        );
      },
    },
    {
      title: "Total Budget",
      dataIndex: "total_cost",
      key: "total_cost",
      render: (text) => {
        return (
          !isNaN(text) &&
          `${currency === "D" ? "USD" : "UGX"} ${numberWithCommas(
            parseInt(text || 0)
          )}`
        );
      },
      responsive: ["md"],
    },
    ...budgetMonthlyExpenditureColumns,
    {
      title: "Actual Expenditure",
      dataIndex: "totalActualExpenditure",
      key: "totalActualExpenditure",
      render: (text) => {
        return (
          !isNaN(text) &&
          `${currency === "D" ? "USD" : "UGX"} ${numberWithCommas(
            parseInt(text || 0)
          )}`
        );
      },
      responsive: ["md"],
    },
    {
      title: "Variance",
      dataIndex: "variance",
      key: "variance",
      render: (text) => {
        return (
          !isNaN(text) &&
          `${currency === "D" ? "USD" : "UGX"} ${numberWithCommas(
            parseInt(text || 0)
          )}`
        );
      },
      responsive: ["md"],
    },
    {
      title: "%Variance",
      dataIndex: "percentageVariance",
      key: "percentageVariance",
      render: (text) => {
        return !isNaN(text) && `${text} %`;
      },
      responsive: ["md"],
    },
  ];
  /* Functions */
  const refreshTable = () => {
    handleRefreshTable();
    if (!success && error) message.error(error);
  };
  const search = (value) => {
    const filterTable = budgetMonitoringStatement?.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(value.toLowerCase())
      )
    );
    setfilterTable(filterTable);
  };
  const setfilterTableNull = (e) => {
    if (!e.target.value) setfilterTable(null);
  };

  //const showTotal = (total) => `Total: ${total}`;
  const openAddModal = () => message.info("Budget Monitoring Statement");
  const exportRecords = () => {
    message.loading("Proccessing document for export please wait ", 3);
    handleExportRecords();
  };
  const generateRecordsPdf = () => {
    const pdfColumns = [
      "Programe And Activity",
      "Total Budget",
      "Actual Expenditure",
      "Variance",
      "%Variance",
    ];
    let pdfRows = [];
    budgetMonitoringStatement?.forEach((record) => {
      const row = [
        record.name,
        record.total_cost &&
          `${currency === "D" ? "USD" : "UGX"} ${numberWithCommas(
            parseInt(record.total_cost || 0)
          )}`,
        record.totalActualExpenditure &&
          `${currency === "D" ? "USD" : "UGX"} ${numberWithCommas(
            parseInt(record.totalActualExpenditure || 0)
          )}`,
        record.variance &&
          `${currency === "D" ? "USD" : "UGX"} ${numberWithCommas(
            parseInt(record.variance || 0)
          )}`,
        record.percentageVariance && `${record.percentageVariance} %`,
      ];
      pdfRows.push(row);
    });
    pdfRows.push([
      "Grand Total",
      `${currency === "D" ? "USD" : "UGX"} ${numberWithCommas(
        parseInt(grandtotal)
      )}`,
      `${currency === "D" ? "USD" : "UGX"} ${numberWithCommas(
        parseInt(actualExpenditure)
      )}`,
      `${currency === "D" ? "USD" : "UGX"} ${numberWithCommas(
        parseInt(varianceTotal)
      )}`,
      `${variancePercentage} %`,
    ]);
    generatePDF(
      pdfRows,
      pdfColumns,
      `${
        budgetDetails.name || projectsDetails.name
      } Budget Monitoring Statement - ${new Date().toLocaleDateString()} `,
      `${
        budgetDetails.name || projectsDetails.name
      } Project Budget Monitoring Statement.pdf`
    );
  };
  /* Use effect */
  useEffect(() => {
    if (logDetailsSuccess && records === "budgetMonitoringStatement") {
      message.info("Started download");
      generateRecordsPdf();
    } else if (!logDetailsSuccess && logDetailsError)
      message.error(logDetailsError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logDetailsSuccess, logDetailsError]);
  return (
    <div className="overflow-auto">
      <Table
        size="small"
        className="overflow-auto"
        footer={(record) => {
          return (
            <table className="table table-bordered table-hover">
              <tr className="text-success fw-bold">
                <td>Grand Total: </td>
                <td>
                  {currency === "D" ? "USD" : "UGX"}{" "}
                  {numberWithCommas(grandtotal)}
                </td>
              </tr>
              <tr className="text-success fw-bold">
                <td>Total Actual Expenditure: </td>
                <td>
                  {currency === "D" ? "USD" : "UGX"}{" "}
                  {numberWithCommas(actualExpenditure)}
                </td>
              </tr>
              <tr className="text-success fw-bold">
                <td>Total Variance: </td>
                <td>
                  {currency === "D" ? "USD" : "UGX"}{" "}
                  {numberWithCommas(varianceTotal)}
                </td>
              </tr>
              <tr className="text-success fw-bold">
                <td>Percentage Variance: </td>
                <td>{variancePercentage} %</td>
              </tr>
            </table>
          );
        }}
        rowClassName={(record) =>
          record.isBudgetLine
            ? "fw-bold text-dark"
            : record.isSubTotal && "text-warning fw-bold"
        }
        rowKey={(r) => Math.floor(Math.random() * 40000)}
        pagination={false}
        loading={loading}
        title={() =>
          TableTitle({
            search,
            setfilterTableNull,
            refreshTable,
            exportRecords,
            openAddModal,
          })
        }
        bordered={true}
        dataSource={
          filterTable === null ? budgetMonitoringStatement : filterTable
        }
        columns={columns}
        scroll={{ x: data?.projectPeriod?.length > 15 ? 5000 : 3000 }}
      />
    </div>
  );
};

export default BudgetMonitoringStatement;
