import { Button, Modal, Space, Spin, Tag } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";
import appConfig from "../../../../config/config.json";
import { undefinedRecordNames } from "../../../../config/helpers/app/undefinedRecords";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { rejectionsActions } from "../../../../config/actions/rejections/rejections.actions";

const IssueDetails = ({ handleApprove, handleCheck }) => {
  const dispatch = useDispatch();
  const visible = useSelector(
    (state) => state.AppUi.admisnistration.storesRegister.viewIssuesDetails
  );
  const { success, error, data, loading } = useSelector(
    (state) => state.storesRegisterState.storesRegisterIssuesDetails
  );
  const { approveStoresRegisterIssueLoading } = useSelector(
    (state) => state.storesRegisterState
  );
  const { loading: checkLoading } = useSelector(
    (state) => state.storesRegisterState.checkStoresRegisterIssue
  );
  const closeModal = () => {
    dispatch(appUiActions.toggleStoresRegisterIssuesDetailsModal(false));
  };
  const checkRecord = () => handleCheck(data.id);
  const approveRecord = () => handleApprove(data.id);

  const reject = () => {
    dispatch(
      rejectionsActions.toggleRejectionsModal({
        visible: true,
        data: {
          user_to_id: `Issueing ${data.name} to ${data.issued_to}`,
          item_application: "Stores Register Issue",
          item_id: data?.id,
          itemName: data.name,
        },
      })
    );
  };
  return (
    <div>
      <Modal
        zIndex={800}
        footer={[
          data.status !== appConfig.status.approved && success && (
            <Space>
              <Button
                onClick={approveRecord}
                loading={approveStoresRegisterIssueLoading}
                type="primary"
              >
                Approve
              </Button>
              {data.status !== appConfig.status.checked && (
                <Button onClick={checkRecord} loading={checkLoading}>
                  Check
                </Button>
              )}
              <Button danger onClick={reject}>
                Reject
              </Button>
            </Space>
          ),
        ]}
        onCancel={closeModal}
        title="DETAILS"
        open={visible}
      >
        {loading ? (
          <div className="d-flex justify-content-center m-5">
            <Spin size="large" />
          </div>
        ) : (
          <>
            {!success ? (
              <div className="alert alert-danger">{error}</div>
            ) : (
              <>
                <div className="details_div">
                  <table className="table table-striped table-hover">
                    <thead>
                      <tr>
                        <th scope="col">Column</th>
                        <th scope="col">Data</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Item</td>
                        <td>{data.name}</td>
                      </tr>
                      <tr>
                        <td>Issued to</td>
                        <td>{data.issued_to}</td>
                      </tr>
                      <tr>
                        <td>Quantity</td>
                        <td>{data.quantity}</td>
                      </tr>
                      <tr>
                        <td>Total Price</td>
                        <td>{data.total_price}</td>
                      </tr>
                      <tr>
                        <td>Remarks</td>
                        <td>{data.remarks}</td>
                      </tr>
                      <tr>
                        <td>Status</td>
                        <td>
                          {data.status === appConfig.status.approved && (
                            <Tag icon={<CheckCircleOutlined />} color="success">
                              {data.status}
                            </Tag>
                          )}
                          {data.status === appConfig.status.checked && (
                            <Tag
                              icon={<MinusCircleOutlined />}
                              color="processing"
                            >
                              {data.status}
                            </Tag>
                          )}
                          {data.status === appConfig.status.created && (
                            <Tag
                              icon={<ExclamationCircleOutlined />}
                              color="warning"
                            >
                              Pending
                            </Tag>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Created By</td>
                        <td>{`${data.creator.first_name} ${data.creator.other_names}`}</td>
                      </tr>
                      <tr>
                        <td>Created At</td>
                        <td>
                          {`${new Date(
                            data.created_at
                          ).toDateString()} ${new Date(
                            data.created_at
                          ).toLocaleTimeString()}
                        `}
                        </td>
                      </tr>
                      <tr>
                        <td>Last Updated By</td>
                        <td>
                          {`${undefinedRecordNames(
                            data,
                            "updator",
                            "first_name"
                          )} ${undefinedRecordNames(
                            data,
                            "updator",
                            "other_names"
                          )}`}
                        </td>
                      </tr>
                      <tr>
                        <td>Last Updated At</td>
                        <td>
                          {data.updated_at &&
                            `${new Date(
                              data.updated_at
                            ).toDateString()} ${new Date(
                              data.updated_at
                            ).toLocaleTimeString()}`}
                        </td>
                      </tr>
                      <tr>
                        <td>Checked By</td>
                        <td>{`${undefinedRecordNames(
                          data,
                          "checker",
                          "first_name"
                        )} ${undefinedRecordNames(
                          data,
                          "checker",
                          "other_names"
                        )}`}</td>
                      </tr>
                      <tr>
                        <td>Checked At</td>
                        <td>
                          {data.checked_at &&
                            `${new Date(
                              data.checked_at
                            ).toDateString()} ${new Date(
                              data.checked_at
                            ).toLocaleTimeString()}
                        `}
                        </td>
                      </tr>
                      <tr>
                        <td>Approved By</td>
                        <td>{`${undefinedRecordNames(
                          data,
                          "approver",
                          "first_name"
                        )} ${undefinedRecordNames(
                          data,
                          "approver",
                          "other_names"
                        )}`}</td>
                      </tr>
                      <tr>
                        <td>Approved At</td>
                        <td>
                          {data.aprroved_at &&
                            `${new Date(
                              data.aprroved_at
                            ).toDateString()} ${new Date(
                              data.aprroved_at
                            ).toLocaleTimeString()}`}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </>
        )}
      </Modal>
    </div>
  );
};

export default IssueDetails;
