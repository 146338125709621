import { donorsActions } from "../../../actions/businesDevAndPartnerships/donors/donors.actions";
import apiRequests from "../../../api/api";
import donorsApiRequest from "../../../api/businessDevAndPartnerships/donors/donors.api";

const donorsThunks = {
  getDonors: () => async (dispatch) => {
    dispatch(donorsActions.setDonorsRequest());
    const res = await donorsApiRequest.fetchDonors();
    if (res.success) dispatch(donorsActions.setDonorsSuccess(res.data));
    else dispatch(donorsActions.setDonorsError(res.error));
  },
  getDonorsGraphData: () => async (dispatch) => {
    dispatch(donorsActions.setDonorsGraphDataRequest());
    const res = await apiRequests.getRequest(
      "/businessDevelopmentAndPartnerships/donors/graphData"
    );
    if (res.success)
      dispatch(donorsActions.setDonorsGraphDataSuccess(res.data));
    else dispatch(donorsActions.setDonorsGraphDataError(res.error));
  },
  getDonorsDetails: (id) => async (dispatch) => {
    dispatch(donorsActions.setDonorDetailsRequest());
    const res = await donorsApiRequest.fetchDonorDetails(id);
    if (res.success) dispatch(donorsActions.setDonorDetailsSuccess(res.data));
    else dispatch(donorsActions.setDonorDetailsError(res.error));
  },
  addDonor: (body, donors) => async (dispatch) => {
    dispatch(donorsActions.addDonorsRequest());
    const res = await donorsApiRequest.addDonor(body);
    if (res.success) {
      dispatch(
        donorsActions.addDonorSuccess({
          message: res.message,
          data: [res.data, ...donors],
        })
      );
    } else dispatch(donorsActions.addDonorsError(res.error));
    dispatch(donorsActions.addDonorsComplete());
  },
  updateDonor: (body, donors) => async (dispatch) => {
    dispatch(donorsActions.editDonorsRequest());
    const res = await donorsApiRequest.updateDonor(body);
    if (res.success) {
      dispatch(
        donorsActions.editDonorSuccess({
          message: res.message,
          data: [res.data, ...donors],
        })
      );
    } else dispatch(donorsActions.editDonorsError(res.error));
    dispatch(donorsActions.editDonorsComplete());
  },
  deleteDonor: (id, donors, name) => async (dispatch) => {
    dispatch(donorsActions.deleteDonorsRequest());
    const res = await donorsApiRequest.deleteDonor(id, name);
    if (res.success) {
      dispatch(
        donorsActions.deleteDonorSuccess({ message: res.message, data: donors })
      );
    } else dispatch(donorsActions.deleteDonorsError(res.error));
    dispatch(donorsActions.deleteDonorsComplete());
  },
  checkDonor: (id) => async (dispatch) => {
    dispatch(donorsActions.checkDonorsRequest());
    const res = await donorsApiRequest.checkDonor(id);
    if (res.success) {
      dispatch(donorsActions.checkDonorSuccess(res.message));
      dispatch(donorsThunks.getDonorsDetails(id));
    } else dispatch(donorsActions.checkDonorsError(res.error));
    dispatch(donorsActions.checkDonorsComplete());
  },
  approveDonor: (id) => async (dispatch) => {
    dispatch(donorsActions.approveDonorsRequest());
    const res = await donorsApiRequest.approveDonor(id);
    if (res.success) {
      dispatch(donorsActions.approveDonorSuccess(res.message));
      dispatch(donorsThunks.getDonorsDetails(id));
    } else dispatch(donorsActions.approveDonorsError(res.error));
    dispatch(donorsActions.approveDonorsComplete());
  },
  submitProposalStatus: (body) => async (dispatch) => {
    dispatch(donorsActions.submitProposalLoading());
    const res = await donorsApiRequest.submitProposalStatus(body);
    if (res.success) {
      //dispatch(donorsThunks.getDonorsDetails(body.id));
      dispatch(donorsActions.submitProposalSuccess(res.message));
    } else dispatch(donorsActions.submitProposalError(res.error));
    dispatch(donorsActions.submitProposalComplete());
  },
  setPersonIncharge: (body) => async (dispatch) => {
    dispatch(donorsActions.setPersonInchargeDonorsLoading());
    const res = await donorsApiRequest.setPersonIncharge(body);
    if (res.success) {
      dispatch(donorsActions.setPersonInchargeDonorSuccess(res.message));
      dispatch(donorsThunks.getDonorsDetails(body.id));
    } else dispatch(donorsActions.setPersonInchargeDonorsError(res.error));
    dispatch(donorsActions.setPersonInchargeDonorsComplete());
  },
};

export default donorsThunks;
