import { Alert, Button, Modal, Spin, Tabs } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import BudgetLineActivitiesTable from "./BudgetLineActivitiesTable";
import OtherBudgetLineDetails from "./OtherBudgetLineDetails";

const { TabPane } = Tabs;

const BudgetLinesDetails = ({
  visible,
  handleCloseModal,
  handleOpenAddAcitvityModal,
}) => {
  const {
    budgetLineDetailsLoading,
    budgetLineDetailsSuccess,
    budgetLineDetailsError,
    budgetLineDetails,
  } = useSelector((state) => state.budgetState);
  const closeModal = () => handleCloseModal(false);
  const openAddActivityModal = () => handleOpenAddAcitvityModal(true);
  return (
    <div>
      <Modal
        zIndex={900}
        width={800}
        title={budgetLineDetails.name || "BUDGET LINE DETAILS"}
        onCancel={closeModal}
        footer={[
          <Button type="primary" onClick={openAddActivityModal}>
            Add Cost Driver
          </Button>,
        ]}
        open={visible}
      >
        {budgetLineDetailsLoading ? (
          <div className="d-flex justify-content-center m-5">
            <Spin />
          </div>
        ) : (
          <div>
            {!budgetLineDetailsSuccess ? (
              <Alert
                showIcon
                type="error"
                message={budgetLineDetailsError}
                className="m-2"
              />
            ) : (
              <div>
                <Tabs defaultActiveKey="1">
                  <TabPane tab="Budget Line Details" key="1">
                    <OtherBudgetLineDetails data={budgetLineDetails} />
                  </TabPane>
                  <TabPane tab="Cost Drivers" key="2">
                    <BudgetLineActivitiesTable data={budgetLineDetails} />
                  </TabPane>
                </Tabs>
              </div>
            )}
          </div>
        )}
      </Modal>
    </div>
  );
};

export default BudgetLinesDetails;
