import axios from "axios";
import fileDownload from "js-file-download";

const manualsAndPoliciesApiRequests = {
  fetchManualsAndPolicies: async () => {
    const res = await axios.get("/administration/manualsAndPolicies");
    return res;
  },

  addManualsAndPolicies: async (body) => {
    try {
      const res = await axios.post("/administration/manualsAndPolicies", body);
      return res;
    } catch (error) {
      console.error("Manuals and policies add error", error);
    }
  },

  updateManualsAndPolicies: async (body) => {
    try {
      const res = await axios.put(
        `/administration/manualsAndPolicies/${body.id}`,
        body
      );
      return res;
    } catch (error) {
      console.error("Manuals and policies update error", error);
    }
  },

  deleteManualsAndPolicies: async (id) => {
    try {
      const res = await axios.delete(
        `/administration/manualsAndPolicies/${id}`
      );
      return res;
    } catch (error) {
      console.error("Manuals and policies delete error", error);
    }
  },

  fetchManualsAndPoliciesDetails: async (id) => {
    try {
      const res = await axios.get(`/administration/manualsAndPolicies/${id}`);
      return res;
    } catch (error) {
      console.error("Manuals and policies details error", error);
    }
  },

  checkManualAndPolicies: async (id) => {
    try {
      const res = await axios.patch(
        `/administration/manualsAndPolicies/check/${id}`,
        {
          checkedAt: new Date(),
        }
      );
      return res;
    } catch (error) {
      console.error("Manuals and policies check error", error);
    }
  },

  approveManualAndPolicies: async (id) => {
    try {
      const res = await axios.patch(
        `/administration/manualsAndPolicies/approve/${id}`,
        {
          approvedAt: new Date(),
        }
      );
      return res;
    } catch (error) {
      console.error("Manuals and policies approval error", error);
    }
  },

  downloadManualAndPolicies: async (file_path, fileName) => {
    try {
      const res = await axios.get(
        `/administration/manualsAndPolicies/download/${file_path}`,
        {
          responseType: "blob",
        }
      );
      fileDownload(res, fileName);
      return res.status === 200
        ? { success: true, message: "Started download" }
        : "Error downloading file";
    } catch (error) {
      console.error("Manuals and policies download error", error);
    }
  },
};

export default manualsAndPoliciesApiRequests;
