import React, { useState } from "react";
import { useStrategicPlansStore } from "../../../config/stores";
import { searchTable } from "../../../config/constants";
import moment from "moment";
import TableButtonActions from "../../../components/common/TableButtonActions";
import RecordStatus from "../../../components/common/RecordStatus";
import AntdTable from "../../../components/common/AntdTable";
import TableTitle from "../../../components/common/TableTitle";

const StrategicPlansTable = () => {
  const [filterTable, setFilterTableNull] = useState(null);
  const [deleteId, setDeleteId] = useState(null);

  const strategicPlansStore = useStrategicPlansStore();

  const search = (value) => {
    const filterTable = searchTable(
      strategicPlansStore.strategicPlans?.data,
      value
    );
    setFilterTableNull(filterTable);
  };
  const confirmDelete = () => {
    strategicPlansStore.deleteStrategicPlan(deleteId);
    setDeleteId(null);
  };
  const viewDetails = (id) => {
    strategicPlansStore.setShowViewStrategicPlanDetails(true);
    strategicPlansStore.getStrategicPlanDetails(id);
  };

  const deleteRecord = (id) => setDeleteId(id);

  const updateRecord = (record) => {
    strategicPlansStore.setCreating(false, true, record);
  };
  const columns = [
    {
      title: "Title",
      render: (record) => {
        const startDate = moment(record.start_date);
        const endDate = moment(record.end_date);
        return (
          <div>
            <span className="d-md-none">Title:</span>
            <span
              onClick={() => viewDetails(record.id)}
              className="text-primary"
            >
              {record.title}
            </span>
            <br />
            <div className="d-md-none">
              Start Date: {startDate.format("DD/MM/YYYY")}
              <br />
              End Date: {endDate.format("DD/MM/YYYY")} <br />
              Period: {endDate.subtract(startDate).year()} <br />
              <TableButtonActions
                record={record}
                confirmDelete={confirmDelete}
                deleteLoading={strategicPlansStore.deletingReceipt}
                viewDetails={viewDetails}
                updateRecord={updateRecord}
                deleteId={deleteId}
                deleteRecord={deleteRecord}
              />
            </div>
          </div>
        );
      },
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      render: (start_date) => moment(start_date).format("DD/MM/YYYY"),
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      render: (end_date) => moment(end_date).format("DD/MM/YYYY"),
    },
    {
      title: "Period",
      key: "period",
      render: (record) => {
        const startDate = moment(record.start_date);
        const endDate = moment(record.end_date);
        return endDate.subtract(startDate).year();
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => <RecordStatus status={status} />,
    },
    {
      title: "Actions",
      key: "actions",
      responsive: ["md"],
      render: (record) => {
        return (
          <TableButtonActions
            record={record}
            confirmDelete={confirmDelete}
            deleteLoading={strategicPlansStore.deletingReceipt}
            viewDetails={viewDetails}
            updateRecord={updateRecord}
            deleteId={deleteId}
            deleteRecord={deleteRecord}
          />
        );
      },
    },
  ];

  const openAddModal = () => {
    strategicPlansStore.setCreating(true, true, {});
  };

  const refreshTable = () => {
    strategicPlansStore.getStrategicPlans();
  };
  return (
    <div>
      <AntdTable
        loading={strategicPlansStore.gettingStrategicPlans}
        title={() => (
          <TableTitle
            setFilterTableNull={setFilterTableNull}
            openAddModal={openAddModal}
            refreshTable={refreshTable}
            search={search}
            exportRecords={null}
          />
        )}
        pageSize={20}
        data={
          filterTable === null
            ? strategicPlansStore?.strategicPlans?.data || []
            : filterTable
        }
        columns={columns}
      />
    </div>
  );
};

export default StrategicPlansTable;
