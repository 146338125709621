import React, { useState } from "react";
import { useAssetIncidentFormStore } from "../../../../config/stores";
import { searchTable } from "../../../../config/constants";
import TableButtonActions from "../../../../components/common/TableButtonActions";
import AntdTable from "../../../../components/common/AntdTable";
import TableTitle from "../../../../components/common/TableTitle";
import dayjs from "dayjs";
import RecordStatus from "../../../../components/common/RecordStatus";

const AssetIncidentFormTable = () => {
  const [filterTable, setFilterTableNull] = useState(null);
  const [deleteId, setDeleteId] = useState(null);

  const assetIncidentFormStore = useAssetIncidentFormStore();

  const search = (value) => {
    const filterTable = searchTable(
      assetIncidentFormStore.assetIncidentForms?.data,
      value
    );
    setFilterTableNull(filterTable);
  };
  const confirmDelete = () => {
    assetIncidentFormStore.deleteAssetIncidentForm(deleteId);
    setDeleteId(null);
  };
  const viewDetails = (id) => {
    assetIncidentFormStore.setShowAssetIncidentFormDetails(true);
    assetIncidentFormStore.getAssetIncidentFormDetails(id);
  };

  const deleteRecord = (id) => setDeleteId(id);

  const updateRecord = (record) => {
    assetIncidentFormStore.setCreating(false, true, record);
  };
  const columns = [
    {
      title: "Date",
      render: (record) => {
        return (
          <div>
            <span className="d-md-none">Date:</span>{" "}
            {dayjs(record.date_of_incident).format("DD/MM/YYYY")} <br />
            <div className="d-md-none">
              Asset: {record.asset?.name}
              <br />
              Created By:{" "}
              {`${record.created_by?.first_name} ${record?.created_by?.other_names}`}{" "}
              <br />
              Status: {record.status} <br />
              <TableButtonActions
                record={record}
                confirmDelete={confirmDelete}
                deleteLoading={assetIncidentFormStore.deletingAssetIncidentForm}
                viewDetails={viewDetails}
                updateRecord={updateRecord}
                deleteId={deleteId}
                deleteRecord={deleteRecord}
              />
            </div>
          </div>
        );
      },
    },
    {
      title: "Recommendation",
      dataIndex: "recommendation",
      render: (recommendation) => (
        <span className="text-uppercase">{recommendation}</span>
      ),
      responsive: ["md"],
    },
    {
      title: "Asset",
      dataIndex: "asset",
      render: (asset) => asset?.name,
      responsive: ["md"],
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      render: (createdBy) =>
        `${createdBy?.first_name} ${createdBy?.other_names}`,
    },
    {
      title: "Status",
      dataIndex: "status",
      responsive: ["md"],
      render: (text) => <RecordStatus status={text} />,
    },
    {
      title: "Actions",
      key: "actions",
      responsive: ["md"],
      render: (record) => {
        return (
          <TableButtonActions
            record={record}
            confirmDelete={confirmDelete}
            deleteLoading={assetIncidentFormStore.deletingAssetIncidentForm}
            viewDetails={viewDetails}
            updateRecord={updateRecord}
            deleteId={deleteId}
            deleteRecord={deleteRecord}
          />
        );
      },
    },
  ];

  const openAddModal = () => {
    assetIncidentFormStore.setCreating(true, true, {});
  };

  const refreshTable = () => {
    assetIncidentFormStore.getAssetIncidentForms();
    assetIncidentFormStore.getMetaData();
  };
  return (
    <div>
      <AntdTable
        serialNumber
        loading={assetIncidentFormStore.gettingAssetIncidentForms}
        title={() => (
          <TableTitle
            setFilterTableNull={setFilterTableNull}
            openAddModal={openAddModal}
            refreshTable={refreshTable}
            search={search}
            exportRecords={null}
          />
        )}
        pageSize={20}
        data={
          filterTable === null
            ? assetIncidentFormStore?.assetIncidentForms?.data || []
            : filterTable
        }
        columns={columns}
      />
    </div>
  );
};

export default AssetIncidentFormTable;
