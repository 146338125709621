import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddProjects from "../../../components/BusinessDevelopmentAndPartnerships/projects/Forms/AddProjects";
import EditProject from "../../../components/BusinessDevelopmentAndPartnerships/projects/Forms/EditProjects";
import ProjectsTable from "../../../components/BusinessDevelopmentAndPartnerships/projects/Tables/ProjectsTable";
import routes from "../../../config/routes/routes";
import projectThunks from "../../../config/thunks/businessDevAndPartnerships/projects/projects.thunks";
import logDetailThunks from "../../../config/thunks/settings/logDetails.thunks";

const Projects = () => {
  const { projectsSuccess } = useSelector((state) => state.projectsState);
  const dispatch = useDispatch();
  /** functions */
  const refreshtable = () => {
    dispatch(projectThunks.getProjects());
    dispatch(projectThunks.projectsMetaData());
  };
  const addProject = (values, projects) =>
    dispatch(projectThunks.addProjects(values, projects));
  const deleteProject = (id, name, projects) =>
    dispatch(projectThunks.deleteProject(id, name, projects));
  const exportRecords = () => {
    dispatch(
      logDetailThunks.addLogDetails({
        created_at: new Date(),
        activity: "Exported Projects Records",
        records: "projects",
      })
    );
  };
  const editProject = (values, projects) =>
    dispatch(projectThunks.editProjects(values, projects));
  //useEfffect
  useEffect(() => {
    document.title = routes.businessDevelopmentAndPartnerships.projects.title;
    if (!projectsSuccess) {
      dispatch(projectThunks.getProjects());
      dispatch(projectThunks.projectsMetaData());
    }
  }, [projectsSuccess, dispatch]);
  return (
    <div className="container-fluid p-2">
      <h5 className="fw-bold">PROJECTS</h5>
      <ProjectsTable
        handleExportRecords={exportRecords}
        handleRefreshTable={refreshtable}
        handleDelete={deleteProject}
      />
      <AddProjects handleAddRecord={addProject} />
      <EditProject handleEditRecord={editProject} />
    </div>
  );
};

export default Projects;
