import React from "react";
import { Button, Modal, Space, Spin, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import appConfig from "../../../../config/config.json";
import { rejectionsActions } from "../../../../config/actions/rejections/rejections.actions";

const AssetsDetails = ({ handleApprove, handleCheck }) => {
  const visible = useSelector(
    (state) => state.AppUi.admisnistration.assetsRegister.viewAssetsDetailsModal
  );
  const { loading, success, error, data } = useSelector(
    (state) => state.assetRegisterState.assetsDetails
  );
  const { loading: approveLoading } = useSelector(
    (state) => state.assetRegisterState.approveAsset
  );
  const { loading: checkLoading } = useSelector(
    (state) => state.assetRegisterState.checkAsset
  );
  const dispatch = useDispatch();
  const checkRecord = () => handleCheck(data.id);
  const approveRecord = () => handleApprove(data.id);
  const closeModal = () =>
    dispatch(appUiActions.toggleAssetsDetailsModal(false));
  const reject = () => {
    dispatch(
      rejectionsActions.toggleRejectionsModal({
        visible: true,
        data: {
          user_to_id: data?.created_by,
          item_application: "Asset Register",
          item_id: data?.id,
          itemName: data.name,
        },
      })
    );
  };
  return (
    <div>
      <Modal
        footer={[
          data.status !== appConfig.status.approved && success && (
            <Space>
              <Button
                onClick={approveRecord}
                loading={approveLoading}
                type="primary"
              >
                Approve
              </Button>
              {data.status !== appConfig.status.checked && (
                <Button onClick={checkRecord} loading={checkLoading}>
                  Check
                </Button>
              )}
              <Button onClick={reject} danger>
                Reject
              </Button>
            </Space>
          ),
        ]}
        onCancel={closeModal}
        open={visible}
        title={data.name?.toUpperCase()}
      >
        {loading ? (
          <div className="d-flex justify-content-center">
            <Spin size="large" />
          </div>
        ) : (
          <>
            {!success && error ? (
              <div className="alert alert-danger">{error}</div>
            ) : (
              <div className="details_div">
                <table className="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th scope="col">Column</th>
                      <th scope="col">Data</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>{data.name}</td>
                    </tr>
                    <tr>
                      <td>Code</td>
                      <td>{data.code}</td>
                    </tr>
                    <tr>
                      <td>Location</td>
                      <td>{data.location}</td>
                    </tr>
                    <tr>
                      <td>Financial Year</td>
                      <td>{data.financial_year}</td>
                    </tr>
                    <tr>
                      <td>Source Of Funds</td>
                      <td>{data.donorDetails?.name}</td>
                    </tr>
                    <tr>
                      <td>Procurement Date</td>
                      <td>{new Date(data.procurement_date).toDateString()}</td>
                    </tr>
                    <tr>
                      <td>Staff With Assset</td>
                      <td>
                        {data.staffWithAsset?.first_name}{" "}
                        {data.staffWithAsset?.other_names}
                      </td>
                    </tr>
                    <tr>
                      <td>Acquisition Amount</td>
                      <td>{data.purchase_ammount}</td>
                    </tr>
                    <tr>
                      <td>Depreciation</td>
                      <td>{data.depreciationValue}</td>
                    </tr>
                    <tr>
                      <td>Net Book Value</td>
                      <td>{data.netBookValue}</td>
                    </tr>
                    <tr>
                      <td>Status</td>
                      <td>
                        {data.status === appConfig.status.approved && (
                          <Tag icon={<CheckCircleOutlined />} color="success">
                            {data.status}
                          </Tag>
                        )}
                        {data.status === appConfig.status.checked && (
                          <Tag
                            icon={<MinusCircleOutlined />}
                            color="processing"
                          >
                            {data.status}
                          </Tag>
                        )}
                        {data.status === appConfig.status.created && (
                          <Tag
                            icon={<ExclamationCircleOutlined />}
                            color="warning"
                          >
                            Pending
                          </Tag>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Created By</td>
                      <td>{`${data?.creator?.first_name} ${data?.creator?.other_names}`}</td>
                    </tr>
                    <tr>
                      <td>Created At</td>
                      <td>
                        {`${new Date(
                          data.created_at
                        ).toDateString()} ${new Date(
                          data.created_at
                        ).toLocaleTimeString()}
                                   `}
                      </td>
                    </tr>
                    <tr>
                      <td>Last Updated By</td>
                      <td>
                        {data.updator &&
                          `${data?.updator?.first_name} ${data?.updator?.other_names}`}
                      </td>
                    </tr>
                    <tr>
                      <td>Last Updated At</td>
                      <td>
                        {data.updated_at &&
                          `${new Date(
                            data.updated_at
                          ).toDateString()} ${new Date(
                            data.updated_at
                          ).toLocaleTimeString()}`}
                      </td>
                    </tr>
                    <tr>
                      <td>Checked By</td>
                      <td>
                        {data.checker &&
                          `${data?.checker?.first_name} ${data?.checker?.other_names}`}
                      </td>
                    </tr>
                    <tr>
                      <td>Checked At</td>
                      <td>
                        {data.checked_at &&
                          `${new Date(
                            data.checked_at
                          ).toDateString()} ${new Date(
                            data.checked_at
                          ).toLocaleTimeString()}
                                   `}
                      </td>
                    </tr>
                    <tr>
                      <td>Approved By</td>
                      <td>
                        {data.approver &&
                          `${data?.approver?.first_name} ${data?.approver?.other_names}`}
                      </td>
                    </tr>
                    <tr>
                      <td>Approved At</td>
                      <td>
                        {data.approved_at &&
                          `${new Date(
                            data.approved_at
                          ).toDateString()} ${new Date(
                            data.approved_at
                          ).toLocaleTimeString()}`}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </>
        )}
      </Modal>
    </div>
  );
};

export default AssetsDetails;
