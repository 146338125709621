import { Col, Input, Row } from "antd";
import React from "react";
import { useStrategicPlansStore } from "../../../../config/stores";
import moment from "moment";

const StrategicPlanOtherDetails = () => {
  const strategicPlanStore = useStrategicPlansStore();
  const { strategicPlanDetails } = strategicPlanStore;
  return (
    <Row className="mb-4" gutter={2}>
      <Col xs={24} sm={24} md={12} lg={6} xl={6}>
        <Row gutter={4}>
          <Col span={6}>
            <span className="fw-bold me-1">Title:</span>
          </Col>
          <Col span={18}>
            <Input value={strategicPlanDetails.title} />
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={12} lg={5} xl={6}>
        <Row gutter={4}>
          <Col span={6}>
            <span className="fw-bold me-1">Start Date:</span>
          </Col>
          <Col span={18}>
            <Input
              value={moment(strategicPlanDetails.start_date).format(
                "DD/MM/YYYY"
              )}
            />
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={12} lg={5} xl={6}>
        <Row gutter={4}>
          <Col span={6}>
            <span className="fw-bold me-1">End Date:</span>
          </Col>
          <Col span={18}>
            <Input
              value={moment(strategicPlanDetails.end_date).format("DD/MM/YYYY")}
            />
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={12} lg={8} xl={6}>
        <Row>
          <Col span={6}>
            <span className="fw-bold me-1">Description:</span>
          </Col>
          <Col span={18}>
            <Input value={strategicPlanDetails.description} />
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={12} lg={6} xl={6} className="my-1">
        <Row gutter={4}>
          <Col span={6}>
            <span className="fw-bold me-1">Created By:</span>
          </Col>
          <Col span={18}>
            <Input
              value={`${strategicPlanDetails?.created_by?.first_name || ""} ${
                strategicPlanDetails?.created_by?.other_names || ""
              }`}
            />
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={12} lg={6} xl={6} className="my-1">
        <Row gutter={4}>
          <Col span={6}>
            <span className="fw-bold me-1">Created At:</span>
          </Col>
          <Col span={18}>
            <Input
              value={moment(strategicPlanDetails.created_at).format(
                "DD/MM/YYYY"
              )}
            />
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={12} lg={6} xl={6} className="my-1">
        <Row gutter={4}>
          <Col span={6}>
            <span className="fw-bold me-1">Updated By:</span>
          </Col>
          <Col span={18}>
            <Input
              value={`${strategicPlanDetails?.updated_by?.first_name || ""} ${
                strategicPlanDetails?.updated_by?.other_names || ""
              }`}
            />
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={12} lg={6} xl={6} className="my-1">
        <Row gutter={4}>
          <Col span={6}>
            <span className="fw-bold me-1">Updated At:</span>
          </Col>
          <Col span={18}>
            <Input
              value={moment(strategicPlanDetails.updated_at).format(
                "DD/MM/YYYY"
              )}
            />
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={12} lg={6} xl={6}>
        <Row gutter={4}>
          <Col span={6}>
            <span className="fw-bold me-1">Checked By:</span>
          </Col>
          <Col span={18}>
            <Input
              value={`${strategicPlanDetails?.checked_by?.first_name || ""} ${
                strategicPlanDetails?.checked_by?.other_names || ""
              }`}
            />
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={12} lg={6} xl={6}>
        <Row gutter={4}>
          <Col span={6}>
            <span className="fw-bold me-1">Checked At:</span>
          </Col>
          <Col span={18}>
            <Input
              value={
                strategicPlanDetails.approved_at &&
                moment(strategicPlanDetails.approved_at).format("DD/MM/YYYY")
              }
            />
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={12} lg={6} xl={6} className="my-1">
        <Row gutter={4}>
          <Col span={6}>
            <span className="fw-bold me-1">Approved By:</span>
          </Col>
          <Col span={18}>
            <Input
              value={`${strategicPlanDetails?.approved_by?.first_name || ""} ${
                strategicPlanDetails?.approved_by?.other_names || ""
              }`}
            />
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={12} lg={6} xl={6} className="my-1">
        <Row gutter={4}>
          <Col span={6}>
            <span className="fw-bold me-1">Approved At:</span>
          </Col>
          <Col span={18}>
            <Input
              value={
                strategicPlanDetails.approved_at &&
                moment(strategicPlanDetails.approved_at).format("DD/MM/YYYY")
              }
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default StrategicPlanOtherDetails;
