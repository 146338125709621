import { Modal, Spin } from "antd";
import Text from "antd/lib/typography/Text";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { appUiActions } from "../../../config/actions/app/appUi.actions";

const PermissionsDetails = () => {
  const { loading, success, data, error } = useSelector(
    (state) => state.permissionsState.permission
  );
  const permissionsDetails = (data, info, key) => {
    try {
      return data[info][key];
    } catch (error) {
      return "";
    }
  };

  const permissions = "permission";
  const visible = useSelector(
    (state) => state.AppUi.settings.permissionsDetailsModal
  );
  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch(appUiActions.togglePermissionDetails(false));
  };
  return (
    <div>
      <Modal
        title={data.permission?.name?.toUpperCase()}
        onOk={closeModal}
        onCancel={closeModal}
        toggle
        open={visible}
      >
        {loading ? (
          <Spin />
        ) : (
          <>
            {!success ? (
              <div className="alert alert-danger m-3">{error}</div>
            ) : (
              <div className="details_div">
                <table className="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th scope="col">Column</th>
                      <th scope="col">Data</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>{data.permission?.name}</td>
                    </tr>
                    <tr>
                      <td>Application</td>
                      <td>{data.permission?.function_id}</td>
                    </tr>
                    <tr>
                      <td>Role</td>
                      <td>{data.permissionsRole?.role}</td>
                    </tr>
                    <tr>
                      <td>Created By</td>
                      <td>{`${data?.creator?.first_name} ${data?.creator?.other_names}`}</td>
                    </tr>
                    <tr>
                      <td>Created At</td>
                      <td>
                        {`${new Date(
                          data.permission?.created_at
                        ).toDateString()} ${new Date(
                          data.permission?.created_at
                        ).toLocaleTimeString()}
                                   `}
                      </td>
                    </tr>
                    <tr>
                      <td>Last Updated By</td>
                      <td>
                        {data?.updator &&
                          `${data?.updator?.first_name} ${data?.updator?.other_names}`}
                      </td>
                    </tr>
                    <tr>
                      <td>Last Updated At</td>
                      <td>
                        {data.permission?.updated_at &&
                          `${new Date(
                            data.permission?.updated_at
                          ).toDateString()} ${new Date(
                            data.permission?.updated_at
                          ).toLocaleTimeString()}`}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <h5 className="mt-3 mb-0">Permissions</h5>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">
                    <Text strong>Create Record:</Text>
                    {permissionsDetails(data, permissions, "create_record") ? (
                      <div className="text-success">Granted</div>
                    ) : (
                      <div className="text-danger">Denied</div>
                    )}
                  </li>
                  <li class="list-group-item">
                    <Text strong>View Records:</Text>
                    {permissionsDetails(data, permissions, "read") ? (
                      <div className="text-success">Granted</div>
                    ) : (
                      <div className="text-danger">Denied</div>
                    )}
                  </li>
                  <li class="list-group-item">
                    <Text strong>Delete Record:</Text>
                    {permissionsDetails(data, permissions, "delete") ? (
                      <div className="text-success">Granted</div>
                    ) : (
                      <div className="text-danger">Denied</div>
                    )}
                  </li>
                  <li class="list-group-item">
                    <Text strong>Update Record:</Text>
                    {permissionsDetails(data, permissions, "update") ? (
                      <div className="text-success">Granted</div>
                    ) : (
                      <div className="text-danger">Denied</div>
                    )}
                  </li>
                  <li class="list-group-item">
                    <Text strong>Check Record:</Text>
                    {permissionsDetails(data, permissions, "check_record") ? (
                      <div className="text-success">Granted</div>
                    ) : (
                      <div className="text-danger">Denied</div>
                    )}
                  </li>
                  <li class="list-group-item">
                    <Text strong>Approve Record:</Text>
                    {permissionsDetails(data, permissions, "approve") ? (
                      <div className="text-success">Granted</div>
                    ) : (
                      <div className="text-danger">Denied</div>
                    )}
                  </li>
                </ul>
              </div>
            )}
          </>
        )}
      </Modal>
    </div>
  );
};

export default PermissionsDetails;
