const initialState = {
  storesRegister: {
    loading: false,
    success: false,
    error: "",
    data: [],
  },
  storesRegisterReceiptDetails: {
    loading: false,
    success: false,
    error: "",
    data: {
      id: "",
      issue_date: "",
      name: "",
      project: "",
      quantity: 15,
      rate: "",
      remarks: "",
      source_of_funds: "",
      status: "",
      total_price: "",
      updated_at: "",
      updated_by: "",
      updator: {
        first_name: "",
        other_names: "",
      },
      creator: {
        first_name: "",
        other_names: "",
      },
      checker: {
        first_name: "",
        other_names: "",
      },
      approver: {
        first_name: "",
        other_names: "",
      },
    },
  },
  storesRegisterIssuesDetails: {
    loading: false,
    success: false,
    error: "",
    data: {},
  },
  addStoresRegisterReceipt: {
    loading: false,
    success: false,
    error: "",
    message: "",
  },
  addStoresRegisterIssue: {
    loading: false,
    success: false,
    error: "",
    message: "",
  },
  updateStoresRegisterReceipt: {
    loading: false,
    success: false,
    data: {},
    error: "",
    message: "",
  },
  updateStoresRegisterIssue: {
    loading: false,
    success: false,
    data: {},
    error: "",
    message: "",
  },
  deleteStoresRegisterReceipt: {
    loading: false,
    success: false,
    error: "",
    message: "",
  },
  deleteStoresRegisterIssue: {
    loading: false,
    success: false,
    error: "",
    message: "",
  },
  approveStoresRegisterReceipt: {
    loading: false,
    success: false,
    message: "",
    error: "",
  },
  checkStoresRegisterReceipt: {
    loading: false,
    success: false,
    message: "",
    error: "",
  },
  approveStoresRegisterIssueLoading: false,
  approveStoresRegisterIssueSuccess: false,
  approveStoresRegisterIssueError: "",
  approveStoresRegisterIssueMessage: "",
  checkStoresRegisterIssue: {
    loading: false,
    success: false,
    message: "",
    error: "",
  },
};

export default initialState;
