import { Alert, Spin } from "antd";
import React from "react";
import { Line } from "react-chartjs-2";
import { useSelector } from "react-redux";

const BudgetLinesGraph = () => {
  const {
    budgetsGraphData,
    budgetsGraphDataLoading,
    budgetsGraphDataSuccess,
    budgetsGraphDataError,
  } = useSelector((state) => state.budgetState);
  let budgetLines = [];
  const actualExpenditure = [];
  const variance = [];
  const approvedBudget = [];
  budgetsGraphData.budgetGraphData?.forEach((element) => {
    budgetLines.push(
      `${element.line.slice(0, 30)}${element.line.length > 30 ? "..." : ""}`
    );
    approvedBudget.push(element.approvedBudgetExpenditure);
    actualExpenditure.push(element.budgetLineActualExpenditure);
    variance.push(
      element.approvedBudgetExpenditure - element.budgetLineActualExpenditure
    );
  });

  const data = {
    labels: budgetLines,
    datasets: [
      {
        label: "Approved Budget",
        data: approvedBudget,
        backgroundColor: ["rgb(54, 162, 235,.1)"],
        borderColor: ["rgb(54, 162, 235,1)"],
        borderWidth: 1,
        fill: true,
        tension: 0.1,
      },
      {
        fill: true,
        tension: 0.1,
        label: "Actual Expenditure",
        data: actualExpenditure,
        backgroundColor: ["rgb(255, 99, 132,.1)"],
        borderColor: ["rgba(255, 149, 104,1)"],
        borderWidth: 1,
      },
      {
        fill: true,
        tension: 0.1,
        label: "Variance",
        data: variance,
        backgroundColor: ["rgba(75, 192, 192, 0.1)"],
        borderColor: ["rgba(75, 192, 192, 1)"],
        borderWidth: 1,
      },
    ],
  };
  return (
    <div className="m-1 w-100  shadow p-2 w-sm-100 ">
      {budgetsGraphDataLoading ? (
        <div className="d-flex justify-content-center m-5">
          <Spin size="large" />
        </div>
      ) : (
        <div>
          {!budgetsGraphDataSuccess ? (
            <Alert type="error" showIcon message={budgetsGraphDataError} />
          ) : (
            <div>
              <h5>Budget Analysis</h5>
              <div className="h-350">
                <Line
                  data={data}
                  options={{
                    scales: {
                      x: {
                        grid: {
                          display: true,
                        },
                        offset: true,
                      },
                      y: {
                        grid: {
                          display: false,
                        },
                      },
                    },
                    responsive: true,
                    plugins: { legend: { display: true, textDirection: "" } },
                    maintainAspectRatio: false,
                  }}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default BudgetLinesGraph;
