import { Button, message, Popconfirm, Popover, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";
import { manualsAndPoliciesActions } from "../../../../config/actions/administration/manualsAndPolicies/manualsAndPolicies.actions";
import appConfig from "../../../../config/config.json";
import generatePDF from "../../../../config/services/generatePdf";
import TableTitle from "../../../shared/Tables/TableTitle";
import tableButtonActions from "../../../shared/Tables/TableButtonActions";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 16, color: "#fff" }} spin />
);

const ManualsAndPoliciesTable = ({
  handleManualsAndPoliciesRecords,
  handleRefreshTable,
  handleViewDetails,
  handleExportRecords,
}) => {
  const [deleteId, setDeleteId] = useState("");
  const deleteLoading = useSelector(
    (state) => state.manualsAndPoliciesState.deleteManualsAndPolicies.loading
  );
  const { loading, data } = useSelector(
    (state) => state.manualsAndPoliciesState.manualsAndPolicies
  );
  const {
    success: logDetailsSuccess,
    error: logDetailsError,
    records,
  } = useSelector((state) => state.logDetailsState.addLogDetails);
  const [filterTable, setfilterTable] = useState(null);
  const dispatch = useDispatch();
  /** functions */
  const search = (value) => {
    const filterTable = data.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(value.toLowerCase())
      )
    );
    setfilterTable(filterTable);
  };
  const deleteRecord = (e) => setDeleteId(e.target.id);
  const confirmDelete = () => handleManualsAndPoliciesRecords(deleteId);
  const refreshTable = () => handleRefreshTable();
  const viewDetails = (e) => handleViewDetails(e.target.id);
  const updateRecord = (e) => {
    const updateRecordArray = data.filter((r) => r.id === e.target.id);
    dispatch(
      manualsAndPoliciesActions.setManualsAndPoliciesUpdateData(
        updateRecordArray[0]
      )
    );
    dispatch(appUiActions.toggleEditManualsAndPoliciesModal(true));
  };
  const generateRecordsPdf = () => {
    const pdfColumns = ["File Name"];
    let pdfRows = [];
    data?.forEach((record) => {
      const row = [record.name];
      pdfRows.push(row);
    });
    generatePDF(
      pdfRows,
      pdfColumns,
      `Amani Intiative Manuals And Policies - ${new Date().toLocaleDateString()}`,
      "Amani Intiatice Manauls And Policies.pdf"
    );
  };
  const exportRecords = () => {
    message.loading("Proccessing document for export please wait ", 3);
    handleExportRecords();
  };
  const viewFile = (e) => {
    dispatch(
      appUiActions.toggleFileView({
        visible: true,
        src: `${appConfig.server_url}${e.target.id}`,
      })
    );
  };
  const openAddModal = () =>
    dispatch(appUiActions.toggleAddManualsAndPoliciesModal(true));
  /** use Effect */
  useEffect(() => {
    if (logDetailsSuccess && records === "manualsAndPolicies") {
      message.info("Started download");
      generateRecordsPdf();
    } else if (!logDetailsSuccess && logDetailsError)
      message.error(logDetailsError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logDetailsSuccess, logDetailsError]);
  const columns = [
    {
      title: "File",
      key: "name",
      render: (text) => (
        <>
          <span className="d-md-none">File:</span>{" "}
          <span id={text.file_path} onClick={viewFile} className="text-primary">
            {text.name}
          </span>{" "}
          <br />
          <div className="d-md-none">
            <Popover
              trigger="click"
              placement="bottom"
              content={() =>
                tableButtonActions(text, {
                  viewDetails,
                  updateRecord,
                  confirmDelete,
                  deleteRecord,
                  deleteLoading,
                  deleteId,
                })
              }
            >
              <Button>Actions</Button>
            </Popover>
          </div>
        </>
      ),
    },
    {
      title: "Action",
      key: "operation",
      responsive: ["sm"],
      render: (text) => (
        <Popover
          trigger="click"
          placement="bottom"
          content={() =>
            tableButtonActions(text, {
              viewDetails,
              updateRecord,
              confirmDelete,
              deleteRecord,
              deleteLoading,
              deleteId,
            })
          }
        >
          <Button>Actions</Button>
        </Popover>
      ),
    },
  ];

  const setfilterTableNull = (e) => {
    if (!e.target.value) setfilterTable(null);
  };

  const showTotal = (total) => `Total: ${total}`;

  return (
    <div className="">
      <Table
        pagination={{ total: data?.length, showSizeChanger: true, showTotal }}
        loading={loading}
        title={() =>
          TableTitle({
            search,
            setfilterTableNull,
            refreshTable,
            exportRecords,
            openAddModal,
          })
        }
        bordered={true}
        dataSource={filterTable === null ? data : filterTable}
        columns={columns}
      />
    </div>
  );
};

export default ManualsAndPoliciesTable;
