import {
  Alert,
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
} from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";

const { Option } = Select;

const AddProjects = ({ handleAddRecord }) => {
  //state,variable, decalrations and initialization
  const {
    addProjectsSuccess,
    addProjectsError,
    addProjectsMessage,
    addProjectLoading,
    projectsMetaData,
    projectsMetaDataLoading,
    projects,
  } = useSelector((state) => state.projectsState);
  const { addProjectsModal: visible } = useSelector((state) => state.AppUi);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  /** functions */
  const closeModal = () => dispatch(appUiActions.toggleAddProjectsModal(false));
  const onFinish = (values) => {
    values.created_at = new Date();
    values.approved_budget = values.approved_budget + values.currency;
    handleAddRecord(values, projects);
  };
  /** use Effects */
  useEffect(() => {
    console.log({ addProjectsSuccess, addProjectsMessage });
    if (addProjectsMessage) {
      message.success(addProjectsMessage);
      form.resetFields();
    }
  }, [addProjectsMessage, addProjectsSuccess, form]);
  return (
    <div>
      <Modal
        footer={null}
        onCancel={closeModal}
        title="ADD NEW PROJECT"
        open={visible}
        width={800}
      >
        <Form
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 16 }}
          layout="horizontal"
          form={form}
          onFinish={onFinish}
        >
          {!addProjectsSuccess && addProjectsError && (
            <Alert
              className="my-2"
              showIcon
              type="error"
              message={addProjectsError}
            />
          )}
          <div className="d-md-flex">
            <div className="w-50 w-sm-100">
              <Form.Item
                name="name"
                label="Project"
                rules={[
                  { required: true, message: "Please add a project name " },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="start_date"
                label="Start Date"
                rules={[{ required: true, message: "Please add a start Date" }]}
              >
                <DatePicker className="w-100" />
              </Form.Item>
              <Form.Item
                name="end_date"
                label="End Date"
                rules={[{ required: true, message: "Please add a end Date" }]}
              >
                <DatePicker className="w-100" />
              </Form.Item>
              <Form.Item
                name="approved_budget"
                label="Approved Budget"
                rules={[
                  {
                    required: true,
                    message: "Please a upper limit for the budget",
                  },
                ]}
              >
                <InputNumber precision={0} className="w-100" />
              </Form.Item>
              <Form.Item
                label="Donor"
                name="donor"
                rules={[{ required: true, message: "Please select a donor" }]}
              >
                <Select
                  defaultValue=""
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  loading={projectsMetaDataLoading}
                >
                  {projectsMetaData?.donors?.map((donor, id) => {
                    return (
                      <Option value={donor.id} key={donor.id}>
                        {`${donor.name}`}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                name="currency"
                label="Currency"
                rules={[
                  {
                    required: true,
                    message: "Please select a currency",
                  },
                ]}
              >
                <Select className="w-50">
                  <Option value="D">USD</Option>
                  <Option value="U">UGX</Option>
                </Select>
              </Form.Item>
            </div>
            <div className="w-50 w-sm-100">
              <Form.Item
                name="bank"
                label="Bank"
                rules={[{ required: true, message: "Please add a  bank " }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="account_number"
                label="Account number"
                rules={[
                  { required: true, message: "Please add an  account number " },
                ]}
              >
                <InputNumber precision={0} className="w-100" />
              </Form.Item>
              <Form.Item
                label="Person Incharge"
                name="person_incharge"
                rules={[
                  {
                    required: true,
                    message: "Please select a person Incharge",
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  loading={projectsMetaDataLoading}
                >
                  {projectsMetaData?.users?.map((user, id) => {
                    return (
                      <Option value={user.id} key={user.id}>
                        {`${user.first_name} ${user.other_names}`}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                label="Project Supervisor"
                name="supervisor"
                rules={[
                  {
                    required: true,
                    message: "Please select a person Incharge",
                  },
                ]}
              >
                <Select
                  defaultValue=""
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  loading={projectsMetaDataLoading}
                >
                  {projectsMetaData?.users?.map((user, id) => {
                    return (
                      <Option value={user.id} key={user.id}>
                        {`${user.first_name} ${user.other_names}`}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                label="Project Accountant"
                name="project_accountant"
                rules={[
                  {
                    required: true,
                    message: "Please select a person Incharge",
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  loading={projectsMetaDataLoading}
                >
                  {projectsMetaData?.users?.map((user, id) => {
                    return (
                      <Option value={user.id} key={user.id}>
                        {`${user.first_name} ${user.other_names}`}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
          </div>
          <Form.Item className={"mt-3"} wrapperCol={{ offset: 5 }}>
            <Button
              loading={addProjectLoading}
              htmlType="submit"
              type="primary"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddProjects;
