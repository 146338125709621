import {
  Alert,
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Modal,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { storesRegisterActions } from "../../../../config/actions/administration/storesRegister/storesRegister.actions";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";
import moment from "moment";

const EditReceiptForm = ({ handleEditRecord }) => {
  const dispatch = useDispatch();
  //use selector to get state from redux state
  const { error, success, loading, data } = useSelector(
    (state) => state.storesRegisterState.updateStoresRegisterReceipt
  );
  const { loading: metaDataLoading, data: metaData } = useSelector(
    (state) => state.assetRegisterState.metaData
  );
  const visible = useSelector(
    (state) =>
      state.AppUi.admisnistration.storesRegister.editStoresRegisterReceiptModal
  );
  //submit form
  const onFinish = (values) => {
    values.updatedAt = new Date();
    values.id = data.id;
    values.issues = data.issues;
    dispatch(storesRegisterActions.setStoresRegisterReceiptUpdateData(values));
    handleEditRecord(values);
  };
  //close modal
  const closeAddRoleModal = () => {
    dispatch(appUiActions.toggleEditStoresRegisterReceiptModal(false));
  };
  const fields = [
    { name: "name", value: data.name },
    { name: "source_of_funds", value: data.source_of_funds },
    { name: "project", value: data.project },
    { name: "issue_date", value: moment(data.issue_date) },
    { name: "rate", value: data.rate },
    { name: "quantity", value: data.quantity },
    { name: "remarks", value: data.remarks },
  ];
  return (
    <div>
      <Modal
        onCancel={closeAddRoleModal}
        open={visible}
        title="EDIT ITEM"
        footer={null}
        width={800}
      >
        <Form
          fields={fields}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          layout="horizontal"
          onFinish={onFinish}
        >
          {!success && error && (
            <Alert message={error} showIcon type="error" className="m-2" />
          )}
          <div className="d-sm-flex">
            <div className="w-50 w-sm-100">
              <Form.Item
                name="name"
                label="Item Name"
                rules={[{ required: true, message: "Please add a first name" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="source_of_funds"
                label="Source Of Funds"
                rules={[
                  { required: true, message: "Please add a source funds" },
                ]}
              >
                <Select
                  showSearch
                  loading={metaDataLoading}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {metaData?.donors?.map((donors) => (
                    <Select.Option key={donors.id} value={donors.id}>
                      {donors.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Project"
                name="project"
                rules={[{ required: true, message: "Please add a project" }]}
              >
                <Select
                  showSearch
                  loading={metaDataLoading}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {metaData?.projects?.map((project) => (
                    <Select.Option key={project.id} value={project.id}>
                      {project.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Issue Date"
                name="issue_date"
                rules={[
                  {
                    required: true,
                    message: "Please  add an issue date",
                  },
                ]}
              >
                <DatePicker className="w-100" />
              </Form.Item>
            </div>
            <div className="w-50 w-sm-50">
              <Form.Item
                label="Rate"
                name="rate"
                rules={[{ required: true, message: "Please add a rate" }]}
              >
                <InputNumber precision={0} className="w-100" />
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: "Please add a quantity" }]}
                name="quantity"
                label="Quantity"
              >
                <InputNumber precision={0} className="w-100" />
              </Form.Item>
              <Form.Item name="remarks" label={"Remarks"}>
                <TextArea />
              </Form.Item>
            </div>
          </div>
          <Form.Item className={"mt-3"} wrapperCol={{ offset: 4, span: 16 }}>
            <Button loading={loading} htmlType="submit" type="primary">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default EditReceiptForm;
