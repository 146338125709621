export const rolesConstants = {
  GET_ROLES_REQUEST: "GET_ROLES_REQUEST",
  GET_ROLES_SUCCESS: "GET_ROLES_SUCCESS",
  GET_ROLES_ERROR: "GET_ROLES_ERROR",

  ADD_ROLES_REQUEST: "ADD_ROLES_REQUEST",
  ADD_ROLES_SUCCESS: "ADD_ROLES_SUCCESS",
  ADD_ROLES_ERROR: "ADD_ROLES_ERROR",
  ADD_ROLE_COMPETE: "ADD_ROLE_COMPETE",

  DELETE_ROLES_REQUEST: "DELETE_ROLES_REQUEST",
  DELETE_ROLES_SUCCESS: "DELETE_ROLES_SUCCESS",
  DELETE_ROLES_ERROR: "DELETE_ROLES_ERROR",
  DELETE_ROLES_COMPLETED: "DELETE_ROLES_COMPLETED",

  UPDATE_ROLE_REQUEST: "UPDATE_ROLE_REQUEST",
  UPDATE_ROLE_SUCCESS: "UPDATE_ROLE_SUCCESS",
  UPDATE_ROLE_ERROR: "UPDATE_ROLE_ERROR",
  UPDATE_ROLES_DATA: "UPDATE_ROLES_DATA",
  UPDATE_ROLES_COMPLETED: "UPDATE_ROLES_COMPLETED",

  GET_SINGLE_ROLES_ERROR: "GET_SINGLE_ROLES_ERROR",
  GET_SINGLE_ROLES_REQUEST: "GET_SINGLE_ROLES_REQUEST",
  GET_SINGLE_ROLES_SUCCESS: "GET_SINGLE_ROLES_SUCCESS",

  APPROVE_ROLES_REQUEST: "APPROVE_ROLES_REQUEST",
  APPROVE_ROLES_SUCCESS: "APPROVE_ROLES_SUCCESS",
  APPROVE_ROLES_ERROR: "APPROVE_ROLES_ERROR",
  APPROVE_ROLES_COMPLETED: "APPROVE_ROLES_COMPLETED",

  CHECK_ROLES_REQUEST: "CHECK_ROLES_REQUEST",
  CHECK_ROLES_SUCCESS: "CHECK_ROLES_SUCCESS",
  CHECK_ROLES_ERROR: "CHECK_ROLES_ERROR",
  CHECK_ROLES_COMPLETED: "CHECK_ROLES_COMPLETED",
};

export const rolesActions = {
  getRolesRequest: () => ({
    type: rolesConstants.GET_ROLES_REQUEST,
  }),
  getRolesError: (payload) => ({
    type: rolesConstants.GET_ROLES_ERROR,
    payload,
  }),
  getRolesSuccess: (payload) => ({
    type: rolesConstants.GET_ROLES_SUCCESS,
    payload,
  }),
  addRolesRequest: () => ({
    type: rolesConstants.ADD_ROLES_REQUEST,
  }),
  addRoleSuccess: (payload) => ({
    type: rolesConstants.ADD_ROLES_SUCCESS,
    payload,
  }),
  addRoleComplete: () => ({
    type: rolesConstants.ADD_ROLE_COMPETE,
  }),
  addRolesError: (payload) => ({
    type: rolesConstants.ADD_ROLES_ERROR,
    payload,
  }),
  deleteRoleCompleted: () => ({
    type: rolesConstants.DELETE_ROLES_COMPLETED,
  }),
  deleteRolesSuccess: (payload) => ({
    type: rolesConstants.DELETE_ROLES_SUCCESS,
    payload,
  }),
  deleteRolesRequest: () => ({
    type: rolesConstants.DELETE_ROLES_REQUEST,
  }),
  deleteRolesError: (payload) => ({
    type: rolesConstants.DELETE_ROLES_ERROR,
    payload,
  }),
  setUpdateRolesData: (payload) => ({
    type: rolesConstants.UPDATE_ROLES_DATA,
    payload,
  }),
  updateRolesRequest: () => ({
    type: rolesConstants.UPDATE_ROLE_REQUEST,
  }),
  updateRolesSuccess: (payload) => ({
    type: rolesConstants.UPDATE_ROLE_SUCCESS,
    payload,
  }),
  updateRolesError: (payload) => ({
    type: rolesConstants.UPDATE_ROLE_ERROR,
    payload,
  }),
  updateRolesCompleted: () => ({
    type: rolesConstants.UPDATE_ROLES_COMPLETED,
  }),
  getSingleRoleRequest: () => ({
    type: rolesConstants.GET_SINGLE_ROLES_REQUEST,
  }),
  getSingleRoleError: (payload) => ({
    type: rolesConstants.GET_SINGLE_ROLES_ERROR,
    payload,
  }),
  getSingleRoleSuccess: (payload) => ({
    type: rolesConstants.GET_SINGLE_ROLES_SUCCESS,
    payload,
  }),
  approveRolesRequest: () => ({
    type: rolesConstants.APPROVE_ROLES_REQUEST,
  }),
  approveRolesSuccess: (payload) => ({
    type: rolesConstants.APPROVE_ROLES_SUCCESS,
    payload,
  }),
  approveRolesError: (payload) => ({
    type: rolesConstants.APPROVE_ROLES_ERROR,
    payload,
  }),
  approveRolesCompleted: () => ({
    type: rolesConstants.APPROVE_ROLES_COMPLETED,
  }),
  checkRolesRequest: () => ({
    type: rolesConstants.CHECK_ROLES_REQUEST,
  }),
  checkRolesSuccess: (payload) => ({
    type: rolesConstants.CHECK_ROLES_SUCCESS,
    payload,
  }),
  checkRolesError: (payload) => ({
    type: rolesConstants.CHECK_ROLES_ERROR,
    payload,
  }),
  checkRolesCompleted: () => ({
    type: rolesConstants.CHECK_ROLES_COMPLETED,
  }),
};
