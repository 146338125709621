const initialState = {
  donors: {
    loading: false,
    success: false,
    error: "",
    data: [],
  },
  donorDetails: {
    loading: false,
    success: false,
    error: "",
    data: {},
  },
  addDonor: {
    loading: false,
    success: false,
    message: "",
    error: "",
  },
  updateDonor: {
    loading: false,
    success: false,
    error: "",
    message: "",
    data: {},
  },
  deleteDonor: {
    loading: false,
    success: false,
    error: "",
    message: "",
  },
  approveDonor: {
    loading: false,
    success: false,
    error: "",
    message: "",
  },
  checkDonor: {
    loading: false,
    success: false,
    error: "",
    message: "",
  },
  setPersonInchargeLoading: false,
  setPersonInchargeSuccess: false,
  setPersonInchargeMessage: "",
  setPersonInchargeError: "",

  submitProposalStatusLoading: false,
  submitProposalStatusSuccess: false,
  submitProposalStatusError: "",
  submitProposalStatusMessage: "",

  donorsGraphDataLoading: false,
  donorsGraphDataSuccess: false,
  donorsGraphData: {},
  donorsGraphDataError: "",
};

export default initialState;
