import Avatar from "antd/lib/avatar/avatar";
import { Button, message, Spin } from "antd";
import React, { useEffect } from "react";
import authThunks from "../../config/thunks/auth/auth.thunk";
import { useDispatch, useSelector } from "react-redux";
import appConfig from "../../config/config.json";
import { removeAuthToken } from "../../config/helpers/auth/authToken";
import userThunks from "../../config/thunks/users/users.thunks";
import { useHistory } from "react-router-dom";
import routes from "../../config/routes/routes";

const CurrentUserInfo = () => {
  const {
    loading: logoutLoading,
    success: logoutSuccess,
    error: logoutError,
  } = useSelector((state) => state.authState.logoutUser);
  const { data: currentUserInfo, loading: currentUserInfoLoading } =
    useSelector((state) => state.usersState.currentUserInfo);
  const { img_url, email, first_name, other_names } = currentUserInfo;
  const history = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userThunks.currentUserInfo());
  }, [dispatch]);

  useEffect(() => {
    if (!logoutSuccess && logoutError) message.error(logoutError);
    else if (logoutSuccess) {
      removeAuthToken();
      history.push(routes.authentication.login.path);
    }
  }, [logoutSuccess, logoutError, history]);

  const logoutUser = () => {
    dispatch(authThunks.logoutUser());
    if (!logoutSuccess && logoutError) {
      message.error(logoutError);
    } else if (logoutSuccess) {
      removeAuthToken();
      history.push(routes.authentication.login.path);
    }
  };

  const manageUserInfo = () => {
    history.push(routes.settings.profile.path);
  };
  return (
    <>
      {currentUserInfoLoading ? (
        <div className="p-5">
          <Spin size="large" />
        </div>
      ) : (
        <div className="p-1 mx-1 flex-column d-flex justify-content-center align-items-center">
          <div className="profile-img mb-3">
            <Avatar size={64} src={appConfig.server_url + img_url}></Avatar>
          </div>
          <div className="info text-center">
            <p className="m-0 fw-bold">
              {first_name} {other_names}
            </p>
            <p className="m-0 mb-1">{email}</p>
            <Button onClick={manageUserInfo} size="small" type="link">
              Your Profile
            </Button>
            <br /> <br />
            <Button onClick={logoutUser} loading={logoutLoading} type="primary">
              Logout
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default CurrentUserInfo;
