import React, { useEffect, useMemo } from "react";
import { useAssetIncidentFormStore } from "../../../../config/stores";
import usePrevious from "../../../../components/hooks/usePrevious";
import { Divider, Form, Radio, message } from "antd";
import { forEach, isEmpty, map } from "lodash";
import dayjs from "dayjs";
import AntdModal from "../../../../components/common/AntdModal";
import AppInput from "../../../../components/common/AppInput";
import AppButton from "../../../../components/common/AppButton";
import { inputNumberFormatter } from "../../../../config/constants";
import { AiOutlineClose, AiOutlinePlus, AiOutlineReload } from "react-icons/ai";

const AddAssetIncidentForm = () => {
  const assetIncidentFormStore = useAssetIncidentFormStore();
  const {
    createdAssetIncidentForm,
    assetIncidentFormToUpdate,
    creating,
    metaData,
  } = assetIncidentFormStore;

  const [form] = Form.useForm();

  const onSubmit = async (values) => {
    form
      .validateFields()
      .then((values) => {
        values[values.nature_of_survey] = true;

        if (values.disposal_options) {
          values[values.disposal_options] = true;
        }
        delete values.nature_of_survey;
        delete values.disposal_options;
        if (assetIncidentFormStore.creating) {
          assetIncidentFormStore.createAssetIncidentForm(values);
        } else {
          assetIncidentFormStore.updateAssetIncidentForm(
            values,
            assetIncidentFormToUpdate.id
          );
        }
      })
      .catch((error) => {
        message.error("Please fill in all required fields correctly");
      });
  };
  const prevState = usePrevious({ createdAssetIncidentForm });

  const closeModal = () => {
    assetIncidentFormStore.setCreating(false);
  };

  const recommendation = Form.useWatch("recommendation", form);

  useEffect(() => {
    if (
      !isEmpty(prevState) &&
      !isEmpty(createdAssetIncidentForm) &&
      prevState.createdAssetIncidentForm !== createdAssetIncidentForm
    ) {
      form.resetFields();
      assetIncidentFormStore.setCreating(true, true, {});
    }
  }, [prevState, createdAssetIncidentForm, form, assetIncidentFormStore]);

  const natureOfSurveyCheckboxOptions = useMemo(
    () => [
      { label: "Wear", value: "wear", key: "wear" },
      {
        label: "Scheduled Replacement",
        value: "scheduled_replacement",
        key: "scheduled_replacement",
      },
      { label: "Surplus", value: "surplus", key: "surplus" },
      { label: "Loss", value: "loss", key: "loss" },
      { label: "Damage", value: "damage", key: "damage" },
      { label: "Theft", value: "theft" },
      {
        label: "Vehicle Accident",
        name: "vehicle_accident",
        value: "vehicle_accident",
        key: "vehicle_accident",
      },
    ],
    []
  );

  const disposalOptions = useMemo(
    () => [
      { label: "Transfer", value: "transfer" },
      { label: "Destruction", value: "destruction" },
      { label: "Discard", value: "discard" },
      { label: "Re-use of spare parts", value: "reuse_of_spare_parts" },
      { label: "Trade off", value: "trade_off" },
      { label: "Sale", value: "sale" },
    ],
    []
  );

  useEffect(() => {
    if (!isEmpty(assetIncidentFormToUpdate) && !creating) {
      // find true nature of survey
      let natureOfSurvey = "";
      forEach(natureOfSurveyCheckboxOptions, (option) => {
        if (assetIncidentFormToUpdate[option.value] === true) {
          natureOfSurvey = option.value;
        }
      });
      let disposalOption = "";
      forEach(disposalOptions, (option) => {
        if (assetIncidentFormToUpdate[option.value] === true) {
          disposalOption = option.value;
        }
      });

      console.log({
        asset_id: assetIncidentFormToUpdate.asset_id,
        date_of_incident: dayjs(assetIncidentFormToUpdate.date_of_incident),
        recommendation: assetIncidentFormToUpdate.recommendation,
        nature_of_survey: natureOfSurvey,
        others: assetIncidentFormToUpdate.others,
        estimated_repair_cost: assetIncidentFormToUpdate.estimated_repair_cost,
        disposal_options: disposalOption,
        other_disposal_option: assetIncidentFormToUpdate.other_disposal_option,
        general_report: assetIncidentFormToUpdate.general_report,
      });

      form.setFieldsValue({
        asset_id: assetIncidentFormToUpdate.asset_id,
        date_of_incident: dayjs(assetIncidentFormToUpdate.date_of_incident),
        recommendation: assetIncidentFormToUpdate.recommendation,
        nature_of_survey: natureOfSurvey,
        others: assetIncidentFormToUpdate.others,
        estimated_repair_cost: assetIncidentFormToUpdate.estimated_repair_cost,
        disposal_options: disposalOption,
        other_disposal_option: assetIncidentFormToUpdate.other_disposal_option,
        general_report: assetIncidentFormToUpdate.general_report,
      });
    } else {
      form.resetFields();
    }
  }, [
    assetIncidentFormToUpdate,
    form,
    creating,
    disposalOptions,
    natureOfSurveyCheckboxOptions,
  ]);

  const recommendationOptions = [
    {
      label: "Repair",
      value: "repair",
      name: "recommendation",
      className: "w-50",
    },
    { label: "Disposal", value: "disposal", name: "recommendation" },
  ];

  return (
    <div>
      <AntdModal
        open={assetIncidentFormStore.showAssetIncidentForm}
        title={
          assetIncidentFormStore.creating
            ? "CREATE ASSET INCIDENT FORM"
            : "UPDATE ASSET INCIDENT FORM"
        }
        closeModal={closeModal}
        size="large"
        footer={[
          <AppButton
            text={
              assetIncidentFormStore.creating
                ? "CREATE ASSET INCIDENT FORM"
                : "UPDATE ASSET INCIDENT FORM"
            }
            key={"create"}
            loading={
              assetIncidentFormStore.creatingAssetIncidentForm ||
              assetIncidentFormStore.updatingAssetIncidentForm
            }
            iconBefore={<AiOutlinePlus />}
            onClick={onSubmit}
          />,
          <AppButton
            text="RESET FORM"
            type="default"
            key="reset"
            iconBefore={<AiOutlineReload />}
            onClick={() => {
              form.resetFields();
            }}
          />,
          <AppButton
            text="CANCEL"
            key={"cancel"}
            iconBefore={<AiOutlineClose />}
            onClick={closeModal}
            danger
          />,
        ]}
      >
        <Form form={form} layout="vertical">
          <Divider
            orientation="left"
            className="fw-bold text-sm text-uppercase"
          >
            <small>Asset Details</small>
          </Divider>
          <AppInput
            name="date_of_incident"
            type="date"
            label="Date Of Incident"
            rules={[
              {
                required: true,
              },
            ]}
          />
          <AppInput
            name="asset_id"
            type="select"
            label="Asset"
            options={map(metaData?.assets, (asset) => ({
              label: `${asset.name} - ${asset.code}`,
              value: asset.id,
            }))}
            rules={[
              {
                required: true,
              },
            ]}
          />
          <Divider
            orientation="left"
            className="fw-bold text-sm text-uppercase"
          >
            <small>Nature of Survey Case</small>
          </Divider>
          <Form.Item name="nature_of_survey">
            <Radio.Group options={natureOfSurveyCheckboxOptions} />
          </Form.Item>
          <AppInput className="mt-2" name="others" label="Others (Specify)" />
          <Divider
            orientation="left"
            className="fw-bold text-sm text-uppercase"
          >
            <small>Recommended Disposition</small>
          </Divider>
          <Form.Item
            name="recommendation"
            rules={[{ required: true, message: "Please fill in this field" }]}
          >
            <Radio.Group
              block
              options={recommendationOptions}
              optionType="button"
              buttonStyle="solid"
            />
          </Form.Item>
          {recommendation === "repair" && (
            <>
              <AppInput
                name="estimated_repair_cost"
                type="number"
                label="Estimated Repair Cost"
                inputAttributes={{
                  addonBefore: "UGX",
                  formatter: inputNumberFormatter,
                }}
                rules={[
                  {
                    required: true,
                  },
                ]}
              />
            </>
          )}
          {recommendation === "disposal" && (
            <>
              <Form.Item name="disposal_options">
                <Radio.Group options={disposalOptions} />
              </Form.Item>
              <AppInput
                className="mt-2"
                name="other_disposal_option"
                label="Others (Specify)"
              />
            </>
          )}
          <Divider
            orientation="left"
            className="fw-bold text-sm text-uppercase"
          >
            <small>General Incidence Report and Recommendation</small>
          </Divider>
          <AppInput
            rules={[
              {
                required: true,
              },
            ]}
            className="mt-2"
            name="general_report"
            type="textarea"
          />

          <Divider />
        </Form>
      </AntdModal>
    </div>
  );
};

export default AddAssetIncidentForm;
