import { chartOfAccountsActions } from "../../../actions/finance/chartOfAccounts/chartOfAccounts.actions";
import chartOfAccountApiRequests from "../../../api/finance/chartOfAccounts/chartOfAccounts.api";

const chartOfAccountsThunks = {
  getChartOfAccounts: () => async (dispatch) => {
    dispatch(chartOfAccountsActions.setChartOfAccountsRequest());
    const res = await chartOfAccountApiRequests.fetchChartOfAccounts();
    if (res.success)
      dispatch(chartOfAccountsActions.setChartOfAccountsSuccess(res.data));
    else dispatch(chartOfAccountsActions.setChartOfAccountsError(res.error));
  },
  getMetaData: () => async (dispatch) => {
    dispatch(chartOfAccountsActions.chartOfAccountsMetaDataRequest());
    const res = await chartOfAccountApiRequests.fetchMetaData();
    if (res.success)
      dispatch(chartOfAccountsActions.chartOfAccountsMetaData(res.data));
    else dispatch(chartOfAccountsActions.chartOfAccountsMetaData({}));
  },
  getAccountDetails: (id) => async (dispatch) => {
    dispatch(chartOfAccountsActions.setAccountDetailsRequest());
    const res = await chartOfAccountApiRequests.fetchAccountDetails(id);
    if (res.success)
      dispatch(chartOfAccountsActions.setAccountDetailsSuccess(res.data));
    else dispatch(chartOfAccountsActions.setAccountDetailsError(res.error));
  },
  getAccountTypeDetails: (id) => async (dispatch) => {
    dispatch(chartOfAccountsActions.setAccountTypeDetailsRequest());
    const res = await chartOfAccountApiRequests.fetchAccountTypeDetails(id);
    if (res.success)
      dispatch(chartOfAccountsActions.setAccountTypeDetailsSuccess(res.data));
    else dispatch(chartOfAccountsActions.setAccountTypeDetailsError(res.error));
  },
  addAccount: (body) => async (dispatch) => {
    dispatch(chartOfAccountsActions.addAccountRequest());
    const res = await chartOfAccountApiRequests.addAccount(body);
    if (res.success) {
      dispatch(chartOfAccountsThunks.getChartOfAccounts());
      dispatch(chartOfAccountsThunks.getMetaData());
      dispatch(chartOfAccountsActions.addAccountSuccess(res.message));
    } else dispatch(chartOfAccountsActions.addAccountError(res.error));
    dispatch(chartOfAccountsActions.addAccountComplete());
  },
  addAccountType: (body) => async (dispatch) => {
    dispatch(chartOfAccountsActions.addAccountTypeRequest());
    const res = await chartOfAccountApiRequests.addAccountType(body);
    if (res.success) {
      dispatch(chartOfAccountsThunks.getChartOfAccounts());
      dispatch(chartOfAccountsThunks.getMetaData());
      dispatch(chartOfAccountsActions.addAccountTypeSuccess(res.message));
    } else dispatch(chartOfAccountsActions.addAccountTypeError(res.error));
    dispatch(chartOfAccountsActions.addAccountTypeComplete());
  },
  editAccount: (body) => async (dispatch) => {
    dispatch(chartOfAccountsActions.editAccountRequest());
    const res = await chartOfAccountApiRequests.editAccount(body);
    if (res.success) {
      dispatch(chartOfAccountsThunks.getChartOfAccounts());
      dispatch(chartOfAccountsThunks.getMetaData());
      dispatch(chartOfAccountsActions.editAccountSuccess(res.message));
    } else dispatch(chartOfAccountsActions.editAccountError(res.error));
    dispatch(chartOfAccountsActions.editAccountComplete());
  },
  editAccountType: (body) => async (dispatch) => {
    dispatch(chartOfAccountsActions.editAccountTypeRequest());
    const res = await chartOfAccountApiRequests.editAccountType(body);
    if (res.success) {
      dispatch(chartOfAccountsThunks.getChartOfAccounts());
      dispatch(chartOfAccountsThunks.getMetaData());
      dispatch(chartOfAccountsActions.editAccountTypeSuccess(res.message));
    } else dispatch(chartOfAccountsActions.editAccountTypeError(res.error));
    dispatch(chartOfAccountsActions.editAccountTypeComplete());
  },
  deleteAccount: (id, name) => async (dispatch) => {
    dispatch(chartOfAccountsActions.deleteAccountRequest());
    const res = await chartOfAccountApiRequests.deleteAccount(id, name);
    if (res.success) {
      dispatch(chartOfAccountsThunks.getChartOfAccounts());
      dispatch(chartOfAccountsThunks.getMetaData());
      dispatch(chartOfAccountsActions.deleteAccountSuccess(res.message));
    } else dispatch(chartOfAccountsActions.deleteAccountError(res.error));
    dispatch(chartOfAccountsActions.deleteAccountComplete());
  },
  deleteAccountType: (id, name) => async (dispatch) => {
    dispatch(chartOfAccountsActions.deleteAccountTypeRequest());
    const res = await chartOfAccountApiRequests.deleteAccountType(id, name);
    if (res.success) {
      dispatch(chartOfAccountsThunks.getChartOfAccounts());
      dispatch(chartOfAccountsThunks.getMetaData());
      dispatch(chartOfAccountsActions.deleteAccountTypeSuccess(res.message));
    } else dispatch(chartOfAccountsActions.deleteAccountTypeError(res.error));
    dispatch(chartOfAccountsActions.deleteAccountTypeComplete());
  },
  checkAccount: (id) => async (dispatch) => {
    dispatch(chartOfAccountsActions.checkAccountRequest());
    const res = await chartOfAccountApiRequests.checkAccount(id);
    if (res.success) {
      dispatch(chartOfAccountsThunks.getAccountDetails(id));
      dispatch(chartOfAccountsActions.checkAccountSuccess(res.message));
    } else dispatch(chartOfAccountsActions.checkAccountError(res.error));
    dispatch(chartOfAccountsActions.checkAccountComplete());
  },
  checkAccountType: (id) => async (dispatch) => {
    dispatch(chartOfAccountsActions.checkAccountTypeRequest());
    const res = await chartOfAccountApiRequests.checkAccountType(id);
    if (res.success) {
      dispatch(chartOfAccountsThunks.getAccountTypeDetails(id));
      dispatch(chartOfAccountsActions.checkAccountTypeSuccess(res.message));
    } else dispatch(chartOfAccountsActions.checkAccountTypeError(res.error));
    dispatch(chartOfAccountsActions.checkAccountTypeComplete());
  },
  approveAccount: (id) => async (dispatch) => {
    dispatch(chartOfAccountsActions.approveAccountRequest());
    const res = await chartOfAccountApiRequests.approveAccount(id);
    if (res.success) {
      dispatch(chartOfAccountsThunks.getAccountDetails(id));
      dispatch(chartOfAccountsActions.approveAccountSuccess(res.message));
    } else dispatch(chartOfAccountsActions.approveAccountError(res.error));
    dispatch(chartOfAccountsActions.approveAccountComplete());
  },
  approveAccountType: (id) => async (dispatch) => {
    dispatch(chartOfAccountsActions.approveAccountTypeRequest());
    const res = await chartOfAccountApiRequests.approveAccountType(id);
    if (res.success) {
      dispatch(chartOfAccountsThunks.getAccountTypeDetails(id));
      dispatch(chartOfAccountsActions.approveAccountTypeSuccess(res.message));
    } else dispatch(chartOfAccountsActions.approveAccountTypeError(res.error));
    dispatch(chartOfAccountsActions.approveAccountTypeComplete());
  },
};

export default chartOfAccountsThunks;
