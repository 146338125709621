import { assetRegisterActions } from "../../../actions/administration/assetRegister/assetRegister.actions";
import { appUiActions } from "../../../actions/app/appUi.actions";
import {
  addAssetCategoryRequest,
  addAssetRequest,
  approveAssetRequest,
  approveAssetTypeRequest,
  checkAssetRequest,
  checkAssetTypesRequest,
  deleteAssetCategoryRequest,
  deleteAssetRequest,
  fetchAssetDetailsRequest,
  fetchAssetsCategoryRequest,
  fetchAssetsRequest,
  fetchAssetTypesDetailsRequest,
  getStaffRecordsRequest,
  updateAssetCategoryRequest,
  updateAssetRequest,
} from "../../../api/administration/assetRegister/assetRegister.api";
import apiRequests from "../../../api/api";

const errorMessage =
  "Sorry there seems to be an error please try again in a few minutes";

const assetRegisterThunk = {
  fetchAssets: () => async (dispatch) => {
    dispatch(assetRegisterActions.fetchAssetsRequest());
    const res = await fetchAssetsRequest();
    try {
      if (res.success)
        dispatch(assetRegisterActions.fetchAssetsSuccess(res.data));
      else dispatch(assetRegisterActions.fetchAssetsError(res.error));
    } catch (error) {
      console.error(error);
      dispatch(assetRegisterActions.fetchAssetsError(errorMessage));
    }
  },
  getAssetRegisterGraphData: () => async (dispatch) => {
    dispatch(assetRegisterActions.setAssetRegisterGraphDataRequest());
    const res = await apiRequests.getRequest(
      "/administration/assetRegister/graphData"
    );
    if (res.success)
      dispatch(assetRegisterActions.setAssetRegisterGraphDataSuccess(res.data));
    else
      dispatch(assetRegisterActions.setAssetRegisterGraphDataError(res.error));
  },
  addAsset: (body) => async (dispatch) => {
    dispatch(assetRegisterActions.addAssetRequest());
    const res = await addAssetRequest(body);
    try {
      if (res.success) {
        dispatch(appUiActions.toggleAddAssetModal());
        dispatch(assetRegisterActions.addAssetSuccess(res.data[0]));
        dispatch(assetRegisterActions.addAssetCompleted());
      } else dispatch(assetRegisterActions.addAssetError(res.error));
    } catch (error) {
      console.error(error);
      dispatch(assetRegisterActions.addAssetError(errorMessage));
    }
  },
  updateAsset: (body, id, assets) => async (dispatch) => {
    dispatch(assetRegisterActions.updateAssetRequest());

    const res = await updateAssetRequest(body, id);
    try {
      if (res.success) {
        const updatedArray = [res.data[0], ...assets];
        dispatch(assetRegisterActions.updateAssetSuccess(updatedArray));
        dispatch(assetRegisterActions.updateAssetCompleted());
        dispatch(appUiActions.toggleEditAssetModal(false));
      } else dispatch(assetRegisterActions.updateAssetError(res.error));
    } catch (error) {
      console.error(error);
      dispatch(assetRegisterActions.updateAssetError(errorMessage));
    }
  },
  deleteAsset: (id, payload) => async (dispatch) => {
    dispatch(assetRegisterActions.deleteAssetRequest());
    const res = await deleteAssetRequest(id);
    try {
      if (res.success) {
        dispatch(assetRegisterActions.deleteAssetSuccess(payload));
        dispatch(assetRegisterActions.deleteAssetCompleted());
      } else {
        dispatch(assetRegisterActions.deleteAssetError(res.error));
        dispatch(assetRegisterActions.deleteAssetCompleted());
      }
    } catch (error) {
      console.error(error);
      dispatch(assetRegisterActions.deleteAssetError(errorMessage));
      dispatch(assetRegisterActions.deleteAssetCompleted());
    }
  },
  fetchAssetRegisterCategories: () => async (dispatch) => {
    dispatch(assetRegisterActions.fetchAssetsCategoryRequest());
    const res = await fetchAssetsCategoryRequest();
    try {
      if (res.success)
        dispatch(assetRegisterActions.fetchAssetsCategorySuccess(res.data));
      else dispatch(assetRegisterActions.fetchAssetsCategoryError(res.error));
    } catch (error) {
      console.error(error);
      dispatch(assetRegisterActions.fetchAssetsCategoryError(errorMessage));
    }
  },
  addAssetCategory: (body) => async (dispatch) => {
    dispatch(assetRegisterActions.addAssetCategoryRequest());
    const res = await addAssetCategoryRequest(body);
    try {
      if (res.success) {
        dispatch(assetRegisterActions.addAssetCategorySuccess(res.data));
        dispatch(assetRegisterActions.addAssetCategoryCompleted());
        dispatch(appUiActions.toggleAddAssetTypesModal(false));
      } else dispatch(assetRegisterActions.addAssetCategoryError(res.error));
    } catch (error) {
      dispatch(assetRegisterActions.addAssetCategoryError(errorMessage));
    }
  },
  updateAssetCategory: (body, id, assetsCategories) => async (dispatch) => {
    dispatch(assetRegisterActions.updateAssetCategoryRequest());
    const res = await updateAssetCategoryRequest(body, id);

    try {
      if (res.success) {
        dispatch(
          assetRegisterActions.updateAssetCategorySuccess([
            res.data,
            ...assetsCategories,
          ])
        );
        dispatch(assetRegisterActions.updateAssetCategoryCompleted());
        dispatch(appUiActions.toggleEditAssetTypesModal(false));
      } else dispatch(assetRegisterActions.updateAssetCategoryError(res.error));
    } catch (error) {
      console.error(error);
      dispatch(assetRegisterActions.updateAssetCategoryError(errorMessage));
    }
  },
  deleteAssetCategory: (id, assetTypes) => async (dispatch) => {
    dispatch(assetRegisterActions.deleteAssetCategoryRequest());
    const res = await deleteAssetCategoryRequest(id);
    try {
      if (res.success) {
        dispatch(assetRegisterActions.deleteAssetCategorySuccess(assetTypes));
        dispatch(assetRegisterActions.deleteAssetCategoryCompleted());
      } else {
        dispatch(assetRegisterActions.deleteAssetCategoryError(res.error));
        dispatch(assetRegisterActions.deleteAssetCategoryCompleted());
      }
    } catch (error) {
      console.error(error);
      dispatch(assetRegisterActions.deleteAssetCategoryError(errorMessage));
    }
  },
  getAssetDetails: (id) => async (dispatch) => {
    try {
      dispatch(assetRegisterActions.setAssetDetailsRequest());
      const res = await fetchAssetDetailsRequest(id);
      if (res.success)
        dispatch(assetRegisterActions.setAssetDetailsSuccess(res.data));
      else dispatch(assetRegisterActions.setAssetDetailsError(res.error));
    } catch (error) {
      console.error(error);
      dispatch(assetRegisterActions.setAssetDetailsError(errorMessage));
    }
  },
  getAssetTypesDetails: (id) => async (dispatch) => {
    try {
      dispatch(assetRegisterActions.setAssetTypesDetailsRequest());
      const res = await fetchAssetTypesDetailsRequest(id);
      if (res.success)
        dispatch(assetRegisterActions.setAssetTypesDetailsSuccess(res.data));
      else dispatch(assetRegisterActions.setAssetTypesDetailsError(res.error));
    } catch (error) {
      console.error(error);
      dispatch(assetRegisterActions.setAssetTypesDetailsError(errorMessage));
    }
  },
  approveAsset: (id) => async (dispatch) => {
    dispatch(assetRegisterActions.approveAssetRequest());
    try {
      const res = await approveAssetRequest(id);
      if (res.success) {
        dispatch(assetRegisterActions.approveAssetSuccess(res.message));
        dispatch(assetRegisterThunk.getAssetDetails(id));
      } else dispatch(assetRegisterActions.approveAssetError(res.error));
    } catch (error) {
      console.error(error);
      dispatch(assetRegisterActions.approveAssetError(errorMessage));
    }
    dispatch(assetRegisterActions.approveAssetCompleted());
  },
  approveAssetType: (id) => async (dispatch) => {
    dispatch(assetRegisterActions.approveAssetTypeRequest());
    try {
      const res = await approveAssetTypeRequest(id);
      if (res.success) {
        dispatch(assetRegisterActions.approveAssetTypeSuccess(res.message));
        dispatch(assetRegisterThunk.getAssetTypesDetails(id));
      } else dispatch(assetRegisterActions.approveAssetTypeError(res.error));
    } catch (error) {
      console.error(error);
      dispatch(assetRegisterActions.approveAssetTypeError(errorMessage));
    }
    dispatch(assetRegisterActions.approveAssetTypeCompleted());
  },
  checkAsset: (id) => async (dispatch) => {
    dispatch(assetRegisterActions.checkAssetRequest());
    try {
      const res = await checkAssetRequest(id);
      if (res.success) {
        dispatch(assetRegisterActions.checkAssetSuccess(res.message));
        dispatch(assetRegisterThunk.getAssetDetails(id));
      } else dispatch(assetRegisterActions.checkAssetError(res.error));
    } catch (error) {
      console.error("Check asset thunk error", error);
      dispatch(assetRegisterActions.checkAssetError(errorMessage));
    }
    dispatch(assetRegisterActions.checkAssetCompleted());
  },
  checkAssetType: (id) => async (dispatch) => {
    dispatch(assetRegisterActions.checkAssetTypesRequest());
    try {
      const res = await checkAssetTypesRequest(id);
      if (res.success) {
        dispatch(assetRegisterActions.checkAssetTypesSuccess(res.message));
        dispatch(assetRegisterThunk.getAssetTypesDetails(id));
      } else dispatch(assetRegisterActions.checkAssetTypesError(res.error));
    } catch (error) {
      console.error("Check asset thunk error", error);
      dispatch(assetRegisterActions.checkAssetTypesError(errorMessage));
    }
    dispatch(assetRegisterActions.checkAssetTypesCompleted());
  },
  getMetaData: () => async (dispatch) => {
    dispatch(assetRegisterActions.staffRecordsFormDataRequest());
    const res = await getStaffRecordsRequest();
    if (res.success)
      dispatch(assetRegisterActions.staffRecordsFormData(res.data));
    else dispatch(assetRegisterActions.staffRecordsFormData([]));
  },
};

export default assetRegisterThunk;
