import { Modal, Form, Input, Button } from "antd";
import React from "react";
import { FcHighPriority } from "react-icons/fc";
import { useDispatch, useSelector } from "react-redux";
import { appUiActions } from "../../../config/actions/app/appUi.actions";

const ChangePassword = ({ handleChangePassword }) => {
  const visible = useSelector(
    (state) => state.AppUi.settings.changePasswordModal
  );
  const { success, error, loading } = useSelector(
    (state) => state.usersState.changePassword
  );
  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch(appUiActions.toggleChangePasswordModal(false));
  };
  const onFinish = (values) => {
    handleChangePassword(values);
  };
  return (
    <div>
      <Modal
        onCancel={closeModal}
        open={visible}
        title="CHANGE YOUR PASSWORD"
        footer={null}
      >
        {!success && error && (
          <div className="alert alert-danger">
            <FcHighPriority /> &nbsp; {error}
          </div>
        )}
        <Form onFinish={onFinish} validateTrigger="onblur" layout="vertical">
          <Form.Item
            label="Current Password"
            name="password"
            rules={[
              { required: true, message: "Please enter your current password" },
              {
                min: 8,
                message: "Your password should be greater than 8 characters",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Submit
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

export default ChangePassword;
