import { Modal, Form, Select, Button, message, Alert, Input } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
const { Option } = Select;

const AddPaymentVoucher = ({ visible, handleCloseModal, handleAddRecord }) => {
  const {
    addPaymentVouchersLoading,
    addPaymentVouchersSuccess,
    addPaymentVouchersError,
    addPaymentVouchersMessage,
    paymentVouchersMetaDataLoading,
    paymentVouchersMetaData,
    paymentVouchers,
  } = useSelector((state) => state.paymentVouchersState);
  const [form] = Form.useForm();
  const closeModal = () => handleCloseModal(false);
  const onFinish = (values) => {
    values.created_at = new Date();
    handleAddRecord(values, paymentVouchers);
  };

  useEffect(() => {
    if (addPaymentVouchersSuccess && addPaymentVouchersMessage) {
      message.success(addPaymentVouchersMessage);
      form.resetFields();
    }
  }, [addPaymentVouchersMessage, addPaymentVouchersSuccess, form]);
  return (
    <div>
      <Modal
        onCancel={closeModal}
        open={visible}
        footer={null}
        title="CREATE FUNDS ADVANCE/WITHDRAW FORM"
      >
        <Form form={form} layout="vertical" onFinish={onFinish}>
          {!addPaymentVouchersSuccess && addPaymentVouchersError && (
            <Alert type="error" showIcon message={addPaymentVouchersError} />
          )}
          <Form.Item
            rules={[
              { required: true, message: "Please select a requsition form" },
            ]}
            label="Requisition Form"
            name="requisition_memo"
          >
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              loading={paymentVouchersMetaDataLoading}
            >
              {paymentVouchersMetaData?.requisitionMemos?.map((memo) => (
                <Option key={memo.id} value={memo.id}>
                  {memo.memo_number}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: "Please add the the bank" }]}
            name="bank"
            label="Bank"
          >
            <Input />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: "Please add a cheque number" }]}
            name="cheque_number"
            label="Cheque Number"
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button
              loading={addPaymentVouchersLoading}
              htmlType="submit"
              type="primary"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddPaymentVoucher;
