import { Button, Form, Input, InputNumber, Modal } from "antd";
import React, { useEffect } from "react";
import { FcHighPriority } from "react-icons/fc";
import { useDispatch, useSelector } from "react-redux";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";

const AddAssetTypeForm = ({ handledAddAssetType }) => {
  const dispatch = useDispatch();
  const { error, success, loading } = useSelector(
    (state) => state.assetRegisterState.addAssetTypes
  );
  const [form] = Form.useForm();
  const visible = useSelector(
    (state) => state.AppUi.admisnistration.assetsRegister.addAssetTypesModal
  );
  const onFinish = (values) => {
    values.createdAt = new Date();
    handledAddAssetType(values);
  };
  const closeAddRoleModal = () => {
    dispatch(appUiActions.toggleAddAssetTypesModal(false));
  };
  useEffect(() => {
    if (success) form.resetFields();
  }, [success, form]);
  return (
    <div>
      <Modal
        onCancel={closeAddRoleModal}
        open={visible}
        title="ADD NEW ASSET CATEGORY"
        footer={null}
        validateTrigger="onBlur"
      >
        <Form
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          validateTrigger="onBlur"
          layout="horizontal"
          onFinish={onFinish}
        >
          {!success && error && (
            <div className="alert alert-danger">
              <FcHighPriority /> &nbsp; {error}
            </div>
          )}
          <Form.Item
            name="name"
            label="Asset Type Name"
            rules={[
              { required: true, message: "Please add an asset type name" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Depreciation %"
            name="depreciation"
            rules={[{ required: true, message: "Please add a depreciation" }]}
          >
            <InputNumber precision={0} className="w-100" max={100} />
          </Form.Item>
          <Form.Item className={"mt-3"} wrapperCol={{ offset: 8, span: 16 }}>
            <Button loading={loading} htmlType="submit" type="primary">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddAssetTypeForm;
