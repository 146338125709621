import { Radio } from "antd";
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";

const ProjectsActualExpenditureGraph = () => {
  const { projectsGraphData } = useSelector((state) => state.projectsState);
  let projectsUsd = [];
  let projectsUgx = [];
  let approvedBudgetUgx = [];
  let approvedBudgetUsd = [];
  let varianceUgx = [];
  let actualExpenitureUgx = [];
  let actualExpenitureUsd = [];
  let varianceUsd = [];
  /* projectsGraphData.projectsActualExpenditureGraphData? */
  const [graphsPerPage] = useState(3);
  let [currentGraphsPage, setcurrentGraphsPage] = useState(1);
  const [currency, setCurrency] = useState("UGX");
  const [projectsDisplayed, setProjectsDisplayed] = useState(projectsUgx);
  const [actualExpenditureDisplayed, setActualExpenditureDisplayed] =
    useState(actualExpenitureUgx);
  const [approvedBudgetDisplayed, setApprovedBudgetDisplayed] =
    useState(approvedBudgetUgx);
  const [varianceDisplayed, setVarianceDisplayed] = useState(varianceUgx);
  const data = {
    labels: projectsDisplayed,
    datasets: [
      {
        label: "Approved Budget",
        data: approvedBudgetDisplayed,
        backgroundColor: ["rgb(54, 162, 235,1)"],
        borderColor: ["rgb(54, 162, 235,1)"],
        borderWidth: 1,
        fill: true,
        tension: 0.1,
      },
      {
        fill: true,
        tension: 0.1,
        label: "Actual Expenditure",
        data: actualExpenditureDisplayed,
        backgroundColor: ["rgb(255, 99, 132,1)"],
        borderColor: ["rgba(255, 149, 104,1)"],
        borderWidth: 1,
      },
      {
        fill: true,
        tension: 0.1,
        label: "Variance",
        data: varianceDisplayed,
        backgroundColor: ["rgba(75, 192, 192, 1)"],
        borderColor: ["rgba(75, 192, 192, 1)"],
        borderWidth: 1,
        width: 10,
      },
    ],
  };
  const onChange = (e) => {
    setCurrency(e.target.value);
    const graphs = changePage(1);
    if (e.target.value === "UGX") {
      setProjectsDisplayed(graphs.projectsUgx);
      setActualExpenditureDisplayed(graphs.actualExpenitureUgx);
      setVarianceDisplayed(graphs.varianceUgx);
      setApprovedBudgetDisplayed(graphs.approvedBudgetUgx);
    } else {
      setProjectsDisplayed(graphs.projectsUsd);
      setActualExpenditureDisplayed(graphs.actualExpenitureUsd);
      setVarianceDisplayed(graphs.varianceUsd);
      setApprovedBudgetDisplayed(graphs.approvedBudgetUsd);
    }
  };

  const numPages = () => {
    return Math.ceil(
      projectsGraphData?.projectsActualExpenditureGraphData?.length /
        graphsPerPage
    );
  };

  const changePage = (page) => {
    // Validate page
    if (page < 1) page = 1;
    if (page > numPages()) page = numPages();

    for (let i = (page - 1) * graphsPerPage; i < page * graphsPerPage; i++) {
      const approvedBudget =
        projectsGraphData.projectsActualExpenditureGraphData[i]?.approvedBudget;
      const currency = approvedBudget?.[approvedBudget?.length - 1];
      if (currency === "U") {
        projectsUgx.push(
          projectsGraphData.projectsActualExpenditureGraphData[
            i
          ]?.project?.split(" ")
        );
        approvedBudgetUgx.push(
          parseInt(
            projectsGraphData.projectsActualExpenditureGraphData[i]
              ?.approvedBudget
          )
        );
        actualExpenitureUgx.push(
          parseInt(
            projectsGraphData.projectsActualExpenditureGraphData[i]
              ?.totalActualExpenditure
          )
        );
        varianceUgx.push(
          projectsGraphData.projectsActualExpenditureGraphData[i]?.variance
        );
      } else {
        projectsUsd.push(
          projectsGraphData.projectsActualExpenditureGraphData[i]?.project
        );
        approvedBudgetUsd.push(
          parseInt(
            projectsGraphData.projectsActualExpenditureGraphData[i]
              ?.approvedBudget
          )
        );
        actualExpenitureUsd.push(
          parseInt(
            projectsGraphData.projectsActualExpenditureGraphData[i]
              ?.totalActualExpenditure
          )
        );
        varianceUsd.push(
          projectsGraphData.projectsActualExpenditureGraphData[i]?.variance
        );
      }
    }
    return {
      projectsUgx,
      projectsUsd,
      varianceUsd,
      varianceUgx,
      actualExpenitureUgx,
      actualExpenitureUsd,
      approvedBudgetUgx,
      approvedBudgetUsd,
    };
  };

  const nextPage = () => {
    let graphs = [];
    if (currentGraphsPage <= numPages()) {
      setcurrentGraphsPage(currentGraphsPage++);
      graphs = changePage(currentGraphsPage);
    }
    if (currency === "UGX") setProjectsDisplayed(graphs.projectsUgx);
    else setProjectsDisplayed(graphs.projectsUsd);
  };
  const prevPage = () => {
    let x = changePage(currentGraphsPage);
    if (currentGraphsPage > 1) {
      setcurrentGraphsPage(currentGraphsPage--);
      changePage(currentGraphsPage);
    }
    if (currency === "UGX") setProjectsDisplayed(x.projectsUgx);
    else setProjectsDisplayed(x.projectsUsd);
  };

  useEffect(() => {
    changePage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="m-1 w-75 bar-graph shadow p-2 w-sm-100 ">
      <h5>Project Expenditure</h5>
      <Radio.Group onChange={onChange} value={currency}>
        <Radio.Button value="UGX">UGX</Radio.Button>
        <Radio.Button value="USD">USD</Radio.Button>
      </Radio.Group>
      <div className="h-350">
        <Bar
          width={1000}
          data={data}
          options={{
            scales: {
              x: {
                grid: {
                  display: true,
                },

                offset: true,
              },
              y: {
                grid: {
                  display: false,
                },
              },
            },
            responsive: true,
            plugins: { legend: { display: true } },
            maintainAspectRatio: false,
          }}
        />
      </div>
      <div className="w-100 my-1 d-flex justify-content-center">
        <nav aria-label="Page navigation example">
          <ul class="pagination">
            <li onClick={prevPage} id="btn_prev" class="page-item">
              <p class="page-link" aria-label="Previous">
                <span aria-hidden="true">{/* &laquo; */}Previous</span>
              </p>
            </li>
            {/*    <li class="page-item">
              <p id="pageNumber" class="page-link" href="#">
                {currentGraphsPage}
              </p>
            </li> */}
            <li onClick={nextPage} id="btn-next" class="page-item">
              <p class="page-link" aria-label="Next">
                <span aria-hidden="true">{/* &raquo; */}Next</span>
              </p>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default ProjectsActualExpenditureGraph;
