import React, { useEffect } from "react";
import { Col, Divider, Form, Row, message } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { flatMap } from "lodash";
import { useStrategicPlansStore } from "../../../../../../config/stores";
import usePrevious from "../../../../../../components/hooks/usePrevious";
import AntdModal from "../../../../../../components/common/AntdModal";
import AppButton from "../../../../../../components/common/AppButton";
import AppInput from "../../../../../../components/common/AppInput";
import { inputNumberFormatter } from "../../../../../../config/constants";

const ActivitiesForm = () => {
  const strategicPlansStore = useStrategicPlansStore();
  const {
    addingStrategicPlanActivity,
    strategicPlanActivityToUpdate,
    creatingStrategicPlanActivity,
    createdStrategicPlanActivity,
    showAddStrategicPlanActivity,
    updatingStrategicPlanActivity,
    strategicPlanDetails,
    showViewThemeAreaDetails,
  } = strategicPlansStore;
  const [form] = Form.useForm();
  const prevState = usePrevious({ createdStrategicPlanActivity });

  const closeModal = () => {
    strategicPlansStore.setAddingStrategicPlanActivity();
  };

  const onSubmit = () => {
    form
      .validateFields()
      .then((data) => {
        if (addingStrategicPlanActivity) {
          data.strategic_plan_id = strategicPlanDetails.id;
          data.thematic_area_id = showViewThemeAreaDetails.id;
          strategicPlansStore.createStrategicPlanActivity(data);
        } else {
          strategicPlansStore.updateStrategicPlanActivity(
            strategicPlanActivityToUpdate.id,
            data
          );
        }
      })
      .catch((err) => {
        const errorMessages = flatMap(err.errorFields, "errors").join(", ");
        message.error(errorMessages);
      });
  };

  useEffect(() => {
    if (
      prevState &&
      createdStrategicPlanActivity &&
      prevState.createdStrategicPlanActivity !== createdStrategicPlanActivity
    ) {
      form.resetFields();
    }
  }, [prevState, form, createdStrategicPlanActivity]);

  useEffect(() => {
    if (addingStrategicPlanActivity) {
      form.setFieldsValue({
        strategicPlansProposedActivitiesIndicators: [{}],
      });
    } else {
      form.setFieldsValue(strategicPlanActivityToUpdate);
    }
  }, [addingStrategicPlanActivity, strategicPlanActivityToUpdate, form]);

  return (
    <AntdModal
      title={addingStrategicPlanActivity ? "ADD ACTIVITY" : "EDIT ACTIVITY"}
      open={showAddStrategicPlanActivity}
      closeModal={closeModal}
      size="large"
      footer={[
        <AppButton
          text={
            addingStrategicPlanActivity ? "CREATE ACTIVITY" : "UPDATE ACTIVITY"
          }
          key={"create"}
          loading={
            creatingStrategicPlanActivity || updatingStrategicPlanActivity
          }
          onClick={onSubmit}
        />,
        <AppButton text="CANCEL" key={"cancel"} onClick={closeModal} danger />,
      ]}
    >
      <Form layout="vertical" form={form}>
        <AppInput
          label="Activity"
          name="activity"
          type="textarea"
          rules={[
            {
              required: true,
              message: "Title is required",
            },
          ]}
        />

        {addingStrategicPlanActivity && (
          <>
            <Divider className="fw-bold">ACTIVITY INDICATORS</Divider>
            <Form.List
              name="strategicPlansProposedActivitiesIndicators"
              rules={[
                {
                  validator: async (_, names) => {
                    if (!names || names.length < 1) {
                      return Promise.reject(
                        new Error("At least 1 thematic area")
                      );
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <Row gutter={4} key={field.key}>
                      <Col span={10}>
                        <AppInput
                          label="Indicator"
                          name={[field.name, "indicator"]}
                          rules={[
                            {
                              required: true,
                              message: "Please input indicator",
                            },
                          ]}
                        />
                      </Col>
                      <Col span={5}>
                        <AppInput
                          label="Unit"
                          name={[field.name, "target_unit"]}
                          rules={[
                            {
                              required: true,
                              message: "Please input indicator",
                            },
                          ]}
                        />
                      </Col>
                      <Col span={5}>
                        <AppInput
                          label="Target"
                          type="number"
                          name={[field.name, "target"]}
                          rules={[
                            {
                              required: true,
                              message: "Please input targe",
                            },
                          ]}
                          inputAttributes={{
                            formatter: inputNumberFormatter,
                          }}
                        />
                      </Col>
                      <Col span={2}>
                        <Form.Item label=" ">
                          {fields.length > 1 ? (
                            <span className="text-danger mx-1">
                              <MinusCircleOutlined
                                className="dynamic-delete-button"
                                onClick={() => remove(field.name)}
                              />
                            </span>
                          ) : null}
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                  <Form.Item>
                    <AppButton
                      type="dashed"
                      text="Add Activity"
                      block
                      onClick={() => add()}
                      iconBefore={<PlusOutlined />}
                    />

                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          </>
        )}
      </Form>
      <Divider className="m-1" />
    </AntdModal>
  );
};

export default ActivitiesForm;
