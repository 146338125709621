export const paymentVoucherConstants = {
  SET_PAYMENT_VOUCHERS_REQUEST: "SET_PAYMENT_VOUCHERS_REQUEST",
  SET_PAYMENT_VOUCHERS_SUCCESS: "SET_PAYMENT_VOUCHERS_SUCCESS",
  SET_PAYMENT_VOUCHERS_ERROR: "SET_PAYMENT_VOUCHERS_ERROR",

  SET_PAYMENT_VOUCHERS_DETAILS_REQUEST: "SET_PAYMENT_VOUCHERS_DETAILS_REQUEST",
  SET_PAYMENT_VOUCHERS_DETAILS_SUCCESS: "SET_PAYMENT_VOUCHERS_DETAILS_SUCCESS",
  SET_PAYMENT_VOUCHERS_DETAILS_ERROR: "SET_PAYMENT_VOUCHERS_DETAILS_ERROR",

  ADD_PAYMENT_VOUCHERS_REQUEST: "ADD_PAYMENT_VOUCHERS_REQUEST",
  ADD_PAYMENT_VOUCHERS_SUCCESS: "ADD_PAYMENT_VOUCHERS_SUCCESS",
  ADD_PAYMENT_VOUCHERS_ERROR: "ADD_PAYMENT_VOUCHERS_ERROR",
  ADD_PAYMENT_VOUCHERS_COMPLETE: "ADD_PAYMENT_VOUCHERS_COMPLETE",

  EDIT_PAYMENT_VOUCHERS_REQUEST: "EDIT_PAYMENT_VOUCHERS_REQUEST",
  EDIT_PAYMENT_VOUCHERS_SUCCESS: "EDIT_PAYMENT_VOUCHERS_SUCCESS",
  EDIT_PAYMENT_VOUCHERS_ERROR: "EDIT_PAYMENT_VOUCHERS_ERROR",
  EDIT_PAYMENT_VOUCHERS_COMPLETE: "EDIT_PAYMENT_VOUCHERS_COMPLETE",
  EDIT_PAYMENT_VOUCHERS_DATA: "EDIT_PAYMENT_VOUCHERS_DATA",

  DELETE_PAYMENT_VOUCHERS_REQUEST: "DELETE_PAYMENT_VOUCHERS_REQUEST",
  DELETE_PAYMENT_VOUCHERS_SUCCESS: "DELETE_PAYMENT_VOUCHERS_SUCCESS",
  DELETE_PAYMENT_VOUCHERS_ERROR: "DELETE_PAYMENT_VOUCHERS_ERROR",
  DELETE_PAYMENT_VOUCHERS_COMPLETE: "DELETE_PAYMENT_VOUCHERS_COMPLETE",

  APPROVE_PAYMENT_VOUCHERS_SUCCESS: "APPROVE_PAYMENT_VOUCHERS_SUCCESS",
  APPROVE_PAYMENT_VOUCHERS_REQUEST: "APPROVE_PAYMENT_VOUCHERS_REQUEST",
  APPROVE_PAYMENT_VOUCHERS_ERROR: "APPROVE_PAYMENT_VOUCHERS_ERROR",
  APPROVE_PAYMENT_VOUCHERS_COMPLETE: "APPROVE_PAYMENT_VOUCHERS_COMPLETE",

  CHECK_PAYMENT_VOUCHERS_REQUEST: "CHECK_PAYMENT_VOUCHERS_REQUEST",
  CHECK_PAYMENT_VOUCHERS_SUCCESS: "CHECK_PAYMENT_VOUCHERS_SUCCESS",
  CHECK_PAYMENT_VOUCHERS_ERROR: "CHECK_PAYMENT_VOUCHERS_ERROR",
  CHECK_PAYMENT_VOUCHERS_COMPLETE: "CHECK_PAYMENT_VOUCHERS_COMPLETE",

  SET_PAYMENT_VOUCHERS_META_DATA_REQUEST:
    "SET_PAYMENT_VOUCHERS_META_DATA_REQUEST",
  SET_PAYMENT_VOUCHERS_META_DATA: "SET_PAYMENT_VOUCHERS_META_DATA",
};

export const paymentVoucherActions = {
  setPaymentVouchersRequest: () => ({
    type: paymentVoucherConstants.SET_PAYMENT_VOUCHERS_REQUEST,
  }),
  setPaymentVouchersSuccess: (payload) => ({
    type: paymentVoucherConstants.SET_PAYMENT_VOUCHERS_SUCCESS,
    payload,
  }),
  setPaymentVouchersError: (payload) => ({
    type: paymentVoucherConstants.SET_PAYMENT_VOUCHERS_ERROR,
    payload,
  }),
  setPaymentVouchersDetailsRequest: () => ({
    type: paymentVoucherConstants.SET_PAYMENT_VOUCHERS_DETAILS_REQUEST,
  }),
  setPaymentVouchersDetailsSuccess: (payload) => ({
    type: paymentVoucherConstants.SET_PAYMENT_VOUCHERS_DETAILS_SUCCESS,
    payload,
  }),
  setPaymentVouchersDetailsError: (payload) => ({
    type: paymentVoucherConstants.SET_PAYMENT_VOUCHERS_DETAILS_ERROR,
    payload,
  }),
  addPaymentVouchersRequest: () => ({
    type: paymentVoucherConstants.ADD_PAYMENT_VOUCHERS_REQUEST,
  }),
  addPaymentVouchersSuccess: (payload) => ({
    type: paymentVoucherConstants.ADD_PAYMENT_VOUCHERS_SUCCESS,
    payload,
  }),
  addPaymentVouchersError: (payload) => ({
    type: paymentVoucherConstants.ADD_PAYMENT_VOUCHERS_ERROR,
    payload,
  }),
  addPaymentVouchersComplete: () => ({
    type: paymentVoucherConstants.ADD_PAYMENT_VOUCHERS_COMPLETE,
  }),
  editPaymentVouchersRequest: () => ({
    type: paymentVoucherConstants.EDIT_PAYMENT_VOUCHERS_REQUEST,
  }),
  editPaymentVouchersSuccess: (payload) => ({
    type: paymentVoucherConstants.EDIT_PAYMENT_VOUCHERS_SUCCESS,
    payload,
  }),
  editPaymentVouchersError: (payload) => ({
    type: paymentVoucherConstants.EDIT_PAYMENT_VOUCHERS_ERROR,
    payload,
  }),
  editPaymentVouchersComplete: () => ({
    type: paymentVoucherConstants.EDIT_PAYMENT_VOUCHERS_COMPLETE,
  }),
  editPaymentVouchersData: (payload) => ({
    type: paymentVoucherConstants.EDIT_PAYMENT_VOUCHERS_DATA,
    payload,
  }),
  deletePaymentVouchersRequest: () => ({
    type: paymentVoucherConstants.DELETE_PAYMENT_VOUCHERS_REQUEST,
  }),
  deletePaymentVouchersSuccess: (payload) => ({
    type: paymentVoucherConstants.DELETE_PAYMENT_VOUCHERS_SUCCESS,
    payload,
  }),
  deletePaymentVouchersError: (payload) => ({
    type: paymentVoucherConstants.DELETE_PAYMENT_VOUCHERS_ERROR,
    payload,
  }),
  deletePaymentVouchersComplete: () => ({
    type: paymentVoucherConstants.DELETE_PAYMENT_VOUCHERS_COMPLETE,
  }),
  checkPaymentVouchersRequest: () => ({
    type: paymentVoucherConstants.CHECK_PAYMENT_VOUCHERS_REQUEST,
  }),
  checkPaymentVouchersSuccess: (payload) => ({
    type: paymentVoucherConstants.CHECK_PAYMENT_VOUCHERS_SUCCESS,
    payload,
  }),
  checkPaymentVouchersError: (payload) => ({
    type: paymentVoucherConstants.CHECK_PAYMENT_VOUCHERS_ERROR,
    payload,
  }),
  checkPaymentVouchersComplete: () => ({
    type: paymentVoucherConstants.CHECK_PAYMENT_VOUCHERS_COMPLETE,
  }),
  approvePaymentVouchersRequest: () => ({
    type: paymentVoucherConstants.APPROVE_PAYMENT_VOUCHERS_REQUEST,
  }),
  approvePaymentVouchersSuccess: (payload) => ({
    type: paymentVoucherConstants.APPROVE_PAYMENT_VOUCHERS_SUCCESS,
    payload,
  }),
  approvePaymentVouchersError: (payload) => ({
    type: paymentVoucherConstants.APPROVE_PAYMENT_VOUCHERS_ERROR,
    payload,
  }),
  approvePaymentVouchersComplete: () => ({
    type: paymentVoucherConstants.APPROVE_PAYMENT_VOUCHERS_COMPLETE,
  }),
  setPaymentVouchersMetaDataRequest: () => ({
    type: paymentVoucherConstants.SET_PAYMENT_VOUCHERS_META_DATA_REQUEST,
  }),
  setPaymentVouchersMetaData: (payload) => ({
    type: paymentVoucherConstants.SET_PAYMENT_VOUCHERS_META_DATA,
    payload,
  }),
};
