import React, { useEffect } from "react";
import { useTimeSheetStore } from "../../../../config/stores";
import AntdModal from "../../../../components/common/AntdModal";
import { Divider, Form } from "antd";
import { useForm } from "antd/es/form/Form";
import AppButton from "../../../../components/common/AppButton";
import AppInput from "../../../../components/common/AppInput";
import dayjs from "dayjs";
import usePrevious from "../../../../components/hooks/usePrevious";

const TimeSheetsForm = () => {
  const timeSheetsStore = useTimeSheetStore();
  const {
    addingTimeSheet,
    createdTimeSheet,
    timeSheetToEdit,
    showAddingTimeSheet,
  } = timeSheetsStore;
  const [form] = useForm();
  const prevState = usePrevious({ createdTimeSheet });

  const closeModal = () => {
    timeSheetsStore.setAddingTimeSheet();
  };

  const onSubmit = () => {
    form.validateFields().then((data) => {
      const payload = {
      month: data.month.month() + 1,
        year: data.year.year(),
        details: data.details,
      };

      if (addingTimeSheet) {
        timeSheetsStore.createTimeSheet(payload);
      } else {
        timeSheetsStore.updateTimeSheet(timeSheetToEdit.id, payload);
      }
    });
  };

  const today = dayjs();

  useEffect(() => {
    if (
      prevState &&
      createdTimeSheet &&
      prevState.createdTimeSheet !== createdTimeSheet
    ) {
      form.resetFields();
    }
  }, [prevState, form, createdTimeSheet]);

  useEffect(() => {
    if (addingTimeSheet) {
      form.setFieldsValue({
        month: dayjs(),
        year: dayjs(),
      });
    } else {
      const month =
        toString(timeSheetToEdit.month).length === 1
          ? `0${timeSheetToEdit.month}`
          : timeSheetToEdit.month;
      form.setFieldsValue({
        month: dayjs(`${timeSheetToEdit.year}-${month}`),
        year: dayjs(`${timeSheetToEdit.year}`),
        details: addingTimeSheet.details,
      });
    }
  }, [addingTimeSheet, timeSheetToEdit, form]);

  return (
    <AntdModal
      title={addingTimeSheet ? "ADD TIME SHEET" : "EDIT TIME SHEET"}
      visible={showAddingTimeSheet}
      closeModal={closeModal}
      footer={[
        <AppButton
          text="CREATE TIME SHEET"
          key={"create"}
          loading={
            timeSheetsStore.creatingTimeSheet ||
            timeSheetsStore.updatingTimeSheet
          }
          onClick={onSubmit}
        />,
        <AppButton text="CANCEL" key={"cancel"} onClick={closeModal} danger />,
      ]}
    >
      <Form
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        form={form}
      >
        <AppInput
          label="Month"
          name="month"
          type="date"
          inputAttributes={{
            picker: "month",
            disabledDate: (current) =>
              current && current < today.subtract(1, "month"),
            format: "MMMM",
          }}
          rules={[{ required: true }]}
        />
        <AppInput
          label="Year"
          name="year"
          type="date"
          inputAttributes={{
            picker: "year",
            disabledDate: (current) => today.year() !== current.year(),
          }}
          rules={[{ required: true }]}
        />
        <AppInput label="Details" name="details" type="textarea" />
      </Form>
      <Divider className="m-1" />
    </AntdModal>
  );
};

export default TimeSheetsForm;
