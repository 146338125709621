import { Table } from "antd";
import React from "react";

const StaffContacts = ({ data }) => {
  const columns = [
    {
      title: "First Name",
      key: "name",
      render: (text) => (
        <div>
          <span className="d-md-none">First Name:</span> {text.first_name}{" "}
          <br />
          <div className="d-md-none">
            Other Names: {text.other_names} <br />
            Contact: {text.contact} <br />
            Email: {text.email} <br />
          </div>
        </div>
      ),
    },
    {
      title: "Other Names",
      dataIndex: "other_names",
      key: "other_names",
      responsive: ["md"],
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      responsive: ["md"],
    },
    {
      title: "Contact",
      dataIndex: "contact",
      key: "contact",
      responsive: ["md"],
    },
  ];
  return (
    <div className="m-2">
      <Table
        size="small"
        title={() => <h5>Staff Contact Details</h5>}
        columns={columns}
        dataSource={data.staffList}
      />
    </div>
  );
};

export default StaffContacts;
