import { Table } from "antd";
import React from "react";

const SubAccountsTable = ({ data }) => {
  const columns = [
    {
      title: "Code ",
      dataIndex: "account_number",
      key: "account_number",
    },
    { title: "Account Name", dataIndex: "name", key: "account name" },
    { title: "Details", dataIndex: "details", key: "details" },
  ];
  return (
    <div>
      <Table scroll={{ x: true }} dataSource={data} columns={columns} />
    </div>
  );
};

export default SubAccountsTable;
