import { manualsAndPolciesConstants } from "../../../actions/administration/manualsAndPolicies/manualsAndPolicies.actions";
import initialState from "../../../initialState/administration/manualsAndPolicies/manualsAndPolicies";

const manualsAndPoliciesState = (state = initialState, { type, payload }) => {
  switch (type) {
    case manualsAndPolciesConstants.SET_MANUALS_AND_POLICES_REQUEST:
      return {
        ...state,
        manualsAndPolicies: { ...state.manualsAndPolicies, loading: true },
      };
    case manualsAndPolciesConstants.SET_MANUALS_AND_POLICES_SUCCESS:
      return {
        ...state,
        manualsAndPolicies: {
          error: "",
          success: true,
          data: payload,
          loading: false,
        },
      };
    case manualsAndPolciesConstants.SET_MANUALS_AND_POLICES_ERROR:
      return {
        ...state,
        manualsAndPolicies: {
          ...state.manualsAndPolcies,
          success: false,
          error: payload,
          loading: false,
        },
      };
    case manualsAndPolciesConstants.SET_MANUALS_AND_POLICES_DETAILS_REQUEST:
      return {
        ...state,
        manualsAndPoliciesDetails: {
          ...state.manualsAndPoliciesDetails,
          loading: true,
        },
      };
    case manualsAndPolciesConstants.SET_MANUALS_AND_POLICES_DETAILS_SUCCESS:
      return {
        ...state,
        manualsAndPoliciesDetails: {
          error: "",
          success: true,
          data: payload,
          loading: false,
        },
      };
    case manualsAndPolciesConstants.SET_MANUALS_AND_POLICES_DETAILS_ERROR:
      return {
        ...state,
        manualsAndPoliciesDetails: {
          ...state.manualsAndPoliciesDetails,
          success: false,
          error: payload,
          loading: false,
        },
      };
    case manualsAndPolciesConstants.ADD_MANUALS_AND_POLICIES_REQUEST:
      return {
        ...state,
        addManualsAndPolicies: {
          ...state.addManualsAndPolicies,
          loading: true,
        },
      };
    case manualsAndPolciesConstants.ADD_MANUALS_AND_POLICIES_SUCCESS:
      return {
        ...state,
        addManualsAndPolicies: {
          error: "",
          loading: false,
          success: true,
          message: payload.message,
        },
        manualsAndPolicies: {
          ...state.manualsAndPolicies,
          data: [payload.data, ...state.manualsAndPolicies.data],
        },
      };
    case manualsAndPolciesConstants.ADD_MANUALS_AND_POLICIES_ERROR:
      return {
        ...state,
        addManualsAndPolicies: {
          message: "",
          error: payload,
          loading: false,
          success: false,
        },
      };
    case manualsAndPolciesConstants.ADD_MANUALS_AND_POLICIES_COMPLETED:
      return {
        ...state,
        addManualsAndPolicies: {
          ...state.addManualsAndPolicies,
          success: false,
        },
      };
    case manualsAndPolciesConstants.UPDATE_MANUALS_AND_POLICIES_REQUEST:
      return {
        ...state,
        updateManualsAndPolicies: {
          ...state.updateManualsAndPolicies,
          loading: true,
        },
      };
    case manualsAndPolciesConstants.UPDATE_MANUALS_AND_POLICIES_SUCCESS:
      return {
        ...state,
        updateManualsAndPolicies: {
          ...state.updateManualsAndPolicies,
          error: "",
          loading: false,
          success: true,
          message: payload.message,
        },
        manualsAndPolicies: {
          ...state.manualsAndPolicies,
          data: payload.data,
        },
      };
    case manualsAndPolciesConstants.UPDATE_MANUALS_AND_POLICIES_ERROR:
      return {
        ...state,
        updateManualsAndPolicies: {
          ...state.updateManualsAndPolicies,
          message: "",
          error: payload,
          loading: false,
          success: false,
        },
      };
    case manualsAndPolciesConstants.UPDATE_MANUALS_AND_POLICIES_COMPLETED:
      return {
        ...state,
        updateManualsAndPolicies: {
          ...state.updateManualsAndPolicies,
          success: false,
        },
      };
    case manualsAndPolciesConstants.SET_UPDATE_MANUALS_AND_POLICIES_DATA:
      return {
        ...state,
        updateManualsAndPolicies: {
          ...state.updateManualsAndPolicies,
          data: payload,
        },
      };
    case manualsAndPolciesConstants.DELETE_MANUALS_AND_POLICIES_REQUEST:
      return {
        ...state,
        deleteManualsAndPolicies: {
          ...state.deleteManualsAndPolicies,
          loading: true,
        },
      };
    case manualsAndPolciesConstants.DELETE_MANUALS_AND_POLICIES_SUCCESS:
      return {
        ...state,
        deleteManualsAndPolicies: {
          error: "",
          loading: false,
          success: true,
          message: payload.message,
        },
        manualsAndPolicies: {
          ...state.manualsAndPolicies,
          data: payload.data,
        },
      };
    case manualsAndPolciesConstants.DELETE_MANUALS_AND_POLICIES_ERROR:
      return {
        ...state,
        deleteManualsAndPolicies: {
          message: "",
          error: payload,
          loading: false,
          success: false,
        },
      };
    case manualsAndPolciesConstants.DELETE_MANUALS_AND_POLICIES_COMPLETED:
      return {
        ...state,
        deleteManualsAndPolicies: {
          ...state.deleteManualsAndPolicies,
          success: false,
          error: "",
        },
      };
    case manualsAndPolciesConstants.APPROVE_MANUALS_AND_POLICIES_REQUEST:
      return {
        ...state,
        approveManualsAndPolicies: {
          ...state.approveManualsAndPolicies,
          loading: true,
        },
      };
    case manualsAndPolciesConstants.APPROVE_MANUALS_AND_POLICIES_SUCCESS:
      return {
        ...state,
        approveManualsAndPolicies: {
          error: "",
          loading: false,
          success: true,
          message: payload,
        },
      };
    case manualsAndPolciesConstants.APPROVE_MANUALS_AND_POLICIES_ERROR:
      return {
        ...state,
        approveManualsAndPolicies: {
          message: "",
          error: payload,
          loading: false,
          success: false,
        },
      };
    case manualsAndPolciesConstants.APPROVE_MANUALS_AND_POLICIES_COMPLETED:
      return {
        ...state,
        approveManualsAndPolicies: {
          ...state.approveManualsAndPolicies,
          success: false,
          error: "",
        },
      };
    case manualsAndPolciesConstants.CHECK_MANUALS_AND_POLICIES_REQUEST:
      return {
        ...state,
        checkManualsAndPolicies: {
          ...state.checkManualsAndPolicies,
          loading: true,
        },
      };
    case manualsAndPolciesConstants.CHECK_MANUALS_AND_POLICIES_SUCCESS:
      return {
        ...state,
        checkManualsAndPolicies: {
          error: "",
          loading: false,
          success: true,
          message: payload,
        },
      };
    case manualsAndPolciesConstants.CHECK_MANUALS_AND_POLICIES_ERROR:
      return {
        ...state,
        checkManualsAndPolicies: {
          message: "",
          error: payload,
          loading: false,
          success: false,
        },
      };
    case manualsAndPolciesConstants.CHECK_MANUALS_AND_POLICIES_COMPLETED:
      return {
        ...state,
        checkManualsAndPolicies: {
          ...state.checkManualsAndPolicies,
          success: false,
          error: "",
        },
      };
    case manualsAndPolciesConstants.DOWNLOAD_MANUALS_AND_POLICIES_REQUEST:
      return {
        ...state,
        downloadManualsAndPolicies: {
          ...state.downloadManualsAndPolicies,
          loading: true,
        },
      };
    case manualsAndPolciesConstants.DOWNLOAD_MANUALS_AND_POLICIES_SUCCESS:
      return {
        ...state,
        downloadManualsAndPolicies: {
          error: "",
          loading: false,
          success: true,
          message: payload,
        },
      };
    case manualsAndPolciesConstants.DOWNLOAD_MANUALS_AND_POLICIES_ERROR:
      return {
        ...state,
        downloadManualsAndPolicies: {
          message: "",
          error: payload,
          loading: false,
          success: false,
        },
      };
    case manualsAndPolciesConstants.DOWNLOAD_MANUALS_AND_POLICIES_COMPLETED:
      return {
        ...state,
        downloadManualsAndPolicies: {
          ...state.downloadManualsAndPolicies,
          success: false,
          error: "",
        },
      };

    default:
      return state;
  }
};

export default manualsAndPoliciesState;
