import { logDetailsConstants } from "../../actions/settings/logDetails.actions";
import intialState from "../../initialState/settings/logDetails";

const logDetailsState = (state = intialState, { type, payload }) => {
  switch (type) {
    case logDetailsConstants.SET_LOG_DETAILS_REQUEST:
      return {
        ...state,
        logDetails: { ...state.logDetails, loading: true },
      };
    case logDetailsConstants.SET_LOG_DETAILS_SUCCESS:
      return {
        ...state,
        logDetails: {
          error: "",
          data: payload,
          success: true,
          loading: false,
        },
      };
    case logDetailsConstants.SET_LOG_DETAILS_ERROR:
      return {
        ...state,
        logDetails: {
          ...state.logDetails,
          error: payload,
          success: false,
          loading: false,
        },
      };
    case logDetailsConstants.ADD_LOG_DETAILS_REQUEST:
      return {
        ...state,
        addLogDetails: { ...state.addLogDetails, loading: true },
      };
    case logDetailsConstants.ADD_LOG_DETAILS_SUCCESS:
      return {
        ...state,
        addLogDetails: {
          loading: false,
          success: true,
          error: "",
          records: payload.records,
        },
        logDetails: {
          ...state.logDetails,
          data: [payload.data, ...state.logDetails.data],
        },
      };
    case logDetailsConstants.ADD_LOG_DETAILS_ERROR:
      return {
        ...state,
        addLogDetails: {
          loading: false,
          success: false,
          error: payload,
        },
      };
    case logDetailsConstants.ADD_LOG_DETAILS_COMPLETED:
      return {
        ...state,
        addLogDetails: {
          ...state.addLogDetails,
          success: false,
          error: "",
          records: "",
        },
      };
    default:
      return state;
  }
};

export default logDetailsState;
