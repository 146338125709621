import React, { useEffect, useState } from "react";
import { useTimeSheetStore } from "../../../../config/stores";
import AntdModal from "../../../../components/common/AntdModal";
import AppButton from "../../../../components/common/AppButton";
import { filter } from "lodash";
import dayjs from "dayjs";
import AntdTable from "../../../../components/common/AntdTable";
import TableTitle from "../../../../components/common/TableTitle";
import TableButtonActions from "../../../../components/common/TableButtonActions";

const TimeSheetDayActivities = () => {
  const timeSheetStore = useTimeSheetStore();
  const [activities, setActivities] = useState();
  const [deleteId, setDeleteId] = useState(null);
  const { timeSheetActivitiesDate, showTimeSheetActivities, timeSheetDetails } =
    timeSheetStore;

  const closeModal = () => {
    timeSheetStore.setShowTimeSheetActivities();
  };

  useEffect(() => {
    if (timeSheetActivitiesDate) {
      const dayActivities = filter(
        timeSheetDetails.timeSheetActivities,
        (activity) => {
          return dayjs(activity.date).date() === timeSheetActivitiesDate.day;
        }
      );

      setActivities(dayActivities);
    }
  }, [timeSheetActivitiesDate, timeSheetDetails.timeSheetActivities]);

  const updateRecord = (record) => {
    timeSheetStore.setAddingTimeSheetActivity(false, true, record);
  };
  const confirmDelete = () => {
    timeSheetStore.deleteTimeSheetActivity(deleteId, timeSheetDetails.id);
    setDeleteId(null);
  };

  const deleteRecord = (id) => setDeleteId(id);

  const columns = [
    {
      title: "PROJECTS",
      dataIndex: "project",
      render: (project, record) => (
        <span
          onClick={() =>
            timeSheetStore.setAddingTimeSheetActivity(false, true, record)
          }
          className="text-primary"
        >
          {project?.name || "SHARED"}
        </span>
      ),
    },
    {
      title: "ACTIVITY",
      dataIndex: "activity",
    },
    {
      title: "HOURS",
      dataIndex: "hours",
    },
    {
      title: "ACTIONS",
      key: "actions",
      responsive: ["md"],
      render: (record) => {
        return (
          <TableButtonActions
            record={record}
            confirmDelete={confirmDelete}
            deleteLoading={timeSheetStore.deletingTimeSheetActivity}
            updateRecord={updateRecord}
            deleteId={deleteId}
            deleteRecord={deleteRecord}
          />
        );
      },
    },
  ];

  const addActivity = () => {
    timeSheetStore.setAddingTimeSheetActivity(true, true, {});
  };
  return (
    <div>
      <AntdModal
        title={`${timeSheetStore.timeSheetActivitiesDate.date} ACTIVITIES`}
        visible={showTimeSheetActivities}
        closeModal={closeModal}
        size="large"
        footer={[
          <AppButton
            text="CANCEL"
            key={"cancel"}
            onClick={closeModal}
            danger
          />,
        ]}
      >
        <AntdTable
          title={() => (
            <TableTitle
              openAddModal={addActivity}
              refreshTable={null}
              search={null}
              exportRecords={null}
            />
          )}
          rowKey="id"
          pagination={false}
          pageSize={20}
          data={activities}
          columns={columns}
        />
      </AntdModal>
    </div>
  );
};

export default TimeSheetDayActivities;
