import { create } from "zustand";
import { devtools } from "zustand/middleware";
import axios from "axios";
import { produce } from "immer";
import { findIndex } from "lodash";

const initialState = {
  gettingActivityNotes: false,
  activityNotes: {},
  activityNotesError: {},

  projectActivityNotes: {},
  gettingProjectActivityNotes: false,
  projectActivityNotesError: {},

  activityNoteDetails: {},
  gettingActivityNoteDetails: false,
  activityNoteDetailsError: {},
  showActivityNoteDetails: false,

  creatingActivityNote: false,
  createActivityNoteError: {},
  showCreatingActivityNote: false,

  creating: false,

  updatingActivityNote: false,
  updateActivityNoteError: {},
  activityNoteToUpdate: {},

  deletingActivityNote: false,
  deleteActivityNoteError: {},

  submittingActivityNote: false,
  submitActivityNoteError: {},

  approvingActivityNote: false,
  approveActivityNoteError: {},

  checkingActivityNote: false,
  checkActivityNoteError: {},

  rejectingActivityNote: false,
  rejectActivityNoteError: {},

  undoingActivityNoteApproval: false,
  undoActivityNoteApprovalError: {},

  creatingActivities: false,
  createActivitiesError: {},
  createdActivity: null,
  showActivityForm: false,

  updatingActivities: false,
  updateActivitiesError: {},
  activityToUpdate: {},
  updatedActivity: null,

  deletingActivities: false,
  deleteActivitiesError: {},

  creatingActivity: false,

  metaData: {},
  gettingMetaData: false,
  metaDataError: {},
};

const store = (set, get) => ({
  ...initialState,

  //actions
  async getActivityNotes() {
    set(
      produce((state) => {
        state.gettingActivityNotes = true;
      })
    );
    await axios({
      url: "/programs-mgt/activity-concept-notes",
      method: "GET",
    })
      .then((res) => {
        set(
          produce((state) => {
            state.activityNotes = res;
          })
        );
      })
      .catch((error) => {
        set(
          produce((state) => {
            state.activityNotesError = error;
          })
        );
      })
      .finally(
        set(
          produce((state) => {
            state.gettingActivityNotes = false;
          })
        )
      );
  },

  async getMetaData() {
    set(
      produce((state) => {
        state.gettingMetaData = true;
      })
    );
    await axios({
      url: "/programs-mgt/activity-concept-notes/meta-data",
      method: "GET",
    })
      .then((res) => {
        set(
          produce((state) => {
            state.metaData = res;
          })
        );
      })
      .catch((error) => {
        set(
          produce((state) => {
            state.metaDataError = error;
          })
        );
      })
      .finally(
        set(
          produce((state) => {
            state.gettingMetaData = false;
          })
        )
      );
  },

  async getProjectActivityNotes(projectId) {
    set({ gettingProjectActivityNotes: true });
    await axios({
      url: `/programs-mgt/activity-concept-notes/project/${projectId}`,
      method: "GET",
    })
      .then((res) => {
        set(
          produce((state) => {
            state.projectActivityNotes = res;
          })
        );
      })
      .catch((error) => {
        set(
          produce((state) => {
            state.projectActivityNotesError = error;
          })
        );
      })
      .finally(() => {
        set({ gettingProjectActivityNotes: false });
      });
  },

  async getActivityNoteDetails(id) {
    set({ gettingActivityNoteDetails: true });
    await axios({
      url: `/programs-mgt/activity-concept-notes/${id}`,
      method: "GET",
    })
      .then((res) => {
        set(
          produce((state) => {
            state.activityNoteDetails = res;
          })
        );
      })
      .catch((error) => {
        set(
          produce((state) => {
            state.activityNoteDetailsError = error;
          })
        );
      })
      .finally(() => {
        set({ gettingActivityNoteDetails: false });
      });
  },

  async createActivityNote(data) {
    set({ creatingActivityNote: true });
    await axios({
      url: "/programs-mgt/activity-concept-notes",
      method: "POST",
      data,
    })
      .then((res) => {
        set(
          produce((state) => {
            state.showActivityNoteDetails = true;
            get().getActivityNoteDetails(res.data.id);
            get().getProjectActivityNotes(data.project_id);
          })
        );
      })
      .catch((error) => {
        set(
          produce((state) => {
            state.createActivityNoteError = error;
          })
        );
      })
      .finally(() => {
        set({ creatingActivityNote: false });
      });
  },

  async updateActivityNote(id, data) {
    set(
      produce((state) => {
        state.updatingActivityNote = true;
        console.log(state.updatingActivityNote);
      })
    );
    await axios({
      url: `/programs-mgt/activity-concept-notes/${id}`,
      method: "PUT",
      data,
    })
      .then((res) => {
        set(
          produce((state) => {
            get().setCreating(false);
            const index = state.activityNotes.findIndex(
              (note) => note.id === id
            );
            state.activityNotes.data[index] = res;
          })
        );
      })
      .catch((error) => {
        set(
          produce((state) => {
            state.updateActivityNoteError = error;
          })
        );
      })
      .finally(
        set(
          produce((state) => {
            state.updatingActivityNote = false;
          })
        )
      );
  },

  setCreating(show = false, creating = false, data = {}) {
    set(
      produce((state) => {
        state.showCreatingActivityNote = show;
        state.creating = creating;
        state.activityNoteToUpdate = data;
      })
    );
  },

  async deleteActivityNote(id) {
    set(
      produce((state) => {
        state.deletingActivityNote = true;
      })
    );
    await axios({
      url: `/programs-mgt/activity-concept-notes/${id}`,
      method: "DELETE",
    })
      .then(() => {
        set(
          produce((state) => {
            state.activityNotes.data = state.activityNotes.data.filter(
              (note) => note.id !== id
            );
          })
        );
      })
      .catch((error) => {
        set(
          produce((state) => {
            state.deleteActivityNoteError = error;
          })
        );
      })
      .finally(
        set(
          produce((state) => {
            state.deletingActivityNote = false;
          })
        )
      );
  },

  async submitActivityNote(id) {
    set(
      produce((state) => {
        state.submittingActivityNote = true;
      })
    );
    await axios({
      url: `/programs-mgt/activity-concept-notes/submit/${id}`,
      method: "PATCH",
    })
      .then(() => {
        set(
          produce((state) => {
            const index = findIndex(state.activityNotes.data, { id });
            state.activityNotes.data[index].status = "submitted";

            get().getActivityNoteDetails(id);
          })
        );
      })
      .catch((error) => {
        set(
          produce((state) => {
            state.submitActivityNoteError = error;
          })
        );
      })
      .finally(() => {
        set(
          produce((state) => {
            state.submittingActivityNote = false;
          })
        );
      });
  },

  async approveActivityNote(id) {
    set(
      produce((state) => {
        state.approvingActivityNote = true;
      })
    );
    await axios({
      url: `/programs-mgt/activity-concept-notes/approve/${id}`,
      method: "PATCH",
    })
      .then(() => {
        set(
          produce((state) => {
            const index = findIndex(state.activityNotes.data, { id });
            state.activityNotes.data[index].status = "approved";

            get().getActivityNoteDetails(id);
          })
        );
      })
      .catch((error) => {
        set(
          produce((state) => {
            state.approveActivityNoteError = error;
          })
        );
      })
      .finally(
        set(
          produce((state) => {
            state.approvingActivityNote = false;
          })
        )
      );
  },

  async checkActivityNote(id) {
    set(
      produce((state) => {
        state.checkingActivityNote = true;
      })
    );
    await axios({
      url: `/programs-mgt/activity-concept-notes/check/${id}`,
      method: "PATCH",
    })
      .then(() => {
        set(
          produce((state) => {
            const index = findIndex(state.activityNotes.data, { id });
            state.activityNotes.data[index].status = "checked";

            get().getActivityNoteDetails(id);
          })
        );
      })
      .catch((error) => {
        set(
          produce((state) => {
            state.checkActivityNoteError = error;
          })
        );
      })
      .finally(
        set(
          produce((state) => {
            state.checkingActivityNote = false;
          })
        )
      );
  },

  async rejectActivityNote(id) {
    set(
      produce((state) => {
        state.rejectingActivityNote = true;
      })
    );
    await axios({
      url: `/programs-mgt/activity-concept-notes/reject/${id}`,
      method: "PATCH",
    })
      .then(() => {
        set(
          produce((state) => {
            const index = findIndex(state.activityNotes.data, { id });
            state.activityNotes.data[index].status = "rejected";

            get().getActivityNoteDetails(id);
          })
        );
      })
      .catch((error) => {
        set(
          produce((state) => {
            state.rejectActivityNoteError = error;
          })
        );
      })
      .finally(
        set(
          produce((state) => {
            state.rejectingActivityNote = false;
          })
        )
      );
  },

  async undoActivityNoteApproval(id) {
    set(
      produce((state) => {
        state.undoingActivityNoteApproval = true;
      })
    );
    await axios({
      url: `/programs-mgt/activity-concept-notes/undo-approval/${id}`,
      method: "PATCH",
    })
      .then(() => {
        set(
          produce((state) => {
            const index = findIndex(state.activityNotes.data, { id });
            state.activityNotes.data[index].status = "checked";

            get().getActivityNoteDetails(id);
          })
        );
      })
      .catch((error) => {
        set(
          produce((state) => {
            state.undoActivityNoteApprovalError = error;
          })
        );
      })
      .finally(
        set(
          produce((state) => {
            state.undoingActivityNoteApproval = false;
          })
        )
      );
  },

  async createActivities(data, activityNoteId) {
    set(
      produce((state) => {
        state.creatingActivities = true;
        state.createdActivity = null;
      })
    );
    await axios({
      url: "/programs-mgt/activity-concept-notes/activities",
      method: "POST",
      data,
    })
      .then((res) => {
        set({
          creatingActivities: false,
          createdActivity: res.data,
        });
        get().getActivityNoteDetails(activityNoteId);
      })
      .catch((error) => {
        set(
          produce((state) => {
            state.createActivitiesError = error;
          })
        );
      })
      .finally(
        set(
          produce((state) => {
            state.creatingActivities = false;
          })
        )
      );
  },

  async updateActivities(id, data, activityNoteId) {
    set({ updatingActivities: true, updatedActivity: null });
    await axios({
      url: `/programs-mgt/activity-concept-notes/activities/${id}`,
      method: "PUT",
      data,
    })
      .then((res) => {
        set({
          updatingActivities: false,
          updatedActivity: res.data,
          showActivityForm: false,
        });
        get().getActivityNoteDetails(activityNoteId);
      })
      .catch((error) => {
        set(
          produce((state) => {
            state.updateActivitiesError = error;
          })
        );
      })
      .finally(
        set(
          produce((state) => {
            state.updatingActivities = false;
          })
        )
      );
  },

  async deleteActivities(id, activityNoteId) {
    set(
      produce((state) => {
        state.deletingActivities = true;
      })
    );
    await axios({
      url: `/programs-mgt/activity-concept-notes/activities/${id}`,
      method: "DELETE",
    })
      .then(() => {
        get().getActivityNoteDetails(activityNoteId);
      })
      .catch((error) => {
        set(
          produce((state) => {
            state.deleteActivitiesError = error;
          })
        );
      })
      .finally(
        set(
          produce((state) => {
            state.deletingActivities = false;
          })
        )
      );
  },

  setShowViewActivityNoteDetails(show = false) {
    set(
      produce((state) => {
        state.showActivityNoteDetails = show;
      })
    );
  },

  setShowActivitiesForm(creating = false, show = false, data = {}) {
    set(
      produce((state) => {
        state.showActivityForm = show;
        state.creatingActivity = creating;
        state.activityToUpdate = data;
      })
    );
  },
});

const storeName = "ACTIVITY CONCEPT NOTE";

const useActivityNoteStore = create(
  devtools(store, {
    name: storeName,
  }),
  {
    name: storeName,
  }
);

export default useActivityNoteStore;
