import React from "react";
import { Form, Input, Button, Alert } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { appUiActions } from "../../../config/actions/app/appUi.actions";

const LoginForm = ({ handleLogin }) => {
  const { loginLoading, loginSuccess, loginError } = useSelector(
    (state) => state.authState
  );
  const dispatch = useDispatch();
  const onFinish = (values) => {
    handleLogin(values);
  };

  const openModal = () => {
    dispatch(appUiActions.toggleForgotPasswordModal(true));
  };
  return (
    <div className="card-responsive card bg-white shadow-small w-25  mt-3 ">
      <div className="card-header text-dark fw-bold  bg-white">
        <p className="m-0">LOG IN TO YOUR ACCOUNT</p>
      </div>
      {loginError && !loginSuccess && (
        <Alert className="m-2" showIcon type="error" message={loginError} />
      )}
      <div className="card-body text-start">
        <Form layout="vertical" onFinish={onFinish}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Please enter your email" },
              {
                pattern:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: "Please enter a valid email",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              { required: true, message: "Please enter a password" },
              {
                min: 8,
                message: "Your password should have more than 8 characters ",
              },
            ]}
          >
            <Input.Password visibilityToggle={false} />
          </Form.Item>
          <Form.Item>
            <Button
              loading={loginLoading}
              className="w-100"
              htmlType="submit"
              type="primary"
            >
              LOGIN
            </Button>
            <div className="d-flex justify-content-center mt-3">
              <Button onClick={openModal} htmlType={"button"} type="text">
                Forgot Password ?
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default LoginForm;
