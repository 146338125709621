import { Col, Input, Row } from "antd";
import React from "react";
import dayjs from "dayjs";

const ProjectInfoDetails = ({ data }) => {
  const getCurrency = (data) => {
    try {
      return data?.approved_budget[data?.approved_budget?.length - 1];
    } catch (error) {
      console.error(error);
      return "";
    }
  };
  const currency = getCurrency(data);
  return (
    <Row className="mb-2" gutter={4}>
      <Col xs={24} sm={24} md={12} lg={6} xl={6}>
        <Row gutter={4}>
          <Col span={6}>
            <span className="fw-bold me-1">Project:</span>
          </Col>
          <Col span={18}>
            <Input value={data.name} />
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={12} lg={6} xl={6}>
        <Row gutter={4}>
          <Col span={6}>
            <span className="fw-bold me-1">Donor:</span>
          </Col>
          <Col span={18}>
            <Input value={data.donor} />
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={12} lg={6} xl={6}>
        <Row gutter={4}>
          <Col span={6}>
            <span className="fw-bold me-1">Start Date:</span>
          </Col>
          <Col span={18}>
            <Input value={dayjs(data.start_date).format("DD/MM/YYYY")} />
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={12} lg={6} xl={6}>
        <Row gutter={4}>
          <Col span={6}>
            <span className="fw-bold me-1">End Date:</span>
          </Col>
          <Col span={18}>
            <Input value={dayjs(data.end_date).format("DD/MM/YYYY")} />
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={12} lg={6} xl={6} className="my-1">
        <Row gutter={4}>
          <Col span={6}>
            <span className="fw-bold me-1">In Charge:</span>
          </Col>
          <Col span={18}>
            <Input value={data.personInchargeName} />
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={12} lg={6} xl={6} className="my-1">
        <Row gutter={4}>
          <Col span={6}>
            <span className="fw-bold me-1">Supervisor:</span>
          </Col>
          <Col span={18}>
            <Input value={data.supervisorName} />
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={12} lg={6} xl={6} className="my-1">
        <Row gutter={4}>
          <Col span={6}>
            <span className="fw-bold me-1">Accountant:</span>
          </Col>
          <Col span={18}>
            <Input value={data.projectAccountantName} />
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={12} lg={6} xl={6} className="my-1">
        <Row gutter={4}>
          <Col span={6}>
            <span className="fw-bold me-1">Bank:</span>
          </Col>
          <Col span={18}>
            <Input value={data.bank} />
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={12} lg={6} xl={6} className="my-1">
        <Row gutter={4}>
          <Col span={6}>
            <span className="fw-bold me-1">Acc. No.:</span>
          </Col>
          <Col span={18}>
            <Input value={data.account_number} />
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={12} lg={6} xl={6} className="my-1">
        <Row gutter={4}>
          <Col span={6}>
            <span className="fw-bold me-1">Budget:</span>
          </Col>
          <Col span={18}>
            <Input
              addonBefore={currency === "D" ? "USD" : "UGX"}
              value={parseInt(data.approved_budget)?.toLocaleString()}
            />
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={12} lg={6} xl={6}>
        <Row gutter={4}>
          <Col span={6}>
            <span className="fw-bold me-1">Created By:</span>
          </Col>
          <Col span={18}>
            <Input
              value={`${data?.creator?.first_name || ""} ${
                data?.creator?.other_names || ""
              }`}
            />
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={12} lg={6} xl={6}>
        <Row gutter={4}>
          <Col span={6}>
            <span className="fw-bold me-1">Created At:</span>
          </Col>
          <Col span={18}>
            <Input value={dayjs(data.created_at).format("DD/MM/YYYY")} />
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={12} lg={6} xl={6}>
        <Row gutter={4}>
          <Col span={6}>
            <span className="fw-bold me-1">Updated By:</span>
          </Col>
          <Col span={18}>
            <Input
              value={`${data?.updator?.first_name || ""} ${
                data?.updator?.other_names || ""
              }`}
            />
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={12} lg={6} xl={6}>
        <Row gutter={4}>
          <Col span={6}>
            <span className="fw-bold me-1">Updated At:</span>
          </Col>
          <Col span={18}>
            <Input value={dayjs(data.updated_at).format("DD/MM/YYYY")} />
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={12} lg={6} xl={6}>
        <Row gutter={4}>
          <Col span={6}>
            <span className="fw-bold me-1">Checked By:</span>
          </Col>
          <Col span={18}>
            <Input
              value={`${data?.checker?.first_name || ""} ${
                data?.checker?.other_names || ""
              }`}
            />
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={12} lg={6} xl={6}>
        <Row gutter={4}>
          <Col span={6}>
            <span className="fw-bold me-1">Checked At:</span>
          </Col>
          <Col span={18}>
            <Input
              value={
                data.approved_at && dayjs(data.approved_at).format("DD/MM/YYYY")
              }
            />
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={12} lg={6} xl={6} className="my-1">
        <Row gutter={4}>
          <Col span={6}>
            <span className="fw-bold me-1">Approved By:</span>
          </Col>
          <Col span={18}>
            <Input
              value={`${data?.approver?.first_name || ""} ${
                data?.approver?.other_names || ""
              }`}
            />
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={12} lg={6} xl={6} className="my-1">
        <Row gutter={4}>
          <Col span={6}>
            <span className="fw-bold me-1">Approved At:</span>
          </Col>
          <Col span={18}>
            <Input
              value={
                data.approved_at && dayjs(data.approved_at).format("DD/MM/YYYY")
              }
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ProjectInfoDetails;
