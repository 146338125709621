import { bidEvaluationsActions } from "../../../actions/procument/bidEvaluations/bidEvaluations.actions";
import apiRequests from "../../../api/api";

const bidEvaluationThunks = {
  getBidEvaluations: () => async (dispatch) => {
    dispatch(bidEvaluationsActions.setBidEvaluationsLoading());
    const res = await apiRequests.getRequest("/procurement/bidEvaluations/");
    if (res.success)
      dispatch(bidEvaluationsActions.setBidEvaluationsSuccess(res.data));
    else dispatch(bidEvaluationsActions.setBidEvaluationsError(res.error));
  },
  getEligibilityEvaluations: (id) => async (dispatch) => {
    dispatch(bidEvaluationsActions.setEligibilityEvaluationsLoading());
    const res = await apiRequests.getRequest(
      `/procurement/bidEvaluations/evaluations/eligibility/${id}`
    );
    if (res.success)
      dispatch(
        bidEvaluationsActions.setEligibilityEvaluationsSuccess(res.data)
      );
    else
      dispatch(bidEvaluationsActions.setEligibilityEvaluationsError(res.error));
  },
  getEligibilityCriteriaDetails: (id) => async (dispatch) => {
    dispatch(bidEvaluationsActions.setEligibilityEvaluationDetailsLoading());
    const res = await apiRequests.getRequest(
      `/procurement/bidEvaluations/evaluations/eligibility/details/${id}`
    );
    if (res.success)
      dispatch(
        bidEvaluationsActions.setEligibilityEvaluationDetailsSuccess(res.data)
      );
    else
      dispatch(
        bidEvaluationsActions.setEligibilityEvaluationDetailsError(res.error)
      );
  },
  getBidEvaluationDetails: (id) => async (dispatch) => {
    dispatch(bidEvaluationsActions.setBidEvaluationDetailsLoading());
    const res = await apiRequests.getRequest(
      `/procurement/bidEvaluations/${id}`
    );
    if (res.success)
      dispatch(bidEvaluationsActions.setBidEvaluationDetailsSuccess(res.data));
    else
      dispatch(bidEvaluationsActions.setBidEvaluationDetailsError(res.error));
  },
  getBidEvaluationsMetaData: (id) => async (dispatch) => {
    dispatch(bidEvaluationsActions.setBidEvaluationsMetaDataLoading());
    const res = await apiRequests.getRequest(
      `/procurement/bidEvaluations/metaData/${id}`
    );
    dispatch(bidEvaluationsActions.setBidEvaluationsMetaData(res.data || {}));
  },
  addEligibilityEvaluationCritieria:
    (values, evaluations) => async (dispatch) => {
      dispatch(
        bidEvaluationsActions.addEligibilityEvaluationsCriteriaRequest()
      );
      const res = await apiRequests.postRequest(
        "/procurement/bidEvaluations/evaluations/eligibility/",
        values
      );
      if (res.success)
        dispatch(
          bidEvaluationsActions.addEligibilityEvaluationsCriteriaSuccess({
            data: [res.data, ...evaluations],
            message: res.message,
          })
        );
      else
        dispatch(
          bidEvaluationsActions.addEligibilityEvaluationsCriteriaError(
            res.error
          )
        );
      dispatch(
        bidEvaluationsActions.addEligibilityEvaluationsCriteriaComplete()
      );
    },
  deleteEligibilityEvaluationCriteria: (id, criteria) => async (dispatch) => {
    dispatch(
      bidEvaluationsActions.deleteEligibilityEvaluationsCriteriaRequest()
    );
    const res = await apiRequests.deleteRequest(
      `/procurement/bidEvaluations/evaluations/eligibility/${id}`
    );
    if (res.success) {
      dispatch(
        bidEvaluationsActions.deleteEligibilityEvaluationsCriteriaSuccess({
          data: criteria,
          message: res.message,
        })
      );
    } else
      dispatch(
        bidEvaluationsActions.deleteEligibilityEvaluationsCriteriaError(
          res.error
        )
      );
    dispatch(
      bidEvaluationsActions.deleteEligibilityEvaluationsCriteriaComplete()
    );
  },
  editEligibilityEvaluationCriteria:
    (values, evaluations) => async (dispatch) => {
      dispatch(
        bidEvaluationsActions.editEligibilityEvaluationsCriteriaRequest()
      );
      const res = await apiRequests.putRequest(
        `/procurement/bidEvaluations/evaluations/eligibility/${values.id}`,
        values
      );
      if (res.success)
        dispatch(
          bidEvaluationsActions.editEligibilityEvaluationsCriteriaSuccess({
            data: [res.data, ...evaluations],
            message: res.message,
          })
        );
      else
        dispatch(
          bidEvaluationsActions.editEligibilityEvaluationsCriteriaError(
            res.error
          )
        );
      dispatch(
        bidEvaluationsActions.editEligibilityEvaluationsCriteriaComplete()
      );
    },
  editEligibilityEvaluation: (values, evaluations) => async (dispatch) => {
    dispatch(bidEvaluationsActions.editEligibilityEvaluationsRequest());
    const res = await apiRequests.patchRequest(
      `/procurement/bidEvaluations/evaluations/eligibility/evaluateBidder/${values.id}`,
      values
    );
    if (res.success) {
      dispatch(
        bidEvaluationsActions.editEligibilityEvaluationsSuccess(res.message)
      );
      dispatch(
        bidEvaluationThunks.getEligibilityEvaluations(values.bid_evaluation)
      );
    } else
      dispatch(
        bidEvaluationsActions.editEligibilityEvaluationsError(res.error)
      );
    dispatch(bidEvaluationsActions.editEligibilityEvaluationsComplete());
  },
  addFinancialEvaluation: (values) => async (dispatch) => {
    dispatch(bidEvaluationsActions.addFinancialEvaluationsRequest());
    const res = await apiRequests.postRequest(
      "/procurement/bidEvaluations/evaluations/financial",
      values
    );
    if (res.success)
      dispatch(
        bidEvaluationsActions.addFinancialEvaluationsSuccess(res.message)
      );
    else
      dispatch(bidEvaluationsActions.addFinancialEvaluationsError(res.error));
    dispatch(bidEvaluationsActions.addFinancialEvaluationsComplete());
  },
  addTechnicalEvaluation: (values) => async (dispatch) => {
    dispatch(bidEvaluationsActions.addTechnicalEvaluationsFormRequest());
    const res = await apiRequests.postRequest(
      "/procurement/bidEvaluations/evaluations/technical",
      values
    );
    if (res.success)
      dispatch(
        bidEvaluationsActions.addTechnicalEvaluationsFormSuccess(res.message)
      );
    else
      dispatch(
        bidEvaluationsActions.addTechnicalEvaluationsFormError(res.error)
      );
    dispatch(bidEvaluationsActions.addTechnicalEvaluationsFormComplete());
  },
  editTechnicalEvaluation: (values) => async (dispatch) => {
    dispatch(bidEvaluationsActions.editTechnicalEvaluationsRequest());
    const res = await apiRequests.patchRequest(
      `/procurement/bidEvaluations/evaluations/technical/${values.id}`,
      values
    );
    if (res.success) {
      dispatch(
        bidEvaluationsActions.editTechnicalEvaluationsSuccess(res.message)
      );
      dispatch(
        bidEvaluationThunks.getTechnicalEvaluations(values.bid_evaluation)
      );
    } else
      dispatch(bidEvaluationsActions.editTechnicalEvaluationsError(res.error));
    dispatch(bidEvaluationsActions.editTechnicalEvaluationsComplete());
  },
  editFinancialEvaluation: (values) => async (dispatch) => {
    dispatch(bidEvaluationsActions.editFinancialEvaluationsRequest());
    const res = await apiRequests.patchRequest(
      `/procurement/bidEvaluations/evaluations/financial/${values.id}`,
      values
    );
    if (res.success) {
      dispatch(
        bidEvaluationsActions.editFinancialEvaluationsSuccess(res.message)
      );
      dispatch(bidEvaluationThunks.getFinancialEvaluationItemData(values.item));
    } else
      dispatch(bidEvaluationsActions.editFinancialEvaluationsError(res.error));
    dispatch(bidEvaluationsActions.editFinancialEvaluationsComplete());
  },
  getTechnicalEvaluations: (id) => async (dispatch) => {
    dispatch(bidEvaluationsActions.setEvaluationItemsRequest());
    const res = await apiRequests.getRequest(
      `/procurement/bidEvaluations/evaluations/technical/${id}`
    );
    if (res.success)
      dispatch(bidEvaluationsActions.setEvaluationItemsSuccess(res.data));
    else dispatch(bidEvaluationsActions.setEvaluationItemsError(res.error));
  },
  getFinancialEvaluationItems: (id) => async (dispatch) => {
    dispatch(bidEvaluationsActions.setFinancialEvaluationItemsRequest());
    const res = await apiRequests.getRequest(
      `/procurement/bidEvaluations/evaluations/financial/${id}`
    );
    if (res.success)
      dispatch(
        bidEvaluationsActions.setFinancialEvaluationItemsSuccess(
          res.data,
          res.financialEvaluations
        )
      );
    else
      dispatch(
        bidEvaluationsActions.setFinancialEvaluationItemsError(res.error)
      );
  },
  getFinancialEvaluationItemData: (id) => async (dispatch) => {
    dispatch(bidEvaluationsActions.setFinancialEvaluationDataRequest());
    const res = await apiRequests.getRequest(
      `/procurement/bidEvaluations/evaluations/financial/evaluationData/${id}`
    );
    if (res.success)
      dispatch(
        bidEvaluationsActions.setFinancialEvaluationDataSuccess(res.data)
      );
    else
      dispatch(
        bidEvaluationsActions.setFinancialEvaluationDataError(res.error)
      );
  },
  getTechnicalEvaluationItemData: (id) => async (dispatch) => {
    dispatch(bidEvaluationsActions.setTechnicalEvaluationDataRequest());
    const res = await apiRequests.getRequest(
      `/procurement/bidEvaluations/evaluations/technical/evaluationData/${id}`
    );
    if (res.success)
      dispatch(
        bidEvaluationsActions.setTechnicalEvaluationDataSuccess(res.data)
      );
    else
      dispatch(
        bidEvaluationsActions.setTechnicalEvaluationDataError(res.error)
      );
  },
  addBidEvaluationForm: (values, bidEvaluations) => async (dispatch) => {
    dispatch(bidEvaluationsActions.addBidEvaluationsRequest());
    const res = await apiRequests.postRequest(
      "/procurement/bidEvaluations",
      values
    );
    if (res.success)
      dispatch(
        bidEvaluationsActions.addBidEvaluationsSuccess({
          data: [res.data, ...bidEvaluations],
          message: res.message,
        })
      );
    else dispatch(bidEvaluationsActions.addBidEvaluationsError(res.error));
    dispatch(bidEvaluationsActions.addBidEvaluationsComplete());
  },
  deleteBidEvaluationForm: (id, bidEvaluations) => async (dispatch) => {
    dispatch(bidEvaluationsActions.deleteBidEvaluationsRequest());
    const res = await apiRequests.deleteRequest(
      `/procurement/bidEvaluations/${id}`
    );
    if (res.success)
      dispatch(
        bidEvaluationsActions.deleteBidEvaluationsSuccess({
          data: bidEvaluations,
          message: res.message,
        })
      );
    else dispatch(bidEvaluationsActions.deleteBidEvaluationsError(res.error));
    dispatch(bidEvaluationsActions.deleteBidEvaluationsComplete());
  },
  editBidEvaluationForm: (values, bidEvaluations) => async (dispatch) => {
    dispatch(bidEvaluationsActions.editBidEvaluationsRequest());
    const res = await apiRequests.patchRequest(
      `/procurement/bidEvaluations/${values.id}`,
      values
    );
    if (res.success)
      dispatch(
        bidEvaluationsActions.editBidEvaluationsSuccess({
          data: [res.data, ...bidEvaluations],
          message: res.message,
        })
      );
    else dispatch(bidEvaluationsActions.editBidEvaluationsError(res.error));
    dispatch(bidEvaluationsActions.editBidEvaluationsComplete());
  },
  submitBidEvaluationForm: (values) => async (dispatch) => {
    dispatch(bidEvaluationsActions.submitBidEvaluationsFormRequest());
    const res = await apiRequests.patchRequest(
      `/procurement/bidEvaluations/submit/${values.id}`,
      values
    );
    if (res.success) {
      dispatch(
        bidEvaluationsActions.submitBidEvaluationsFormSuccess(res.message)
      );
      dispatch(bidEvaluationThunks.getBidEvaluationDetails(values.id));
    } else
      dispatch(bidEvaluationsActions.submitBidEvaluationsFormError(res.error));
    dispatch(bidEvaluationsActions.submitBidEvaluationsFormComplete());
  },
  checkBidEvaluationForm: (id) => async (dispatch) => {
    dispatch(bidEvaluationsActions.checkBidEvaluationsFormRequest());
    const res = await apiRequests.patchRequest(
      `/procurement/bidEvaluations/check/${id}`,
      { checkedAt: new Date() }
    );
    if (res.success) {
      dispatch(
        bidEvaluationsActions.checkBidEvaluationsFormSuccess(res.message)
      );
      dispatch(bidEvaluationThunks.getBidEvaluationDetails(id));
    } else
      dispatch(bidEvaluationsActions.checkBidEvaluationsFormError(res.error));
    dispatch(bidEvaluationsActions.checkBidEvaluationsFormComplete());
  },
  approveBidEvaluationForm: (id) => async (dispatch) => {
    dispatch(bidEvaluationsActions.approveBidEvaluationsFormRequest());
    const res = await apiRequests.patchRequest(
      `/procurement/bidEvaluations/approve/${id}`,
      { approvedAt: new Date() }
    );
    if (res.success) {
      dispatch(
        bidEvaluationsActions.approveBidEvaluationsFormSuccess(res.message)
      );
      dispatch(bidEvaluationThunks.getBidEvaluationDetails(id));
    } else
      dispatch(bidEvaluationsActions.approveBidEvaluationsFormError(res.error));
    dispatch(bidEvaluationsActions.approveBidEvaluationsFormComplete());
  },
  rejectBidEvaluationForm: (values) => async (dispatch) => {
    dispatch(bidEvaluationsActions.rejectBidEvaluationsFormRequest());
    const res = await apiRequests.patchRequest(
      `/procurement/bidEvaluations/reject/${values.id}`,
      values
    );
    if (res.success)
      dispatch(
        bidEvaluationsActions.rejectBidEvaluationsFormSuccess(res.message)
      );
    else
      dispatch(bidEvaluationsActions.rejectBidEvaluationsFormError(res.error));
    dispatch(bidEvaluationsActions.rejectBidEvaluationsFormComplete());
  },
  removeBidderFromEvaluation: (values) => async (dispatch) => {
    dispatch(bidEvaluationsActions.removeBidderFromBidEvaluationFormRequest());
    const res = await apiRequests.patchRequest(
      `/procurement/bidEvaluations/removeBidderFromEvaluation/${values.id}`,
      values
    );
    if (res.success)
      dispatch(
        bidEvaluationsActions.removeBidderFromBidEvaluationFormSuccess(
          res.message
        )
      );
    else
      dispatch(
        bidEvaluationsActions.removeBidderFromBidEvaluationFormError(res.error)
      );
    dispatch(bidEvaluationsActions.removeBidderFromBidEvaluationFormComplete());
  },
};

export default bidEvaluationThunks;
