import { Drawer } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { appUiActions } from "../../../config/actions/app/appUi.actions";
import { Menu, Space } from "antd";
import {
  FcBriefcase,
  FcComboChart,
  FcGlobe,
  FcList,
  FcPackage,
  FcSettings,
  FcWorkflow,
} from "react-icons/fc";
import { Link } from "react-router-dom";
import routes from "../../../config/routes/routes";
import minLogo from "../../../assets/logo/favicon.png";

const { SubMenu } = Menu;

const AppDrawer = () => {
  const visible = useSelector((state) => state.AppUi.appDrawerVisible);
  const dispatch = useDispatch();

  const closeAppDrawer = () => {
    console.info(dispatch(appUiActions.toggleAppDrawer(false)));
  };
  const {
    data: { viewPermissions },
  } = useSelector((state) => state.usersState.currentUserInfo);
  /* viewPermissions */
  return (
    <div>
      <Drawer placement="left" onClose={closeAppDrawer} open={visible} closable>
        <Space className="mt-4 mx-3 mb-2">
          <img
            className="m-1"
            src={minLogo}
            width="40"
            height="40"
            alt="mini logo"
          />
          <h3 className="my-0">AIFMIS</h3>
        </Space>
        <Menu mode="inline">
          <Menu.Item onClick={closeAppDrawer} key="0" icon={<FcWorkflow />}>
            <Link to="/">Dashboard</Link>
          </Menu.Item>
          <SubMenu key="adminOps" icon={<FcBriefcase />} title="Administration">
            {viewPermissions?.Staff_Records && (
              <Menu.Item onClick={closeAppDrawer} key="2">
                <Link to={routes.administration.staffRecords.path}>
                  Staff Records
                </Link>
              </Menu.Item>
            )}
            {viewPermissions?.Asset_register && (
              <Menu.Item onClick={closeAppDrawer} key="22">
                <Link to={routes.administration.assetRegister.assets.path}>
                  Assets Register
                </Link>
              </Menu.Item>
            )}
            {viewPermissions?.Stores_Register && (
              <Menu.Item onClick={closeAppDrawer} key="3">
                <Link to={routes.administration.storesRegister.path}>
                  Stores Register
                </Link>
              </Menu.Item>
            )}
            {viewPermissions?.Manuals_and_policies && (
              <Menu.Item onClick={closeAppDrawer} key="21">
                <Link to={routes.administration.manualsAndPolicies.path}>
                  Manual and Policies
                </Link>
              </Menu.Item>
            )}
          </SubMenu>
          <SubMenu
            key="businsessDev"
            title="Business Development and Partnerships"
            icon={<FcGlobe />}
          >
            <Menu.ItemGroup title="Busniess Development and Partnerships">
              {viewPermissions?.Donors && (
                <Menu.Item onClick={closeAppDrawer} key="4">
                  <Link
                    to={routes.businessDevelopmentAndPartnerships.donors.path}
                  >
                    Funding And Partnership Opportunities
                  </Link>
                </Menu.Item>
              )}
              {viewPermissions?.Projects && (
                <Menu.Item onClick={closeAppDrawer} key="5">
                  <Link
                    to={routes.businessDevelopmentAndPartnerships.projects.path}
                  >
                    Approved Projects
                  </Link>
                </Menu.Item>
              )}
            </Menu.ItemGroup>
          </SubMenu>
          <SubMenu key="finance" icon={<FcComboChart />} title="Finance">
            {viewPermissions?.Chart_of_accounts && (
              <Menu.Item onClick={closeAppDrawer} key="6">
                <Link to={routes.finance.chartOfAccounts.path}>
                  Chart Of Accounts
                </Link>
              </Menu.Item>
            )}
            {viewPermissions?.Chart_of_accounts && (
              <Menu.Item onClick={closeAppDrawer} key="26">
                <Link to={routes.finance.accounts.path}>Accounts</Link>
              </Menu.Item>
            )}
            {viewPermissions?.Budgets && (
              <Menu.Item key="7">
                <Link onClick={closeAppDrawer} to={routes.finance.budget.path}>
                  Budgets
                </Link>
              </Menu.Item>
            )}
            {viewPermissions?.Payment_voucher && (
              <Menu.Item onClick={closeAppDrawer} key="9">
                <Link to={routes.finance.paymentVoucher.path}>
                  Funds Advance/Withdraw Form
                </Link>
              </Menu.Item>
            )}
            {viewPermissions?.Advanced_accountability_form && (
              <Menu.Item onClick={closeAppDrawer} key="10">
                <Link to={routes.finance.advanceAccountabilityForm.path}>
                  Payment Voucher
                </Link>
              </Menu.Item>
            )}
            {viewPermissions?.Requisition_memo && (
              <Menu.Item key="11">
                <Link
                  onClick={closeAppDrawer}
                  to={routes.finance.requistionMemos.path}
                >
                  Requisition Form
                </Link>
              </Menu.Item>
            )}
          </SubMenu>
          <SubMenu icon={<FcPackage />} key="procurement" title="Procurement">
            {viewPermissions?.Request_For_Quotation && (
              <Menu.Item onClick={closeAppDrawer} key="12">
                <Link to={routes.procurement.rfq.path}>
                  Request For Quotation
                </Link>
              </Menu.Item>
            )}
            {viewPermissions?.Receiving_of_bids && (
              <Menu.Item onClick={closeAppDrawer} key="13">
                <Link to={routes.procurement.recordOfBidders.path}>
                  Receiving Of Bidders
                </Link>
              </Menu.Item>
            )}
            {viewPermissions?.Bids_evaluation && (
              <Menu.Item onClick={closeAppDrawer} key="14">
                <Link to={routes.procurement.bidEvaluation.path}>
                  Bids Evaluation
                </Link>
              </Menu.Item>
            )}
            {viewPermissions?.Notice_of_best_bidder && (
              <Menu.Item onClick={closeAppDrawer} key="15">
                <Link to={routes.procurement.noticeOfBestBidder.path}>
                  Notice Of The Best Bidder
                </Link>
              </Menu.Item>
            )}
            {viewPermissions?.Local_purchase_order && (
              <Menu.Item onClick={closeAppDrawer} key="16">
                <Link to={routes.procurement.localPurchaseOrder.path}>
                  Local Purchase Order
                </Link>
              </Menu.Item>
            )}
          </SubMenu>
          <Menu.Item icon={<FcList />} onClick={closeAppDrawer} key="23">
            <Link to={routes.myProjects.path}>My Projects</Link>
          </Menu.Item>
          <SubMenu key="settings" icon={<FcSettings />} title="Settings">
            <Menu.Item onClick={closeAppDrawer} key="24">
              <Link to={routes.settings.profile.path}>My profile</Link>
            </Menu.Item>
            {viewPermissions?.Roles && (
              <Menu.Item onClick={closeAppDrawer} key="17">
                <Link to={routes.settings.roles.path}>System Roles</Link>
              </Menu.Item>
            )}
            {viewPermissions?.Permissions && (
              <Menu.Item onClick={closeAppDrawer} key="18">
                <Link to={routes.settings.permissions.path}>
                  System Permissions
                </Link>
              </Menu.Item>
            )}
            {viewPermissions?.Log_details && (
              <Menu.Item onClick={closeAppDrawer} key="19">
                <Link to={routes.settings.logDetails.path}>Log Details</Link>
              </Menu.Item>
            )}
          </SubMenu>
        </Menu>
      </Drawer>
    </div>
  );
};

export default AppDrawer;
