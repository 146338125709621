import axios from "axios";

const errorResponseObject = {
  success: false,
  error: "Sorry, there seems to be an error.",
};

const chartOfAccountApiRequests = {
  fetchChartOfAccounts: async () => {
    try {
      const res = await axios.get("/finance/chartOfAccounts/");
      return res;
    } catch (error) {
      console.error("Fetch chart of accounts error:", error);
      return errorResponseObject;
    }
  },

  fetchMetaData: async () => {
    try {
      const res = await axios.get("/finance/chartOfAccounts/metaData");
      return res;
    } catch (error) {
      console.error("Fetch chart of accounts meta data error:", error);
      return errorResponseObject;
    }
  },

  fetchAccountDetails: async (id) => {
    try {
      const res = await axios.get(`/finance/chartOfAccounts/accounts/${id}`);
      return res;
    } catch (error) {
      console.error("Fetch account details error:", error);
      return errorResponseObject;
    }
  },

  fetchAccountTypeDetails: async (id) => {
    try {
      const res = await axios.get(
        `/finance/chartOfAccounts/accountTypes/${id}`
      );
      return res;
    } catch (error) {
      console.error("Fetch account type details error:", error);
      return errorResponseObject;
    }
  },

  addAccount: async (body) => {
    try {
      const res = await axios.post("/finance/chartOfAccounts/accounts", body);
      return res;
    } catch (error) {
      console.error("Add account error:", error);
      return errorResponseObject;
    }
  },

  addAccountType: async (body) => {
    try {
      const res = await axios.post(
        "/finance/chartOfAccounts/accountTypes",
        body
      );
      return res;
    } catch (error) {
      console.error("Add account type error:", error);
      return errorResponseObject;
    }
  },

  editAccount: async (body) => {
    try {
      const res = await axios.put(
        `/finance/chartOfAccounts/accounts/${body.id}`,
        body
      );
      return res;
    } catch (error) {
      console.error("Edit account error:", error);
      return errorResponseObject;
    }
  },

  editAccountType: async (body) => {
    try {
      const res = await axios.put(
        `/finance/chartOfAccounts/accountTypes/${body.id}`,
        body
      );
      return res;
    } catch (error) {
      console.error("Edit account type error:", error);
      return errorResponseObject;
    }
  },

  deleteAccount: async (id, name) => {
    try {
      const res = await axios.delete(
        `/finance/chartOfAccounts/accounts/${name}/${id}`
      );
      return res;
    } catch (error) {
      console.error("Delete account error:", error);
      return errorResponseObject;
    }
  },

  deleteAccountType: async (id, name) => {
    try {
      const res = await axios.delete(
        `/finance/chartOfAccounts/accountTypes/${name}/${id}`
      );
      return res;
    } catch (error) {
      console.error("Delete account type error:", error);
      return errorResponseObject;
    }
  },

  checkAccount: async (id) => {
    try {
      const res = await axios.patch(
        `/finance/chartOfAccounts/accounts/check/${id}`,
        {
          checkedAt: new Date(),
        }
      );
      return res;
    } catch (error) {
      console.error("Check account error:", error);
      return errorResponseObject;
    }
  },

  checkAccountType: async (id) => {
    try {
      const res = await axios.patch(
        `/finance/chartOfAccounts/accountTypes/check/${id}`,
        {
          checkedAt: new Date(),
        }
      );
      return res;
    } catch (error) {
      console.error("Check account type error:", error);
      return errorResponseObject;
    }
  },

  approveAccount: async (id) => {
    try {
      const res = await axios.patch(
        `/finance/chartOfAccounts/accounts/approve/${id}`,
        {
          approvedAt: new Date(),
        }
      );
      return res;
    } catch (error) {
      console.error("Approve account error:", error);
      return errorResponseObject;
    }
  },

  approveAccountType: async (id) => {
    try {
      const res = await axios.patch(
        `/finance/chartOfAccounts/accountTypes/approve/${id}`,
        {
          approvedAt: new Date(),
        }
      );
      return res;
    } catch (error) {
      console.error("Approve account type error:", error);
      return errorResponseObject;
    }
  },
};

export default chartOfAccountApiRequests;
