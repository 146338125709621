import { Alert, Button, message, Modal, Space, Spin, Tag } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { numberWithCommas } from "../../../../config/helpers/app/numberFormater";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import appConfig from "../../../../config/config.json";
import { rejectionsActions } from "../../../../config/actions/rejections/rejections.actions";
import { AiFillIeCircle } from "react-icons/ai";

const RrfqDetails = ({
  visible,
  handleCloseModal,
  handleApprove,
  handleCheck,
  handleRejection,
}) => {
  const closeModal = () => handleCloseModal(false);
  const {
    rfqsDetails: data,
    rfqsDetailsLoading,
    rfqsDetailsSuccess,
    rfqsDetailsError,
    approveRfqsLoading,
    approveRfqsError,
    approveRfqsSuccess,
    approveRfqsMessage,
    checkRfqsLoading,
    checkRfqsSuccess,
    checkRfqsMessage,
    checkRfqsError,
  } = useSelector((state) => state.rfqsState);
  const dispatch = useDispatch();
  const currency =
    data?.projectDetails?.approved_budget[
      data?.projectDetails?.approved_budget.length - 1
    ];
  const checkRecord = () => handleCheck(data.id);
  const approveRecord = () => handleApprove(data.id);
  const reject = () => {
    handleRejection(true);
    dispatch(
      rejectionsActions.addProcurementRejectionsData({
        user_to_id: data?.created_by,
        item_application: "Request For Quotation",
        item_id: data?.id,
        itemName: `Request For Quotation ${data.rfqno}`,
        applicationPath: "rfqs",
      })
    );
  };
  //use effect
  useEffect(() => {
    if (approveRfqsSuccess && approveRfqsMessage)
      message.success(approveRfqsMessage);
    else if (!approveRfqsSuccess && approveRfqsError)
      message.error(approveRfqsError);
    if (checkRfqsSuccess && checkRfqsMessage) message.success(checkRfqsMessage);
    else if (!checkRfqsSuccess && checkRfqsError) message.error(checkRfqsError);
  }, [
    approveRfqsError,
    approveRfqsMessage,
    approveRfqsSuccess,
    checkRfqsError,
    checkRfqsMessage,
    checkRfqsSuccess,
  ]);
  return (
    <div>
      <Modal
        footer={[
          data.status !== appConfig.status.rejected &&
            data.status !== appConfig.status.approved &&
            rfqsDetailsSuccess && (
              <Space>
                <Button
                  onClick={approveRecord}
                  loading={approveRfqsLoading}
                  type="primary"
                >
                  Approve
                </Button>
                {data.status !== appConfig.status.checked && (
                  <Button onClick={checkRecord} loading={checkRfqsLoading}>
                    Check
                  </Button>
                )}
                <Button onClick={reject} danger>
                  Reject
                </Button>
              </Space>
            ),
        ]}
        open={visible}
        title="RFQ DETAILS"
        onCancel={closeModal}
      >
        {rfqsDetailsLoading ? (
          <div className="d-flex m-5 justify-content-center">
            <Spin size="large" />
          </div>
        ) : (
          <div>
            {!rfqsDetailsSuccess ? (
              <Alert type="error" message={rfqsDetailsError} showIcon />
            ) : (
              <div className="details_div">
                <table
                  id="paymentVoucherDetails"
                  className="table table-striped table-hover"
                >
                  <thead>
                    <tr>
                      <th scope="col">Column</th>
                      <th scope="col">Data</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Rfq No</td>
                      <td>{data.rfqno}</td>
                    </tr>
                    <tr>
                      <td>Deadline</td>
                      <td>
                        {new Date(data.deadline_of_submission).toDateString()}
                      </td>
                    </tr>
                    <tr>
                      <td>Subject Of Procurement</td>
                      <td>{data.subject}</td>
                    </tr>
                    <tr>
                      <td>Source Of Funds</td>
                      <td>{data.projectDetails?.donor_name}</td>
                    </tr>
                    <tr>
                      <td>Project</td>
                      <td>{data.projectDetails?.project_name}</td>
                    </tr>
                    <tr>
                      <td>Requisition Form Number</td>
                      <td>{data.requisitionMemoDetails?.memo_number}</td>
                    </tr>
                    <tr>
                      <td>Items For Procurement</td>
                      <td></td>
                    </tr>
                    {data.requisitionMemoDetails?.activities?.map(
                      (activity) => (
                        <tr key={activity.id}>
                          <td>{activity.name}</td>
                          <td>{`${
                            currency === "D" ? "USD" : "UGX"
                          } ${numberWithCommas(activity.total_cost)}`}</td>
                        </tr>
                      )
                    )}
                    <tr>
                      <td className="text-warning">Sub Total</td>
                      <td className="text-warning">
                        {`${
                          currency === "D" ? "USD" : "UGX"
                        } ${numberWithCommas(
                          data.requisitionMemoDetails
                            ?.requisitionMemoActivitiesTotal
                        )}`}
                      </td>
                    </tr>
                    <tr>
                      <td>Status</td>
                      <td>
                        {data.status === appConfig.status.approved && (
                          <Tag icon={<CheckCircleOutlined />} color="success">
                            {data.status}
                          </Tag>
                        )}
                        {data.status === appConfig.status.checked && (
                          <Tag
                            icon={<MinusCircleOutlined />}
                            color="processing"
                          >
                            {data.status}
                          </Tag>
                        )}
                        {data.status === appConfig.status.created && (
                          <Tag
                            icon={<ExclamationCircleOutlined />}
                            color="warning"
                          >
                            Pending
                          </Tag>
                        )}
                        {data.status === appConfig.status.pending && (
                          <Tag
                            icon={<ExclamationCircleOutlined />}
                            color="warning"
                          >
                            Pending
                          </Tag>
                        )}
                        {data.status === appConfig.status.rejected && (
                          <Tag icon={<AiFillIeCircle />} color="error">
                            Rejected
                          </Tag>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Payee</td>
                      <td>{`${data?.creator?.first_name} ${data?.creator?.other_names}`}</td>
                    </tr>
                    <tr>
                      <td>Created By</td>
                      <td>{`${data?.payee?.first_name} ${data?.payee?.other_names}`}</td>
                    </tr>
                    <tr>
                      <td>Created At</td>
                      <td>
                        {`${new Date(
                          data.created_at
                        ).toDateString()} ${new Date(
                          data.created_at
                        ).toLocaleTimeString()}
                                   `}
                      </td>
                    </tr>
                    <tr>
                      <td>Last Updated By</td>
                      <td>
                        {data.updator &&
                          `${data?.updator?.first_name} ${data?.updator?.other_names}`}
                      </td>
                    </tr>
                    <tr>
                      <td>Last Updated At</td>
                      <td>
                        {data.updated_at &&
                          `${new Date(
                            data.updated_at
                          ).toDateString()} ${new Date(
                            data.updated_at
                          ).toLocaleTimeString()}`}
                      </td>
                    </tr>
                    <tr>
                      <td>Checked By</td>
                      <td>
                        {data.checker &&
                          `${data?.checker?.first_name} ${data?.checker?.other_names}`}
                      </td>
                    </tr>
                    <tr>
                      <td>Checked At</td>
                      <td>
                        {data.checked_at &&
                          `${new Date(
                            data.checked_at
                          ).toDateString()} ${new Date(
                            data.checked_at
                          ).toLocaleTimeString()}
                                   `}
                      </td>
                    </tr>
                    <tr>
                      <td>Approved By</td>
                      <td>
                        {data.approver &&
                          `${data?.approver?.first_name} ${data?.approver?.other_names}`}
                      </td>
                    </tr>
                    <tr>
                      <td>Approved At</td>
                      <td>
                        {data.approved_at &&
                          `${new Date(
                            data.approved_at
                          ).toDateString()} ${new Date(
                            data.approved_at
                          ).toLocaleTimeString()}`}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}
      </Modal>
    </div>
  );
};

export default RrfqDetails;
