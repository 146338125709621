import { projectsConstants } from "../../../actions/businesDevAndPartnerships/projects/projects.actions";
import initialState from "../../../initialState/businessDevAndPartnerships/Projects/projects";

const projectsState = (state = initialState, { type, payload }) => {
  switch (type) {
    case projectsConstants.SET_PROJECTS_REQUEST:
      return {
        ...state,
        projectsLoading: true,
      };
    case projectsConstants.SET_PROJECTS_SUCCESS:
      return {
        ...state,
        projectsLoading: false,
        projects: payload,
        projectsSuccess: true,
      };
    case projectsConstants.SET_PROJECTS_ERROR:
      return {
        ...state,
        projectsSuccess: false,
        projectsError: payload,
        projectsLoading: false,
      };
    case projectsConstants.SET_PROJECTS_GRAPH_DATA_REQUEST:
      return {
        ...state,
        projectsGraphDataLoading: true,
      };
    case projectsConstants.SET_PROJECTS_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        projectsGraphDataLoading: false,
        projectsGraphData: payload,
        projectsGraphDataSuccess: true,
      };
    case projectsConstants.SET_PROJECTS_GRAPH_DATA_ERROR:
      return {
        ...state,
        projectsGraphDataSuccess: false,
        projectsGraphDataError: payload,
        projectsGraphDataLoading: false,
      };
    case projectsConstants.SET_MY_PROJECTS_REQUEST:
      return {
        ...state,
        myProjectsLoading: true,
      };
    case projectsConstants.SET_MY_PROJECTS_SUCCESS:
      return {
        ...state,
        myProjectsLoading: false,
        myProjects: payload,
        myProjectsSuccess: true,
      };
    case projectsConstants.SET_MY_PROJECTS_ERROR:
      return {
        ...state,
        myProjectsSuccess: false,
        myProjectsError: payload,
        myProjectsLoading: false,
      };
    case projectsConstants.SET_PROJECTS_DETAILS_REQUEST:
      return {
        ...state,
        projectsDetailsLoading: true,
      };
    case projectsConstants.SET_PROJECTS_DETAILS_SUCCESS:
      return {
        ...state,
        projectsDetailsSuccess: true,
        projectsDetails: payload,
        projectsDetailsError: "",
        projectsDetailsLoading: false,
      };
    case projectsConstants.SET_PROJECTS_DETAILS_ERROR:
      return {
        ...state,
        projectsDetailsSuccess: false,
        projectsDetailsError: payload,
        projectsDetailsLoading: false,
      };
    case projectsConstants.ADD_PROJECT_REQUEST:
      return {
        ...state,
        addProjectLoading: true,
      };
    case projectsConstants.ADD_PROJECT_SUCCESS:
      return {
        ...state,
        addProjectLoading: false,
        addProjectsSuccess: true,
        addProjectsError: "",
        addProjectsMessage: payload.message,
        projects: payload.data,
      };
    case projectsConstants.ADD_PROJECT_ERROR:
      return {
        ...state,
        addProjectLoading: false,
        addProjectsSuccess: false,
        addProjectsMessage: "",
        addProjectsError: payload,
      };
    case projectsConstants.ADD_PROJECT_COMPLETE:
      return {
        ...state,
        addProjectsSuccess: false,
      };
    case projectsConstants.EDIT_PROJECTS_DATA:
      return {
        ...state,
        editProjectData: payload,
      };
    case projectsConstants.EDIT_PROJECTS_REQUEST:
      return {
        ...state,
        editProjectLoading: true,
      };
    case projectsConstants.EDIT_PROJECTS_SUCCESS:
      return {
        ...state,
        editProjectSuccess: true,
        editProjectLoading: false,
        editProjectMessage: payload.message,
        editProjectError: "",
        projects: payload.data,
      };
    case projectsConstants.EDIT_PROJECTS_ERROR:
      return {
        ...state,
        editProjectSuccess: false,
        editProjectLoading: false,
        editProjectMessage: "",
        editProjectError: payload,
      };
    case projectsConstants.EDIT_PROJECTS_COMPLETE:
      return {
        ...state,
        editProjectSuccess: false,
      };
    case projectsConstants.DELETE_PROJECTS_REQUEST:
      return {
        ...state,
        deleteProjectsLoading: true,
      };
    case projectsConstants.DELETE_PROJECTS_SUCCESS:
      return {
        ...state,
        deleteProjectsLoading: false,
        deleteProjectsSuccess: true,
        deleteProjectsMessage: payload.message,
        projects: payload.data,
      };
    case projectsConstants.DELETE_PROJECTS_ERROR:
      return {
        ...state,
        deleteProjectsLoading: false,
        deleteProjectsSuccess: false,
        deleteProjectError: payload,
      };
    case projectsConstants.DELETE_PROJECTS_COMPLETE:
      return {
        ...state,
        deleteProjectsSuccess: false,
        deleteProjectError: "",
      };
    case projectsConstants.CHECK_PROJECTS_REQUEST:
      return {
        ...state,
        checkProjectsLoading: true,
      };
    case projectsConstants.CHECK_PROJECTS_SUCCESS:
      return {
        ...state,
        checkProjectsLoading: false,
        checkProjectsSuccess: true,
        checkProjectMessage: payload,
      };
    case projectsConstants.CHECK_PROJECTS_ERROR:
      return {
        ...state,
        checkProjectsError: payload,
        checkProjectsLoading: false,
        checkProjectsSuccess: false,
      };
    case projectsConstants.CHECK_PROJECTS_COMPLETE:
      return {
        ...state,
        checkProjectsError: "",
        checkProjectsSuccess: false,
      };
    case projectsConstants.APPROVE_PROJECTS_REQUEST:
      return {
        ...state,
        approveProjectsLoading: true,
      };
    case projectsConstants.APPROVE_PROJECTS_SUCCESS:
      return {
        ...state,
        approveProjectsSuccess: true,
        approveProjectsLoading: false,
        approveProjectsMessage: payload,
      };
    case projectsConstants.APPROVE_PROJECTS_ERROR:
      return {
        ...state,
        approveProjectsError: payload,
        approveProjectsLoading: false,
        approveProjectsSuccess: false,
      };
    case projectsConstants.APPROVE_PROJECTS_COMPLETE:
      return {
        ...state,
        approveProjectsSuccess: false,
        approveProjectsError: "",
      };
    case projectsConstants.SET_PROJECTS_META_DATA_REQUEST:
      return {
        ...state,
        projectsMetaDataLoading: true,
      };
    case projectsConstants.SET_PROJECTS_META_DATA:
      return {
        ...state,
        projectsMetaDataLoading: false,
        projectsMetaData: payload,
      };
    default:
      return state;
  }
};

export default projectsState;
