import { Tag } from "antd";
import React from "react";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { undefinedRecordNames } from "../../../../config/helpers/app/undefinedRecords";

const OtherDetails = ({ data }) => {
  return (
    <div className="mt-2 px-1 w-50 w-sm-100">
      <h3>Other Details</h3>
      <table className="table table-striped bg-white table-hover">
        <thead>
          <tr>
            <th scope="col">Column</th>
            <th scope="col">Data</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Supervised by</td>
            <td>{data.supervised_by}</td>
          </tr>
          <tr>
            <td>Account Status</td>
            <td>
              {data.account_status === "active" && (
                <Tag icon={<CheckCircleOutlined />} color="success">
                  Active
                </Tag>
              )}
              {data.account_status === "inactive" && (
                <Tag icon={<ExclamationCircleOutlined />} color="red">
                  Inactive
                </Tag>
              )}
            </td>
          </tr>
          <tr>
            <td>Account Confirmed</td>
            <td>{data.account_confirmed ? <>Yes</> : <>No</>}</td>
          </tr>
          <tr>
            <td>Has Signature</td>
            <td>{data.signature ? <>Yes</> : <>No</>}</td>
          </tr>
          <tr>
            <td>Created By</td>
            <td>{`${undefinedRecordNames(
              data,
              "creator",
              "first_name"
            )} ${undefinedRecordNames(data, "creator", "other_names")}`}</td>
          </tr>
          <tr>
            <td>Created At</td>
            <td>
              {`${new Date(data.created_at).toDateString()} ${new Date(
                data.created_at
              ).toLocaleTimeString()}
              `}
            </td>
          </tr>
          <tr>
            <td>Last Updated By</td>
            <td>
              {`${undefinedRecordNames(
                data,
                "updator",
                "first_name"
              )} ${undefinedRecordNames(data, "updator", "other_names")}`}
            </td>
          </tr>
          <tr>
            <td>Last Updated At</td>
            <td>
              {data.updated_at &&
                `${new Date(data.updated_at).toDateString()} ${new Date(
                  data.updated_at
                ).toLocaleTimeString()}`}
            </td>
          </tr>
          <tr>
            <td>Last Activated By</td>
            <td>{`${undefinedRecordNames(
              data,
              "activator",
              "first_name"
            )} ${undefinedRecordNames(data, "activator", "other_names")}`}</td>
          </tr>
          <tr>
            <td>Last Activated At</td>
            <td>
              {data.time_activated &&
                `${new Date(data.time_activated).toDateString()} ${new Date(
                  data.time_activated
                ).toLocaleTimeString()}
              `}
            </td>
          </tr>
          <tr>
            <td>Last Deactivated By</td>
            <td>{`${undefinedRecordNames(
              data,
              "deactivator",
              "first_name"
            )} ${undefinedRecordNames(
              data,
              "deactivator",
              "other_names"
            )}`}</td>
          </tr>
          <tr>
            <td>Last Deactivated At</td>
            <td>
              {data.time_deactivated &&
                `${new Date(data.time_deactivated).toDateString()} ${new Date(
                  data.time_deactivated
                ).toLocaleTimeString()}
              `}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default OtherDetails;
