import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddRfq from "../../../components/Procurement/rfqs/Forms/AddRfq";
import EditRfq from "../../../components/Procurement/rfqs/Forms/EditRfq";
import RfqsTable from "../../../components/Procurement/rfqs/Tables/RfqsTable";
import routes from "../../../config/routes/routes";
import rfqThunks from "../../../config/thunks/procurement/rfqs/rfqs.thunks";
import RrfqDetails from "../../../components/Procurement/rfqs/Details/RrfqDetails";
import logDetailThunks from "../../../config/thunks/settings/logDetails.thunks";
import AddProcurementRejections from "../../../components/shared/Rejections/procuremnet/AddProcurementRejections";

const Rfq = () => {
  /* state initialization variables  */
  const dispatch = useDispatch();
  const { rfqsSuccess } = useSelector((state) => state.rfqsState);
  /* state for ui */
  const [addRfqModalVisible, setAddRfqModalVisible] = useState(false);
  const [addRejectionModalVisible, setAddRejectionModalVisible] =
    useState(false);
  const [editRfqModalVisible, setEditRfqModalVisible] = useState(false);
  const [rfqDetailsModalVisible, setRfqDetailsModalVisible] = useState(false);
  /* thunk funxtions */
  const refreshData = () => {
    dispatch(rfqThunks.getRfqs());
    dispatch(rfqThunks.rfqsMetaData());
  };
  const addRfq = (values, rfqs) => dispatch(rfqThunks.addRfqs(values, rfqs));
  const editRfq = (values, rfqs) => dispatch(rfqThunks.editRfqs(values, rfqs));
  const deleteRfq = (id, rfqs) => dispatch(rfqThunks.deleteRfqs(id, rfqs));
  const approveRfq = (id) => dispatch(rfqThunks.approveRfqs(id));
  const checkRfq = (id) => dispatch(rfqThunks.checkRfqs(id));
  const getRfqDetails = (id) => {
    dispatch(rfqThunks.getRfqsDetails(id));
    setRfqDetailsModalVisible(true);
  };
  const exportRecords = () => {
    dispatch(
      logDetailThunks.addLogDetails({
        created_at: new Date(),
        activity: `Exported  Request For Qoutation`,
        records: "rfqs",
      })
    );
  };
  /* useEffect */
  useEffect(() => {
    document.title = routes.procurement.rfq.title;
    if (!rfqsSuccess) {
      dispatch(rfqThunks.getRfqs());
      dispatch(rfqThunks.rfqsMetaData());
    }
  }, [rfqsSuccess, dispatch]);
  return (
    <div className="container-fluid p-2">
      <h5 className="fw-bold text-uppercase">Request For Quotation</h5>
      <RfqsTable
        handleExportRecords={exportRecords}
        handleViewDetails={getRfqDetails}
        handleOpenEditModal={setEditRfqModalVisible}
        handleDelete={deleteRfq}
        handleOpenAddModal={setAddRfqModalVisible}
        handleRefreshTable={refreshData}
      />
      <AddRfq
        handleAddRecord={addRfq}
        visible={addRfqModalVisible}
        handleCloseModal={setAddRfqModalVisible}
      />
      <EditRfq
        handleEditRecord={editRfq}
        visible={editRfqModalVisible}
        handleCloseModal={setEditRfqModalVisible}
      />
      <RrfqDetails
        handleRejection={setAddRejectionModalVisible}
        handleApprove={approveRfq}
        handleCheck={checkRfq}
        visible={rfqDetailsModalVisible}
        handleCloseModal={setRfqDetailsModalVisible}
      />
      <AddProcurementRejections
        visible={addRejectionModalVisible}
        handleCloseModal={setAddRejectionModalVisible}
      />
    </div>
  );
};

export default Rfq;
