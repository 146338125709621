const initialState = {
  projects: [],
  projectsLoading: false,
  projectsError: "",
  projectsSuccess: false,

  projectsGraphData: {},
  projectsGraphDataLoading: false,
  projectsGraphDataError: "",
  projectsGraphDataSuccess: false,

  addProjectLoading: false,
  addProjectsMessage: "",
  addProjectsSuccess: false,
  addProjectsError: "",

  projectsDetails: {},
  projectsDetailsSuccess: false,
  projectsDetailsLoading: false,
  projectsDetailsError: "",

  editProjectSuccess: false,
  editProjectLoading: false,
  editProjectData: {},
  editProjectError: "",
  editProjectMessage: "",

  deleteProjectsLoading: false,
  deleteProjectsSuccess: false,
  deleteProjectsMessage: "",
  deleteProjectError: "",

  approveProjectsLoading: false,
  approveProjectsError: "",
  approveProjectsSuccess: false,
  approveProjectsMessage: "",

  checkProjectsLoading: false,
  checkProjectsSuccess: false,
  checkProjectMessage: "",
  checkProjectsError: "",

  projectsMetaDataLoading: false,
  projectsMetaData: {},

  myProjects: [],
  myProjectsLoading: false,
  myProjectsSuccess: false,
  myProjectsError: "",
};

export default initialState;
