export const rejectionsConstants = {
  ADD_REJECTIONS_REQUEST: "ADD_REJECTIONS_REQUEST",
  ADD_REJECTIONS_ERROR: "ADD_REJECTIONS_ERROR",
  ADD_REJECTIONS_SUCCESS: "ADD_REJECTIONS_SUCCESS",
  ADD_REJECTIONS_COMPLETE: "ADD_REJECTIONS_COMPLETE",

  ADD_PROCUREMENT_REJECTIONS_REQUEST: "ADD_PROCUREMENT_REJECTIONS_REQUEST",
  ADD_PROCUREMENT_REJECTIONS_ERROR: "ADD_PROCUREMENT_REJECTIONS_ERROR",
  ADD_PROCUREMENT_REJECTIONS_SUCCESS: "ADD_PROCUREMENT_REJECTIONS_SUCCESS",
  ADD_PROCUREMENT_REJECTIONS_COMPLETE: "ADD_PROCUREMENT_REJECTIONS_COMPLETE",
  ADD_PROCUREMENT_REJECTIONS_DATA: "ADD_PROCUREMENT_REJECTIONS_DATA",

  TOGGLE_ADD_REJECTIONS_MODAL: "TOGGLE_ADD_REJECTIONS_MODAL",
};

export const rejectionsActions = {
  addRejectionRequest: () => ({
    type: rejectionsConstants.ADD_REJECTIONS_REQUEST,
  }),
  addRejectionSuccess: (payload) => ({
    type: rejectionsConstants.ADD_REJECTIONS_SUCCESS,
    payload,
  }),
  addRejectionsError: (payload) => ({
    type: rejectionsConstants.ADD_REJECTIONS_ERROR,
    payload,
  }),
  addRejectionsComplete: () => ({
    type: rejectionsConstants.ADD_REJECTIONS_COMPLETE,
  }),
  addProcurementRejectionsRequest: () => ({
    type: rejectionsConstants.ADD_PROCUREMENT_REJECTIONS_REQUEST,
  }),
  addProcurementRejectionsSuccess: (payload) => ({
    type: rejectionsConstants.ADD_PROCUREMENT_REJECTIONS_SUCCESS,
    payload,
  }),
  addProcurementRejectionsError: (payload) => ({
    type: rejectionsConstants.ADD_PROCUREMENT_REJECTIONS_ERROR,
    payload,
  }),
  addProcurementRejectionsComplete: () => ({
    type: rejectionsConstants.ADD_PROCUREMENT_REJECTIONS_COMPLETE,
  }),
  addProcurementRejectionsData: (payload) => ({
    type: rejectionsConstants.ADD_PROCUREMENT_REJECTIONS_DATA,
    payload,
  }),
  toggleRejectionsModal: (payload) => ({
    type: rejectionsConstants.TOGGLE_ADD_REJECTIONS_MODAL,
    payload,
  }),
};
