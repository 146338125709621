import { Button, Input, Popover, Space } from "antd";
import React from "react";
import { AiOutlinePlus, AiOutlineSearch } from "react-icons/ai";
import { FcExport, FcRefresh } from "react-icons/fc";
import { useDispatch } from "react-redux";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";

const AssetsTypesTableTitle = ({
  search,
  setfilterTableNull,
  refreshTable,
  exportRecords,
}) => {
  const dispatch = useDispatch();
  const openAddAssetTypesModal = () => {
    dispatch(appUiActions.toggleAddAssetTypesModal(true));
  };
  const options = (
    <Space direction="vertical">
      <Button onClick={exportRecords} className="w-100 bg-light">
        <Space>
          <FcExport />
          Export
        </Space>
      </Button>
      <Button className="w-100" onClick={refreshTable}>
        <Space>
          <FcRefresh /> Refresh
        </Space>
      </Button>
      <Popover
        content={() => (
          <Input.Search
            onChange={setfilterTableNull}
            placeholder="Search by..."
            enterButton
            onSearch={search}
          ></Input.Search>
        )}
        trigger="click"
      >
        <Button className="w-100 d-md-none">
          <Space>
            <AiOutlineSearch /> Search
          </Space>
        </Button>
      </Popover>
    </Space>
  );
  return (
    <div className="d-flex w-100">
      <Space className="w-50" direction="horizontal">
        <Button onClick={openAddAssetTypesModal} type="primary">
          <Space>
            <AiOutlinePlus /> Add New
          </Space>
        </Button>
        <Popover content={options} placement="bottom" trigger="click">
          <Button>Options</Button>
        </Popover>
      </Space>
      <div className="w-50 d-none d-md-block">
        <Input.Search
          onChange={setfilterTableNull}
          placeholder="Search by..."
          enterButton
          onSearch={search}
        ></Input.Search>
      </div>
    </div>
  );
};

export default AssetsTypesTableTitle;
