import {
  Alert,
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";

const { Option } = Select;

const AddAccount = ({ handleAddRecord }) => {
  const {
    addAccountSuccess,
    addAccountError,
    addAccountMessage,
    addAccountLoading: loading,
    metaData,
    metaDataLoading,
  } = useSelector((state) => state.chartOfAccountsState);
  const [form] = Form.useForm();
  const { addCOAAccountModal: visible } = useSelector((state) => state.AppUi);
  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch(appUiActions.toggleAddChartOfAccountsAccountModal(false));
  };
  const onFinish = (values) => {
    values.created_at = new Date();
    handleAddRecord(values);
  };
  useEffect(() => {
    if (addAccountSuccess && addAccountMessage) {
      message.success(addAccountMessage);
      form.resetFields();
    }
  }, [addAccountSuccess, addAccountMessage, form]);
  return (
    <div>
      <Modal
        onCancel={closeModal}
        footer={null}
        open={visible}
        title="ADD GENERAL ACCOUNT"
      >
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          validateTrigger="onBlur"
          layout="horizontal"
          form={form}
          onFinish={onFinish}
        >
          {!addAccountSuccess && addAccountError && (
            <Alert
              className="m-2"
              showIcon
              type="error"
              message={addAccountError}
            />
          )}
          <Form.Item
            name="name"
            label="Account Name"
            rules={[{ required: true, message: "Please add an account name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="account_number"
            label="Code"
            rules={[
              { required: true, message: "Please add an account number" },
            ]}
          >
            <InputNumber precision={0} className="w-100" />
          </Form.Item>
          <Form.Item
            name="account_type"
            label="Account Type"
            rules={[
              { required: true, message: "Please select an account type" },
            ]}
          >
            <Select
              defaultValue=""
              showSearch
              allowClear
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              loading={metaDataLoading}
            >
              {metaData?.accountTypes?.map((accountType) => {
                return (
                  <Option key={accountType.id} value={accountType.id}>
                    {accountType.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item label="Is sub account" name="is_subaccount">
            <Select allowClear>
              <Option key="yes" value={true}>
                Yes
              </Option>
              <Option key="no" value={false}>
                No
              </Option>
            </Select>
          </Form.Item>
          <Form.Item name="parent_account" label="Parent Account">
            <Select
              allowClear
              defaultValue=""
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              loading={metaDataLoading}
            >
              {metaData?.accounts?.map((accountType) => {
                return (
                  <Option key={accountType.id} value={accountType.id}>
                    {accountType.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item name="details" label="Details">
            <TextArea />
          </Form.Item>
          <Form.Item className={"mt-3"} wrapperCol={{ offset: 8, span: 16 }}>
            <Button loading={loading} htmlType="submit" type="primary">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddAccount;
