const intitialState = {
  permissions: {
    loading: false,
    success: false,
    data: [],
    error: "",
  },
  addPermission: {
    loading: false,
    message: "",
    success: false,
    error: "",
  },
  deletePermission: {
    loading: false,
    message: "",
    success: false,
    error: "",
  },
  updatePermission: {
    loading: false,
    message: "",
    success: false,
    error: "",
    data: "",
  },
  permission: {
    loading: false,
    message: "",
    success: "",
    data: {},
    error: "",
  },
  roles: {
    loading: false,
    error: "",
    data: [],
    success: false,
  },
};

export default intitialState;
