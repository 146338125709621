export const storesRegisterConstants = {
  SET_STORES_REGISTER_REQUEST: "SET_STORES_REGISTER_REQUEST",
  SET_STORES_REGISTER_ERROR: "SET_STORES_REGISTER_ERROR",
  SET_STORES_REGISTER_SUCCESS: "SET_STORES_REGISTER_SUCCESS",

  SET_STORES_REGISTER_RECEIPT_DETAILS_REQUEST:
    "SET_STORES_REGISTER_RECEIPT_DETAILS_REQUEST",
  SET_STORES_REGISTER_RECEIPT_DETAILS_SUCCESS:
    "SET_STORES_REGISTER_RECEIPT_DETAILS_SUCCESS",
  SET_STORES_REGISTER_RECEIPT_DETAILS_ERROR:
    "SET_STORES_REGISTER_RECEIPT_DETAILS_ERRROR",

  SET_STORES_REGISTER_ISSUES_DETAILS_REQUEST:
    "SET_STORES_REGISTER_ISSUES_DETAILS_REQUEST",
  SET_STORES_REGISTER_ISSUES_DETAILS_SUCCESS:
    "SET_STORES_REGISTER_ISSUES_DETAILS_SUCCESS",
  SET_STORES_REGISTER_ISSUES_DETAILS_ERROR:
    "SET_STORES_REGISTER_ISSUES_DETAILS_ERRROR",

  ADD_STORES_REGISTER_RECEIPT_REQUEST: "ADD_STORES_REGISTER_RECEIPT_REQUEST",
  ADD_STORES_REGISTER_RECEIPT_SUCCESS: "ADD_STORES_REGISTER_RECEIPT_SUCCESS",
  ADD_STORES_REGISTER_RECEIPT_ERROR: "ADD_STORES_REGISTER_RECEIPT_ERROR",
  ADD_STORES_REGISTER_RECEIPT_COMPLETED:
    "ADD_STORES_REGISTER_RECEIPT_COMPLETED",

  ADD_STORES_REGISTER_ISSUE_REQUEST: "ADD_STORES_REGISTER_ISSUE_REQUEST",
  ADD_STORES_REGISTER_ISSUE_SUCCESS: "ADD_STORES_REGISTER_ISSUE_SUCCESS",
  ADD_STORES_REGISTER_ISSUE_ERROR: "ADD_STORES_REGISTER_ISSUE_ERROR",
  ADD_STORES_REGISTER_ISSUE_COMPLETED: "ADD_STORES_REGISTER_ISSUE_COMPLETED",

  DELETE_STORES_REGISTER_RECEIPT_REQUEST:
    "DELETE_STORES_REGISTER_RECEIPT_REQUEST",
  DELETE_STORES_REGISTER_RECEIPT_SUCCESS:
    "DELETE_STORES_REGISTER_RECEIPT_SUCCESS",
  DELETE_STORES_REGISTER_RECEIPT_ERROR: "DELETE_STORES_REGISTER_RECEIPT_ERROR",
  DELETE_STORES_REGISTER_RECEIPT_COMPLETED:
    "DELETE_STORES_REGISTER_RECEIPT_COMPLETED",

  DELETE_STORES_REGISTER_ISSUE_REQUEST: "DELETE_STORES_REGISTER_ISSUE_REQUEST",
  DELETE_STORES_REGISTER_ISSUE_SUCCESS: "DELETE_STORES_REGISTER_ISSSUE_SUCCESS",
  DELETE_STORES_REGISTER_ISSUE_ERROR: "DELETE_STORES_REGISTER_ISSUE_ERROR",
  DELETE_STORES_REGISTER_ISSUE_COMPLETED:
    "DELETE_STORES_REGISTER_ISSUE_COMPLETED",

  UPDATE_STORES_REGISTER_ISSUE_REQUEST: "UPDATE_STORES_REGISTER_ISSUE_REQUEST",
  UPDATE_STORES_REGISTER_ISSUE_SUCCESS: "UPDATE_STORES_REGISTER_ISSUE_SUCCESS",
  UPDATE_STORES_REGISTER_ISSUE_ERROR: "UPDATE_STORES_REGISTER_ISSUE_ERROR",
  UPDATE_STORES_REGISTER_ISSUE_COMPLETED:
    "UPDATE_STORES_REGISTER_ISSUE_COMPLETED",
  SET_UPDATE_ISSUE_DATA: "SET_UPDATE_ISSUE_DATA",

  UPDATE_STORES_REGISTER_RECEIPT_REQUEST:
    "UPDATES_STORES_REGISTER_RECEIPT_REQUEST",
  UPDATE_STORES_REGISTER_RECEIPT_SUCCESS:
    "UPDATE_STORES_REGISTER_RECEIPT_SUCCESS",
  UPDATE_STORES_REGISTER_RECEIPT_ERROR: "UPDATES_STORES_REGISTER_RECEIPT_ERROR",
  UPDATE_STORES_REGISTER_RECEIPT_COMPLETED:
    "UPDATES_STORES_REGISTER_RECEIPT_COMPLETED",
  SET_UPDATE_RECEIPT_DATA: "SET_UPDATE_RECEIPT_DATA",

  APPROVE_STORES_REGISTER_RECEIPT_REQUEST:
    "APPROVE_STORES_REGISTER_RECEIPT_REQUEST",
  APPROVE_STORES_REGISTER_RECEIPT_SUCCESS:
    "APPROVE_STORES_REGISTER_RECEIPT_SUCCESS",
  APPROVE_STORES_REGISTER_RECEIPT_ERROR:
    "APPROVE_STORES_REGISTER_RECEIPT_ERROR",
  APPROVE_STORES_REGISTER_RECEIPT_COMPLETED:
    "APPROVE_STORES_REGISTER_RECEIPT_COMPLETED",

  APPROVE_STORES_REGISTER_ISSUES_REQUEST:
    "APPROVE_STORES_REGISTER_ISSUES_REQUEST",
  APPROVE_STORES_REGISTER_ISSUES_SUCCESS:
    "APPROVE_STORES_REGISTER_ISSUES_SUCCESS",
  APPROVE_STORES_REGISTER_ISSUES_ERROR: "APPROVE_STORES_REGISTER_ISSUES_ERROR",
  APPROVE_STORES_REGISTER_ISSUES_COMPLETED:
    "APPROVE_STORES_REGISTER_ISSUES_COMPLETED",

  CHECK_STORES_REGISTER_RECEIPT_REQUEST:
    "CHECK_STORES_REGISTER_RECEIPT_REQUEST",
  CHECK_STORES_REGISTER_RECEIPT_SUCCESS:
    "CHECK_STORES_REGISTER_RECEIPT_SUCCESS",
  CHECK_STORES_REGISTER_RECEIPT_ERROR: "CHECK_STORES_REGISTER_RECEIPT_ERROR",
  CHECK_STORES_REGISTER_RECEIPT_COMPLETED:
    "CHECK_STORES_REGISTER_RECEIPT_COMPLETED",

  CHECK_STORES_REGISTER_ISSUES_REQUEST: "CHECK_STORES_REGISTER_ISSUES_REQUEST",
  CHECK_STORES_REGISTER_ISSUES_SUCCESS: "CHECK_STORES_REGISTER_ISSUES_SUCCESS",
  CHECK_STORES_REGISTER_ISSUES_ERROR: "CHECK_STORES_REGISTER_ISSUES_ERROR",
  CHECK_STORES_REGISTER_ISSUES_COMPLETED:
    "CHECK_STORES_REGISTER_ISSUES_COMPLETED",
};

export const storesRegisterActions = {
  setStoresRegisterRequest: () => ({
    type: storesRegisterConstants.SET_STORES_REGISTER_REQUEST,
  }),
  setStoresRegisterError: (payload) => ({
    type: storesRegisterConstants.SET_STORES_REGISTER_ERROR,
    payload,
  }),
  setStoresRegisterSuccess: (payload) => ({
    type: storesRegisterConstants.SET_STORES_REGISTER_SUCCESS,
    payload,
  }),
  addStoresRegisterReceiptRequest: () => ({
    type: storesRegisterConstants.ADD_STORES_REGISTER_RECEIPT_REQUEST,
  }),
  addStoresRegisterReceiptError: (payload) => ({
    type: storesRegisterConstants.ADD_STORES_REGISTER_RECEIPT_ERROR,
    payload,
  }),
  addStoresRegisterReceiptSuccess: (payload) => ({
    type: storesRegisterConstants.ADD_STORES_REGISTER_RECEIPT_SUCCESS,
    payload,
  }),
  addStoresRegisterReceiptCompleted: () => ({
    type: storesRegisterConstants.ADD_STORES_REGISTER_RECEIPT_COMPLETED,
  }),
  addStoresRegisterIssueRequest: () => ({
    type: storesRegisterConstants.ADD_STORES_REGISTER_ISSUE_REQUEST,
  }),
  addStoresRegisterIssueError: (payload) => ({
    type: storesRegisterConstants.ADD_STORES_REGISTER_ISSUE_ERROR,
    payload,
  }),
  addStoresRegisterIssueSuccess: (payload) => ({
    type: storesRegisterConstants.ADD_STORES_REGISTER_ISSUE_SUCCESS,
    payload,
  }),
  addStoresRegisterIssueCompleted: () => ({
    type: storesRegisterConstants.ADD_STORES_REGISTER_ISSUE_COMPLETED,
  }),
  updateStoresRegisterReceiptRequest: () => ({
    type: storesRegisterConstants.UPDATE_STORES_REGISTER_RECEIPT_REQUEST,
  }),
  updateStoresRegisterReceiptSuccess: (payload) => ({
    type: storesRegisterConstants.UPDATE_STORES_REGISTER_RECEIPT_SUCCESS,
    payload,
  }),
  updateStoresRegisterReceiptError: (payload) => ({
    type: storesRegisterConstants.UPDATE_STORES_REGISTER_RECEIPT_ERROR,
    payload,
  }),
  updateStoresRegisterReceiptCompleted: () => ({
    type: storesRegisterConstants.UPDATE_STORES_REGISTER_RECEIPT_COMPLETED,
  }),
  setStoresRegisterReceiptUpdateData: (payload) => ({
    type: storesRegisterConstants.SET_UPDATE_RECEIPT_DATA,
    payload,
  }),
  updateStoresRegisterIssueRequest: () => ({
    type: storesRegisterConstants.UPDATE_STORES_REGISTER_ISSUE_REQUEST,
  }),
  updateStoresRegisterIssueSuccess: (payload) => ({
    type: storesRegisterConstants.UPDATE_STORES_REGISTER_ISSUE_SUCCESS,
    payload,
  }),
  updateStoresRegisterIssueError: (payload) => ({
    type: storesRegisterConstants.UPDATE_STORES_REGISTER_ISSUE_ERROR,
    payload,
  }),
  updateStoresRegisterIssueCompleted: () => ({
    type: storesRegisterConstants.UPDATE_STORES_REGISTER_ISSUE_COMPLETED,
  }),
  setStoresRegisterIssueUpdateData: (payload) => ({
    type: storesRegisterConstants.SET_UPDATE_ISSUE_DATA,
    payload,
  }),
  deleteStoresRegisterReceiptRequest: () => ({
    type: storesRegisterConstants.DELETE_STORES_REGISTER_RECEIPT_REQUEST,
  }),
  deleteStoresRegisterReceiptError: (payload) => ({
    type: storesRegisterConstants.DELETE_STORES_REGISTER_RECEIPT_ERROR,
    payload,
  }),
  deleteStoresRegisterReceiptSuccess: (payload) => ({
    type: storesRegisterConstants.DELETE_STORES_REGISTER_RECEIPT_SUCCESS,
    payload,
  }),
  deleteStoresRegisterReceiptCompleted: () => ({
    type: storesRegisterConstants.DELETE_STORES_REGISTER_RECEIPT_COMPLETED,
  }),
  deleteStoresRegisterIssueRequest: () => ({
    type: storesRegisterConstants.DELETE_STORES_REGISTER_ISSUE_REQUEST,
  }),
  deleteStoresRegisterIssueError: (payload) => ({
    type: storesRegisterConstants.DELETE_STORES_REGISTER_ISSUE_ERROR,
    payload,
  }),
  deleteStoresRegisterIssueSuccess: (payload) => ({
    type: storesRegisterConstants.DELETE_STORES_REGISTER_ISSUE_SUCCESS,
    payload,
  }),
  deleteStoresRegisterIssueCompleted: () => ({
    type: storesRegisterConstants.DELETE_STORES_REGISTER_ISSUE_COMPLETED,
  }),
  approveStoresRegisterReceiptRequest: () => ({
    type: storesRegisterConstants.APPROVE_STORES_REGISTER_RECEIPT_REQUEST,
  }),
  approveStoresRegisterReceiptSuccess: (payload) => ({
    type: storesRegisterConstants.APPROVE_STORES_REGISTER_RECEIPT_SUCCESS,
    payload,
  }),
  approveStoresRegisterReceiptError: (payload) => ({
    type: storesRegisterConstants.APPROVE_STORES_REGISTER_RECEIPT_ERROR,
    payload,
  }),
  approveStoresRegisterReceiptCompleted: () => ({
    type: storesRegisterConstants.APPROVE_STORES_REGISTER_RECEIPT_COMPLETED,
  }),
  approveStoresRegisterIssuesRequest: () => ({
    type: storesRegisterConstants.APPROVE_STORES_REGISTER_ISSUES_REQUEST,
  }),
  approveStoresRegisterIssuesSuccess: (payload) => ({
    type: storesRegisterConstants.APPROVE_STORES_REGISTER_ISSUES_SUCCESS,
    payload,
  }),
  approveStoresRegisterIssuesError: (payload) => ({
    type: storesRegisterConstants.APPROVE_STORES_REGISTER_ISSUES_ERROR,
    payload,
  }),
  approveStoresRegisterIssuesCompleted: () => ({
    type: storesRegisterConstants.APPROVE_STORES_REGISTER_ISSUES_COMPLETED,
  }),
  checkStoresRegisterReceiptRequest: () => ({
    type: storesRegisterConstants.CHECK_STORES_REGISTER_RECEIPT_REQUEST,
  }),
  checkStoresRegisterReceiptSuccess: (payload) => ({
    type: storesRegisterConstants.CHECK_STORES_REGISTER_RECEIPT_SUCCESS,
    payload,
  }),
  checkStoresRegisterReceiptError: (payload) => ({
    type: storesRegisterConstants.CHECK_STORES_REGISTER_RECEIPT_ERROR,
    payload,
  }),
  checkStoresRegisterReceiptCompleted: () => ({
    type: storesRegisterConstants.CHECK_STORES_REGISTER_RECEIPT_COMPLETED,
  }),
  checkStoresRegisterIssuesRequest: () => ({
    type: storesRegisterConstants.CHECK_STORES_REGISTER_ISSUES_REQUEST,
  }),
  checkStoresRegisterIssuesSuccess: (payload) => ({
    type: storesRegisterConstants.CHECK_STORES_REGISTER_ISSUES_SUCCESS,
    payload,
  }),
  checkStoresRegisterIssuesError: (payload) => ({
    type: storesRegisterConstants.CHECK_STORES_REGISTER_ISSUES_ERROR,
    payload,
  }),
  checkStoresRegisterIssuesCompleted: () => ({
    type: storesRegisterConstants.CHECK_STORES_REGISTER_ISSUES_COMPLETED,
  }),
  storesRegisterrReceiptDetailsRequest: () => ({
    type: storesRegisterConstants.SET_STORES_REGISTER_RECEIPT_DETAILS_REQUEST,
  }),
  storesRegisterrReceiptDetailsSuccess: (payload) => ({
    type: storesRegisterConstants.SET_STORES_REGISTER_RECEIPT_DETAILS_SUCCESS,
    payload,
  }),
  storesRegisterrReceiptDetailsError: (payload) => ({
    type: storesRegisterConstants.SET_STORES_REGISTER_RECEIPT_DETAILS_ERROR,
    payload,
  }),
  storesRegisterIssuesDetailsRequest: () => ({
    type: storesRegisterConstants.SET_STORES_REGISTER_ISSUES_DETAILS_REQUEST,
  }),
  storesRegisterIssuesDetailsSuccess: (payload) => ({
    type: storesRegisterConstants.SET_STORES_REGISTER_ISSUES_DETAILS_SUCCESS,
    payload,
  }),
  storesRegisterIssuesDetailsError: (payload) => ({
    type: storesRegisterConstants.SET_STORES_REGISTER_ISSUES_DETAILS_ERROR,
    payload,
  }),
};
