import { Button, Input, Popconfirm, Popover, Space } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";
import appConfig from "../../../../config/config.json";

const UserInfo = ({
  data,
  handleActivateAccount,
  handleDeactivateAccount,
  handleGenerateNewConfirmToken,
}) => {
  const {
    id,
    first_name,
    other_names,
    gender,
    email,
    img_url,
    role,
    contact,
    department,
    account_status,
    account_confirmed,
  } = data;
  const { loading: accountStatusLoading } = useSelector(
    (state) => state.staffRecordsState.staffAccountStatus
  );
  const { loading: generateNewConfirmTokenLoading } = useSelector(
    (state) => state.staffRecordsState.generateNewConfirmToken
  );
  const dispatch = useDispatch();
  const openModal = () =>
    dispatch(appUiActions.toggleChangeProfilePictureModal(true));
  const activateAccount = () => handleActivateAccount(id);
  const deactivateAccount = () => handleDeactivateAccount(id);
  const generateNewConfirmToken = () =>
    handleGenerateNewConfirmToken(data.email);
  const openSignatureModal = () =>
    dispatch(appUiActions.toggleSignatureModal(true));

  const actions = (
    <Space direction="vertical">
      {account_status === "active" ? (
        <Popconfirm
          title="Are you sure you want to deactivate this account"
          placement="top"
          trigger="click"
          onConfirm={deactivateAccount}
          okButtonProps={{ loading: accountStatusLoading }}
        >
          <Button className="w-100" loading={accountStatusLoading} danger>
            Deactivate Account
          </Button>
        </Popconfirm>
      ) : (
        <Popconfirm
          title="Are you sure you want to activate this account"
          placement="bottom"
          trigger="click"
          onConfirm={activateAccount}
          okButtonProps={{ loading: accountStatusLoading }}
        >
          <Button
            className="w-100"
            loading={accountStatusLoading}
            type="primary"
          >
            Activate Account
          </Button>
        </Popconfirm>
      )}
      {!account_confirmed && (
        <Button
          loading={generateNewConfirmTokenLoading}
          className="w-100"
          onClick={generateNewConfirmToken}
        >
          Generate New Token
        </Button>
      )}
      <Button onClick={openModal} type="default">
        Change Profile Picture
      </Button>
      <Button
        onClick={openSignatureModal}
        className="bg-light w-100"
        type="dashed"
      >
        Upload Signature
      </Button>
    </Space>
  );

  return (
    <div className="w-50 w-sm-100">
      <div className="profile-image mb-2 d-flex flex-row ">
        <Avatar size={90} src={appConfig.server_url + img_url}></Avatar>
        <Popover placement="bottom" content={actions} trigger="click">
          <Button className="m-4">Actions</Button>
        </Popover>
      </div>
      <div className="d-flex flex-row mb-2">
        <div className="details m-1 w-50">
          <p htmlFor="exampleFormControlInput1" className="form-label">
            First Name
          </p>
          <Input size="large" readOnly value={first_name} />
        </div>
        <div className="details m-1 w-50">
          <p htmlFor="exampleFormControlInput1" className="form-label">
            Other Names
          </p>
          <Input size="large" readOnly value={other_names} />
        </div>
      </div>
      <div className="d-flex flex-row mb-2">
        <div className="details m-1 w-50">
          <p htmlFor="exampleFormControlInput1" className="form-label">
            Role
          </p>
          <Input size="large" readOnly value={role} />
        </div>
        <div className="details  mb-2 m-1 w-50">
          <p htmlFor="exampleFormControlInput1" className="form-label">
            Gender
          </p>
          <Input size="large" readOnly value={gender} />
        </div>
      </div>
      <div className="details mb-2 d-block">
        <p htmlFor="exampleFormControlInput1" className="form-label">
          Email
        </p>
        <Input size="large" readOnly value={email} />
      </div>
      <div className="details mb-2 d-block">
        <p htmlFor="exampleFormControlInput1" className="form-label">
          Contact
        </p>
        <Input size="large" readOnly value={contact} />
      </div>
      <div className="details mb-2 d-block">
        <p htmlFor="exampleFormControlInput1" className="form-label">
          Department
        </p>
        <Input size="large" readOnly value={department} />
      </div>
    </div>
  );
};

export default UserInfo;
