import { Button, Form, Modal, Upload, Select, Alert, message } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { InboxOutlined } from "@ant-design/icons";

const { Option } = Select;

const AddAdvanceAccountabilityFormExpenditureDocuments = ({
  handleAddRecord,
  handleCloseModal,
  visible,
}) => {
  const {
    advanceAccountabilityFormsDetails,
    advanceAccountabilityFormsExpenditure,
    addAdvanceAccountabilityFormsDocumentSuccess,
    addAdvanceAccountabilityFormsDocumentError,
    addAdvanceAccountabilityFormsDocumentLoading,
    addAdvanceAccountabilityFormsDocumentMessage,
  } = useSelector((state) => state.advanceAccountabilityFormsState);
  const [form] = Form.useForm();
  const onFinish = (values) => {
    values.createdAt = new Date();
    const formData = new FormData();
    formData.append(
      "advance_accountability_form_expense",
      values.advance_accountability_form_expense
    );
    formData.append(
      "advance_accountability_form",
      advanceAccountabilityFormsDetails.id
    );
    formData.append("document", values.document.file.originFileObj);
    handleAddRecord(formData);
  };
  useEffect(() => {
    if (
      addAdvanceAccountabilityFormsDocumentSuccess &&
      addAdvanceAccountabilityFormsDocumentMessage
    ) {
      message.success(addAdvanceAccountabilityFormsDocumentMessage);
      form.resetFields();
    }
  }, [
    addAdvanceAccountabilityFormsDocumentMessage,
    form,
    addAdvanceAccountabilityFormsDocumentSuccess,
  ]);
  return (
    <div>
      <Modal
        onCancel={() => handleCloseModal(false)}
        open={visible}
        title="ADD NEW FILE"
        footer={null}
      >
        <Form
          form={form}
          validateTrigger="onBlur"
          layout="vertical"
          onFinish={onFinish}
        >
          {!addAdvanceAccountabilityFormsDocumentSuccess &&
            addAdvanceAccountabilityFormsDocumentError && (
              <Alert
                type="error"
                showIcon
                message={addAdvanceAccountabilityFormsDocumentError}
              />
            )}
          <Form.Item
            rules={[
              { required: true, message: "Please select a requsition memo" },
            ]}
            label="Advance Accoutability Form Expense"
            name="advance_accountability_form_expense"
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              allowClear
            >
              {advanceAccountabilityFormsExpenditure.map((memo) => (
                <Option key={memo.id} value={memo.id}>
                  {memo.item}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="document"
            rules={[{ required: true, message: "Please add a file" }]}
          >
            <Upload.Dragger name="file" listType={null} maxCount={1}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
            </Upload.Dragger>
          </Form.Item>
          <Form.Item>
            <Button
              className={"w-100"}
              loading={addAdvanceAccountabilityFormsDocumentLoading}
              htmlType="submit"
              type="primary"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddAdvanceAccountabilityFormExpenditureDocuments;
