const initialState = {
  chartOfAccounts: [],
  chartOfAccountsLoading: false,
  chartOfAccountsSuccess: false,
  chartOfAccountsError: "",

  metaDataLoading: false,
  metaData: {},

  accountDetails: {},
  accountDetailsLoading: false,
  accountDetailsSuccess: "",
  accountDetailsError: "",

  accountTypeDetails: {},
  accountTypeDetailsLoading: false,
  accountTypeDetailsSuccess: false,
  accountTypeDetailsError: "",

  addAccountSuccess: false,
  addAccountError: "",
  addAccountMessage: "",
  addAccountLoading: false,

  addAccountTypeSuccess: false,
  addAccountTypeLoading: false,
  addAccountTypeError: "",
  addAccountTypeMessage: "",

  editAccountSuccess: false,
  editAccountLoading: false,
  editAccountError: "",
  editAccountMessage: "",
  editAccountData: {},

  editAccountTypeSuccess: false,
  editAccountTypeLoading: false,
  editAccountTypeError: "",
  editAccountTypeMessage: "",
  editAccountTypeData: {},

  deleteAccountLoading: false,
  deleteAccountSuccess: false,
  deleteAccountError: "",
  deleteAccountMessage: "",

  deleteAccountTypeLoading: false,
  deleteAccountTypeSuccess: false,
  deleteAccountTypeError: "",
  deleteAccountTypeMessage: "",

  approveAccountSuccess: false,
  approveAccountLoading: false,
  approveAccountMessage: "",
  approveAccountError: "",

  approveAccountTypeSuccess: false,
  approveAccountTypeLoading: false,
  approveAccountTypeMessage: "",
  approveAccountTypeError: "",

  checkAccountSuccess: false,
  checkAccountLoading: false,
  checkAccountError: "",
  checkAccountMessage: "",

  checkAccountTypeSuccess: false,
  checkAccountTypeLoading: false,
  checkAccountTypeError: "",
  checkAccountTypeMessage: "",
};

export default initialState;
