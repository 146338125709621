import { chartOfAccountsConstants } from "../../../actions/finance/chartOfAccounts/chartOfAccounts.actions";
import initialState from "../../../initialState/finance/chartOfAccounts/chartOfAccounts";

const chartOfAccountsState = (state = initialState, { type, payload }) => {
  switch (type) {
    case chartOfAccountsConstants.SET_CHART_OF_ACCOUNTS_REQUEST:
      return {
        ...state,
        chartOfAccountsLoading: true,
      };
    case chartOfAccountsConstants.SET_CHART_OF_ACCOUNTS_SUCCESS:
      return {
        ...state,
        chartOfAccountsSuccess: true,
        chartOfAccountsLoading: false,
        chartOfAccounts: payload,
      };
    case chartOfAccountsConstants.SET_CHART_OF_ACCOUNTS_ERROR:
      return {
        ...state,
        chartOfAccountsLoading: false,
        chartOfAccountsSuccess: false,
        chartOfAccountsError: payload,
      };
    case chartOfAccountsConstants.SET_ACCOUNTS_DETAILS_REQUEST:
      return {
        ...state,
        accountDetailsLoading: true,
      };
    case chartOfAccountsConstants.SET_ACCOUNTS_DETAILS_SUCCESS:
      return {
        ...state,
        accountDetailsLoading: false,
        accountDetailsSuccess: true,
        accountDetails: payload,
      };
    case chartOfAccountsConstants.SET_ACCOUNTS_DETAILS_ERROR:
      return {
        ...state,
        accountDetailsError: payload,
        accountDetailsLoading: false,
        accountDetailsSuccess: false,
      };
    case chartOfAccountsConstants.SET_ACCOUNT_TYPES_DETAILS_REQUEST:
      return {
        ...state,
        accountTypeDetailsLoading: true,
      };
    case chartOfAccountsConstants.SET_ACCOUNT_TYPES_DETAILS_SUCCESS:
      return {
        ...state,
        accountTypeDetailsLoading: false,
        accountTypeDetailsSuccess: true,
        accountTypeDetails: payload,
      };
    case chartOfAccountsConstants.SET_ACCOUNT_TYPES_DETAILS_ERROR:
      return {
        ...state,
        accountTypeDetailsError: payload,
        accountTypeDetailsLoading: false,
        accountTypeDetailsSuccess: false,
      };
    case chartOfAccountsConstants.ADD_ACCOUNT_REQUEST:
      return {
        ...state,
        addAccountLoading: true,
      };
    case chartOfAccountsConstants.ADD_ACCOUNT_SUCCESS:
      return {
        ...state,
        addAccountLoading: false,
        addAccountSuccess: true,
        addAccountMessage: payload,
        addAccountError: "",
      };
    case chartOfAccountsConstants.ADD_ACCOUNT_ERROR:
      return {
        ...state,
        addAccountLoading: false,
        addAccountSuccess: false,
        addAccountError: payload,
      };
    case chartOfAccountsConstants.ADD_ACCOUNT_COMPLETE:
      return {
        ...state,
        addAccountSuccess: false,
      };
    case chartOfAccountsConstants.ADD_ACCOUNT_TYPE_REQUEST:
      return {
        ...state,
        addAccountTypeLoading: true,
      };
    case chartOfAccountsConstants.ADD_ACCOUNT_TYPE_SUCCESS:
      return {
        ...state,
        addAccountTypeLoading: false,
        addAccountTypeSuccess: true,
        addAccountTypeMessage: payload,
        addAccountTypeError: "",
      };
    case chartOfAccountsConstants.ADD_ACCOUNT_TYPE_ERROR:
      return {
        ...state,
        addAccountTypeLoading: false,
        addAccountTypeSuccess: false,
        addAccountTypeError: payload,
      };
    case chartOfAccountsConstants.ADD_ACCOUNT_TYPE_COMPLETE:
      return {
        ...state,
        addAccountTypeSuccess: false,
      };
    case chartOfAccountsConstants.EDIT_ACCOUNT_REQUEST:
      return {
        ...state,
        editAccountLoading: true,
      };
    case chartOfAccountsConstants.EDIT_ACCOUNT_SUCCESS:
      return {
        ...state,
        editAccountLoading: false,
        editAccountSuccess: true,
        editAccountMessage: payload,
        editAccountError: "",
      };
    case chartOfAccountsConstants.EDIT_ACCOUNT_ERROR:
      return {
        ...state,
        editAccountLoading: false,
        editAccountSuccess: false,
        editAccountError: payload,
      };
    case chartOfAccountsConstants.EDIT_ACCOUNT_COMPLETE:
      return {
        ...state,
        editAccountSuccess: false,
      };
    case chartOfAccountsConstants.EDIT_ACCOUNT_DATA:
      return {
        ...state,
        editAccountData: payload,
      };
    case chartOfAccountsConstants.EDIT_ACCOUNT_TYPE_REQUEST:
      return {
        ...state,
        editAccountTypeLoading: true,
      };
    case chartOfAccountsConstants.EDIT_ACCOUNT_TYPE_SUCCESS:
      return {
        ...state,
        editAccountTypeLoading: false,
        editAccountTypeSuccess: true,
        editAccountTypeMessage: payload,
        editAccountTypeError: "",
      };
    case chartOfAccountsConstants.EDIT_ACCOUNT_TYPE_ERROR:
      return {
        ...state,
        editAccountTypeLoading: false,
        editAccountTypeSuccess: false,
        editAccountTypeError: payload,
      };
    case chartOfAccountsConstants.EDIT_ACCOUNT_TYPE_COMPLETE:
      return {
        ...state,
        editAccountTypeSuccess: false,
      };
    case chartOfAccountsConstants.EDIT_ACCOUNT_TYPE_DATA:
      return {
        ...state,
        editAccountTypeData: payload,
      };
    case chartOfAccountsConstants.DELETE_ACCOUNT_REQUEST:
      return {
        ...state,
        deleteAccountLoading: true,
      };
    case chartOfAccountsConstants.DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        deleteAccountLoading: false,
        deleteAccountSuccess: true,
        deleteAccountMessage: payload,
        deleteAccountError: "",
      };
    case chartOfAccountsConstants.DELETE_ACCOUNT_ERROR:
      return {
        ...state,
        deleteAccountLoading: false,
        deleteAccountSuccess: false,
        deleteAccountError: payload,
      };
    case chartOfAccountsConstants.DELETE_ACCOUNT_COMPLETE:
      return {
        ...state,
        deleteAccountSuccess: false,
        deleteAccountError: "",
      };
    case chartOfAccountsConstants.DELETE_ACCOUNT_TYPE_REQUEST:
      return {
        ...state,
        deleteAccountTypeLoading: true,
      };
    case chartOfAccountsConstants.DELETE_ACCOUNT_TYPE_SUCCESS:
      return {
        ...state,
        deleteAccountTypeLoading: false,
        deleteAccountTypeSuccess: true,
        deleteAccountTypeMessage: payload,
        deleteAccountTypeError: "",
      };
    case chartOfAccountsConstants.DELETE_ACCOUNT_TYPE_ERROR:
      return {
        ...state,
        deleteAccountTypeLoading: false,
        deleteAccountTypeSuccess: false,
        deleteAccountTypeError: payload,
      };
    case chartOfAccountsConstants.DELETE_ACCOUNT_TYPE_COMPLETE:
      return {
        ...state,
        deleteAccountTypeSuccess: false,
        deleteAccountTypeError: "",
      };
    case chartOfAccountsConstants.CHECK_ACCOUNT_REQUEST:
      return {
        ...state,
        checkAccountLoading: true,
      };
    case chartOfAccountsConstants.CHECK_ACCOUNT_SUCCESS:
      return {
        ...state,
        checkAccountLoading: false,
        checkAccountSuccess: true,
        checkAccountMessage: payload,
        checkAccountError: "",
      };
    case chartOfAccountsConstants.CHECK_ACCOUNT_ERROR:
      return {
        ...state,
        checkAccountLoading: false,
        checkAccountSuccess: false,
        checkAccountError: payload,
      };
    case chartOfAccountsConstants.CHECK_ACCOUNT_COMPLETE:
      return {
        ...state,
        checkAccountSuccess: false,
        checkAccountError: "",
      };
    case chartOfAccountsConstants.CHECK_ACCOUNT_TYPE_REQUEST:
      return {
        ...state,
        checkAccountTypeLoading: true,
      };
    case chartOfAccountsConstants.CHECK_ACCOUNT_TYPE_SUCCESS:
      return {
        ...state,
        checkAccountTypeLoading: false,
        checkAccountTypeSuccess: true,
        checkAccountTypeMessage: payload,
        checkAccountTypeError: "",
      };
    case chartOfAccountsConstants.CHECK_ACCOUNT_TYPE_ERROR:
      return {
        ...state,
        checkAccountTypeLoading: false,
        checkAccountTypeSuccess: false,
        checkAccountTypeError: payload,
      };
    case chartOfAccountsConstants.CHECK_ACCOUNT_TYPE_COMPLETE:
      return {
        ...state,
        checkAccountTypeSuccess: false,
        checkAccountTypeError: "",
      };
    case chartOfAccountsConstants.APPROVE_ACCOUNT_REQUEST:
      return {
        ...state,
        approveAccountLoading: true,
      };
    case chartOfAccountsConstants.APPROVE_ACCOUNT_SUCCESS:
      return {
        ...state,
        approveAccountLoading: false,
        approveAccountSuccess: true,
        approveAccountMessage: payload,
        approveAccountError: "",
      };
    case chartOfAccountsConstants.APPROVE_ACCOUNT_ERROR:
      return {
        ...state,
        approveAccountLoading: false,
        approveAccountSuccess: false,
        approveAccountError: payload,
      };
    case chartOfAccountsConstants.APPROVE_ACCOUNT_COMPLETE:
      return {
        ...state,
        approveAccountSuccess: false,
        approveAccountError: "",
      };
    case chartOfAccountsConstants.APPROVE_ACCOUNT_TYPE_REQUEST:
      return {
        ...state,
        approveAccountTypeLoading: true,
      };
    case chartOfAccountsConstants.APPROVE_ACCOUNT_TYPE_SUCCESS:
      return {
        ...state,
        approveAccountTypeLoading: false,
        approveAccountTypeSuccess: true,
        approveAccountTypeMessage: payload,
        approveAccountTypeError: "",
      };
    case chartOfAccountsConstants.APPROVE_ACCOUNT_TYPE_ERROR:
      return {
        ...state,
        approveAccountTypeLoading: false,
        approveAccountTypeSuccess: false,
        approveAccountTypeError: payload,
      };
    case chartOfAccountsConstants.APPROVE_ACCOUNT_TYPE_COMPLETE:
      return {
        ...state,
        approveAccountTypeSuccess: false,
        approveAccountTypeError: "",
      };
    case chartOfAccountsConstants.CHART_OF_ACOUNTS_META_DATA_REQUEST:
      return {
        ...state,
        metaDataLoading: true,
      };
    case chartOfAccountsConstants.CHART_OF_ACOUNTS_META_DATA:
      return {
        ...state,
        metaDataLoading: false,
        metaData: payload,
      };
    default:
      return state;
  }
};
export default chartOfAccountsState;
