import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddBidEvaluationForm from "../../../components/Procurement/bidEvaluations/Forms/AddBidEvaluationForm";
import EditBidEvaluationForm from "../../../components/Procurement/bidEvaluations/Forms/EditBidEvaluationForm";
import BidEvaluationsTable from "../../../components/Procurement/bidEvaluations/Tables/BidEvaluationsTable";
import routes from "../../../config/routes/routes";
import bidEvaluationThunks from "../../../config/thunks/procurement/bidEvaluation/bidEvaluation.thunks";
import logDetailThunks from "../../../config/thunks/settings/logDetails.thunks";

const BidEvaluation = () => {
  //state variables initialization
  const { bidEvaluationsSuccess } = useSelector(
    (state) => state.bidEvaluationState
  );
  const dispatch = useDispatch();
  //ui
  const [addBidEvaluationModalVisible, setAddBidEvaluationModalVisible] =
    useState(false);
  const [editBidEvaluationModalVisible, setEditBidEvaluationModalVisible] =
    useState(false);

  //thunks
  const refreshData = () => {
    dispatch(bidEvaluationThunks.getBidEvaluations());
    dispatch(bidEvaluationThunks.getBidEvaluationsMetaData());
  };
  const exportRecords = () => {
    dispatch(
      logDetailThunks.addLogDetails({
        created_at: new Date(),
        activity: `Exported Bid Evaluations`,
        records: "bidEvaluations",
      })
    );
  };
  const addBidEvaluations = (values, bidEvaluations) =>
    dispatch(bidEvaluationThunks.addBidEvaluationForm(values, bidEvaluations));

  const deleteBidEvaluation = (id, bidEvaluations) =>
    dispatch(bidEvaluationThunks.deleteBidEvaluationForm(id, bidEvaluations));

  const editBidEvaluation = (values, bidEvaluations) =>
    dispatch(bidEvaluationThunks.editBidEvaluationForm(values, bidEvaluations));

  //use effect
  useEffect(() => {
    document.title = routes.procurement.bidEvaluation.title;
    if (!bidEvaluationsSuccess) {
      dispatch(bidEvaluationThunks.getBidEvaluations());
      dispatch(bidEvaluationThunks.getBidEvaluationsMetaData());
    }
  }, [bidEvaluationsSuccess, dispatch]);

  return (
    <div className="container-fluid p-2">
      <h3>Bid Evaluations</h3>
      <BidEvaluationsTable
        handleOpenEditModal={setEditBidEvaluationModalVisible}
        handleDelete={deleteBidEvaluation}
        handleOpenAddModal={setAddBidEvaluationModalVisible}
        handleExportRecords={exportRecords}
        handleRefreshTable={refreshData}
      />
      <AddBidEvaluationForm
        handleAddRecord={addBidEvaluations}
        visible={addBidEvaluationModalVisible}
        handleCloseModal={setAddBidEvaluationModalVisible}
      />
      <EditBidEvaluationForm
        handleCloseModal={setEditBidEvaluationModalVisible}
        visible={editBidEvaluationModalVisible}
        handleEditRecord={editBidEvaluation}
      />
    </div>
  );
};

export default BidEvaluation;
