import {
  Modal,
  Form,
  message,
  Alert,
  Button,
  Input,
  DatePicker,
  InputNumber,
} from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";

const EditBidder = ({ visible, handleEditRecord, handleCloseModal }) => {
  const [form] = Form.useForm();
  const {
    editBiddersLoading,
    editBiddersMessage,
    editBiddersSuccess,
    editBiddersError,
    bidders,
    recordOfBiddersDetails,
    editBiddersData: data,
  } = useSelector((state) => state.recordOfBiddersState);

  const fields = [
    { name: "name", value: data.name },
    { name: "time_of_submission", value: moment(data.time_of_submission) },
    { name: "no_of_envelopes", value: data.no_of_envelopes },
    { name: "comments", value: data.comments },
  ];
  const closeModal = () => handleCloseModal(false);
  const onFinish = (values) => {
    values.updated_at = new Date();
    values.record_of_bidders = recordOfBiddersDetails.id;
    values.id = data.id;
    const biddersArray = bidders.filter((bidder) => bidder.id !== data.id);
    handleEditRecord(values, biddersArray);
  };

  useEffect(() => {
    if (editBiddersSuccess && editBiddersMessage) {
      message.success(editBiddersMessage);
      handleCloseModal(false);
    }
  }, [editBiddersMessage, editBiddersSuccess, handleCloseModal]);
  return (
    <div>
      <Modal
        open={visible}
        onCancel={closeModal}
        footer={null}
        title={`EDIT - ${data.name?.toUpperCase()}`}
      >
        <Form
          fields={fields}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          validateTrigger="onBlur"
          layout="horizontal"
          form={form}
          onFinish={onFinish}
        >
          {!editBiddersSuccess && editBiddersError && (
            <Alert type="error" showIcon message={editBiddersError} />
          )}
          <Form.Item
            name="name"
            label="Bidder's Name"
            rules={[{ required: true, message: "Please add the bidders name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="time_of_submission"
            label="Date Of Submission"
            rules={[
              {
                required: true,
                message: "Please select a date of submission name",
              },
            ]}
          >
            <DatePicker className="w-100" showTime />
          </Form.Item>
          <Form.Item name="no_of_envelopes" label="No. Of Envelopes">
            <InputNumber precision={0} className="w-100" />
          </Form.Item>
          <Form.Item name="comments" label="Comment (If Any)">
            <Input.TextArea />
          </Form.Item>
          <Form.Item className={"mt-3"} wrapperCol={{ offset: 8, span: 16 }}>
            <Button
              loading={editBiddersLoading}
              htmlType="submit"
              type="primary"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default EditBidder;
