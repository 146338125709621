import { budgetActions } from "../../../actions/finance/budget/budget.actions";
import apiRequests from "../../../api/api";
import budgetApiRequests from "../../../api/finance/budget/budget.api";

const budgetThunks = {
  getBudget: () => async (dispatch) => {
    dispatch(budgetActions.setBudgetsLoading());
    const res = await budgetApiRequests.getBudgets();
    if (res.success) dispatch(budgetActions.setBudgetsSucess(res.data));
    else dispatch(budgetActions.setBudgetsError(res.error));
  },
  getBudgetGraphData: (budget) => async (dispatch) => {
    dispatch(budgetActions.setBudgetsGraphDataLoading());
    const res = await apiRequests.getRequest(
      `/finance/budgets/graphData/${budget}`
    );
    if (res.success)
      dispatch(budgetActions.setBudgetsGraphDataSucess(res.data));
    else dispatch(budgetActions.setBudgetsGraphDataError(res.error));
  },
  getApprovedBudget: (id) => async (dispatch) => {
    dispatch(budgetActions.setApprovedBudgetsLoading());
    const res = await budgetApiRequests.getApprovedBudget(id);
    if (res.success) dispatch(budgetActions.setApprovedBudgetsSucess(res.data));
    else dispatch(budgetActions.setApprovedBudgetsError(res.error));
  },
  getBudgetMonitoringStatement: (id) => async (dispatch) => {
    dispatch(budgetActions.setBudgetMonitoringStatementLoading());
    const res = await budgetApiRequests.getBudgetMonitoringStatement(id);
    if (res.success)
      dispatch(budgetActions.setBudgetMonitoringStatementSucess(res.data));
    else dispatch(budgetActions.setBudgetMonitoringStatementError(res.error));
  },
  addBudget: (body) => async (dispatch) => {
    dispatch(budgetActions.addBudgetLoading(body));
    const res = await budgetApiRequests.addBudget(body);
    if (res.success) {
      dispatch(budgetThunks.getBudget());
      dispatch(budgetActions.addBudgetSuccess(res.message));
    } else dispatch(budgetActions.addBudgetError(res.error));
    dispatch(budgetActions.addBudgetComplete());
  },
  addBudgetLine: (body) => async (dispatch) => {
    dispatch(budgetActions.addBudgetLineLoading());
    const res = await budgetApiRequests.addBudgetLine(body);
    if (res.success) {
      dispatch(budgetThunks.getApprovedBudget(body.budget));
      dispatch(budgetActions.addBudgetLineSuccess(res.message));
    } else dispatch(budgetActions.addBudgetLineError(res.error));
    dispatch(budgetActions.addBudgetLineComplete());
  },
  addBudgetActivtities: (body) => async (dispatch) => {
    dispatch(budgetActions.addBudgetActivityLoading(body));
    const res = await budgetApiRequests.addBudgetActivity(body);
    if (res.success) {
      dispatch(budgetThunks.getApprovedBudget(body.budget));
      dispatch(budgetActions.addBudgetActivitySuccess(res.message));
    } else dispatch(budgetActions.addBudgetActivityError(res.error));
    dispatch(budgetActions.addBudgetActivityComplete());
  },
  editBudgetLine: (body) => async (dispatch) => {
    dispatch(budgetActions.editBudgetLineLoading());
    const res = await budgetApiRequests.editBudgetLine(body);
    if (res.success) {
      dispatch(budgetThunks.getApprovedBudget(body.budget));
      dispatch(budgetActions.editBudgetLineSuccess(res.message));
    } else dispatch(budgetActions.editBudgetLineError(res.error));
    dispatch(budgetActions.editBudgetLineComplete());
  },
  editBudgetActivity: (body) => async (dispatch) => {
    dispatch(budgetActions.editBudgetAcitvityLoading(body));
    const res = await budgetApiRequests.editBudgetActivity(body);
    if (res.success) {
      dispatch(budgetThunks.getApprovedBudget(body.budget));
      dispatch(budgetActions.editBudgetAcitvitySuccess(res.message));
    } else dispatch(budgetActions.editBudgetAcitvityError(res.error));
    dispatch(budgetActions.editBudgetAcitvityComplete());
  },
  deleteBudget: (id, project, budgets) => async (dispatch) => {
    dispatch(budgetActions.deleteBudgetLoading());
    const res = await budgetApiRequests.deleteBudget(id, project);
    if (res.success)
      dispatch(
        budgetActions.deleteBudgetSuccess({
          message: res.message,
          data: budgets,
        })
      );
    else dispatch(budgetActions.deleteBudgetError(res.error));
    dispatch(budgetActions.deleteBudgetComplete());
  },
  deleteBudgetLine: (id, budget) => async (dispatch) => {
    dispatch(budgetActions.deleteBudgetLineLoading());
    const res = await budgetApiRequests.deleteBudgetLine(id, budget);
    if (res.success) {
      dispatch(budgetThunks.getApprovedBudget(budget));
      dispatch(budgetActions.deleteBudgetLineSuccess(res.message));
    } else dispatch(budgetActions.deleteBudgetLineError(res.error));
    dispatch(budgetActions.deleteBudgetLineComplete());
  },
  deleteBudgetActivity: (id, budget) => async (dispatch) => {
    dispatch(budgetActions.deleteBudgetActivityLoading());
    const res = await budgetApiRequests.deleteBudgetActivity(id, budget);
    if (res.success) {
      dispatch(budgetActions.deleteBudgetActivitySuccess(res.message));
      dispatch(budgetThunks.getApprovedBudget(budget));
    } else dispatch(budgetActions.deleteBudgetActivityError(res.error));
    dispatch(budgetActions.deleteBudgetActivityComplete());
  },
  approveBudget: (id) => async (dispatch) => {
    dispatch(budgetActions.approveBudgetLoading());
    const res = await budgetApiRequests.approveBudget(id);
    if (res.success) {
      dispatch(budgetThunks.getBudgetDetails(id));
      dispatch(budgetActions.approveBudgetSuccess(res.message));
    } else dispatch(budgetActions.approveBudgetError(res.error));
    dispatch(budgetActions.approveBudgetComplete());
  },
  checkBudget: (id) => async (dispatch) => {
    dispatch(budgetActions.checkBudgetLoading());
    const res = await budgetApiRequests.checkBudget(id);
    if (res.success) {
      dispatch(budgetThunks.getBudgetDetails(id));
      dispatch(budgetActions.checkBudgetSuccess(res.message));
    } else dispatch(budgetActions.checkBudgetError(res.error));
    dispatch(budgetActions.checkBudgetComplete());
  },
  submitBudget: (id) => async (dispatch) => {
    dispatch(budgetActions.submitBudgetLoading());
    const res = await budgetApiRequests.submitBudget(id);
    if (res.success) {
      dispatch(budgetThunks.getBudgetDetails(id));
      dispatch(budgetActions.submitBudgetSuccess(res.message));
    } else dispatch(budgetActions.submitBudgetError(res.error));
    dispatch(budgetActions.submitBudgetComplete());
  },
  metaData: () => async (dispatch) => {
    dispatch(budgetActions.metaDataLoading());
    const res = await budgetApiRequests.metaData();
    dispatch(budgetActions.metaData(res.data || {}));
  },
  getBudgetDetails: (id) => async (dispatch) => {
    dispatch(budgetActions.setBudgetDetailsLoading());
    const res = await budgetApiRequests.getDetails(`budgets/${id}`);
    if (res.success) dispatch(budgetActions.setBudgetDetailsSucess(res.data));
    else dispatch(budgetActions.setBudgetsError(res.error));
  },
  getBudgetLineDetails: (id) => async (dispatch) => {
    dispatch(budgetActions.setBudgetLineDetailsLoading());
    const res = await budgetApiRequests.getDetails(`budgets/budgetLines/${id}`);
    if (res.success)
      dispatch(budgetActions.setBudgetLineDetailsSucess(res.data));
    else dispatch(budgetActions.setBudgetLineDetailsError(res.error));
  },
  getBudgetAcitvityDetails: (id) => async (dispatch) => {
    dispatch(budgetActions.setBudgetActivityDetailsLoading());
    const res = await budgetApiRequests.getDetails(
      `budgets/budgetActivities/${id}`
    );
    if (res.success)
      dispatch(budgetActions.setBudgetActivityDetailsSucess(res.data));
    else dispatch(budgetActions.setBudgetActivityDetailsError(res.error));
  },
};

export default budgetThunks;
