const initialState = {
  auth: {
    forgotPasswordModalVisible: false,
  },
  appDrawerVisible: false,
  fileModalOpen: false,
  fileSrc: "",
  settings: {
    addRolesModal: false,
    editRoleModal: false,
    addPermissonsModal: false,
    editPermissonsModal: false,
    permissionsDetailsModal: false,
    changePasswordModal: false,
    rolesDetailsModal: false,
  },
  admisnistration: {
    assetsRegister: {
      addAssetModal: false,
      editAssetModal: false,
      assetTypesModal: false,
      addAssetTypesModal: false,
      editAssetTypesModal: false,
      viewAssetTypeDetails: false,
      viewAssetsDetailsModal: false,
    },
    staffRecords: {
      addStaffModal: false,
      editStaffModal: false,
      profilePictureModal: false,
      signatureModal: false,
    },
    manualsAndPolicies: {
      addManualsAndPoliciesModal: false,
      editManualsAndPoliciesModal: false,
      manualsAndPoliciesDetailsModal: false,
    },
    storesRegister: {
      addStoresRegisterReceiptModal: false,
      editStoresRegisterReceiptModal: false,
      addStoresRegisterIssuesModal: false,
      editStoresRegisterIssuesModal: false,
      viewReceiptDetails: false,
      viewIssuesDetails: false,
    },
  },
  //business Development and partnerships
  addDonorsModal: false,
  editDonorsModal: false,
  setPersonInchargeModal: false,

  addProjectsModal: false,
  editProjectsModal: false,

  //finance
  //chart of accounts
  addCOAAccountModal: false,
  addCOAAccountTypeModal: false,
  editAccountsModal: false,
  editAccountTypesModal: false,
  accountDetailsModal: false,
  accountTypeDetailsModal: false,
  searchChartOfAccountsModal: false,

  //budget
  addBudgetModal: false,
  addBudgetLineModal: false,
  addBudgetActivityModal: false,
  editBudgetLineModal: false,
  editBudgetActivity: false,
};

export default initialState;
