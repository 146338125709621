// services/reportGenerator.js

import jsPDF from "jspdf";
import "jspdf-autotable";
import { numberWithCommas } from "../helpers/app/numberFormater";
import image from "../../assets/logo/authLogo.png";
import { centrePdfText } from "../helpers/app/pdf";
import appConfig from "../config.json";
// Date Fns is used to format the dates we receive

// define a generatePDF function that accepts a tickets argument
const generateRequisitionMemoPdf = (data, currency) => {
  const doc = new jsPDF();
  doc.setFontSize(10);
  doc.addImage(image, "PNG", 60, 15, 80, 25); //base64 image, format, x-coordinate, y-coordinate, width, height
  centrePdfText(doc, "PO Box 11406, Kampala (U)", 40);
  centrePdfText(
    doc,
    " P.O.BOX 610029, Maracha, Uganda |Adongoro Cell, Maracha Town Council",
    45
  );
  centrePdfText(
    doc,
    "Tel: +256 794728054/ +256 703154084 | Mob: +256 782206830",
    60
  );
  centrePdfText(
    doc,
    "Web: www.amaniinitiative.org  | Email: info.amaniinitiative@gmail.com  ",
    65
  );
  centrePdfText(doc, "AMANI INITIATIVE REQUISITION FORM", 75);
  doc.text(`DATE: ${new Date(data.created_at).toDateString()} `, 14, 85);
  doc.text(`REQUISITION FORM NUMBER: ${data.memo_number} `, 100, 85);
  doc.text(`PROJECT: ${data.projectDetails?.name} `, 14, 90);
  doc.text(`SOURCE OF FUNDS: ${data.donorDetails?.name}`, 14, 95);
  doc.text(`BUDGET LINE: ${data.budgetLineDetails?.name}`, 14, 100);
  doc.text(`BUDGET CODE: ${data.budgetLineDetails?.expense_code}`, 120, 100);
  doc.text(`TYPE: ${data.type}`, 14, 105);
  doc.text(`DETAILS: ${data.details}`, 14, 110);

  let total = 0;
  const pdfColumns = ["Expense Item", "Units", "Unit Cost", "Total Amount"];
  let pdfRows = [];
  data.requisitionMemoActivities?.forEach((activity) => {
    total += activity.total_cost;
    pdfRows.push([
      activity.activityName,
      numberWithCommas(activity.units),
      `${currency === "D" ? "USD" : "UGX"} ${numberWithCommas(
        activity.unit_cost
      )}`,
      `${currency === "D" ? "USD" : "UGX"} ${numberWithCommas(
        activity.total_cost
      )}`,
    ]);
  });
  pdfRows.push([
    "Total",
    "",
    "",
    `${currency === "D" ? "USD" : "UGX"} ${numberWithCommas(total)}`,
  ]);
  doc.autoTable(pdfColumns, pdfRows, { startY: 120 });
  let { finalY } = doc.lastAutoTable;
  doc.text(
    `Prepared By: ${data?.creator?.first_name} ${data?.creator?.other_names} `,
    14,
    finalY + 10
  );
  doc.text(`Role: ${data?.creator?.role} `, 14, finalY + 20);
  doc.text(
    `Date Prepared: ${new Date(data.created_at).toDateString()}  `,
    117,
    finalY + 10
  );
  doc.text(`Signature: `, 125, finalY + 20);
  if (data?.creator?.signature) {
    doc.addImage(
      `${appConfig.server_url}${data?.creator?.signature}`,
      "PNG",
      150,
      finalY + 12,
      30,
      15
    );
  }
  doc.text(
    `Checked By: ${data?.checker?.first_name} ${data?.checker?.other_names}`,
    14,
    finalY + 35
  );
  doc.text(`Role: ${data?.checker?.role}`, 14, finalY + 45);
  doc.text(
    `Date Checked: ${new Date(data.checked_at).toDateString()}  `,
    117,
    finalY + 35
  );
  doc.text(`Signature: `, 125, finalY + 45);
  if (data?.checker?.signature) {
    doc.addImage(
      `${appConfig.server_url}${data?.checker?.signature}`,
      "PNG",
      150,
      finalY + 37,
      30,
      15
    );
  }
  doc.text(
    `Approved By: ${data?.approver?.first_name} ${data?.approver?.other_names}`,
    14,
    finalY + 60
  );
  doc.text(`Role: ${data?.approver?.role}`, 14, finalY + 70);
  doc.text(
    `Date Approved: ${new Date(data.approved_at).toDateString()}  `,
    117,
    finalY + 60
  );
  doc.text(`Signature: `, 125, finalY + 70);
  if (data?.approver?.signature) {
    doc.addImage(
      `${appConfig.server_url}${data?.approver?.signature}`,
      "PNG",
      150,
      finalY + 63,
      30,
      15
    );
  }
  doc.save("AMANI INITIATIVE REQUISITION FORM");
};

export default generateRequisitionMemoPdf;
