import axios from "axios";

const errorResponseObject = {
  success: false,
  error: "Sorry, there seems to be an error.",
};

const projectsApiRequests = {
  fetchProjects: async () => {
    try {
      const res = await axios.get(
        "/businessDevelopmentAndPartnerships/projects"
      );
      return res;
    } catch (error) {
      console.error("Fetch Projects error:", error);
      return errorResponseObject;
    }
  },

  fetchProjectDetails: async (id) => {
    try {
      const res = await axios.get(
        `/businessDevelopmentAndPartnerships/projects/${id}`
      );
      return res;
    } catch (error) {
      console.error("Fetch Project details error:", error);
      return errorResponseObject;
    }
  },

  addProject: async (body) => {
    try {
      const res = await axios.post(
        "/businessDevelopmentAndPartnerships/projects",
        body
      );
      return res;
    } catch (error) {
      console.error("Add Project details error:", error);
      return errorResponseObject;
    }
  },

  editProject: async (body) => {
    try {
      const res = await axios.put(
        `/businessDevelopmentAndPartnerships/projects/${body.id}`,
        body
      );
      return res;
    } catch (error) {
      console.error("Edit Project details error:", error);
      return errorResponseObject;
    }
  },

  deleteProject: async (id, name) => {
    try {
      const res = await axios.delete(
        `/businessDevelopmentAndPartnerships/projects/${name}/${id}`
      );
      return res;
    } catch (error) {
      console.error("Delete Project details error:", error);
      return errorResponseObject;
    }
  },

  checkProject: async (id) => {
    try {
      const res = await axios.patch(
        `/businessDevelopmentAndPartnerships/projects/check/${id}`,
        {
          checkedAt: new Date(),
        }
      );
      return res;
    } catch (error) {
      console.error("Check Project details error:", error);
      return errorResponseObject;
    }
  },

  approveProject: async (id) => {
    try {
      const res = await axios.patch(
        `/businessDevelopmentAndPartnerships/projects/approve/${id}`,
        {
          approvedAt: new Date(),
        }
      );
      return res;
    } catch (error) {
      console.error("Approve Project details error:", error);
      return errorResponseObject;
    }
  },

  projectsMetaData: async () => {
    try {
      const res = await axios.get(
        "/businessDevelopmentAndPartnerships/projects/metaData"
      );
      return res;
    } catch (error) {
      console.error("Projects Meta Data error", error);
      return errorResponseObject;
    }
  },
};

export default projectsApiRequests;
