import React from "react";
import { useStrategicPlansStore } from "../../../../../config/stores";
import AntdModal from "../../../../../components/common/AntdModal";
import StrategicPlanActivities from "./activities/StrategicPlanActivitiesTable";
import { toUpper } from "lodash";

const ThematicAreaDetails = () => {
  const strategicPlanStore = useStrategicPlansStore();
  const { showViewThemeAreaDetails } = strategicPlanStore;

  const closeModal = () => {
    strategicPlanStore.setShowViewThemeAreaDetails();
  };

  return (
    <AntdModal
      title={toUpper(showViewThemeAreaDetails.thematic_area)}
      open={showViewThemeAreaDetails.show}
      closeModal={closeModal}
      size="large"
      zIndex={100}
    >
      <StrategicPlanActivities
        canEdit
        activities={showViewThemeAreaDetails.strategicPlansProposedActivities}
      />
    </AntdModal>
  );
};

export default ThematicAreaDetails;
