import { rejectionsActions } from "../../actions/rejections/rejections.actions";
import apiRequests from "../../api/api";
import rejectionsApiRequests from "../../api/rejections/rejections.api";

const rejectionsThunks = {
  addRejection: (body) => async (dispatch) => {
    dispatch(rejectionsActions.addRejectionRequest());
    const res = await rejectionsApiRequests.addRejection(body);
    if (res.success) {
      dispatch(rejectionsActions.addRejectionSuccess(res.message));
    } else dispatch(rejectionsActions.addRejectionsError(res.error));
    dispatch(rejectionsActions.addRejectionsComplete());
  },
  addProcurementRejection: (body) => async (dispatch) => {
    dispatch(rejectionsActions.addProcurementRejectionsRequest());
    const res = await apiRequests.patchRequest(
      `/procurement/${body.applicationPath}/reject`,
      body
    );
    if (res.success) {
      dispatch(rejectionsActions.addProcurementRejectionsSuccess(res.message));
    } else dispatch(rejectionsActions.addProcurementRejectionsError(res.error));
    dispatch(rejectionsActions.addProcurementRejectionsComplete());
  },
};

export default rejectionsThunks;
