import { dashboardDataActions } from "../../actions/dashBoard/dashboard.actions";
import apiRequests from "../../api/api";

const dashboardThunks = {
  getDashBoardData: () => async (dispatch) => {
    dispatch(dashboardDataActions.setDashboardDataRequest());
    const res = await apiRequests.getRequest("/dashboard");
    if (res.success)
      dispatch(dashboardDataActions.setDashboardDataSuccess(res.data));
    else dispatch(dashboardDataActions.setDashboardDataError(res.error));
  },
};

export default dashboardThunks;
