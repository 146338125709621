import { localPurchaseOrdersActions } from "../../../actions/procument/localPurchaseOrder/localPuchaseOrder.actions";
import apiRequests from "../../../api/api";

const localPurchaseOrdersThunks = {
  getLocalPurchaseOrders: () => async (dispatch) => {
    dispatch(localPurchaseOrdersActions.setLocalPurchaseOrdersRequest());
    const res = await apiRequests.getRequest(
      "/procurement/localPurchaseOrders/"
    );
    if (res.success)
      dispatch(
        localPurchaseOrdersActions.setLocalPurchaseOrdersSuccess(res.data)
      );
    else
      dispatch(
        localPurchaseOrdersActions.setLocalPurchaseOrdersError(res.error)
      );
  },
  getLpoItems: (id) => async (dispatch) => {
    dispatch(localPurchaseOrdersActions.setLpoItemsRequest());
    const res = await apiRequests.getRequest(
      `/procurement/localPurchaseOrders/items/${id}`
    );
    if (res.success)
      dispatch(localPurchaseOrdersActions.setLpoItemsSuccess(res.data));
    else dispatch(localPurchaseOrdersActions.setLpoItemsError(res.error));
  },
  getLocalPurchaseOrdersDetails: (id) => async (dispatch) => {
    dispatch(localPurchaseOrdersActions.setLocalPurchaseOrdersDetailsRequest());
    const res = await apiRequests.getRequest(
      `/procurement/localPurchaseOrders/${id}`
    );
    if (res.success)
      dispatch(
        localPurchaseOrdersActions.setLocalPurchaseOrdersDetailsSuccess(
          res.data
        )
      );
    else
      dispatch(
        localPurchaseOrdersActions.setLocalPurchaseOrdersDetailsError(res.error)
      );
  },
  getLpoItemsDetails: (id) => async (dispatch) => {
    dispatch(localPurchaseOrdersActions.setLpoItemsDetailsRequest());
    const res = await apiRequests.getRequest(
      `/procurement/localPurchaseOrders/items/details/${id}`
    );
    if (res.success)
      dispatch(localPurchaseOrdersActions.setLpoItemsDetailsSuccess(res.data));
    else
      dispatch(localPurchaseOrdersActions.setLpoItemsDetailsError(res.error));
  },
  addLocalPurchaseOrders: (body, localPurchaseOrders) => async (dispatch) => {
    dispatch(localPurchaseOrdersActions.addLocalPurchaseOrdersRequest());
    const res = await apiRequests.postRequest(
      "/procurement/localPurchaseOrders/",
      body
    );
    if (res.success)
      dispatch(
        localPurchaseOrdersActions.addLocalPurchaseOrdersSuccess({
          data: [res.data, ...localPurchaseOrders],
          message: res.message,
        })
      );
    else
      dispatch(
        localPurchaseOrdersActions.addLocalPurchaseOrdersError(res.error)
      );
    dispatch(localPurchaseOrdersActions.addLocalPurchaseOrdersComplete());
  },
  addLpoItems: (body, items) => async (dispatch) => {
    dispatch(localPurchaseOrdersActions.addLpoItemsRequest());
    const res = await apiRequests.postRequest(
      "/procurement/localPurchaseOrders/items/",
      body
    );
    if (res.success) {
      dispatch(localPurchaseOrdersActions.addLpoItemsSuccess(res.message));
      dispatch(
        localPurchaseOrdersThunks.getLpoItems(body.local_purchase_order)
      );
    } else dispatch(localPurchaseOrdersActions.addLpoItemsError(res.error));
    dispatch(localPurchaseOrdersActions.addLpoItemsComplete());
  },
  editLpoItems: (body) => async (dispatch) => {
    dispatch(localPurchaseOrdersActions.editLpoItemsRequest());
    const res = await apiRequests.putRequest(
      `/procurement/localPurchaseOrders/items/${body.id}`,
      body
    );
    if (res.success) {
      dispatch(localPurchaseOrdersActions.editLpoItemsSuccess(res.message));
      dispatch(
        localPurchaseOrdersThunks.getLpoItems(body.local_purchase_order)
      );
    } else dispatch(localPurchaseOrdersActions.editLpoItemsError(res.error));
    dispatch(localPurchaseOrdersActions.editLpoItemsComplete());
  },
  editLocalPurchaseOrder: (body, localPurchaseOrders) => async (dispatch) => {
    dispatch(localPurchaseOrdersActions.editLocalPurchaseOrdersRequest());
    const res = await apiRequests.putRequest(
      `/procurement/localPurchaseOrders/${body.id}`,
      body
    );
    if (res.success)
      dispatch(
        localPurchaseOrdersActions.editLocalPurchaseOrdersSuccess({
          data: [res.data, ...localPurchaseOrders],
          message: res.message,
        })
      );
    else
      dispatch(
        localPurchaseOrdersActions.editLocalPurchaseOrdersError(res.error)
      );
    dispatch(localPurchaseOrdersActions.editLocalPurchaseOrdersComplete());
  },
  deleteLocalPurchaseOrders: (id, localPurchaseOrders) => async (dispatch) => {
    dispatch(localPurchaseOrdersActions.deleteLocalPurchaseOrdersRequest());
    const res = await apiRequests.deleteRequest(
      `/procurement/localPurchaseOrders/${id}`
    );
    if (res.success)
      dispatch(
        localPurchaseOrdersActions.deleteLocalPurchaseOrdersSuccess({
          message: res.message,
          data: localPurchaseOrders,
        })
      );
    else
      dispatch(
        localPurchaseOrdersActions.deleteLocalPurchaseOrdersError(res.error)
      );
    dispatch(localPurchaseOrdersActions.deleteLocalPurchaseOrdersComplete());
  },
  deleteLpoItems: (id, local_purchase_order) => async (dispatch) => {
    dispatch(localPurchaseOrdersActions.deleteLpoItemsRequest());
    const res = await apiRequests.deleteRequest(
      `/procurement/localPurchaseOrders/items/${id}`
    );
    if (res.success) {
      dispatch(localPurchaseOrdersActions.deleteLpoItemsSuccess(res.message));
      dispatch(localPurchaseOrdersThunks.getLpoItems(local_purchase_order));
    } else dispatch(localPurchaseOrdersActions.deleteLpoItemsError(res.error));
    dispatch(localPurchaseOrdersActions.deleteLpoItemsError());
  },
  checkLocalPurchaseOrders: (id) => async (dispatch) => {
    dispatch(localPurchaseOrdersActions.checkLocalPurchaseOrdersRequest());
    const res = await apiRequests.patchRequest(
      `/procurement/localPurchaseOrders/check/${id}`,
      { checkedAt: new Date() }
    );
    if (res.success) {
      dispatch(
        localPurchaseOrdersActions.checkLocalPurchaseOrdersSuccess(res.message)
      );
      dispatch(localPurchaseOrdersThunks.getLocalPurchaseOrdersDetails(id));
    } else
      dispatch(
        localPurchaseOrdersActions.checkLocalPurchaseOrdersError(res.error)
      );
    dispatch(localPurchaseOrdersActions.checkLocalPurchaseOrdersComplete());
  },
  approveLocalPurchaseOrders: (id) => async (dispatch) => {
    dispatch(localPurchaseOrdersActions.approveLocalPurchaseOrdersRequest());
    const res = await apiRequests.patchRequest(
      `/procurement/localPurchaseOrders/approve/${id}`,
      { approvedAt: new Date() }
    );
    if (res.success) {
      dispatch(
        localPurchaseOrdersActions.approveLocalPurchaseOrdersSuccess(
          res.message
        )
      );
      dispatch(localPurchaseOrdersThunks.getLocalPurchaseOrdersDetails(id));
    } else
      dispatch(
        localPurchaseOrdersActions.approveLocalPurchaseOrdersError(res.error)
      );
    dispatch(localPurchaseOrdersActions.approveLocalPurchaseOrdersComplete());
  },
  submitLocalPurchaseOrders: (id) => async (dispatch) => {
    dispatch(localPurchaseOrdersActions.submitLocalPurchaseOrdersRequest());
    const res = await apiRequests.patchRequest(
      `/procurement/localPurchaseOrders/submit/${id}`,
      { approvedAt: new Date() }
    );
    if (res.success) {
      dispatch(
        localPurchaseOrdersActions.submitLocalPurchaseOrdersSuccess(res.message)
      );
      dispatch(localPurchaseOrdersThunks.getLocalPurchaseOrdersDetails(id));
    } else
      dispatch(
        localPurchaseOrdersActions.submitLocalPurchaseOrdersError(res.error)
      );
    dispatch(localPurchaseOrdersActions.submitLocalPurchaseOrdersComplete());
  },
  getLocalPurchaseOrdersMetaData: () => async (dispatch) => {
    dispatch(
      localPurchaseOrdersActions.setLocalPurchaseOrdersMetaDataRequest()
    );
    const res = await apiRequests.getRequest(
      "/procurement/localPurchaseOrders/metaData"
    );
    dispatch(
      localPurchaseOrdersActions.setLocalPurchaseOrdersMetaData(res.data || {})
    );
  },
};

export default localPurchaseOrdersThunks;
