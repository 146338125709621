import React, { useEffect, useState } from "react";
import { useActivityNoteStore } from "../../../../config/stores";
import { useSelector } from "react-redux";
import AntdModal from "../../../../components/common/AntdModal";
import { Col, Divider, Form, Radio, Row, message } from "antd";
import AppButton from "../../../../components/common/AppButton";
import AppInput from "../../../../components/common/AppInput";
import dayjs from "dayjs";
import { filter, find, isEmpty, map } from "lodash";

const ActivityConceptNotesForm = () => {
  const activityConceptNoteStore = useActivityNoteStore();
  const {
    creating,
    activityNoteToUpdate,
    showCreatingActivityNote,
    creatingActivityNote,
    updatingActivityNote,
    metaData,
  } = activityConceptNoteStore;
  const { projectsDetails } = useSelector((state) => state.projectsState);
  const { approvedBudget } = useSelector((state) => state.budgetState);
  const [thematicAreas, setThematicAreas] = useState([]);
  const [activities, setActivities] = useState([]);

  const [form] = Form.useForm();
  const strategicPlanId = Form.useWatch("strategic_plan_id", form);
  const thematicAreaId = Form.useWatch("thematic_area_id", form);

  const closeModal = () => {
    activityConceptNoteStore.setCreating();
  };

  const onSubmit = () => {
    form
      .validateFields()
      .then((data) => {
        if (creating) {
          const budgetLine = find(approvedBudget, {
            id: data.budget_line_id,
          });
          data.title = budgetLine.name;
          activityConceptNoteStore.createActivityNote(data);
        } else {
          activityConceptNoteStore.updateActivityNote(
            activityNoteToUpdate.id,
            data
          );
        }
      })
      .catch(() => {
        message.error("Please fill in all fields correctly");
      });
  };

  useEffect(() => {
    if (creating) {
      form.setFieldsValue({
        project_id: projectsDetails.id,
        donor: projectsDetails.donor,
        thematic_area: projectsDetails.thematic_area,
      });
    } else {
      form.setFieldsValue({
        ...activityNoteToUpdate,
        date: dayjs(activityNoteToUpdate.date),
        donor: projectsDetails.donor,
      });
    }
  }, [projectsDetails, activityNoteToUpdate, form, creating]);

  useEffect(() => {
    if (isEmpty(metaData)) activityConceptNoteStore.getMetaData();
  }, [metaData]);

  useEffect(() => {
    if (strategicPlanId) {
      const strategicPlan = find(metaData.strategicPlans, {
        id: strategicPlanId,
      });
      setThematicAreas(strategicPlan?.strategicPlanThematicAreas);
    }

    if (strategicPlanId && thematicAreaId) {
      const thematicArea = find(thematicAreas, { id: thematicAreaId });
      setActivities(thematicArea?.strategicPlansProposedActivities);
    }
  }, [strategicPlanId, thematicAreaId, metaData, thematicAreas, form]);

  return (
    <AntdModal
      title={
        creating ? "ADD ACTIVITY CONCEPT NOTE" : "EDIT ACTIVITY CONCEPT NOTE"
      }
      customSize={1100}
      open={showCreatingActivityNote}
      closeModal={closeModal}
      footer={[
        <AppButton
          text={
            creating
              ? "CREATE ACTIVITY CONCEPT NOTE"
              : "UPDATE ACTIVITY CONCEPT NOTE"
          }
          key={"create"}
          loading={creatingActivityNote || updatingActivityNote}
          onClick={onSubmit}
        />,
        <AppButton text="CANCEL" key={"cancel"} onClick={closeModal} danger />,
      ]}
    >
      <Form labelCol={{ span: 5 }} layout="horizontal" form={form}>
        <Row gutter={4}>
          <Col span={12}>
            <AppInput
              label="Project"
              name="project_id"
              type="select"
              rules={[
                {
                  required: true,
                  message: "Please select a project",
                },
              ]}
              inputAttributes={{
                disabled: true,
              }}
              options={[
                {
                  label: projectsDetails.name,
                  value: projectsDetails.id,
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <AppInput
              label="Donor"
              name="donor"
              type="select"
              inputAttributes={{
                disabled: true,
              }}
              options={[
                {
                  label: projectsDetails.donor,
                  value: projectsDetails.donor,
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <AppInput
              label="Strategic Plan"
              name="strategic_plan_id"
              type="select"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
              options={map(metaData.strategicPlans, (strategicPlan) => ({
                label: strategicPlan.title,
                value: strategicPlan.id,
              }))}
            />
          </Col>
          <Col span={12}>
            <AppInput
              label="Budget Line"
              name="budget_line_id"
              type="select"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
              options={filter(approvedBudget, {
                requires_concept_note: true,
              }).map((budget) => ({
                label: budget.name,
                value: budget.id,
              }))}
            />
          </Col>
          <Col span={12}>
            <AppInput
              label="Thematic Area"
              name="thematic_area_id"
              type="select"
              options={map(thematicAreas, (thematicArea) => ({
                label: thematicArea.thematic_area,
                value: thematicArea.id,
              }))}
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <AppInput
              label="Activity"
              name="activity_id"
              type="select"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
              options={map(activities, (activity) => ({
                label: activity.activity,
                value: activity.id,
              }))}
            />
          </Col>
          <Col span={12}>
            <AppInput
              label="Date"
              name="date"
              type="date"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <AppInput
              label="Activity Venue"
              name="activity_venue"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <AppInput
              label="Background"
              name="background_to_activity"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <AppInput
              label="Methodology"
              name="methodology"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <AppInput
              label="Implementer"
              name="activity_expected_output"
              type="implementer"
            />
          </Col>
          <Col span={24}>
            <AppInput
              label="Outputs"
              name="activity_expected_output"
              type="textarea"
            />
          </Col>
        </Row>

        <Divider orientation="center">Program Quality Markers</Divider>

        <Row className="mx-5">
          <Col span={4}>Gender</Col>
          <Col span={4}>
            <Form.Item name="gender_marker">
              <Radio.Group>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={4}>Resilience</Col>
          <Col span={4}>
            <Form.Item name="resilience_marker">
              <Radio.Group>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={4}>Governance</Col>
          <Col span={4}>
            <Form.Item name="governance_marker">
              <Radio.Group>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={4}>Community</Col>
          <Col span={4}>
            <Form.Item name="community_marker">
              <Radio.Group>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={4}>Engagement</Col>
          <Col span={4}>
            <Form.Item name="engagement_marker">
              <Radio.Group>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Divider className="m-1" />
    </AntdModal>
  );
};

export default ActivityConceptNotesForm;
