import React from "react";
import PrintOutHeader from "../../../components/shared/PrintOutHeader";
import { Table } from "antd";
import PrintOutUserDetails from "../../../components/shared/PrintOutUserDetails";

const PrintNoticeOfBestBidder = ({ data = {} }) => {
  return (
    <div className="print-table">
      <PrintOutHeader />
      <Table
        title={() => (
          <div className="w-100 d-flex justify-content-center">
            <h6 className="fw-bold w-100 text-center my-1">
              NOTICE OF BEST BIDDER
            </h6>
          </div>
        )}
        size="small"
        bordered
        className="my-2"
        showHeader={false}
        pagination={false}
        columns={[
          {
            dataIndex: "title",
            key: "title",
          },
          {
            dataIndex: "value",
            key: "value",
            render: (text) => <strong>{text}</strong>,
          },
        ]}
        dataSource={[
          {
            title: "RFQ No.",
            value: data.rfq,
          },
          {
            title: "SUBJECT OF PROCUREMENT",
            value: data?.subject,
          },
          {
            title: "BEST BIDDER",
            value: data.bestBidder?.name,
          },
          {
            title: "DATE DISPLAYED",
            value: new Date(data.date_displayed).toDateString(),
          },
        ]}
      />
      <PrintOutUserDetails data={data} />
    </div>
  );
};

export default PrintNoticeOfBestBidder;
