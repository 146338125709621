import { Alert, Button, Form, Input, message, Modal } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bidEvaluationsActions } from "../../../../config/actions/procument/bidEvaluations/bidEvaluations.actions";

const ReasonForNonComplaiceForm = ({
  visible,
  handleCloseModal,
  handleEvaluation,
}) => {
  const {
    removeBidderFromEvaluationLoading,
    editEligibilityEvaluationData,
    bidEvaluationsDetails,
    removeBidderFromEvaluationSuccess,
    removeBidderFromEvaluationMessage,
    removeBidderFromEvaluationError,
  } = useSelector((state) => state.bidEvaluationState);
  const closeModal = () => handleCloseModal(false);

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const fields = [
    { name: "bidder", value: editEligibilityEvaluationData.name },
    { name: "complaince", value: editEligibilityEvaluationData.complaince },
    { name: "reason", value: editEligibilityEvaluationData.reason },
  ];
  const onFinish = (values) => {
    values.updated_at = new Date();
    values.bid_evaluation = bidEvaluationsDetails.id;
    values.bidder = editEligibilityEvaluationData.bidder;
    values.id = editEligibilityEvaluationData.id;
    values.name = editEligibilityEvaluationData.name;
    dispatch(bidEvaluationsActions.editEligibilityEvaluationsData(values));
    values.complaince = "Non Compliant";
    handleEvaluation(values);
  };
  useEffect(() => {
    if (
      removeBidderFromEvaluationSuccess &&
      removeBidderFromEvaluationMessage
    ) {
      message.success(removeBidderFromEvaluationMessage);
      handleCloseModal(false);
    } else if (
      !removeBidderFromEvaluationSuccess &&
      removeBidderFromEvaluationError
    ) {
      message.error(removeBidderFromEvaluationError);
    }
  }, [
    handleCloseModal,
    removeBidderFromEvaluationSuccess,
    removeBidderFromEvaluationMessage,
    removeBidderFromEvaluationError,
  ]);
  return (
    <div>
      <Modal
        title={`${editEligibilityEvaluationData.name} Reason For Non Complaince`.toUpperCase()}
        footer={null}
        onCancel={closeModal}
        open={visible}
      >
        <Form form={form} layout="vertical" onFinish={onFinish} fields={fields}>
          {removeBidderFromEvaluationError &&
            !removeBidderFromEvaluationSuccess && (
              <Alert
                type="error"
                message={removeBidderFromEvaluationError}
                showIcon
                className="m-2"
              />
            )}
          <Form.Item
            rules={[
              {
                required: true,
                message: "Please add a reason for non compliance",
              },
            ]}
            label="Reason For Non Complaince"
            name="reason"
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item className={"mt-3"}>
            <Button
              loading={removeBidderFromEvaluationLoading}
              htmlType="submit"
              type="primary"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ReasonForNonComplaiceForm;
