import { Button, Input, Space, Tooltip } from "antd";
import React from "react";
import { FcRefresh } from "react-icons/fc";

const LogDetailsTableTitle = ({ search, setfilterTableNull, refreshTable }) => {
  return (
    <div className="d-flex w-100">
      <Space className="w-25" direction="horizontal">
        <Button onClick={refreshTable}>
          <Space>
            <Tooltip title="refresh">
              <FcRefresh />{" "}
            </Tooltip>
            <span className="d-none d-md-block">Refresh</span>
          </Space>
        </Button>
      </Space>
      <div className="w-75">
        <Input.Search
          onChange={setfilterTableNull}
          placeholder="Search by..."
          enterButton
          onSearch={search}
        ></Input.Search>
      </div>
    </div>
  );
};

export default LogDetailsTableTitle;
