import React from "react";
import appConfig from "../../config/config.json";

const PrintOutUserDetails = ({ data = {} }) => {
  return (
    <div>
      <div className="d-flex">
        <div className="w-50">
          <div className="mt-2">
            <p className="mb-0">
              Prepared By:{" "}
              <span className="fw-bold">
                {data?.creator?.first_name} {data?.creator?.other_names}
              </span>
            </p>
            <p className="mb-0">
              Role: <span className="fw-bold"> {data?.creator?.role}</span>
            </p>
            <p className="mb-0">
              Date Prepared:{" "}
              <span className="fw-bold">
                {new Date(data.created_at).toDateString()}
              </span>
            </p>
            <p className="mb-0">Signature:</p>
            {data?.creator?.signature && (
              <img
                src={`${appConfig.server_url}${data?.creator?.signature}`}
                alt="signature"
                width="30"
                height="15"
              />
            )}
          </div>
        </div>
        <div className="w-50">
          <div className="mt-2">
            <p className="mb-0">
              Checked By:{" "}
              <span className="fw-bold">
                {data?.checker?.first_name} {data?.checker?.other_names}
              </span>
            </p>
            <p className="mb-0">
              Role: <span className="fw-bold">{data?.checker?.role}</span>
            </p>
            <p className="mb-0">
              Date Checked:{" "}
              <span className="fw-bold">
                {new Date(data.checked_at).toDateString()}
              </span>
            </p>
            <p className="mb-0">Signature:</p>
            {data?.checker?.signature && (
              <img
                src={`${appConfig.server_url}${data?.checker?.signature}`}
                alt="signature"
                width="30"
                height="15"
              />
            )}
          </div>
        </div>
      </div>
      <div className="d-flex">
        <div className="w-100">
          <div className="mt-3">
            <p className="mb-0">
              Approved By:{" "}
              <span className="fw-bold">
                {data?.approver?.first_name} {data?.approver?.other_names}
              </span>
            </p>
            <p className="mb-0">
              Role: <span className="fw-bold">{data?.approver?.role}</span>
            </p>
            <p className="mb-0">
              Date Approved:{" "}
              <span className="fw-bold">
                {new Date(data.approved_at).toDateString()}
              </span>
            </p>
            <p className="mb-0">Signature:</p>
            {data?.approver?.signature && (
              <img
                src={`${appConfig.server_url}${data?.approver?.signature}`}
                alt="signature"
                width="30"
                height="15"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrintOutUserDetails;
