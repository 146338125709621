import { Button, message, Popover, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bidEvaluationsActions } from "../../../../config/actions/procument/bidEvaluations/bidEvaluations.actions";
import TableTitle from "../../../shared/Tables/TableTitle";

const EligibilityEvaluationsTable = ({
  handleRefreshTable,
  handleOpenEditModal,
  handleAddComplaince,
}) => {
  const [filterTable, setfilterTable] = useState(null);
  const {
    eligibilityEvaluations: data,
    eligibilityEvaluationsLoading: loading,
    eligibilityEvaluationsSuccess: success,
    eligibilityEvaluationsError: error,
    editEligibilityEvaluationSuccess,
    editEligibilityEvaluationMessage,
    addEligibilityEvaluationsLoading: complianceLoading,
  } = useSelector((state) => state.bidEvaluationState);

  const dispatch = useDispatch();

  const columns = [
    {
      title: "Bidder",
      key: "rfqno",
      render: (text) => (
        <div>
          <span className="d-md-none">Eligibility Criteria:</span> {text.name}
          <br />
          <div className="d-md-none">
            Complaince: {text.complaince} <br />
            <Popover
              className=""
              trigger="click"
              placement="bottom"
              content={() =>
                eligibilityActions(text, {
                  addCompliance,
                  addNonCompliance,
                  complianceLoading,
                })
              }
            >
              Evaluate: <Button type="link">Evaluate</Button>
            </Popover>
          </div>
        </div>
      ),
    },
    {
      title: "Compliance",
      dataIndex: "complaince",
      key: "complaice",
      responsive: ["md"],
    },
    {
      title: "Action",
      key: "operation",
      responsive: ["md"],
      render: (text) => (
        <Popover
          trigger="click"
          placement="bottom"
          content={() =>
            eligibilityActions(text, {
              addCompliance,
              addNonCompliance,
              complianceLoading,
            })
          }
        >
          <Button>Evaluate</Button>
        </Popover>
      ),
    },
  ];
  const eligibilityActions = (
    text,
    { addCompliance, addNonCompliance, complianceLoading }
  ) => {
    const compliance = () => addCompliance(text);
    const nonCompliance = () => addNonCompliance(text);

    return (
      <Space direction="vertical">
        <Button
          type="primary"
          className="w-100"
          onClick={compliance}
          loading={complianceLoading}
        >
          Compliant
        </Button>
        <Button
          type="primary"
          danger
          onClick={nonCompliance}
          loading={complianceLoading}
        >
          Non Compliant
        </Button>
      </Space>
    );
  };
  /* Functions */
  const addCompliance = (values) => {
    values.complaince = "Compliant";
    values.updated_at = new Date();
    handleAddComplaince(values);
  };
  const addNonCompliance = (values) => {
    values.updated_at = new Date();
    dispatch(bidEvaluationsActions.editEligibilityEvaluationsData(values));
    handleOpenEditModal(true);
  };
  const refreshTable = () => {
    handleRefreshTable();
    if (!success && error) message.error(error);
  };

  const search = (value) => {
    const filterTable = data.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(value.toLowerCase())
      )
    );
    setfilterTable(filterTable);
  };
  const setfilterTableNull = (e) => {
    if (!e.target.value) setfilterTable(null);
  };

  const showTotal = (total) => `Total: ${total}`;
  const openAddModal = () => {
    message.info("Request Unavailable");
  };
  const exportRecords = () => {
    message.info("Export Unavailable");
  };

  /* Use effect */
  useEffect(() => {
    if (editEligibilityEvaluationSuccess && editEligibilityEvaluationMessage) {
      message.success(editEligibilityEvaluationMessage);
    }
  }, [editEligibilityEvaluationSuccess, editEligibilityEvaluationMessage]);

  return (
    <div>
      <Table
        pagination={{ total: data.length, showSizeChanger: true, showTotal }}
        loading={loading}
        title={() =>
          TableTitle({
            search,
            setfilterTableNull,
            refreshTable,
            exportRecords,
            openAddModal,
          })
        }
        bordered={true}
        dataSource={filterTable === null ? data : filterTable}
        columns={columns}
      />
    </div>
  );
};

export default EligibilityEvaluationsTable;
