export const requisitionMemosConstants = {
  SET_REQUISTION_MEMOS_LOADING: "SET_REQUISTION_MEMOS_LOADING",
  SET_REQUISTION_MEMOS_SUCCESS: "SET_REQUISTION_MEMOS_SUCCESS",
  SET_REQUISTION_MEMOS_ERROR: "SET_REQUISTION_MEMOS_ERROR",

  SET_PROJECT_REQUISTION_MEMOS_LOADING: "SET_PROJECT_REQUISTION_MEMOS_LOADING",
  SET_PROJECT_REQUISTION_MEMOS_SUCCESS: "SET_PROJECT_REQUISTION_MEMOS_SUCCESS",
  SET_PROJECT_REQUISTION_MEMOS_ERROR: "SET_PROJECT_REQUISTION_MEMOS_ERROR",

  SET_REQUISTION_MEMO_DETAILS_LOADING: "SET_REQUISTION_MEMO_DETAILS_LOADING",
  SET_REQUISTION_MEMO_DETAILS_SUCCESS: "SET_REQUISTION_MEMO_DETAILS_SUCCESS",
  SET_REQUISTION_MEMO_DETAILS_ERROR: "SET_REQUISTION_MEMO_DETAILS_ERROR",

  SET_REQUISTION_MEMO_ACTIVITY_DETAILS_LOADING:
    "SET_REQUISTION_MEMO_ACTIVITY_DETAILS_LOADING",
  SET_REQUISTION_MEMO_ACTIVITY_DETAILS_SUCCESS:
    "SET_REQUISTION_MEMO_ACTIVITY_DETAILS_SUCCESS",
  SET_REQUISTION_MEMO_ACTIVITY_DETAILS_ERROR:
    "SET_REQUISTION_MEMO_ACTIVITY_DETAILS_ERROR",

  ADD_REQUISTION_MEMO_LOADING: "ADD_REQUISTION_MEMO_LOADING",
  ADD_REQUISTION_MEMO_ERROR: "ADD_REQUISTION_MEMO_ERROR",
  ADD_REQUISTION_MEMO_SUCCESS: "ADD_REQUISTION_MEMO_SUCCESS",
  ADD_REQUISTION_MEMO_COMPLETE: "ADD_REQUISTION_MEMO_COMPLETE",

  ADD_REQUISTION_MEMO_ACTIVITY_LOADING: "ADD_REQUISTION_MEMO_ACTIVITY_LOADING",
  ADD_REQUISTION_MEMO_ACTIVITY_ERROR: "ADD_REQUISTION_MEMO_ACTIVITY_ERROR",
  ADD_REQUISTION_MEMO_ACTIVITY_SUCCESS: "ADD_REQUISTION_MEMO_ACTIVITY_SUCCESS",
  ADD_REQUISTION_MEMO_ACTIVITY_COMPLETE:
    "ADD_REQUISTION_MEMO_ACTIVITY_COMPLETE",

  EDIT_REQUISTION_MEMO_ACTIVITY_LOADING:
    "EDIT_REQUISTION_MEMO_ACTIVITY_LOADING",
  EDIT_REQUISTION_MEMO_ACTIVITY_SUCCESS:
    "EDIT_REQUISTION_MEMO_ACTIVITY_SUCCESS",
  EDIT_REQUISTION_MEMO_ACTIVITY_ERROR: "EDIT_REQUISTION_MEMO_ACTIVITY_ERROR",
  EDIT_REQUISTION_MEMO_ACTIVITY_COMPLETE:
    "EDIT_REQUISTION_MEMO_ACTIVITY_COMPLETE",
  EDIT_REQUISTION_MEMO_ACTIVITY_DATA: "EDIT_REQUISTION_MEMO_ACTIVITY_DATA",

  EDIT_REQUISTION_MEMO_LOADING: "EDIT_REQUISTION_MEMO_LOADING",
  EDIT_REQUISTION_MEMO_SUCCESS: "EDIT_REQUISTION_MEMO_SUCCESS",
  EDIT_REQUISTION_MEMO_ERROR: "EDIT_REQUISTION_MEMO_ERROR",
  EDIT_REQUISTION_MEMO_COMPLETE: "EDIT_REQUISTION_MEMO_COMPLETE",
  EDIT_REQUISTION_MEMO_DATA: "EDIT_REQUISTION_MEMO_DATA",

  DELETE_REQUISTION_MEMO_LOADING: "DELETE_REQUISTION_MEMO_LOADING",
  DELETE_REQUISTION_MEMO_SUCCESS: "DELETE_REQUISTION_MEMO_SUCCESS",
  DELETE_REQUISTION_MEMO_ERROR: "DELETE_REQUISTION_MEMO_ERROR",
  DELETE_REQUISTION_MEMO_COMPLETE: "DELETE_REQUISTION_MEMO_COMPLETE",

  DELETE_REQUISTION_MEMO_ACTIVITY_LOADING:
    "DELETE_REQUISTION_MEMO_ACTIVITY_LOADING",
  DELETE_REQUISTION_MEMO_ACTIVITY_SUCCESS:
    "DELETE_REQUISTION_MEMO_ACTIVITY_SUCCESS",
  DELETE_REQUISTION_MEMO_ACTIVITY_ERROR:
    "DELETE_REQUISTION_MEMO_ACTIVITY_ERROR",
  DELETE_REQUISTION_MEMO_ACTIVITY_COMPLETE:
    "DELETE_REQUISTION_MEMO_ACTIVITY_COMPLETE",

  APPROVE_REQUISTION_MEMO_LOADING: "APPROVE_REQUISTION_MEMO_LOADING",
  APPROVE_REQUISTION_MEMO_SUCCESS: "APPROVE_REQUISTION_MEMO_SUCCESS",
  APPROVE_REQUISTION_MEMO_ERROR: "APPROVE_REQUISTION_MEMO_ERROR",
  APPROVE_REQUISTION_MEMO_COMPLETE: "APPROVE_REQUISTION_MEMO_COMPLETE",

  CHECK_REQUISTION_MEMO_LOADING: "CHECK_REQUISTION_MEMO_LOADING",
  CHECK_REQUISTION_MEMO_SUCCESS: "CHECK_REQUISTION_MEMO_SUCCESS",
  CHECK_REQUISTION_MEMO_ERROR: "CHECK_REQUISTION_MEMO_ERROR",
  CHECK_REQUISTION_MEMO_COMPLETE: "CHECK_REQUISTION_MEMO_COMPLETE",

  SUBMIT_REQUISTION_MEMO_LOADING: "SUBMIT_REQUISTION_MEMO_LOADING",
  SUBMIT_REQUISTION_MEMO_SUCCESS: "SUBMIT_REQUISTION_MEMO_SUCCESS",
  SUBMIT_REQUISTION_MEMO_ERROR: "SUBMIT_REQUISTION_MEMO_ERROR",
  SUBMIT_REQUISTION_MEMO_COMPLETE: "SUBMIT_REQUISTION_MEMO_COMPLETE",

  META_DATA: "META_DATA",
  META_DATA_LOADING: "META_DATA_LOADING",
};

export const requisitionMemosActions = {
  setRequisitionMemosLoading: () => ({
    type: requisitionMemosConstants.SET_REQUISTION_MEMOS_LOADING,
  }),
  setRequisitionMemosSuccess: (payload) => ({
    type: requisitionMemosConstants.SET_REQUISTION_MEMOS_SUCCESS,
    payload,
  }),
  setRequisitionMemosError: (payload) => ({
    type: requisitionMemosConstants.SET_REQUISTION_MEMOS_ERROR,
    payload,
  }),
  setProjectRequisitionMemosLoading: () => ({
    type: requisitionMemosConstants.SET_PROJECT_REQUISTION_MEMOS_LOADING,
  }),
  setProjectRequisitionMemosSuccess: (payload) => ({
    type: requisitionMemosConstants.SET_PROJECT_REQUISTION_MEMOS_SUCCESS,
    payload,
  }),
  setProjectRequisitionMemosError: (payload) => ({
    type: requisitionMemosConstants.SET_PROJECT_REQUISTION_MEMOS_ERROR,
    payload,
  }),
  setRequisitionMemosDetailsLoading: () => ({
    type: requisitionMemosConstants.SET_REQUISTION_MEMO_DETAILS_LOADING,
  }),
  setRequisitionMemosDetailsSucess: (payload) => ({
    type: requisitionMemosConstants.SET_REQUISTION_MEMO_DETAILS_SUCCESS,
    payload,
  }),
  setRequisitionMemosDetailsError: (payload) => ({
    type: requisitionMemosConstants.SET_REQUISTION_MEMO_DETAILS_ERROR,
    payload,
  }),
  setRequisitionMemosActivityDetailsLoading: () => ({
    type: requisitionMemosConstants.SET_REQUISTION_MEMO_ACTIVITY_DETAILS_LOADING,
  }),
  setRequisitionMemosActivityDetailsSucess: (payload) => ({
    type: requisitionMemosConstants.SET_REQUISTION_MEMO_ACTIVITY_DETAILS_SUCCESS,
    payload,
  }),
  setRequisitionMemosActivityDetailsError: (payload) => ({
    type: requisitionMemosConstants.SET_REQUISTION_MEMO_ACTIVITY_DETAILS_ERROR,
    payload,
  }),
  addRequisitionMemosLoading: () => ({
    type: requisitionMemosConstants.ADD_REQUISTION_MEMO_LOADING,
  }),
  addRequisitionMemosSuccess: (payload) => ({
    type: requisitionMemosConstants.ADD_REQUISTION_MEMO_SUCCESS,
    payload,
  }),
  addRequisitionMemosError: (payload) => ({
    type: requisitionMemosConstants.ADD_REQUISTION_MEMO_ERROR,
    payload,
  }),
  addRequisitionMemosComplete: () => ({
    type: requisitionMemosConstants.ADD_REQUISTION_MEMO_COMPLETE,
  }),
  addRequisitionMemosActivityLoading: () => ({
    type: requisitionMemosConstants.ADD_REQUISTION_MEMO_ACTIVITY_LOADING,
  }),
  addRequisitionMemosActivitySuccess: (payload) => ({
    type: requisitionMemosConstants.ADD_REQUISTION_MEMO_ACTIVITY_SUCCESS,
    payload,
  }),
  addRequisitionMemosActivityError: (payload) => ({
    type: requisitionMemosConstants.ADD_REQUISTION_MEMO_ACTIVITY_ERROR,
    payload,
  }),
  addRequisitionMemosActivityComplete: () => ({
    type: requisitionMemosConstants.ADD_REQUISTION_MEMO_ACTIVITY_COMPLETE,
  }),
  editRequisitionMemosAcitvityLoading: () => ({
    type: requisitionMemosConstants.EDIT_REQUISTION_MEMO_ACTIVITY_LOADING,
  }),
  editRequisitionMemosAcitvitySuccess: (payload) => ({
    type: requisitionMemosConstants.EDIT_REQUISTION_MEMO_ACTIVITY_SUCCESS,
    payload,
  }),
  editRequisitionMemosAcitvityError: (payload) => ({
    type: requisitionMemosConstants.EDIT_REQUISTION_MEMO_ACTIVITY_ERROR,
    payload,
  }),
  editRequisitionMemosAcitvityComplete: () => ({
    type: requisitionMemosConstants.EDIT_REQUISTION_MEMO_ACTIVITY_COMPLETE,
  }),
  editRequisitionMemosAcitvityData: (payload) => ({
    type: requisitionMemosConstants.EDIT_REQUISTION_MEMO_ACTIVITY_DATA,
    payload,
  }),
  editRequisitionMemosLoading: () => ({
    type: requisitionMemosConstants.EDIT_REQUISTION_MEMO_LOADING,
  }),
  editRequisitionMemosSuccess: (payload) => ({
    type: requisitionMemosConstants.EDIT_REQUISTION_MEMO_SUCCESS,
    payload,
  }),
  editRequisitionMemosError: (payload) => ({
    type: requisitionMemosConstants.EDIT_REQUISTION_MEMO_ERROR,
    payload,
  }),
  editRequisitionMemosComplete: () => ({
    type: requisitionMemosConstants.EDIT_REQUISTION_MEMO_COMPLETE,
  }),
  editRequisitionMemosData: (payload) => ({
    type: requisitionMemosConstants.EDIT_REQUISTION_MEMO_DATA,
    payload,
  }),
  deleteRequisitionMemosLoading: () => ({
    type: requisitionMemosConstants.DELETE_REQUISTION_MEMO_LOADING,
  }),
  deleteRequisitionMemosSuccess: (payload) => ({
    type: requisitionMemosConstants.DELETE_REQUISTION_MEMO_SUCCESS,
    payload,
  }),
  deleteRequisitionMemosError: (payload) => ({
    type: requisitionMemosConstants.DELETE_REQUISTION_MEMO_ERROR,
    payload,
  }),
  deleteRequisitionMemosComplete: () => ({
    type: requisitionMemosConstants.DELETE_REQUISTION_MEMO_COMPLETE,
  }),
  deleteRequisitionMemosActivityLoading: () => ({
    type: requisitionMemosConstants.DELETE_REQUISTION_MEMO_ACTIVITY_LOADING,
  }),
  deleteRequisitionMemosActivitySuccess: (payload) => ({
    type: requisitionMemosConstants.DELETE_REQUISTION_MEMO_ACTIVITY_SUCCESS,
    payload,
  }),
  deleteRequisitionMemosActivityError: (payload) => ({
    type: requisitionMemosConstants.DELETE_REQUISTION_MEMO_ACTIVITY_ERROR,
    payload,
  }),
  deleteRequisitionMemosActivityComplete: () => ({
    type: requisitionMemosConstants.DELETE_REQUISTION_MEMO_ACTIVITY_COMPLETE,
  }),
  approveRequisitionMemosLoading: () => ({
    type: requisitionMemosConstants.APPROVE_REQUISTION_MEMO_LOADING,
  }),
  approveRequisitionMemosSuccess: (payload) => ({
    type: requisitionMemosConstants.APPROVE_REQUISTION_MEMO_SUCCESS,
    payload,
  }),
  approveRequisitionMemosError: (payload) => ({
    type: requisitionMemosConstants.APPROVE_REQUISTION_MEMO_ERROR,
    payload,
  }),
  approveRequisitionMemosComplete: (payload) => ({
    type: requisitionMemosConstants.APPROVE_REQUISTION_MEMO_COMPLETE,
    payload,
  }),
  checkRequisitionMemosLoading: () => ({
    type: requisitionMemosConstants.CHECK_REQUISTION_MEMO_LOADING,
  }),
  checkRequisitionMemosSuccess: (payload) => ({
    type: requisitionMemosConstants.CHECK_REQUISTION_MEMO_SUCCESS,
    payload,
  }),
  checkRequisitionMemosError: (payload) => ({
    type: requisitionMemosConstants.CHECK_REQUISTION_MEMO_ERROR,
    payload,
  }),
  checkRequisitionMemosComplete: (payload) => ({
    type: requisitionMemosConstants.CHECK_REQUISTION_MEMO_COMPLETE,
    payload,
  }),
  submitRequisitionMemosLoading: () => ({
    type: requisitionMemosConstants.SUBMIT_REQUISTION_MEMO_LOADING,
  }),
  submitRequisitionMemosSuccess: (payload) => ({
    type: requisitionMemosConstants.SUBMIT_REQUISTION_MEMO_SUCCESS,
    payload,
  }),
  submitRequisitionMemosError: (payload) => ({
    type: requisitionMemosConstants.SUBMIT_REQUISTION_MEMO_ERROR,
    payload,
  }),
  submitRequisitionMemosComplete: (payload) => ({
    type: requisitionMemosConstants.SUBMIT_REQUISTION_MEMO_COMPLETE,
    payload,
  }),
  metaData: (payload) => ({
    type: requisitionMemosConstants.META_DATA,
    payload,
  }),
  metaDataLoading: () => ({
    type: requisitionMemosConstants.META_DATA_LOADING,
  }),
};
