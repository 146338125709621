import { Alert, Button, message, Modal, Space, Spin, Tabs } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LocalPurchaseOrdersItemsTable from "../Tables/LocalPurchaseOrdersItemsTable";
import LocalPurchaseOrderOtherDetails from "./LocalPurchaseOrderOtherDetails";
import appConfig from "../../../../config/config.json";
import { rejectionsActions } from "../../../../config/actions/rejections/rejections.actions";
import generateLocalPurchaseOrder from "../../../../config/services/lpoPdf";

const LocalPurchaseOrderDetails = ({
  visible,
  handleCloseModal,
  handleOpenAddModal,
  handleRefreshLpoItems,
  handleOpenEditModal,
  deleteLocalPurchaseOrderItem,
  handleViewItemDetails,
  handleApprove,
  handleSubmit,
  handleCheck,
  handleRejection,
}) => {
  const {
    localPurchaseOrdersDetails,
    localPurchaseOrdersDetailsSuccess,
    localPurchaseOrdersDetailsLoading,
    localPurchaseOrdersDetailsError,
    approveLocalPurchaseOrdersLoading,
    approveLocalPurchaseOrdersError,
    approveLocalPurchaseOrdersSuccess,
    approveLocalPurchaseOrdersMessage,
    checkLocalPurchaseOrdersLoading,
    checkLocalPurchaseOrdersSuccess,
    checkLocalPurchaseOrdersMessage,
    checkLocalPurchaseOrdersError,
    submitLocalPurchaseOrdersLoading,
    submitLocalPurchaseOrdersSuccess,
    submitLocalPurchaseOrdersMessage,
    submitLocalPurchaseOrdersError,
    lpoItems,
  } = useSelector((state) => state.localPurchaseOrdersState);
  const dispatch = useDispatch();

  const closeModal = () => handleCloseModal(false);
  const refresh = () => handleRefreshLpoItems(localPurchaseOrdersDetails.id);
  const approve = () => handleApprove(localPurchaseOrdersDetails.id);
  const check = () => handleCheck(localPurchaseOrdersDetails.id);
  const submit = () => handleSubmit(localPurchaseOrdersDetails.id);
  const reject = () => {
    handleRejection(true);
    dispatch(
      rejectionsActions.addProcurementRejectionsData({
        user_to_id: localPurchaseOrdersDetails?.created_by,
        item_application: "Local Purchase Orders",
        item_id: localPurchaseOrdersDetails?.id,
        itemName: `Local Purchase Order ${localPurchaseOrdersDetails.id}`,
        applicationPath: "localPurchaseOrders",
      })
    );
  };
  const generatePdf = () => {
    localPurchaseOrdersDetails.lpoItems = lpoItems;
    localPurchaseOrdersDetails.status === appConfig.status.approved
      ? generateLocalPurchaseOrder(localPurchaseOrdersDetails, "U")
      : message.info("Funds Advance/Withdraw Form has not yet been approved");
  };

  useEffect(() => {
    if (approveLocalPurchaseOrdersSuccess && approveLocalPurchaseOrdersMessage)
      message.success(approveLocalPurchaseOrdersMessage);
    else if (
      !approveLocalPurchaseOrdersSuccess &&
      approveLocalPurchaseOrdersError
    )
      message.error(approveLocalPurchaseOrdersError);
    if (submitLocalPurchaseOrdersSuccess && submitLocalPurchaseOrdersMessage)
      message.success(submitLocalPurchaseOrdersMessage);
    else if (
      !submitLocalPurchaseOrdersSuccess &&
      submitLocalPurchaseOrdersError
    )
      message.error(submitLocalPurchaseOrdersError);
    if (checkLocalPurchaseOrdersSuccess && checkLocalPurchaseOrdersMessage)
      message.success(checkLocalPurchaseOrdersMessage);
    else if (!checkLocalPurchaseOrdersSuccess && checkLocalPurchaseOrdersError)
      message.error(checkLocalPurchaseOrdersError);
  }, [
    approveLocalPurchaseOrdersError,
    approveLocalPurchaseOrdersMessage,
    approveLocalPurchaseOrdersSuccess,
    checkLocalPurchaseOrdersError,
    checkLocalPurchaseOrdersMessage,
    checkLocalPurchaseOrdersSuccess,
    submitLocalPurchaseOrdersError,
    submitLocalPurchaseOrdersMessage,
    submitLocalPurchaseOrdersSuccess,
  ]);
  return (
    <div>
      <Modal
        zIndex={500}
        width={800}
        open={visible}
        onCancel={closeModal}
        title={`LOCAL PURCHASE ORDER ${localPurchaseOrdersDetails.id} DETAILS`}
        footer={[
          localPurchaseOrdersDetails.status !== appConfig.status.rejected &&
            localPurchaseOrdersDetails.status !== appConfig.status.approved && (
              <Space>
                {localPurchaseOrdersDetails.submitted && (
                  <Space>
                    <Button
                      onClick={approve}
                      loading={approveLocalPurchaseOrdersLoading}
                      type="primary"
                    >
                      Approve
                    </Button>
                    {localPurchaseOrdersDetails.status !==
                      appConfig.status.checked && (
                      <Button
                        onClick={check}
                        loading={checkLocalPurchaseOrdersLoading}
                      >
                        Check
                      </Button>
                    )}
                    <Button onClick={reject} danger>
                      Reject
                    </Button>
                  </Space>
                )}
                {!localPurchaseOrdersDetails.submitted && (
                  <Button
                    loading={submitLocalPurchaseOrdersLoading}
                    className="bg-light"
                    type="dashed"
                    onClick={submit}
                  >
                    Submit
                  </Button>
                )}
              </Space>
            ),
        ]}
      >
        {localPurchaseOrdersDetailsLoading ? (
          <div className="d-flex justify-content-center m-2">
            <Spin size="large" />
          </div>
        ) : (
          <div>
            {localPurchaseOrdersDetailsSuccess ? (
              <div>
                <Tabs defaultActiveKey="1">
                  <Tabs.TabPane key="1" tab="Local Purchase Order">
                    <LocalPurchaseOrdersItemsTable
                      handleViewDetails={handleViewItemDetails}
                      handleExportRecords={generatePdf}
                      handleOpenEditModal={handleOpenEditModal}
                      handleDelete={deleteLocalPurchaseOrderItem}
                      handleRefreshTable={refresh}
                      handleOpenAddModal={handleOpenAddModal}
                    />
                  </Tabs.TabPane>
                  <Tabs.TabPane key="2" tab="Other Details">
                    <LocalPurchaseOrderOtherDetails
                      data={localPurchaseOrdersDetails}
                    />
                  </Tabs.TabPane>
                </Tabs>
              </div>
            ) : (
              <Alert
                className="m-2"
                type="error"
                showIcon
                message={localPurchaseOrdersDetailsError}
              />
            )}
          </div>
        )}
      </Modal>
    </div>
  );
};

export default LocalPurchaseOrderDetails;
