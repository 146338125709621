import {
  Modal,
  Form,
  Button,
  Select,
  Alert,
  Input,
  DatePicker,
  message,
} from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

const { Option } = Select;
const AddRfq = ({ visible, handleCloseModal, handleAddRecord }) => {
  const {
    addRfqsLoading,
    addRfqsMessage,
    addRfqsSuccess,
    addRfqsError,
    rfqsMetaDataLoading,
    rfqsMetaData,
    rfqs,
  } = useSelector((state) => state.rfqsState);
  const closeModal = () => handleCloseModal(false);
  const [form] = Form.useForm();

  const onFinish = (values) => {
    values.created_at = new Date();
    handleAddRecord(values, rfqs);
  };

  useEffect(() => {
    if (addRfqsSuccess && addRfqsMessage) {
      message.success(addRfqsMessage);
      form.resetFields();
    }
  }, [addRfqsMessage, addRfqsSuccess, form]);
  return (
    <div>
      <Modal
        open={visible}
        title="GENERATE RFQ NUMBER"
        footer={null}
        onCancel={closeModal}
      >
        <Form
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 16 }}
          validateTrigger="onBlur"
          layout="horizontal"
          form={form}
          onFinish={onFinish}
        >
          {!addRfqsSuccess && addRfqsError && (
            <Alert type="error" showIcon message={addRfqsError} />
          )}
          <Form.Item
            label="RFQ No."
            rules={[{ required: true, message: "Please add an Rfq No." }]}
            name="rfqno"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Deadline Of Submission"
            rules={[{ required: true, message: "Please add an Rfq No." }]}
            name="deadline_of_submission"
          >
            <DatePicker className="w-100" />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: "Please select a requsition memo" },
            ]}
            label="Requisition Form"
            name="requisition_memo"
          >
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              loading={rfqsMetaDataLoading}
            >
              {rfqsMetaData?.requisitionMemos?.map((memo) => (
                <Option key={memo.memo_number} value={memo.memo_number}>
                  {memo.memo_number}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Subject Of Procurement"
            name="subject"
            rules={[{ required: true, message: "Please add a subject" }]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item className={"mt-3"} wrapperCol={{ offset: 8, span: 16 }}>
            <Button loading={addRfqsLoading} htmlType="submit" type="primary">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddRfq;
