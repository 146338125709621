const initialState = {
  getRoles: {
    loading: true,
    success: false,
    error: "",
    data: [],
  },
  addNewRole: {
    loading: false,
    success: false,
    error: "",
    data: null,
  },
  deleteRole: {
    loading: false,
    success: false,
    error: "",
  },
  updateRole: {
    loading: false,
    success: false,
    error: "",
    data: {},
  },
  getSingleRole: {
    loading: false,
    success: false,
    error: "",
    data: {},
  },
  approveRoles: {
    loading: false,
    success: false,
    error: "",
    message: "",
  },
  checkRoles: {
    loading: false,
    success: false,
    error: "",
    message: "",
  },
};

export default initialState;
