import {
  Button,
  message,
  Modal,
  Popconfirm,
  Popover,
  Space,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";
import { assetRegisterActions } from "../../../../config/actions/administration/assetRegister/assetRegister.actions";
import AssetsTypesTableTitle from "./AssetTypesTableTitle";
import generatePDF from "../../../../config/services/generatePdf";
import tableButtonActions from "../../../shared/Tables/TableButtonActions";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 16, color: "#fff" }} spin />
);

const AssetTypesTable = ({
  handleDeleteAssetTypes,
  handleRefreshTable,
  handleViewDetails,
  handleExportRecords,
}) => {
  const [deleteId, setDeleteId] = useState("");
  const visible = useSelector(
    (state) => state.AppUi.admisnistration.assetsRegister.assetTypesModal
  );
  const deleteLoading = useSelector(
    (state) => state.assetRegisterState.deleteAssetTypes.loading
  );
  const { loading, data } = useSelector(
    (state) => state.assetRegisterState.fetchAssetCategory
  );
  const {
    success: logDetailsSuccess,
    error: logDetailsError,
    records,
  } = useSelector((state) => state.logDetailsState.addLogDetails);
  const [filterTable, setfilterTable] = useState(null);
  const dispatch = useDispatch();
  /** functions */
  const exportRecords = () => {
    message.loading("Proccessing document for export please wait ", 3);
    handleExportRecords();
  };
  const search = (value) => {
    const filterTable = data.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(value.toLowerCase())
      )
    );
    setfilterTable(filterTable);
  };
  const deleteAssets = (e) => setDeleteId(e.target.id);
  const confirmDelete = () => handleDeleteAssetTypes(deleteId);
  const viewAssetDetails = (e) => handleViewDetails(e.target.id);

  const updateRecord = (e) => {
    const updateRecordArray = data.filter((r) => r.id === e.target.id);
    dispatch(
      assetRegisterActions.setUpdateAssetCategoryData(updateRecordArray[0])
    );
    dispatch(appUiActions.toggleEditAssetTypesModal(true));
  };
  const generateRecordsPdf = () => {
    const pdfColumns = ["Asset Type", "Depreciation"];
    let pdfRows = [];
    data.forEach((record) => {
      const row = [record.name, record.depreciation];
      pdfRows.push(row);
    });
    generatePDF(
      pdfRows,
      pdfColumns,
      `Amani Initiative Asset Types - ${new Date().toLocaleDateString()} `,
      "Amani Initiative Asset Types.pdf"
    );
  };
  const closeAssetTypesModal = () =>
    dispatch(appUiActions.toggleAssetTypesModal(false));
  /** use effects */
  useEffect(() => {
    if (logDetailsSuccess && records === "assetTypes") {
      message.info("Started download");
      generateRecordsPdf();
    } else if (!logDetailsSuccess && logDetailsError)
      message.error(logDetailsError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logDetailsSuccess, logDetailsError]);
  const columns = [
    {
      title: "Asset Type ",
      key: "name",
      render: (text) => (
        <div>
          <span className="d-md-none">Asset Type:</span> {text.name} <br />
          <div className="d-md-none">
            Depreciation: {text.depreciation} % <br />
            <Popover
              className=""
              trigger="click"
              placement="bottom"
              content={() => buttonActions(text)}
            >
              Actions: <Button type="link">Actions</Button>
            </Popover>
          </div>
        </div>
      ),
    },
    {
      title: "Depreciation %",
      dataIndex: "depreciation",
      key: "depreciation",
      responsive: ["md"],
    },
    {
      title: "Action",
      key: "operation",
      responsive: ["md"],
      render: (text) => (
        <Popover
          trigger="click"
          placement="bottom"
          content={() =>
            tableButtonActions(text, {
              viewDetails: viewAssetDetails,
              updateRecord,
              confirmDelete,
              deleteRecord: deleteAssets,
              deleteLoading,
              deleteId,
            })
          }
        >
          <Button>Actions</Button>
        </Popover>
      ),
    },
  ];

  const buttonActions = (text) => (
    <Space direction="vertical">
      <button
        onClick={viewAssetDetails}
        id={text?.id}
        className="w-100 ant-btn"
      >
        More
      </button>
      <button
        onClick={updateRecord}
        id={text?.id}
        className="ant-btn ant-btn-primary w-100"
      >
        Edit
      </button>
      <Popconfirm
        onConfirm={confirmDelete}
        title="Are you sure you want to delete this ?"
      >
        <button
          className="ant-btn ant-btn-primary ant-btn-dangerous"
          onClick={deleteAssets}
          id={text?.id}
        >
          {deleteLoading && deleteId === text?.id && (
            <Spin indicator={antIcon} />
          )}
          &nbsp; Delete
        </button>
      </Popconfirm>
    </Space>
  );
  const setfilterTableNull = (e) => {
    if (!e.target.value) setfilterTable(null);
  };

  const refreshTable = () => {
    handleRefreshTable();
  };
  const showTotal = (total) => `Total: ${total}`;

  return (
    <div className="">
      <Modal
        title="ASSET TYPES"
        onCancel={closeAssetTypesModal}
        open={visible}
        footer={null}
        width={800}
      >
        <Table
          pagination={{ total: data.length, showSizeChanger: true, showTotal }}
          loading={loading}
          title={() =>
            AssetsTypesTableTitle({
              search,
              setfilterTableNull,
              refreshTable,
              exportRecords,
            })
          }
          bordered={true}
          dataSource={filterTable === null ? data : filterTable}
          columns={columns}
        />
      </Modal>
    </div>
  );
};

export default AssetTypesTable;
