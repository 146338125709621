const intialState = {
  currentUserInfo: { loading: true, success: false, error: "", data: {} },
  profile: {
    loading: false,
    success: false,
    error: "",
    data: {
      userProfile: {
        first_name: "",
        other_names: "",
        gender: "",
        email: "",
        img_url: "",
        role: "",
        contact: "",
        department: "",
      },
    },
  },
  changePassword: {
    loading: false,
    success: false,
    error: "",
    message: "",
  },
};

export default intialState;
