import { dashboardDataConstants } from "../../actions/dashBoard/dashboard.actions";
import initialState from "../../initialState/dashboard/dashboard";

const dashboardState = (state = initialState, { type, payload }) => {
  switch (type) {
    case dashboardDataConstants.SET_DASHBOARD_DATA_REQUEST:
      return {
        ...state,
        dashboardDataLoading: true,
      };
    case dashboardDataConstants.SET_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        dashboardDataLoading: false,
        dashboardDataSuccess: true,
        dashboardData: payload,
      };
    case dashboardDataConstants.SET_DASHBOARD_DATA_ERROR:
      return {
        ...state,
        dashboardDataLoading: false,
        dashboardDataSuccess: false,
        dashboardDataError: payload,
      };
    default:
      return state;
  }
};
export default dashboardState;
