import { Button, Modal, Space, Spin, Tag } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";
import { undefinedRecordNames } from "../../../../config/helpers/app/undefinedRecords";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import appConfig from "../../../../config/config.json";
import manualsAndPoliciesThunks from "../../../../config/thunks/administration/manualsAndPolicies/manualsAndPolicies.thunks";
import { rejectionsActions } from "../../../../config/actions/rejections/rejections.actions";

const ManualsAndPoliciesDetails = ({ handleApprove, handleCheck }) => {
  const { loading, error, success, data } = useSelector(
    (state) => state.manualsAndPoliciesState.manualsAndPoliciesDetails
  );
  const visible = useSelector(
    (state) =>
      state.AppUi.admisnistration.manualsAndPolicies
        .manualsAndPoliciesDetailsModal
  );
  const { loading: approveLoading } = useSelector(
    (state) => state.manualsAndPoliciesState.approveManualsAndPolicies
  );
  const { loading: checkLoading } = useSelector(
    (state) => state.manualsAndPoliciesState.checkManualsAndPolicies
  );
  const dispatch = useDispatch();
  const checkRecord = () => handleCheck(data.id);
  const approveRecord = () => handleApprove(data.id);
  const closeModal = () => {
    dispatch(appUiActions.toggleManualsAndPoliciesDetailsModal(false));
  };
  const downloadPdf = () =>
    dispatch(
      manualsAndPoliciesThunks.downloadManualsAndPolicies(data.id, data.name)
    );
  const reject = () => {
    dispatch(
      rejectionsActions.toggleRejectionsModal({
        visible: true,
        data: {
          user_to_id: data?.created_by,
          item_application: "Manuals And Policies",
          item_id: data?.id,
          itemName: data?.name,
        },
      })
    );
  };
  return (
    <div>
      <Modal
        footer={[
          data.status !== appConfig.status.approved && success && (
            <Space>
              <Button
                onClick={approveRecord}
                loading={approveLoading}
                type="primary"
              >
                Approve
              </Button>
              {data.status !== appConfig.status.checked && (
                <Button onClick={checkRecord} loading={checkLoading}>
                  Check
                </Button>
              )}
              <Button onClick={reject} danger>
                Reject
              </Button>
            </Space>
          ),
          data.status === appConfig.status.approved && success && (
            <Button onClick={downloadPdf} className="bg-light text-dark m-1">
              Download
            </Button>
          ),
        ]}
        onCancel={closeModal}
        title="DOCUMENT DETAILS"
        open={visible}
      >
        {loading ? (
          <div>
            <Spin />
          </div>
        ) : (
          <>
            {!success && error ? (
              <div></div>
            ) : (
              <div className="details_div">
                <table className="table table-bordered table-hover">
                  <thead>
                    <tr>
                      <th scope="col">Column</th>
                      <th scope="col">Data</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>{data.name}</td>
                    </tr>
                    <tr>
                      <td>Status</td>
                      <td>
                        {data.status === appConfig.status.approved && (
                          <Tag icon={<CheckCircleOutlined />} color="success">
                            {data.status}
                          </Tag>
                        )}
                        {data.status === appConfig.status.checked && (
                          <Tag
                            icon={<MinusCircleOutlined />}
                            color="processing"
                          >
                            {data.status}
                          </Tag>
                        )}
                        {data.status === appConfig.status.created && (
                          <Tag
                            icon={<ExclamationCircleOutlined />}
                            color="warning"
                          >
                            Pending
                          </Tag>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Created By</td>
                      <td>{`${undefinedRecordNames(
                        data,
                        "creator",
                        "first_name"
                      )} ${undefinedRecordNames(
                        data,
                        "creator",
                        "other_names"
                      )}`}</td>
                    </tr>
                    <tr>
                      <td>Created At</td>
                      <td>
                        {`${new Date(
                          data.created_at
                        ).toDateString()} ${new Date(
                          data.created_at
                        ).toLocaleTimeString()}
                        `}
                      </td>
                    </tr>
                    <tr>
                      <td>Last Updated By</td>
                      <td>
                        {`${undefinedRecordNames(
                          data,
                          "updator",
                          "first_name"
                        )} ${undefinedRecordNames(
                          data,
                          "updator",
                          "other_names"
                        )}`}
                      </td>
                    </tr>
                    <tr>
                      <td>Last Updated At</td>
                      <td>
                        {data.updated_at &&
                          `${new Date(
                            data.updated_at
                          ).toDateString()} ${new Date(
                            data.updated_at
                          ).toLocaleTimeString()}`}
                      </td>
                    </tr>
                    <tr>
                      <td>Checked By</td>
                      <td>{`${undefinedRecordNames(
                        data,
                        "checker",
                        "first_name"
                      )} ${undefinedRecordNames(
                        data,
                        "checker",
                        "other_names"
                      )}`}</td>
                    </tr>
                    <tr>
                      <td>Checked At</td>
                      <td>
                        {data.checked_at &&
                          `${new Date(
                            data.checked_at
                          ).toDateString()} ${new Date(
                            data.checked_at
                          ).toLocaleTimeString()}
                        `}
                      </td>
                    </tr>
                    <tr>
                      <td>Approved By</td>
                      <td>{`${undefinedRecordNames(
                        data,
                        "approver",
                        "first_name"
                      )} ${undefinedRecordNames(
                        data,
                        "approver",
                        "other_names"
                      )}`}</td>
                    </tr>
                    <tr>
                      <td>Approved At</td>
                      <td>
                        {data.aprroved_at &&
                          `${new Date(
                            data.aprroved_at
                          ).toDateString()} ${new Date(
                            data.aprroved_at
                          ).toLocaleTimeString()}`}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </>
        )}
      </Modal>
    </div>
  );
};

export default ManualsAndPoliciesDetails;
