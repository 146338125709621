import { Button, message, Popover, Table } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { recordOfBiddersActions } from "../../../../config/actions/procument/recordOfBidders/recordOfBidders.actions";
import tableButtonActions from "../../../shared/Tables/TableButtonActions";
import TableTitle from "../../../shared/Tables/TableTitle";
import { useReactToPrint } from "react-to-print";
import PrintRecordOfBidders from "../Details/PrintRecordOfBidders";

const BiddersTable = ({
  handleDelete,
  handleRefreshTable,
  handleExportRecords,
  handleOpenAddModal,
  handleOpenEditModal,
  handleViewDetails,
}) => {
  const [deleteId, setDeleteId] = useState("");
  const [filterTable, setfilterTable] = useState(null);
  const {
    bidders: data,
    biddersLoading: loading,
    biddersSuccess: success,
    biddersError: error,
    deleteBiddersLoading: deleteLoading,
    deleteBiddersSuccess: deleteSuccess,
    deleteBiddersError: deleteError,
    deleteBiddersMessage: deleteMessage,
    recordOfBiddersDetails,
  } = useSelector((state) => state.recordOfBiddersState);
  const dispatch = useDispatch();
  const {
    success: logDetailsSuccess,
    error: logDetailsError,
    records,
  } = useSelector((state) => state.logDetailsState.addLogDetails);
  const columns = [
    {
      title: "Name",
      key: "id",
      render: (text) => (
        <div>
          <span className="d-md-none">Bidders.:</span> {text.name} <br />
          <div className="d-md-none">
            Date Of Submission.:
            {`${new Date(text.time_of_submission).toDateString()}`} <br />
            Time Of Submission:{" "}
            {new Date(text.time_of_submission).toLocaleTimeString()} <br />
            No. Of Envelopes: {text.no_of_envelopes} <br />
            Comments: {text.comments}
            <Popover
              className=""
              trigger="click"
              placement="bottom"
              content={() =>
                tableButtonActions(text, {
                  viewDetails,
                  updateRecord,
                  confirmDelete,
                  deleteRecord,
                  deleteLoading,
                  deleteId,
                })
              }
            >
              Actions: <Button type="link">Actions</Button>
            </Popover>
          </div>
        </div>
      ),
    },
    {
      title: "Date Of Submission",
      key: "time_of_submission",
      dataIndex: "time_of_submission",
      render: (text) => `${new Date(text).toDateString()}`,
      responsive: ["md"],
    },
    {
      title: "Time Of Submission",
      key: "time_of_submission",
      dataIndex: "time_of_submission",
      render: (text) => `${new Date(text).toLocaleTimeString()}`,
      responsive: ["md"],
    },
    {
      title: "Action",
      key: "operation",
      render: (text) => (
        <Popover
          trigger="click"
          placement="bottom"
          content={() =>
            tableButtonActions(text, {
              viewDetails,
              updateRecord,
              confirmDelete,
              deleteRecord,
              deleteLoading,
              deleteId,
            })
          }
        >
          <Button>Actions</Button>
        </Popover>
      ),
      responsive: ["md"],
    },
  ];
  /* Functions */
  const deleteRecord = (e) => setDeleteId(e.target.id);
  const confirmDelete = () => {
    const biddersArray = data.filter((e) => e.id !== deleteId);
    handleDelete(deleteId, biddersArray);
  };
  const refreshTable = () => {
    handleRefreshTable();
    if (!success && error) message.error(error);
  };
  const updateRecord = (e) => {
    const biddersArray = data.filter((bidder) => bidder.id === e.target.id);
    handleOpenEditModal(true);
    dispatch(recordOfBiddersActions.editBiddersData(biddersArray[0]));
  };
  const viewDetails = (e) => handleViewDetails(e.target.id);

  const search = (value) => {
    const filterTable = data.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(value.toLowerCase())
      )
    );
    setfilterTable(filterTable);
  };
  const setfilterTableNull = (e) => {
    if (!e.target.value) setfilterTable(null);
  };

  const showTotal = (total) => `Total: ${total}`;
  const openAddModal = () => {
    handleOpenAddModal(true);
  };
  const exportRecords = () => {
    message.loading("Processing document for export please wait ", 3);
    handleExportRecords();
  };

  /* Use effect */
  useEffect(() => {
    if (logDetailsSuccess && records === "bidders") {
      message.info("Started download");
      handlePrint();
    } else if (!logDetailsSuccess && logDetailsError)
      message.error(logDetailsError);
    if (deleteSuccess && deleteMessage) message.success(deleteMessage);
    else if (!deleteSuccess && deleteError) message.error(deleteError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    logDetailsSuccess,
    logDetailsError,
    deleteSuccess,
    deleteMessage,
    deleteError,
  ]);

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div>
      <div className="d-none">
        <div className="p-2" ref={componentRef}>
          <PrintRecordOfBidders
            data={{
              ...recordOfBiddersDetails,
              bidders: data,
            }}
          />
        </div>
      </div>
      <Table
        pagination={{ total: data.length, showSizeChanger: true, showTotal }}
        loading={loading}
        title={() =>
          TableTitle({
            search,
            setfilterTableNull,
            refreshTable,
            exportRecords,
            openAddModal,
          })
        }
        bordered={true}
        dataSource={filterTable === null ? data : filterTable}
        columns={columns}
      />
    </div>
  );
};

export default BiddersTable;
