import { authActions } from "../../actions/auth/auth.action";
import {
  authenticationRequest,
  confirmAccountRequest,
  forgotPasswordRequest,
  loginUserRequest,
  logoutUserRequest,
  resetPasswordRequest,
} from "../../api/auth/auth.api";

const authThunks = {
  loginUser: (payload) => async (dispatch) => {
    const res = await loginUserRequest("POST", "/users/auth/login", payload);
    try {
      if (res.success) {
        dispatch(authActions.loginUserSuccess());
        localStorage.setItem("authToken", res.data.user_credentials);
      } else dispatch(authActions.loginUserError(res.error));
    } catch (error) {
      dispatch(
        authActions.loginUserError(
          "Sorry we are having trouble logging you in. Please try again in a few minutes "
        )
      );
    }
  },
  forgotPassword: (payload) => async (dispatch) => {
    const res = await forgotPasswordRequest(
      "POST",
      "/users/auth/forgotPassword",
      payload
    );
    try {
      if (res.success) {
        dispatch(authActions.forgotPasswordSuccess(res.data));
      } else dispatch(authActions.forgotPasswordError(res.error));
    } catch (error) {
      console.error(error);
      dispatch(
        authActions.forgotPasswordError(
          "Sorry there seems to be a problem Please try again in a few minutes"
        )
      );
    }
  },
  resetPassword: (payload, token) => async (dispatch) => {
    const res = await resetPasswordRequest(
      "PATCH",
      `/users/auth/resetPassword/${token}`,
      payload
    );
    try {
      if (res.success) dispatch(authActions.resetPasswordSuccess(res.data));
      else dispatch(authActions.resetPasswordError(res.error));
    } catch (error) {
      console.error(error);
      dispatch(
        authActions.resetPasswordError(
          "Sorry we are having some trouble resetting your password, Please try again in a few minutes "
        )
      );
    }
  },
  confirmAccount: (token) => async (dispatch) => {
    const res = await confirmAccountRequest("PATCH", token);
    try {
      if (res.success) dispatch(authActions.confirmAccountSuccess(res.data));
      else
        dispatch(
          authActions.confirmAccountError(
            "Sorry it seems your request code has expired. Please request another to confirm your account"
          )
        );
    } catch (error) {
      console.error(error);
      dispatch(
        authActions.confirmAccountError(
          "Sorry we are having some trouble confirming your account please try again in a few minutes"
        )
      );
    }
  },
  authenticateUser: () => async (dispatch) => {
    const res = await authenticationRequest("/users/auth/verifyToken");
    try {
      if (res.success)
        console.info("Action", dispatch(authActions.authenticateUserSuccess()));
      else
        console.info(
          "Action",
          dispatch(
            authActions.authenticateUserError({
              authenticated: res.success,
              success: res.success,
              connection: true,
              error: "Sorry your session has expired",
            })
          )
        );
    } catch (error) {
      console.error(error);
      dispatch(
        authActions.authenticateUserError({
          connection: false,
          authenticated: true,
          error: "There seems to be a connection problem ....",
        })
      );
    }
  },
  logoutUser: () => async (dispatch) => {
    dispatch(authActions.logoutUserRequest());
    const res = await logoutUserRequest();
    try {
      if (res.success) console.info(dispatch(authActions.logoutUserSuccess()));
      else console.info(dispatch(authActions.logoutUserError(res.error)));
    } catch (error) {
      console.error(error);
      console.info(
        "Action",
        dispatch(
          authActions.logoutUserError(
            "Sorry there seems to be an error Please try again in a few minutes"
          )
        )
      );
    }
  },
};

export default authThunks;
