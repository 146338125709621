export const chartOfAccountsConstants = {
  SET_CHART_OF_ACCOUNTS_REQUEST: "SET_CHART_OF_ACCOUNTS_REQUEST",
  SET_CHART_OF_ACCOUNTS_SUCCESS: "SET_CHART_OF_ACCOUNTS_SUCCESS",
  SET_CHART_OF_ACCOUNTS_ERROR: "SET_CHART_OF_ACCOUNTS_ERROR",

  CHART_OF_ACOUNTS_META_DATA_REQUEST: "CHART_OF_ACOUNTS_META_DATA_REQUEST",
  CHART_OF_ACOUNTS_META_DATA: "CHART_OF_ACOUNTS_META_DATA",

  SET_ACCOUNTS_DETAILS_REQUEST: "SET_ACCOUNTS_DETAILS_REQUEST",
  SET_ACCOUNTS_DETAILS_SUCCESS: "SET_ACCOUNTS_DETAILS_SUCCESS",
  SET_ACCOUNTS_DETAILS_ERROR: "SET_ACCOUNTS_DETAILS_ERROR",

  SET_ACCOUNT_TYPES_DETAILS_REQUEST: "SET_ACCOUNT_TYPES_DETAILS_REQUEST",
  SET_ACCOUNT_TYPES_DETAILS_SUCCESS: "SET_ACCOUNT_TYPES_DETAILS_SUCCESS",
  SET_ACCOUNT_TYPES_DETAILS_ERROR: "SET_ACCOUNT_TYPES_DETAILS_ERROR",

  ADD_ACCOUNT_REQUEST: "ADD_ACCOUNT_REQUEST",
  ADD_ACCOUNT_SUCCESS: "ADD_ACCOUNT_SUCCESS",
  ADD_ACCOUNT_ERROR: "ADD_ACCOUNT_ERROR",
  ADD_ACCOUNT_COMPLETE: "ADD_ACCOUNT_COMPLETE",

  ADD_ACCOUNT_TYPE_REQUEST: "ADD_ACCOUNT_TYPE_REQUEST",
  ADD_ACCOUNT_TYPE_SUCCESS: "ADD_ACCOUNT_TYPE_SUCCESS",
  ADD_ACCOUNT_TYPE_ERROR: "ADD_ACCOUNT_TYPE_ERROR",
  ADD_ACCOUNT_TYPE_COMPLETE: "ADD_ACCOUNT_TYPE_COMPLETE",

  EDIT_ACCOUNT_REQUEST: "EDIT_ACCOUNT_REQUEST",
  EDIT_ACCOUNT_SUCCESS: "EDIT_ACCOUNT_SUCCESS",
  EDIT_ACCOUNT_ERROR: "EDIT_ACCOUNT_ERROR",
  EDIT_ACCOUNT_COMPLETE: "EDIT_ACCOUNT_COMPLETE",
  EDIT_ACCOUNT_DATA: "EDIT_ACCOUNT_DATA",

  EDIT_ACCOUNT_TYPE_REQUEST: "EDIT_ACCOUNT_TYPE_REQUEST",
  EDIT_ACCOUNT_TYPE_SUCCESS: "EDIT_ACCOUNT_TYPE_SUCCESS",
  EDIT_ACCOUNT_TYPE_ERROR: "EDIT_ACCOUNT_TYPE_ERROR",
  EDIT_ACCOUNT_TYPE_COMPLETE: "EDIT_ACCOUNT_TYPE_COMPLETE",
  EDIT_ACCOUNT_TYPE_DATA: "EDIT_ACCOUNT_TYPE_DATA",

  DELETE_ACCOUNT_REQUEST: "DELETE_ACCOUNT_REQUEST",
  DELETE_ACCOUNT_SUCCESS: "DELETE_ACCOUNT_SUCCESS",
  DELETE_ACCOUNT_ERROR: "DELETE_ACCOUNT_ERROR",
  DELETE_ACCOUNT_COMPLETE: "DELETE_ACCOUNT_COMPLETE",

  DELETE_ACCOUNT_TYPE_REQUEST: "DELETE_ACCOUNT_TYPE_REQUEST",
  DELETE_ACCOUNT_TYPE_SUCCESS: "DELETE_ACCOUNT_TYPE_SUCCESS",
  DELETE_ACCOUNT_TYPE_ERROR: "DELETE_ACCOUNT_TYPE_ERROR",
  DELETE_ACCOUNT_TYPE_COMPLETE: "DELETE_ACCOUNT_TYPE_COMPLETE",

  CHECK_ACCOUNT_REQUEST: "CHECK_ACCOUNT_REQUEST",
  CHECK_ACCOUNT_SUCCESS: "CHECK_ACCOUNT_SUCCESS",
  CHECK_ACCOUNT_ERROR: "CHECK_ACCOUNT_ERROR",
  CHECK_ACCOUNT_COMPLETE: "CHECK_ACCOUNT_COMPLETE",

  CHECK_ACCOUNT_TYPE_REQUEST: "CHECK_ACCOUNT_TYPE_REQUEST",
  CHECK_ACCOUNT_TYPE_SUCCESS: "CHECK_ACCOUNT_TYPE_SUCCESS",
  CHECK_ACCOUNT_TYPE_ERROR: "CHECK_ACCOUNT_TYPE_ERROR",
  CHECK_ACCOUNT_TYPE_COMPLETE: "CHECK_ACCOUNT_TYPE_COMPLETE",

  APPROVE_ACCOUNT_REQUEST: "APPROVE_ACCOUNT_REQUEST",
  APPROVE_ACCOUNT_SUCCESS: "APPROVE_ACCOUNT_SUCCESS",
  APPROVE_ACCOUNT_ERROR: "APPROVE_ACCOUNT_ERROR",
  APPROVE_ACCOUNT_COMPLETE: "APPROVE_ACCOUNT_COMPLETE",

  APPROVE_ACCOUNT_TYPE_REQUEST: "APPROVE_ACCOUNT_TYPE_REQUEST",
  APPROVE_ACCOUNT_TYPE_SUCCESS: "APPROVE_ACCOUNT_TYPE_SUCCESS",
  APPROVE_ACCOUNT_TYPE_ERROR: "APPROVE_ACCOUNT_TYPE_ERROR",
  APPROVE_ACCOUNT_TYPE_COMPLETE: "APPROVE_ACCOUNT_TYPE_COMPLETE",
};

export const chartOfAccountsActions = {
  setChartOfAccountsRequest: () => ({
    type: chartOfAccountsConstants.SET_CHART_OF_ACCOUNTS_REQUEST,
  }),
  setChartOfAccountsSuccess: (payload) => ({
    type: chartOfAccountsConstants.SET_CHART_OF_ACCOUNTS_SUCCESS,
    payload,
  }),
  setChartOfAccountsError: (payload) => ({
    type: chartOfAccountsConstants.SET_CHART_OF_ACCOUNTS_ERROR,
    payload,
  }),
  setAccountDetailsRequest: () => ({
    type: chartOfAccountsConstants.SET_ACCOUNTS_DETAILS_REQUEST,
  }),
  setAccountDetailsSuccess: (payload) => ({
    type: chartOfAccountsConstants.SET_ACCOUNTS_DETAILS_SUCCESS,
    payload,
  }),
  setAccountDetailsError: (payload) => ({
    type: chartOfAccountsConstants.SET_ACCOUNTS_DETAILS_ERROR,
    payload,
  }),
  setAccountTypeDetailsRequest: () => ({
    type: chartOfAccountsConstants.SET_ACCOUNT_TYPES_DETAILS_REQUEST,
  }),
  setAccountTypeDetailsSuccess: (payload) => ({
    type: chartOfAccountsConstants.SET_ACCOUNT_TYPES_DETAILS_SUCCESS,
    payload,
  }),
  setAccountTypeDetailsError: (payload) => ({
    type: chartOfAccountsConstants.SET_ACCOUNT_TYPES_DETAILS_ERROR,
    payload,
  }),
  addAccountRequest: () => ({
    type: chartOfAccountsConstants.ADD_ACCOUNT_REQUEST,
  }),
  addAccountSuccess: (payload) => ({
    type: chartOfAccountsConstants.ADD_ACCOUNT_SUCCESS,
    payload,
  }),
  addAccountError: (payload) => ({
    type: chartOfAccountsConstants.ADD_ACCOUNT_ERROR,
    payload,
  }),
  addAccountComplete: () => ({
    type: chartOfAccountsConstants.ADD_ACCOUNT_COMPLETE,
  }),
  addAccountTypeRequest: () => ({
    type: chartOfAccountsConstants.ADD_ACCOUNT_TYPE_REQUEST,
  }),
  addAccountTypeSuccess: (payload) => ({
    type: chartOfAccountsConstants.ADD_ACCOUNT_TYPE_SUCCESS,
    payload,
  }),
  addAccountTypeError: (payload) => ({
    type: chartOfAccountsConstants.ADD_ACCOUNT_TYPE_ERROR,
    payload,
  }),
  addAccountTypeComplete: () => ({
    type: chartOfAccountsConstants.ADD_ACCOUNT_TYPE_COMPLETE,
  }),
  setEditAccountData: (payload) => ({
    type: chartOfAccountsConstants.EDIT_ACCOUNT_DATA,
    payload,
  }),
  editAccountRequest: () => ({
    type: chartOfAccountsConstants.EDIT_ACCOUNT_REQUEST,
  }),
  editAccountSuccess: (payload) => ({
    type: chartOfAccountsConstants.EDIT_ACCOUNT_SUCCESS,
    payload,
  }),
  editAccountError: (payload) => ({
    type: chartOfAccountsConstants.EDIT_ACCOUNT_ERROR,
    payload,
  }),
  editAccountComplete: () => ({
    type: chartOfAccountsConstants.EDIT_ACCOUNT_COMPLETE,
  }),
  setEditAccountTypeData: (payload) => ({
    type: chartOfAccountsConstants.EDIT_ACCOUNT_TYPE_DATA,
    payload,
  }),
  editAccountTypeRequest: () => ({
    type: chartOfAccountsConstants.EDIT_ACCOUNT_TYPE_REQUEST,
  }),
  editAccountTypeSuccess: (payload) => ({
    type: chartOfAccountsConstants.EDIT_ACCOUNT_TYPE_SUCCESS,
    payload,
  }),
  editAccountTypeError: (payload) => ({
    type: chartOfAccountsConstants.EDIT_ACCOUNT_TYPE_ERROR,
    payload,
  }),
  editAccountTypeComplete: () => ({
    type: chartOfAccountsConstants.EDIT_ACCOUNT_TYPE_COMPLETE,
  }),
  deleteAccountRequest: () => ({
    type: chartOfAccountsConstants.DELETE_ACCOUNT_REQUEST,
  }),
  deleteAccountSuccess: (payload) => ({
    type: chartOfAccountsConstants.DELETE_ACCOUNT_SUCCESS,
    payload,
  }),
  deleteAccountError: (payload) => ({
    type: chartOfAccountsConstants.DELETE_ACCOUNT_ERROR,
    payload,
  }),
  deleteAccountComplete: () => ({
    type: chartOfAccountsConstants.DELETE_ACCOUNT_COMPLETE,
  }),
  deleteAccountTypeRequest: () => ({
    type: chartOfAccountsConstants.DELETE_ACCOUNT_TYPE_REQUEST,
  }),
  deleteAccountTypeSuccess: (payload) => ({
    type: chartOfAccountsConstants.DELETE_ACCOUNT_TYPE_SUCCESS,
    payload,
  }),
  deleteAccountTypeError: (payload) => ({
    type: chartOfAccountsConstants.DELETE_ACCOUNT_TYPE_ERROR,
    payload,
  }),
  deleteAccountTypeComplete: () => ({
    type: chartOfAccountsConstants.DELETE_ACCOUNT_TYPE_COMPLETE,
  }),
  checkAccountTypeRequest: () => ({
    type: chartOfAccountsConstants.CHECK_ACCOUNT_TYPE_REQUEST,
  }),
  checkAccountTypeSuccess: (payload) => ({
    type: chartOfAccountsConstants.CHECK_ACCOUNT_TYPE_SUCCESS,
    payload,
  }),
  checkAccountTypeError: (payload) => ({
    type: chartOfAccountsConstants.CHECK_ACCOUNT_TYPE_ERROR,
    payload,
  }),
  checkAccountTypeComplete: () => ({
    type: chartOfAccountsConstants.CHECK_ACCOUNT_TYPE_COMPLETE,
  }),
  checkAccountRequest: () => ({
    type: chartOfAccountsConstants.CHECK_ACCOUNT_REQUEST,
  }),
  checkAccountSuccess: (payload) => ({
    type: chartOfAccountsConstants.CHECK_ACCOUNT_SUCCESS,
    payload,
  }),
  checkAccountError: (payload) => ({
    type: chartOfAccountsConstants.CHECK_ACCOUNT_ERROR,
    payload,
  }),
  checkAccountComplete: () => ({
    type: chartOfAccountsConstants.CHECK_ACCOUNT_COMPLETE,
  }),
  approveAccountTypeRequest: () => ({
    type: chartOfAccountsConstants.APPROVE_ACCOUNT_TYPE_REQUEST,
  }),
  approveAccountTypeSuccess: (payload) => ({
    type: chartOfAccountsConstants.APPROVE_ACCOUNT_TYPE_SUCCESS,
    payload,
  }),
  approveAccountTypeError: (payload) => ({
    type: chartOfAccountsConstants.APPROVE_ACCOUNT_TYPE_ERROR,
    payload,
  }),
  approveAccountTypeComplete: () => ({
    type: chartOfAccountsConstants.APPROVE_ACCOUNT_TYPE_COMPLETE,
  }),
  approveAccountRequest: () => ({
    type: chartOfAccountsConstants.APPROVE_ACCOUNT_REQUEST,
  }),
  approveAccountSuccess: (payload) => ({
    type: chartOfAccountsConstants.APPROVE_ACCOUNT_SUCCESS,
    payload,
  }),
  approveAccountError: (payload) => ({
    type: chartOfAccountsConstants.APPROVE_ACCOUNT_ERROR,
    payload,
  }),
  approveAccountComplete: () => ({
    type: chartOfAccountsConstants.APPROVE_ACCOUNT_COMPLETE,
  }),
  chartOfAccountsMetaData: (payload) => ({
    type: chartOfAccountsConstants.CHART_OF_ACOUNTS_META_DATA,
    payload,
  }),
  chartOfAccountsMetaDataRequest: () => ({
    type: chartOfAccountsConstants.CHART_OF_ACOUNTS_META_DATA_REQUEST,
  }),
};
