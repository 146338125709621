export const appUiConstants = {
  TOGGLE_FORGOT_PASSWORD_MODAL: "TOGGLE_FORGOT_PASSWORD_MODAL",
  TOGGLE_APP_DRAWER: "TOGGLE_APP_DRAWER",

  TOGGLE_ADD_ROLES_MODAL: "TOGGLE_ADD_ROLES_MODAL",
  TOGGLE_EDIT_ROLES_MODAL: "TOGGLE_EDIT_ROLES_MODAL",
  TOGGLE_VIEW_ROLES_MODAL: "TOGGLE_VIEW_ROLES_MODAL",

  TOGGLE_ADD_ASSET_MODAL: "TOGGLE_ADD_ASSET_MODAL",
  TOGGLE_EDIT_ASSET_MODAL: "TOGGLE_EDIT_ASSET_MODAL",
  TOGGLE_VIEW_ASSET_DETAILS_MODAL: "TOGGLE_VIEW_ASSET_DETAILS_MODAL",

  TOGGLE_ASSET_TYPES_MODAL: "TOGGLE_ASSET_TYPES_MODAL",
  TOGGLE_ADD_ASSET_TYPES_MODAL: "TOGGLE_ADD_ASSET_TYPES_MODAL",
  TOGGLE_EDIT_ASSET_TYPES_MODAL: "TOGGLE_EDIT_ASSET_TYPES_MODAL",
  TOGGLE_VIEW_ASSET_TYPES_DETAILS_MODAL:
    "TOGGLE_VIEW_ASSET_TYPES_DETAILS_MODAL",

  TOGGLE_ADD_PERMISSIONS_MODAL: "TOGGLE_ADD_PERMISSIONS_MODAL",
  TOGGLE_EDIT_PERMISSIONS_MODAL: "TOGGLE_EDIT_PERMISSIONS_MODAL",
  TOGGLE_PERMISSION_DETAILS: "TOGGLE_PERMISSIONS_DETAILS",

  TOGGLE_CHANGE_PASSWORD_MODAL: "TOGGLE_CHANGE_PASSWORD_MODAL",

  TOGGLE_EDIT_STAFF_RECORDS_MODAL: "TOGGLE_EDIT_STAFF_RECORDS_MODAL",
  TOGGLE_ADD_STAFF_RECORDS_MODAL: "TOGGLE_ADD_STAFF_RECORDS_MODAL",
  TOGGLE_CHANGE_PROFILE_PICTURE_MODAL: "TOGGLE_CHANGE_PROFILE_PICTURE_MODAL",

  TOGGLE_ADD_MANUALS_AND_POLICIES_MODAL:
    "TOGGLE_ADD_MANUALS_AND_POLICIES_MODAL",
  TOGGLE_EDIT_MANUALS_AND_POLICIES_MODAL:
    "TOGGLE_EDIT_MANUALS_AND_POLICIES_MODAL",
  TOGGLE_VIEW_MANUALS_AND_POLICIES_DETAILS:
    "TOGGLE_VIEW_MANUALS_AND_POLICIES_DETAILS",

  TOGGLE_EDIT_STORES_REGISTER_RECEIPT_MODAL:
    "TOGGLE_EDIT_STORES_REGISTER_RECEIPT_MODAL",
  TOGGLE_ADD_STORES_REGISTER_RECEIPT_MODAL:
    "TOGGLE_ADD_STORES_REGISTER_RECEIPT_MODAL",
  TOGGLE_EDIT_STORES_REGISTER_ISSUES_MODAL:
    "TOGGLE_EDIT_STORES_REGISTER_ISSUES_MODAL",
  TOGGLE_ADD_STORES_REGISTER_ISSUES_MODAL:
    "TOGGLE_ADD_STORES_REGISTER_ISSUES_MODAL",
  TOGGLE_VIEW_STORE_REGISTER_RECEIPT_DETAILS_MODAL:
    "TOGGLE_VIEW_STORE_REGISTER_RECEIPT_DETAILS_MODAL",
  TOGGLE_VIEW_STORE_REGISTER_ISSUES_DETAILS_MODAL:
    "TOGGLE_VIEW_STORE_REGISTER_ISSUES_DETAILS_MODAL",

  TOGGLE_FILE_VIEWER: "TOGGLE_FILE_VIEWER",

  TOGGLE_ADD_DONORS_MODAL: "TOGGLE_ADD_DONORS_MODAL",
  TOGGLE_EDIT_DONORS_MODAL: "TOGGLE_EDIT_DONORS_MODAL",
  TOGGLE_SET_PERSON_INCHARGE_MODAL: "TOGGLE_SET_PERSON_INCHARGE_MODAL",

  TOGGLE_ADD_PROJECTS_MODAL: "TOGGLE_ADD_PROJECTS_MODAL",
  TOGGLE_EDIT_PROJECTS_MODAL: "TOGGLE_EDIT_PROJECTS_MODAL",

  TOGGLE_ADD_CHART_OF_ACCOUNTS_ACCOUNT_MODAL:
    "TOGGLE_ADD_CHART_OF_ACCOUNTS_ACCOUNT_MODAL",
  TOGGLE_ADD_CHART_OF_ACCOUNT_ACCOUNT_TYPE_MODAL:
    "TOGGLE_ADD_CHART_OF_ACCOUNT_ACCOUNT_TYPE_MODAL",
  TOGGLE_EDIT_CHART_OF_ACCOUNTS_ACCOUNT_MODAL:
    "TOGGLE_EDIT_CHART_OF_ACCOUNTS_ACCOUNT_MODAL",
  TOGGLE_EDIT_CHART_OF_ACCOUNT_ACCOUNT_TYPE_MODAL:
    "TOGGLE_EDIT_CHART_OF_ACCOUNT_ACCOUNT_TYPE_MODAL",
  TOGGLE_CHART_OF_ACCOUNTS_ACCOUNT_DETAILS_MODAL:
    "TOGGLE_CHART_OF_ACCOUNTS_ACCOUNT_DETAILS_MODAL",
  TOGGLE_CHART_OF_ACCOUNT_ACCOUNT_TYPE_DETAILS_MODAL:
    "TOGGLT_CHART_OF_ACCOUNT_ACCOUNT_TYPE_DETAILS_MODAL",
  TOGGLE_SEARCH_CHART_OF_ACCOUNTS_MODAL:
    "TOGGLE_SEARCH_CHART_OF_ACCOUNTS_MODAL",

  TOGGLE_ADD_BUDGET_MODAL: "TOGGLE_ADD_BUDGET_MODAL",
  TOGGLE_ADD_BUDGET_LINE_MODAL: "TOGGLE_ADD_BUDGET_LINE_MODAL",
  TOGGLE_ADD_BUDGET_ACTIVITY_MODAL: "TOGGLE_ADD_BUDGET_ACTIVITY_MODAL",
  TOGGLE_EDIT_BUDGET_LINE_MODAL: "TOGGLE_EDIT_BUDGET_LINE_MODAL",
  TOGGLE_EDIT_BUDGET_ACTIVITY_MODAL: "TOGGLE_EDIT_BUDGET_ACTIVITY_MODAL",

  TOGGLE_UPLOAD_SIGNATURE_MODAL: "TOGGLE_UPLOAD_SIGNATURE_MODAL",
};

export const appUiActions = {
  toggleForgotPasswordModal: (payload) => ({
    type: appUiConstants.TOGGLE_FORGOT_PASSWORD_MODAL,
    payload,
  }),
  toggleAppDrawer: (payload) => ({
    type: appUiConstants.TOGGLE_APP_DRAWER,
    payload,
  }),
  toggleAddRolesModal: (payload) => ({
    type: appUiConstants.TOGGLE_ADD_ROLES_MODAL,
    payload,
  }),
  toggleEditRolesModal: (payload) => ({
    type: appUiConstants.TOGGLE_EDIT_ROLES_MODAL,
    payload,
  }),
  toggleAddAssetModal: (payload) => ({
    type: appUiConstants.TOGGLE_ADD_ASSET_MODAL,
    payload,
  }),
  toggleEditAssetModal: (payload) => ({
    type: appUiConstants.TOGGLE_EDIT_ASSET_MODAL,
    payload,
  }),
  toggleAddPermissionsModal: (payload) => ({
    type: appUiConstants.TOGGLE_ADD_PERMISSIONS_MODAL,
    payload,
  }),
  toggleEditPermissionsModal: (payload) => ({
    type: appUiConstants.TOGGLE_EDIT_PERMISSIONS_MODAL,
    payload,
  }),
  togglePermissionDetails: (payload) => ({
    type: appUiConstants.TOGGLE_PERMISSION_DETAILS,
    payload,
  }),
  toggleChangePasswordModal: (payload) => ({
    type: appUiConstants.TOGGLE_CHANGE_PASSWORD_MODAL,
    payload,
  }),
  toggleAssetTypesModal: (payload) => ({
    type: appUiConstants.TOGGLE_ASSET_TYPES_MODAL,
    payload,
  }),
  toggleAddAssetTypesModal: (payload) => ({
    type: appUiConstants.TOGGLE_ADD_ASSET_TYPES_MODAL,
    payload,
  }),
  toggleEditAssetTypesModal: (payload) => ({
    type: appUiConstants.TOGGLE_EDIT_ASSET_TYPES_MODAL,
    payload,
  }),
  toggleAddStaffRecordsModal: (payload) => ({
    type: appUiConstants.TOGGLE_ADD_STAFF_RECORDS_MODAL,
    payload,
  }),
  toggleEditStaffRecordsModal: (payload) => ({
    type: appUiConstants.TOGGLE_EDIT_STAFF_RECORDS_MODAL,
    payload,
  }),
  toggleEditManualsAndPoliciesModal: (payload) => ({
    type: appUiConstants.TOGGLE_EDIT_MANUALS_AND_POLICIES_MODAL,
    payload,
  }),
  toggleAddManualsAndPoliciesModal: (payload) => ({
    type: appUiConstants.TOGGLE_ADD_MANUALS_AND_POLICIES_MODAL,
    payload,
  }),
  toggleEditStoresRegisterReceiptModal: (payload) => ({
    type: appUiConstants.TOGGLE_EDIT_STORES_REGISTER_RECEIPT_MODAL,
    payload,
  }),
  toggleAddStoresRegisterReceiptModal: (payload) => ({
    type: appUiConstants.TOGGLE_ADD_STORES_REGISTER_RECEIPT_MODAL,
    payload,
  }),
  toggleEditStoresRegisterIssuesModal: (payload) => ({
    type: appUiConstants.TOGGLE_EDIT_STORES_REGISTER_ISSUES_MODAL,
    payload,
  }),
  toggleAddStoresRegisterIssuesModal: (payload) => ({
    type: appUiConstants.TOGGLE_ADD_STORES_REGISTER_ISSUES_MODAL,
    payload,
  }),
  toggleStoresRegisterReceptDetailsModal: (payload) => ({
    type: appUiConstants.TOGGLE_VIEW_STORE_REGISTER_RECEIPT_DETAILS_MODAL,
    payload,
  }),
  toggleStoresRegisterIssuesDetailsModal: (payload) => ({
    type: appUiConstants.TOGGLE_VIEW_STORE_REGISTER_ISSUES_DETAILS_MODAL,
    payload,
  }),
  toggleViewRolesModal: (payload) => ({
    type: appUiConstants.TOGGLE_VIEW_ROLES_MODAL,
    payload,
  }),
  toggleAssetTypesDetailsModal: (payload) => ({
    type: appUiConstants.TOGGLE_VIEW_ASSET_TYPES_DETAILS_MODAL,
    payload,
  }),
  toggleAssetsDetailsModal: (payload) => ({
    type: appUiConstants.TOGGLE_VIEW_ASSET_DETAILS_MODAL,
    payload,
  }),
  toggleManualsAndPoliciesDetailsModal: (payload) => ({
    type: appUiConstants.TOGGLE_VIEW_MANUALS_AND_POLICIES_DETAILS,
    payload,
  }),
  toggleChangeProfilePictureModal: (payload) => ({
    type: appUiConstants.TOGGLE_CHANGE_PROFILE_PICTURE_MODAL,
    payload,
  }),
  toggleFileView: (payload) => ({
    type: appUiConstants.TOGGLE_FILE_VIEWER,
    payload,
  }),
  toggleAddProjectsModal: (payload) => ({
    type: appUiConstants.TOGGLE_ADD_PROJECTS_MODAL,
    payload,
  }),
  toggleEditProjectsModal: (payload) => ({
    type: appUiConstants.TOGGLE_EDIT_PROJECTS_MODAL,
    payload,
  }),
  toggleAddDonorsModal: (payload) => ({
    type: appUiConstants.TOGGLE_ADD_DONORS_MODAL,
    payload,
  }),
  toggleEditDonorsModal: (payload) => ({
    type: appUiConstants.TOGGLE_EDIT_DONORS_MODAL,
    payload,
  }),
  toggleAddChartOfAccountsAccountModal: (payload) => ({
    type: appUiConstants.TOGGLE_ADD_CHART_OF_ACCOUNTS_ACCOUNT_MODAL,
    payload,
  }),
  toggleAddChartOfAccountsAccountTypeModal: (payload) => ({
    type: appUiConstants.TOGGLE_ADD_CHART_OF_ACCOUNT_ACCOUNT_TYPE_MODAL,
    payload,
  }),
  toggleEditChartOfAccountsAccountModal: (payload) => ({
    type: appUiConstants.TOGGLE_EDIT_CHART_OF_ACCOUNTS_ACCOUNT_MODAL,
    payload,
  }),
  toggleEditChartOfAccountsAccountTypeModal: (payload) => ({
    type: appUiConstants.TOGGLE_EDIT_CHART_OF_ACCOUNT_ACCOUNT_TYPE_MODAL,
    payload,
  }),
  toggleChartOfAccountsAccountDetailsModal: (payload) => ({
    type: appUiConstants.TOGGLE_CHART_OF_ACCOUNTS_ACCOUNT_DETAILS_MODAL,
    payload,
  }),
  toggleChartOfAccountsAccountTypeDetailsModal: (payload) => ({
    type: appUiConstants.TOGGLE_CHART_OF_ACCOUNT_ACCOUNT_TYPE_DETAILS_MODAL,
    payload,
  }),
  toggleSearchChartOfAccountsModal: (payload) => ({
    type: appUiConstants.TOGGLE_SEARCH_CHART_OF_ACCOUNTS_MODAL,
    payload,
  }),
  toggleSetPersonInchargeModal: (payload) => ({
    type: appUiConstants.TOGGLE_SET_PERSON_INCHARGE_MODAL,
    payload,
  }),
  toggleAddBudgetModal: (payload) => ({
    type: appUiConstants.TOGGLE_ADD_BUDGET_MODAL,
    payload,
  }),
  toggleAddBudgetLineModal: (payload) => ({
    type: appUiConstants.TOGGLE_ADD_BUDGET_LINE_MODAL,
    payload,
  }),
  toggleAddBudgetActivityModal: (payload) => ({
    type: appUiConstants.TOGGLE_ADD_BUDGET_ACTIVITY_MODAL,
    payload,
  }),
  toggleEditBudgetLineModal: (payload) => ({
    type: appUiConstants.TOGGLE_EDIT_BUDGET_LINE_MODAL,
    payload,
  }),
  toggleEditBudgetAcitivityModal: (payload) => ({
    type: appUiConstants.TOGGLE_EDIT_BUDGET_ACTIVITY_MODAL,
    payload,
  }),
  toggleSignatureModal: (payload) => ({
    type: appUiConstants.TOGGLE_UPLOAD_SIGNATURE_MODAL,
    payload,
  }),
};
