import axios from "axios";

const staffRecordsApiRequests = {
  fetchStaffRecords: async () => {
    try {
      const res = await axios({
        method: "GET",
        url: `/administration/staffRecords`,
      });
      return res;
    } catch (error) {
      console.error(error);
    }
  },
  addStaffRecords: async (body) => {
    try {
      const res = await axios({
        method: "POST",
        url: `/administration/staffRecords`,
        headers: {
          accept: "application/json",
          "content-type": "application/json",
        },
        data: body,
      });
      return res;
    } catch (error) {
      console.error(error);
    }
  },
  updateStaffRecord: async (body) => {
    try {
      const res = await axios({
        method: "PUT",
        url: `/administration/staffRecords/${body.id}`,
        headers: {
          accept: "application/json",
          "content-type": "application/json",
        },
        data: body,
      });
      return res;
    } catch (error) {
      console.error(error);
    }
  },
  deleteStaffRecord: async (id) => {
    try {
      const res = await axios({
        method: "DELETE",
        url: `/administration/staffRecords/${id}`,
      });
      return res;
    } catch (error) {
      console.error(error);
    }
  },
  fetchStaffDetails: async (id) => {
    try {
      const res = await axios({
        method: "GET",
        url: `/administration/staffRecords/${id}`,
      });
      return res;
    } catch (error) {
      console.error(error);
    }
  },
  activateAccount: async (id) => {
    try {
      const res = await axios({
        method: "PATCH",
        url: `/administration/staffRecords/activateAccount/${id}`,
        headers: { "content-type": "application/json" },
        data: { activationTime: new Date() },
      });
      return res;
    } catch (error) {
      console.error(error);
    }
  },
  deactivateAccount: async (id) => {
    try {
      const res = await axios({
        method: "PATCH",
        url: `/administration/staffRecords/deactivateAccount/${id}`,
        headers: { "content-type": "application/json" },
        data: { deactivationTime: new Date() },
      });
      return res;
    } catch (error) {
      console.error(error);
    }
  },
  updateProfilePicture: async (id, body) => {
    try {
      const res = await axios({
        method: "PATCH",
        url: `/administration/staffRecords/profilePicture/${id}`,
        data: body,
      });
      return res;
    } catch (error) {
      console.error(error);
    }
  },
  generateNewConfirmToken: async (email) => {
    try {
      const res = await axios({
        method: "POST",
        url: `/users/auth/generateNewConfirmToken`,
        headers: { "content-type": "application/json" },
        data: { email },
      });
      return res;
    } catch (error) {
      console.error("Generate new token request error", error);
      return { success: false, error: "Sorry there seems to be an error" };
    }
  },
};

export default staffRecordsApiRequests;
