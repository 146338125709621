import { advanceAccountabilityFormActions } from "../../../actions/finance/advanceAccountabilityForm/advanceAccountabilityForm.actions";
import apiRequests from "../../../api/api";

const advanceAccountabilityFormThunks = {
  getAdvanceAccountabilityForms: () => async (dispatch) => {
    dispatch(
      advanceAccountabilityFormActions.setAdvanceAccountabilityFormsLoading()
    );
    const res = await apiRequests.getRequest(
      "/finance/advanceAccountabilityForms"
    );
    if (res.success)
      dispatch(
        advanceAccountabilityFormActions.setAdvanceAccountabilityFormsSucess(
          res.data
        )
      );
    else
      dispatch(
        advanceAccountabilityFormActions.setAdvanceAccountabilityFormsError(
          res.error
        )
      );
  },
  getProjectAdvanceAccountabilityForms: (id) => async (dispatch) => {
    dispatch(
      advanceAccountabilityFormActions.setProjectAdvanceAccountabilityFormsLoading()
    );
    const res = await apiRequests.getRequest(
      `/finance/advanceAccountabilityForms/projects/${id}`
    );
    if (res.success)
      dispatch(
        advanceAccountabilityFormActions.setProjectAdvanceAccountabilityFormsSucess(
          res.data
        )
      );
    else
      dispatch(
        advanceAccountabilityFormActions.setProjectAdvanceAccountabilityFormsError(
          res.error
        )
      );
  },
  getAdvanceAccountabilityFormsExpenditure: (id) => async (dispatch) => {
    dispatch(
      advanceAccountabilityFormActions.setAdvanceAccountabilityFormsExpenditureLoading()
    );
    const res = await apiRequests.getRequest(
      `/finance/AdvanceAccountabilityForms/expenditure/${id}`
    );
    if (res.success)
      dispatch(
        advanceAccountabilityFormActions.setAdvanceAccountabilityFormsExpenditureSucess(
          res.data
        )
      );
    else
      dispatch(
        advanceAccountabilityFormActions.setAdvanceAccountabilityFormsExpenditureError(
          res.error
        )
      );
  },
  addAdvanceAccountabilityForm: (body) => async (dispatch) => {
    dispatch(
      advanceAccountabilityFormActions.addAdvanceAccountabilityFormsLoading(
        body
      )
    );
    const res = await apiRequests.postRequest(
      "/finance/advanceAccountabilityForms",
      body
    );
    if (res.success) {
      dispatch(
        advanceAccountabilityFormActions.addAdvanceAccountabilityFormsSuccess(
          res.message
        )
      );
      dispatch(
        advanceAccountabilityFormThunks.getProjectAdvanceAccountabilityForms(
          body.project
        )
      );
    } else
      dispatch(
        advanceAccountabilityFormActions.addAdvanceAccountabilityFormsError(
          res.error
        )
      );
    dispatch(
      advanceAccountabilityFormActions.addAdvanceAccountabilityFormsComplete()
    );
  },
  addAdvanceAccountabilityFormExpenditure: (body) => async (dispatch) => {
    dispatch(
      advanceAccountabilityFormActions.addAdvanceAccountabilityFormsExpenditureLoading()
    );
    const res = await apiRequests.postRequest(
      "/finance/advanceAccountabilityForms/expenditure",
      body
    );
    if (res.success) {
      dispatch(
        advanceAccountabilityFormActions.addAdvanceAccountabilityFormsExpenditureSuccess(
          res.message
        )
      );
      dispatch(
        advanceAccountabilityFormThunks.getAdvanceAccountabilityFormsExpenditure(
          body.advance_accountability_form
        )
      );
    } else
      dispatch(
        advanceAccountabilityFormActions.addAdvanceAccountabilityFormsExpenditureError(
          res.error
        )
      );
    dispatch(
      advanceAccountabilityFormActions.addAdvanceAccountabilityFormsExpenditureComplete()
    );
  },
  addAdvanceAccountabilityFormExpenditureDocument:
    (body) => async (dispatch) => {
      dispatch(
        advanceAccountabilityFormActions.addAdvanceAccountabilityFormsDocumentLoading(
          body
        )
      );
      const res = await apiRequests.filePostRequest(
        "/finance/advanceAccountabilityForms/expenditure/documents",
        body
      );
      if (res.success) {
        dispatch(
          advanceAccountabilityFormActions.addAdvanceAccountabilityFormsDocumentSuccess(
            res.message
          )
        );
      } else
        dispatch(
          advanceAccountabilityFormActions.addAdvanceAccountabilityFormsDocumentError(
            res.error
          )
        );
      dispatch(
        advanceAccountabilityFormActions.addAdvanceAccountabilityFormsDocumentComplete()
      );
    },
  editAdvanceAccountabilityForm: (body) => async (dispatch) => {
    dispatch(
      advanceAccountabilityFormActions.editAdvanceAccountabilityFormsLoading()
    );
    const res = await apiRequests.patchRequest(
      `/finance/advanceAccountabilityForms/${body.id}`,
      body
    );
    if (res.success) {
      dispatch(
        advanceAccountabilityFormActions.editAdvanceAccountabilityFormsSuccess(
          res.message
        )
      );
      dispatch(
        advanceAccountabilityFormThunks.getProjectAdvanceAccountabilityForms(
          body.project
        )
      );
    } else
      dispatch(
        advanceAccountabilityFormActions.editAdvanceAccountabilityFormsError(
          res.error
        )
      );
    dispatch(
      advanceAccountabilityFormActions.editAdvanceAccountabilityFormsComplete()
    );
  },
  editAdvanceAccountabilityFormExpenditure: (body) => async (dispatch) => {
    dispatch(
      advanceAccountabilityFormActions.editAdvanceAccountabilityFormsExpenditureLoading(
        body
      )
    );
    const res = await apiRequests.patchRequest(
      `/finance/advanceAccountabilityForms/expenditure/${body.id}`,
      body
    );
    if (res.success) {
      dispatch(
        advanceAccountabilityFormActions.editAdvanceAccountabilityFormsExpenditureSuccess(
          res.message
        )
      );
      dispatch(
        advanceAccountabilityFormThunks.getAdvanceAccountabilityFormsExpenditure(
          body.advance_accountability_form
        )
      );
    } else
      dispatch(
        advanceAccountabilityFormActions.editAdvanceAccountabilityFormsExpenditureError(
          res.error
        )
      );
    dispatch(
      advanceAccountabilityFormActions.editAdvanceAccountabilityFormsExpenditureComplete()
    );
  },
  deleteAdvanceAccountabilityForm: (id, project) => async (dispatch) => {
    dispatch(
      advanceAccountabilityFormActions.deleteAdvanceAccountabilityFormsLoading()
    );
    const res = await apiRequests.deleteRequest(
      `/finance/advanceAccountabilityForms/${id}`
    );
    if (res.success) {
      dispatch(
        advanceAccountabilityFormActions.deleteAdvanceAccountabilityFormsSuccess(
          res.message
        )
      );
      dispatch(
        advanceAccountabilityFormThunks.getProjectAdvanceAccountabilityForms(
          project
        )
      );
    } else
      dispatch(
        advanceAccountabilityFormActions.deleteAdvanceAccountabilityFormsError(
          res.error
        )
      );
    dispatch(
      advanceAccountabilityFormActions.deleteAdvanceAccountabilityFormsComplete()
    );
  },
  deleteAdvanceAccountabilityFormExpenditure:
    (id, advanceAccountabilityForm) => async (dispatch) => {
      dispatch(
        advanceAccountabilityFormActions.deleteAdvanceAccountabilityFormsExpenditureLoading()
      );
      const res = await apiRequests.deleteRequest(
        `/finance/AdvanceAccountabilityForms/expenditure/${id}`
      );
      if (res.success) {
        dispatch(
          advanceAccountabilityFormActions.deleteAdvanceAccountabilityFormsExpenditureSuccess(
            res.message
          )
        );
        dispatch(
          advanceAccountabilityFormThunks.getAdvanceAccountabilityFormsExpenditure(
            advanceAccountabilityForm
          )
        );
      } else
        dispatch(
          advanceAccountabilityFormActions.deleteAdvanceAccountabilityFormsExpenditureError(
            res.error
          )
        );
      dispatch(
        advanceAccountabilityFormActions.deleteAdvanceAccountabilityFormsExpenditureComplete()
      );
    },
  deleteAdvanceAccountabilityFormExpenditureDocument:
    (id, advanceAccountabilityForm) => async (dispatch) => {
      dispatch(
        advanceAccountabilityFormActions.deleteAdvanceAccountabilityFormsDocumentLoading()
      );
      const res = await apiRequests.deleteRequest(
        `/finance/advanceAccountabilityForms/expenditure/documents/${advanceAccountabilityForm}/${id}`
      );
      if (res.success) {
        dispatch(
          advanceAccountabilityFormActions.deleteAdvanceAccountabilityFormsDocumentSuccess(
            res.message
          )
        );
      } else
        dispatch(
          advanceAccountabilityFormActions.deleteAdvanceAccountabilityFormsDocumentError(
            res.error
          )
        );
      dispatch(
        advanceAccountabilityFormActions.deleteAdvanceAccountabilityFormsDocumentComplete()
      );
    },
  approveAdvanceAccountabilityForm: (id) => async (dispatch) => {
    dispatch(
      advanceAccountabilityFormActions.approveAdvanceAccountabilityFormsLoading()
    );
    const res = await apiRequests.patchRequest(
      `/finance/advanceAccountabilityForms/approve/${id}`,
      { approvedAt: new Date() }
    );
    if (res.success) {
      dispatch(
        advanceAccountabilityFormActions.approveAdvanceAccountabilityFormsSuccess(
          res.message
        )
      );
      dispatch(
        advanceAccountabilityFormThunks.getAdvanceAccountabilityFormDetails(id)
      );
    } else
      dispatch(
        advanceAccountabilityFormActions.approveAdvanceAccountabilityFormsError(
          res.error
        )
      );
    dispatch(
      advanceAccountabilityFormActions.approveAdvanceAccountabilityFormsComplete()
    );
  },
  checkAdvanceAccountabilityForm: (id) => async (dispatch) => {
    dispatch(
      advanceAccountabilityFormActions.checkAdvanceAccountabilityFormsLoading()
    );
    const res = await apiRequests.patchRequest(
      `/finance/advanceAccountabilityForms/check/${id}`,
      { checkedAt: new Date() }
    );
    if (res.success) {
      dispatch(
        advanceAccountabilityFormActions.checkAdvanceAccountabilityFormsSuccess(
          res.message
        )
      );
      dispatch(
        advanceAccountabilityFormThunks.getAdvanceAccountabilityFormDetails(id)
      );
    } else
      dispatch(
        advanceAccountabilityFormActions.checkAdvanceAccountabilityFormsError(
          res.error
        )
      );
    dispatch(
      advanceAccountabilityFormActions.checkAdvanceAccountabilityFormsComplete()
    );
  },
  submitAdvanceAccountabilityForm: (id) => async (dispatch) => {
    dispatch(
      advanceAccountabilityFormActions.submitAdvanceAccountabilityFormsLoading()
    );
    const res = await apiRequests.patchRequest(
      `/finance/advanceAccountabilityForms/submit/${id}`
    );
    if (res.success) {
      dispatch(
        advanceAccountabilityFormActions.submitAdvanceAccountabilityFormsSuccess(
          res.message
        )
      );
      dispatch(
        advanceAccountabilityFormThunks.getAdvanceAccountabilityFormDetails(id)
      );
    } else
      dispatch(
        advanceAccountabilityFormActions.submitAdvanceAccountabilityFormsError(
          res.error
        )
      );
    dispatch(
      advanceAccountabilityFormActions.submitAdvanceAccountabilityFormsComplete()
    );
  },
  metaData: (id) => async (dispatch) => {
    dispatch(advanceAccountabilityFormActions.metaDataLoading());
    const res = await apiRequests.getRequest(
      `/finance/advanceAccountabilityForms/metaData/${id}`
    );
    dispatch(advanceAccountabilityFormActions.metaData(res.data || {}));
  },
  getAdvanceAccountabilityFormDetails: (id) => async (dispatch) => {
    dispatch(
      advanceAccountabilityFormActions.setAdvanceAccountabilityFormsDetailsLoading()
    );
    const res = await apiRequests.getRequest(
      `/finance/advanceAccountabilityForms/${id}`
    );
    if (res.success)
      dispatch(
        advanceAccountabilityFormActions.setAdvanceAccountabilityFormsDetailsSucess(
          res.data
        )
      );
    else
      dispatch(
        advanceAccountabilityFormActions.setAdvanceAccountabilityFormsDetailsError(
          res.error
        )
      );
  },
  getAdvanceAccountabilityFormDetailsExpenditure: (id) => async (dispatch) => {
    dispatch(
      advanceAccountabilityFormActions.setAdvanceAccountabilityFormsExpenditureDetailsLoading()
    );
    const res = await apiRequests.getRequest(
      `/finance/advanceAccountabilityForms/expenditure/details/${id}`
    );
    if (res.success)
      dispatch(
        advanceAccountabilityFormActions.setAdvanceAccountabilityFormsExpenditureDetailsSucess(
          res.data
        )
      );
    else
      dispatch(
        advanceAccountabilityFormActions.setAdvanceAccountabilityFormsExpenditureDetailsError(
          res.error
        )
      );
  },
};

export default advanceAccountabilityFormThunks;
