import React from "react";
import appConfig from "../../config/config.json";
import { Tag } from "antd";
import {
  AiOutlineCheckCircle,
  AiOutlineException,
  AiOutlineExclamationCircle,
  AiOutlineMinusCircle,
} from "react-icons/ai";

const RecordStatus = ({ status = "created" }) => {
  if (status === appConfig.status.approved)
    return (
      <Tag icon={<AiOutlineCheckCircle />} color="success">
        <span className="mx-1 text-uppercase">{status}</span>
      </Tag>
    );
  else if (status === appConfig.status.checked)
    return (
      <Tag icon={<AiOutlineMinusCircle />} color="processing">
        <span className="mx-1 text-uppercase">{status}</span>
      </Tag>
    );
  else if (status === appConfig.status.rejected)
    return (
      <Tag icon={<AiOutlineException />} color="error">
        <span className="mx-1 text-uppercase">{status}</span>
      </Tag>
    );
  else
    return (
      <Tag icon={<AiOutlineExclamationCircle />} color="warning">
        <span>PENDING</span>
      </Tag>
    );
};

export default RecordStatus;
