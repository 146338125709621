import React, { useEffect } from "react";
import LoginForm from "../../components/auth/forms/LoginForm";
import AuthLogo from "../../components/auth/images/AuthLogo";
import { useDispatch, useSelector } from "react-redux";
import authThunks from "../../config/thunks/auth/auth.thunk";
import { authActions } from "../../config/actions/auth/auth.action";
import ForgotPasswordForm from "../../components/auth/forms/ForgotPasswordForm";
import { Modal } from "antd";
import { appUiActions } from "../../config/actions/app/appUi.actions";
import routes from "../../config/routes/routes";
import { getAuthToken } from "../../config/helpers/auth/authToken";
import { useHistory } from "react-router";

const Login = () => {
  const { authenticated, loginSuccess } = useSelector(
    (state) => state.authState
  );
  const { message, success } = useSelector(
    (state) => state.authState.forgotPassword
  );
  const dispatch = useDispatch();
  const authToken = getAuthToken();
  const history = useHistory();
  useEffect(() => {
    if (authenticated && loginSuccess) {
      window.location = "/";
    }
    if (authToken) history.push("/");
    document.title = routes.authentication.login.title;
  }, [authenticated, loginSuccess, authToken, history]);
  const loginUser = (values) => {
    dispatch(authActions.loginUserRequest());
    dispatch(authThunks.loginUser(values));
    if (loginSuccess) window.location = "/";
  };

  useEffect(() => {
    if (message && success) {
      dispatch(appUiActions.toggleForgotPasswordModal(false));
      Modal.success({
        title: "Email Verified",
        content: <p> {message} </p>,
      });
    }
  }, [message, dispatch, success]);

  const forgotPassword = (values) => {
    dispatch(authThunks.forgotPassword(values));
  };

  return (
    <div className="bg-light p-4 min-vh-100 text-center ">
      <div className="container-fluid">
        <div className="d-flex flex-column align-items-center justify-content-center">
          <AuthLogo />
          <h4>
            Amani Initiative Finance/Administration Manangement Information
            System
          </h4>
          <LoginForm handleLogin={loginUser} />
          <ForgotPasswordForm handleForgotPassword={forgotPassword} />
        </div>
      </div>
    </div>
  );
};

export default Login;
