export const localPurchaseOrdersConstants = {
  SET_LOCAL_PURCHASE_ORDERS_REQUEST: "SET_LOCAL_PURCHASE_ORDERS_REQUEST",
  SET_LOCAL_PURCHASE_ORDERS_SUCCESS: "SET_LOCAL_PURCHASE_ORDERS_SUCCESS",
  SET_LOCAL_PURCHASE_ORDERS_ERROR: "SET_LOCAL_PURCHASE_ORDERS_ERROR",

  SET_LPO_ITEMS_REQUEST: "SET_LPO_ITEMS_REQUEST",
  SET_LPO_ITEMS_SUCCESS: "SET_LPO_ITEMS_SUCCESS",
  SET_LPO_ITEMS_ERROR: "SET_LPO_ITEMS_ERROR",

  SET_LOCAL_PURCHASE_ORDERS_DETAILS_REQUEST:
    "SET_LOCAL_PURCHASE_ORDERS_DETAILS_REQUEST",
  SET_LOCAL_PURCHASE_ORDERS_DETAILS_SUCCESS:
    "SET_LOCAL_PURCHASE_ORDERS_DETAILS_SUCCESS",
  SET_LOCAL_PURCHASE_ORDERS_DETAILS_ERROR:
    "SET_LOCAL_PURCHASE_ORDERS_DETAILS_ERROR",

  SET_LPO_ITEMS_DETAILS_REQUEST: "SET_LPO_ITEMS_DETAILS_REQUEST",
  SET_LPO_ITEMS_DETAILS_SUCCESS: "SET_LPO_ITEMS_DETAILS_SUCCESS",
  SET_LPO_ITEMS_DETAILS_ERROR: "SET_LPO_ITEMS_DETAILS_ERROR",

  ADD_LOCAL_PURCHASE_ORDERS_REQUEST: "ADD_LOCAL_PURCHASE_ORDERS_REQUEST",
  ADD_LOCAL_PURCHASE_ORDERS_SUCCESS: "ADD_LOCAL_PURCHASE_ORDERS_SUCCESS",
  ADD_LOCAL_PURCHASE_ORDERS_ERROR: "ADD_LOCAL_PURCHASE_ORDERS_ERROR",
  ADD_LOCAL_PURCHASE_ORDERS_COMPLETE: "ADD_LOCAL_PURCHASE_ORDERS_COMPLETE",

  ADD_LPO_ITEMS_REQUEST: "ADD_LPO_ITEMS_REQUEST",
  ADD_LPO_ITEMS_SUCCESS: "ADD_LPO_ITEMS_SUCCESS",
  ADD_LPO_ITEMS_ERROR: "ADD_LPO_ITEMS_ERROR",
  ADD_LPO_ITEMS_COMPLETE: "ADD_LPO_ITEMS_COMPLETE",

  EDIT_LOCAL_PURCHASE_ORDERS_REQUEST: "EDIT_LOCAL_PURCHASE_ORDERS_REQUEST",
  EDIT_LOCAL_PURCHASE_ORDERS_SUCCESS: "EDIT_LOCAL_PURCHASE_ORDERS_SUCCESS",
  EDIT_LOCAL_PURCHASE_ORDERS_ERROR: "EDIT_LOCAL_PURCHASE_ORDERS_ERROR",
  EDIT_LOCAL_PURCHASE_ORDERS_COMPLETE: "EDIT_LOCAL_PURCHASE_ORDERS_COMPLETE",
  EDIT_LOCAL_PURCHASE_ORDERS_DATA: "EDIT_LOCAL_PURCHASE_ORDERS_DATA",

  EDIT_LPO_ITEMS_REQUEST: "EDIT_LPO_ITEMS_REQUEST",
  EDIT_LPO_ITEMS_SUCCESS: "EDIT_LPO_ITEMS_SUCCESS",
  EDIT_LPO_ITEMS_ERROR: "EDIT_LPO_ITEMS_ERROR",
  EDIT_LPO_ITEMS_COMPLETE: "EDIT_LPO_ITEMS_COMPLETE",
  EDIT_LPO_ITEMS_DATA: "EDIT_LPO_ITEMS_DATA",

  DELETE_LOCAL_PURCHASE_ORDERS_REQUEST: "DELETE_LOCAL_PURCHASE_ORDERS_REQUEST",
  DELETE_LOCAL_PURCHASE_ORDERS_SUCCESS: "DELETE_LOCAL_PURCHASE_ORDERS_SUCCESS",
  DELETE_LOCAL_PURCHASE_ORDERS_ERROR: "DELETE_LOCAL_PURCHASE_ORDERS_ERROR",
  DELETE_LOCAL_PURCHASE_ORDERS_COMPLETE:
    "DELETE_LOCAL_PURCHASE_ORDERS_COMPLETE",

  DELETE_LPO_ITEMS_REQUEST: "DELETE_LPO_ITEMS_REQUEST",
  DELETE_LPO_ITEMS_SUCCESS: "DELETE_LPO_ITEMS_SUCCESS",
  DELETE_LPO_ITEMS_ERROR: "DELETE_LPO_ITEMS_ERROR",
  DELETE_LPO_ITEMS_COMPLETE: "DELETE_LPO_ITEMS_COMPLETE",

  APPROVE_LOCAL_PURCHASE_ORDERS_SUCCESS:
    "APPROVE_LOCAL_PURCHASE_ORDERS_SUCCESS",
  APPROVE_LOCAL_PURCHASE_ORDERS_REQUEST:
    "APPROVE_LOCAL_PURCHASE_ORDERS_REQUEST",
  APPROVE_LOCAL_PURCHASE_ORDERS_ERROR: "APPROVE_LOCAL_PURCHASE_ORDERS_ERROR",
  APPROVE_LOCAL_PURCHASE_ORDERS_COMPLETE:
    "APPROVE_LOCAL_PURCHASE_ORDERS_COMPLETE",

  CHECK_LOCAL_PURCHASE_ORDERS_REQUEST: "CHECK_LOCAL_PURCHASE_ORDERS_REQUEST",
  CHECK_LOCAL_PURCHASE_ORDERS_SUCCESS: "CHECK_LOCAL_PURCHASE_ORDERS_SUCCESS",
  CHECK_LOCAL_PURCHASE_ORDERS_ERROR: "CHECK_LOCAL_PURCHASE_ORDERS_ERROR",
  CHECK_LOCAL_PURCHASE_ORDERS_COMPLETE: "CHECK_LOCAL_PURCHASE_ORDERS_COMPLETE",

  SUBMIT_LOCAL_PURCHASE_ORDERS_REQUEST: "SUBMIT_LOCAL_PURCHASE_ORDERS_REQUEST",
  SUBMIT_LOCAL_PURCHASE_ORDERS_SUCCESS: "SUBMIT_LOCAL_PURCHASE_ORDERS_SUCCESS",
  SUBMIT_LOCAL_PURCHASE_ORDERS_ERROR: "SUBMIT_LOCAL_PURCHASE_ORDERS_ERROR",
  SUBMIT_LOCAL_PURCHASE_ORDERS_COMPLETE:
    "SUBMIT_LOCAL_PURCHASE_ORDERS_COMPLETE",

  SET_LOCAL_PURCHASE_ORDERS_META_DATA_REQUEST:
    "SET_LOCAL_PURCHASE_ORDERS_META_DATA_REQUEST",
  SET_LOCAL_PURCHASE_ORDERS_META_DATA: "SET_LOCAL_PURCHASE_ORDERS_META_DATA",
};

export const localPurchaseOrdersActions = {
  setLocalPurchaseOrdersRequest: () => ({
    type: localPurchaseOrdersConstants.SET_LOCAL_PURCHASE_ORDERS_REQUEST,
  }),
  setLocalPurchaseOrdersSuccess: (payload) => ({
    type: localPurchaseOrdersConstants.SET_LOCAL_PURCHASE_ORDERS_SUCCESS,
    payload,
  }),
  setLocalPurchaseOrdersError: (payload) => ({
    type: localPurchaseOrdersConstants.SET_LOCAL_PURCHASE_ORDERS_ERROR,
    payload,
  }),
  setLpoItemsRequest: () => ({
    type: localPurchaseOrdersConstants.SET_LPO_ITEMS_REQUEST,
  }),
  setLpoItemsSuccess: (payload) => ({
    type: localPurchaseOrdersConstants.SET_LPO_ITEMS_SUCCESS,
    payload,
  }),
  setLpoItemsError: (payload) => ({
    type: localPurchaseOrdersConstants.SET_LPO_ITEMS_ERROR,
    payload,
  }),
  setLocalPurchaseOrdersDetailsRequest: () => ({
    type: localPurchaseOrdersConstants.SET_LOCAL_PURCHASE_ORDERS_DETAILS_REQUEST,
  }),
  setLocalPurchaseOrdersDetailsSuccess: (payload) => ({
    type: localPurchaseOrdersConstants.SET_LOCAL_PURCHASE_ORDERS_DETAILS_SUCCESS,
    payload,
  }),
  setLocalPurchaseOrdersDetailsError: (payload) => ({
    type: localPurchaseOrdersConstants.SET_LOCAL_PURCHASE_ORDERS_DETAILS_ERROR,
    payload,
  }),
  setLpoItemsDetailsRequest: () => ({
    type: localPurchaseOrdersConstants.SET_LPO_ITEMS_DETAILS_REQUEST,
  }),
  setLpoItemsDetailsSuccess: (payload) => ({
    type: localPurchaseOrdersConstants.SET_LPO_ITEMS_DETAILS_SUCCESS,
    payload,
  }),
  setLpoItemsDetailsError: (payload) => ({
    type: localPurchaseOrdersConstants.SET_LPO_ITEMS_DETAILS_ERROR,
    payload,
  }),
  addLocalPurchaseOrdersRequest: () => ({
    type: localPurchaseOrdersConstants.ADD_LOCAL_PURCHASE_ORDERS_REQUEST,
  }),
  addLocalPurchaseOrdersSuccess: (payload) => ({
    type: localPurchaseOrdersConstants.ADD_LOCAL_PURCHASE_ORDERS_SUCCESS,
    payload,
  }),
  addLocalPurchaseOrdersError: (payload) => ({
    type: localPurchaseOrdersConstants.ADD_LOCAL_PURCHASE_ORDERS_ERROR,
    payload,
  }),
  addLocalPurchaseOrdersComplete: () => ({
    type: localPurchaseOrdersConstants.ADD_LOCAL_PURCHASE_ORDERS_COMPLETE,
  }),
  addLpoItemsRequest: () => ({
    type: localPurchaseOrdersConstants.ADD_LPO_ITEMS_REQUEST,
  }),
  addLpoItemsSuccess: (payload) => ({
    type: localPurchaseOrdersConstants.ADD_LPO_ITEMS_SUCCESS,
    payload,
  }),
  addLpoItemsError: (payload) => ({
    type: localPurchaseOrdersConstants.ADD_LPO_ITEMS_ERROR,
    payload,
  }),
  addLpoItemsComplete: () => ({
    type: localPurchaseOrdersConstants.ADD_LPO_ITEMS_COMPLETE,
  }),
  editLocalPurchaseOrdersRequest: () => ({
    type: localPurchaseOrdersConstants.EDIT_LOCAL_PURCHASE_ORDERS_REQUEST,
  }),
  editLocalPurchaseOrdersSuccess: (payload) => ({
    type: localPurchaseOrdersConstants.EDIT_LOCAL_PURCHASE_ORDERS_SUCCESS,
    payload,
  }),
  editLocalPurchaseOrdersError: (payload) => ({
    type: localPurchaseOrdersConstants.EDIT_LOCAL_PURCHASE_ORDERS_ERROR,
    payload,
  }),
  editLocalPurchaseOrdersComplete: () => ({
    type: localPurchaseOrdersConstants.EDIT_LOCAL_PURCHASE_ORDERS_COMPLETE,
  }),
  editLocalPurchaseOrdersData: (payload) => ({
    type: localPurchaseOrdersConstants.EDIT_LOCAL_PURCHASE_ORDERS_DATA,
    payload,
  }),
  editLpoItemsRequest: () => ({
    type: localPurchaseOrdersConstants.EDIT_LPO_ITEMS_REQUEST,
  }),
  editLpoItemsSuccess: (payload) => ({
    type: localPurchaseOrdersConstants.EDIT_LPO_ITEMS_SUCCESS,
    payload,
  }),
  editLpoItemsError: (payload) => ({
    type: localPurchaseOrdersConstants.EDIT_LPO_ITEMS_ERROR,
    payload,
  }),
  editLpoItemsComplete: () => ({
    type: localPurchaseOrdersConstants.EDIT_LPO_ITEMS_COMPLETE,
  }),
  editLpoItemsData: (payload) => ({
    type: localPurchaseOrdersConstants.EDIT_LPO_ITEMS_DATA,
    payload,
  }),
  deleteLocalPurchaseOrdersRequest: () => ({
    type: localPurchaseOrdersConstants.DELETE_LOCAL_PURCHASE_ORDERS_REQUEST,
  }),
  deleteLocalPurchaseOrdersSuccess: (payload) => ({
    type: localPurchaseOrdersConstants.DELETE_LOCAL_PURCHASE_ORDERS_SUCCESS,
    payload,
  }),
  deleteLocalPurchaseOrdersError: (payload) => ({
    type: localPurchaseOrdersConstants.DELETE_LOCAL_PURCHASE_ORDERS_ERROR,
    payload,
  }),
  deleteLocalPurchaseOrdersComplete: () => ({
    type: localPurchaseOrdersConstants.DELETE_LOCAL_PURCHASE_ORDERS_COMPLETE,
  }),
  deleteLpoItemsRequest: () => ({
    type: localPurchaseOrdersConstants.DELETE_LPO_ITEMS_REQUEST,
  }),
  deleteLpoItemsSuccess: (payload) => ({
    type: localPurchaseOrdersConstants.DELETE_LPO_ITEMS_SUCCESS,
    payload,
  }),
  deleteLpoItemsError: (payload) => ({
    type: localPurchaseOrdersConstants.DELETE_LPO_ITEMS_ERROR,
    payload,
  }),
  deleteLpoItemsComplete: () => ({
    type: localPurchaseOrdersConstants.DELETE_LPO_ITEMS_COMPLETE,
  }),
  submitLocalPurchaseOrdersRequest: () => ({
    type: localPurchaseOrdersConstants.SUBMIT_LOCAL_PURCHASE_ORDERS_REQUEST,
  }),
  submitLocalPurchaseOrdersSuccess: (payload) => ({
    type: localPurchaseOrdersConstants.SUBMIT_LOCAL_PURCHASE_ORDERS_SUCCESS,
    payload,
  }),
  submitLocalPurchaseOrdersError: (payload) => ({
    type: localPurchaseOrdersConstants.SUBMIT_LOCAL_PURCHASE_ORDERS_ERROR,
    payload,
  }),
  submitLocalPurchaseOrdersComplete: () => ({
    type: localPurchaseOrdersConstants.SUBMIT_LOCAL_PURCHASE_ORDERS_COMPLETE,
  }),
  checkLocalPurchaseOrdersRequest: () => ({
    type: localPurchaseOrdersConstants.CHECK_LOCAL_PURCHASE_ORDERS_REQUEST,
  }),
  checkLocalPurchaseOrdersSuccess: (payload) => ({
    type: localPurchaseOrdersConstants.CHECK_LOCAL_PURCHASE_ORDERS_SUCCESS,
    payload,
  }),
  checkLocalPurchaseOrdersError: (payload) => ({
    type: localPurchaseOrdersConstants.CHECK_LOCAL_PURCHASE_ORDERS_ERROR,
    payload,
  }),
  checkLocalPurchaseOrdersComplete: () => ({
    type: localPurchaseOrdersConstants.CHECK_LOCAL_PURCHASE_ORDERS_COMPLETE,
  }),
  approveLocalPurchaseOrdersRequest: () => ({
    type: localPurchaseOrdersConstants.APPROVE_LOCAL_PURCHASE_ORDERS_REQUEST,
  }),
  approveLocalPurchaseOrdersSuccess: (payload) => ({
    type: localPurchaseOrdersConstants.APPROVE_LOCAL_PURCHASE_ORDERS_SUCCESS,
    payload,
  }),
  approveLocalPurchaseOrdersError: (payload) => ({
    type: localPurchaseOrdersConstants.APPROVE_LOCAL_PURCHASE_ORDERS_ERROR,
    payload,
  }),
  approveLocalPurchaseOrdersComplete: () => ({
    type: localPurchaseOrdersConstants.APPROVE_LOCAL_PURCHASE_ORDERS_COMPLETE,
  }),
  setLocalPurchaseOrdersMetaDataRequest: () => ({
    type: localPurchaseOrdersConstants.SET_LOCAL_PURCHASE_ORDERS_META_DATA_REQUEST,
  }),
  setLocalPurchaseOrdersMetaData: (payload) => ({
    type: localPurchaseOrdersConstants.SET_LOCAL_PURCHASE_ORDERS_META_DATA,
    payload,
  }),
};
