import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Alert,
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";
import staffRecordsThunks from "../../../../config/thunks/administration/staffRecords/staffRecords.thunks";
import moment from "moment";
import { storesRegisterActions } from "../../../../config/actions/administration/storesRegister/storesRegister.actions";

const { Option } = Select;

const EditIssueForm = ({ handleEditRecord }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  //use selector to get state from redux state
  const { error, success, loading, data } = useSelector(
    (state) => state.storesRegisterState.updateStoresRegisterIssue
  );
  const {
    success: usersSuccess,
    loading: usersLoading,
    data: metaData,
  } = useSelector((state) => state.assetRegisterState.metaData);
  //submit form
  const onFinish = (values) => {
    dispatch(storesRegisterActions.setStoresRegisterIssueUpdateData(values));
    values.id = data.id;
    values.item_id = data.item_id;
    values.updatedAt = new Date();
    handleEditRecord(values);
  };
  //close modal
  const closeModal = () => {
    dispatch(appUiActions.toggleEditStoresRegisterIssuesModal(false));
  };
  //use effect
  useEffect(() => {
    if (success) form.resetFields();
    if (!usersSuccess) dispatch(staffRecordsThunks.getStaffRecords());
  }, [form, success, usersSuccess, dispatch]);
  const visible = useSelector(
    (state) =>
      state.AppUi.admisnistration.storesRegister.editStoresRegisterIssuesModal
  );
  const fields = [
    { name: "issued_to", value: data.issued_to },
    { name: "remarks", value: data.remarks },
    { name: "issue_date", value: moment(data.issue_date) },
    { name: "quantity", value: data.quantity },
  ];
  return (
    <div>
      <Modal
        onCancel={closeModal}
        footer={null}
        title="EDIT STORES REGISTER ISSUE"
        open={visible}
      >
        <Form
          fields={fields}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          validateTrigger="onBlur"
          layout="horizontal"
          form={form}
          onFinish={onFinish}
        >
          {!success && error && (
            <Alert type="error" showIcon message={error} className="m-2" />
          )}
          <Form.Item label="Item" name="item">
            <Input readOnly defaultValue={data.name} className="w-100" />
          </Form.Item>
          <Form.Item
            name="issued_to"
            label="Issued To"
            rules={[
              {
                required: true,
                message: "Please select who you are issueing to",
              },
            ]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .join("")
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              loading={usersLoading}
            >
              {metaData?.users?.map((user, idx) => {
                return (
                  <Option value={user.id} key={idx}>
                    {user.first_name} {user.other_names}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Issue Date"
            name="issue_date"
            rules={[
              {
                required: true,
                message: "Please  add an issue date",
              },
            ]}
          >
            <DatePicker className="w-100" />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: "Please add a quantity" }]}
            name="quantity"
            label="Quantity"
          >
            <InputNumber className="w-100" />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: "Please add remarks" }]}
            name="remarks"
            label={"Remarks"}
          >
            <TextArea />
          </Form.Item>
          <Form.Item className={"mt-3"} wrapperCol={{ offset: 8, span: 16 }}>
            <Button loading={loading} htmlType="submit" type="primary">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default EditIssueForm;
