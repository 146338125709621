import {
  Modal,
  Form,
  Button,
  message,
  Alert,
  Input,
  InputNumber,
  Select,
} from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { localPurchaseOrdersActions } from "../../../../config/actions/procument/localPurchaseOrder/localPuchaseOrder.actions";
import { numberWithCommas } from "../../../../config/helpers/app/numberFormater";

const { Option } = Select;

const EditLocalPurchaseOrderItem = ({
  visible,
  handleEditRecord,
  handleCloseModal,
}) => {
  const {
    editLpoItemsSuccess,
    editLpoItemsLoading,
    editLpoItemsError,
    editLpoItemsMessage,
    editLpoItemsData: data,
    localPurchaseOrdersDetails,
  } = useSelector((state) => state.localPurchaseOrdersState);
  const closeModal = () => handleCloseModal(false);
  const dispatch = useDispatch();
  const fields = [
    { name: "item", value: data.item },
    { name: "units", value: data.units },
    { name: "unit_cost", value: data.unit_cost },
    { name: "description", value: data.description },
  ];
  const [form] = Form.useForm();
  const onFinish = (values) => {
    values.updated_at = new Date();
    values.id = data.id;
    values.local_purchase_order = localPurchaseOrdersDetails.id;
    values.old_total_cost = parseInt(data.units) * parseInt(data.unit_cost);
    dispatch(localPurchaseOrdersActions.editLpoItemsData(values));
    handleEditRecord(values);
  };
  useEffect(() => {
    if (editLpoItemsSuccess && editLpoItemsMessage) {
      message.success(editLpoItemsMessage);
      handleCloseModal(false);
    }
  }, [editLpoItemsSuccess, editLpoItemsMessage, handleCloseModal]);
  return (
    <div>
      <Modal
        title="ADD LOCAL PURCHASE ORDER ITEM"
        onCancel={closeModal}
        footer={null}
        open={visible}
      >
        <Form
          fields={fields}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          layout="horizontal"
          form={form}
          onFinish={onFinish}
        >
          {!editLpoItemsSuccess && editLpoItemsError && (
            <Alert
              className="m-2"
              showIcon
              type="error"
              message={editLpoItemsError}
            />
          )}
          <Form.Item
            label="Item"
            name={"item"}
            fieldKey={"item"}
            rules={[{ required: true, message: "Please select an item" }]}
          >
            <Select
              disabled
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              className="w-100"
              allowClear
            >
              {localPurchaseOrdersDetails?.items?.map((item) => {
                return (
                  <Option value={item.id} key={item.id}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Units"
            name={"units"}
            fieldKey={"units"}
            hasFeedback
            rules={[{ required: true, message: "Please edit Units" }]}
          >
            <InputNumber precision={0} className="w-100" placeholder="Units" />
          </Form.Item>
          <Form.Item
            label="Unit Cost"
            name={"unit_cost"}
            fieldKey={"unit_cost"}
            hasFeedback
            rules={[{ required: true, message: "Please edit a Unit Cost" }]}
          >
            <InputNumber
              precision={0}
              className="w-100"
              placeholder="Unit Cost"
            />
          </Form.Item>
          <Form.Item label="Total" shouldUpdate>
            {({ getFieldValue }) => {
              const unitCost = getFieldValue("unit_cost");
              const units = getFieldValue("units");
              const total_cost = parseInt(unitCost) * parseInt(units);
              return (
                <Input
                  readOnly
                  value={!isNaN(total_cost) ? numberWithCommas(total_cost) : 0}
                ></Input>
              );
            }}
          </Form.Item>
          <Form.Item
            label="Description"
            name={"description"}
            rules={[{ required: true, message: "Please edit a Description" }]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item className={"mt-3"} wrapperCol={{ offset: 8, span: 16 }}>
            <Button
              loading={editLpoItemsLoading}
              htmlType="submit"
              type="primary"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default EditLocalPurchaseOrderItem;
