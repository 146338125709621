import React from "react";
import authLogo from "../../../assets/logo/authLogo.png";

const AuthLogo = () => {
  return (
    <div className="m-4">
      <img src={authLogo} alt="Auth page logo" />
    </div>
  );
};

export default AuthLogo;
