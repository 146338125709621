import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LogDetailsTable from "../../components/settings/LogDetails/Tables/LogDetailsTable";
import routes from "../../config/routes/routes";
import logDetailThunks from "../../config/thunks/settings/logDetails.thunks";

const LogDetails = () => {
  const { success } = useSelector((state) => state.logDetailsState.logDetails);
  const { data: currentUserInfo } = useSelector(
    (state) => state.usersState.currentUserInfo
  );
  const dispatch = useDispatch();
  //thunks
  const refreshData = () => {
    dispatch(logDetailThunks.getLogDetails(currentUserInfo?.id));
  };

  useEffect(() => {
    document.title = routes.settings.logDetails.title;
    if (!success) dispatch(logDetailThunks.getLogDetails(currentUserInfo?.id));
  }, [success, dispatch, currentUserInfo?.id]);
  return (
    <div className="container-fluid mt-2">
      <h5 className="fw-bold text-uppercase">System Log Details </h5>
      <LogDetailsTable handleRefresh={refreshData} />
    </div>
  );
};

export default LogDetails;
