import { message, Table } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import TableTitle from "../../../shared/Tables/TableTitle";

const FinancialEvaluationsTable = ({
  handleRefreshTable,
  handleViewDetails,
}) => {
  const [filterTable, setfilterTable] = useState(null);
  const {
    financialEvaluationsItems: data,
    financialEvaluationsItemsLoading: loading,
    financialEvaluationsItemsSuccess: success,
    financialEvaluationsItemsError: error,
  } = useSelector((state) => state.bidEvaluationState);
  const columns = [
    {
      title: "Item",
      key: "item",
      render: (text) => (
        <div>
          <span className="d-md-none">Item:</span> {text.item}
          <br />
          <div className="d-md-none">
            <button onClick={viewDetails} id={text.id} className="ant-btn">
              Evaluate Bidders
            </button>
          </div>
        </div>
      ),
    },
    {
      title: "Action",
      key: "operation",
      responsive: ["md"],
      render: (text) => (
        <button onClick={viewDetails} id={text.id} className="ant-btn">
          Evaluate Bidders
        </button>
      ),
    },
  ];
  /* Functions */
  const refreshTable = () => {
    handleRefreshTable();
    if (!success && error) message.error(error);
  };

  const viewDetails = (e) => handleViewDetails(e.target.id);

  const search = (value) => {
    const filterTable = data.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(value.toLowerCase())
      )
    );
    setfilterTable(filterTable);
  };
  const setfilterTableNull = (e) => {
    if (!e.target.value) setfilterTable(null);
  };

  const showTotal = (total) => `Total: ${total}`;
  const openAddModal = () => message.info("Add Item Unavailable");

  const exportRecords = () => {
    message.info("Export Unavailable");
  };

  return (
    <div>
      <Table
        pagination={{ total: data.length, showSizeChanger: true, showTotal }}
        loading={loading}
        title={() =>
          TableTitle({
            search,
            setfilterTableNull,
            refreshTable,
            exportRecords,
            openAddModal,
          })
        }
        bordered={true}
        dataSource={filterTable === null ? data : filterTable}
        columns={columns}
      />
    </div>
  );
};

export default FinancialEvaluationsTable;
