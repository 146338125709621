import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import routes from "../../../config/routes/routes";
import advanceAccountabilityFormThunks from "../../../config/thunks/finance/advanceAccountabilityForm/advanceAccountabilityForm.thunks";
import AdvanceAccountabilityFormTable from "../../../components/Finance/advanceAccountabilityForms/tables/AdvanceAccountabilityFormTable";
import { message } from "antd";
import AdvanceAccountabilityFormDetails from "../../../components/Finance/advanceAccountabilityForms/details/AdvanceAccountabilityFormDetails";
import AdvanceAccountabilityFormExpenditureDetails from "../../../components/Finance/advanceAccountabilityForms/details/AdvanceAccountabilityFormExpenditureDetails";
import AddRejections from "../../../components/shared/Rejections/AddRejections";
import logDetailThunks from "../../../config/thunks/settings/logDetails.thunks";

const AdvanceAccountabilityForm = () => {
  //state initialization variable
  const { advanceAccountabilityFormsSuccess } = useSelector(
    (state) => state.advanceAccountabilityFormsState
  );
  const [
    advanceAccountabilityFormDetailsModalVisible,
    setAdvanceAccountabilityFormDetailsModalVisible,
  ] = useState(false);
  const [
    advanceAccountabilityFormExpenditureDetailsModalVisible,
    setAdvanceAccountabilityFormExpenditureDetailsModalVisible,
  ] = useState(false);
  const dispatch = useDispatch();
  //ui
  const changes = () =>
    message.info("You can makes changes from the my projects page");
  //thunks
  const refreshData = () =>
    dispatch(advanceAccountabilityFormThunks.getAdvanceAccountabilityForms());
  const getAdvanceAccountabilityFormDetails = (id) => {
    setAdvanceAccountabilityFormDetailsModalVisible(true);
    dispatch(
      advanceAccountabilityFormThunks.getAdvanceAccountabilityFormsExpenditure(
        id
      )
    );
    dispatch(
      advanceAccountabilityFormThunks.getAdvanceAccountabilityFormDetails(id)
    );
  };
  const getAdvanceAccountabilityFormExpenditureDetails = (id) => {
    setAdvanceAccountabilityFormExpenditureDetailsModalVisible(true);
    dispatch(
      advanceAccountabilityFormThunks.getAdvanceAccountabilityFormDetailsExpenditure(
        id
      )
    );
  };
  const submitAdvanceAccountabilityForm = (id) =>
    message.info("You can submit the Payment Voucher from my Projects");
  const checkAdvanceAccountabilityForm = (id) =>
    dispatch(
      advanceAccountabilityFormThunks.checkAdvanceAccountabilityForm(id)
    );
  const approveAdvanceAccountabilityForm = (id) =>
    dispatch(
      advanceAccountabilityFormThunks.approveAdvanceAccountabilityForm(id)
    );
  const exportRecords = () => {
    dispatch(
      logDetailThunks.addLogDetails({
        created_at: new Date(),
        activity: `Exported Payment Vouchers  Records`,
        records: "advanceAccountabilityForms",
      })
    );
  };
  //use effects
  useEffect(() => {
    document.title = routes.finance.advanceAccountabilityForm.title;
    if (!advanceAccountabilityFormsSuccess)
      dispatch(advanceAccountabilityFormThunks.getAdvanceAccountabilityForms());
  }, [advanceAccountabilityFormsSuccess, dispatch]);

  return (
    <div className="container-fluid p-2">
      <h5 className="fw-bold">PAYMENT VOUCHERS</h5>
      <AdvanceAccountabilityFormTable
        handleExportRecords={exportRecords}
        handleDelete={changes}
        handleViewDetails={getAdvanceAccountabilityFormDetails}
        handleRefreshTable={refreshData}
      />
      <AdvanceAccountabilityFormDetails
        handleApprove={approveAdvanceAccountabilityForm}
        handleCheck={checkAdvanceAccountabilityForm}
        handleSubmit={submitAdvanceAccountabilityForm}
        handleViewDetails={getAdvanceAccountabilityFormExpenditureDetails}
        handleOpenAddDocument={changes}
        handleOpenEditModal={changes}
        handleDeleteExpenditure={changes}
        handleRefresh={getAdvanceAccountabilityFormDetails}
        handleOpenAddExpenditureModal={changes}
        handleCloseModal={setAdvanceAccountabilityFormDetailsModalVisible}
        visible={advanceAccountabilityFormDetailsModalVisible}
      />
      <AdvanceAccountabilityFormExpenditureDetails
        handleDelete={changes}
        handleCloseModal={
          setAdvanceAccountabilityFormExpenditureDetailsModalVisible
        }
        visible={advanceAccountabilityFormExpenditureDetailsModalVisible}
      />
      <AddRejections />
    </div>
  );
};

export default AdvanceAccountabilityForm;
