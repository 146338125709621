import { message } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddPermission from "../../components/settings/Permissions/Forms/AddPermissions";
import EditPermission from "../../components/settings/Permissions/Forms/EditPermissions";
import PermissionsDetails from "../../components/settings/Permissions/PermissionsDetails";
import PermissionsTable from "../../components/settings/Permissions/Tables/PermissionsTable";
import routes from "../../config/routes/routes";
import permissionsThunks from "../../config/thunks/settings/permissions.thunks";

const Permissions = () => {
  const { success: permissionsSuccess, data: permissionsArray } = useSelector(
    (state) => state.permissionsState.permissions
  );
  const {
    success: deleteSuccess,
    error: deleteError,
    message: deleteMessage,
  } = useSelector((state) => state.permissionsState.deletePermission);
  const dispatch = useDispatch();
  const deletePemissions = (id) => {
    const newPermissionsArray = permissionsArray.filter((e) => e.id !== id);
    dispatch(permissionsThunks.deletePermission(id, newPermissionsArray));
  };
  const addPermisson = (values) => {
    dispatch(permissionsThunks.createPermission(values));
  };
  const updatePermission = (values) => {
    const filteredArray = permissionsArray.filter((e) => e.id !== values.id);
    const updatedArray = [values, ...filteredArray];
    dispatch(
      permissionsThunks.updatePermissions(values, values.id, updatedArray)
    );
  };
  const viewPermissionDetails = (id) => {
    dispatch(permissionsThunks.getPermission(id));
  };
  useEffect(() => {
    document.title = routes.settings.permissions.title;
    if (!permissionsSuccess) dispatch(permissionsThunks.getPermissions());
  }, [dispatch, permissionsSuccess]);
  useEffect(() => {
    if (deleteSuccess) message.success(deleteMessage);
    else if (!deleteSuccess && deleteError) message.error(deleteError);
  }, [deleteError, deleteSuccess, deleteMessage]);

  return (
    <div className="container-fluid mt-2">
      <h5 className="fw-bold text-uppercase">System Permissions</h5>
      <PermissionsTable
        handleViewPermissionDetails={viewPermissionDetails}
        handleDeletePermissions={deletePemissions}
      />
      <AddPermission handledAddPermission={addPermisson} />
      <EditPermission handleEditPermission={updatePermission} />
      <PermissionsDetails />
    </div>
  );
};

export default Permissions;
