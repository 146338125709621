import { Button, message, Popover, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";
import { budgetActions } from "../../../../config/actions/finance/budget/budget.actions";
import {
  getCurrency,
  numberWithCommas,
} from "../../../../config/helpers/app/numberFormater";
import generatePDF from "../../../../config/services/generatePdf";
import tableButtonActions from "../../../shared/Tables/TableButtonActions";
import TableTitle from "../../../shared/Tables/TableTitle";
import BudgetLines from "./BudgetLines";

const { Text } = Typography;

const ApprovedBudget = ({
  handleDeleteBudgetLine,
  handleRefreshTable,
  handleExportRecords,
  handleViewBudgetLineDetails,
  handleViewBudgetActivitiesDetails,
  handleDeleteBudgetActivity,
  handleOpenBudgetEditActivityModal,
}) => {
  const [deleteId, setDeleteId] = useState("");
  const [filterTable, setFilterTable] = useState(null);
  const dispatch = useDispatch();
  const {
    approvedBudget: data,
    approvedBudgetLoading: loading,
    budgetsSuccess: success,
    budgetsError: error,
    deleteBudgetLineLoading: deleteLoading,
    deleteBudgetLineSuccess: deleteSuccess,
    deleteBudgetLineError: deleteError,
    deleteBudgetLineMessage: deleteMessage,
    budgetDetails,
    deleteBudgetActivityLoading,
    deleteBudgetActivitySuccess,
    deleteBudgetActivityError,
    deleteBudgetActivityMessage,
  } = useSelector((state) => state.budgetState);
  const {
    success: logDetailsSuccess,
    error: logDetailsError,
    records,
  } = useSelector((state) => state.logDetailsState.addLogDetails);
  const currency = getCurrency(budgetDetails, "approved_budget");
  const columns = [
    {
      title: "Budget Line",
      key: "project",
      render: (text) => (
        <div>
          <span className="d-sm-none">Budget Line:</span> {text.name} <br />
          <div className="d-sm-none">
            <Popover
              className=""
              trigger="click"
              placement="bottom"
              content={() =>
                tableButtonActions(text, {
                  viewDetails,
                  updateRecord,
                  confirmDelete,
                  deleteRecord,
                  deleteLoading,
                  deleteId,
                })
              }
            >
              Actions: <Button type="link">Actions</Button>
            </Popover>
          </div>
        </div>
      ),
    },
    {
      title: "Action",
      key: "operation",
      responsive: ["md"],
      render: (text) => (
        <Popover
          trigger="click"
          placement="bottom"
          content={() =>
            tableButtonActions(text, {
              viewDetails,
              updateRecord,
              confirmDelete,
              deleteRecord,
              deleteLoading,
              deleteId,
            })
          }
        >
          <Button>Actions</Button>
        </Popover>
      ),
    },
  ];
  /* Functions */
  const updateRecord = (e) => {
    const editBudgetLineArray = data.filter((r) => r.id === e.target.id);
    dispatch(budgetActions.editBudgetLineData(editBudgetLineArray[0]));
    dispatch(appUiActions.toggleEditBudgetLineModal(true));
  };
  const deleteRecord = (e) => setDeleteId(e.target.id);
  const confirmDelete = () => {
    const deleteObjectArray = data.filter((r) => r.id === deleteId);
    // const budgetsArray = data.filter((e) => e.id !== deleteId);
    handleDeleteBudgetLine(deleteId, deleteObjectArray[0]?.budget);
  };
  const refreshTable = () => {
    handleRefreshTable();
    if (!success && error) message.error(error);
  };
  const viewDetails = (e) => {
    handleViewBudgetLineDetails(e.target.id);
    //history.push(`${routes.finance.budget.path}/${e.target.id}`);
  };
  const search = (value) => {
    const filterTable = data.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(value.toLowerCase())
      )
    );
    setFilterTable(filterTable);
  };
  const setFilterTableNull = (e) => {
    if (!e.target.value) setFilterTable(null);
  };

  const showTotal = (total) => `Total: ${total}`;
  const openAddModal = () =>
    dispatch(appUiActions.toggleAddBudgetLineModal(true));
  const exportRecords = () => {
    message.loading("Processing document for export please wait ", 3);
    handleExportRecords();
  };
  const generateRecordsPdf = () => {
    const pdfColumns = ["Expense Item", "Units", "Unit Cost", "Total"];
    let pdfRows = [];
    data.forEach((record) => {
      const row = [record.name, "", "", ""];
      pdfRows.push(row);
      record?.activities.forEach((r) => {
        const row = [
          r.name,
          numberWithCommas(r.units),
          `${currency === "D" ? "USD" : "UGX"} ${numberWithCommas(
            r.unit_cost
          )}`,
          `${currency === "D" ? "USD" : "UGX"} ${numberWithCommas(
            r.unit_cost * r.units
          )}`,
        ];
        pdfRows.push(row);
      });
    });
    generatePDF(
      pdfRows,
      pdfColumns,
      `${
        budgetDetails.name
      }  Approved Budget - ${new Date().toLocaleDateString()} `,
      `${budgetDetails.name} Project Budget.pdf`
    );
  };
  const budgetActivitiesTableConfig = {
    deleteId,
    deleteBudgetActivityLoading,
    viewDetails: (id) => handleViewBudgetActivitiesDetails(id),
    updateRecord: (id, records) => {
      const updateData = records.filter((r) => r.id === id);
      dispatch(budgetActions.editBudgetAcitvityData(updateData[0]));
      handleOpenBudgetEditActivityModal(true);
    },
    deleteRecord,
    confirmDelete: () => {
      handleDeleteBudgetActivity(deleteId);
    },
  };
  /* Use effect */
  useEffect(() => {
    if (logDetailsSuccess && records === "approvedBudget") {
      message.info("Started download");
      generateRecordsPdf();
    } else if (!logDetailsSuccess && logDetailsError)
      message.error(logDetailsError);
    if (deleteSuccess && deleteMessage) message.success(deleteMessage);
    else if (!deleteSuccess && deleteError) message.error(deleteError);
    //delete budget
    if (deleteBudgetActivitySuccess && deleteBudgetActivityMessage)
      message.success(deleteBudgetActivityMessage);
    else if (!deleteBudgetActivitySuccess && deleteBudgetActivityError)
      message.error(deleteBudgetActivityError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    logDetailsSuccess,
    logDetailsError,
    deleteSuccess,
    deleteMessage,
    deleteError,
    deleteBudgetActivitySuccess,
    deleteBudgetActivityError,
    deleteBudgetActivityMessage,
  ]);

  return (
    <div>
      <Table
        rowKey={(r) => r.id}
        pagination={{ total: data.length, showSizeChanger: true, showTotal }}
        loading={loading}
        title={() =>
          TableTitle({
            search,
            setFilterTableNull,
            refreshTable,
            exportRecords,
            openAddModal,
          })
        }
        expandable={{
          expandedRowRender: (record) =>
            BudgetLines(record, budgetActivitiesTableConfig, budgetDetails),
        }}
        bordered={true}
        dataSource={filterTable === null ? data : filterTable}
        columns={columns}
        summary={(records) => {
          let grandTotal = 0;
          records.forEach((record) => {
            record?.activities?.forEach((activity) => {
              grandTotal += activity.total_cost;
            });
          });
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Text type="success">
                    Grand Total:{" "}
                    <span className="d-sm-none">
                      {`${currency === "D" ? "USD" : "UGX"} ${numberWithCommas(
                        grandTotal
                      )}`}
                    </span>
                  </Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell className="d-none d-md-block">
                  <Text type="success">{`${
                    currency === "D" ? "USD" : "UGX"
                  } ${numberWithCommas(grandTotal)}`}</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </div>
  );
};

export default ApprovedBudget;
