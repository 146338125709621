import { Alert, Button, message, Modal, Space, Spin, Tabs } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BiddersTable from "../Tables/BiddersTable";
import OtherRecordOfBiddersDetails from "./OtherRecordOfBiddersDetails";
import appConfig from "../../../../config/config.json";
import { rejectionsActions } from "../../../../config/actions/rejections/rejections.actions";
import logDetailThunks from "../../../../config/thunks/settings/logDetails.thunks";

const { TabPane } = Tabs;

const RecordOfBiddersDetails = ({
  visible,
  handleApprove,
  handleSubmit,
  handleCheck,
  handleDeleteBidder,
  handleCloseModal,
  handleOpenAddModal,
  handleRefreshData,
  handleOpenEditModal,
  handleGetBidderDetails,
  handleRejection,
}) => {
  const dispatch = useDispatch();
  const closeModal = () => handleCloseModal(false);
  const {
    recordOfBiddersDetails,
    recordOfBiddersDetailsSuccess,
    recordOfBiddersDetailsLoading,
    recordOfBiddersDetailsError,
    approveRecordOfBiddersLoading,
    approveRecordOfBiddersError,
    approveRecordOfBiddersSuccess,
    approveRecordOfBiddersMessage,
    checkRecordOfBiddersLoading,
    checkRecordOfBiddersSuccess,
    checkRecordOfBiddersMessage,
    checkRecordOfBiddersError,
    submitRecordOfBiddersLoading,
    submitRecordOfBiddersSuccess,
    submitRecordOfBiddersMessage,
    submitRecordOfBiddersError,
  } = useSelector((state) => state.recordOfBiddersState);

  const approve = () => handleApprove(recordOfBiddersDetails.id);
  const check = () => handleCheck(recordOfBiddersDetails.id);
  const submit = () => handleSubmit(recordOfBiddersDetails.id);
  const reject = () => {
    handleRejection(true);
    dispatch(
      rejectionsActions.addProcurementRejectionsData({
        user_to_id: recordOfBiddersDetails?.created_by,
        item_application: "Record Of Receipt Of Bidders",
        item_id: recordOfBiddersDetails?.id,
        itemName: `Record Of Receipt Of Bidders ${recordOfBiddersDetails.id}`,
        applicationPath: "recordOfBidders",
      })
    );
  };
  const exportRecords = () => {
    dispatch(
      logDetailThunks.addLogDetails({
        created_at: new Date(),
        activity: `Exported  Bidders for Record of Bidders ${recordOfBiddersDetails.id} `,
        records: "bidders",
      })
    );
  };

  useEffect(() => {
    if (approveRecordOfBiddersSuccess && approveRecordOfBiddersMessage)
      message.success(approveRecordOfBiddersMessage);
    else if (!approveRecordOfBiddersSuccess && approveRecordOfBiddersError)
      message.error(approveRecordOfBiddersError);
    if (submitRecordOfBiddersSuccess && submitRecordOfBiddersMessage)
      message.success(submitRecordOfBiddersMessage);
    else if (!submitRecordOfBiddersSuccess && submitRecordOfBiddersError)
      message.error(submitRecordOfBiddersError);
    if (checkRecordOfBiddersSuccess && checkRecordOfBiddersMessage)
      message.success(checkRecordOfBiddersMessage);
    else if (!checkRecordOfBiddersSuccess && checkRecordOfBiddersError)
      message.error(checkRecordOfBiddersError);
  }, [
    approveRecordOfBiddersError,
    approveRecordOfBiddersMessage,
    approveRecordOfBiddersSuccess,
    checkRecordOfBiddersError,
    checkRecordOfBiddersMessage,
    checkRecordOfBiddersSuccess,
    submitRecordOfBiddersError,
    submitRecordOfBiddersMessage,
    submitRecordOfBiddersSuccess,
  ]);

  return (
    <div>
      <Modal
        width={800}
        zIndex={500}
        onCancel={closeModal}
        title="RECORD OF BIDDERS FORM DETAILS"
        open={visible}
        footer={[
          recordOfBiddersDetails.status !== appConfig.status.rejected &&
            recordOfBiddersDetails.status !== appConfig.status.approved && (
              <Space>
                {recordOfBiddersDetails.submitted && (
                  <Space>
                    <Button
                      onClick={approve}
                      loading={approveRecordOfBiddersLoading}
                      type="primary"
                    >
                      Approve
                    </Button>
                    {recordOfBiddersDetails.status !==
                      appConfig.status.checked && (
                      <Button
                        onClick={check}
                        loading={checkRecordOfBiddersLoading}
                      >
                        Check
                      </Button>
                    )}
                    <Button onClick={reject} danger>
                      Reject
                    </Button>
                  </Space>
                )}
                {!recordOfBiddersDetails.submitted && (
                  <Button
                    loading={submitRecordOfBiddersLoading}
                    className="bg-light"
                    type="dashed"
                    onClick={submit}
                  >
                    Submit
                  </Button>
                )}
              </Space>
            ),
        ]}
      >
        {recordOfBiddersDetailsLoading ? (
          <div className="d-flex justify-content-center m-5">
            <Spin size="large" />
          </div>
        ) : (
          <div>
            {!recordOfBiddersDetailsSuccess ? (
              <Alert
                type="error"
                message={recordOfBiddersDetailsError}
                showIcon
              />
            ) : (
              <div>
                <Tabs defaultActiveKey="1">
                  <TabPane tab="Bidders" key="1">
                    <BiddersTable
                      handleExportRecords={exportRecords}
                      handleViewDetails={handleGetBidderDetails}
                      handleOpenEditModal={handleOpenEditModal}
                      handleDelete={handleDeleteBidder}
                      handleRefreshTable={() =>
                        handleRefreshData(recordOfBiddersDetails.id)
                      }
                      handleOpenAddModal={handleOpenAddModal}
                    />
                  </TabPane>
                  <TabPane tab="Other Details" key="2">
                    <OtherRecordOfBiddersDetails
                      data={recordOfBiddersDetails}
                    />
                  </TabPane>
                </Tabs>
              </div>
            )}
          </div>
        )}
      </Modal>
    </div>
  );
};

export default RecordOfBiddersDetails;
