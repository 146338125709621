import React from "react";
import { useActivityNoteStore } from "../../../../config/stores";
import AntdModal from "../../../../components/common/AntdModal";
import ActivityConceptNotesActivitiesTable from "./ActivitiesTable";
import ActivitiesForm from "./ActivitiesForm";
import RecordStatusButtons from "../../../../components/common/FormStatusButtons";

const ConceptNoteDetails = () => {
  const activityConceptNotesStore = useActivityNoteStore();
  const {
    showActivityNoteDetails,
    activityNoteDetails,
    gettingActivityNoteDetails,
  } = activityConceptNotesStore;

  const closeModal = () => {
    activityConceptNotesStore.setShowViewActivityNoteDetails(false);
  };
  return (
    <AntdModal
      title={
        gettingActivityNoteDetails ? "Loading..." : activityNoteDetails?.title
      }
      closeModal={closeModal}
      size="large"
      open={showActivityNoteDetails}
      footer={
        <RecordStatusButtons
          requiresSubmitted
          data={activityNoteDetails}
          storeActions={{
            approve: activityConceptNotesStore.approveActivityNote,
            approving: activityConceptNotesStore.approvingActivityNote,
            reject: activityConceptNotesStore.rejectActivityNote,
            rejecting: activityConceptNotesStore.rejectingActivityNote,
            submit: activityConceptNotesStore.submitActivityNote,
            submitting: activityConceptNotesStore.submittingActivityNote,
            check: activityConceptNotesStore.checkActivityNote,
            checking: activityConceptNotesStore.checkingActivityNote,
          }}
        />
      }
    >
      <ActivitiesForm />
      <ActivityConceptNotesActivitiesTable
        canEdit
        activities={activityNoteDetails.activities}
      />
    </AntdModal>
  );
};

export default ConceptNoteDetails;
