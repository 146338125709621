import { Button, message, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { numberWithCommas } from "../../../config/helpers/app/numberFormater";
import routes from "../../../config/routes/routes";
import generatePDF from "../../../config/services/generatePdf";
import TableTitle from "../../shared/Tables/TableTitle";

const MyProjectsTable = ({ handleRefreshTable, handleExportRecords }) => {
  //variables and initialization
  const {
    myProjects: data,
    myProjectsLoading: loading,
    myProjectsSuccess: success,
    myProjectsError: error,
  } = useSelector((state) => state.projectsState);
  const {
    success: logDetailsSuccess,
    error: logDetailsError,
    records,
  } = useSelector((state) => state.logDetailsState.addLogDetails);
  const history = useHistory();
  const [filterTable, setfilterTable] = useState(null);
  const columns = [
    {
      title: "Project",
      key: "projects",
      render: (text) => (
        <div>
          <span className="d-md-none">Project:</span> {text.name} <br />
          <div className="d-md-none">
            Start Date: {`${new Date(text.start_date).toDateString()}`} <br />
            End Date: {`${new Date(text.end_date).toDateString()}`} <br />
            Action:{" "}
            <Button type="link" onClick={() => viewDetails(text.id)}>
              More
            </Button>
          </div>
        </div>
      ),
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      render: (text) => `${new Date(text).toDateString()}`,
      responsive: ["md"],
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
      render: (text) => `${new Date(text).toDateString()}`,
      responsive: ["md"],
    },
    {
      title: "Approved Budget",
      dataIndex: "approved_budget",
      key: "approved_budget",
      render: (text) => {
        try {
          let currency = text[text?.length - 1];
          return (
            <>{`${currency === "D" ? "USD" : "UGX"} ${numberWithCommas(
              parseInt(text)
            )}`}</>
          );
        } catch (error) {
          return "";
        }
      },
      responsive: ["md"],
    },
    {
      title: "Action",
      key: "operation",
      render: (text) => (
        <Button onClick={() => viewDetails(text.id)}>More</Button>
      ),
      responsive: ["md"],
    },
  ];
  //functions
  const viewDetails = (id) => history.push(`${routes.myProjects.path}/${id}`);

  const search = (value) => {
    const filterTable = data.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(value.toLowerCase())
      )
    );
    setfilterTable(filterTable);
  };
  const setfilterTableNull = (e) => {
    if (!e.target.value) setfilterTable(null);
  };
  const refreshTable = () => {
    handleRefreshTable();
    if (!success && error) message.error(error);
  };
  const showTotal = (total) => `Total: ${total}`;
  const openAddModal = () =>
    message.info("Projects can be added from the projects page");
  const generateRecordsPdf = () => {
    const pdfColumns = [
      "Projects",
      "Approved Budget",
      "Start Date",
      "End Date",
      "Status",
    ];
    let pdfRows = [];
    data?.forEach((record) => {
      let currency =
        record?.approved_budget[record?.approved_budget?.length - 1];
      const row = [
        record.name,
        `${currency === "D" ? "USD" : "UGX"} ${parseInt(
          record?.approved_budget
        )}`,
        new Date(record.start_date).toDateString(),
        new Date(record.end_date).toDateString(),
        record.status,
      ];
      pdfRows.push(row);
    });
    generatePDF(
      pdfRows,
      pdfColumns,
      `My Projects - ${new Date().toLocaleDateString()}`,
      "My Projects Projects.pdf"
    );
  };
  const exportRecords = () => {
    message.loading("Proccessing document for export please wait ", 3);
    handleExportRecords();
  };
  //use effects
  useEffect(() => {
    if (logDetailsSuccess && records === "myProjects") {
      message.info("Started download");
      generateRecordsPdf();
    } else if (!logDetailsSuccess && logDetailsError)
      message.error(logDetailsError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logDetailsSuccess, logDetailsError]);

  return (
    <div>
      <Table
        pagination={{ total: data.length, showSizeChanger: true, showTotal }}
        loading={loading}
        title={() =>
          TableTitle({
            search,
            setfilterTableNull,
            refreshTable,
            exportRecords,
            openAddModal,
          })
        }
        bordered={true}
        dataSource={filterTable === null ? data : filterTable}
        columns={columns}
      />
    </div>
  );
};

export default MyProjectsTable;
