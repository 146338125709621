import React from "react";
import { numberWithCommas } from "../../../../config/helpers/app/numberFormater";

const AdvanceAccountabilityFormExpenditureOtherDetails = ({ data }) => {
  return (
    <div>
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th scope="col">Column</th>
            <th scope="col">Data</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Item</td>
            <td>{data.name}</td>
          </tr>

          <tr>
            <td>Budgetted Amount</td>
            <td>
              {numberWithCommas(data.requisitionMemoActivityTotalCost || 0)}
            </td>
          </tr>
          <tr>
            <td>Actual Expenditure</td>
            <td>{numberWithCommas(data.actual_expenditure || 0)}</td>
          </tr>
          <tr>
            <td>Variance</td>
            <td>{numberWithCommas(data.variance || 0)}</td>
          </tr>
          <tr>
            <td>Created By</td>
            <td>{`${data?.creator?.first_name} ${data?.creator?.other_names}`}</td>
          </tr>
          <tr>
            <td>Created At</td>
            <td>
              {`${new Date(data.created_at).toDateString()} ${new Date(
                data.created_at
              ).toLocaleTimeString()}
                        `}
            </td>
          </tr>
          <tr>
            <td>Last Updated By</td>
            <td>
              {data.updator &&
                `${data?.updator?.first_name} ${data?.updator?.other_names}`}
            </td>
          </tr>
          <tr>
            <td>Last Updated At</td>
            <td>
              {data.updated_at &&
                `${new Date(data.updated_at).toDateString()} ${new Date(
                  data.updated_at
                ).toLocaleTimeString()}`}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default AdvanceAccountabilityFormExpenditureOtherDetails;
