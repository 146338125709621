import React, { useEffect } from "react";
import { useStrategicPlansStore } from "../../../config/stores";
import { Col, Divider, Form, Row } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import usePrevious from "../../../components/hooks/usePrevious";
import AntdModal from "../../../components/common/AntdModal";
import AppButton from "../../../components/common/AppButton";
import AppInput from "../../../components/common/AppInput";
import dayjs from "dayjs";

const StrategicPlanForm = () => {
  const strategicPlansStore = useStrategicPlansStore();
  const {
    creating,
    creatingStrategicPlan,
    createdStrategicPlan,
    strategicPlanToUpdate,
    showCreateStrategicPlan,
    updatingStrategicPlan,
  } = strategicPlansStore;
  const [form] = Form.useForm();
  const prevState = usePrevious({ createdStrategicPlan });

  const closeModal = () => {
    strategicPlansStore.setCreating();
  };

  const onSubmit = () => {
    form.validateFields().then((data) => {
      if (creating) {
        strategicPlansStore.createStrategicPlan(data);
      } else {
        strategicPlansStore.updateStrategicPlan(strategicPlanToUpdate.id, data);
      }
    });
  };

  useEffect(() => {
    if (
      prevState &&
      createdStrategicPlan &&
      prevState.createdStrategicPlan !== createdStrategicPlan
    ) {
      form.resetFields();
    }
  }, [prevState, form, createdStrategicPlan]);

  useEffect(() => {
    if (creating) {
      form.setFieldsValue({
        start_date: null,
        end_date: null,
        strategicPlanThematicAreas: [{ thematic_area: "", description: "" }],
      });
    } else {
      form.setFieldsValue({
        ...strategicPlanToUpdate,
        start_date: dayjs(strategicPlanToUpdate.start_date),
        end_date: dayjs(strategicPlanToUpdate.end_date),
      });
    }
  }, [creating, strategicPlanToUpdate, form]);

  return (
    <AntdModal
      title={creating ? "ADD STRATEGIC PLAN" : "EDIT STRATEGIC PLAN"}
      open={showCreateStrategicPlan}
      closeModal={closeModal}
      size="large"
      footer={[
        <AppButton
          text={creating ? "CREATE STRATEGIC PLAN" : "UPDATE STRATEGIC PLAN"}
          key={"create"}
          loading={creatingStrategicPlan || updatingStrategicPlan}
          onClick={onSubmit}
        />,
        <AppButton text="CANCEL" key={"cancel"} onClick={closeModal} danger />,
      ]}
    >
      <Form layout="vertical" form={form}>
        <AppInput
          label="Title"
          name="title"
          rules={[
            {
              required: true,
              message: "Title is required",
            },
          ]}
        />
        <Row gutter={4}>
          <Col span={12}>
            <AppInput
              type="date"
              label="Start Date"
              name="start_date"
              rules={[
                {
                  required: true,
                  message: "Start date is required",
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <AppInput
              type="date"
              label="End Date"
              name="end_date"
              rules={[
                {
                  required: true,
                  message: "End date is required",
                },
              ]}
            />
          </Col>
        </Row>
        <AppInput label="Description" name="description" type="textarea" />
        {creating && (
          <>
            <Divider className="fw-bold">THEMATIC AREAS</Divider>
            <Form.List
              name="strategicPlanThematicAreas"
              rules={[
                {
                  validator: async (_, names) => {
                    if (!names || names.length < 1) {
                      return Promise.reject(
                        new Error("At least 1 thematic area")
                      );
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <Row gutter={4} key={field.key}>
                      <Col span={11}>
                        <AppInput
                          label="Thematic Area"
                          name={[field.name, "thematic_area"]}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message:
                                "Please input passenger's name or delete this field.",
                            },
                          ]}
                        />
                      </Col>
                      <Col span={11}>
                        <AppInput
                          label="Description"
                          name={[field.name, "description"]}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message:
                                "Please input passenger's name or delete this field.",
                            },
                          ]}
                        />
                      </Col>
                      <Col span={2}>
                        <Form.Item label=" ">
                          {fields.length > 1 ? (
                            <span className="text-danger mx-1">
                              <MinusCircleOutlined
                                className="dynamic-delete-button"
                                onClick={() => remove(field.name)}
                              />
                            </span>
                          ) : null}
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                  <Form.Item>
                    <AppButton
                      type="dashed"
                      text="Add Thematic Area"
                      block
                      onClick={() => add()}
                      iconBefore={<PlusOutlined />}
                    />

                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          </>
        )}
      </Form>
      <Divider className="m-1" />
    </AntdModal>
  );
};

export default StrategicPlanForm;
