import React, { useState } from "react";
import { searchTable } from "../../../../../../config/constants";
import TableButtonActions from "../../../../../../components/common/TableButtonActions";
import AntdTable from "../../../../../../components/common/AntdTable";
import TableTitle from "../../../../../../components/common/TableTitle";

const StrategicPlanIndicators = ({
  indicators = [],
  canEdit = false,
  strategicPlansStore = {},
}) => {
  const [filterTable, setFilterTableNull] = useState(null);
  const [deleteId, setDeleteId] = useState(null);

  const search = (value) => {
    const filterTable = searchTable(indicators, value);
    setFilterTableNull(filterTable);
  };

  const confirmDelete = () => {
    strategicPlansStore.deleteStrategicPlanActivityIndicator(deleteId);
    setDeleteId(null);
  };

  const deleteRecord = (id) => setDeleteId(id);

  const updateRecord = (record) => {
    strategicPlansStore.setAddingStrategicPlanActivityIndicator(
      false,
      true,
      record
    );
  };

  const columns = [
    {
      title: "Activity",
      render: (record) => {
        return (
          <div>
            <span className="d-md-none">Indicator:</span>
            {record.indicator}
            <br />
            <div className="d-md-none">
              Actions:
              <TableButtonActions
                record={record}
                confirmDelete={confirmDelete}
                deleteLoading={
                  strategicPlansStore.deletingStrategicPlanActivityIndicator
                }
                updateRecord={updateRecord}
                deleteId={deleteId}
                deleteRecord={deleteRecord}
              />
            </div>
          </div>
        );
      },
    },
    {
      title: "Target",
      dataIndex: "target",
      key: "target",
      render: (record) => record?.toLocaleString(),
    },
    {
      title: "Actions",
      key: "actions",
      responsive: ["md"],
      render: (record) => {
        return (
          <TableButtonActions
            record={record}
            confirmDelete={confirmDelete}
            deleteLoading={
              strategicPlansStore.deletingStrategicPlanActivityIndicator
            }
            updateRecord={canEdit && updateRecord}
            deleteId={deleteId}
            deleteRecord={canEdit && deleteRecord}
          />
        );
      },
    },
  ];

  console.log("indicators", indicators);

  return (
    <div>
      <AntdTable
        serialNumber
        title={() => (
          <TableTitle
            setFilterTableNull={setFilterTableNull}
            openAddModal={null}
            refreshTable={null}
            search={search}
            exportRecords={null}
          />
        )}
        pageSize={20}
        data={filterTable === null ? indicators || [] : filterTable}
        columns={columns}
      />
    </div>
  );
};

export default StrategicPlanIndicators;
