import React from "react";
import { useAssetIncidentFormStore } from "../../../../../config/stores";
import AntdModal from "../../../../../components/common/AntdModal";
import { Alert, Tabs } from "antd";
import FormPrintOut from "./FormPrintOut";
import OtherDetails from "./OtherDetails";
import AppButton from "../../../../../components/common/AppButton";
import { AiOutlineCloseCircle, AiOutlineUpCircle } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { rejectionsActions } from "../../../../../config/actions/rejections/rejections.actions";
import AppLoader from "../../../../../components/common/AppLoader";
import RecordStatusButtons from "../../../../../components/common/FormStatusButtons";

const AssetIncidentFormDetails = () => {
  const assetIncidentFormStore = useAssetIncidentFormStore();
  const {
    showAssetIncidentFormDetails,
    gettingAssetIncidentFormDetailsError,
    assetIncidentFormDetails,
    gettingAssetIncidentFormDetails,
  } = assetIncidentFormStore;
  const dispatch = useDispatch();

  const closeModal = () => {
    assetIncidentFormStore.setShowAssetIncidentFormDetails(false);
  };

  const reject = () => {
    dispatch(
      rejectionsActions.toggleRejectionsModal({
        visible: true,
        data: {
          user_to_id: assetIncidentFormDetails?.created_by,
          item_application: "Asset Incident Form",
          item_id: assetIncidentFormDetails?.id,
          itemName: `Asset Incident Form for asset ${assetIncidentFormDetails?.asset?.name} ${assetIncidentFormDetails?.asset?.code}}`,
        },
      })
    );
  };

  return (
    <div>
      <AntdModal
        open={showAssetIncidentFormDetails}
        title={"ASSET INCIDENT FORM"}
        closeModal={closeModal}
        size="large"
        footer={[
          <RecordStatusButtons
            key={"recordStatusButtons"}
            data={assetIncidentFormDetails}
            storeActions={{
              submit: assetIncidentFormStore.submitAssetIncidentForm,
              submitting: assetIncidentFormStore.submittingAssetIncidentForm,
              check: assetIncidentFormStore.checkAssetIncidentForm,
              checking: assetIncidentFormStore.checkingAssetIncidentForm,
              approve: assetIncidentFormStore.approveAssetIncidentForm,
              approving: assetIncidentFormStore.approvingAssetIncidentForm,
              reject: reject,
            }}
          />,
        ]}
      >
        {gettingAssetIncidentFormDetails && (
          <AppLoader message="Loading Form" />
        )}
        {!gettingAssetIncidentFormDetailsError ? (
          <>
            <Tabs
              items={[
                {
                  label: "ASSET INCIDENT FORM",
                  key: "assetIncidentForm",
                  children: <FormPrintOut />,
                },
                {
                  label: "OTHER DETAILS",
                  key: "otherDetails",
                  children: <OtherDetails />,
                },
              ]}
            />
          </>
        ) : (
          <Alert
            showIcon
            type="error"
            className="m-1"
            message={gettingAssetIncidentFormDetailsError?.message}
          />
        )}
      </AntdModal>
    </div>
  );
};

export default AssetIncidentFormDetails;
