import { appUiActions } from "../../actions/app/appUi.actions";
import { permissionsActions } from "../../actions/settings/permissions.actions";
import {
  createPermissionsRequest,
  deletePermissionsRequest,
  fetchPermissionRequest,
  fetchPermissionsRequest,
  fetchRolesRequest,
  updatePermissionsRequest,
} from "../../api/settings/permissions.api";

const errorMessage =
  "Sorry we are having trouble loading the permissions please try again in few minutes";
const permissionsThunks = {
  getPermissions: () => async (dispatch) => {
    dispatch(permissionsActions.setPermissionsRequest());
    const res = await fetchPermissionsRequest();
    try {
      if (res.success)
        dispatch(permissionsActions.setPermissionsSuccess(res.data));
      else dispatch(permissionsActions.setPermissionsError(res.error));
    } catch (error) {
      console.error("Fetch permission thunk error: ", error);
      dispatch(permissionsActions.setPermissionsError(errorMessage));
    }
  },
  createPermission: (body) => async (dispatch) => {
    dispatch(permissionsActions.createPermissionsRequest());
    try {
      const res = await createPermissionsRequest(body);
      const { message, error, data } = res;
      if (res.success) {
        dispatch(
          permissionsActions.createPermissionsSuccess({
            message,
            data: data[0],
          })
        );
        dispatch(permissionsActions.createPermissionsCompleted());
      } else dispatch(permissionsActions.createPermissionsError(error));
    } catch (error) {
      console.error("Create Permissions thunk error:", error);
      dispatch(permissionsActions.createPermissionsError(errorMessage));
    }
  },
  deletePermission: (id, permissions) => async (dispatch) => {
    dispatch(permissionsActions.deletePermissionsRequest());
    try {
      const res = await deletePermissionsRequest(id);
      if (res.success) {
        dispatch(
          permissionsActions.deletePermissionsSuccess({
            message: res.message,
            data: permissions,
          })
        );
      } else dispatch(permissionsActions.deletePermissionsError(res.error));
      dispatch(permissionsActions.deletePermissionsCompleted());
    } catch (error) {
      console.error("Delete permission thunk error:", error);
      dispatch(permissionsActions.deletePermissionsError(errorMessage));
    }
  },
  updatePermissions: (body, id, permissions) => async (dispatch) => {
    dispatch(permissionsActions.updatePermissionsRequest());
    try {
      const res = await updatePermissionsRequest(body, id);
      if (res.success) {
        dispatch(
          permissionsActions.updatePermissionsSuccess({
            message: res.message,
            permissions,
          })
        );
        dispatch(permissionsActions.updatePermissionsCompleted());
        dispatch(appUiActions.toggleEditPermissionsModal(false));
      } else {
        dispatch(permissionsActions.updatePermissionsError(res.error));
      }
    } catch (error) {
      console.error("Update permissions thunk error:", error);
      dispatch(permissionsActions.updatePermissionsError(errorMessage));
    }
  },
  getPermission: (id) => async (dispatch) => {
    dispatch(permissionsActions.setPermissionRequest());
    try {
      const res = await fetchPermissionRequest(id);
      if (res.success)
        dispatch(
          permissionsActions.setPermissionSuccess({
            message: res.message,
            data: res.data,
          })
        );
      else dispatch(permissionsActions.setPermissionError(res.error));
    } catch (error) {
      console.error("Update permissions thunk error:", error);
      dispatch(permissionsActions.setPermissionError(errorMessage));
    }
  },
  getRoles: () => async (dispatch) => {
    dispatch(permissionsActions.setRolesRequest());
    try {
      const res = await fetchRolesRequest();
      if (res.success) dispatch(permissionsActions.setRolesSuccess(res.data));
      else dispatch(permissionsActions.setRolesError(res.error));
    } catch (error) {
      console.error("set Roles in permissions form thunk error:", error);
      dispatch(permissionsActions.setRolesError(errorMessage));
    }
  },
};

export default permissionsThunks;
