import React, { useState } from "react";
import { useReceiptsStore } from "../../../../config/stores";
import { searchTable } from "../../../../config/constants";
import TableButtonActions from "../../../../components/common/TableButtonActions";
import AntdTable from "../../../../components/common/AntdTable";
import TableTitle from "../../../../components/common/TableTitle";
import dayjs from "dayjs";

const ReceiptsTable = () => {
  const [filterTable, setFilterTableNull] = useState(null);
  const [deleteId, setDeleteId] = useState(null);

  const receiptsStore = useReceiptsStore();

  const search = (value) => {
    const filterTable = searchTable(receiptsStore.receipts?.receipts, value);
    setFilterTableNull(filterTable);
  };
  const confirmDelete = () => {
    receiptsStore.deleteReceipt(deleteId);
    setDeleteId(null);
  };
  const viewDetails = (id) => {
    receiptsStore.setShowViewReceiptDetails(true);
    receiptsStore.getReceiptDetails(id);
  };

  const deleteRecord = (id) => setDeleteId(id);

  const updateRecord = (record) => {
    receiptsStore.setUpdateReceiptData(record);
    receiptsStore.setCreating(true, false, record);
  };
  const columns = [
    {
      title: "Date",
      render: (record) => {
        return (
          <div>
            <span className="d-md-none">Date:</span>{" "}
            {dayjs(record.date).format("DD/MM/YYYY")} <br />
            <div className="d-md-none">
              Amount: {record.amount?.toLocaleString()}
              <br />
              Donor: {record.donor?.name} <br />
              Project: {record.project?.name} <br />
              Project: {record.account?.name} <br />
              <TableButtonActions
                record={record}
                confirmDelete={confirmDelete}
                deleteLoading={receiptsStore.deletingReceipt}
                viewDetails={viewDetails}
                updateRecord={updateRecord}
                deleteId={deleteId}
                deleteRecord={deleteRecord}
              />
            </div>
          </div>
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (amount) => amount?.toLocaleString(),
      responsive: ["md"],
    },
    {
      title: "Account",
      dataIndex: "account",
      render: (account) => account.name,
    },
    {
      title: "Donor",
      dataIndex: "donor",
      render: (donor) => donor.name,
    },
    {
      title: "Project",
      dataIndex: "project",
      render: (project) => project.name,
    },
    {
      title: "Actions",
      key: "actions",
      responsive: ["md"],
      render: (record) => {
        return (
          <TableButtonActions
            record={record}
            confirmDelete={confirmDelete}
            deleteLoading={receiptsStore.deletingReceipt}
            viewDetails={viewDetails}
            updateRecord={updateRecord}
            deleteId={deleteId}
            deleteRecord={deleteRecord}
          />
        );
      },
    },
  ];

  const openAddModal = () => {
    receiptsStore.setCreating(true, true, {});
  };

  const refreshTable = () => {
    receiptsStore.getReceipts();
    receiptsStore.getReceiptsMetaData();
  };
  return (
    <div>
      <AntdTable
        loading={receiptsStore.gettingReceipts}
        title={() => (
          <TableTitle
            setFilterTableNull={setFilterTableNull}
            openAddModal={openAddModal}
            refreshTable={refreshTable}
            search={search}
            exportRecords={null}
          />
        )}
        pageSize={20}
        data={
          filterTable === null
            ? receiptsStore?.receipts?.receipts || []
            : filterTable
        }
        columns={columns}
      />
    </div>
  );
};

export default ReceiptsTable;
