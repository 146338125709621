import { Alert, Button, message, Modal, Space, Spin, Tabs } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdvanceAccountabilityFormExpenditureTable from "./AdvanceAccountabilityFormExpenditureTable";
import AdvanceAccountabilityFormOtherDetails from "./AdvanceAccountabilityFormOtherDetails";
import appConfig from "../../../../config/config.json";
import generateAdvanceAccounatabilityFormPdf from "../../../../config/services/advanceAccountabilityFormPdf";
import { getCurrency } from "../../../../config/helpers/app/numberFormater";
import { rejectionsActions } from "../../../../config/actions/rejections/rejections.actions";

const { TabPane } = Tabs;

const AdvanceAccountabilityFormDetails = ({
  visible,
  handleApprove,
  handleCheck,
  handleSubmit,
  handleViewDetails,
  handleOpenEditModal,
  handleCloseModal,
  handleOpenAddExpenditureModal,
  handleRefresh,
  handleDeleteExpenditure,
  handleOpenAddDocument,
  handleRejection,
}) => {
  const {
    advanceAccountabilityFormsDetailsLoading,
    advanceAccountabilityFormsDetailsSuccess,
    advanceAccountabilityFormsDetailsError,
    advanceAccountabilityFormsDetails,
    submitAdvanceAccountabilityFormsLoading,
    submitAdvanceAccountabilityFormsSuccess,
    submitAdvanceAccountabilityFormsError,
    submitAdvanceAccountabilityFormsMessage,
    approveAdvanceAccountabilityFormsLoading,
    approveAdvanceAccountabilityFormsSuccess,
    approveAdvanceAccountabilityFormsError,
    approveAdvanceAccountabilityFormsMessage,
    checkAdvanceAccountabilityFormsLoading,
    checkAdvanceAccountabilityFormsSuccess,
    checkAdvanceAccountabilityFormsError,
    checkAdvanceAccountabilityFormsMessage,
    advanceAccountabilityFormsExpenditure,
  } = useSelector((state) => state.advanceAccountabilityFormsState);
  const currency = getCurrency(
    advanceAccountabilityFormsDetails?.projectDetails,
    "approved_budget"
  );
  const dispatch = useDispatch();
  const refreshData = () => handleRefresh(advanceAccountabilityFormsDetails.id);
  const submit = () => handleSubmit(advanceAccountabilityFormsDetails.id);
  const approve = () => handleApprove(advanceAccountabilityFormsDetails.id);
  const check = () => handleCheck(advanceAccountabilityFormsDetails.id);
  const exportRecord = () => {
    advanceAccountabilityFormsDetails.status === appConfig.status.approved
      ? generateAdvanceAccounatabilityFormPdf(
          advanceAccountabilityFormsDetails,
          currency,
          advanceAccountabilityFormsExpenditure
        )
      : message.info("The Payment Voucher has not yet been approved");
  };
  const reject = () => {
    dispatch(
      rejectionsActions.toggleRejectionsModal({
        visible: true,
        data: {
          user_to_id: advanceAccountabilityFormsDetails?.created_by,
          item_application: "Payment Vouchers",
          item_id: advanceAccountabilityFormsDetails?.id,
          itemName: `Payment Voucher ${advanceAccountabilityFormsDetails.id}`,
        },
      })
    );
  };
  /* use effect */
  useEffect(() => {
    if (
      submitAdvanceAccountabilityFormsMessage &&
      submitAdvanceAccountabilityFormsSuccess
    )
      message.success(submitAdvanceAccountabilityFormsMessage);
    else if (
      !submitAdvanceAccountabilityFormsSuccess &&
      submitAdvanceAccountabilityFormsError
    )
      message.error(submitAdvanceAccountabilityFormsError);
    //approve
    if (
      approveAdvanceAccountabilityFormsSuccess &&
      approveAdvanceAccountabilityFormsMessage
    )
      message.success(approveAdvanceAccountabilityFormsMessage);
    else if (
      !approveAdvanceAccountabilityFormsSuccess &&
      approveAdvanceAccountabilityFormsError
    )
      message.error(approveAdvanceAccountabilityFormsError);
    //check
    if (
      checkAdvanceAccountabilityFormsSuccess &&
      checkAdvanceAccountabilityFormsMessage
    )
      message.success(checkAdvanceAccountabilityFormsMessage);
    else if (
      !checkAdvanceAccountabilityFormsSuccess &&
      checkAdvanceAccountabilityFormsError
    )
      message.error(checkAdvanceAccountabilityFormsError);
  }, [
    submitAdvanceAccountabilityFormsSuccess,
    submitAdvanceAccountabilityFormsMessage,
    submitAdvanceAccountabilityFormsError,
    approveAdvanceAccountabilityFormsSuccess,
    approveAdvanceAccountabilityFormsMessage,
    approveAdvanceAccountabilityFormsError,
    checkAdvanceAccountabilityFormsSuccess,
    checkAdvanceAccountabilityFormsMessage,
    checkAdvanceAccountabilityFormsError,
  ]);
  return (
    <div>
      <Modal
        width={800}
        onCancel={() => handleCloseModal(false)}
        open={visible}
        footer={[
          advanceAccountabilityFormsDetails.status !==
            appConfig.status.approved && (
            <Space className="mx-2">
              {advanceAccountabilityFormsDetails.submitted ? (
                <Space>
                  <Button
                    onClick={approve}
                    loading={approveAdvanceAccountabilityFormsLoading}
                    type="primary"
                  >
                    Approve
                  </Button>
                  {advanceAccountabilityFormsDetails.status !==
                    appConfig.status.checked && (
                    <Button
                      onClick={check}
                      loading={checkAdvanceAccountabilityFormsLoading}
                    >
                      Check
                    </Button>
                  )}
                  <Button onClick={reject} danger>
                    Reject
                  </Button>
                </Space>
              ) : (
                <Button
                  onClick={submit}
                  loading={submitAdvanceAccountabilityFormsLoading}
                  className="bg-light mx-2"
                  type="dashed"
                >
                  Submit
                </Button>
              )}
            </Space>
          ),
          advanceAccountabilityFormsDetails.status !==
            appConfig.status.approved && (
            <Button onClick={() => handleOpenAddDocument(true)}>
              Add Document
            </Button>
          ),
        ]}
        title="PAYMENT VOUCHER DETAILS"
      >
        {advanceAccountabilityFormsDetailsLoading ? (
          <div className="d-flex justify-content-center ">
            <Spin size="large" />
          </div>
        ) : (
          <div>
            {!advanceAccountabilityFormsDetailsSuccess ? (
              <Alert
                message={advanceAccountabilityFormsDetailsError}
                showIcon
                type="error"
              />
            ) : (
              <div>
                <Tabs defaultActiveKey="1">
                  <TabPane key="1" tab="Actual Expenditure">
                    <AdvanceAccountabilityFormExpenditureTable
                      handleExportRecords={exportRecord}
                      handleViewDetails={handleViewDetails}
                      handleOpenEditModal={handleOpenEditModal}
                      handleDelete={handleDeleteExpenditure}
                      handleRefreshTable={refreshData}
                      handleOpenAddModal={handleOpenAddExpenditureModal}
                    />
                  </TabPane>
                  <TabPane key="2" tab="Other Details">
                    <AdvanceAccountabilityFormOtherDetails
                      data={advanceAccountabilityFormsDetails}
                    />
                  </TabPane>
                </Tabs>
              </div>
            )}
          </div>
        )}
      </Modal>
    </div>
  );
};

export default AdvanceAccountabilityFormDetails;
