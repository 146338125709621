const initialState = {
  rfqs: [],
  rfqsLoading: false,
  rfqsError: "",
  rfqsSuccess: false,

  addRfqsLoading: false,
  addRfqsMessage: "",
  addRfqsSuccess: false,
  addRfqsError: "",

  rfqsDetails: {},
  rfqsDetailsSuccess: false,
  rfqsDetailsLoading: false,
  rfqsDetailsError: "",

  editRfqsSuccess: false,
  editRfqsLoading: false,
  editRfqsData: {},
  editRfqsError: "",
  editRfqsMessage: "",

  deleteRfqsLoading: false,
  deleteRfqsSuccess: false,
  deleteRfqsMessage: "",
  deleteRfqsError: "",

  approveRfqsLoading: false,
  approveRfqsError: "",
  approveRfqsSuccess: false,
  approveRfqsMessage: "",

  checkRfqsLoading: false,
  checkRfqsSuccess: false,
  checkRfqsMessage: "",
  checkRfqsError: "",

  rfqsMetaDataLoading: false,
  rfqsMetaData: {},
};

export default initialState;
