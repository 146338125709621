import React from "react";
import PrintOutHeader from "../../../shared/PrintOutHeader";
import { Table } from "antd";
import PrintOutUserDetails from "../../../shared/PrintOutUserDetails";

const PrintLocalPurchaseOrder = ({ data = {} }) => {
  const columns = [
    {
      title: "Item",
      key: "id",
      dataIndex: "name",
    },
    {
      title: "Units",
      key: "units",
      dataIndex: "units",
      render: (text) => parseInt(text).toLocaleString(),
      responsive: ["md"],
    },
    {
      title: "Unit Cost",
      key: "unit_cost",
      dataIndex: "unit_cost",
      render: (text) => `UGX ${parseInt(text).toLocaleString()}`,
    },
    {
      title: "Total Cost",
      key: "total_cost",
      render: (text) =>
        `UGX ${(
          parseInt(text.units) * parseInt(text.unit_cost)
        ).toLocaleString()}`,
    },
  ];

  let subTotal = 0;
  data.lpoItems.forEach((record) => {
    subTotal += parseInt(record.units) * parseInt(record.unit_cost);
  });
  const discount = subTotal - (data.discount / 100) * subTotal;
  return (
    <div className="border p-2 print-table">
      <PrintOutHeader />
      <Table
        title={() => (
          <div className="w-100 d-flex justify-content-center">
            <h6 className="fw-bold w-100 text-center my-1">
              LOCAL PURCHASE ORDER {data.id}
            </h6>
          </div>
        )}
        size="small"
        bordered
        className="my-2"
        showHeader={false}
        pagination={false}
        columns={[
          {
            dataIndex: "title",
            key: "title",
          },
          {
            dataIndex: "value",
            key: "value",
            render: (text) => (
              <strong className="text-uppercase">{text}</strong>
            ),
          },
        ]}
        dataSource={[
          {
            title: "RFQ No.",
            value: data.rfqDetails?.rfqno,
          },
          {
            title: "SUBJECT OF PROCUREMENT",
            value: data.rfqDetails?.subject,
          },
          {
            title: "BIDDER",
            value: data.bestBidder?.name,
          },
          {
            title: "DISCOUNT %",
            value: `${data.discount}%`,
          },
          {
            title: "TOTAL NET COST",
            value: `UGX ${discount.toLocaleString()}`,
          },
        ]}
      />
      <Table
        size="small"
        pagination={false}
        title={() => <span className="fw-bold">ITEM(S)</span>}
        bordered={true}
        dataSource={data.lpoItems}
        columns={columns}
      />
      <PrintOutUserDetails data={data} />
    </div>
  );
};

export default PrintLocalPurchaseOrder;
