import { Button, Popover, Table } from "antd";
import React from "react";
import { numberWithCommas } from "../../../../config/helpers/app/numberFormater";
import tableButtonActions from "../../../shared/Tables/TableButtonActions";

const StoresRegisterExpandedTable = (record, config) => {
  const { deleteId, deleteLoading } = config;
  const deleteRecord = (e) => config.deleteRecord(e);
  const confirmDelete = () => config.confirmDelete();
  const updateRecord = (e) => config.updateRecord(record, e.target.id);
  const viewDetails = (e) => config.viewDetails(e.target.id);
  const columns = [
    {
      title: "Issued to",
      key: "issued_to_name",
      render: (text) => (
        <div>
          <span className="d-md-none">Issued To:</span> {text.issued_to_name}{" "}
          <br />
          <div className="d-md-none">
            Date Of Issue: {new Date(text.issue_date).toDateString()} <br />
            Quantity: {`UGX ${numberWithCommas(parseInt(text.quantity))}`}{" "}
            <br />
            Total Price: {`UGX ${numberWithCommas(
              parseInt(text.total_price)
            )}`}{" "}
            <br />
            <br />
            <Popover
              className=""
              trigger="click"
              placement="bottom"
              content={() =>
                tableButtonActions(text, {
                  viewDetails,
                  updateRecord,
                  confirmDelete,
                  deleteRecord,
                  deleteLoading,
                  deleteId,
                })
              }
            >
              Actions: <Button type="link">Actions</Button>
            </Popover>
          </div>
        </div>
      ),
    },
    {
      title: "Date Of Issue",
      dataIndex: "issue_date",
      key: "issued_date",
      render: (text) => (
        <>
          {new Date(text).toDateString()} {new Date(text).toLocaleTimeString()}
        </>
      ),
      responsive: ["md"],
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      responsive: ["md"],
    },
    {
      title: "Total Price",
      dataIndex: "total_price",
      key: "total_price",
      render: (text) => numberWithCommas(text),
      responsive: ["md"],
    },
    {
      title: "Action",
      key: "operation",
      responsive: ["md"],
      render: (text) => (
        <Popover
          trigger="click"
          placement="bottom"
          content={() =>
            tableButtonActions(text, {
              viewDetails,
              updateRecord,
              confirmDelete,
              deleteRecord,
              deleteLoading,
              deleteId,
            })
          }
        >
          <Button>Actions</Button>
        </Popover>
      ),
    },
  ];
  return (
    <Table
      rowKey={(r) => r.id}
      columns={columns}
      dataSource={record.issues}
      pagination={false}
      bordered
    />
  );
};

export default StoresRegisterExpandedTable;
