import { Button, message, Popover, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";
import { donorsActions } from "../../../../config/actions/businesDevAndPartnerships/donors/donors.actions";
import { numberWithCommas } from "../../../../config/helpers/app/numberFormater";
import routes from "../../../../config/routes/routes";
import generatePDF from "../../../../config/services/generatePdf";
import tableButtonActions from "../../../shared/Tables/TableButtonActions";
import TableTitle from "../../../shared/Tables/TableTitle";
import appConfig from "../../../../config/config.json";
import {
  AiFillIeCircle,
  AiOutlineCheckCircle,
  AiOutlineExclamationCircle,
  AiOutlineMinusCircle,
} from "react-icons/ai";

const DonorsTable = ({
  handleDelete,
  handleRefreshTable,
  handleExportRecords,
}) => {
  //state,variable, decalrations and initialization
  const [deleteId, setDeleteId] = useState("");
  const [filterTable, setfilterTable] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    loading: deleteLoading,
    success: deleteSuccess,
    error: deleteError,
    message: deleteMessage,
  } = useSelector((state) => state.donorsState.deleteDonor);
  const { data, loading, success, error } = useSelector(
    (state) => state.donorsState.donors
  );
  const {
    success: logDetailsSuccess,
    error: logDetailsError,
    records,
  } = useSelector((state) => state.logDetailsState.addLogDetails);
  const columns = [
    {
      title: "Donor",
      key: "name",
      render: (text) => (
        <div>
          <span className="d-md-none">Donor:</span> {text.name} <br />
          <div className="d-md-none">
            Focus: {text.focus} <br />
            Thematic Area: {text.thematic_area} <br />
            Budget (Upper Limit): {numberWithCommas(parseInt(text.budget))}{" "}
            <br />
            Deadline Of Submissions:{" "}
            {new Date(text.deadline_of_submission).toDateString()} <br />
            Eligibility Status: {text.eligibility_status} <br />
            Proposal Status:{" "}
            {text.proposal_status === appConfig.status.approved && (
              <Tag icon={<AiOutlineCheckCircle />} color="success">
                {text.proposal_status}
              </Tag>
            )}
            {text.proposal_status === appConfig.status.submitted && (
              <Tag icon={<AiOutlineMinusCircle />} color="processing">
                {text.proposal_status}
              </Tag>
            )}
            {!text.proposal_status && (
              <Tag icon={<AiOutlineExclamationCircle />} color="warning">
                Pending
              </Tag>
            )}
            {text.proposal_status === appConfig.status.rejected && (
              <Tag icon={<AiFillIeCircle />} color="error">
                Rejected
              </Tag>
            )}{" "}
            <br />
            <Popover
              className=""
              trigger="click"
              placement="bottom"
              content={() =>
                tableButtonActions(text, {
                  viewDetails,
                  updateRecord,
                  confirmDelete,
                  deleteRecord,
                  deleteLoading,
                  deleteId,
                })
              }
            >
              Actions: <Button type="link">Actions</Button>
            </Popover>
          </div>
        </div>
      ),
    },
    { title: "Focus", dataIndex: "focus", key: "focus", responsive: ["lg"] },
    {
      title: "Thematic Area",
      dataIndex: "thematic_area",
      key: "thematic_area",
      responsive: ["lg"],
    },
    {
      title: "Budget (Upper limit) ",
      key: "budget",
      dataIndex: "budget",
      responsive: ["lg"],
      render: (text) => {
        try {
          let currency = text[text?.length - 1];
          return (
            <>{`${currency === "D" ? "USD" : "UGX"} ${numberWithCommas(
              parseInt(text)
            )}`}</>
          );
        } catch (error) {
          return "";
        }
      },
    },
    {
      title: "Eligibility status/0-10 ",
      dataIndex: "eligibility_status",
      key: "eligibility_status",
      responsive: ["lg"],
    },
    {
      title: "Deadline of submission",
      dataIndex: "deadline_of_submission",
      key: "deadline_of_submission",
      responsive: ["lg"],
      render: (text) => <> {`${new Date(text).toDateString()}`} </>,
    },
    {
      title: "Proposal Status",
      dataIndex: "proposal_status",
      key: "proposal Status",
      responsive: ["md"],
      render: (text) => {
        if (text === appConfig.status.approved)
          return (
            <Tag icon={<AiOutlineCheckCircle />} color="success">
              {text}
            </Tag>
          );
        else if (text === appConfig.status.submitted)
          return (
            <Tag icon={<AiOutlineMinusCircle />} color="processing">
              {text}
            </Tag>
          );
        else if (text === appConfig.status.rejected)
          return (
            <Tag icon={<AiFillIeCircle />} color="error">
              {text}
            </Tag>
          );
        else
          return (
            <Tag icon={<AiOutlineExclamationCircle />} color="warning">
              Pending
            </Tag>
          );
      },
    },
    {
      title: "Action",
      key: "operation",
      responsive: ["md"],
      render: (text) => (
        <Popover
          trigger="click"
          placement="bottom"
          content={() =>
            tableButtonActions(text, {
              viewDetails,
              updateRecord,
              confirmDelete,
              deleteRecord,
              deleteLoading,
              deleteId,
            })
          }
        >
          <Button>Actions</Button>
        </Popover>
      ),
    },
  ];
  /** functions */
  const deleteRecord = (e) => setDeleteId(e.target.id);
  const confirmDelete = () => {
    const deleteObjectArray = data.filter((r) => r.id === deleteId);
    const donorsArray = data.filter((e) => e.id !== deleteId);
    handleDelete(deleteId, deleteObjectArray[0]?.name, donorsArray);
  };
  const refreshTable = () => {
    handleRefreshTable();
    if (!success && error) message.error(error);
  };
  const viewDetails = (e) => {
    history.push(
      `${routes.businessDevelopmentAndPartnerships.donors.path}/${e.target.id}`
    );
  };
  const search = (value) => {
    const filterTable = data.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(value.toLowerCase())
      )
    );
    setfilterTable(filterTable);
  };
  const setfilterTableNull = (e) => {
    if (!e.target.value) setfilterTable(null);
  };

  const showTotal = (total) => `Total: ${total}`;
  const updateRecord = (e) => {
    const updateRecordArray = data.filter((r) => r.id === e.target.id);
    dispatch(donorsActions.setEditData(updateRecordArray[0]));
    dispatch(appUiActions.toggleEditDonorsModal(true));
  };
  const openAddModal = () => dispatch(appUiActions.toggleAddDonorsModal(true));
  const exportRecords = () => {
    message.loading("Proccessing document for export please wait ", 3);
    handleExportRecords();
  };
  const generateRecordsPdf = () => {
    const pdfColumns = [
      "Donor",
      "Focus",
      "Thematic Area",
      "Budget (Upper limit)",
      "Eligibility status/0-10",
      "Deadline of submission",
      "Status",
    ];
    let pdfRows = [];
    data?.forEach((record) => {
      const row = [
        record.name,
        record.focus,
        record.thematic_area,
        record.budget,
        record.eligibility_status,
        `${new Date(record.deadline_of_submission).toDateString()}`,
        record.status,
      ];
      pdfRows.push(row);
    });
    generatePDF(
      pdfRows,
      pdfColumns,
      `Amani Intiative Donors - ${new Date().toLocaleDateString()}`,
      "Amani Intiatice Donors.pdf"
    );
  };
  /** use Effect */
  useEffect(() => {
    if (logDetailsSuccess && records === "donors") {
      message.info("Started download");
      generateRecordsPdf();
    } else if (!logDetailsSuccess && logDetailsError)
      message.error(logDetailsError);
    if (deleteSuccess && deleteMessage) message.success(deleteMessage);
    else if (!deleteSuccess && deleteError) message.error(deleteError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    logDetailsSuccess,
    logDetailsError,
    deleteSuccess,
    deleteMessage,
    deleteError,
  ]);
  return (
    <div>
      <Table
        pagination={{ total: data.length, showSizeChanger: true, showTotal }}
        loading={loading}
        title={() =>
          TableTitle({
            search,
            setfilterTableNull,
            refreshTable,
            exportRecords,
            openAddModal,
          })
        }
        bordered={true}
        dataSource={filterTable === null ? data : filterTable}
        columns={columns}
      />
    </div>
  );
};

export default DonorsTable;
