export const logDetailsConstants = {
  SET_LOG_DETAILS_REQUEST: "SET_LOG_DETAILS_REQUEST",
  SET_LOG_DETAILS_SUCCESS: "SET_LOG_DETAILS_SUCCESS",
  SET_LOG_DETAILS_ERROR: "SET_LOG_DETAILS_ERROR",

  ADD_LOG_DETAILS_REQUEST: "ADD_LOG_DETAILS_REQUEST",
  ADD_LOG_DETAILS_SUCCESS: "ADD_LOG_DETAILS_SUCCESS",
  ADD_LOG_DETAILS_ERROR: "ADD_LOG_DETAILS_ERROR",
  ADD_LOG_DETAILS_COMPLETED: "ADD_LOG_DETAILS_COMPLETED",
};

export const logDetailsActions = {
  setLogDetailsRequest: () => ({
    type: logDetailsConstants.SET_LOG_DETAILS_REQUEST,
  }),
  setLogDetailsSuccess: (payload) => ({
    type: logDetailsConstants.SET_LOG_DETAILS_SUCCESS,
    payload,
  }),
  setLogDetailsError: (payload) => ({
    type: logDetailsConstants.SET_LOG_DETAILS_ERROR,
    payload,
  }),
  addLogDetailsRequest: () => ({
    type: logDetailsConstants.ADD_LOG_DETAILS_REQUEST,
  }),
  addLogDetailSuccess: (payload) => ({
    type: logDetailsConstants.ADD_LOG_DETAILS_SUCCESS,
    payload,
  }),
  addLogDetailsError: (payload) => ({
    type: logDetailsConstants.ADD_LOG_DETAILS_ERROR,
    payload,
  }),
  addLogDetailsCompleted: () => ({
    type: logDetailsConstants.ADD_LOG_DETAILS_COMPLETED,
  }),
};
