import { Alert, Button, Form, message, Modal, Select, Space } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";
import appConfig from "../../../../config/config.json";
import permissionsThunks from "../../../../config/thunks/settings/permissions.thunks";

const { Option } = Select;

const AddPermission = ({ handledAddPermission }) => {
  const dispatch = useDispatch();
  const {
    error,
    success,
    loading,
    message: addPermissionMessage,
  } = useSelector((state) => state.permissionsState.addPermission);
  const roles = useSelector((state) => state.permissionsState.roles);
  const [form] = Form.useForm();

  useEffect(() => {
    if (!roles.success) dispatch(permissionsThunks.getRoles());

    if (success) {
      message.success(addPermissionMessage);
      form.resetFields();
    }
  }, [roles.success, dispatch, success, addPermissionMessage, form]);
  const onFinish = (values) => {
    values.createdAt = new Date();
    for (const key in values) {
      if (!values[key]) values[key] = false;
      else if (values[key] === "true") values[key] = true;
    }
    handledAddPermission(values);
  };
  const visible = useSelector(
    (state) => state.AppUi.settings.addPermissonsModal
  );
  const closeModal = () => {
    dispatch(appUiActions.toggleAddPermissionsModal(false));
  };
  return (
    <div>
      <Modal
        onCancel={closeModal}
        open={visible}
        title="ADD NEW PERMISSION"
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          validateTrigger="onBlur"
          onFinish={onFinish}
        >
          {!success && error && (
            <Alert showIcon message={error} className="m-2" type="error" />
          )}
          <div className="form-inputs">
            <div className="input-fields  p-1">
              <Form.Item
                name="roleId"
                label="Role"
                rules={[{ required: true, message: "Please select a role" }]}
              >
                <Select
                  loading={roles.loading}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {roles.data.map((e, idx) => {
                    return (
                      <Option value={e.id} key={idx}>
                        {e.role}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                name="appFunction"
                label="System application"
                rules={[
                  { required: true, message: "Please select a permission" },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.value
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {appConfig.applicationFunctions.map((e, idx) => {
                    return (
                      <Option value={e} key={idx}>
                        {e}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
            <h3>Permissions</h3>
            <div className="d-md-flex">
              <div className="w-50 w-sm-100">
                <Form.Item name="create">
                  <Space>
                    <Checkbox value={true} defaultChecked={false} />
                    Can Create Records
                  </Space>
                </Form.Item>
                <Form.Item name="read">
                  <Space>
                    <Checkbox value={true} defaultChecked={false} />
                    Can View Records
                  </Space>
                </Form.Item>
                <Form.Item name="update">
                  <Space>
                    <Checkbox value={true} defaultChecked={false} />
                    Can Update records
                  </Space>
                </Form.Item>
              </div>
              <div className="w-50 w-sm-100">
                <Form.Item name="deleteRecord">
                  <Space>
                    <Checkbox value={true} defaultChecked={false} />
                    Can Delete Records
                  </Space>
                </Form.Item>
                <Form.Item name="check">
                  <Space>
                    <Checkbox value={true} defaultChecked={false} />
                    Can Check Records
                  </Space>
                </Form.Item>
                <Form.Item name="approve">
                  <Space>
                    <Checkbox value={true} defaultChecked={false} />
                    Can Approve records
                  </Space>
                </Form.Item>
              </div>
            </div>
          </div>
          <Form.Item>
            <Button
              className="w-100"
              loading={loading}
              htmlType="submit"
              type="primary"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddPermission;
