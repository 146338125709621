import { logDetailsActions } from "../../actions/settings/logDetails.actions";
import logDetailsApiRequests from "../../api/settings/logDetails.api";

const errorMessage =
  "Sorry there seems to be an  error please try again in a few minutes";

const logDetailThunks = {
  getLogDetails: (id) => async (dispatch) => {
    dispatch(logDetailsActions.setLogDetailsRequest());
    try {
      const res = await logDetailsApiRequests.fetchLogDetails(id);
      if (res.success)
        dispatch(logDetailsActions.setLogDetailsSuccess(res.data));
      else dispatch(logDetailsActions.setLogDetailsError(res.error));
    } catch (error) {
      console.error("Log Details thunk error:", error);
      dispatch(logDetailsActions.setLogDetailsError(errorMessage));
    }
  },
  addLogDetails: (body) => async (dispatch) => {
    dispatch(logDetailsActions.addLogDetailsRequest(body));
    const res = await logDetailsApiRequests.addLogDetails(body);
    if (res.success)
      dispatch(
        logDetailsActions.addLogDetailSuccess({
          records: body.records,
          data: res.data,
        })
      );
    else dispatch(logDetailsActions.addLogDetailsError(res.error));
    dispatch(logDetailsActions.addLogDetailsCompleted());
  },
};

export default logDetailThunks;
