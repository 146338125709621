import React, { useCallback, useState } from "react";
import { useStrategicPlansStore } from "../../../../../../config/stores";
import { searchTable } from "../../../../../../config/constants";
import TableButtonActions from "../../../../../../components/common/TableButtonActions";
import AntdTable from "../../../../../../components/common/AntdTable";
import TableTitle from "../../../../../../components/common/TableTitle";
import StrategicPlanIndicators from "./IndicatorsTable";
import AppButton from "../../../../../../components/common/AppButton";

const StrategicPlanActivities = ({
  activities = [],
  canEdit = false,
  hideAdd = false,
}) => {
  const [filterTable, setFilterTableNull] = useState(null);
  const [deleteId, setDeleteId] = useState(null);

  const strategicPlansStore = useStrategicPlansStore();

  const search = (value) => {
    const filterTable = searchTable(activities, value);
    setFilterTableNull(filterTable);
  };

  const confirmDelete = () => {
    strategicPlansStore.deleteStrategicPlanActivity(deleteId);
    setDeleteId(null);
  };

  const deleteRecord = (id) => setDeleteId(id);

  const updateRecord = (record) => {
    strategicPlansStore.setAddingStrategicPlanActivity(false, true, record);
  };

  const columns = [
    {
      title: "Activity",
      render: (record) => {
        return (
          <div>
            <span className="d-md-none">Activity:</span>
            {record.activity}
            <br />
            <div className="d-md-none">
              Actions:
              <TableButtonActions
                record={record}
                confirmDelete={confirmDelete}
                deleteLoading={strategicPlansStore.deletingTimeSheet}
                updateRecord={updateRecord}
                deleteId={deleteId}
                deleteRecord={deleteRecord}
              />
            </div>
          </div>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      width: 100,
      responsive: ["md"],
      render: (record) => {
        return (
          <TableButtonActions
            record={record}
            confirmDelete={confirmDelete}
            deleteLoading={strategicPlansStore.deletingStrategicPlanActivity}
            updateRecord={canEdit && updateRecord}
            deleteId={deleteId}
            deleteRecord={canEdit && deleteRecord}
            extraActions={
              <AppButton
                text="ADD INDICATORS"
                type="dashed"
                onClick={() => {
                  strategicPlansStore.setAddingStrategicPlanActivityIndicator(
                    true,
                    true,
                    {}
                  );
                  strategicPlansStore.setActivityToAddIndicators(record);
                }}
              />
            }
          />
        );
      },
    },
  ];

  const openAddModal = () => {
    strategicPlansStore.setAddingStrategicPlanActivity(true, true, {});
  };

  const refreshTable = () => {
    strategicPlansStore.getStrategicPlanDetails(
      strategicPlansStore.strategicPlanDetails.id
    );
  };

  const rowExpanded = useCallback(
    (record) => {
      console.log(record);
      return (
        <StrategicPlanIndicators
          strategicPlansStore={strategicPlansStore}
          canEdit
          indicators={record.strategicPlansProposedActivitiesIndicators}
        />
      );
    },
    [strategicPlansStore]
  );

  const showAdd = canEdit && !hideAdd;
  return (
    <div>
      <AntdTable
        title={() => (
          <TableTitle
            setFilterTableNull={setFilterTableNull}
            openAddModal={showAdd && openAddModal}
            refreshTable={refreshTable}
            search={search}
            exportRecords={null}
          />
        )}
        expandable={{
          expandRowByClick: true,
          columnWidth: 40,
          expandedRowRender: rowExpanded,
        }}
        pageSize={20}
        data={filterTable === null ? activities || [] : filterTable}
        columns={columns}
      />
    </div>
  );
};

export default StrategicPlanActivities;
