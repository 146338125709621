import { Button, Popover, Table } from "antd";
import React from "react";
import tableButtonActions from "../../../shared/Tables/TableButtonActions";

const AccountsTable = (record, config) => {
  const { deleteAccountLoading: deleteLoading, deleteId } = config;
  const viewDetails = (e) => config.viewDetails(e.target.id);
  const updateRecord = (e) => config.updateAccount(record, e.target.id);
  const confirmDelete = (e) => config.confirmDeleteAccount(record);
  const deleteRecord = (e) => config.deleteRecord(e);
  const columns = [
    {
      title: "Code",
      key: "account_number",
      render: (text) => (
        <div>
          <span className="d-md-none">Code:</span> {text.account_number} <br />
          <div className="d-md-none">
            Account Type: {text.name} <br />
            Details: {text.details} <br />
            <Popover
              className=""
              trigger="click"
              placement="bottom"
              content={() =>
                tableButtonActions(text, {
                  viewDetails,
                  updateRecord,
                  confirmDelete,
                  deleteRecord,
                  deleteLoading,
                  deleteId,
                })
              }
            >
              Actions: <Button type="link">Actions</Button>
            </Popover>
          </div>
        </div>
      ),
    },
    {
      title: "Account",
      dataIndex: "name",
      key: "account name",
      responsive: ["md"],
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
      responsive: ["lg"],
    },
    {
      title: "Action",
      key: "operation",
      responsive: ["md"],
      render: (text) => (
        <Popover
          trigger="click"
          placement="bottom"
          content={() =>
            tableButtonActions(text, {
              viewDetails,
              updateRecord,
              confirmDelete,
              deleteRecord,
              deleteLoading,
              deleteId,
            })
          }
        >
          <Button>Actions</Button>
        </Popover>
      ),
    },
  ];
  return (
    <div>
      <Table
        bordered
        rowClassName={(record) => !record.is_subaccount && "bg-light"}
        rowKey={(r) => r.id}
        columns={columns}
        dataSource={record.accounts}
        pagination={false}
      />
    </div>
  );
};

export default AccountsTable;
