export const rfqsConstants = {
  SET_RFQS_REQUEST: "SET_RFQS_REQUEST",
  SET_RFQS_SUCCESS: "SET_RFQS_SUCCESS",
  SET_RFQS_ERROR: "SET_RFQS_ERROR",

  SET_RFQS_DETAILS_REQUEST: "SET_RFQS_DETAILS_REQUEST",
  SET_RFQS_DETAILS_SUCCESS: "SET_RFQS_DETAILS_SUCCESS",
  SET_RFQS_DETAILS_ERROR: "SET_RFQS_DETAILS_ERROR",

  ADD_RFQS_REQUEST: "ADD_RFQS_REQUEST",
  ADD_RFQS_SUCCESS: "ADD_RFQS_SUCCESS",
  ADD_RFQS_ERROR: "ADD_RFQS_ERROR",
  ADD_RFQS_COMPLETE: "ADD_RFQS_COMPLETE",

  EDIT_RFQS_REQUEST: "EDIT_RFQS_REQUEST",
  EDIT_RFQS_SUCCESS: "EDIT_RFQS_SUCCESS",
  EDIT_RFQS_ERROR: "EDIT_RFQS_ERROR",
  EDIT_RFQS_COMPLETE: "EDIT_RFQS_COMPLETE",
  EDIT_RFQS_DATA: "EDIT_RFQS_DATA",

  DELETE_RFQS_REQUEST: "DELETE_RFQS_REQUEST",
  DELETE_RFQS_SUCCESS: "DELETE_RFQS_SUCCESS",
  DELETE_RFQS_ERROR: "DELETE_RFQS_ERROR",
  DELETE_RFQS_COMPLETE: "DELETE_RFQS_COMPLETE",

  APPROVE_RFQS_SUCCESS: "APPROVE_RFQS_SUCCESS",
  APPROVE_RFQS_REQUEST: "APPROVE_RFQS_REQUEST",
  APPROVE_RFQS_ERROR: "APPROVE_RFQS_ERROR",
  APPROVE_RFQS_COMPLETE: "APPROVE_RFQS_COMPLETE",

  CHECK_RFQS_REQUEST: "CHECK_RFQS_REQUEST",
  CHECK_RFQS_SUCCESS: "CHECK_RFQS_SUCCESS",
  CHECK_RFQS_ERROR: "CHECK_RFQS_ERROR",
  CHECK_RFQS_COMPLETE: "CHECK_RFQS_COMPLETE",

  SET_RFQS_META_DATA_REQUEST: "SET_RFQS_META_DATA_REQUEST",
  SET_RFQS_META_DATA: "SET_RFQS_META_DATA",
};

export const rfqActions = {
  setRfqsRequest: () => ({
    type: rfqsConstants.SET_RFQS_REQUEST,
  }),
  setRfqsSuccess: (payload) => ({
    type: rfqsConstants.SET_RFQS_SUCCESS,
    payload,
  }),
  setRfqsError: (payload) => ({
    type: rfqsConstants.SET_RFQS_ERROR,
    payload,
  }),
  setRfqsDetailsRequest: () => ({
    type: rfqsConstants.SET_RFQS_DETAILS_REQUEST,
  }),
  setRfqsDetailsSuccess: (payload) => ({
    type: rfqsConstants.SET_RFQS_DETAILS_SUCCESS,
    payload,
  }),
  setRfqsDetailsError: (payload) => ({
    type: rfqsConstants.SET_RFQS_DETAILS_ERROR,
    payload,
  }),
  addRfqsRequest: () => ({
    type: rfqsConstants.ADD_RFQS_REQUEST,
  }),
  addRfqsSuccess: (payload) => ({
    type: rfqsConstants.ADD_RFQS_SUCCESS,
    payload,
  }),
  addRfqsError: (payload) => ({
    type: rfqsConstants.ADD_RFQS_ERROR,
    payload,
  }),
  addRfqsComplete: () => ({
    type: rfqsConstants.ADD_RFQS_COMPLETE,
  }),
  editRfqsRequest: () => ({
    type: rfqsConstants.EDIT_RFQS_REQUEST,
  }),
  editRfqsSuccess: (payload) => ({
    type: rfqsConstants.EDIT_RFQS_SUCCESS,
    payload,
  }),
  editRfqsError: (payload) => ({
    type: rfqsConstants.EDIT_RFQS_ERROR,
    payload,
  }),
  editRfqsComplete: () => ({
    type: rfqsConstants.EDIT_RFQS_COMPLETE,
  }),
  editRfqsData: (payload) => ({
    type: rfqsConstants.EDIT_RFQS_DATA,
    payload,
  }),
  deleteRfqsRequest: () => ({
    type: rfqsConstants.DELETE_RFQS_REQUEST,
  }),
  deleteRfqsSuccess: (payload) => ({
    type: rfqsConstants.DELETE_RFQS_SUCCESS,
    payload,
  }),
  deleteRfqsError: (payload) => ({
    type: rfqsConstants.DELETE_RFQS_ERROR,
    payload,
  }),
  deleteRfqsComplete: () => ({
    type: rfqsConstants.DELETE_RFQS_COMPLETE,
  }),
  checkRfqsRequest: () => ({
    type: rfqsConstants.CHECK_RFQS_REQUEST,
  }),
  checkRfqsSuccess: (payload) => ({
    type: rfqsConstants.CHECK_RFQS_SUCCESS,
    payload,
  }),
  checkRfqsError: (payload) => ({
    type: rfqsConstants.CHECK_RFQS_ERROR,
    payload,
  }),
  checkRfqsComplete: () => ({
    type: rfqsConstants.CHECK_RFQS_COMPLETE,
  }),
  approveRfqsRequest: () => ({
    type: rfqsConstants.APPROVE_RFQS_REQUEST,
  }),
  approveRfqsSuccess: (payload) => ({
    type: rfqsConstants.APPROVE_RFQS_SUCCESS,
    payload,
  }),
  approveRfqsError: (payload) => ({
    type: rfqsConstants.APPROVE_RFQS_ERROR,
    payload,
  }),
  approveRfqsComplete: () => ({
    type: rfqsConstants.APPROVE_RFQS_COMPLETE,
  }),
  setRfqsMetaDataRequest: () => ({
    type: rfqsConstants.SET_RFQS_META_DATA_REQUEST,
  }),
  setRfqsMetaData: (payload) => ({
    type: rfqsConstants.SET_RFQS_META_DATA,
    payload,
  }),
};
