export const budgetConstants = {
  SET_BUDGETS_LOADING: "SET_BUDGETS_LOADING",
  SET_BUDGETS_SUCCESS: "SET_BUDGETS_SUCCESS",
  SET_BUDGETS_ERROR: "SET_BUDGETS_ERROR",

  SET_BUDGETS_GRAPH_DATA_LOADING: "SET_BUDGETS_GRAPH_DATA_LOADING",
  SET_BUDGETS_GRAPH_DATA_SUCCESS: "SET_BUDGETS_GRAPH_DATA_SUCCESS",
  SET_BUDGETS_GRAPH_DATA_ERROR: "SET_BUDGETS_GRAPH_DATA_ERROR",

  SET_APPROVED_BUDGET_LOADING: "SET_APPROVED_BUDGET_LOADING",
  SET_APPROVED_BUDGET_SUCCESS: "SET_APPROVED_BUDGET_SUCCESS",
  SET_APPROVED_BUDGET_ERROR: "SET_APPROVED_BUDGET_ERROR",

  SET_BUDGET_MONITORING_STATEMENT_LOADING:
    "SET_BUDGET_MONITORING_STATEMENT_LOADING",
  SET_BUDGET_MONITORING_STATEMENT_SUCCESS:
    "SET_BUDGET_MONITORING_STATEMENT_SUCCESS",
  SET_BUDGET_MONITORING_STATEMENT_ERROR:
    "SET_BUDGET_MONITORING_STATEMENT_ERROR",

  SET_BUDGET_DETAILS_LOADING: "SET_BUDGET_DETAILS_LOADING",
  SET_BUDGET_DETAILS_SUCCESS: "SET_BUDGET_DETAILS_SUCCESS",
  SET_BUDGET_DETAILS_ERROR: "SET_BUDGET_DETAILS_ERROR",

  SET_BUDGET_ACTIVITY_DETAILS_LOADING: "SET_BUDGET_ACTIVITY_DETAILS_LOADING",
  SET_BUDGET_ACTIVITY_DETAILS_SUCCESS: "SET_BUDGET_ACTIVITY_DETAILS_SUCCESS",
  SET_BUDGET_ACTIVITY_DETAILS_ERROR: "SET_BUDGET_ACTIVITY_DETAILS_ERROR",

  SET_BUDGET_LINE_DETAILS_LOADING: "SET_BUDGET_LINE_DETAILS_LOADING",
  SET_BUDGET_LINE_DETAILS_SUCCESS: "SET_BUDGET_LINE_DETAILS_SUCCESS",
  SET_BUDGET_LINE_DETAILS_ERROR: "SET_BUDGET_LINE_DETAILS_ERROR",

  ADD_BUDGET_LOADING: "ADD_BUDGET_LOADING",
  ADD_BUDGET_ERROR: "ADD_BUDGET_ERROR",
  ADD_BUDGET_SUCCESS: "ADD_BUDGET_SUCCESS",
  ADD_BUDGET_COMPLETE: "ADD_BUDGET_COMPLETE",

  ADD_BUDGET_ACTIVITY_LOADING: "ADD_BUDGET_ACTIVITY_LOADING",
  ADD_BUDGET_ACTIVITY_ERROR: "ADD_BUDGET_ACTIVITY_ERROR",
  ADD_BUDGET_ACTIVITY_SUCCESS: "ADD_BUDGET_ACTIVITY_SUCCESS",
  ADD_BUDGET_ACTIVITY_COMPLETE: "ADD_BUDGET_ACTIVITY_COMPLETE",

  ADD_BUDGET_LINE_LOADING: "ADD_BUDGET_LINE_LOADING",
  ADD_BUDGET_LINE_ERROR: "ADD_BUDGET_LINE_ERROR",
  ADD_BUDGET_LINE_SUCCESS: "ADD_BUDGET_LINE_SUCCESS",
  ADD_BUDGET_LINE_COMPLETE: "ADD_BUDGET_LINE_COMPLETE",

  EDIT_BUDGET_LINE_LOADING: "EDIT_BUDGET_LINE_LOADING",
  EDIT_BUDGET_LINE_SUCCESS: "EDIT_BUDGET_LINE_SUCCESS",
  EDIT_BUDGET_LINE_ERROR: "EDIT_BUDGET_LINE_ERROR",
  EDIT_BUDGET_LINE_COMPLETE: "EDIT_BUDGET_LINE_COMPLETE",
  EDIT_BUDGET_LINE_DATA: "EDIT_BUDGET_LINE_DATA",

  EDIT_BUDGET_ACTIVITY_LOADING: "EDIT_BUDGET_ACTIVITY_LOADING",
  EDIT_BUDGET_ACTIVITY_SUCCESS: "EDIT_BUDGET_ACTIVITY_SUCCESS",
  EDIT_BUDGET_ACTIVITY_ERROR: "EDIT_BUDGET_ACTIVITY_ERROR",
  EDIT_BUDGET_ACTIVITY_COMPLETE: "EDIT_BUDGET_ACTIVITY_COMPLETE",
  EDIT_BUDGET_ACTIVITY_DATA: "EDIT_BUDGET_ACTIVITY_DATA",

  DELETE_BUDGET_LOADING: "DELETE_BUDGET_LOADING",
  DELETE_BUDGET_SUCCESS: "DELETE_BUDGET_SUCCESS",
  DELETE_BUDGET_ERROR: "DELETE_BUDGET_ERROR",
  DELETE_BUDGET_COMPLETE: "DELETE_BUDGET_COMPLETE",

  DELETE_BUDGET_LINE_LOADING: "DELETE_BUDGET_LINE_LOADING",
  DELETE_BUDGET_LINE_SUCCESS: "DELETE_BUDGET_LINE_SUCCESS",
  DELETE_BUDGET_LINE_ERROR: "DELETE_BUDGET_LINE_ERROR",
  DELETE_BUDGET_LINE_COMPLETE: "DELETE_BUDGET_LINE_COMPLETE",

  DELETE_BUDGET_ACTIVITY_LOADING: "DELETE_BUDGET_ACTIVITY_LOADING",
  DELETE_BUDGET_ACTIVITY_SUCCESS: "DELETE_BUDGET_ACTIVITY_SUCCESS",
  DELETE_BUDGET_ACTIVITY_ERROR: "DELETE_BUDGET_ACTIVITY_ERROR",
  DELETE_BUDGET_ACTIVITY_COMPLETE: "DELETE_BUDGET_ACTIVITY_COMPLETE",

  APPROVE_BUDGET_LOADING: "APPROVE_BUDGET_LOADING",
  APPROVE_BUDGET_SUCCESS: "APPROVE_BUDGET_SUCCESS",
  APPROVE_BUDGET_ERROR: "APPROVE_BUDGET_ERROR",
  APPROVE_BUDGET_COMPLETE: "APPROVE_BUDGET_COMPLETE",

  CHECK_BUDGET_LOADING: "CHECK_BUDGET_LOADING",
  CHECK_BUDGET_SUCCESS: "CHECK_BUDGET_SUCCESS",
  CHECK_BUDGET_ERROR: "CHECK_BUDGET_ERROR",
  CHECK_BUDGET_COMPLETE: "CHECK_BUDGET_COMPLETE",

  SUBMIT_BUDGET_LOADING: "SUBMIT_BUDGET_LOADING",
  SUBMIT_BUDGET_SUCCESS: "SUBMIT_BUDGET_SUCCESS",
  SUBMIT_BUDGET_ERROR: "SUBMIT_BUDGET_ERROR",
  SUBMIT_BUDGET_COMPLETE: "SUBMIT_BUDGET_COMPLETE",

  META_DATA: "META_DATA",
  META_DATA_LOADING: "META_DATA_LOADING",
};

export const budgetActions = {
  setBudgetsLoading: () => ({
    type: budgetConstants.SET_BUDGETS_LOADING,
  }),
  setBudgetsSucess: (payload) => ({
    type: budgetConstants.SET_BUDGETS_SUCCESS,
    payload,
  }),
  setBudgetsError: (payload) => ({
    type: budgetConstants.SET_BUDGETS_ERROR,
    payload,
  }),
  setBudgetsGraphDataLoading: () => ({
    type: budgetConstants.SET_BUDGETS_GRAPH_DATA_LOADING,
  }),
  setBudgetsGraphDataSucess: (payload) => ({
    type: budgetConstants.SET_BUDGETS_GRAPH_DATA_SUCCESS,
    payload,
  }),
  setBudgetsGraphDataError: (payload) => ({
    type: budgetConstants.SET_BUDGETS_GRAPH_DATA_ERROR,
    payload,
  }),
  setApprovedBudgetsLoading: () => ({
    type: budgetConstants.SET_APPROVED_BUDGET_LOADING,
  }),
  setApprovedBudgetsSucess: (payload) => ({
    type: budgetConstants.SET_APPROVED_BUDGET_SUCCESS,
    payload,
  }),
  setApprovedBudgetsError: (payload) => ({
    type: budgetConstants.SET_APPROVED_BUDGET_ERROR,
    payload,
  }),
  setBudgetMonitoringStatementLoading: () => ({
    type: budgetConstants.SET_BUDGET_MONITORING_STATEMENT_LOADING,
  }),
  setBudgetMonitoringStatementSucess: (payload) => ({
    type: budgetConstants.SET_BUDGET_MONITORING_STATEMENT_SUCCESS,
    payload,
  }),
  setBudgetMonitoringStatementError: (payload) => ({
    type: budgetConstants.SET_BUDGET_MONITORING_STATEMENT_ERROR,
    payload,
  }),
  setBudgetDetailsLoading: () => ({
    type: budgetConstants.SET_BUDGET_DETAILS_LOADING,
  }),
  setBudgetDetailsSucess: (payload) => ({
    type: budgetConstants.SET_BUDGET_DETAILS_SUCCESS,
    payload,
  }),
  setBudgetDetailsError: (payload) => ({
    type: budgetConstants.SET_BUDGET_DETAILS_ERROR,
    payload,
  }),
  setBudgetLineDetailsLoading: () => ({
    type: budgetConstants.SET_BUDGET_LINE_DETAILS_LOADING,
  }),
  setBudgetLineDetailsSucess: (payload) => ({
    type: budgetConstants.SET_BUDGET_LINE_DETAILS_SUCCESS,
    payload,
  }),
  setBudgetLineDetailsError: (payload) => ({
    type: budgetConstants.SET_BUDGET_LINE_DETAILS_ERROR,
    payload,
  }),
  setBudgetActivityDetailsLoading: () => ({
    type: budgetConstants.SET_BUDGET_ACTIVITY_DETAILS_LOADING,
  }),
  setBudgetActivityDetailsSucess: (payload) => ({
    type: budgetConstants.SET_BUDGET_ACTIVITY_DETAILS_SUCCESS,
    payload,
  }),
  setBudgetActivityDetailsError: (payload) => ({
    type: budgetConstants.SET_BUDGET_ACTIVITY_DETAILS_ERROR,
    payload,
  }),
  addBudgetLoading: () => ({
    type: budgetConstants.ADD_BUDGET_LOADING,
  }),
  addBudgetSuccess: (payload) => ({
    type: budgetConstants.ADD_BUDGET_SUCCESS,
    payload,
  }),
  addBudgetError: (payload) => ({
    type: budgetConstants.ADD_BUDGET_ERROR,
    payload,
  }),
  addBudgetComplete: () => ({
    type: budgetConstants.ADD_BUDGET_COMPLETE,
  }),
  addBudgetLineLoading: () => ({
    type: budgetConstants.ADD_BUDGET_LINE_LOADING,
  }),
  addBudgetLineSuccess: (payload) => ({
    type: budgetConstants.ADD_BUDGET_LINE_SUCCESS,
    payload,
  }),
  addBudgetLineError: (payload) => ({
    type: budgetConstants.ADD_BUDGET_LINE_ERROR,
    payload,
  }),
  addBudgetLineComplete: () => ({
    type: budgetConstants.ADD_BUDGET_LINE_COMPLETE,
  }),
  addBudgetActivityLoading: () => ({
    type: budgetConstants.ADD_BUDGET_ACTIVITY_LOADING,
  }),
  addBudgetActivitySuccess: (payload) => ({
    type: budgetConstants.ADD_BUDGET_ACTIVITY_SUCCESS,
    payload,
  }),
  addBudgetActivityError: (payload) => ({
    type: budgetConstants.ADD_BUDGET_ACTIVITY_ERROR,
    payload,
  }),
  addBudgetActivityComplete: () => ({
    type: budgetConstants.ADD_BUDGET_ACTIVITY_COMPLETE,
  }),
  editBudgetLineLoading: () => ({
    type: budgetConstants.EDIT_BUDGET_LINE_LOADING,
  }),
  editBudgetLineSuccess: (payload) => ({
    type: budgetConstants.EDIT_BUDGET_LINE_SUCCESS,
    payload,
  }),
  editBudgetLineError: (payload) => ({
    type: budgetConstants.EDIT_BUDGET_LINE_ERROR,
    payload,
  }),
  editBudgetLineComplete: () => ({
    type: budgetConstants.EDIT_BUDGET_LINE_COMPLETE,
  }),
  editBudgetLineData: (payload) => ({
    type: budgetConstants.EDIT_BUDGET_LINE_DATA,
    payload,
  }),
  editBudgetAcitvityLoading: () => ({
    type: budgetConstants.EDIT_BUDGET_ACTIVITY_LOADING,
  }),
  editBudgetAcitvitySuccess: (payload) => ({
    type: budgetConstants.EDIT_BUDGET_ACTIVITY_SUCCESS,
    payload,
  }),
  editBudgetAcitvityError: (payload) => ({
    type: budgetConstants.EDIT_BUDGET_ACTIVITY_ERROR,
    payload,
  }),
  editBudgetAcitvityComplete: () => ({
    type: budgetConstants.EDIT_BUDGET_ACTIVITY_COMPLETE,
  }),
  editBudgetAcitvityData: (payload) => ({
    type: budgetConstants.EDIT_BUDGET_ACTIVITY_DATA,
    payload,
  }),
  deleteBudgetLoading: () => ({
    type: budgetConstants.DELETE_BUDGET_LOADING,
  }),
  deleteBudgetSuccess: (payload) => ({
    type: budgetConstants.DELETE_BUDGET_SUCCESS,
    payload,
  }),
  deleteBudgetError: (payload) => ({
    type: budgetConstants.DELETE_BUDGET_ERROR,
    payload,
  }),
  deleteBudgetComplete: () => ({
    type: budgetConstants.DELETE_BUDGET_COMPLETE,
  }),
  deleteBudgetLineLoading: () => ({
    type: budgetConstants.DELETE_BUDGET_LINE_LOADING,
  }),
  deleteBudgetLineSuccess: (payload) => ({
    type: budgetConstants.DELETE_BUDGET_LINE_SUCCESS,
    payload,
  }),
  deleteBudgetLineError: (payload) => ({
    type: budgetConstants.DELETE_BUDGET_LINE_ERROR,
    payload,
  }),
  deleteBudgetLineComplete: () => ({
    type: budgetConstants.DELETE_BUDGET_LINE_COMPLETE,
  }),
  deleteBudgetActivityLoading: () => ({
    type: budgetConstants.DELETE_BUDGET_ACTIVITY_LOADING,
  }),
  deleteBudgetActivitySuccess: (payload) => ({
    type: budgetConstants.DELETE_BUDGET_ACTIVITY_SUCCESS,
    payload,
  }),
  deleteBudgetActivityError: (payload) => ({
    type: budgetConstants.DELETE_BUDGET_ACTIVITY_ERROR,
    payload,
  }),
  deleteBudgetActivityComplete: () => ({
    type: budgetConstants.DELETE_BUDGET_ACTIVITY_COMPLETE,
  }),
  approveBudgetLoading: () => ({
    type: budgetConstants.APPROVE_BUDGET_LOADING,
  }),
  approveBudgetSuccess: (payload) => ({
    type: budgetConstants.APPROVE_BUDGET_SUCCESS,
    payload,
  }),
  approveBudgetError: (payload) => ({
    type: budgetConstants.APPROVE_BUDGET_ERROR,
    payload,
  }),
  approveBudgetComplete: (payload) => ({
    type: budgetConstants.APPROVE_BUDGET_COMPLETE,
    payload,
  }),
  checkBudgetLoading: () => ({
    type: budgetConstants.CHECK_BUDGET_LOADING,
  }),
  checkBudgetSuccess: (payload) => ({
    type: budgetConstants.CHECK_BUDGET_SUCCESS,
    payload,
  }),
  checkBudgetError: (payload) => ({
    type: budgetConstants.CHECK_BUDGET_ERROR,
    payload,
  }),
  checkBudgetComplete: (payload) => ({
    type: budgetConstants.CHECK_BUDGET_COMPLETE,
    payload,
  }),
  submitBudgetLoading: () => ({
    type: budgetConstants.SUBMIT_BUDGET_LOADING,
  }),
  submitBudgetSuccess: (payload) => ({
    type: budgetConstants.SUBMIT_BUDGET_SUCCESS,
    payload,
  }),
  submitBudgetError: (payload) => ({
    type: budgetConstants.SUBMIT_BUDGET_ERROR,
    payload,
  }),
  submitBudgetComplete: (payload) => ({
    type: budgetConstants.SUBMIT_BUDGET_COMPLETE,
    payload,
  }),
  metaData: (payload) => ({
    type: budgetConstants.META_DATA,
    payload,
  }),
  metaDataLoading: (payload) => ({
    type: budgetConstants.META_DATA_LOADING,
  }),
};
