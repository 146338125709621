import { message } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RequisitionMemoActivityDetails from "../../../components/Finance/requisitionMemos/Details/RequisitionMemoActivityDetails";
import RequisitionMemoDetails from "../../../components/Finance/requisitionMemos/Details/RequisitionMemoDetails";
import RequisitionMemoTable from "../../../components/Finance/requisitionMemos/Tables/RequisitionMemoTable";
import AddRejections from "../../../components/shared/Rejections/AddRejections";
import routes from "../../../config/routes/routes";
import requisitionMemoThunks from "../../../config/thunks/finance/requisitionMemo/requisitionMemo.thunks";
import logDetailThunks from "../../../config/thunks/settings/logDetails.thunks";

const RequisitionMemo = () => {
  //state varibles and intiailization
  const { requisitionMemosSuccess } = useSelector(
    (state) => state.requisitionMemoState
  );
  const [
    requsitionMemoDetailsModalVisible,
    setRequsitionMemoDetailsModalVisible,
  ] = useState(false);
  const [
    requsitionMemoActivityDetailsModalVisible,
    setRequsitionMemoActivityDetailsModalVisible,
  ] = useState(false);
  const dispatch = useDispatch();
  //ui
  const toggleRequisitionMemoDetailsModalVisible = (value) =>
    setRequsitionMemoDetailsModalVisible(value);
  const toggleRequsitionMemoActivityDetailsModalVisible = (value) =>
    setRequsitionMemoActivityDetailsModalVisible(value);
  //thunks
  const submitRequisitionMemo = (id) =>
    dispatch(requisitionMemoThunks.submitRequisitionMemo(id));
  const checkRequisitionMemo = (id) =>
    dispatch(requisitionMemoThunks.checkRequisitionMemos(id));
  const approveRequisitionMemo = (id) =>
    dispatch(requisitionMemoThunks.approveRequisitionMemos(id));
  const refreshData = () =>
    dispatch(requisitionMemoThunks.getRequisitionMemos());
  const deleteRequisitionMemo = (id) =>
    dispatch(requisitionMemoThunks.deleteRequisitionMemos(id));
  const getRequisitionMemoDetails = (id) => {
    toggleRequisitionMemoDetailsModalVisible(true);
    dispatch(requisitionMemoThunks.getRequisitionMemosDetails(id));
  };
  const getRequisitionMemoActivityDetails = (id) => {
    setRequsitionMemoActivityDetailsModalVisible(true);
    dispatch(requisitionMemoThunks.getRequisitionMemoActivityDetails(id));
  };
  const exportRequisitionMemos = () => {
    dispatch(
      logDetailThunks.addLogDetails({
        created_at: new Date(),
        activity: `Exported Requisition Forms`,
        records: "requisitionMemos",
      })
    );
  };
  //useEffects
  useEffect(() => {
    document.title = routes.finance.requistionMemos.title;
    if (!requisitionMemosSuccess)
      dispatch(requisitionMemoThunks.getRequisitionMemos());
  }, [requisitionMemosSuccess, dispatch]);
  return (
    <div className="container-fluid p-2">
      <h5 className="fw-bold">REQUISITION FORMS</h5>
      <RequisitionMemoTable
        handleExportRecords={exportRequisitionMemos}
        handleViewDetails={getRequisitionMemoDetails}
        handleDelete={deleteRequisitionMemo}
        handleRefreshTable={refreshData}
      />
      <RequisitionMemoDetails
        handleSubmitRequisititionMemo={submitRequisitionMemo}
        handleCheckRequisitionMemo={checkRequisitionMemo}
        handleApproveRequisitionMemo={approveRequisitionMemo}
        handleOpenViewDetails={getRequisitionMemoActivityDetails}
        handleOpenEditModal={() =>
          message.info("You can edit the requsition memos from my projects")
        }
        handleDeleteActivity={() =>
          message.info("You can delete the requsition memos from my projects")
        }
        handleOpenAddActivityModal={() =>
          message.info(
            "You can add the requsition memo actvities from my projects"
          )
        }
        handleRefreshData={getRequisitionMemoDetails}
        handleCloseModal={toggleRequisitionMemoDetailsModalVisible}
        visible={requsitionMemoDetailsModalVisible}
      />
      <RequisitionMemoActivityDetails
        handleCloseModal={toggleRequsitionMemoActivityDetailsModalVisible}
        visible={requsitionMemoActivityDetailsModalVisible}
      />
      <AddRejections />
    </div>
  );
};

export default RequisitionMemo;
