import { advanceAccountabilityFormConstants } from "../../../actions/finance/advanceAccountabilityForm/advanceAccountabilityForm.actions";
import initialState from "../../../initialState/finance/advanceAccountabilityForm/advanceAccountabilityForm";

const advanceAccountabilityFormsState = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case advanceAccountabilityFormConstants.SET_ADVANCE_ACCOUNTABILITY_FORMS_LOADING:
      return {
        ...state,
        advanceAccountabilityFormsLoading: true,
      };
    case advanceAccountabilityFormConstants.SET_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS:
      return {
        ...state,
        advanceAccountabilityFormsSuccess: true,
        advanceAccountabilityFormsLoading: false,
        advanceAccountabilityFormsError: "",
        advanceAccountabilityForms: payload,
      };
    case advanceAccountabilityFormConstants.SET_ADVANCE_ACCOUNTABILITY_FORMS_ERROR:
      return {
        ...state,
        advanceAccountabilityFormsError: payload,
        advanceAccountabilityFormsLoading: false,
        advanceAccountabilityFormsSuccess: false,
      };
    case advanceAccountabilityFormConstants.SET_PROJECT_ADVANCE_ACCOUNTABILITY_FORMS_LOADING:
      return {
        ...state,
        projectAdvanceAccountabilityFormsLoading: true,
      };
    case advanceAccountabilityFormConstants.SET_PROJECT_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS:
      return {
        ...state,
        projectAdvanceAccountabilityFormsSuccess: true,
        projectAdvanceAccountabilityFormsLoading: false,
        projectAdvanceAccountabilityFormsError: "",
        projectAdvanceAccountabilityForms: payload,
      };
    case advanceAccountabilityFormConstants.SET_PROJECT_ADVANCE_ACCOUNTABILITY_FORMS_ERROR:
      return {
        ...state,
        projectAdvanceAccountabilityFormsError: payload,
        projectAdvanceAccountabilityFormsLoading: false,
        projectAdvanceAccountabilityFormsSuccess: false,
      };
    case advanceAccountabilityFormConstants.SET_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_LOADING:
      return {
        ...state,
        advanceAccountabilityFormsExpenditureLoading: true,
      };
    case advanceAccountabilityFormConstants.SET_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_SUCCESS:
      return {
        ...state,
        advanceAccountabilityFormsExpenditureSuccess: true,
        advanceAccountabilityFormsExpenditureLoading: false,
        advanceAccountabilityFormsExpenditureError: "",
        advanceAccountabilityFormsExpenditure: payload,
      };
    case advanceAccountabilityFormConstants.SET_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_ERROR:
      return {
        ...state,
        advanceAccountabilityFormsMonitoringStateError: payload,
        advanceAccountabilityFormsExpenditureLoading: false,
        advanceAccountabilityFormsExpenditureSuccess: false,
      };
    case advanceAccountabilityFormConstants.SET_ADVANCE_ACCOUNTABILITY_FORMS_DETAILS_LOADING:
      return {
        ...state,
        advanceAccountabilityFormsDetailsLoading: true,
      };
    case advanceAccountabilityFormConstants.SET_ADVANCE_ACCOUNTABILITY_FORMS_DETAILS_SUCCESS:
      return {
        ...state,
        advanceAccountabilityFormsDetailsSuccess: true,
        advanceAccountabilityFormsDetailsLoading: false,
        advanceAccountabilityFormsDetailsError: "",
        advanceAccountabilityFormsDetails: payload,
      };
    case advanceAccountabilityFormConstants.SET_ADVANCE_ACCOUNTABILITY_FORMS_DETAILS_ERROR:
      return {
        ...state,
        advanceAccountabilityFormsDetailsError: payload,
        advanceAccountabilityFormsDetailsLoading: false,
        advanceAccountabilityFormsDetailsSuccess: false,
      };
    case advanceAccountabilityFormConstants.SET_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_DETAILS_LOADING:
      return {
        ...state,
        advanceAccountabilityFormsExpenditureDetailsLoading: true,
      };
    case advanceAccountabilityFormConstants.SET_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_DETAILS_SUCCESS:
      return {
        ...state,
        advanceAccountabilityFormsExpenditureDetailsSuccess: true,
        advanceAccountabilityFormsExpenditureDetailsLoading: false,
        advanceAccountabilityFormsExpenditureDetailsError: "",
        advanceAccountabilityFormsExpenditureDetails: payload,
      };
    case advanceAccountabilityFormConstants.SET_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_DETAILS_ERROR:
      return {
        ...state,
        advanceAccountabilityFormsExpenditureDetailsError: payload,
        advanceAccountabilityFormsExpenditureDetailsLoading: false,
        advanceAccountabilityFormsExpenditureDetailsSuccess: false,
      };
    case advanceAccountabilityFormConstants.ADD_ADVANCE_ACCOUNTABILITY_FORMS_LOADING:
      return {
        ...state,
        addAdvanceAccountabilityFormsLoading: true,
      };
    case advanceAccountabilityFormConstants.ADD_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS:
      return {
        ...state,
        addAdvanceAccountabilityFormsSuccess: true,
        addAdvanceAccountabilityFormsLoading: false,
        addAdvanceAccountabilityFormsError: "",
        addAdvanceAccountabilityFormsMessage: payload,
      };
    case advanceAccountabilityFormConstants.ADD_ADVANCE_ACCOUNTABILITY_FORMS_ERROR:
      return {
        ...state,
        addAdvanceAccountabilityFormsLoading: false,
        addAdvanceAccountabilityFormsSuccess: false,
        addAdvanceAccountabilityFormsError: payload,
      };
    case advanceAccountabilityFormConstants.ADD_ADVANCE_ACCOUNTABILITY_FORMS_COMPLETE:
      return {
        ...state,
        addAdvanceAccountabilityFormsSuccess: false,
      };
    case advanceAccountabilityFormConstants.ADD_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_LOADING:
      return {
        ...state,
        addAdvanceAccountabilityFormsExpenditureLoading: true,
      };
    case advanceAccountabilityFormConstants.ADD_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_SUCCESS:
      return {
        ...state,
        addAdvanceAccountabilityFormsExpenditureSuccess: true,
        addAdvanceAccountabilityFormsExpenditureLoading: false,
        addAdvanceAccountabilityFormsExpenditureError: "",
        addAdvanceAccountabilityFormsExpenditureMessage: payload,
      };
    case advanceAccountabilityFormConstants.ADD_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_ERROR:
      return {
        ...state,
        addAdvanceAccountabilityFormsExpenditureLoading: false,
        addAdvanceAccountabilityFormsExpenditureSuccess: false,
        addAdvanceAccountabilityFormsExpenditureError: payload,
      };
    case advanceAccountabilityFormConstants.ADD_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_COMPLETE:
      return {
        ...state,
        addAdvanceAccountabilityFormsExpenditureSuccess: false,
      };
    case advanceAccountabilityFormConstants.ADD_ADVANCE_ACCOUNTABILITY_FORMS_DOCUMENT_LOADING:
      return {
        ...state,
        addAdvanceAccountabilityFormsDocumentLoading: true,
      };
    case advanceAccountabilityFormConstants.ADD_ADVANCE_ACCOUNTABILITY_FORMS_DOCUMENT_SUCCESS:
      return {
        ...state,
        addAdvanceAccountabilityFormsDocumentSuccess: true,
        addAdvanceAccountabilityFormsDocumentLoading: false,
        addAdvanceAccountabilityFormsDocumentError: "",
        addAdvanceAccountabilityFormsDocumentMessage: payload,
      };
    case advanceAccountabilityFormConstants.ADD_ADVANCE_ACCOUNTABILITY_FORMS_DOCUMENT_ERROR:
      return {
        ...state,
        addAdvanceAccountabilityFormsDocumentLoading: false,
        addAdvanceAccountabilityFormsDocumentSuccess: false,
        addAdvanceAccountabilityFormsDocumentError: payload,
      };
    case advanceAccountabilityFormConstants.ADD_ADVANCE_ACCOUNTABILITY_FORMS_DOCUMENT_COMPLETE:
      return {
        ...state,
        addAdvanceAccountabilityFormsDocumentSuccess: false,
      };
    case advanceAccountabilityFormConstants.EDIT_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_LOADING:
      return {
        ...state,
        editAdvanceAccountabilityFormsExpenditureLoading: true,
      };
    case advanceAccountabilityFormConstants.EDIT_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_SUCCESS:
      return {
        ...state,
        editAdvanceAccountabilityFormsExpenditureSuccess: true,
        editAdvanceAccountabilityFormsExpenditureLoading: false,
        editAdvanceAccountabilityFormsExpenditureError: "",
        editAdvanceAccountabilityFormsExpenditureMessage: payload,
      };
    case advanceAccountabilityFormConstants.EDIT_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_ERROR:
      return {
        ...state,
        editAdvanceAccountabilityFormsExpenditureLoading: false,
        editAdvanceAccountabilityFormsExpenditureSuccess: false,
        editAdvanceAccountabilityFormsExpenditureError: payload,
      };
    case advanceAccountabilityFormConstants.EDIT_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_COMPLETE:
      return {
        ...state,
        editAdvanceAccountabilityFormsExpenditureSuccess: false,
      };
    case advanceAccountabilityFormConstants.EDIT_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_DATA:
      return {
        ...state,
        editAdvanceAccountabilityFormsExpenditureFormData: payload,
      };
    case advanceAccountabilityFormConstants.EDIT_ADVANCE_ACCOUNTABILITY_FORMS_LOADING:
      return {
        ...state,
        editAdvanceAccountabilityFormsLoading: true,
      };
    case advanceAccountabilityFormConstants.EDIT_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS:
      return {
        ...state,
        editAdvanceAccountabilityFormsSuccess: true,
        editAdvanceAccountabilityFormsLoading: false,
        editAdvanceAccountabilityFormsError: "",
        editAdvanceAccountabilityFormsMessage: payload,
      };
    case advanceAccountabilityFormConstants.EDIT_ADVANCE_ACCOUNTABILITY_FORMS_ERROR:
      return {
        ...state,
        editAdvanceAccountabilityFormsLoading: false,
        editAdvanceAccountabilityFormsSuccess: false,
        editAdvanceAccountabilityFormsError: payload,
      };
    case advanceAccountabilityFormConstants.EDIT_ADVANCE_ACCOUNTABILITY_FORMS_COMPLETE:
      return {
        ...state,
        editAdvanceAccountabilityFormsSuccess: false,
      };
    case advanceAccountabilityFormConstants.EDIT_ADVANCE_ACCOUNTABILITY_FORMS_DATA:
      return {
        ...state,
        editAdvanceAccountabilityFormsFormData: payload,
      };
    case advanceAccountabilityFormConstants.DELETE_ADVANCE_ACCOUNTABILITY_FORMS_LOADING:
      return {
        ...state,
        deleteAdvanceAccountabilityFormsLoading: true,
      };
    case advanceAccountabilityFormConstants.DELETE_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS:
      return {
        ...state,
        deleteAdvanceAccountabilityFormsSuccess: true,
        deleteAdvanceAccountabilityFormsLoading: false,
        deleteAdvanceAccountabilityFormsError: "",
        deleteAdvanceAccountabilityFormsMessage: payload,
      };
    case advanceAccountabilityFormConstants.DELETE_ADVANCE_ACCOUNTABILITY_FORMS_ERROR:
      return {
        ...state,
        deleteAdvanceAccountabilityFormsLoading: false,
        deleteAdvanceAccountabilityFormsSuccess: false,
        deleteAdvanceAccountabilityFormsError: payload,
      };
    case advanceAccountabilityFormConstants.DELETE_ADVANCE_ACCOUNTABILITY_FORMS_COMPLETE:
      return {
        ...state,
        deleteAdvanceAccountabilityFormsSuccess: false,
        deleteAdvanceAccountabilityFormsError: "",
      };
    case advanceAccountabilityFormConstants.DELETE_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_LOADING:
      return {
        ...state,
        deleteAdvanceAccountabilityFormsExpenditureLoading: true,
      };
    case advanceAccountabilityFormConstants.DELETE_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_SUCCESS:
      return {
        ...state,
        deleteAdvanceAccountabilityFormsExpenditureSuccess: true,
        deleteAdvanceAccountabilityFormsExpenditureLoading: false,
        deleteAdvanceAccountabilityFormsExpenditureError: "",
        deleteAdvanceAccountabilityFormsExpenditureMessage: payload,
      };
    case advanceAccountabilityFormConstants.DELETE_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_ERROR:
      return {
        ...state,
        deleteAdvanceAccountabilityFormsExpenditureLoading: false,
        deleteAdvanceAccountabilityFormsExpenditureSuccess: false,
        deleteAdvanceAccountabilityFormsExpenditureError: payload,
      };
    case advanceAccountabilityFormConstants.DELETE_ADVANCE_ACCOUNTABILITY_FORMS_EXPENDITURE_COMPLETE:
      return {
        ...state,
        deleteAdvanceAccountabilityFormsExpenditureSuccess: false,
        deleteAdvanceAccountabilityFormsExpenditureError: "",
      };
    case advanceAccountabilityFormConstants.DELETE_ADVANCE_ACCOUNTABILITY_FORMS_DOCUMENT_LOADING:
      return {
        ...state,
        deleteAdvanceAccountabilityFormsDocumentLoading: true,
      };
    case advanceAccountabilityFormConstants.DELETE_ADVANCE_ACCOUNTABILITY_FORMS_DOCUMENT_SUCCESS:
      return {
        ...state,
        deleteAdvanceAccountabilityFormsDocumentSuccess: true,
        deleteAdvanceAccountabilityFormsDocumentLoading: false,
        deleteAdvanceAccountabilityFormsDocumentError: "",
        deleteAdvanceAccountabilityFormsDocumentMessage: payload,
      };
    case advanceAccountabilityFormConstants.DELETE_ADVANCE_ACCOUNTABILITY_FORMS_DOCUMENT_ERROR:
      return {
        ...state,
        deleteAdvanceAccountabilityFormsDocumentLoading: false,
        deleteAdvanceAccountabilityFormsDocumentSuccess: false,
        deleteAdvanceAccountabilityFormsDocumentError: payload,
      };
    case advanceAccountabilityFormConstants.DELETE_ADVANCE_ACCOUNTABILITY_FORMS_DOCUMENT_COMPLETE:
      return {
        ...state,
        deleteAdvanceAccountabilityFormsDocumentSuccess: false,
        deleteAdvanceAccountabilityFormsDocumentError: "",
      };
    case advanceAccountabilityFormConstants.APPROVE_ADVANCE_ACCOUNTABILITY_FORMS_LOADING:
      return {
        ...state,
        approveAdvanceAccountabilityFormsLoading: true,
      };
    case advanceAccountabilityFormConstants.APPROVE_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS:
      return {
        ...state,
        approveAdvanceAccountabilityFormsSuccess: true,
        approveAdvanceAccountabilityFormsLoading: false,
        approveAdvanceAccountabilityFormsError: "",
        approveAdvanceAccountabilityFormsMessage: payload,
      };
    case advanceAccountabilityFormConstants.APPROVE_ADVANCE_ACCOUNTABILITY_FORMS_ERROR:
      return {
        ...state,
        approveAdvanceAccountabilityFormsLoading: false,
        approveAdvanceAccountabilityFormsSuccess: false,
        approveAdvanceAccountabilityFormsError: payload,
      };
    case advanceAccountabilityFormConstants.APPROVE_ADVANCE_ACCOUNTABILITY_FORMS_COMPLETE:
      return {
        ...state,
        approveAdvanceAccountabilityFormsSuccess: false,
        approveAdvanceAccountabilityFormsError: "",
      };
    case advanceAccountabilityFormConstants.CHECK_ADVANCE_ACCOUNTABILITY_FORMS_LOADING:
      return {
        ...state,
        checkAdvanceAccountabilityFormsLoading: true,
      };
    case advanceAccountabilityFormConstants.CHECK_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS:
      return {
        ...state,
        checkAdvanceAccountabilityFormsSuccess: true,
        checkAdvanceAccountabilityFormsLoading: false,
        checkAdvanceAccountabilityFormsError: "",
        checkAdvanceAccountabilityFormsMessage: payload,
      };
    case advanceAccountabilityFormConstants.CHECK_ADVANCE_ACCOUNTABILITY_FORMS_ERROR:
      return {
        ...state,
        checkAdvanceAccountabilityFormsLoading: false,
        checkAdvanceAccountabilityFormsSuccess: false,
        checkAdvanceAccountabilityFormsError: payload,
      };
    case advanceAccountabilityFormConstants.CHECK_ADVANCE_ACCOUNTABILITY_FORMS_COMPLETE:
      return {
        ...state,
        checkAdvanceAccountabilityFormsSuccess: false,
        checkAdvanceAccountabilityFormsError: "",
      };
    case advanceAccountabilityFormConstants.SUBMIT_ADVANCE_ACCOUNTABILITY_FORMS_LOADING:
      return {
        ...state,
        submitAdvanceAccountabilityFormsLoading: true,
      };
    case advanceAccountabilityFormConstants.SUBMIT_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS:
      return {
        ...state,
        submitAdvanceAccountabilityFormsSuccess: true,
        submitAdvanceAccountabilityFormsLoading: false,
        submitAdvanceAccountabilityFormsError: "",
        submitAdvanceAccountabilityFormsMessage: payload,
      };
    case advanceAccountabilityFormConstants.SUBMIT_ADVANCE_ACCOUNTABILITY_FORMS_ERROR:
      return {
        ...state,
        submitAdvanceAccountabilityFormsLoading: false,
        submitAdvanceAccountabilityFormsSuccess: false,
        submitAdvanceAccountabilityFormsError: payload,
      };
    case advanceAccountabilityFormConstants.SUBMIT_ADVANCE_ACCOUNTABILITY_FORMS_COMPLETE:
      return {
        ...state,
        submitAdvanceAccountabilityFormsSuccess: false,
        submitAdvanceAccountabilityFormsError: "",
      };
    case advanceAccountabilityFormConstants.META_DATA:
      return {
        ...state,
        metaDataLoading: false,
        metaData: payload,
      };
    case advanceAccountabilityFormConstants.META_DATA_LOADING:
      return {
        ...state,
        metaDataLoading: false,
      };
    default:
      return state;
  }
};

export default advanceAccountabilityFormsState;
