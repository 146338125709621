import React, { useEffect } from "react";
import { useActivityNoteStore } from "../../../config/stores";
import { isEmpty } from "lodash";
import ActivityConceptNotesTable from "./mangeConceptNotes/ActivityConceptNotesTable";
import ConceptNoteDetails from "./mangeConceptNotes/ConceptNoteDetails";

const ActivityConceptNotes = () => {
  const activityConceptNotesStore = useActivityNoteStore();

  const { activityNotes, getActivityNotes } = activityConceptNotesStore;

  useEffect(() => {
    if (isEmpty(activityNotes)) getActivityNotes();
  }, []);

  return (
    <div className="p-2 container-fluid">
      <h5 className="fw-bold">ACTIVITY CONCEPT NOTES</h5>
      <ActivityConceptNotesTable
        activityConceptNotes={activityNotes.data}
        refreshTable={getActivityNotes}
      />
      <ConceptNoteDetails />
    </div>
  );
};

export default ActivityConceptNotes;
