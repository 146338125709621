import React from "react";
import AntdModal from "../../../../components/common/AntdModal";
import { useAccountsStore } from "../../../../config/stores";
import AntdTable from "../../../../components/common/AntdTable";
import dayjs from "dayjs";

const AccountDetails = () => {
  const accountsStore = useAccountsStore();
  const { accountDetails } = accountsStore;

  const closeModal = () => {
    accountsStore.setShowViewAccountDetails(false);
  };
  return (
    <AntdModal
      open={accountsStore.showAccountDetails}
      title={"ACCOUNT DETAILS"}
      footer={null}
      closeModal={closeModal}
    >
      <AntdTable
        showHeader={false}
        title={() => (
          <span className="fw-bold text-uppercase">{accountDetails?.name}</span>
        )}
        bordered={false}
        columns={[
          {
            dataIndex: "title",
          },
          {
            dataIndex: "value",
            render: (value) => <span className="fw-bold">{value}</span>,
          },
        ]}
        data={[
          {
            key: 1,
            title: "Account Name",
            value: accountDetails?.name,
          },
          {
            key: 2,
            title: "Account Number",
            value: accountDetails?.account_number,
          },
          {
            key: 3,
            title: "Type",
            value: accountDetails?.type,
          },
          {
            key: 4,
            title: "Starting Balance",
            value: accountDetails?.starting_balance?.toLocaleString(),
          },
          {
            key: 5,
            title: "Created By",
            value: `${accountDetails?.created_by?.first_name || ""} ${
              accountDetails?.created_by?.other_names || ""
            }`,
          },
          {
            key: 1,
            title: "Created At",
            value: dayjs(accountDetails?.created_at).format("DD/MM/YYYY HH:mm"),
          },
          {
            key: 5,
            title: "Updated By",
            value: `${accountDetails?.updated_by?.first_name || ""} ${
              accountDetails?.updated_by?.other_names || ""
            }`,
          },
          {
            key: 1,
            title: "Updated At",
            value: dayjs(accountDetails?.updated_at).format("DD/MM/YYYY HH:mm"),
          },
        ]}
      />
    </AntdModal>
  );
};

export default AccountDetails;
