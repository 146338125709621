import { staffRecordsConstants } from "../../../actions/administration/staffRecords/staffRecords.actions";
import initialState from "../../../initialState/administration/staffRecords/staffRecords";

const staffRecordsState = (state = initialState, { type, payload }) => {
  switch (type) {
    case staffRecordsConstants.SET_STAFF_RECORDS_REQUEST:
      return {
        ...state,
        staffRecords: { ...state.staffRecords, loading: true },
      };
    case staffRecordsConstants.SET_STAFF_RECORDS_SUCCESS:
      return {
        ...state,
        staffRecords: {
          error: "",
          data: payload,
          loading: false,
          success: true,
        },
      };
    case staffRecordsConstants.SET_STAFF_RECORDS_ERROR:
      return {
        ...state,
        staffRecords: {
          ...state.staffRecords,
          error: payload,
          loading: false,
          success: false,
        },
      };
    case staffRecordsConstants.ADD_STAFF_REQUEST:
      return {
        ...state,
        addStaffRecords: { ...state.addStaffRecords, loading: true },
      };
    case staffRecordsConstants.ADD_STAFF_SUCCESS:
      return {
        ...state,
        addStaffRecords: {
          error: "",
          success: true,
          loading: false,
          message: payload.message,
        },
        staffRecords: {
          ...state.staffRecords,
          data: [payload.data, ...state.staffRecords.data],
        },
      };
    case staffRecordsConstants.ADD_STAFF_ERROR:
      return {
        ...state,
        addStaffRecords: {
          ...state.addStaffRecords,
          error: payload,
          success: false,
          loading: false,
        },
      };
    case staffRecordsConstants.ADD_STAFF_COMPLETED:
      return {
        ...state,
        addStaffRecords: {
          ...state.addStaffRecords,
          success: false,
        },
      };
    case staffRecordsConstants.UPDATE_STAFF_REQUEST:
      return {
        ...state,
        updateStaffRecords: { ...state.updateStaffRecords, loading: true },
      };
    case staffRecordsConstants.UPDATE_STAFF_SUCCESS:
      return {
        ...state,
        updateStaffRecords: {
          ...state.updateStaffRecords,
          error: "",
          loading: false,
          success: true,
          message: payload.message,
        },
        staffRecords: {
          ...state.staffRecords,
          data: payload.data,
        },
      };
    case staffRecordsConstants.SET_UPDATE_STAFF_DATA:
      return {
        ...state,
        updateStaffRecords: {
          ...state.updateStaffRecords,
          data: payload,
        },
      };
    case staffRecordsConstants.UPDATE_STAFF_ERROR:
      return {
        ...state,
        updateStaffRecords: {
          ...state.updateStaffRecords,
          success: false,
          error: payload,
          loading: false,
        },
      };
    case staffRecordsConstants.UPDATE_STAFF_COMPLETED:
      return {
        ...state,
        updateStaffRecords: {
          ...state.updateStaffRecords,
          message: "",
          success: false,
        },
      };
    case staffRecordsConstants.DELETE_STAFF_REQUEST: {
      return {
        ...state,
        deleteStaffRecords: { ...state.deleteStaffRecords, loading: true },
      };
    }
    case staffRecordsConstants.DELETE_STAFF_SUCCESS: {
      return {
        ...state,
        deleteStaffRecords: {
          error: "",
          loading: false,
          success: true,
          message: payload.message,
        },
        staffRecords: {
          ...state.staffRecords,
          data: payload.data,
        },
      };
    }
    case staffRecordsConstants.DELETE_STAFF_ERROR:
      return {
        ...state,
        deleteStaffRecords: {
          ...state.deleteStaffRecords,
          loading: false,
          success: false,
          error: payload,
        },
      };
    case staffRecordsConstants.DELETE_STAFF_COMPLETED:
      return {
        ...state,
        deleteStaffRecords: {
          ...state.deleteStaffRecords,
          success: false,
          error: "",
        },
      };
    case staffRecordsConstants.SET_STAFF_DETAILS_REQUEST:
      return {
        ...state,
        staffDetails: { ...state.staffDetails, loading: true },
      };
    case staffRecordsConstants.SET_STAFF_DETAILS_SUCCESS:
      return {
        ...state,
        staffDetails: {
          success: true,
          loading: false,
          error: "",
          data: payload,
        },
      };
    case staffRecordsConstants.SET_STAFF_DETAILS_ERROR:
      return {
        ...state,
        staffDetails: {
          ...state.staffDetails,
          success: false,
          error: payload,
          loading: false,
        },
      };
    case staffRecordsConstants.STAFF_ACCOUNT_STATUS_REQUEST:
      return {
        ...state,
        staffAccountStatus: { ...state.staffAccountStatus, loading: true },
      };
    case staffRecordsConstants.STAFF_ACCOUNT_STATUS_SUCCESS:
      return {
        ...state,
        staffAccountStatus: {
          error: "",
          message: payload,
          success: true,
          loading: false,
        },
      };
    case staffRecordsConstants.STAFF_ACCOUNT_STATUS_ERROR:
      return {
        ...state,
        staffAccountStatus: {
          ...state.staffAccountStatus,
          error: payload,
          loading: false,
          success: false,
        },
      };
    case staffRecordsConstants.STAFF_ACCOUNT_STATUS_COMPLETED:
      return {
        ...state,
        staffAccountStatus: {
          ...state.staffAccountStatus,
          success: false,
          error: "",
        },
      };
    case staffRecordsConstants.UPDATE_PROFILE_PICTURE_REQUEST:
      return {
        ...state,
        updateProfilePicture: { ...state.updateProfilePicture, loading: true },
      };
    case staffRecordsConstants.UPDATE_PROFILE_PICTURE_SUCCESS:
      return {
        ...state,
        updateProfilePicture: {
          success: true,
          loading: false,
          message: payload,
          error: "",
        },
      };
    case staffRecordsConstants.UPDATE_PROFILE_PICTURE_ERROR:
      return {
        ...state,
        updateProfilePicture: {
          success: false,
          error: payload,
          message: "",
          loading: false,
        },
      };
    case staffRecordsConstants.UPDATE_PROFILE_PICTURE_COMPLETED:
      return {
        ...state,
        updateProfilePicture: {
          ...state.updateStaffRecords,
          success: false,
          error: "",
        },
      };
    case staffRecordsConstants.GENERATE_NEW_CONFIRM_TOKEN_REQUEST:
      return {
        ...state,
        generateNewConfirmToken: {
          ...state.generateNewConfirmToken,
          loading: true,
        },
      };
    case staffRecordsConstants.GENERATE_NEW_CONFIRM_TOKEN_SUCCESS:
      return {
        ...state,
        generateNewConfirmToken: {
          success: true,
          loading: false,
          error: "",
          message: payload,
        },
      };
    case staffRecordsConstants.GENERATE_NEW_CONFIRM_TOKEN_ERROR:
      return {
        ...state,
        generateNewConfirmToken: {
          success: false,
          error: payload,
          message: "",
          loading: false,
        },
      };
    case staffRecordsConstants.GENERATE_NEW_CONFIRM_TOKEN_COMPLETE:
      return {
        ...state,
        generateNewConfirmToken: {
          success: false,
          error: "",
        },
      };
    case staffRecordsConstants.UPLOAD_STAFF_SIGNATURE_REQUEST:
      return {
        ...state,
        uploadStaffSignatureLoading: true,
      };
    case staffRecordsConstants.UPLOAD_STAFF_SIGNATURE_SUCCESS:
      return {
        ...state,
        uploadStaffSignatureSuccess: true,
        uploadStaffSignatureMessage: payload,
        uploadStaffSignatureError: "",
        uploadStaffSignatureLoading: false,
      };
    case staffRecordsConstants.UPLOAD_STAFF_SIGNATURE_ERROR:
      return {
        ...state,
        uploadStaffSignatureSuccess: false,
        uploadStaffSignatureError: payload,
        uploadStaffSignatureLoading: false,
      };
    case staffRecordsConstants.UPLOAD_STAFF_SIGNATURE_COMPLETE:
      return {
        ...state,
        uploadStaffSignatureSuccess: false,
      };
    default:
      return state;
  }
};

export default staffRecordsState;
