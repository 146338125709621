import { Alert, Modal, Spin } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { numberWithCommas } from "../../../../config/helpers/app/numberFormater";

const LocalPurchaseOrderItemDetails = ({ visible, handleCloseModal }) => {
  const {
    lpoItemsDetailsLoading,
    lpoItemsDetailsSuccess,
    lpoItemsDetailsError,
    lpoItemsDetails: data,
  } = useSelector((state) => state.localPurchaseOrdersState);

  const closeModal = () => handleCloseModal(false);
  return (
    <div>
      <Modal
        open={visible}
        onCancel={closeModal}
        title={data.name || "LOCAL PURCHASE ORDER ITEM"}
        footer={null}
      >
        {lpoItemsDetailsLoading ? (
          <div className="m-5 d-flex justify-content-center">
            <Spin size="large" />
          </div>
        ) : (
          <div>
            {!lpoItemsDetailsSuccess ? (
              <Alert
                showIcon
                message={lpoItemsDetailsError}
                className="m-2"
                type="error"
              />
            ) : (
              <div className="details_div">
                <table className="table table-bordered table-hover">
                  <thead>
                    <tr>
                      <th scope="col">Column</th>
                      <th scope="col">Data</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Units</td>
                      <td>{numberWithCommas(data.units)}</td>
                    </tr>
                    <tr>
                      <td>Unit Cost</td>
                      <td>{`${numberWithCommas(parseInt(data.unit_cost))}`}</td>
                    </tr>
                    <tr>
                      <td>Total Cost</td>
                      <td>{` ${numberWithCommas(
                        parseInt(data.unit_cost) * parseInt(data.units)
                      )}`}</td>
                    </tr>
                    <tr>
                      <td>Created By</td>
                      <td>{`${data?.creator?.first_name} ${data?.creator?.other_names}`}</td>
                    </tr>
                    <tr>
                      <td>Created At</td>
                      <td>
                        {`${new Date(
                          data.created_at
                        ).toDateString()} ${new Date(
                          data.created_at
                        ).toLocaleTimeString()}
                            `}
                      </td>
                    </tr>
                    <tr>
                      <td>Last Updated By</td>
                      <td>
                        {data.updator &&
                          `${data?.updator?.first_name} ${data?.updator?.other_names}`}
                      </td>
                    </tr>
                    <tr>
                      <td>Last Updated At</td>
                      <td>
                        {data.updated_at &&
                          `${new Date(
                            data.updated_at
                          ).toDateString()} ${new Date(
                            data.updated_at
                          ).toLocaleTimeString()}`}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}
      </Modal>
    </div>
  );
};

export default LocalPurchaseOrderItemDetails;
