import React, { useEffect } from "react";
import { Col, Divider, Form, Row, message } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { flatMap, map } from "lodash";
import { useStrategicPlansStore } from "../../../../../../config/stores";
import { inputNumberFormatter } from "../../../../../../config/constants";
import usePrevious from "../../../../../../components/hooks/usePrevious";
import AppInput from "../../../../../../components/common/AppInput";
import AppButton from "../../../../../../components/common/AppButton";
import AntdModal from "../../../../../../components/common/AntdModal";

const IndicatorsForm = () => {
  const strategicPlansStore = useStrategicPlansStore();
  const {
    addingStrategicPlanActivityIndicator,
    strategicPlanActivityIndicatorToUpdate,
    creatingStrategicPlanActivityIndicator,
    createdStrategicPlanActivityIndicator,
    showAddStrategicPlanActivityIndicator,
    updatingStrategicPlanActivityIndicator,
    strategicPlanDetails,
    activityToAddIndicators,
  } = strategicPlansStore;
  const [form] = Form.useForm();
  const prevState = usePrevious({ createdStrategicPlanActivityIndicator });

  const closeModal = () => {
    strategicPlansStore.setAddingStrategicPlanActivityIndicator();
  };

  const onSubmit = () => {
    form
      .validateFields()
      .then((data) => {
        if (addingStrategicPlanActivityIndicator) {
          data.strategicPlansProposedActivitiesIndicators = map(
            data.strategicPlansProposedActivitiesIndicators,
            (indicator) => {
              return {
                ...indicator,
                strategic_plan_id: strategicPlanDetails.id,
                activity_id: activityToAddIndicators.id,
              };
            }
          );
          strategicPlansStore.createStrategicPlanActivityIndicator(data);
        } else {
          strategicPlansStore.updateStrategicPlanActivityIndicator(
            strategicPlanActivityIndicatorToUpdate.id,
            data
          );
        }
      })
      .catch((err) => {
        const errorMessages = flatMap(err.errorFields, "errors").join(", ");
        message.error(errorMessages);
      });
  };

  useEffect(() => {
    if (
      prevState &&
      createdStrategicPlanActivityIndicator &&
      prevState.createdStrategicPlanActivityIndicator !==
        createdStrategicPlanActivityIndicator
    ) {
      form.resetFields();
    }
  }, [prevState, form, createdStrategicPlanActivityIndicator]);

  useEffect(() => {
    if (addingStrategicPlanActivityIndicator) {
      form.setFieldsValue({
        strategicPlansProposedActivitiesIndicators: [{}],
      });
    } else {
      form.setFieldsValue(strategicPlanActivityIndicatorToUpdate);
    }
  }, [
    addingStrategicPlanActivityIndicator,
    strategicPlanActivityIndicatorToUpdate,
    form,
  ]);

  return (
    <AntdModal
      title={
        addingStrategicPlanActivityIndicator
          ? "ADD INDICATOR"
          : "EDIT INDICATOR"
      }
      zIndex={1000}
      open={showAddStrategicPlanActivityIndicator}
      closeModal={closeModal}
      size="large"
      footer={[
        <AppButton
          text={
            addingStrategicPlanActivityIndicator
              ? "CREATE INDICATOR"
              : "UPDATE INDICATOR"
          }
          key={"create"}
          loading={
            creatingStrategicPlanActivityIndicator ||
            updatingStrategicPlanActivityIndicator
          }
          onClick={onSubmit}
        />,
        <AppButton text="CANCEL" key={"cancel"} onClick={closeModal} danger />,
      ]}
    >
      <Form layout="vertical" form={form}>
        {addingStrategicPlanActivityIndicator ? (
          <>
            <Form.List
              name="strategicPlansProposedActivitiesIndicators"
              rules={[
                {
                  validator: async (_, names) => {
                    if (!names || names.length < 1) {
                      return Promise.reject(
                        new Error("At least 1 thematic area")
                      );
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <Row gutter={4} key={field.key}>
                      <Col span={10}>
                        <AppInput
                          label="Indicator"
                          name={[field.name, "indicator"]}
                          rules={[
                            {
                              required: true,
                              message: "Please input indicator",
                            },
                          ]}
                        />
                      </Col>
                      <Col span={5}>
                        <AppInput
                          label="Unit"
                          name={[field.name, "target_unit"]}
                          rules={[
                            {
                              required: true,
                              message: "Please input indicator",
                            },
                          ]}
                        />
                      </Col>
                      <Col span={5}>
                        <AppInput
                          label="Target"
                          type="number"
                          name={[field.name, "target"]}
                          rules={[
                            {
                              required: true,
                              message: "Please input targe",
                            },
                          ]}
                          inputAttributes={{
                            formatter: inputNumberFormatter,
                          }}
                        />
                      </Col>
                      <Col span={2}>
                        <Form.Item label=" ">
                          {fields.length > 1 ? (
                            <span className="text-danger mx-1">
                              <MinusCircleOutlined
                                className="dynamic-delete-button"
                                onClick={() => remove(field.name)}
                              />
                            </span>
                          ) : null}
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                  <Form.Item>
                    <AppButton
                      type="dashed"
                      text="Add Activity"
                      block
                      onClick={() => add()}
                      iconBefore={<PlusOutlined />}
                    />

                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          </>
        ) : (
          <>
            {" "}
            <AppInput
              label="Indicator"
              name="indicator"
              type="textarea"
              rules={[
                {
                  required: true,
                  message: "Indicator is required",
                },
              ]}
            />
            <AppInput
              label="Unit"
              name={"target_unit"}
              rules={[
                {
                  required: true,
                  message: "Please input indicator",
                },
              ]}
            />
            <AppInput
              label="Target"
              type="number"
              name={"target"}
              rules={[
                {
                  required: true,
                  message: "Please input targe",
                },
              ]}
              inputAttributes={{
                formatter: inputNumberFormatter,
              }}
            />
          </>
        )}
      </Form>
      <Divider className="m-1" />
    </AntdModal>
  );
};

export default IndicatorsForm;
