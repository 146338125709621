export const manualsAndPolciesConstants = {
  SET_MANUALS_AND_POLICES_REQUEST: "SET_MANUALS_AND_POLICES_REQUEST",
  SET_MANUALS_AND_POLICES_SUCCESS: "SET_MANUALS_AND_POLICES_SUCCESS",
  SET_MANUALS_AND_POLICES_ERROR: "SET_MANUALS_AND_POLICES_ERROR",

  SET_MANUALS_AND_POLICES_DETAILS_REQUEST:
    "SET_MANUALS_AND_POLICES_DETAILS_REQUEST",
  SET_MANUALS_AND_POLICES_DETAILS_SUCCESS:
    "SET_MANUALS_AND_POLICES_DETAILS_SUCCESS",
  SET_MANUALS_AND_POLICES_DETAILS_ERROR:
    "SET_MANUALS_AND_POLICES_DETAILS_ERROR",

  ADD_MANUALS_AND_POLICIES_REQUEST: "ADD_MANUALS_AND_POLICIES_REQUEST",
  ADD_MANUALS_AND_POLICIES_SUCCESS: "ADD_MANUALS_AND_POLICIES_SUCCESS",
  ADD_MANUALS_AND_POLICIES_ERROR: "ADD_MANUALS_AND_POLICIES_ERROR",
  ADD_MANUALS_AND_POLICIES_COMPLETED: "ADD_MANUALS_AND_POLICIES_COMPLETED",

  UPDATE_MANUALS_AND_POLICIES_REQUEST: "UPDATE_MANUALS_AND_POLICIES_REQUEST",
  UPDATE_MANUALS_AND_POLICIES_SUCCESS: "UPDATE_MANUALS_AND_POLICIES_SUCCESS",
  UPDATE_MANUALS_AND_POLICIES_ERROR: "UPDATE_MANUALS_AND_POLICIES_ERROR",
  SET_UPDATE_MANUALS_AND_POLICIES_DATA: "SET_UPDATE_MANUALS_AND_POLICIES_DATA",
  UPDATE_MANUALS_AND_POLICIES_COMPLETED:
    "UPDATE_MANUALS_AND_POLICIES_COMPLETED",

  DELETE_MANUALS_AND_POLICIES_REQUEST: "DELETE_MANUALS_AND_POLICIES_REQUEST",
  DELETE_MANUALS_AND_POLICIES_SUCCESS: "DELETE_MANUALS_AND_POLICIES_SUCCESS",
  DELETE_MANUALS_AND_POLICIES_ERROR: "DELETE_MANUALS_AND_POLICIES_ERROR",
  DELETE_MANUALS_AND_POLICIES_COMPLETED:
    "DELETE_MANUALS_AND_POLICIES_COMPLETED",

  APPROVE_MANUALS_AND_POLICIES_REQUEST: "APPROVE_MANUALS_AND_POLICIES_REQUEST",
  APPROVE_MANUALS_AND_POLICIES_SUCCESS: "APPROVE_MANUALS_AND_POLICIES_SUCCESS",
  APPROVE_MANUALS_AND_POLICIES_ERROR: "APPROVE_MANUALS_AND_POLICIES_ERROR",
  APPROVE_MANUALS_AND_POLICIES_COMPLETED:
    "APPROVE_MANUALS_AND_POLICIES_COMPLETED",

  CHECK_MANUALS_AND_POLICIES_REQUEST: "CHECK_MANUALS_AND_POLICIES_REQUEST",
  CHECK_MANUALS_AND_POLICIES_SUCCESS: "CHECK_MANUALS_AND_POLICIES_SUCCESS",
  CHECK_MANUALS_AND_POLICIES_ERROR: "CHECK_MANUALS_AND_POLICIES_ERROR",
  CHECK_MANUALS_AND_POLICIES_COMPLETED: "CHECK_MANUALS_AND_POLICIES_COMPLETED",

  DOWNLOAD_MANUALS_AND_POLICIES_REQUEST:
    "DOWNLOAD_MANUALS_AND_POLICIES_REQUEST",
  DOWNLOAD_MANUALS_AND_POLICIES_SUCCESS:
    "DOWNLOAD_MANUALS_AND_POLICIES_SUCCESS",
  DOWNLOAD_MANUALS_AND_POLICIES_ERROR: "DOWNLOAD_MANUALS_AND_POLICIES_ERROR",
  DOWNLOAD_MANUALS_AND_POLICIES_COMPLETED:
    "DOWNLOAD_MANUALS_AND_POLICIES_COMPLETED",
};

export const manualsAndPoliciesActions = {
  setManualsAndPoliciesRequest: () => ({
    type: manualsAndPolciesConstants.SET_MANUALS_AND_POLICES_REQUEST,
  }),
  setManualsAndPoliciesSuccess: (payload) => ({
    type: manualsAndPolciesConstants.SET_MANUALS_AND_POLICES_SUCCESS,
    payload,
  }),
  setManualsAndPoliciesError: (payload) => ({
    type: manualsAndPolciesConstants.SET_MANUALS_AND_POLICES_ERROR,
    payload,
  }),
  addManualsAndPoliciesRequest: () => ({
    type: manualsAndPolciesConstants.ADD_MANUALS_AND_POLICIES_REQUEST,
  }),
  addManualsAndPoliciesSuccess: (payload) => ({
    type: manualsAndPolciesConstants.ADD_MANUALS_AND_POLICIES_SUCCESS,
    payload,
  }),
  addManualsAndPoliciesError: (payload) => ({
    type: manualsAndPolciesConstants.ADD_MANUALS_AND_POLICIES_ERROR,
    payload,
  }),
  addManualsAndPoliciesCompleted: () => ({
    type: manualsAndPolciesConstants.ADD_MANUALS_AND_POLICIES_COMPLETED,
  }),
  updateManualsAndPoliciesRequest: () => ({
    type: manualsAndPolciesConstants.UPDATE_MANUALS_AND_POLICIES_REQUEST,
  }),
  updateManualsAndPoliciesSuccess: (payload) => ({
    type: manualsAndPolciesConstants.UPDATE_MANUALS_AND_POLICIES_SUCCESS,
    payload,
  }),
  updateManualsAndPoliciesError: (payload) => ({
    type: manualsAndPolciesConstants.UPDATE_MANUALS_AND_POLICIES_ERROR,
    payload,
  }),
  updateManualsAndPoliciesCompleted: () => ({
    type: manualsAndPolciesConstants.UPDATE_MANUALS_AND_POLICIES_COMPLETED,
  }),
  setManualsAndPoliciesUpdateData: (payload) => ({
    type: manualsAndPolciesConstants.SET_UPDATE_MANUALS_AND_POLICIES_DATA,
    payload,
  }),
  deleteManualsAndPoliciesRequest: () => ({
    type: manualsAndPolciesConstants.DELETE_MANUALS_AND_POLICIES_REQUEST,
  }),
  deleteManualsAndPoliciesSuccess: (payload) => ({
    type: manualsAndPolciesConstants.DELETE_MANUALS_AND_POLICIES_SUCCESS,
    payload,
  }),
  deleteManualsAndPoliciesError: (payload) => ({
    type: manualsAndPolciesConstants.DELETE_MANUALS_AND_POLICIES_ERROR,
    payload,
  }),
  deleteManualsAndPoliciesCompleted: () => ({
    type: manualsAndPolciesConstants.DELETE_MANUALS_AND_POLICIES_COMPLETED,
  }),
  setManualsAndPoliciesDetailsRequest: () => ({
    type: manualsAndPolciesConstants.SET_MANUALS_AND_POLICES_DETAILS_REQUEST,
  }),
  setManualsAndPoliciesDetailsSuccess: (payload) => ({
    type: manualsAndPolciesConstants.SET_MANUALS_AND_POLICES_DETAILS_SUCCESS,
    payload,
  }),
  setManualsAndPoliciesDetailsError: (payload) => ({
    type: manualsAndPolciesConstants.SET_MANUALS_AND_POLICES_DETAILS_ERROR,
    payload,
  }),
  approveManualsAndPoliciesRequest: () => ({
    type: manualsAndPolciesConstants.APPROVE_MANUALS_AND_POLICIES_REQUEST,
  }),
  approveManualsAndPoliciesSuccess: (payload) => ({
    type: manualsAndPolciesConstants.APPROVE_MANUALS_AND_POLICIES_SUCCESS,
    payload,
  }),
  approveManualsAndPoliciesError: (payload) => ({
    type: manualsAndPolciesConstants.APPROVE_MANUALS_AND_POLICIES_ERROR,
    payload,
  }),
  approveManualsAndPoliciesCompleted: () => ({
    type: manualsAndPolciesConstants.APPROVE_MANUALS_AND_POLICIES_COMPLETED,
  }),
  checkManualsAndPoliciesRequest: () => ({
    type: manualsAndPolciesConstants.CHECK_MANUALS_AND_POLICIES_REQUEST,
  }),
  checkManualsAndPoliciesSuccess: (payload) => ({
    type: manualsAndPolciesConstants.CHECK_MANUALS_AND_POLICIES_SUCCESS,
    payload,
  }),
  checkManualsAndPoliciesError: (payload) => ({
    type: manualsAndPolciesConstants.CHECK_MANUALS_AND_POLICIES_ERROR,
    payload,
  }),
  checkManualsAndPoliciesCompleted: () => ({
    type: manualsAndPolciesConstants.CHECK_MANUALS_AND_POLICIES_COMPLETED,
  }),
  downloadManualsAndPoliciesRequest: () => ({
    type: manualsAndPolciesConstants.DOWNLOAD_MANUALS_AND_POLICIES_REQUEST,
  }),
  downloadManualsAndPoliciesSuccess: (payload) => ({
    type: manualsAndPolciesConstants.DOWNLOAD_MANUALS_AND_POLICIES_SUCCESS,
    payload,
  }),
  downloadManualsAndPoliciesError: (payload) => ({
    type: manualsAndPolciesConstants.DOWNLOAD_MANUALS_AND_POLICIES_ERROR,
    payload,
  }),
  downloadManualsAndPoliciesCompleted: () => ({
    type: manualsAndPolciesConstants.DOWNLOAD_MANUALS_AND_POLICIES_COMPLETED,
  }),
};
