const intialState = {
  addRejection: {
    modalOpen: false,
    success: false,
    loading: false,
    error: "",
    data: {},
    message: "",
  },
  addProcurementRejectionsSuccess: false,
  addProcurementRejectionsLoading: false,
  addProcurementRejectionsError: "",
  addProcurementRejectionsMessage: "",
  addProcurementRejectionsData: {},
};

export default intialState;
