import appConfig from "../../config.json";
import { getAuthToken } from "../../helpers/auth/authToken";

export const loginUserRequest = async (method, url, body) => {
  try {
    const res = await fetch(`${appConfig.server_api_url + url}`, {
      method: method,
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    });
    return res.json();
  } catch (error) {
    console.error(error);
  }
};

export const forgotPasswordRequest = async (method, url, body) => {
  try {
    const res = await fetch(`${appConfig.server_api_url + url}`, {
      method,
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    });
    return res.json();
  } catch (error) {
    console.error(error);
  }
};

export const resetPasswordRequest = async (method, url, body) => {
  try {
    const res = await fetch(`${appConfig.server_api_url + url}`, {
      method,
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    });

    return res.json();
  } catch (error) {
    console.error(error);
  }
};

export const confirmAccountRequest = async (method, token) => {
  try {
    const res = await fetch(
      `${appConfig.server_api_url}/users/auth/confirmAccount/${token}`,
      { method }
    );
    return res.json();
  } catch (error) {
    console.error(error);
  }
};

export const authenticationRequest = async (url) => {
  try {
    const res = await fetch(`${appConfig.server_api_url + url}`, {
      headers: {
        authorization: `Bearer ${getAuthToken()}`,
      },
    });
    return res.json();
  } catch (error) {
    console.error(error);
  }
};

export const logoutUserRequest = async () => {
  try {
    const res = await fetch(`${appConfig.server_api_url}/users/auth/logout`, {
      headers: {
        authorization: `Bearer ${getAuthToken()}`,
      },
    });
    return res.json();
  } catch (error) {
    console.error(error);
  }
};
