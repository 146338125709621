import appConfig from "../../config.json";
import { getAuthToken } from "../../helpers/auth/authToken";

const authorization = `Bearer ${getAuthToken()}`;

const logDetailsApiRequests = {
  fetchLogDetails: async (id) => {
    try {
      const res = await fetch(
        `${appConfig.server_api_url}/settings/logDetails/${id}`,
        { headers: { authorization } }
      );
      return res.json();
    } catch (error) {
      console.error(error);
    }
  },
  addLogDetails: async (body) => {
    try {
      const res = await fetch(
        `${appConfig.server_api_url}/settings/logDetails`,
        {
          method: "POST",
          headers: {
            authorization,
            accept: "application/json",
            "content-type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      return res.json();
    } catch (error) {
      return {
        success: false,
        error:
          "Sorry there seems to be an error please try again in a few minutes",
      };
    }
  },
};

export default logDetailsApiRequests;
