export const dashboardDataConstants = {
  SET_DASHBOARD_DATA_REQUEST: "SET_DASHBOARD_DATA_REQUEST",
  SET_DASHBOARD_DATA_ERROR: "SET_DASHBOARD_DATA_ERROR",
  SET_DASHBOARD_DATA_SUCCESS: "SET_DASHBOARD_DATA_SUCCESS",
};

export const dashboardDataActions = {
  setDashboardDataRequest: () => ({
    type: dashboardDataConstants.SET_DASHBOARD_DATA_REQUEST,
  }),
  setDashboardDataSuccess: (payload) => ({
    type: dashboardDataConstants.SET_DASHBOARD_DATA_SUCCESS,
    payload,
  }),
  setDashboardDataError: (payload) => ({
    type: dashboardDataConstants.SET_DASHBOARD_DATA_ERROR,
    payload,
  }),
};
