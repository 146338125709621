const initialState = {
  bidEvaluations: [],
  bidEvaluationsLoading: false,
  bidEvaluationsError: "",
  bidEvaluationsSuccess: false,

  eligibilityEvaluations: [],
  eligibilityEvaluationsLoading: false,
  eligibilityEvaluationsError: "",
  eligibilityEvaluationsSuccess: false,

  evaluationItems: [],
  evaluationItemsLoading: false,
  evaluationItemsError: "",
  evaluationItemsSuccess: false,

  financialEvaluationsItems: [],
  financialEvaluationsItemsLoading: false,
  financialEvaluationsItemsError: "",
  financialEvaluationsItemsSuccess: false,

  addBidEvaluationsLoading: false,
  addBidEvaluationsMessage: "",
  addBidEvaluationsSuccess: false,
  addBidEvaluationsError: "",

  addTechnicalEvaluationsLoading: false,
  addTechnicalEvaluationsMessage: "",
  addTechnicalEvaluationsSuccess: false,
  addTechnicalEvaluationsError: "",

  addEligibilityEvaluationsLoading: false,
  addEligibilityEvaluationsMessage: "",
  addEligibilityEvaluationsSuccess: false,
  addEligibilityEvaluationsError: "",

  addFinancialEvaluationsLoading: false,
  addFinancialEvaluationsMessage: "",
  addFinancialEvaluationsSuccess: false,
  addFinancialEvaluationsError: "",

  bidEvaluationsDetails: {},
  bidEvaluationsDetailsSuccess: false,
  bidEvaluationsDetailsLoading: false,
  bidEvaluationsDetailsError: "",

  eligibilityEvaluationsDetails: {},
  eligibilityEvaluationsDetailsSuccess: false,
  eligibilityEvaluationsDetailsLoading: false,
  eligibilityEvaluationsDetailsError: "",

  technicalEvaluationItemData: {},
  technicalEvaluationItemDataSuccess: false,
  technicalEvaluationItemDataLoading: false,
  technicalEvaluationItemDataError: "",

  financialEvaluationItemData: {},
  financialEvaluationItemDataSuccess: false,
  financialEvaluationItemDataLoading: false,
  financialEvaluationItemDataError: "",

  editBidEvaluationsSuccess: false,
  editBidEvaluationsLoading: false,
  editBidEvaluationsData: {},
  editBidEvaluationsError: "",
  editBidEvaluationsMessage: "",

  editEligibilityEvaluationSuccess: false,
  editEligibilityEvaluationLoading: false,
  editEligibilityEvaluationData: {},
  editEligibilityEvaluationError: "",
  editEligibilityEvaluationMessage: "",

  editTechnicalEvaluationsSuccess: false,
  editTechnicalEvaluationsLoading: false,
  editTechnicalEvaluationsData: {},
  editTechnicalEvaluationsError: "",
  editTechnicalEvaluationsMessage: "",

  editFinancialEvaluationsSuccess: false,
  editFinancialEvaluationsLoading: false,
  editFinancialEvaluationsData: {},
  editFinancialEvaluationsError: "",
  editFinancialEvaluationsMessage: "",

  editEligibilityCriteriaSuccess: false,
  editEligibilityCriteriaLoading: false,
  editEligibilityCriteriaData: {},
  editEligibilityCriteriaError: "",
  editEligibilityCriteriaMessage: "",

  deleteBidEvaluationsLoading: false,
  deleteBidEvaluationsSuccess: false,
  deleteBidEvaluationsMessage: "",
  deleteBidEvaluationsError: "",

  deleteEligibilityEvaluationsCriteriaLoading: false,
  deleteEligibilityEvaluationsCriteriaSuccess: false,
  deleteEligibilityEvaluationsCriteriaMessage: "",
  deleteEligibilityEvaluationsCriteriaError: "",

  approveBidEvaluationsLoading: false,
  approveBidEvaluationsError: "",
  approveBidEvaluationsSuccess: false,
  approveBidEvaluationsMessage: "",

  checkBidEvaluationsLoading: false,
  checkBidEvaluationsSuccess: false,
  checkBidEvaluationsMessage: "",
  checkBidEvaluationsError: "",

  submitBidEvaluationsLoading: false,
  submitBidEvaluationsSuccess: false,
  submitBidEvaluationsMessage: "",
  submitBidEvaluationsError: "",

  rejectBidEvaluationsLoading: false,
  rejectBidEvaluationsSuccess: false,
  rejectBidEvaluationsMessage: "",
  rejectBidEvaluationsError: "",

  removeBidderFromEvaluationLoading: false,
  removeBidderFromEvaluationSuccess: false,
  removeBidderFromEvaluationMessage: "",
  removeBidderFromEvaluationError: "",

  bidEvaluationsMetaDataLoading: false,
  bidEvaluationsMetaData: {},
};

export default initialState;
