import { bidEvaluationsConstants } from "../../../actions/procument/bidEvaluations/bidEvaluations.actions";
import initialState from "../../../initialState/procurement/bidEvaluation/bidEvaluation";

const bidEvaluationState = (
  state = initialState,
  { type, payload, financialEvaluations }
) => {
  switch (type) {
    case bidEvaluationsConstants.SET_BID_EVALUATIONS_LOADING:
      return {
        ...state,
        bidEvaluationsLoading: true,
      };
    case bidEvaluationsConstants.SET_BID_EVALUATIONS_SUCCESS:
      return {
        ...state,
        bidEvaluationsSuccess: true,
        bidEvaluationsLoading: false,
        bidEvaluationsError: "",
        bidEvaluations: payload,
      };
    case bidEvaluationsConstants.SET_BID_EVALUATIONS_ERROR:
      return {
        ...state,
        bidEvaluationsError: payload,
        bidEvaluationsLoading: false,
        bidEvaluationsSuccess: false,
      };
    case bidEvaluationsConstants.SET_ELIGIBILITY_EVALUATIONS_LOADING:
      return {
        ...state,
        eligibilityEvaluationsLoading: true,
      };
    case bidEvaluationsConstants.SET_ELIGIBILITY_EVALUATIONS_SUCCESS:
      return {
        ...state,
        eligibilityEvaluationsSuccess: true,
        eligibilityEvaluationsLoading: false,
        eligibilityEvaluationsError: "",
        eligibilityEvaluations: payload,
      };
    case bidEvaluationsConstants.SET_ELIGIBILITY_EVALUATIONS_ERROR:
      return {
        ...state,
        eligibilityEvaluationsError: payload,
        eligibilityEvaluationsLoading: false,
        eligibilityEvaluationsSuccess: false,
      };
    case bidEvaluationsConstants.SET_ELIGIBILITY_EVALUATION_CRITERIA_DETAILS_LOADING:
      return {
        ...state,
        eligibilityEvaluationsDetailsLoading: true,
      };
    case bidEvaluationsConstants.SET_ELIGIBILITY_EVALUATION_CRITERIA_DETAILS_SUCCESS:
      return {
        ...state,
        eligibilityEvaluationsDetailsSuccess: true,
        eligibilityEvaluationsDetailsLoading: false,
        eligibilityEvaluationsDetailsError: "",
        eligibilityEvaluationsDetails: payload,
      };
    case bidEvaluationsConstants.SET_ELIGIBILITY_EVALUATION_CRITERIA_DETAILS_ERROR:
      return {
        ...state,
        eligibilityEvaluationsDetailsError: payload,
        eligibilityEvaluationsDetailsLoading: false,
        eligibilityEvaluationsDetailsSuccess: false,
      };
    case bidEvaluationsConstants.SET_BID_EVALUATION_DETAILS_LOADING:
      return {
        ...state,
        bidEvaluationsDetailsLoading: true,
      };
    case bidEvaluationsConstants.SET_BID_EVALUATION_DETAILS_SUCCESS:
      return {
        ...state,
        bidEvaluationsDetailsSuccess: true,
        bidEvaluationsDetailsLoading: false,
        bidEvaluationsDetailsError: "",
        bidEvaluationsDetails: payload,
      };
    case bidEvaluationsConstants.SET_BID_EVALUATION_DETAILS_ERROR:
      return {
        ...state,
        bidEvaluationsDetailsError: payload,
        bidEvaluationsDetailsLoading: false,
        bidEvaluationsDetailsSuccess: false,
      };
    case bidEvaluationsConstants.SET_TECHNICAL_EVALUATIONS_ITEMS_LOADING:
      return {
        ...state,
        evaluationItemsLoading: true,
      };
    case bidEvaluationsConstants.SET_TECHNICAL_EVALUATIONS_ITEMS_SUCCESS:
      return {
        ...state,
        evaluationItemsSuccess: true,
        evaluationItemsLoading: false,
        evaluationItemsError: "",
        evaluationItems: payload,
      };
    case bidEvaluationsConstants.SET_TECHNICAL_EVALUATIONS_ITEMS_ERROR:
      return {
        ...state,
        evaluationItemsError: payload,
        evaluationItemsLoading: false,
        evaluationItemsSuccess: false,
      };
    case bidEvaluationsConstants.SET_FINANCIAL_EVALUATIONS_ITEMS_LOADING:
      return {
        ...state,
        financialEvaluationsItemsLoading: true,
      };
    case bidEvaluationsConstants.SET_FINANCIAL_EVALUATIONS_ITEMS_SUCCESS:
      return {
        ...state,
        financialEvaluationsItemsSuccess: true,
        financialEvaluationsItemsLoading: false,
        financialEvaluationsItemsError: "",
        financialEvaluationsItems: payload,
        financialEvaluations,
      };
    case bidEvaluationsConstants.SET_FINANCIAL_EVALUATIONS_ITEMS_ERROR:
      return {
        ...state,
        financialEvaluationsItemsError: payload,
        financialEvaluationsItemsLoading: false,
        financialEvaluationsItemsSuccess: false,
      };
    case bidEvaluationsConstants.SET_FINANCIAL_EVALUATIONS_DATA_LOADING:
      return {
        ...state,
        financialEvaluationItemDataLoading: true,
      };
    case bidEvaluationsConstants.SET_FINANCIAL_EVALUATIONS_DATA_SUCCESS:
      return {
        ...state,
        financialEvaluationItemDataSuccess: true,
        financialEvaluationItemDataLoading: false,
        financialEvaluationItemDataError: "",
        financialEvaluationItemData: payload,
      };
    case bidEvaluationsConstants.SET_FINANCIAL_EVALUATIONS_DATA_ERROR:
      return {
        ...state,
        financialEvaluationItemDataError: payload,
        financialEvaluationItemDataLoading: false,
        financialEvaluationItemDataSuccess: false,
      };
    case bidEvaluationsConstants.SET_TECHNICAL_EVALUATIONS_DATA_LOADING:
      return {
        ...state,
        technicalEvaluationItemDataLoading: true,
      };
    case bidEvaluationsConstants.SET_TECHNICAL_EVALUATIONS_DATA_SUCCESS:
      return {
        ...state,
        technicalEvaluationItemDataSuccess: true,
        technicalEvaluationItemDataLoading: false,
        technicalEvaluationItemDataError: "",
        technicalEvaluationItemData: payload,
      };
    case bidEvaluationsConstants.SET_TECHNICAL_EVALUATIONS_DATA_ERROR:
      return {
        ...state,
        technicalEvaluationItemDataError: payload,
        technicalEvaluationItemDataLoading: false,
        technicalEvaluationItemDataSuccess: false,
      };
    case bidEvaluationsConstants.SET_BID_EVALUATIONS_META_DATA_LOADING:
      return {
        ...state,
        bidEvaluationsMetaDataLoading: true,
      };
    case bidEvaluationsConstants.SET_BID_EVALUATIONS_META_DATA:
      return {
        ...state,
        bidEvaluationsMetaData: payload,
        bidEvaluationsMetaDataLoading: false,
      };
    case bidEvaluationsConstants.ADD_FINANCIAL_EVALUATIONS_FORM_LOADING:
      return {
        ...state,
        addFinancialEvaluationsLoading: true,
      };
    case bidEvaluationsConstants.ADD_FINANCIAL_EVALUATIONS_FORM_SUCCESS:
      return {
        ...state,
        addFinancialEvaluationsLoading: false,
        addFinancialEvaluationsSuccess: true,
        addFinancialEvaluationsError: "",
        addFinancialEvaluationsMessage: payload,
      };
    case bidEvaluationsConstants.ADD_FINANCIAL_EVALUATIONS_FORM_ERROR:
      return {
        ...state,
        addFinancialEvaluationsLoading: false,
        addFinancialEvaluationsSuccess: false,
        addFinancialEvaluationsMessage: "",
        addFinancialEvaluationsError: payload,
      };
    case bidEvaluationsConstants.ADD_FINANCIAL_EVALUATIONS_FORM_COMPLETE:
      return {
        ...state,
        addFinancialEvaluationsSuccess: false,
      };
    case bidEvaluationsConstants.ADD_TECHNICAL_EVALUATIONS_FORM_LOADING:
      return {
        ...state,
        addTechnicalEvaluationsLoading: true,
      };
    case bidEvaluationsConstants.ADD_TECHNICAL_EVALUATIONS_FORM_SUCCESS:
      return {
        ...state,
        addTechnicalEvaluationsLoading: false,
        addTechnicalEvaluationsSuccess: true,
        addTechnicalEvaluationsError: "",
        addTechnicalEvaluationsMessage: payload,
      };
    case bidEvaluationsConstants.ADD_TECHNICAL_EVALUATIONS_FORM_ERROR:
      return {
        ...state,
        addTechnicalEvaluationsLoading: false,
        addTechnicalEvaluationsSuccess: false,
        addTechnicalEvaluationsMessage: "",
        addTechnicalEvaluationsError: payload,
      };
    case bidEvaluationsConstants.ADD_TECHNICAL_EVALUATIONS_FORM_COMPLETE:
      return {
        ...state,
        addTechnicalEvaluationsSuccess: false,
      };
    case bidEvaluationsConstants.EDIT_FINANCIAL_EVALUATIONS_LOADING:
      return {
        ...state,
        editFinancialEvaluationsLoading: true,
      };
    case bidEvaluationsConstants.EDIT_FINANCIAL_EVALUATIONS_SUCCESS:
      return {
        ...state,
        editFinancialEvaluationsLoading: false,
        editFinancialEvaluationsSuccess: true,
        editFinancialEvaluationsError: "",
        editFinancialEvaluationsMessage: payload,
      };
    case bidEvaluationsConstants.EDIT_FINANCIAL_EVALUATIONS_ERROR:
      return {
        ...state,
        editFinancialEvaluationsLoading: false,
        editFinancialEvaluationsSuccess: false,
        editFinancialEvaluationsMessage: "",
        editFinancialEvaluationsError: payload,
      };
    case bidEvaluationsConstants.EDIT_FINANCIAL_EVALUATIONS_COMPLETE:
      return {
        ...state,
        editFinancialEvaluationsSuccess: false,
      };
    case bidEvaluationsConstants.EDIT_FINANCIAL_EVALUATIONS_DATA:
      return {
        ...state,
        editFinancialEvaluationsData: payload,
      };
    case bidEvaluationsConstants.EDIT_TECHNICAL_EVALUATIONS_LOADING:
      return {
        ...state,
        editTechnicalEvaluationsLoading: true,
      };
    case bidEvaluationsConstants.EDIT_TECHNICAL_EVALUATIONS_SUCCESS:
      return {
        ...state,
        editTechnicalEvaluationsLoading: false,
        editTechnicalEvaluationsSuccess: true,
        editTechnicalEvaluationsError: "",
        editTechnicalEvaluationsMessage: payload,
      };
    case bidEvaluationsConstants.EDIT_TECHNICAL_EVALUATIONS_ERROR:
      return {
        ...state,
        editTechnicalEvaluationsLoading: false,
        editTechnicalEvaluationsSuccess: false,
        editTechnicalEvaluationsMessage: "",
        editTechnicalEvaluationsError: payload,
      };
    case bidEvaluationsConstants.EDIT_TECHNICAL_EVALUATIONS_COMPLETE:
      return {
        ...state,
        editTechnicalEvaluationsSuccess: false,
      };
    case bidEvaluationsConstants.EDIT_TECHNICAL_EVALUATIONS_DATA:
      return {
        ...state,
        editTechnicalEvaluationsData: payload,
      };
    case bidEvaluationsConstants.ADD_BID_EVALUATIONS_FORM_LOADING:
      return {
        ...state,
        addBidEvaluationsLoading: true,
      };
    case bidEvaluationsConstants.ADD_BID_EVALUATIONS_FORM_SUCCESS:
      return {
        ...state,
        addBidEvaluationsLoading: false,
        addBidEvaluationsSuccess: true,
        addBidEvaluationsError: "",
        addBidEvaluationsMessage: payload.message,
        bidEvaluations: payload.data,
      };
    case bidEvaluationsConstants.ADD_BID_EVALUATIONS_FORM_ERROR:
      return {
        ...state,
        addBidEvaluationsLoading: false,
        addBidEvaluationsSuccess: false,
        addBidEvaluationsMessage: "",
        addBidEvaluationsError: payload,
      };
    case bidEvaluationsConstants.ADD_BID_EVALUATIONS_FORM_COMPLETE:
      return {
        ...state,
        addBidEvaluationsSuccess: false,
      };
    case bidEvaluationsConstants.ADD_ELIGIBILITY_EVALUATIONS_CRITERIA_FORM_LOADING:
      return {
        ...state,
        addEligibilityEvaluationsLoading: true,
      };
    case bidEvaluationsConstants.ADD_ELIGIBILITY_EVALUATIONS_CRITERIA_FORM_SUCCESS:
      return {
        ...state,
        addEligibilityEvaluationsLoading: false,
        addEligibilityEvaluationsSuccess: true,
        addEligibilityEvaluationsError: "",
        addEligibilityEvaluationsMessage: payload.message,
        eligibilityEvaluations: payload.data,
      };
    case bidEvaluationsConstants.ADD_ELIGIBILITY_EVALUATIONS_CRITERIA_FORM_ERROR:
      return {
        ...state,
        addEligibilityEvaluationsLoading: false,
        addEligibilityEvaluationsSuccess: false,
        addEligibilityEvaluationsMessage: "",
        addEligibilityEvaluationsError: payload,
      };
    case bidEvaluationsConstants.ADD_ELIGIBILITY_EVALUATIONS_CRITERIA_FORM_COMPLETE:
      return {
        ...state,
        addEligibilityEvaluationsSuccess: false,
      };
    case bidEvaluationsConstants.DELETE_ELIGIBILITY_EVALUATIONS_CRITERIA_LOADING:
      return {
        ...state,
        deleteEligibilityEvaluationsCriteriaLoading: true,
      };
    case bidEvaluationsConstants.DELETE_ELIGIBILITY_EVALUATIONS_CRITERIA_SUCCESS:
      return {
        ...state,
        deleteEligibilityEvaluationsCriteriaLoading: false,
        deleteEligibilityEvaluationsCriteriaSuccess: true,
        deleteEligibilityEvaluationsCriteriaError: "",
        deleteEligibilityEvaluationsCriteriaMessage: payload.message,
        eligibilityEvaluations: payload.data,
      };
    case bidEvaluationsConstants.DELETE_ELIGIBILITY_EVALUATIONS_CRITERIA_ERROR:
      return {
        ...state,
        deleteEligibilityEvaluationsCriteriaLoading: false,
        deleteEligibilityEvaluationsCriteriaSuccess: false,
        deleteEligibilityEvaluationsCriteriaMessage: "",
        deleteEligibilityEvaluationsCriteriaError: payload,
      };
    case bidEvaluationsConstants.DELETE_ELIGIBILITY_EVALUATIONS_CRITERIA_COMPLETE:
      return {
        ...state,
        deleteEligibilityEvaluationsCriteriaSuccess: false,
        deleteEligibilityEvaluationsCriteriaError: "",
      };
    case bidEvaluationsConstants.EDIT_ELIGIBILITY_EVALUATIONS_CRITERIA_LOADING:
      return {
        ...state,
        editEligibilityCriteriaLoading: true,
      };
    case bidEvaluationsConstants.EDIT_ELIGIBILITY_EVALUATIONS_CRITERIA_SUCCESS:
      return {
        ...state,
        editEligibilityCriteriaLoading: false,
        editEligibilityCriteriaSuccess: true,
        editEligibilityCriteriaError: "",
        editEligibilityCriteriaMessage: payload.message,
        eligibilityEvaluations: payload.data,
      };
    case bidEvaluationsConstants.EDIT_ELIGIBILITY_EVALUATIONS_CRITERIA_ERROR:
      return {
        ...state,
        editEligibilityCriteriaLoading: false,
        editEligibilityCriteriaSuccess: false,
        editEligibilityCriteriaMessage: "",
        editEligibilityCriteriaError: payload,
      };
    case bidEvaluationsConstants.EDIT_ELIGIBILITY_EVALUATIONS_CRITERIA_COMPLETE:
      return {
        ...state,
        editEligibilityCriteriaSuccess: false,
      };
    case bidEvaluationsConstants.EDIT_ELIGIBILITY_EVALUATIONS_CRITERIA_DATA:
      return {
        ...state,
        editEligibilityCriteriaData: payload,
      };
    case bidEvaluationsConstants.EDIT_ELIGIBILITY_EVALUATIONS_LOADING:
      return {
        ...state,
        editEligibilityEvaluationLoading: true,
      };
    case bidEvaluationsConstants.EDIT_ELIGIBILITY_EVALUATIONS_SUCCESS:
      return {
        ...state,
        editEligibilityEvaluationLoading: false,
        editEligibilityEvaluationSuccess: true,
        editEligibilityEvaluationError: "",
        editEligibilityEvaluationMessage: payload,
      };
    case bidEvaluationsConstants.EDIT_ELIGIBILITY_EVALUATIONS_ERROR:
      return {
        ...state,
        editEligibilityEvaluationLoading: false,
        editEligibilityEvaluationSuccess: false,
        editEligibilityEvaluationMessage: "",
        editEligibilityEvaluationError: payload,
      };
    case bidEvaluationsConstants.EDIT_ELIGIBILITY_EVALUATIONS_COMPLETE:
      return {
        ...state,
        editEligibilityEvaluationSuccess: false,
        editEligibilityEvaluationError: "",
      };
    case bidEvaluationsConstants.EDIT_ELIGIBILITY_EVALUATIONS_DATA:
      return {
        ...state,
        editEligibilityEvaluationData: payload,
      };
    case bidEvaluationsConstants.DELETE_BID_EVALUATIONS_FORM_LOADING:
      return {
        ...state,
        deleteBidEvaluationsLoading: true,
      };
    case bidEvaluationsConstants.DELETE_BID_EVALUATIONS_FORM_SUCCESS:
      return {
        ...state,
        deleteBidEvaluationsLoading: false,
        deleteBidEvaluationsSuccess: true,
        deleteBidEvaluationsError: "",
        deleteBidEvaluationsMessage: payload.message,
        bidEvaluations: payload.data,
      };
    case bidEvaluationsConstants.DELETE_BID_EVALUATIONS_FORM_ERROR:
      return {
        ...state,
        deleteBidEvaluationsLoading: false,
        deleteBidEvaluationsSuccess: false,
        deleteBidEvaluationsMessage: "",
        deleteBidEvaluationsError: payload,
      };
    case bidEvaluationsConstants.DELETE_BID_EVALUATIONS_FORM_COMPLETE:
      return {
        ...state,
        deleteBidEvaluationsSuccess: false,
        deleteBidEvaluationsError: "",
      };
    case bidEvaluationsConstants.EDIT_BID_EVALUATIONS_FORM_LOADING:
      return {
        ...state,
        editBidEvaluationsLoading: true,
      };
    case bidEvaluationsConstants.EDIT_BID_EVALUATIONS_FORM_SUCCESS:
      return {
        ...state,
        editBidEvaluationsLoading: false,
        editBidEvaluationsSuccess: true,
        editBidEvaluationsError: "",
        editBidEvaluationsMessage: payload.message,
        bidEvaluations: payload.data,
      };
    case bidEvaluationsConstants.EDIT_BID_EVALUATIONS_FORM_ERROR:
      return {
        ...state,
        editBidEvaluationsLoading: false,
        editBidEvaluationsSuccess: false,
        editBidEvaluationsMessage: "",
        editBidEvaluationsError: payload,
      };
    case bidEvaluationsConstants.EDIT_BID_EVALUATIONS_FORM_COMPLETE:
      return {
        ...state,
        editBidEvaluationsSuccess: false,
      };
    case bidEvaluationsConstants.EDIT_BID_EVALUATIONS_FORM_DATA:
      return {
        ...state,
        editBidEvaluationsData: payload,
      };
    case bidEvaluationsConstants.REJECT_BID_EVALUATIONS_FORM_LOADING:
      return {
        ...state,
        rejectBidEvaluationsLoading: true,
      };
    case bidEvaluationsConstants.REJECT_BID_EVALUATIONS_FORM_SUCCESS:
      return {
        ...state,
        rejectBidEvaluationsLoading: false,
        rejectBidEvaluationsSuccess: true,
        rejectBidEvaluationsMessage: payload,
      };
    case bidEvaluationsConstants.REJECT_BID_EVALUATIONS_FORM_ERROR:
      return {
        ...state,
        rejectBidEvaluationsError: payload,
        rejectBidEvaluationsLoading: false,
        rejectBidEvaluationsSuccess: false,
      };
    case bidEvaluationsConstants.REJECT_BID_EVALUATIONS_FORM_COMPLETE:
      return {
        ...state,
        rejectBidEvaluationsError: "",
        rejectBidEvaluationsSuccess: false,
      };
    case bidEvaluationsConstants.REMOVE_BIDDER_FROM_EVALUATION_LOADING:
      return {
        ...state,
        removeBidderFromEvaluationLoading: true,
      };
    case bidEvaluationsConstants.REMOVE_BIDDER_FROM_EVALUATION_SUCCESS:
      return {
        ...state,
        removeBidderFromEvaluationLoading: false,
        removeBidderFromEvaluationSuccess: true,
        removeBidderFromEvaluationMessage: payload,
        removeBidderFromEvaluationError: "",
      };
    case bidEvaluationsConstants.REMOVE_BIDDER_FROM_EVALUATION_ERROR:
      return {
        ...state,
        removeBidderFromEvaluationError: payload,
        removeBidderFromEvaluationLoading: false,
        removeBidderFromEvaluationSuccess: false,
      };
    case bidEvaluationsConstants.REMOVE_BIDDER_FROM_EVALUATION_COMPLETE:
      return {
        ...state,
        removeBidderFromEvaluationSuccess: false,
      };
    case bidEvaluationsConstants.SUBMIT_BID_EVALUATIONS_FORM_LOADING:
      return {
        ...state,
        submitBidEvaluationsLoading: true,
      };
    case bidEvaluationsConstants.SUBMIT_BID_EVALUATIONS_FORM_SUCCESS:
      return {
        ...state,
        submitBidEvaluationsLoading: false,
        submitBidEvaluationsSuccess: true,
        submitBidEvaluationsMessage: payload,
      };
    case bidEvaluationsConstants.SUBMIT_BID_EVALUATIONS_FORM_ERROR:
      return {
        ...state,
        submitBidEvaluationsError: payload,
        submitBidEvaluationsLoading: false,
        submitBidEvaluationsSuccess: false,
      };
    case bidEvaluationsConstants.SUBMIT_BID_EVALUATIONS_FORM_COMPLETE:
      return {
        ...state,
        submitBidEvaluationsError: "",
        submitBidEvaluationsSuccess: false,
      };
    case bidEvaluationsConstants.CHECK_BID_EVALUATIONS_FORM_LOADING:
      return {
        ...state,
        checkBidEvaluationsLoading: true,
      };
    case bidEvaluationsConstants.CHECK_BID_EVALUATIONS_FORM_SUCCESS:
      return {
        ...state,
        checkBidEvaluationsLoading: false,
        checkBidEvaluationsSuccess: true,
        checkBidEvaluationsMessage: payload,
      };
    case bidEvaluationsConstants.CHECK_BID_EVALUATIONS_FORM_ERROR:
      return {
        ...state,
        checkBidEvaluationsError: payload,
        checkBidEvaluationsLoading: false,
        checkBidEvaluationsSuccess: false,
      };
    case bidEvaluationsConstants.CHECK_BID_EVALUATIONS_FORM_COMPLETE:
      return {
        ...state,
        checkBidEvaluationsError: "",
        checkBidEvaluationsSuccess: false,
      };
    case bidEvaluationsConstants.APPROVE_BID_EVALUATIONS_FORM_LOADING:
      return {
        ...state,
        approveBidEvaluationsLoading: true,
      };
    case bidEvaluationsConstants.APPROVE_BID_EVALUATIONS_FORM_SUCCESS:
      return {
        ...state,
        approveBidEvaluationsSuccess: true,
        approveBidEvaluationsLoading: false,
        approveBidEvaluationsMessage: payload,
      };
    case bidEvaluationsConstants.APPROVE_BID_EVALUATIONS_FORM_ERROR:
      return {
        ...state,
        approveBidEvaluationsError: payload,
        approveBidEvaluationsLoading: false,
        approveBidEvaluationsSuccess: false,
      };
    case bidEvaluationsConstants.APPROVE_BID_EVALUATIONS_FORM_COMPLETE:
      return {
        ...state,
        approveBidEvaluationsSuccess: false,
        approveBidEvaluationsError: "",
      };
    default:
      return state;
  }
};
export default bidEvaluationState;
