import React from "react";
import { Avatar, Button, Col, Layout, Popover, Row, Tooltip } from "antd";
import { FcMenu } from "react-icons/fc";
import CurrentUserInfo from "../../users/CurrentUserInfo";
import { useDispatch, useSelector } from "react-redux";
import { appUiActions } from "../../../config/actions/app/appUi.actions";
import appConfig from "../../../config/config.json";

const { Header } = Layout;

const Navbar = () => {
  const dispatch = useDispatch();
  const openAppDrawer = () => {
    dispatch(appUiActions.toggleAppDrawer(true));
  };
  const { data: currentUserInfo } = useSelector(
    (state) => state.usersState.currentUserInfo
  );

  const { first_name, other_names } = currentUserInfo;
  return (
    <>
      <Header
        style={{
          padding: 0,
          height: 38,
        }}
        className="bg-white shadow-sm"
      >
        <Row>
          <Col md={12} sm={12} className="w-sm-50">
            <Row align="middle" justify="start">
              <Tooltip title="Menu" placement="bottom">
                <Button
                  className="d-md-none"
                  onClick={openAppDrawer}
                  type="text"
                  size="large"
                >
                  <FcMenu />
                </Button>
              </Tooltip>
              <Button size="small" type="text">
                <span className="fw-bold  mt-3 text-primary">AIFMIS</span>
              </Button>
            </Row>
          </Col>
          <Col md={12}>
            <Row align="middle" justify="end">
              <Button size="small" type="text">
                <span className="fw-bold">
                  {first_name} {other_names}
                </span>
              </Button>
              <Tooltip placement="bottom" title="Your Account Info">
                <Popover
                  content={CurrentUserInfo}
                  placement="bottomRight"
                  trigger="click"
                >
                  <Button size="large" type="text">
                    <Avatar
                      className="rounded-circle"
                      src={appConfig.server_url + currentUserInfo.img_url}
                      alt=""
                      size={30}
                    />
                  </Button>
                </Popover>
              </Tooltip>
            </Row>
          </Col>
        </Row>
      </Header>
    </>
  );
};

export default Navbar;
