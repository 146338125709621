import { combineReducers } from "redux";
import AppUi from "./App/AppUi.reducer";
import authState from "./auth/auth.reducer";
import usersState from "./users/users.reducer";
import rolesState from "./settings/roles.reducer";
import assetRegisterState from "./administration/assetRegister/assetRegister.reducer";
import permissionsState from "./settings/permissions.reducer";
import storesRegisterState from "./administration/storesRegister/storesRegister.reducer";
import logDetailsState from "./settings/logDetails.reducer";
import staffRecordsState from "./administration/staffRecords/staffRecords.reducer";
import manualsAndPoliciesState from "./administration/manualsAndPolicies/manualsAndPolicies.reducer";
import rejectionsState from "./rejections/rejections.reducer";
import donorsState from "./businessDevAndPartnerships/donors/donors.reducer";
import projectsState from "./businessDevAndPartnerships/projects/projects.reducer";
import chartOfAccountsState from "./finance/chartOfAccounts/chartOfAccounts.reducer";
import budgetState from "./finance/budget/budget.reducer";
import requisitionMemoState from "./finance/requistionMemo/requisitionMemo.reducer";
import paymentVouchersState from "./finance/paymentVouchers/paymentVoucher.reducer";
import advanceAccountabilityFormsState from "./finance/advanceAccountabilityForm/advanceAccountabilityForm.reducer";
import rfqsState from "./procurement/rfqs/rfqs.reducer";
import recordOfBiddersState from "./procurement/recordOfBidders/recordOfBidders.reducer";
import bidEvaluationState from "./procurement/bidEvaluation/bidEvaluations.reducer";
import noticeOfBestBidderState from "./procurement/noticeOfBestBidder/noticeOfBestBidder.reducer";
import localPurchaseOrdersState from "./procurement/localPurchaseOrders/localPurchaseOrder.reducer";
import dashboardState from "./dashboard/dashboard.reducer";

export default combineReducers({
  AppUi,
  authState,
  usersState,
  rolesState,
  assetRegisterState,
  permissionsState,
  storesRegisterState,
  logDetailsState,
  staffRecordsState,
  manualsAndPoliciesState,
  rejectionsState,
  donorsState,
  projectsState,
  chartOfAccountsState,
  budgetState,
  requisitionMemoState,
  paymentVouchersState,
  advanceAccountabilityFormsState,
  rfqsState,
  recordOfBiddersState,
  bidEvaluationState,
  noticeOfBestBidderState,
  localPurchaseOrdersState,
  dashboardState,
});
