import {
  Alert,
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
} from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";

const { Option } = Select;

const AddDonor = ({ handleAddRecord }) => {
  //state,variable, decalrations and initialization
  const {
    success,
    error,
    message: successMessage,
    loading,
  } = useSelector((state) => state.donorsState.addDonor);
  const { addDonorsModal: visible } = useSelector((state) => state.AppUi);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  /** functions */
  const closeModal = () => dispatch(appUiActions.toggleAddDonorsModal(false));
  const onFinish = (values) => {
    values.created_at = new Date();
    values.budget = values.budget + values.currency;
    handleAddRecord(values);
  };
  /** use Effects */
  useEffect(() => {
    if (success && successMessage) {
      message.success(successMessage);
      form.resetFields();
    }
  }, [success, successMessage, form]);
  /* Education
Child Protection & Safeguarding
Child, Adolescent and Maternal Health
Socioeconomic Empowerment */
  const thematicAreas = [
    "Education",
    "Child Protection And Safeguarding",
    "Child, Adolescent and Maternal Health",
    "Socioeconomic Empowerment",
  ];
  return (
    <div>
      <Modal
        footer={null}
        onCancel={closeModal}
        title="ADD NEW DONOR"
        open={visible}
        width={800}
      >
        <Form
          labelCol={{ span: 11 }}
          wrapperCol={{ span: 16 }}
          layout="horizontal"
          form={form}
          onFinish={onFinish}
        >
          {!success && error && <Alert showIcon type="error" message={error} />}
          <div className="d-lg-flex">
            <div className="w-50 w-sm-100">
              <Form.Item
                name="name"
                label="Donor"
                rules={[
                  { required: true, message: "Please add a donor name " },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  { required: true, message: "Please add a donor email " },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="contact"
                label="Contact"
                rules={[
                  { required: true, message: "Please add a donor contact " },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="budget"
                label="Budget (Upper limit)"
                rules={[
                  {
                    required: true,
                    message: "Please a upper limit for the budget",
                  },
                ]}
              >
                <InputNumber precision={0} className="w-100" />
              </Form.Item>
              <Form.Item
                name="currency"
                label="Currency"
                rules={[
                  {
                    required: true,
                    message: "Please select a currency",
                  },
                ]}
              >
                <Select style={{ width: "30%" }}>
                  <Option value="D">USD</Option>
                  <Option value="U">UGX</Option>
                </Select>
              </Form.Item>
            </div>
            <div className="w-50 w-sm-100">
              <Form.Item
                name="focus"
                label="Focus Of Intervention"
                rules={[
                  {
                    required: true,
                    message: "Please add a focus of intervention ",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="deadline_of_submission"
                label="Deadline of submission"
                rules={[
                  {
                    required: true,
                    message: "Please enter a deadline of submission",
                  },
                ]}
              >
                <DatePicker className="w-100" />
              </Form.Item>
              <Form.Item
                name="link"
                label="Link to documents "
                rules={[
                  {
                    required: true,
                    message: "Please a link to application documents",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="eligibility_status"
                label="Eligibility status/0-10"
                rules={[
                  {
                    required: true,
                    message: "Please an Eligibility status/0-10",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="thematic_area"
                label="Thematic Area"
                rules={[
                  {
                    required: true,
                    message: "Please a Thematic Area",
                  },
                ]}
              >
                <Select allowClear>
                  {thematicAreas.map((i) => (
                    <Option value={i} key={i}>
                      {i}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>
          <Form.Item className={"mt-3 "} wrapperCol={{ offset: 5, span: 18 }}>
            <Button loading={loading} htmlType="submit" type="primary">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddDonor;
