const initialState = {
  localPurchaseOrders: [],
  localPurchaseOrdersLoading: false,
  localPurchaseOrdersError: "",
  localPurchaseOrdersSuccess: false,

  lpoItems: [],
  lpoItemsLoading: false,
  lpoItemsError: "",
  lpoItemsSuccess: false,

  addLocalPurchaseOrdersLoading: false,
  addLocalPurchaseOrdersMessage: "",
  addLocalPurchaseOrdersSuccess: false,
  addLocalPurchaseOrdersError: "",

  addLpoItemsLoading: false,
  addLpoItemsMessage: "",
  addLpoItemsSuccess: false,
  addLpoItemsError: "",

  localPurchaseOrdersDetails: {},
  localPurchaseOrdersDetailsSuccess: false,
  localPurchaseOrdersDetailsLoading: false,
  localPurchaseOrdersDetailsError: "",

  lpoItemsDetails: {},
  lpoItemsDetailsSuccess: false,
  lpoItemsDetailsLoading: false,
  lpoItemsDetailsError: "",

  editLocalPurchaseOrdersSuccess: false,
  editLocalPurchaseOrdersLoading: false,
  editLocalPurchaseOrdersData: {},
  editLocalPurchaseOrdersError: "",
  editLocalPurchaseOrdersMessage: "",

  editLpoItemsSuccess: false,
  editLpoItemsLoading: false,
  editLpoItemsData: {},
  editLpoItemsError: "",
  editLpoItemsMessage: "",

  deleteLocalPurchaseOrdersLoading: false,
  deleteLocalPurchaseOrdersSuccess: false,
  deleteLocalPurchaseOrdersMessage: "",
  deleteLocalPurchaseOrdersError: "",

  deleteLpoItemsLoading: false,
  deleteLpoItemsSuccess: false,
  deleteLpoItemsMessage: "",
  deleteLpoItemsError: "",

  approveLocalPurchaseOrdersLoading: false,
  approveLocalPurchaseOrdersError: "",
  approveLocalPurchaseOrdersSuccess: false,
  approveLocalPurchaseOrdersMessage: "",

  checkLocalPurchaseOrdersLoading: false,
  checkLocalPurchaseOrdersSuccess: false,
  checkLocalPurchaseOrdersMessage: "",
  checkLocalPurchaseOrdersError: "",

  submitLocalPurchaseOrdersLoading: false,
  submitLocalPurchaseOrdersSuccess: false,
  submitLocalPurchaseOrdersMessage: "",
  submitLocalPurchaseOrdersError: "",

  localPurchaseOrdersMetaDataLoading: false,
  localPurchaseOrdersMetaData: {},
};

export default initialState;
