import {
  Alert,
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
} from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";
import moment from "moment";
import { projectActions } from "../../../../config/actions/businesDevAndPartnerships/projects/projects.actions";
import appConfig from "../../../../config/config.json";

const { Option } = Select;

const EditProject = ({ handleEditRecord }) => {
  //state,variable, decalrations and initialization
  const {
    editProjectSuccess,
    editProjectError,
    editProjectMessage,
    editProjectLoading,
    editProjectData: data,
    projectsMetaData,
    projectsMetaDataLoading,
    projects,
  } = useSelector((state) => state.projectsState);
  const { editProjectsModal: visible } = useSelector((state) => state.AppUi);
  const dispatch = useDispatch();
  const fields = [
    { name: "name", value: data.name },
    { name: "donor", value: data.donor },
    { name: "start_date", value: moment(data.start_date) },
    { name: "end_date", value: moment(data.end_date) },
    { name: "approved_budget", value: parseInt(data?.approved_budget) },
    { name: "currency", value: getCurrency(data) },
    { name: "account_number", value: data.account_number },
    { name: "bank", value: data.bank },
    { name: "person_incharge", value: data.person_incharge },
    { name: "supervisor", value: data.supervisor },
    { name: "project_accountant", value: data.project_accountant },
  ];
  /** functions */
  function getCurrency(data) {
    try {
      return data?.approved_budget?.[data?.approved_budget?.length - 1];
    } catch (error) {
      console.error(error);
      return "";
    }
  }
  const closeModal = () =>
    dispatch(appUiActions.toggleEditProjectsModal(false));
  const onFinish = (values) => {
    values.updated_at = new Date();
    values.approved_budget = values.approved_budget + values.currency;
    values.id = data.id;
    dispatch(projectActions.editProjectData(values));
    const updateProjectsArray = projects.filter((e) => e.id !== values.id);
    handleEditRecord(values, updateProjectsArray);
  };
  /** use Effects */
  useEffect(() => {
    console.log({ editProjectMessage });
    if (editProjectSuccess && editProjectMessage) {
      console.log(editProjectMessage);
      message.success(editProjectMessage);
      dispatch(appUiActions.toggleEditProjectsModal(false));
    }
  }, [editProjectMessage, editProjectSuccess, dispatch]);
  return (
    <div>
      <Modal
        footer={null}
        onCancel={closeModal}
        title="EDIT PROJECT"
        open={visible}
        width={800}
      >
        <Form
          fields={fields}
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 16 }}
          layout="horizontal"
          onFinish={onFinish}
        >
          {!editProjectSuccess && editProjectError && (
            <Alert
              className="my-2"
              showIcon
              type="error"
              message={editProjectError}
            />
          )}
          <div className="d-md-flex">
            <div className="w-50 w-sm-100">
              <Form.Item
                name="name"
                label="Project"
                rules={[
                  { required: true, message: "Please add a project name " },
                ]}
              >
                <Input
                  disabled={
                    data.status === appConfig.status.approved ? true : false
                  }
                />
              </Form.Item>
              <Form.Item
                name="start_date"
                label="Start Date"
                rules={[{ required: true, message: "Please add a start Date" }]}
              >
                <DatePicker
                  disabled={
                    data.status === appConfig.status.approved ? true : false
                  }
                  className="w-100"
                />
              </Form.Item>
              <Form.Item
                name="end_date"
                label="End Date"
                rules={[{ required: true, message: "Please add a end Date" }]}
              >
                <DatePicker
                  disabled={
                    data.status === appConfig.status.approved ? true : false
                  }
                  className="w-100"
                />
              </Form.Item>
              <Form.Item
                name="approved_budget"
                label="Approved Budget"
                rules={[
                  {
                    required: true,
                    message: "Please a upper limit for the budget",
                  },
                ]}
              >
                <InputNumber
                  disabled={
                    data.status === appConfig.status.approved ? true : false
                  }
                  precision={0}
                  className="w-100"
                />
              </Form.Item>
              <Form.Item
                label="Donor"
                name="donor"
                rules={[{ required: true, message: "Please select a donor" }]}
              >
                <Select
                  disabled={
                    data.status === appConfig.status.approved ? true : false
                  }
                  defaultValue=""
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  loading={projectsMetaDataLoading}
                >
                  {projectsMetaData?.donors?.map((donor, id) => {
                    return (
                      <Option value={donor.id} key={donor.id}>
                        {`${donor.name}`}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                name="currency"
                label="Currency"
                rules={[
                  {
                    required: true,
                    message: "Please select a currency",
                  },
                ]}
              >
                <Select
                  disabled={
                    data.status === appConfig.status.approved ? true : false
                  }
                  className="w-50"
                >
                  <Option value="D">USD</Option>
                  <Option value="U">UGX</Option>
                </Select>
              </Form.Item>
            </div>
            <div className="w-50 w-sm-100">
              <Form.Item
                name="bank"
                label="Bank"
                rules={[{ required: true, message: "Please add a  bank " }]}
              >
                <Input
                  disabled={
                    data.status === appConfig.status.approved ? true : false
                  }
                />
              </Form.Item>
              <Form.Item
                name="account_number"
                label="Account number"
                rules={[
                  { required: true, message: "Please add an  account number " },
                ]}
              >
                <InputNumber
                  disabled={
                    data.status === appConfig.status.approved ? true : false
                  }
                  precision={0}
                  className="w-100"
                />
              </Form.Item>
              <Form.Item
                label="Person Incharge"
                name="person_incharge"
                rules={[
                  {
                    required: true,
                    message: "Please select a person Incharge",
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  loading={projectsMetaDataLoading}
                >
                  {projectsMetaData?.users?.map((user, id) => {
                    return (
                      <Option value={user.id} key={user.id}>
                        {`${user.first_name} ${user.other_names}`}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                label="Project Supervisor"
                name="supervisor"
                rules={[
                  {
                    required: true,
                    message: "Please select a person Incharge",
                  },
                ]}
              >
                <Select
                  defaultValue=""
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  loading={projectsMetaDataLoading}
                >
                  {projectsMetaData?.users?.map((user, id) => {
                    return (
                      <Option value={user.id} key={user.id}>
                        {`${user.first_name} ${user.other_names}`}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                label="Project Accountant"
                name="project_accountant"
                rules={[
                  {
                    required: true,
                    message: "Please select a project accountant",
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  loading={projectsMetaDataLoading}
                >
                  {projectsMetaData?.users?.map((user, id) => {
                    return (
                      <Option value={user.id} key={user.id}>
                        {`${user.first_name} ${user.other_names}`}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
          </div>
          <Form.Item className={"mt-3"} wrapperCol={{ offset: 5, span: 16 }}>
            <Button
              loading={editProjectLoading}
              htmlType="submit"
              type="primary"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default EditProject;
