import {
  Alert,
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
} from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";
import moment from "moment";
import { assetRegisterActions } from "../../../../config/actions/administration/assetRegister/assetRegister.actions";
//import { assetRegisterActions } from "../../../../config/actions/administration/assetRegister/assetRegister.actions";

const { Option } = Select;
const EditAssetForm = ({ handleEditAsset }) => {
  const dispatch = useDispatch();
  const { error, success, loading, data } = useSelector(
    (state) => state.assetRegisterState.editAsset
  );
  const { loading: metaDataLoading, data: metaData } = useSelector(
    (state) => state.assetRegisterState.metaData
  );
  const { loading: assetTypesLoading, data: assetTypes } = useSelector(
    (state) => state.assetRegisterState.fetchAssetCategory
  );
  const visible = useSelector(
    (state) => state.AppUi.admisnistration.assetsRegister.editAssetModal
  );

  const onFinish = (values) => {
    values.updatedAt = new Date();
    values.id = data.id;
    dispatch(assetRegisterActions.setUpdateAssetData(values));
    handleEditAsset(values);
  };
  const closeAddRoleModal = () => {
    dispatch(appUiActions.toggleEditAssetModal(false));
  };
  const fields = [
    { name: "name", value: data.name },
    { name: "location", value: data.location },
    { name: "source_of_funds_id", value: data.source_of_funds_id },
    { name: "financial_year", value: data.financial_year },
    { name: "purchase_ammount", value: data.purchase_ammount },
    { name: "staff_with_asset", value: data.staff_with_asset },
    { name: "category_id", value: data.category_id },
    { name: "code", value: data.code },
    { name: "procurement_date", value: moment(data.procurement_date) },
  ];
  //
  return (
    <div>
      <Modal
        onCancel={closeAddRoleModal}
        open={visible}
        title="EDIT  ASSET"
        footer={null}
        validateTrigger="onBlur"
        width={800}
      >
        <Form
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 20 }}
          validateTrigger="onBlur"
          fields={fields}
          layout="horizontal"
          onFinish={onFinish}
        >
          {!success && error && <Alert type="error" showIcon message={error} />}
          <div className="d-sm-flex w-100 ">
            <div className="w-50 w-sm-100">
              <Form.Item
                name="name"
                label="Asset Description"
                rules={[
                  {
                    required: true,
                    message: "Please add an asset Description",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="procurement_date"
                label="Acquisition Date"
                rules={[
                  { required: true, message: "Please add an Acqusition Date" },
                ]}
              >
                <DatePicker className="w-100" />
              </Form.Item>
              <Form.Item
                label="Location"
                name="location"
                rules={[{ required: true, message: "Please add a Location" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Staff With Asset"
                name="staff_with_asset"
                rules={[
                  {
                    required: true,
                    message: "Please the staff currently with the asset",
                  },
                ]}
              >
                <Select
                  showSearch
                  loading={metaDataLoading}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {metaData?.users?.map((user) => (
                    <Option key={user.id} value={user.id}>
                      {`${user.first_name} ${user.other_names}`}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Souce of funds"
                name="source_of_funds_id"
                rules={[
                  { required: true, message: "Please add a source of funds" },
                ]}
              >
                <Select
                  showSearch
                  loading={metaDataLoading}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {metaData?.donors?.map((donor) => (
                    <Option key={donor.id} value={donor.id}>
                      {donor.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="w-50 w-sm-100">
              <Form.Item
                label="Purchase Amount"
                name="purchase_ammount"
                rules={[
                  { required: true, message: "Please add a Purchase Amount" },
                ]}
              >
                <InputNumber precision={0} className="w-100" />
              </Form.Item>
              <Form.Item
                label="Code"
                name="code"
                rules={[{ required: true, message: "Please add a code" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Financial Year"
                name="financial_year"
                rules={[{ required: true, message: "Please add a code" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                rules={[
                  { required: true, message: "Please select an asset type" },
                ]}
                name="category_id"
                label="Asset Type"
              >
                <Select loading={assetTypesLoading}>
                  {assetTypes.map((item) => {
                    return (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
          </div>
          <Form.Item wrapperCol={{ offset: 5 }}>
            <Button
              className="w-25"
              loading={loading}
              htmlType="submit"
              type="primary"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default EditAssetForm;
