import React, { useEffect } from "react";
import { useAccountsStore } from "../../../config/stores";
import { isEmpty } from "lodash";
import AccountsTable from "./MangeAccounts/AccountsTable";
import AccountForm from "./MangeAccounts/AccountForm";
import AccountDetails from "./MangeAccounts/AccountDetails";

const Accounts = () => {
  const accountsStore = useAccountsStore();

  useEffect(() => {
    if (isEmpty(accountsStore.accounts)) accountsStore.getAccounts();
  }, [accountsStore.accounts]);
  return (
    <div className="container-fluid p-2">
      <h5 className="fw-bold">ACCOUNTS</h5>
      <AccountsTable />
      <AccountForm />
      <AccountDetails />
    </div>
  );
};

export default Accounts;
