import React, { useEffect } from "react";
import ReceiptsTable from "./ManageReceipts/ReceiptsTable";
import { useReceiptsStore } from "../../../config/stores";
import { isEmpty } from "lodash";
import ReceiptsForm from "./ManageReceipts/ReceiptsForm";
import ReceiptDetails from "./ManageReceipts/ReceiptDetails";

const Receipts = () => {
  const receiptsStore = useReceiptsStore();

  const { receipts, metaData } = receiptsStore;

  useEffect(() => {
    if (isEmpty(receipts)) receiptsStore.getReceipts();
    if (isEmpty(metaData)) receiptsStore.getReceiptsMetaData();
  }, [receipts, metaData]);
  return (
    <div className="container-fluid p-2">
      <h5 className="fw-bold">RECEIPTS</h5>
      <ReceiptsTable />
      <ReceiptsForm />
      <ReceiptDetails />
    </div>
  );
};

export default Receipts;
