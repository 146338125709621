import { Table, Typography } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import {
  getCurrency,
  numberWithCommas,
} from "../../../../config/helpers/app/numberFormater";

const BudgetLineActivitiesTable = ({ data }) => {
  const { budgetDetails } = useSelector((state) => state.budgetState);
  const { Text } = Typography;
  const currency = getCurrency(budgetDetails, "approved_budget");
  const columns = [
    { key: "name", title: "Cost Drivers", dataIndex: "name" },
    { key: "units", title: "Units", dataIndex: "units" },
    {
      key: "unit_cost",
      title: "Unit Cost",
      dataIndex: "unit_cost",
      render: (text) => {
        return `${currency === "D" ? "USD" : "UGX"} ${numberWithCommas(
          parseInt(text)
        )}`;
      },
    },
    {
      key: "total_cost",
      title: "Activity Total Cost",
      dataIndex: "total_cost",
      render: (text) => {
        return `${currency === "D" ? "USD" : "UGX"} ${numberWithCommas(
          parseInt(text)
        )}`;
      },
    },
  ];
  return (
    <div>
      <Table
        dataSource={data?.budgetLineActivties}
        columns={columns}
        bordered
        scroll={{ x: true }}
        rowKey={(r) => r.id}
        summary={(records) => {
          let subTotal = 0;
          records.forEach((record) => {
            subTotal += record.total_cost;
          });
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell>
                  <Text type="warning">Sub Total:</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Text type="warning">{`${
                    currency === "D" ? "USD" : "UGX"
                  } ${numberWithCommas(subTotal)}`}</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </div>
  );
};

export default BudgetLineActivitiesTable;
