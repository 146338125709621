import { storesRegisterConstants } from "../../../actions/administration/storesRegister/storesRegister.actions";
import initialState from "../../../initialState/administration/storesRegister/storesRegister";

const storesRegisterState = (state = initialState, { type, payload }) => {
  switch (type) {
    case storesRegisterConstants.SET_STORES_REGISTER_REQUEST:
      return {
        ...state,
        storesRegister: {
          ...state.storesRegister,
          loading: true,
        },
      };
    case storesRegisterConstants.SET_STORES_REGISTER_SUCCESS:
      return {
        ...state,
        storesRegister: {
          loading: false,
          success: true,
          data: payload,
          error: "",
        },
      };
    case storesRegisterConstants.SET_STORES_REGISTER_ERROR:
      return {
        ...state,
        storesRegister: {
          ...state.storesRegister,
          loading: false,
          success: false,
          error: payload,
        },
      };
    case storesRegisterConstants.ADD_STORES_REGISTER_RECEIPT_REQUEST:
      return {
        ...state,
        addStoresRegisterReceipt: {
          ...state.addStoresRegisterReceipt,
          loading: true,
        },
      };
    case storesRegisterConstants.ADD_STORES_REGISTER_RECEIPT_SUCCESS:
      return {
        ...state,
        addStoresRegisterReceipt: {
          ...state.addStoresRegisterReceipt,
          loading: false,
          error: "",
          success: true,
          message: payload.message,
        },
        storesRegister: {
          ...state.storesRegister,
          data: [payload.data, ...state.storesRegister.data],
        },
      };
    case storesRegisterConstants.ADD_STORES_REGISTER_RECEIPT_ERROR:
      return {
        ...state,
        addStoresRegisterReceipt: {
          ...state.addStoresRegisterReceipt,
          loading: false,
          success: false,
          error: payload,
        },
      };
    case storesRegisterConstants.ADD_STORES_REGISTER_RECEIPT_COMPLETED:
      return {
        ...state,
        addStoresRegisterReceipt: {
          success: false,
        },
      };
    case storesRegisterConstants.ADD_STORES_REGISTER_ISSUE_REQUEST:
      return {
        ...state,
        addStoresRegisterIssue: {
          ...state.addStoresRegisterIssue,
          loading: true,
        },
      };
    case storesRegisterConstants.ADD_STORES_REGISTER_ISSUE_SUCCESS:
      return {
        ...state,
        addStoresRegisterIssue: {
          loading: false,
          success: true,
          error: "",
          message: payload.message,
        },
      };
    case storesRegisterConstants.ADD_STORES_REGISTER_ISSUE_ERROR:
      return {
        ...state,
        addStoresRegisterIssue: {
          ...state.addStoresRegisterIssue,
          error: payload,
          success: false,
          loading: false,
        },
      };
    case storesRegisterConstants.ADD_STORES_REGISTER_ISSUE_COMPLETED:
      return {
        ...state,
        addStoresRegisterIssue: {
          ...state.addStoresRegisterIssue,
          success: false,
        },
      };
    case storesRegisterConstants.DELETE_STORES_REGISTER_RECEIPT_REQUEST:
      return {
        ...state,
        deleteStoresRegisterReceipt: {
          ...state.deleteStoresRegisterReceipt,
          loading: true,
        },
      };
    case storesRegisterConstants.DELETE_STORES_REGISTER_RECEIPT_SUCCESS:
      return {
        ...state,
        deleteStoresRegisterReceipt: {
          loading: false,
          message: payload.message,
          error: "",
          success: true,
        },
        storesRegister: {
          ...state.storesRegister,
          data: payload.data,
        },
      };
    case storesRegisterConstants.DELETE_STORES_REGISTER_RECEIPT_ERROR:
      return {
        ...state,
        deleteStoresRegisterReceipt: {
          ...state.deleteStoresRegisterReceipt,
          error: payload,
          loading: false,
          success: false,
        },
      };
    case storesRegisterConstants.DELETE_STORES_REGISTER_RECEIPT_COMPLETED:
      return {
        ...state,
        deleteStoresRegisterReceipt: {
          ...state.deleteStoresRegisterReceipt,
          success: false,
          error: "",
        },
      };
    case storesRegisterConstants.DELETE_STORES_REGISTER_ISSUE_REQUEST:
      return {
        ...state,
        deleteStoresRegisterIssue: {
          ...state.deleteStoresRegisterIssue,
          loading: true,
        },
      };
    case storesRegisterConstants.DELETE_STORES_REGISTER_ISSUE_SUCCESS:
      return {
        ...state,
        deleteStoresRegisterIssue: {
          loading: false,
          success: true,
          message: payload.message,
          error: "",
        },
      };
    case storesRegisterConstants.DELETE_STORES_REGISTER_ISSUE_ERROR:
      return {
        ...state,
        deleteStoresRegisterIssue: {
          ...state.deleteStoresRegisterIssue,
          error: payload,
          loading: false,
          success: false,
        },
      };
    case storesRegisterConstants.DELETE_STORES_REGISTER_ISSUE_COMPLETED:
      return {
        ...state,
        deleteStoresRegisterIssue: {
          ...state.deleteStoresRegisterIssue,
          success: false,
          error: "",
        },
      };
    case storesRegisterConstants.SET_UPDATE_ISSUE_DATA:
      return {
        ...state,
        updateStoresRegisterIssue: {
          ...state.updateStoresRegisterIssue,
          data: payload,
        },
      };
    case storesRegisterConstants.UPDATE_STORES_REGISTER_ISSUE_SUCCESS:
      return {
        ...state,
        updateStoresRegisterIssue: {
          data: {},
          error: "",
          success: true,
          loading: false,
          message: payload.message,
        },
      };
    case storesRegisterConstants.UPDATE_STORES_REGISTER_ISSUE_REQUEST:
      return {
        ...state,
        updateStoresRegisterIssue: {
          ...state.updateStoresRegisterIssue,
          loading: true,
        },
      };
    case storesRegisterConstants.UPDATE_STORES_REGISTER_ISSUE_ERROR:
      return {
        ...state,
        updateStoresRegisterIssue: {
          ...state.updateStoresRegisterIssue,
          loading: false,
          success: false,
          error: payload,
        },
      };
    case storesRegisterConstants.UPDATE_STORES_REGISTER_ISSUE_COMPLETED:
      return {
        ...state,
        updateStoresRegisterIssue: {
          ...state.updateStoresRegisterIssue,
          success: false,
        },
      };
    case storesRegisterConstants.UPDATE_STORES_REGISTER_RECEIPT_REQUEST:
      return {
        ...state,
        updateStoresRegisterReceipt: {
          ...state.updateStoresRegisterReceipt,
          loading: true,
        },
      };
    case storesRegisterConstants.SET_UPDATE_RECEIPT_DATA:
      return {
        ...state,
        updateStoresRegisterReceipt: {
          ...state.updateStoresRegisterReceipt,
          data: payload,
        },
      };
    case storesRegisterConstants.UPDATE_STORES_REGISTER_RECEIPT_SUCCESS:
      return {
        ...state,
        updateStoresRegisterReceipt: {
          ...state.updateStoresRegisterReceipt,
          error: "",
          loading: false,
          success: true,
          message: payload.message,
        },
        storesRegister: {
          ...state.storesRegister,
          data: payload.data,
        },
      };
    case storesRegisterConstants.UPDATE_STORES_REGISTER_RECEIPT_ERROR:
      return {
        ...state,
        updateStoresRegisterReceipt: {
          ...state.updateStoresRegisterReceipt,
          error: payload,
          success: false,
          loading: false,
        },
      };
    case storesRegisterConstants.UPDATE_STORES_REGISTER_RECEIPT_COMPLETED:
      return {
        ...state,
        updateStoresRegisterReceipt: {
          ...state.updateStoresRegisterReceipt,
          success: false,
        },
      };
    case storesRegisterConstants.APPROVE_STORES_REGISTER_RECEIPT_REQUEST:
      return {
        ...state,
        approveStoresRegisterReceipt: {
          ...state.approveStoresRegisterReceipt,
          loading: true,
        },
      };
    case storesRegisterConstants.APPROVE_STORES_REGISTER_RECEIPT_SUCCESS:
      return {
        ...state,
        approveStoresRegisterReceipt: {
          loading: false,
          success: true,
          message: payload.message,
          error: "",
        },
      };
    case storesRegisterConstants.APPROVE_STORES_REGISTER_RECEIPT_ERROR:
      return {
        ...state,
        approveStoresRegisterReceipt: {
          ...state.approveStoresRegisterReceipt,
          loading: false,
          error: payload,
          success: false,
        },
      };
    case storesRegisterConstants.APPROVE_STORES_REGISTER_RECEIPT_COMPLETED:
      return {
        ...state,
        approveStoresRegisterReceipt: {
          ...state.approveStoresRegisterReceipt,
          success: false,
          error: "",
        },
      };
    case storesRegisterConstants.APPROVE_STORES_REGISTER_ISSUES_REQUEST:
      return {
        ...state,
        approveStoresRegisterIssueLoading: true,
      };
    case storesRegisterConstants.APPROVE_STORES_REGISTER_ISSUES_SUCCESS:
      return {
        ...state,
        approveStoresRegisterIssueSuccess: true,
        approveStoresRegisterIssueError: "",
        approveStoresRegisterIssueMessage: payload,
        approveStoresRegisterIssueLoading: false,
      };
    case storesRegisterConstants.APPROVE_STORES_REGISTER_ISSUES_ERROR:
      return {
        ...state,
        approveStoresRegisterIssueLoading: false,
        approveStoresRegisterIssueSuccess: false,
        approveStoresRegisterIssueError: payload,
      };
    case storesRegisterConstants.APPROVE_STORES_REGISTER_ISSUES_COMPLETED:
      return {
        ...state,
        approveStoresRegisterIssueSuccess: false,
        approveStoresRegisterIssueError: "",
      };
    case storesRegisterConstants.SET_STORES_REGISTER_RECEIPT_DETAILS_REQUEST:
      return {
        ...state,
        storesRegisterReceiptDetails: {
          ...state.storesRegisterReceiptDetails,
          loading: true,
        },
      };
    case storesRegisterConstants.SET_STORES_REGISTER_RECEIPT_DETAILS_SUCCESS:
      return {
        ...state,
        storesRegisterReceiptDetails: {
          loading: false,
          error: "",
          data: payload,
          success: true,
        },
      };
    case storesRegisterConstants.SET_STORES_REGISTER_RECEIPT_DETAILS_ERROR:
      return {
        ...state,
        storesRegisterReceiptDetails: {
          ...state.storesRegisterReceiptDetails,
          loading: false,
          error: payload,
          success: false,
        },
      };
    case storesRegisterConstants.SET_STORES_REGISTER_ISSUES_DETAILS_REQUEST:
      return {
        ...state,
        storesRegisterIssuesDetails: {
          ...state.storesRegisterIssuesDetails,
          loading: true,
        },
      };
    case storesRegisterConstants.SET_STORES_REGISTER_ISSUES_DETAILS_SUCCESS:
      return {
        ...state,
        storesRegisterIssuesDetails: {
          loading: false,
          error: "",
          data: payload,
          success: true,
        },
      };
    case storesRegisterConstants.SET_STORES_REGISTER_ISSUES_DETAILS_ERROR:
      return {
        ...state,
        storesRegisterIssuesDetails: {
          ...state.storesRegisterIssuesDetails,
          loading: false,
          error: payload,
          success: false,
        },
      };
    case storesRegisterConstants.CHECK_STORES_REGISTER_RECEIPT_REQUEST:
      return {
        ...state,
        checkStoresRegisterReceipt: {
          ...state.checkStoresRegisterReceipt,
          loading: true,
        },
      };
    case storesRegisterConstants.CHECK_STORES_REGISTER_RECEIPT_SUCCESS:
      return {
        ...state,
        checkStoresRegisterReceipt: {
          error: "",
          success: true,
          message: payload.message,
          loading: false,
        },
      };
    case storesRegisterConstants.CHECK_STORES_REGISTER_RECEIPT_ERROR:
      return {
        ...state,
        checkStoresRegisterReceipt: {
          error: payload,
          message: "",
          loading: false,
          success: false,
        },
      };
    case storesRegisterConstants.CHECK_STORES_REGISTER_RECEIPT_COMPLETED:
      return {
        ...state,
        checkStoresRegisterReceipt: {
          ...state.checkStoresRegisterReceipt,
          success: false,
          error: "",
        },
      };
    case storesRegisterConstants.CHECK_STORES_REGISTER_ISSUES_REQUEST:
      return {
        ...state,
        checkStoresRegisterIssue: {
          ...state.checkStoresRegisterIssue,
          loading: true,
        },
      };
    case storesRegisterConstants.CHECK_STORES_REGISTER_ISSUES_SUCCESS:
      return {
        ...state,
        checkStoresRegisterIssue: {
          error: "",
          message: payload,
          loading: false,
          success: true,
        },
      };
    case storesRegisterConstants.CHECK_STORES_REGISTER_ISSUES_ERROR:
      return {
        ...state,
        checkStoresRegisterIssue: {
          error: payload,
          loading: false,
          success: false,
          message: "",
        },
      };
    case storesRegisterConstants.CHECK_STORES_REGISTER_ISSUES_COMPLETED:
      return {
        ...state,
        checkStoresRegisterIssue: {
          ...state.checkStoresRegisterIssue,
          error: "",
          success: false,
        },
      };
    default:
      return state;
  }
};

export default storesRegisterState;
