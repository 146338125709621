import { permissionsConstants } from "../../actions/settings/permissions.actions";
import intitialState from "../../initialState/settings/permissions";

const permissionsState = (state = intitialState, { type, payload }) => {
  switch (type) {
    case permissionsConstants.SET_PERMISSIONS_REQUEST:
      return {
        ...state,
        permissions: {
          ...state.permissions,
          loading: true,
        },
      };
    case permissionsConstants.SET_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissions: {
          error: "",
          loading: false,
          success: true,
          data: payload,
        },
      };
    case permissionsConstants.SET_PERMISSIONS_ERROR:
      return {
        ...state,
        permissions: {
          ...state.permissions,
          loading: false,
          success: false,
          error: payload,
        },
      };
    case permissionsConstants.SET_PERMISSION_REQUEST:
      return {
        ...state,
        permission: {
          ...state.permission,
          loading: true,
        },
      };
    case permissionsConstants.SET_PERMISSION_SUCCESS:
      return {
        ...state,
        permission: {
          error: "",
          loading: false,
          success: true,
          message: payload.message,
          data: payload.data,
        },
      };
    case permissionsConstants.SET_PERMISSION_ERROR:
      return {
        ...state,
        permission: {
          ...state.permission,
          loading: false,
          success: false,
          error: payload,
        },
      };
    case permissionsConstants.CREATE_PERMISIONS_REQUEST:
      return {
        ...state,
        addPermission: {
          ...state.addPermission,
          loading: true,
        },
      };
    case permissionsConstants.CREATE_PERMISIONS_SUCCESS:
      return {
        ...state,
        addPermission: {
          error: "",
          loading: false,
          success: true,
          message: payload.message,
        },
        permissions: {
          ...state.permissions,
          data: [payload.data, ...state.permissions.data],
        },
      };
    case permissionsConstants.CREATE_PERMISIONS_ERROR:
      return {
        ...state,
        addPermission: {
          ...state.addPermission,
          error: payload,
          loading: false,
          success: false,
        },
      };
    case permissionsConstants.CREATE_PERMISIONS_COMPLETED:
      return {
        ...state,
        addPermission: {
          ...state.addPermission,
          success: false,
          error: "",
        },
      };
    case permissionsConstants.DELETE_PERMISSION_COMPLETED:
      return {
        ...state,
        deletePermission: {
          ...state.deletePermission,
          success: false,
          error: "",
        },
      };
    case permissionsConstants.DELETE_PERMISSION_REQUEST:
      return {
        ...state,
        deletePermission: {
          ...state.deletePermission,
          loading: true,
        },
      };
    case permissionsConstants.DELETE_PERMISSION_SUCCESS:
      return {
        ...state,
        deletePermission: {
          ...state.deletePermission,
          loading: false,
          success: true,
          message: payload.message,
        },
        permissions: {
          ...state.permissions,
          data: payload.data,
        },
      };
    case permissionsConstants.DELETE_PERMISSION_ERROR:
      return {
        ...state,
        deletePermission: {
          ...state.deletePermission,
          loading: false,
          error: payload,
          success: false,
        },
      };
    case permissionsConstants.UPDATE_PERMISSIONS_REQUEST:
      return {
        ...state,
        updatePermission: {
          ...state.updatePermission,
          loading: true,
        },
      };
    case permissionsConstants.SET_UPDATE_PERMISSIONS_DATA:
      return {
        ...state,
        updatePermission: {
          ...state.updatePermission,
          data: payload,
        },
      };
    case permissionsConstants.UPDATE_PERMISSIONS_SUCCESS:
      return {
        ...state,
        updatePermission: {
          ...state.updatePermission,
          loading: false,
          success: true,
          message: payload.message,
        },
        permissions: {
          ...state.permissions,
          data: payload.permissions,
        },
      };
    case permissionsConstants.UPDATE_PERMISSIONS_ERROR:
      return {
        ...state,
        updatePermission: {
          ...state.updatePermission,
          loading: false,
          success: false,
          error: payload,
        },
      };
    case permissionsConstants.UPDATE_PERMISSIONS_COMPLETED:
      return {
        ...state,
        updatePermission: {
          ...state.updatePermission,
          success: false,
          error: "",
        },
      };
    case permissionsConstants.SET_ROLES_REQUEST:
      return {
        ...state,
        roles: {
          ...state.roles,
          loading: true,
        },
      };
    case permissionsConstants.SET_ROLES_SUCCESS:
      return {
        ...state,
        roles: {
          error: "",
          loading: false,
          data: payload,
          success: true,
        },
      };
    case permissionsConstants.SET_ROLES_ERROR:
      return {
        ...state,
        roles: {
          ...state.roles,
          error: payload,
          loading: false,
          success: false,
        },
      };

    default:
      return state;
  }
};

export default permissionsState;
