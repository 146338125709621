import { recordOfBiddersConstants } from "../../../actions/procument/recordOfBidders/recordOfBidders.actions";
import initialState from "../../../initialState/procurement/recordOfBidders/recordOfBidders";

const recordOfBiddersState = (state = initialState, { type, payload }) => {
  switch (type) {
    case recordOfBiddersConstants.SET_RECORD_OF_BIDDERS_REQUEST:
      return {
        ...state,
        recordOfBiddersLoading: true,
      };
    case recordOfBiddersConstants.SET_RECORD_OF_BIDDERS_SUCCESS:
      return {
        ...state,
        recordOfBiddersLoading: false,
        recordOfBidders: payload,
        recordOfBiddersSuccess: true,
      };
    case recordOfBiddersConstants.SET_RECORD_OF_BIDDERS_ERROR:
      return {
        ...state,
        recordOfBiddersSuccess: false,
        recordOfBiddersError: payload,
        recordOfBiddersLoading: false,
      };
    case recordOfBiddersConstants.SET_BIDDERS_REQUEST:
      return {
        ...state,
        biddersLoading: true,
      };
    case recordOfBiddersConstants.SET_BIDDERS_SUCCESS:
      return {
        ...state,
        biddersLoading: false,
        bidders: payload,
        biddersSuccess: true,
      };
    case recordOfBiddersConstants.SET_BIDDERS_ERROR:
      return {
        ...state,
        biddersSuccess: false,
        biddersError: payload,
        biddersLoading: false,
      };
    case recordOfBiddersConstants.SET_RECORD_OF_BIDDERS_DETAILS_REQUEST:
      return {
        ...state,
        recordOfBiddersDetailsLoading: true,
      };
    case recordOfBiddersConstants.SET_RECORD_OF_BIDDERS_DETAILS_SUCCESS:
      return {
        ...state,
        recordOfBiddersDetailsSuccess: true,
        recordOfBiddersDetails: payload,
        recordOfBiddersDetailsError: "",
        recordOfBiddersDetailsLoading: false,
      };
    case recordOfBiddersConstants.SET_RECORD_OF_BIDDERS_DETAILS_ERROR:
      return {
        ...state,
        recordOfBiddersDetailsSuccess: false,
        recordOfBiddersDetailsError: payload,
        recordOfBiddersDetailsLoading: false,
      };
    case recordOfBiddersConstants.SET_BIDDERS_DETAILS_REQUEST:
      return {
        ...state,
        biddersDetailsLoading: true,
      };
    case recordOfBiddersConstants.SET_BIDDERS_DETAILS_SUCCESS:
      return {
        ...state,
        biddersDetailsSuccess: true,
        biddersDetails: payload,
        biddersDetailsError: "",
        biddersDetailsLoading: false,
      };
    case recordOfBiddersConstants.SET_BIDDERS_DETAILS_ERROR:
      return {
        ...state,
        biddersDetailsSuccess: false,
        biddersDetailsError: payload,
        biddersDetailsLoading: false,
      };
    case recordOfBiddersConstants.ADD_RECORD_OF_BIDDERS_REQUEST:
      return {
        ...state,
        addRecordOfBiddersLoading: true,
      };
    case recordOfBiddersConstants.ADD_RECORD_OF_BIDDERS_SUCCESS:
      return {
        ...state,
        addRecordOfBiddersLoading: false,
        addRecordOfBiddersSuccess: true,
        addRecordOfBiddersError: "",
        addRecordOfBiddersMessage: payload.message,
        recordOfBidders: payload.data,
      };
    case recordOfBiddersConstants.ADD_RECORD_OF_BIDDERS_ERROR:
      return {
        ...state,
        addRecordOfBiddersLoading: false,
        addRecordOfBiddersSuccess: false,
        addRecordOfBiddersMessage: "",
        addRecordOfBiddersError: payload,
      };
    case recordOfBiddersConstants.ADD_RECORD_OF_BIDDERS_COMPLETE:
      return {
        ...state,
        addRecordOfBiddersSuccess: false,
      };
    case recordOfBiddersConstants.ADD_BIDDERS_REQUEST:
      return {
        ...state,
        addBiddersLoading: true,
      };
    case recordOfBiddersConstants.ADD_BIDDERS_SUCCESS:
      return {
        ...state,
        addBiddersLoading: false,
        addBiddersSuccess: true,
        addBiddersError: "",
        addBiddersMessage: payload.message,
        bidders: payload.data,
      };
    case recordOfBiddersConstants.ADD_BIDDERS_ERROR:
      return {
        ...state,
        addBiddersLoading: false,
        addBiddersSuccess: false,
        addBiddersError: payload,
      };
    case recordOfBiddersConstants.ADD_BIDDERS_COMPLETE:
      return {
        ...state,
        addBiddersSuccess: false,
      };
    case recordOfBiddersConstants.EDIT_RECORD_OF_BIDDERS_DATA:
      return {
        ...state,
        editRecordOfBiddersData: payload,
      };
    case recordOfBiddersConstants.EDIT_RECORD_OF_BIDDERS_REQUEST:
      return {
        ...state,
        editRecordOfBiddersLoading: true,
      };
    case recordOfBiddersConstants.EDIT_RECORD_OF_BIDDERS_SUCCESS:
      return {
        ...state,
        editRecordOfBiddersSuccess: true,
        editRecordOfBiddersLoading: false,
        editRecordOfBiddersMessage: payload.message,
        editRecordOfBiddersError: "",
        recordOfBidders: payload.data,
      };
    case recordOfBiddersConstants.EDIT_RECORD_OF_BIDDERS_ERROR:
      return {
        ...state,
        editRecordOfBiddersSuccess: false,
        editRecordOfBiddersLoading: false,
        editRecordOfBiddersMessage: "",
        editRecordOfBiddersError: payload,
      };
    case recordOfBiddersConstants.EDIT_RECORD_OF_BIDDERS_COMPLETE:
      return {
        ...state,
        editRecordOfBiddersSuccess: false,
      };
    case recordOfBiddersConstants.EDIT_BIDDERS_DATA:
      return {
        ...state,
        editBiddersData: payload,
      };
    case recordOfBiddersConstants.EDIT_BIDDERS_REQUEST:
      return {
        ...state,
        editBiddersLoading: true,
      };
    case recordOfBiddersConstants.EDIT_BIDDERS_SUCCESS:
      return {
        ...state,
        editBiddersSuccess: true,
        editBiddersLoading: false,
        editBiddersMessage: payload.message,
        editBiddersError: "",
        bidders: payload.data,
      };
    case recordOfBiddersConstants.EDIT_BIDDERS_ERROR:
      return {
        ...state,
        editBiddersSuccess: false,
        editBiddersLoading: false,
        editBiddersError: payload,
      };
    case recordOfBiddersConstants.EDIT_BIDDERS_COMPLETE:
      return {
        ...state,
        editBiddersSuccess: false,
      };
    case recordOfBiddersConstants.DELETE_RECORD_OF_BIDDERS_REQUEST:
      return {
        ...state,
        deleteRecordOfBiddersLoading: true,
      };
    case recordOfBiddersConstants.DELETE_RECORD_OF_BIDDERS_SUCCESS:
      return {
        ...state,
        deleteRecordOfBiddersLoading: false,
        deleteRecordOfBiddersSuccess: true,
        deleteRecordOfBiddersMessage: payload.message,
        recordOfBidders: payload.data,
      };
    case recordOfBiddersConstants.DELETE_RECORD_OF_BIDDERS_ERROR:
      return {
        ...state,
        deleteRecordOfBiddersLoading: false,
        deleteRecordOfBiddersSuccess: false,
        deleteRecordOfBiddersError: payload,
      };
    case recordOfBiddersConstants.DELETE_RECORD_OF_BIDDERS_COMPLETE:
      return {
        ...state,
        deleteRecordOfBiddersSuccess: false,
        deleteRecordOfBiddersError: "",
      };
    case recordOfBiddersConstants.DELETE_BIDDERS_REQUEST:
      return {
        ...state,
        deleteBiddersLoading: true,
      };
    case recordOfBiddersConstants.DELETE_BIDDERS_SUCCESS:
      return {
        ...state,
        deleteBiddersLoading: false,
        deleteBiddersSuccess: true,
        deleteBiddersMessage: payload.message,
        bidders: payload.data,
      };
    case recordOfBiddersConstants.DELETE_BIDDERS_ERROR:
      return {
        ...state,
        deleteBiddersLoading: false,
        deleteBiddersSuccess: false,
        deleteBiddersError: payload,
      };
    case recordOfBiddersConstants.DELETE_BIDDERS_COMPLETE:
      return {
        ...state,
        deleteBiddersSuccess: false,
        deleteBiddersError: "",
      };
    case recordOfBiddersConstants.SUBMIT_RECORD_OF_BIDDERS_REQUEST:
      return {
        ...state,
        submitRecordOfBiddersLoading: true,
      };
    case recordOfBiddersConstants.SUBMIT_RECORD_OF_BIDDERS_SUCCESS:
      return {
        ...state,
        submitRecordOfBiddersLoading: false,
        submitRecordOfBiddersSuccess: true,
        submitRecordOfBiddersMessage: payload,
      };
    case recordOfBiddersConstants.SUBMIT_RECORD_OF_BIDDERS_ERROR:
      return {
        ...state,
        submitRecordOfBiddersError: payload,
        submitRecordOfBiddersLoading: false,
        submitRecordOfBiddersSuccess: false,
      };
    case recordOfBiddersConstants.SUBMIT_RECORD_OF_BIDDERS_COMPLETE:
      return {
        ...state,
        submitRecordOfBiddersError: "",
        submitRecordOfBiddersSuccess: false,
      };
    case recordOfBiddersConstants.CHECK_RECORD_OF_BIDDERS_REQUEST:
      return {
        ...state,
        checkRecordOfBiddersLoading: true,
      };
    case recordOfBiddersConstants.CHECK_RECORD_OF_BIDDERS_SUCCESS:
      return {
        ...state,
        checkRecordOfBiddersLoading: false,
        checkRecordOfBiddersSuccess: true,
        checkRecordOfBiddersMessage: payload,
      };
    case recordOfBiddersConstants.CHECK_RECORD_OF_BIDDERS_ERROR:
      return {
        ...state,
        checkRecordOfBiddersError: payload,
        checkRecordOfBiddersLoading: false,
        checkRecordOfBiddersSuccess: false,
      };
    case recordOfBiddersConstants.CHECK_RECORD_OF_BIDDERS_COMPLETE:
      return {
        ...state,
        checkRecordOfBiddersError: "",
        checkRecordOfBiddersSuccess: false,
      };
    case recordOfBiddersConstants.APPROVE_RECORD_OF_BIDDERS_REQUEST:
      return {
        ...state,
        approveRecordOfBiddersLoading: true,
      };
    case recordOfBiddersConstants.APPROVE_RECORD_OF_BIDDERS_SUCCESS:
      return {
        ...state,
        approveRecordOfBiddersSuccess: true,
        approveRecordOfBiddersLoading: false,
        approveRecordOfBiddersMessage: payload,
      };
    case recordOfBiddersConstants.APPROVE_RECORD_OF_BIDDERS_ERROR:
      return {
        ...state,
        approveRecordOfBiddersError: payload,
        approveRecordOfBiddersLoading: false,
        approveRecordOfBiddersSuccess: false,
      };
    case recordOfBiddersConstants.APPROVE_RECORD_OF_BIDDERS_COMPLETE:
      return {
        ...state,
        approveRecordOfBiddersSuccess: false,
        approveRecordOfBiddersError: "",
      };
    case recordOfBiddersConstants.SET_RECORD_OF_BIDDERS_META_DATA_REQUEST:
      return {
        ...state,
        recordOfBiddersMetaDataLoading: true,
      };
    case recordOfBiddersConstants.SET_RECORD_OF_BIDDERS_META_DATA:
      return {
        ...state,
        recordOfBiddersMetaDataLoading: false,
        recordOfBiddersMetaData: payload,
      };
    default:
      return state;
  }
};

export default recordOfBiddersState;
