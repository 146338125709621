import { requisitionMemosConstants } from "../../../actions/finance/requisitionMemo/requsitionMemo.actions";
import initialState from "../../../initialState/finance/requisitionMemo/requisitionMemo";

const requisitionMemoState = (state = initialState, { type, payload }) => {
  switch (type) {
    case requisitionMemosConstants.SET_REQUISTION_MEMOS_LOADING:
      return {
        ...state,
        requisitionMemosLoading: true,
      };
    case requisitionMemosConstants.SET_REQUISTION_MEMOS_SUCCESS:
      return {
        ...state,
        requisitionMemosSuccess: true,
        requisitionMemosLoading: false,
        requisitionMemosError: "",
        requisitionMemos: payload,
      };
    case requisitionMemosConstants.SET_REQUISTION_MEMOS_ERROR:
      return {
        ...state,
        requisitionMemosError: payload,
        requisitionMemosLoading: false,
        requisitionMemosSuccess: false,
      };
    case requisitionMemosConstants.SET_PROJECT_REQUISTION_MEMOS_LOADING:
      return {
        ...state,
        projectRequistionMemosLoading: true,
      };
    case requisitionMemosConstants.SET_PROJECT_REQUISTION_MEMOS_SUCCESS:
      return {
        ...state,
        projectRequistionMemosSuccess: true,
        projectRequistionMemosLoading: false,
        projectRequistionMemosError: "",
        projectRequistionMemos: payload,
      };
    case requisitionMemosConstants.SET_PROJECT_REQUISTION_MEMOS_ERROR:
      return {
        ...state,
        projectRequistionMemosError: payload,
        projectRequistionMemosLoading: false,
        projectRequistionMemosSuccess: false,
      };
    case requisitionMemosConstants.SET_REQUISTION_MEMO_DETAILS_LOADING:
      return {
        ...state,
        requisitionMemosDetailsLoading: true,
      };
    case requisitionMemosConstants.SET_REQUISTION_MEMO_DETAILS_SUCCESS:
      return {
        ...state,
        requisitionMemosDetailsSuccess: true,
        requisitionMemosDetailsLoading: false,
        requisitionMemosDetailsError: "",
        requisitionMemosDetails: payload,
      };
    case requisitionMemosConstants.SET_REQUISTION_MEMO_DETAILS_ERROR:
      return {
        ...state,
        requisitionMemosDetailsError: payload,
        requisitionMemosDetailsLoading: false,
        requisitionMemosDetailsSuccess: false,
      };
    case requisitionMemosConstants.SET_REQUISTION_MEMO_ACTIVITY_DETAILS_LOADING:
      return {
        ...state,
        requisitionMemosActivityDetailsLoading: true,
      };
    case requisitionMemosConstants.SET_REQUISTION_MEMO_ACTIVITY_DETAILS_SUCCESS:
      return {
        ...state,
        requisitionMemosActivityDetailsSuccess: true,
        requisitionMemosActivityDetailsLoading: false,
        requisitionMemosActivityDetailsError: "",
        requisitionMemosActivityDetails: payload,
      };
    case requisitionMemosConstants.SET_REQUISTION_MEMO_ACTIVITY_DETAILS_ERROR:
      return {
        ...state,
        requisitionMemosActivityDetailsError: payload,
        requisitionMemosActivityDetailsLoading: false,
        requisitionMemosActivityDetailsSuccess: false,
      };

    case requisitionMemosConstants.ADD_REQUISTION_MEMO_LOADING:
      return {
        ...state,
        addRequisitionMemosLoading: true,
      };
    case requisitionMemosConstants.ADD_REQUISTION_MEMO_SUCCESS:
      return {
        ...state,
        addRequisitionMemosSuccess: true,
        addRequisitionMemosLoading: false,
        addRequisitionMemosError: "",
        addRequisitionMemosMessage: payload.message,
        projectRequistionMemos: payload.data,
      };
    case requisitionMemosConstants.ADD_REQUISTION_MEMO_ERROR:
      return {
        ...state,
        addRequisitionMemosLoading: false,
        addRequisitionMemosSuccess: false,
        addRequisitionMemosError: payload,
      };
    case requisitionMemosConstants.ADD_REQUISTION_MEMO_COMPLETE:
      return {
        ...state,
        addRequisitionMemosSuccess: false,
      };
    case requisitionMemosConstants.ADD_REQUISTION_MEMO_ACTIVITY_LOADING:
      return {
        ...state,
        addRequisitionMemosactivityLoading: true,
      };
    case requisitionMemosConstants.ADD_REQUISTION_MEMO_ACTIVITY_SUCCESS:
      return {
        ...state,
        addRequisitionMemosActivitySuccess: true,
        addRequisitionMemosactivityLoading: false,
        addRequisitionMemosActitvityError: "",
        addRequisitionMemosActivityMessage: payload,
      };
    case requisitionMemosConstants.ADD_REQUISTION_MEMO_ACTIVITY_ERROR:
      return {
        ...state,
        addRequisitionMemosactivityLoading: false,
        addRequisitionMemosActivitySuccess: false,
        addRequisitionMemosActitvityError: payload,
      };
    case requisitionMemosConstants.ADD_REQUISTION_MEMO_ACTIVITY_COMPLETE:
      return {
        ...state,
        addRequisitionMemosActivitySuccess: false,
      };
    case requisitionMemosConstants.EDIT_REQUISTION_MEMO_ACTIVITY_LOADING:
      return {
        ...state,
        editRequisitionMemosActitvityLoading: true,
      };
    case requisitionMemosConstants.EDIT_REQUISTION_MEMO_ACTIVITY_SUCCESS:
      return {
        ...state,
        editRequisitionMemosActitvitySuccess: true,
        editRequisitionMemosActitvityLoading: false,
        editRequisitionMemosActitvityError: "",
        editRequisitionMemosActitvityMessage: payload,
      };
    case requisitionMemosConstants.EDIT_REQUISTION_MEMO_ACTIVITY_ERROR:
      return {
        ...state,
        editRequisitionMemosActitvityLoading: false,
        editRequisitionMemosActitvitySuccess: false,
        editRequisitionMemosActitvityError: payload,
      };
    case requisitionMemosConstants.EDIT_REQUISTION_MEMO_ACTIVITY_COMPLETE:
      return {
        ...state,
        editRequisitionMemosActitvitySuccess: false,
      };
    case requisitionMemosConstants.EDIT_REQUISTION_MEMO_ACTIVITY_DATA:
      return {
        ...state,
        editRequisitionMemosActitvityData: payload,
      };
    case requisitionMemosConstants.EDIT_REQUISTION_MEMO_LOADING:
      return {
        ...state,
        editRequisitionMemoLoading: true,
      };
    case requisitionMemosConstants.EDIT_REQUISTION_MEMO_SUCCESS:
      return {
        ...state,
        editRequisitionMemoSuccess: true,
        editRequisitionMemoLoading: false,
        editRequisitionMemoError: "",
        editRequisitionMemoMessage: payload.message,
        projectRequistionMemos: payload.data,
      };
    case requisitionMemosConstants.EDIT_REQUISTION_MEMO_ERROR:
      return {
        ...state,
        editRequisitionMemoLoading: false,
        editRequisitionMemoSuccess: false,
        editRequisitionMemoError: payload,
      };
    case requisitionMemosConstants.EDIT_REQUISTION_MEMO_COMPLETE:
      return {
        ...state,
        editRequisitionMemoSuccess: false,
      };
    case requisitionMemosConstants.EDIT_REQUISTION_MEMO_DATA:
      return {
        ...state,
        editRequisitionMemoData: payload,
      };
    case requisitionMemosConstants.DELETE_REQUISTION_MEMO_LOADING:
      return {
        ...state,
        deleteRequisitionMemosLoading: true,
      };
    case requisitionMemosConstants.DELETE_REQUISTION_MEMO_SUCCESS:
      return {
        ...state,
        deleteRequisitionMemosSuccess: true,
        deleteRequisitionMemosLoading: false,
        deleteRequisitionMemosError: "",
        deleteRequisitionMemosMessage: payload.message,
        projectRequistionMemos: payload.data,
      };
    case requisitionMemosConstants.DELETE_REQUISTION_MEMO_ERROR:
      return {
        ...state,
        deleteRequisitionMemosLoading: false,
        deleteRequisitionMemosSuccess: false,
        deleteRequisitionMemosError: payload,
      };
    case requisitionMemosConstants.DELETE_REQUISTION_MEMO_COMPLETE:
      return {
        ...state,
        deleteRequisitionMemosSuccess: false,
        deleteRequisitionMemosError: "",
      };
    case requisitionMemosConstants.DELETE_REQUISTION_MEMO_ACTIVITY_LOADING:
      return {
        ...state,
        deleteRequisitionMemosActivityLoading: true,
      };
    case requisitionMemosConstants.DELETE_REQUISTION_MEMO_ACTIVITY_SUCCESS:
      return {
        ...state,
        deleteRequisitionMemosActivitySuccess: true,
        deleteRequisitionMemosActivityLoading: false,
        deleteRequisitionMemosActivityError: "",
        deleteRequisitionMemosActivityMessage: payload,
      };
    case requisitionMemosConstants.DELETE_REQUISTION_MEMO_ACTIVITY_ERROR:
      return {
        ...state,
        deleteRequisitionMemosActivityLoading: false,
        deleteRequisitionMemosActivitySuccess: false,
        deleteRequisitionMemosActivityError: payload,
      };
    case requisitionMemosConstants.DELETE_REQUISTION_MEMO_ACTIVITY_COMPLETE:
      return {
        ...state,
        deleteRequisitionMemosActivitySuccess: false,
        deleteRequisitionMemosActivityError: "",
      };
    case requisitionMemosConstants.APPROVE_REQUISTION_MEMO_LOADING:
      return {
        ...state,
        approveRequisitionMemosLoading: true,
      };
    case requisitionMemosConstants.APPROVE_REQUISTION_MEMO_SUCCESS:
      return {
        ...state,
        approveRequisitionMemosSuccess: true,
        approveRequisitionMemosLoading: false,
        approveRequisitionMemosError: "",
        approveRequisitionMemosMessage: payload,
      };
    case requisitionMemosConstants.APPROVE_REQUISTION_MEMO_ERROR:
      return {
        ...state,
        approveRequisitionMemosLoading: false,
        approveRequisitionMemosSuccess: false,
        approveRequisitionMemosError: payload,
      };
    case requisitionMemosConstants.APPROVE_REQUISTION_MEMO_COMPLETE:
      return {
        ...state,
        approveRequisitionMemosSuccess: false,
        approveRequisitionMemosError: "",
      };
    case requisitionMemosConstants.CHECK_REQUISTION_MEMO_LOADING:
      return {
        ...state,
        checkRequisitionMemosLoading: true,
      };
    case requisitionMemosConstants.CHECK_REQUISTION_MEMO_SUCCESS:
      return {
        ...state,
        checkRequisitionMemosSuccess: true,
        checkRequisitionMemosLoading: false,
        checkRequisitionMemosError: "",
        checkRequisitionMemosMessage: payload,
      };
    case requisitionMemosConstants.CHECK_REQUISTION_MEMO_ERROR:
      return {
        ...state,
        checkRequisitionMemosLoading: false,
        checkRequisitionMemosSuccess: false,
        checkRequisitionMemosError: payload,
      };
    case requisitionMemosConstants.CHECK_REQUISTION_MEMO_COMPLETE:
      return {
        ...state,
        checkRequisitionMemosSuccess: false,
        checkRequisitionMemosError: "",
      };
    case requisitionMemosConstants.SUBMIT_REQUISTION_MEMO_LOADING:
      return {
        ...state,
        submitRequisitionMemosLoading: true,
      };
    case requisitionMemosConstants.SUBMIT_REQUISTION_MEMO_SUCCESS:
      return {
        ...state,
        submitRequisitionMemosSuccess: true,
        submitRequisitionMemosLoading: false,
        submitRequisitionMemosError: "",
        submitRequisitionMemosMessage: payload,
      };
    case requisitionMemosConstants.SUBMIT_REQUISTION_MEMO_ERROR:
      return {
        ...state,
        submitRequisitionMemosLoading: false,
        submitRequisitionMemosSuccess: false,
        submitRequisitionMemosError: payload,
      };
    case requisitionMemosConstants.SUBMIT_REQUISTION_MEMO_COMPLETE:
      return {
        ...state,
        submitRequisitionMemosSuccess: false,
        submitRequisitionMemosError: "",
      };
    case requisitionMemosConstants.META_DATA:
      return {
        ...state,
        metaDataLoading: false,
        metaData: payload,
      };
    case requisitionMemosConstants.META_DATA_LOADING:
      return {
        ...state,
        metaDataLoading: false,
      };
    default:
      return state;
  }
};

export default requisitionMemoState;
