import axios from "axios";

const errorResponseObject = {
  success: false,
  error: "Sorry, there seems to be an error.",
};

const donorsApiRequest = {
  fetchDonors: async () => {
    try {
      const res = await axios.get("/businessDevelopmentAndPartnerships/donors");
      return res;
    } catch (error) {
      console.error("Fetch donors error:", error);
      return errorResponseObject;
    }
  },

  fetchDonorDetails: async (id) => {
    try {
      const res = await axios.get(
        `/businessDevelopmentAndPartnerships/donors/${id}`
      );
      return res;
    } catch (error) {
      console.error("Fetch donor details error:", error);
      return errorResponseObject;
    }
  },

  addDonor: async (body) => {
    try {
      const res = await axios.post(
        "/businessDevelopmentAndPartnerships/donors",
        body
      );
      return res;
    } catch (error) {
      console.error("Add donor error:", error);
      return errorResponseObject;
    }
  },

  updateDonor: async (body) => {
    try {
      const res = await axios.put(
        `/businessDevelopmentAndPartnerships/donors/${body.id}`,
        body
      );
      return res;
    } catch (error) {
      console.error("Update donor error:", error);
      return errorResponseObject;
    }
  },

  deleteDonor: async (id, name) => {
    try {
      const res = await axios.delete(
        `/businessDevelopmentAndPartnerships/donors/${name}/${id}`
      );
      return res;
    } catch (error) {
      console.error("Delete donor error:", error);
      return errorResponseObject;
    }
  },

  checkDonor: async (id) => {
    try {
      const res = await axios.patch(
        `/businessDevelopmentAndPartnerships/donors/check/${id}`,
        {
          checkedAt: new Date(),
        }
      );
      return res;
    } catch (error) {
      console.error("Check donor error:", error);
      return errorResponseObject;
    }
  },

  approveDonor: async (id) => {
    try {
      const res = await axios.patch(
        `/businessDevelopmentAndPartnerships/donors/approve/${id}`,
        {
          approvedAt: new Date(),
        }
      );
      return res;
    } catch (error) {
      console.error("Approve donor error:", error);
      return errorResponseObject;
    }
  },

  setPersonIncharge: async (body) => {
    try {
      const res = await axios.patch(
        `/businessDevelopmentAndPartnerships/donors/addPersonIncharge/${body.id}`,
        body
      );
      return res;
    } catch (error) {
      console.error("Set person in charge error:", error);
      return errorResponseObject;
    }
  },

  submitProposalStatus: async (body) => {
    try {
      const res = await axios.patch(
        `/businessDevelopmentAndPartnerships/donors/proposalStatus/${body.id}`,
        body
      );
      return res;
    } catch (error) {
      console.error("Submit proposal status error:", error);
      return errorResponseObject;
    }
  },
};

export default donorsApiRequest;
