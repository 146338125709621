import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import ResetPasswordForm from "../../components/auth/forms/ResetPasswordForm";
import AuthLogo from "../../components/auth/images/AuthLogo";
import { useDispatch, useSelector } from "react-redux";
import authThunks from "../../config/thunks/auth/auth.thunk";
import { authActions } from "../../config/actions/auth/auth.action";
import { Modal } from "antd";

const ResetPassword = () => {
  const history = useHistory();
  const pathArray = history.location.pathname.split("/");
  const token = pathArray[pathArray.length - 1];
  const dispatch = useDispatch();
  const { success, message } = useSelector(
    (state) => state.authState.resetPassword
  );

  useEffect(() => {
    if (message && success) {
      Modal.success({
        title: "You password has been Reset Successfully",
        content: <p>{message}</p>,
        onOk() {
          history.push("/login");
        },
      });
    }
  }, [message, history, success]);
  const resetPassword = (values) => {
    dispatch(authActions.resetPasswordRequest());
    dispatch(authThunks.resetPassword(values, token));
  };

  return (
    <div className="bg-light p-4 min-vh-100 text-center ">
      <div className="container-fluid">
        <div className=" d-flex flex-column align-items-center justify-content-center">
          <AuthLogo />
          <ResetPasswordForm handlePasswordReset={resetPassword} />
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
