import { paymentVoucherActions } from "../../../actions/finance/paymentVouchers/paymentVouchers.actions";
import apiRequests from "../../../api/api";

const paymentVoucherThunks = {
  getPaymentVouchers: () => async (dispatch) => {
    dispatch(paymentVoucherActions.setPaymentVouchersRequest());
    const res = await apiRequests.getRequest("/finance/paymentVouchers/");
    if (res.success)
      dispatch(paymentVoucherActions.setPaymentVouchersSuccess(res.data));
    else dispatch(paymentVoucherActions.setPaymentVouchersError(res.error));
  },
  getPaymentVouchersDetails: (id) => async (dispatch) => {
    dispatch(paymentVoucherActions.setPaymentVouchersDetailsRequest());
    const res = await apiRequests.getRequest(`/finance/paymentVouchers/${id}`);
    if (res.success)
      dispatch(
        paymentVoucherActions.setPaymentVouchersDetailsSuccess(res.data)
      );
    else
      dispatch(paymentVoucherActions.setPaymentVouchersDetailsError(res.error));
  },
  addPaymentVouchers: (body, paymentVouchers) => async (dispatch) => {
    dispatch(paymentVoucherActions.addPaymentVouchersRequest());
    const res = await apiRequests.postRequest(
      "/finance/paymentVouchers/",
      body
    );
    if (res.success) {
      dispatch(
        paymentVoucherActions.addPaymentVouchersSuccess({
          data: [res.data, ...paymentVouchers],
          message: res.message,
        })
      );
      dispatch(paymentVoucherThunks.getPaymentVouchers());
    } else dispatch(paymentVoucherActions.addPaymentVouchersError(res.error));
    dispatch(paymentVoucherActions.addPaymentVouchersComplete());
  },
  editPaymentVouchers: (body, paymentVouchers) => async (dispatch) => {
    dispatch(paymentVoucherActions.editPaymentVouchersRequest());
    const res = await apiRequests.putRequest(
      `/finance/paymentVouchers/${body.id}`,
      body
    );
    if (res.success) {
      dispatch(paymentVoucherActions.editPaymentVouchersSuccess(res.message));
      dispatch(paymentVoucherThunks.getPaymentVouchers());
    } else dispatch(paymentVoucherActions.editPaymentVouchersError(res.error));
    dispatch(paymentVoucherActions.editPaymentVouchersComplete());
  },
  deletePaymentVouchers: (id, paymentVouchers) => async (dispatch) => {
    dispatch(paymentVoucherActions.deletePaymentVouchersRequest());
    const res = await apiRequests.deleteRequest(
      `/finance/paymentVouchers/${id}`
    );
    if (res.success)
      dispatch(
        paymentVoucherActions.deletePaymentVouchersSuccess({
          message: res.message,
          data: paymentVouchers,
        })
      );
    else dispatch(paymentVoucherActions.deletePaymentVouchersError(res.error));
    dispatch(paymentVoucherActions.deletePaymentVouchersComplete());
  },
  checkPaymentVouchers: (id) => async (dispatch) => {
    dispatch(paymentVoucherActions.checkPaymentVouchersRequest());
    const res = await apiRequests.patchRequest(
      `/finance/paymentVouchers/check/${id}`,
      { checkedAt: new Date() }
    );
    if (res.success) {
      dispatch(paymentVoucherActions.checkPaymentVouchersSuccess(res.message));
      dispatch(paymentVoucherThunks.getPaymentVouchersDetails(id));
    } else dispatch(paymentVoucherActions.checkPaymentVouchersError(res.error));
    dispatch(paymentVoucherActions.checkPaymentVouchersComplete());
  },
  approvePaymentVouchers: (id) => async (dispatch) => {
    dispatch(paymentVoucherActions.approvePaymentVouchersRequest());
    const res = await apiRequests.patchRequest(
      `/finance/paymentVouchers/approve/${id}`,
      { approvedAt: new Date() }
    );
    if (res.success) {
      dispatch(
        paymentVoucherActions.approvePaymentVouchersSuccess(res.message)
      );
      dispatch(paymentVoucherThunks.getPaymentVouchersDetails(id));
    } else
      dispatch(paymentVoucherActions.approvePaymentVouchersError(res.error));
    dispatch(paymentVoucherActions.approvePaymentVouchersComplete());
  },
  paymentVouchersMetaData: () => async (dispatch) => {
    dispatch(paymentVoucherActions.setPaymentVouchersMetaDataRequest());
    const res = await apiRequests.getRequest(
      "/finance/paymentVouchers/metaData"
    );
    dispatch(paymentVoucherActions.setPaymentVouchersMetaData(res.data || {}));
  },
};

export default paymentVoucherThunks;
