import React, { useState } from "react";
import { useActivityNoteStore } from "../../../../config/stores";
import { searchTable } from "../../../../config/constants";
import AntdTable from "../../../../components/common/AntdTable";
import TableTitle from "../../../../components/common/TableTitle";
import TableButtonActions from "../../../../components/common/TableButtonActions";
import moment from "moment";
const ActivityConceptNotesActivitiesTable = ({
  activities = [],
  canEdit = false,
  refreshTable = () => {},
}) => {
  const [filterTable, setFilterTableNull] = useState(null);
  const [deleteId, setDeleteId] = useState(null);

  const activityConceptNotesStore = useActivityNoteStore();
  const { gettingActivityNoteDetails, activityNoteDetails } =
    activityConceptNotesStore;

  const search = (value) => {
    const filterTable = searchTable(activities, value);
    setFilterTableNull(filterTable);
  };

  const confirmDelete = () => {
    activityConceptNotesStore.deleteActivities(
      deleteId,
      activityNoteDetails.id
    );
    setDeleteId(null);
  };

  const deleteRecord = (id) => setDeleteId(id);

  const updateRecord = (record) => {
    activityConceptNotesStore.setShowActivitiesForm(false, true, record);
  };

  const columns = [
    {
      title: "Session",
      render: (record) => {
        return (
          <div>
            <span className="d-md-none">session:</span>
            {record.session} <br />
            <div className="d-md-none">
              Person Responsible : {record.person_responsible}
              <br />
              Start : {moment(record.start).format("DDD MM YYYY HH:mm")} <br />
              End: {moment(record.end).format("DDD MM YYYY HH:mm")} <br />
              Actions:
              <TableButtonActions
                record={record}
                confirmDelete={confirmDelete}
                deleteLoading={activityConceptNotesStore.deletingActivities}
                updateRecord={updateRecord}
                deleteId={deleteId}
                deleteRecord={deleteRecord}
              />
            </div>
          </div>
        );
      },
    },
    {
      title: "Person Responsible",
      dataIndex: "person_responsible",
      responsive: ["md"],
    },
    {
      title: "Start",
      dataIndex: "start",
      responsive: ["md"],
      render: (start) => moment(start).format("DD/MM/YYYY HH:mm"),
    },
    {
      title: "End",
      dataIndex: "end",
      responsive: ["md"],
      render: (end) => moment(end).format("DD/MM/YYYY HH:mm"),
    },
    {
      title: "Actions",
      key: "actions",
      responsive: ["md"],
      render: (record) => {
        return (
          <TableButtonActions
            record={record}
            confirmDelete={confirmDelete}
            deleteLoading={activityConceptNotesStore.deletingActivities}
            updateRecord={canEdit && updateRecord}
            deleteId={deleteId}
            deleteRecord={canEdit && deleteRecord}
          />
        );
      },
    },
  ];

  const openAddModal = () => {
    activityConceptNotesStore.setShowActivitiesForm(true, true, {});
  };

  return (
    <div>
      <AntdTable
        loading={gettingActivityNoteDetails}
        title={() => (
          <TableTitle
            setFilterTableNull={setFilterTableNull}
            openAddModal={canEdit && openAddModal}
            refreshTable={refreshTable}
            search={search}
            exportRecords={null}
          />
        )}
        pageSize={20}
        data={filterTable === null ? activities || [] : filterTable}
        columns={columns}
      />
    </div>
  );
};

export default ActivityConceptNotesActivitiesTable;
