import axios from "axios";

const errorResponseObject = {
  success: false,
  error: "Sorry there seems to be an internal server error",
};

const apiRequests = {
  getRequest: async (url) => {
    try {
      const res = await axios.get(`${url}`);
      return res;
    } catch (error) {
      console.error("Get request error", error);
      return errorResponseObject;
    }
  },
  postRequest: async (url, body) => {
    try {
      const res = await axios.post(`${url}`, body);
      return res;
    } catch (error) {
      console.error("Post Request Error", error);
      return errorResponseObject;
    }
  },
  putRequest: async (url, body) => {
    try {
      const res = await axios.put(`${url}`, body);
      return res;
    } catch (error) {
      console.error("Put request error", error);
      return errorResponseObject;
    }
  },
  patchRequest: async (url, body) => {
    try {
      const res = await axios.patch(`${url}`, body);
      return res;
    } catch (error) {
      console.error("Patch request error", error);
      return errorResponseObject;
    }
  },
  deleteRequest: async (url) => {
    try {
      const res = await axios.delete(`${url}`);
      return res;
    } catch (error) {
      console.error("Delete request error", error);
      return errorResponseObject;
    }
  },
  filePostRequest: async (path, body) => {
    try {
      const res = await axios.post(`${path}`, body);
      return res;
    } catch (error) {
      console.error("File post error", error);
      return errorResponseObject;
    }
  },
};

export default apiRequests;
