import React from "react";
import PrintOutHeader from "../../../shared/PrintOutHeader";
import { Table } from "antd";
import PrintOutUserDetails from "../../../shared/PrintOutUserDetails";

const PrintBidEvaluations = ({ data = {} }) => {
  const columns = [
    {
      title: "Bidder",
      key: "rfqno",
      dataIndex: "name",
    },
    {
      title: "Compliance",
      dataIndex: "complaince",
      key: "complaice",
      responsive: ["md"],
    },
  ];

  const financialEvaluationsColumns = [
    {
      title: "Item",
      dataIndex: "name",
    },
    {
      title: "Bidder",
      dataIndex: "bidder",
    },
    {
      title: "Units",
      dataIndex: "units",
      render: (text) => Number(text)?.toLocaleString(),
    },
    {
      title: "Unit Cost",
      dataIndex: "unit_cost",
      render: (text) => `UGX ${Number(text)?.toLocaleString()}`,
    },
    {
      title: "Total Cost",
      dataIndex: "total_cost",
      render: (text) => `UGX ${Number(text)?.toLocaleString()}`,
    },
  ];
  return (
    <div className="border p-2 print-table">
      <PrintOutHeader />
      <Table
        title={() => (
          <div className="w-100 d-flex justify-content-center">
            <h6 className="fw-bold w-100 text-center my-1">
              BID EVALUATION FORM {data.id}
            </h6>
          </div>
        )}
        size="small"
        bordered
        className="my-2"
        showHeader={false}
        pagination={false}
        columns={[
          {
            dataIndex: "title",
            key: "title",
          },
          {
            dataIndex: "value",
            key: "value",
            render: (text) => <strong>{text}</strong>,
          },
        ]}
        dataSource={[
          {
            title: "RFQ No.",
            value: data.rfqDetails?.rfqno,
          },
          {
            title: "SUBJECT OF PROCUREMENT",
            value: data.rfqDetails?.subject,
          },

          {
            title: "EVALUATOR 1",
            value: `${data?.evaluator1Name?.first_name} ${data?.evaluator1Name?.other_names}`,
          },
          {
            title: "EVALUATOR 1",
            value: `${data?.evaluator2Name?.first_name} ${data?.evaluator2Name?.other_names}`,
          },
          {
            title: "EVALUATOR 1",
            value: `${data?.evaluator3Name?.first_name} ${data?.evaluator3Name?.other_names}`,
          },
        ]}
      />
      <Table
        size="small"
        pagination={false}
        title={() => <span className="fw-bold">ELIGIBILITY EVALUATIONS</span>}
        bordered={true}
        dataSource={data.eligibilityEvaluations}
        columns={columns}
      />
      <Table
        className="my-2"
        size="small"
        pagination={false}
        ri
        title={() => <span className="fw-bold">TECHNICAL EVALUATIONS</span>}
        bordered={true}
        dataSource={data.evaluationItems}
        columns={columns}
      />
      <Table
        className="my-2"
        size="small"
        pagination={false}
        title={() => <span className="fw-bold">FINANCIAL EVALUATIONS</span>}
        bordered={true}
        dataSource={data.financialEvaluations}
        columns={financialEvaluationsColumns}
      />
      <PrintOutUserDetails data={data} />
    </div>
  );
};

export default PrintBidEvaluations;
