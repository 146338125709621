import { Alert, Card, Collapse, message, Space, Spin, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import ProjectInfoDetails from "../../components/BusinessDevelopmentAndPartnerships/projects/Tables/ProjectInfoDetails";
import AdvanceAccountabilityFormDetails from "../../components/Finance/advanceAccountabilityForms/details/AdvanceAccountabilityFormDetails";
import AdvanceAccountabilityFormExpenditureDetails from "../../components/Finance/advanceAccountabilityForms/details/AdvanceAccountabilityFormExpenditureDetails";
import AddAdvanceAccountabilityForm from "../../components/Finance/advanceAccountabilityForms/forms/AddAdvanceAccountabilityForm";
import AddAdvanceAccountabilityFormExpenditure from "../../components/Finance/advanceAccountabilityForms/forms/AddAdvanceAccountabilityFormExpenditure";
import AddAdvanceAccountabilityFormExpenditureDocuments from "../../components/Finance/advanceAccountabilityForms/forms/AddAdvanceAccountabilityFormExpenditureDocument";
import EditAdvanceAccountabilityForm from "../../components/Finance/advanceAccountabilityForms/forms/EditAdvanceAccountabilityForm";
import EditAdvanceAccountabilityFormExpenditure from "../../components/Finance/advanceAccountabilityForms/forms/EditAdvanceAccountavilityFormExpenditure";
import ProjectAdvanceAccountabilityFormsTable from "../../components/Finance/advanceAccountabilityForms/tables/ProjectAdvanceAccountabilityFormsTable";
import BudgetActivityDetails from "../../components/Finance/Budget/Details/BudgetActivityDetails";
import BudgetLinesDetails from "../../components/Finance/Budget/Details/BudgetLinesDetailsModal";
import ApprovedBudget from "../../components/Finance/Budget/Tables/ApprovedBudget";
import BudgetMonitoringStatement from "../../components/Finance/Budget/Tables/BudgetMonitoringStatement";
import RequisitionMemoActivityDetails from "../../components/Finance/requisitionMemos/Details/RequisitionMemoActivityDetails";
import RequisitionMemoDetails from "../../components/Finance/requisitionMemos/Details/RequisitionMemoDetails";
import AddRequsitionMemoActivity from "../../components/Finance/requisitionMemos/forms/AddActivity";
import AddRequsitionMemo from "../../components/Finance/requisitionMemos/forms/AddRequsitionMemo";
import EditRequsitionMemoActivity from "../../components/Finance/requisitionMemos/forms/EditActivity";
import EditRequsitionMemo from "../../components/Finance/requisitionMemos/forms/EditRequisitionMemo";
import ProjectRequisitionMemoTable from "../../components/Finance/requisitionMemos/Tables/ProjectRequisitionMemoTable";
import AddRejections from "../../components/shared/Rejections/AddRejections";
import { getIdFromPath } from "../../config/helpers/app/url";
import routes from "../../config/routes/routes";
import projectThunks from "../../config/thunks/businessDevAndPartnerships/projects/projects.thunks";
import advanceAccountabilityFormThunks from "../../config/thunks/finance/advanceAccountabilityForm/advanceAccountabilityForm.thunks";
import budgetThunks from "../../config/thunks/finance/budgets/budgets.thunks";
import requisitionMemoThunks from "../../config/thunks/finance/requisitionMemo/requisitionMemo.thunks";
import logDetailThunks from "../../config/thunks/settings/logDetails.thunks";
import { FcBriefcase, FcBusiness, FcCloth, FcTodoList } from "react-icons/fc";
import AppButton from "../../components/common/AppButton";
import { AiOutlineArrowLeft, AiOutlineReload } from "react-icons/ai";
import { useActivityNoteStore } from "../../config/stores";
import ActivityConceptNotesTable from "../programsMgt/activityConceptNotes/mangeConceptNotes/ActivityConceptNotesTable";
import ActivityConceptNotesForm from "../programsMgt/activityConceptNotes/mangeConceptNotes/ActivityConceptNotesForm";
import { isEmpty } from "lodash";
import ConceptNoteDetails from "../programsMgt/activityConceptNotes/mangeConceptNotes/ConceptNoteDetails";

const MyProjectDetails = () => {
  //state, variables and initialization
  /* budget */
  const [budgetLineDetailsModalVisible, setBudgetLineDetailsModalVisible] =
    useState(false);
  const [
    budgetActivityDetailsModalVisible,
    setBudgetActivityDetailsModalVisible,
  ] = useState(false);
  /* requsition memo */
  const [addRequsitionMemoModalVisible, setAddRequsitionMemoModalVisible] =
    useState(false);
  const [editRequsitionMemoModalVisible, setEditRequsitionMemoModalVisible] =
    useState(false);
  const [
    requsitionMemoDetailsModalVisible,
    setRequsitionMemoDetailsModalVisible,
  ] = useState(false);
  const [
    addRequsitionMemoActivityModalVisible,
    setAddRequsitionMemoActivityModalVisible,
  ] = useState(false);
  const [
    editRequsitionMemoActivityModalVisible,
    setEditRequsitionMemoActivityModalVisible,
  ] = useState(false);
  const [
    requsitionMemoActivityDetailsModalVisible,
    setRequsitionMemoActivityDetailsModalVisible,
  ] = useState(false);
  /* payment voucher */
  const {
    projectsDetails,
    projectsDetailsSuccess,
    projectsDetailsLoading,
    projectsDetailsError,
  } = useSelector((state) => state.projectsState);
  const history = useHistory();
  const dispatch = useDispatch();
  const projectId = getIdFromPath(history.location.pathname);
  /* advance accountability */
  const [
    addAdvanceAccountabilityFormModalVisible,
    setAddadvanceAccountabilityFormModalVisible,
  ] = useState(false);
  const [
    editAdvanceAccountabilityFormModalVisible,
    setEditadvanceAccountabilityFormModalVisible,
  ] = useState(false);
  const [
    advanceAccountabilityFormDetailsModalVisible,
    setAdvanceAccountabilityFormDetailsModalVisible,
  ] = useState(false);
  const [
    addAdvanceAccountabilityFormExpenditureModalVisible,
    setAddadvanceAccountabilityFormExpenditureModalVisible,
  ] = useState(false);
  const [
    editAdvanceAccountabilityFormExpenditureModalVisible,
    setEditadvanceAccountabilityFormExpenditureModalVisible,
  ] = useState(false);
  const [addDocumentModalVisible, setAddDocumentModalVisible] = useState(false);
  const [
    advanceAccountabilityFormExpenditureDetailsModalVisible,
    setAdvanceAccountabilityFormExpenditureDetailsModalVisible,
  ] = useState(false);
  //ui

  const toggleBudgetLineDetailsModalVisible = (value) =>
    setBudgetLineDetailsModalVisible(value);
  const toggleBudgetActivityDetailsModalVisible = (value) =>
    setBudgetActivityDetailsModalVisible(value);
  const toggleAddRequsitionMemoModalVisible = (value) =>
    setAddRequsitionMemoModalVisible(value);
  const toggleEditRequisitionMemoModalVisible = (value) =>
    setEditRequsitionMemoModalVisible(value);
  const toggleRequisitionMemoDetailsModalVisible = (value) =>
    setRequsitionMemoDetailsModalVisible(value);
  const toggleAddRequsitionMemoActivityModalVisible = (value) =>
    setAddRequsitionMemoActivityModalVisible(value);
  const toggleEditRequsitionMemoActivityModalVisible = (value) =>
    setEditRequsitionMemoActivityModalVisible(value);
  const toggleRequsitionMemoActivityDetailsModalVisible = (value) =>
    setRequsitionMemoActivityDetailsModalVisible(value);
  //thunks
  /* budget */
  const approvedBudgetChanges = (budgetId, budget) =>
    message.info(
      "You can make changes to approved budget from the budget application"
    );
  const refreshApprovedBudget = () =>
    dispatch(budgetThunks.getApprovedBudget(projectsDetails.projectBudget));
  const refreshRequisitionMemos = () =>
    dispatch(
      requisitionMemoThunks.getProjectRequisitionMemos(projectsDetails.id)
    );
  const getBudgetLineDetails = (id) => {
    setBudgetLineDetailsModalVisible(true);
    dispatch(budgetThunks.getBudgetLineDetails(id));
  };
  const getBudgetActivityDetails = (id) => {
    setBudgetActivityDetailsModalVisible(true);
    dispatch(budgetThunks.getBudgetAcitvityDetails(id));
  };
  const exportApprovedBudgetData = () => {
    dispatch(
      logDetailThunks.addLogDetails({
        created_at: new Date(),
        activity: `Exported ${projectsDetails.name} Approved Budget Records`,
        records: "approvedBudget",
      })
    );
  };
  const refreshBudgetMoniotringStatement = () =>
    dispatch(
      budgetThunks.getBudgetMonitoringStatement(projectsDetails.projectBudget)
    );
  const exportBudgetMonitoringStatement = () => {
    dispatch(
      logDetailThunks.addLogDetails({
        created_at: new Date(),
        activity: `Exported ${projectsDetails.name} Budget Monitoring Statement`,
        records: "budgetMonitoringStatement",
      })
    );
  };
  /* requisition form */
  const addRequisitionMemo = (values, requisitionMemos) =>
    dispatch(
      requisitionMemoThunks.addRequisitionMemos(values, requisitionMemos)
    );
  const editRequisitionMemo = (values, requisitionMemosArr) =>
    dispatch(
      requisitionMemoThunks.editRequisitionMemos(values, requisitionMemosArr)
    );
  const deleteRequisitionMemo = (id, requisitionMemos) =>
    dispatch(
      requisitionMemoThunks.deleteRequisitionMemos(id, requisitionMemos)
    );
  const getRequisitionMemoDetails = (id) => {
    toggleRequisitionMemoDetailsModalVisible(true);
    dispatch(requisitionMemoThunks.getRequisitionMemosDetails(id));
  };
  const addRequisitionMemoActivity = (values) =>
    dispatch(requisitionMemoThunks.addRequisitionMemoActivity(values));
  const deleteRequisitionMemoActivity = (id, requisitionMemo) =>
    dispatch(
      requisitionMemoThunks.deleteRequisitionMemoActivity(id, requisitionMemo)
    );
  const editRequisitionMemoActivity = (values) =>
    dispatch(requisitionMemoThunks.editRequisitionMemoActivity(values));
  const getRequisitionMemoActivityDetails = (id) => {
    setRequsitionMemoActivityDetailsModalVisible(true);
    dispatch(requisitionMemoThunks.getRequisitionMemoActivityDetails(id));
  };
  const submitRequisitionMemo = (id) =>
    dispatch(requisitionMemoThunks.submitRequisitionMemo(id));
  const checkRequisitionMemo = (id) =>
    dispatch(requisitionMemoThunks.checkRequisitionMemos(id));
  const approveRequisitionMemo = (id) =>
    dispatch(requisitionMemoThunks.approveRequisitionMemos(id));
  const exportRequisitionMemos = () => {
    dispatch(
      logDetailThunks.addLogDetails({
        created_at: new Date(),
        activity: `Exported ${projectsDetails.name} Requisition Forms`,
        records: "projectRequisitionMemos",
      })
    );
  };
  /* payment voucher */
  const refreshAdvanceAccountabilityForm = () => {
    dispatch(advanceAccountabilityFormThunks.metaData(projectId));
    dispatch(
      advanceAccountabilityFormThunks.getProjectAdvanceAccountabilityForms(
        projectId
      )
    );
  };
  const addAdvanceAccountabilityForm = (values) =>
    dispatch(
      advanceAccountabilityFormThunks.addAdvanceAccountabilityForm(values)
    );
  const getAdvanceAccountabilityFormDetails = (id) => {
    setAdvanceAccountabilityFormDetailsModalVisible(true);
    dispatch(
      advanceAccountabilityFormThunks.getAdvanceAccountabilityFormsExpenditure(
        id
      )
    );
    dispatch(
      advanceAccountabilityFormThunks.getAdvanceAccountabilityFormDetails(id)
    );
  };
  const deleteAdvanceAccountabilityForms = (id) =>
    dispatch(
      advanceAccountabilityFormThunks.deleteAdvanceAccountabilityForm(
        id,
        projectId
      )
    );
  const addAdvanceAccountabilityFormExpenditure = (values) => {
    dispatch(
      advanceAccountabilityFormThunks.addAdvanceAccountabilityFormExpenditure(
        values
      )
    );
  };
  const deleteAdvanceAccountabilityFormsExpenditure = (
    id,
    advanceAccountabilityForm
  ) =>
    dispatch(
      advanceAccountabilityFormThunks.deleteAdvanceAccountabilityFormExpenditure(
        id,
        advanceAccountabilityForm
      )
    );
  const editAdvanceAccountabilityFormExpenditure = (values) =>
    dispatch(
      advanceAccountabilityFormThunks.editAdvanceAccountabilityFormExpenditure(
        values
      )
    );
  const addDocument = (values) =>
    dispatch(
      advanceAccountabilityFormThunks.addAdvanceAccountabilityFormExpenditureDocument(
        values
      )
    );
  const getAdvanceAccountabilityFormExpenditureDetails = (id) => {
    setAdvanceAccountabilityFormExpenditureDetailsModalVisible(true);
    dispatch(
      advanceAccountabilityFormThunks.getAdvanceAccountabilityFormDetailsExpenditure(
        id
      )
    );
  };
  const deleteDocument = (id, advanceAccountabilityForm) =>
    dispatch(
      advanceAccountabilityFormThunks.deleteAdvanceAccountabilityFormExpenditureDocument(
        id,
        advanceAccountabilityForm
      )
    );
  const submitAdvanceAccountabilityForm = (id) =>
    dispatch(
      advanceAccountabilityFormThunks.submitAdvanceAccountabilityForm(id)
    );
  const checkAdvanceAccountabilityForm = (id) =>
    dispatch(
      advanceAccountabilityFormThunks.checkAdvanceAccountabilityForm(id)
    );
  const approveAdvanceAccountabilityForm = (id) =>
    dispatch(
      advanceAccountabilityFormThunks.approveAdvanceAccountabilityForm(id)
    );
  const editAdvanceAccountabilityForm = (values) =>
    dispatch(
      advanceAccountabilityFormThunks.editAdvanceAccountabilityForm(values)
    );
  const exportAdvanceAccountabilityForms = () => {
    dispatch(
      logDetailThunks.addLogDetails({
        created_at: new Date(),
        activity: `Exported ${projectsDetails.name} Payment Vouchers  Records`,
        records: "projectAdvanceAccountabilityForms",
      })
    );
  };
  //useEffects
  useEffect(() => {
    document.title = routes.myProjects.myProjectDetails.title;
    getMyProject();
  }, []);

  const activityConceptNoteStore = useActivityNoteStore();

  useEffect(() => {
    if (!isEmpty(projectsDetails)) {
      dispatch(budgetThunks.getApprovedBudget(projectsDetails.projectBudget));
      dispatch(
        budgetThunks.getBudgetMonitoringStatement(projectsDetails.projectBudget)
      );
    }
  }, [projectsDetails, dispatch]);

  const getMyProject = () => {
    dispatch(projectThunks.getProjectDetails(projectId));

    dispatch(requisitionMemoThunks.getProjectRequisitionMemos(projectId));
    dispatch(
      advanceAccountabilityFormThunks.getProjectAdvanceAccountabilityForms(
        projectId
      )
    );
    dispatch(advanceAccountabilityFormThunks.metaData(projectId));

    activityConceptNoteStore.getProjectActivityNotes(projectId);
  };
  return (
    <div className="container-fluid p-2">
      {projectsDetailsLoading ? (
        <div className="d-flex justify-content-center m-5">
          <Spin size="large" />
        </div>
      ) : (
        <div>
          {!projectsDetailsSuccess ? (
            <Alert showIcon message={projectsDetailsError} type="error" />
          ) : (
            <>
              <Collapse
                className="bg-white"
                items={[
                  {
                    label: (
                      <Space align="center">
                        <p className="fw-bold my-0">
                          {projectsDetails.name?.toUpperCase()}
                        </p>
                      </Space>
                    ),
                    extra: (
                      <Space>
                        <AppButton
                          iconBefore={<AiOutlineReload />}
                          onClick={getMyProject}
                          type="default"
                          text="Reload"
                        />
                        <AppButton
                          iconBefore={<AiOutlineArrowLeft />}
                          onClick={() => history.push(routes.myProjects.path)}
                          danger
                          type="default"
                          text="View My Projects"
                        />
                      </Space>
                    ),
                    key: "project",
                    children: (
                      <>
                        <ProjectInfoDetails data={projectsDetails} />
                      </>
                    ),
                  },
                ]}
                defaultActiveKey={["project"]}
              />
              <Card className="my-1" size="small">
                <Tabs
                  type="card"
                  items={[
                    {
                      label: "ACTIVITY CONCEPT NOTES",
                      key: "activityConceptNotes",
                      icon: <FcCloth />,
                      children: (
                        <>
                          <ActivityConceptNotesForm />
                          <ConceptNoteDetails />
                          <ActivityConceptNotesTable
                            canEdit={true}
                            activityConceptNotes={
                              activityConceptNoteStore.projectActivityNotes
                                ?.data
                            }
                            refreshTable={() =>
                              activityConceptNoteStore.getProjectActivityNotes(
                                projectsDetails.id
                              )
                            }
                          />
                        </>
                      ),
                    },
                    {
                      label: "REQUISITION FORMS",
                      icon: <FcBusiness />,
                      key: "requisitionForms",
                      children: (
                        <>
                          <ProjectRequisitionMemoTable
                            handleExportRecords={exportRequisitionMemos}
                            handleViewDetails={getRequisitionMemoDetails}
                            handleOpenEditModal={
                              toggleEditRequisitionMemoModalVisible
                            }
                            handleDelete={deleteRequisitionMemo}
                            handleRefreshTable={refreshRequisitionMemos}
                            handleOpenAddModal={
                              toggleAddRequsitionMemoModalVisible
                            }
                          />
                          <AddRequsitionMemo
                            handleAddRecord={addRequisitionMemo}
                            handleCloseModal={
                              toggleAddRequsitionMemoModalVisible
                            }
                            visible={addRequsitionMemoModalVisible}
                          />
                          <EditRequsitionMemo
                            handleEditRecord={editRequisitionMemo}
                            visible={editRequsitionMemoModalVisible}
                            handleCloseModal={
                              toggleEditRequisitionMemoModalVisible
                            }
                          />
                          <RequisitionMemoDetails
                            handleSubmitRequisititionMemo={
                              submitRequisitionMemo
                            }
                            handleCheckRequisitionMemo={checkRequisitionMemo}
                            handleApproveRequisitionMemo={
                              approveRequisitionMemo
                            }
                            handleOpenViewDetails={
                              getRequisitionMemoActivityDetails
                            }
                            handleOpenEditModal={
                              toggleEditRequsitionMemoActivityModalVisible
                            }
                            handleDeleteActivity={deleteRequisitionMemoActivity}
                            handleOpenAddActivityModal={
                              toggleAddRequsitionMemoActivityModalVisible
                            }
                            handleRefreshData={getRequisitionMemoDetails}
                            handleCloseModal={
                              toggleRequisitionMemoDetailsModalVisible
                            }
                            visible={requsitionMemoDetailsModalVisible}
                          />
                          <AddRequsitionMemoActivity
                            handleAddRecord={addRequisitionMemoActivity}
                            visible={addRequsitionMemoActivityModalVisible}
                            handleCloseModal={
                              toggleAddRequsitionMemoActivityModalVisible
                            }
                          />
                          <EditRequsitionMemoActivity
                            handleCloseModal={
                              toggleEditRequsitionMemoActivityModalVisible
                            }
                            handleEditRecord={editRequisitionMemoActivity}
                            visible={editRequsitionMemoActivityModalVisible}
                          />
                          <RequisitionMemoActivityDetails
                            handleCloseModal={
                              toggleRequsitionMemoActivityDetailsModalVisible
                            }
                            visible={requsitionMemoActivityDetailsModalVisible}
                          />
                        </>
                      ),
                    },
                    {
                      label: "PAYMENT VOUCHERS",
                      icon: <FcTodoList />,
                      key: "paymentVouchers",
                      children: (
                        <>
                          <ProjectAdvanceAccountabilityFormsTable
                            handleExportRecords={
                              exportAdvanceAccountabilityForms
                            }
                            handleOpenEditModal={
                              setEditadvanceAccountabilityFormModalVisible
                            }
                            handleDelete={deleteAdvanceAccountabilityForms}
                            handleViewDetails={
                              getAdvanceAccountabilityFormDetails
                            }
                            handleOpenAddModal={
                              setAddadvanceAccountabilityFormModalVisible
                            }
                            handleRefreshTable={
                              refreshAdvanceAccountabilityForm
                            }
                          />
                          <AddAdvanceAccountabilityForm
                            handleAddRecord={addAdvanceAccountabilityForm}
                            handleCloseModal={
                              setAddadvanceAccountabilityFormModalVisible
                            }
                            visible={addAdvanceAccountabilityFormModalVisible}
                          />
                          <AdvanceAccountabilityFormDetails
                            handleApprove={approveAdvanceAccountabilityForm}
                            handleCheck={checkAdvanceAccountabilityForm}
                            handleSubmit={submitAdvanceAccountabilityForm}
                            handleViewDetails={
                              getAdvanceAccountabilityFormExpenditureDetails
                            }
                            handleOpenAddDocument={setAddDocumentModalVisible}
                            handleOpenEditModal={
                              setEditadvanceAccountabilityFormExpenditureModalVisible
                            }
                            handleDeleteExpenditure={
                              deleteAdvanceAccountabilityFormsExpenditure
                            }
                            handleRefresh={getAdvanceAccountabilityFormDetails}
                            handleOpenAddExpenditureModal={
                              setAddadvanceAccountabilityFormExpenditureModalVisible
                            }
                            handleCloseModal={
                              setAdvanceAccountabilityFormDetailsModalVisible
                            }
                            visible={
                              advanceAccountabilityFormDetailsModalVisible
                            }
                          />
                          <AddAdvanceAccountabilityFormExpenditure
                            handleCloseModal={
                              setAddadvanceAccountabilityFormExpenditureModalVisible
                            }
                            handleAddRecord={
                              addAdvanceAccountabilityFormExpenditure
                            }
                            visible={
                              addAdvanceAccountabilityFormExpenditureModalVisible
                            }
                          />
                          <EditAdvanceAccountabilityFormExpenditure
                            handleAddRecord={
                              editAdvanceAccountabilityFormExpenditure
                            }
                            handleCloseModal={
                              setEditadvanceAccountabilityFormExpenditureModalVisible
                            }
                            visible={
                              editAdvanceAccountabilityFormExpenditureModalVisible
                            }
                          />
                          <AddAdvanceAccountabilityFormExpenditureDocuments
                            handleAddRecord={addDocument}
                            handleCloseModal={setAddDocumentModalVisible}
                            visible={addDocumentModalVisible}
                          />
                          <AdvanceAccountabilityFormExpenditureDetails
                            handleDelete={deleteDocument}
                            handleCloseModal={
                              setAdvanceAccountabilityFormExpenditureDetailsModalVisible
                            }
                            visible={
                              advanceAccountabilityFormExpenditureDetailsModalVisible
                            }
                          />
                          <EditAdvanceAccountabilityForm
                            handleCloseModal={
                              setEditadvanceAccountabilityFormModalVisible
                            }
                            visible={editAdvanceAccountabilityFormModalVisible}
                            handleEditRecord={editAdvanceAccountabilityForm}
                          />
                        </>
                      ),
                    },
                    {
                      label: "APPROVED BUDGET",
                      icon: <FcBusiness />,
                      key: "approvedBudget",
                      children: (
                        <>
                          <ApprovedBudget
                            handleExportRecords={exportApprovedBudgetData}
                            handleViewBudgetActivitiesDetails={
                              getBudgetActivityDetails
                            }
                            handleOpenBudgetEditActivityModal={
                              approvedBudgetChanges
                            }
                            handleDeleteBudgetActivity={approvedBudgetChanges}
                            handleViewBudgetLineDetails={getBudgetLineDetails}
                            handleRefreshTable={refreshApprovedBudget}
                            handleDeleteBudgetLine={approvedBudgetChanges}
                          />
                          <BudgetLinesDetails
                            handleOpenAddAcitvityModal={approvedBudgetChanges}
                            visible={budgetLineDetailsModalVisible}
                            handleCloseModal={
                              toggleBudgetLineDetailsModalVisible
                            }
                          />
                          <BudgetActivityDetails
                            visible={budgetActivityDetailsModalVisible}
                            handleCloseModal={
                              toggleBudgetActivityDetailsModalVisible
                            }
                          />
                        </>
                      ),
                    },
                    {
                      label: "BUDGET MONITORING STATEMENT",
                      icon: <FcBriefcase />,
                      key: "budgetMonitoringStatement",
                      children: (
                        <BudgetMonitoringStatement
                          handleExportRecords={exportBudgetMonitoringStatement}
                          handleRefreshTable={refreshBudgetMoniotringStatement}
                        />
                      ),
                    },
                  ]}
                />
              </Card>
            </>
          )}
        </div>
      )}

      <AddRejections />
    </div>
  );
};

export default MyProjectDetails;
