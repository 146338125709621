import { assetRegisterConstants } from "../../../actions/administration/assetRegister/assetRegister.actions";
import initialState from "../../../initialState/administration/assetsRegister/assetRegister";

const assetRegisterState = (state = initialState, { type, payload }) => {
  switch (type) {
    case assetRegisterConstants.FETCH_ASSETS_REQUEST:
      return {
        ...state,
        fetchAssets: {
          ...state.fetchAssets,
          loading: true,
        },
      };
    case assetRegisterConstants.FETCH_ASSETS_SUCCESS:
      return {
        ...state,
        fetchAssets: {
          error: "",
          data: payload,
          loading: false,
          success: true,
        },
      };
    case assetRegisterConstants.FETCH_ASSETS_ERROR:
      return {
        ...state,
        fetchAssets: {
          ...state.fetchAssets,
          loading: false,
          success: false,
          error: payload,
        },
      };
    case assetRegisterConstants.SET_ASSET_REGISTER_GRAPH_DATA_REQUEST:
      return {
        ...state,
        assetRegisterGraphData: {
          ...state.assetRegisterGraphData,
          loading: true,
        },
      };
    case assetRegisterConstants.SET_ASSET_REGISTER_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        assetRegisterGraphData: {
          error: "",
          data: payload,
          loading: false,
          success: true,
        },
      };
    case assetRegisterConstants.SET_ASSET_REGISTER_GRAPH_DATA_ERROR:
      return {
        ...state,
        assetRegisterGraphData: {
          ...state.assetRegisterGraphData,
          loading: false,
          success: false,
          error: payload,
        },
      };
    case assetRegisterConstants.ADD_ASSET_REQUEST:
      return {
        ...state,
        addAsset: {
          ...state.addAsset,
          loading: true,
        },
      };
    case assetRegisterConstants.ADD_ASSET_COMPLETED:
      return {
        ...state,
        addAsset: {
          ...state.addAsset,
          success: false,
          error: "",
        },
      };
    case assetRegisterConstants.ADD_ASSET_SUCCESS:
      return {
        ...state,
        addAsset: {
          ...state.addAsset,
          loading: false,
          success: true,
        },
        fetchAssets: {
          ...state.fetchAssets,
          data: [payload, ...state.fetchAssets.data],
        },
      };
    case assetRegisterConstants.ADD_ASSET_ERROR:
      return {
        ...state,
        addAsset: {
          ...state.addAsset,
          loading: false,
          success: false,
          error: payload,
        },
      };
    case assetRegisterConstants.DELETE_ASSET_ERROR:
      return {
        ...state,
        deleteAsset: {
          ...state.deleteAsset,
          loading: false,
          success: false,
          error: payload,
        },
      };
    case assetRegisterConstants.DELETE_ASSET_REQUEST:
      return {
        ...state,
        deleteAsset: {
          ...state.deleteAsset,
          loading: true,
        },
      };
    case assetRegisterConstants.DELETE_ASSET_SUCCESS:
      return {
        ...state,
        deleteAsset: {
          ...state.deleteAsset,
          loading: false,
          success: true,
        },
        fetchAssets: {
          ...state.fetchAssets,
          data: payload,
        },
      };
    case assetRegisterConstants.DELETE_ASSET_COMPLETED:
      return {
        ...state,
        deleteAsset: {
          ...state.deleteAsset,
          success: false,
          error: "",
        },
      };
    case assetRegisterConstants.UPDATE_ASSET_REQUEST:
      return {
        ...state,
        editAsset: {
          ...state.editAsset,
          loading: true,
        },
      };
    case assetRegisterConstants.UPDATE_ASSET_SUCCESS:
      return {
        ...state,
        editAsset: {
          ...state.editAsset,
          success: true,
          loading: false,
        },
        fetchAssets: {
          ...state.fetchAssets,
          data: payload,
        },
      };
    case assetRegisterConstants.UPDATE_ASSET_COMPLETED:
      return {
        ...state,
        editAsset: {
          ...state.editAsset,
          success: false,
          error: "",
        },
      };
    case assetRegisterConstants.UPDATE_ASSET_ERROR:
      return {
        ...state,
        editAsset: {
          ...state.editAsset,
          success: false,
          loading: false,
          error: payload,
        },
      };
    case assetRegisterConstants.SET_UPDATE_ASSET_DATA:
      return {
        ...state,
        editAsset: {
          ...state.editAsset,
          data: payload,
        },
      };
    case assetRegisterConstants.UPDATE_ASSET_CATEGORY_ERROR:
      return {
        ...state,
        editAssetTypes: {
          ...state.editAssetTypes,
          success: false,
          loading: false,
          error: payload,
        },
      };
    case assetRegisterConstants.UPDATE_ASSET_CATEGORY_SUCCESS:
      return {
        ...state,
        editAssetTypes: {
          ...state.editAssetTypes,
          success: true,
          loading: false,
          error: "",
        },
        fetchAssetCategory: {
          ...state.fetchAssetCategory,
          data: payload,
        },
      };
    case assetRegisterConstants.UPDATE_ASSET_CATEGORY_REQUEST:
      return {
        ...state,
        editAssetTypes: {
          ...state.editAssetTypes,
          loading: true,
        },
      };
    case assetRegisterConstants.UPDATE_ASSET_CATEGORY_COMPLETED:
      return {
        ...state,
        editAssetTypes: {
          ...state.editAssetTypes,
          success: false,
        },
      };
    case assetRegisterConstants.SET_UPDATE_ASSET_CATEGORY_DATA:
      return {
        ...state,
        editAssetTypes: {
          ...state.editAssetTypes,
          data: payload,
        },
      };
    case assetRegisterConstants.FETCH_ASSET_CATEGORY_REQUEST:
      return {
        ...state,
        fetchAssetCategory: {
          ...state.fetchAssetCategory,
          loading: true,
        },
      };
    case assetRegisterConstants.FETCH_ASSET_CATEGORY_SUCCESS:
      return {
        ...state,
        fetchAssetCategory: {
          ...state.fetchAssetCategory,
          loading: false,
          success: true,
          data: payload,
        },
      };
    case assetRegisterConstants.FETCH_ASSET_CATEGORY_ERROR:
      return {
        ...state,
        fetchAssetCategory: {
          ...state.fetchAssetCategory,
          loading: false,
          success: false,
          error: payload,
        },
      };
    case assetRegisterConstants.ADD_ASSET_CATEGORIES_REQUEST:
    return {
        ...state,
        addAssetTypes: {
          ...state.addAssetTypes,
          loading: true,
        },
      };
    case assetRegisterConstants.ADD_ASSET_CATEGORIES_SUCCESS:
      return {
        ...state,
        addAssetTypes: {
          ...state.addAssetTypes,
          loading: false,
          success: true,
          error: "",
        },
        fetchAssetCategory: {
          ...state.fetchAssetCategory,
          data: [payload, ...state.fetchAssetCategory.data],
        },
      };
    case assetRegisterConstants.ADD_ASSET_CATEGORIES_ERROR:
      return {
        ...state,
        addAssetTypes: {
          ...state.addAssetTypes,
          loading: false,
          success: false,
          error: payload,
        },
      };
    case assetRegisterConstants.ADD_ASSET_CATGORY_COMPLETED:
      return {
        ...state,
        addAssetTypes: {
          ...state.addAssetTypes,
          success: false,
        },
      };
    case assetRegisterConstants.DELETE_ASSET_CATEGORY_REQUEST:
      return {
        ...state,
        deleteAssetTypes: {
          ...state.deleteAssetTypes,
          loading: true,
        },
      };
    case assetRegisterConstants.DELETE_ASSET_CATEGORY_SUCCESS:
      return {
        ...state,
        deleteAssetTypes: {
          ...state.deleteAssetTypes,
          loading: false,
          success: true,
          error: "",
        },
        fetchAssetCategory: {
          ...state.fetchAssetCategory,
          data: payload,
        },
      };
    case assetRegisterConstants.DELETE_ASSET_CATEGORY_ERROR:
      return {
        ...state,
        deleteAssetTypes: {
          ...state.deleteAssetTypes,
          loading: false,
          success: false,
          error: payload,
        },
      };
    case assetRegisterConstants.DELETE_ASSET_CATEGORY_COMPLETED:
      return {
        ...state,
        deleteAssetTypes: {
          ...state.deleteAssetTypes,
          success: false,
          error: "",
        },
      };
    case assetRegisterConstants.SET_ASSET_DETAILS_REQUEST:
      return {
        ...state,
        assetsDetails: { ...state.assetsDetails, loading: true },
      };
    case assetRegisterConstants.SET_ASSET_DETAILS_SUCCESS:
      return {
        ...state,
        assetsDetails: {
          error: "",
          data: payload,
          success: true,
          loading: false,
        },
      };
    case assetRegisterConstants.SET_ASSET_DETAILS_ERROR:
      return {
        ...state,
        assetsDetails: {
          ...state.assetsDetails,
          success: false,
          error: payload,
          loading: false,
        },
      };
    case assetRegisterConstants.SET_ASSET_TYPES_DETAILS_REQUEST:
      return {
        ...state,
        assetTypesDetails: {
          ...state.assetTypesDetails,
          loading: true,
        },
      };
    case assetRegisterConstants.SET_ASSET_TYPES_DETAILS_SUCCESS:
      return {
        ...state,
        assetTypesDetails: {
          loading: false,
          success: true,
          data: payload,
          error: "",
        },
      };
    case assetRegisterConstants.SET_ASSET_TYPES_DETAILS_ERROR:
      return {
        ...state,
        assetTypesDetails: {
          ...state.assetTypesDetails,
          loading: false,
          success: false,
          error: payload,
        },
      };
    case assetRegisterConstants.APPROVE_ASSET_REQUEST:
      return {
        ...state,
        approveAsset: { ...state.approveAsset, loading: true },
      };
    case assetRegisterConstants.APPROVE_ASSET_SUCCESS:
      return {
        ...state,
        approveAsset: {
          loading: false,
          success: true,
          error: "",
          message: payload,
        },
      };
    case assetRegisterConstants.APPROVE_ASSET_ERROR:
      return {
        ...state,
        approveAsset: {
          loading: false,
          success: false,
          error: payload,
          message: "",
        },
      };
    case assetRegisterConstants.APPROVE_ASSET_COMPLETED:
      return {
        ...state,
        approveAsset: {
          ...state.approveAsset,
          success: false,
          error: "",
        },
      };
    case assetRegisterConstants.APPROVE_ASSET_TYPE_REQUEST:
      return {
        ...state,
        approveAssetType: { ...state.approveAssetType, loading: true },
      };
    case assetRegisterConstants.APPROVE_ASSET_TYPE_SUCCESS:
      return {
        ...state,
        approveAssetType: {
          success: true,
          error: "",
          message: payload,
          loading: false,
        },
      };
    case assetRegisterConstants.APPROVE_ASSET_TYPE_ERROR:
      return {
        ...state,
        approveAssetType: {
          error: payload,
          message: "",
          success: false,
          loading: false,
        },
      };
    case assetRegisterConstants.APPROVE_ASSET_TYPE_COMPLETED:
      return {
        ...state,
        approveAssetType: {
          ...state.approveAssetType,
          success: false,
          error: "",
        },
      };
    case assetRegisterConstants.CHECK_ASSET_REQUEST:
      return {
        ...state,
        checkAsset: { ...state.checkAsset, loading: true },
      };
    case assetRegisterConstants.CHECK_ASSET_SUCCESS:
      return {
        ...state,
        checkAsset: {
          success: true,
          loading: false,
          error: "",
          message: payload,
        },
      };
    case assetRegisterConstants.CHECK_ASSET_ERROR:
      return {
        ...state,
        checkAsset: {
          loading: false,
          success: false,
          message: "",
          error: payload,
        },
      };
    case assetRegisterConstants.CHECK_ASSET_COMPLETED:
      return {
        ...state,
        checkAsset: {
          ...state.checkAsset,
          success: false,
          error: "",
        },
      };
    case assetRegisterConstants.CHECK_ASSET_TYPE_REQUEST:
      return {
        ...state,
        checkAssetType: { ...state.checkAssetType, loading: true },
      };
    case assetRegisterConstants.CHECK_ASSET_TYPE_SUCCESS:
      return {
        ...state,
        checkAssetType: {
          loading: false,
          error: "",
          success: true,
          message: payload,
        },
      };
    case assetRegisterConstants.CHECK_ASSET_TYPE_ERROR:
      return {
        ...state,
        checkAssetType: {
          loading: false,
          error: payload,
          success: false,
          message: "",
        },
      };
    case assetRegisterConstants.CHECK_ASSET_TYPE_COMPLETED:
      return {
        ...state,
        checkAssetType: {
          ...state.checkAssetType,
          success: false,
          error: "",
        },
      };
    case assetRegisterConstants.STAFF_RECORDS_FORM_DATA_REQUEST:
      return {
        ...state,
        metaData: { ...state.metaData, loading: true },
      };
    case assetRegisterConstants.STAFF_RECORDS_FORM_DATA:
      return {
        ...state,
        metaData: {
          ...state.metaData,
          data: payload,
          success: true,
          loading: false,
        },
      };
    default:
      return state;
  }
};

export default assetRegisterState;
