import { Button, Input, Popover, Space } from "antd";
import React from "react";
import { AiOutlinePlus, AiOutlineSearch } from "react-icons/ai";
import { FcExport, FcKindle, FcRefresh } from "react-icons/fc";
import { useDispatch } from "react-redux";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";

const AssetsTableTitle = ({
  search,
  setfilterTableNull,
  refreshTable,
  exportRecords,
  openAddModal,
}) => {
  const dispatch = useDispatch();
  const openAddAssetsModal = () => {
    openAddModal();
  };
  const openAssetsTypesModal = () => {
    dispatch(appUiActions.toggleAssetTypesModal(true));
  };

  const actions = (
    <Space direction="vertical">
      <Button className="w-100" onClick={refreshTable}>
        <Space>
          <FcRefresh /> Refresh
        </Space>
      </Button>
      <Button onClick={exportRecords} className="bg-light w-100">
        <Space>
          <FcExport />
          Export
        </Space>
      </Button>
      <Button
        className="d-lg-none w-100"
        onClick={openAssetsTypesModal}
        type="dashed"
      >
        <Space>
          <FcKindle /> Asset Types
        </Space>
      </Button>
      <Popover
        content={() => (
          <Input.Search
            onChange={setfilterTableNull}
            placeholder="Search by..."
            enterButton
            onSearch={search}
          ></Input.Search>
        )}
        trigger="click"
      >
        <Button className="w-100 d-md-none">
          <Space>
            <AiOutlineSearch /> Search
          </Space>
        </Button>
      </Popover>
    </Space>
  );
  return (
    <div className="d-flex w-100">
      <Space className="w-50" direction="horizontal">
        <Button onClick={openAddAssetsModal} type="primary">
          <Space>
            <AiOutlinePlus /> Add New Asset
          </Space>
        </Button>
        <Popover placement="bottom" trigger="click" content={actions}>
          <Button>Options</Button>
        </Popover>
        <Button
          className="d-none d-lg-block"
          onClick={openAssetsTypesModal}
          type="text"
        >
          <Space>
            <FcKindle /> Asset Types
          </Space>
        </Button>
      </Space>
      <div className="w-50 d-none d-md-block">
        <Input.Search
          onChange={setfilterTableNull}
          placeholder="Search by..."
          enterButton
          onSearch={search}
        ></Input.Search>
      </div>
    </div>
  );
};

export default AssetsTableTitle;
