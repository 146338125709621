import React from "react";
import { Route, Switch } from "react-router-dom";
import routes from "../../config/routes/routes";
import PageNotFound from "../App/404";

const FinanceLayout = () => {
  return (
    <div className="d-flex">
      <Switch>
        <Route
          exact
          path={routes.finance.chartOfAccounts.path}
          component={routes.finance.chartOfAccounts.component}
        />
        <Route
          exact
          path={routes.finance.accounts.path}
          component={routes.finance.accounts.component}
        />
        <Route
          exact
          path={routes.finance.receipts.path}
          component={routes.finance.receipts.component}
        />
        <Route
          exact
          path={routes.finance.budget.budgetDetails.path}
          component={routes.finance.budget.budgetDetails.component}
        />
        <Route
          exact
          path={routes.finance.budget.path}
          component={routes.finance.budget.component}
        />
        <Route
          exact
          path={routes.finance.requistionMemos.path}
          component={routes.finance.requistionMemos.component}
        />
        <Route
          exact
          path={routes.finance.paymentVoucher.path}
          component={routes.finance.paymentVoucher.component}
        />
        <Route
          exact
          path={routes.finance.advanceAccountabilityForm.path}
          component={routes.finance.advanceAccountabilityForm.component}
        />
        <Route>
          <PageNotFound />
        </Route>
      </Switch>
    </div>
  );
};

export default FinanceLayout;
