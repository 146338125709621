const initialState = {
  advanceAccountabilityForms: [],
  advanceAccountabilityFormsLoading: false,
  advanceAccountabilityFormsSuccess: false,
  advanceAccountabilityFormsError: "",

  projectAdvanceAccountabilityForms: [],
  projectAdvanceAccountabilityFormsLoading: false,
  projectAdvanceAccountabilityFormsSuccess: false,
  projectAdvanceAccountabilityFormsError: "",

  advanceAccountabilityFormsExpenditureLoading: false,
  advanceAccountabilityFormsExpenditureSuccess: false,
  advanceAccountabilityFormsExpenditure: [],
  advanceAccountabilityFormsExpenditureError: "",

  advanceAccountabilityFormsDetailsLoading: false,
  advanceAccountabilityFormsDetailsSuccess: false,
  advanceAccountabilityFormsDetailsError: "",
  advanceAccountabilityFormsDetails: {},

  advanceAccountabilityFormsExpenditureDetailsLoading: false,
  advanceAccountabilityFormsExpenditureDetailsSuccess: false,
  advanceAccountabilityFormsExpenditureDetailsError: "",
  advanceAccountabilityFormsExpenditureDetails: {},

  addAdvanceAccountabilityFormsSuccess: false,
  addAdvanceAccountabilityFormsLoading: false,
  addAdvanceAccountabilityFormsError: "",
  addAdvanceAccountabilityFormsMessage: "",

  addAdvanceAccountabilityFormsDocumentSuccess: false,
  addAdvanceAccountabilityFormsDocumentError: "",
  addAdvanceAccountabilityFormsDocumentLoading: false,
  addAdvanceAccountabilityFormsDocumentMessage: "",

  addAdvanceAccountabilityFormsExpenditureSuccess: false,
  addAdvanceAccountabilityFormsExpenditureError: "",
  addAdvanceAccountabilityFormsExpenditureLoading: false,
  addAdvanceAccountabilityFormsExpenditureMessage: "",

  editAdvanceAccountabilityFormsExpenditureSuccess: false,
  editAdvanceAccountabilityFormsExpenditureError: "",
  editAdvanceAccountabilityFormsExpenditureLoading: false,
  editAdvanceAccountabilityFormsExpenditureMessage: "",
  editAdvanceAccountabilityFormsExpenditureFormData: {},

  editAdvanceAccountabilityFormsSuccess: false,
  editAdvanceAccountabilityFormsError: "",
  editAdvanceAccountabilityFormsLoading: false,
  editAdvanceAccountabilityFormsMessage: "",
  editAdvanceAccountabilityFormsFormData: {},

  deleteAdvanceAccountabilityFormsExpenditureLoading: false,
  deleteAdvanceAccountabilityFormsExpenditureSuccess: false,
  deleteAdvanceAccountabilityFormsExpenditureError: "",
  deleteAdvanceAccountabilityFormsExpenditureMessage: "",

  deleteAdvanceAccountabilityFormsDocumentLoading: false,
  deleteAdvanceAccountabilityFormsDocumentSuccess: false,
  deleteAdvanceAccountabilityFormsDocumentError: "",
  deleteAdvanceAccountabilityFormsDocumentMessage: "",

  deleteAdvanceAccountabilityFormsLoading: false,
  deleteAdvanceAccountabilityFormsSuccess: false,
  deleteAdvanceAccountabilityFormsError: "",
  deleteAdvanceAccountabilityFormsMessage: "",

  approveAdvanceAccountabilityFormsLoading: false,
  approveAdvanceAccountabilityFormsSuccess: false,
  approveAdvanceAccountabilityFormsError: "",
  approveAdvanceAccountabilityFormsMessage: "",

  checkAdvanceAccountabilityFormsLoading: false,
  checkAdvanceAccountabilityFormsSuccess: false,
  checkAdvanceAccountabilityFormsError: "",
  checkAdvanceAccountabilityFormsMessage: "",

  submitAdvanceAccountabilityFormsLoading: false,
  submitAdvanceAccountabilityFormsSuccess: false,
  submitAdvanceAccountabilityFormsError: "",
  submitAdvanceAccountabilityFormsMessage: "",

  metaData: {},
  metaDataLoading: false,
};

export default initialState;
