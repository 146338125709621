import React, { useEffect } from "react";
import { useStrategicPlansStore } from "../../../config/stores";
import { isEmpty } from "lodash";
import StrategicPlansTable from "./StrategicPlansTable";
import StrategicPlanForm from "./StrategicPlanForm";
import StrategicPlanDetails from "./manageStrategicPlan";

const StrategicPlans = () => {
  const strategicPlansStore = useStrategicPlansStore();

  const { strategicPlans, getStrategicPlans, showStrategicPlanDetails } =
    strategicPlansStore;

  useEffect(() => {
    if (isEmpty(strategicPlans)) getStrategicPlans();
  }, []);
  return (
    <div className="container-fluid p-2">
      <h5 className="fw-bold">STRATEGIC PLANS</h5>
      {showStrategicPlanDetails ? (
        <StrategicPlanDetails />
      ) : (
        <>
          <StrategicPlansTable />
          <StrategicPlanForm />
        </>
      )}
    </div>
  );
};

export default StrategicPlans;
