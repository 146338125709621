import { Button, Input, Popover, Space } from "antd";
import React from "react";
import { AiOutlinePlus, AiOutlineSearch } from "react-icons/ai";
import { FcExport, FcRefresh } from "react-icons/fc";

const TableTitle = ({
  search,
  setfilterTableNull,
  refreshTable,
  exportRecords,
  openAddModal,
}) => {
  const actions = (
    <Space direction="vertical">
      <Button onClick={exportRecords} className="bg-light w-100">
        <Space>
          <FcExport />
          Export
        </Space>
      </Button>
      <Button className="w-100" onClick={refreshTable}>
        <Space>
          <FcRefresh /> Reload
        </Space>
      </Button>
      <Popover
        content={() => (
          <Input.Search
            onChange={setfilterTableNull}
            placeholder="Search by..."
            enterButton
            onSearch={search}
          ></Input.Search>
        )}
        trigger="click"
      >
        <Button className="w-100 d-md-none">
          <Space>
            <AiOutlineSearch /> Search
          </Space>
        </Button>
      </Popover>
    </Space>
  );
  return (
    <div className="d-flex w-100">
      <Space className="w-50" direction="horizontal">
        <Button onClick={openAddModal} type="primary">
          <Space>
            <AiOutlinePlus /> ADD NEW
          </Space>
        </Button>
        <Popover content={actions} trigger="click" placement="bottom">
          <Button>Actions</Button>
        </Popover>
      </Space>
      <div className="w-50 d-none d-md-block">
        <Input.Search
          onChange={setfilterTableNull}
          placeholder="Search by..."
          enterButton
          onSearch={search}
        ></Input.Search>
      </div>
    </div>
  );
};

export default TableTitle;
