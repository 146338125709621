import { rejectionsConstants } from "../../actions/rejections/rejections.actions";
import intialState from "../../initialState/rejections/rejections";

const rejectionsState = (state = intialState, { type, payload }) => {
  switch (type) {
    case rejectionsConstants.ADD_REJECTIONS_REQUEST:
      return {
        ...state,
        addRejection: { ...state.addRejection, loading: true },
      };
    case rejectionsConstants.ADD_REJECTIONS_SUCCESS:
      return {
        ...state,
        addRejection: {
          ...state.addRejection,
          message: payload,
          modalOpen: false,
          success: true,
          loading: false,
          error: "",
        },
      };
    case rejectionsConstants.ADD_REJECTIONS_ERROR:
      return {
        ...state,
        addRejection: {
          ...state.addRejection,
          success: false,
          error: payload,
          loading: false,
        },
      };
    case rejectionsConstants.ADD_REJECTIONS_COMPLETE:
      return {
        ...state,
        addRejection: {
          ...state.addRejection,
          success: false,
        },
      };
    case rejectionsConstants.TOGGLE_ADD_REJECTIONS_MODAL:
      return {
        ...state,
        addRejection: {
          ...state.addRejection,
          modalOpen: payload.visible,
          data: payload.data,
        },
      };
    case rejectionsConstants.ADD_PROCUREMENT_REJECTIONS_DATA:
      return {
        ...state,
        addProcurementRejectionsData: payload,
      };
    case rejectionsConstants.ADD_PROCUREMENT_REJECTIONS_REQUEST:
      return {
        ...state,
        addProcurementRejectionsLoading: true,
      };
    case rejectionsConstants.ADD_PROCUREMENT_REJECTIONS_SUCCESS:
      return {
        ...state,
        addProcurementRejectionsLoading: false,
        addProcurementRejectionsSuccess: true,
        addProcurementRejectionsError: "",
        addProcurementRejectionsMessage: payload,
      };
    case rejectionsConstants.ADD_PROCUREMENT_REJECTIONS_ERROR:
      return {
        ...state,
        addProcurementRejectionsLoading: false,
        addProcurementRejectionsSuccess: false,
        addProcurementRejectionsError: payload,
      };
    case rejectionsConstants.ADD_PROCUREMENT_REJECTIONS_COMPLETE:
      return {
        ...state,
        addProcurementRejectionsSuccess: false,
      };
    default:
      return state;
  }
};
export default rejectionsState;
