import React, { useCallback, useState } from "react";
import { useStrategicPlansStore } from "../../../../../config/stores";
import { searchTable } from "../../../../../config/constants";
import TableButtonActions from "../../../../../components/common/TableButtonActions";
import AntdTable from "../../../../../components/common/AntdTable";
import TableTitle from "../../../../../components/common/TableTitle";
import StrategicPlanActivities from "./activities/StrategicPlanActivitiesTable";

const ThematicAreasTable = ({ thematicAreas = [], canEdit = false }) => {
  const [filterTable, setFilterTableNull] = useState(null);
  const [deleteId, setDeleteId] = useState(null);

  const strategicPlansStore = useStrategicPlansStore();

  const search = (value) => {
    const filterTable = searchTable(thematicAreas, value);
    setFilterTableNull(filterTable);
  };

  const confirmDelete = () => {
    strategicPlansStore.deleteStrategicPlanThematicArea(deleteId);
    setDeleteId(null);
  };
  const viewDetails = (id, record) => {
    strategicPlansStore.setShowViewThemeAreaDetails({
      show: true,
      ...record,
    });
  };

  const deleteRecord = (id) => setDeleteId(id);

  const updateRecord = (record) => {
    strategicPlansStore.setShowAddStrategicPlanThematicArea(
      false,
      true,
      record
    );
  };

  const columns = [
    {
      title: "Thematic Area",
      render: (record) => {
        return (
          <div>
            <span className="d-md-none">Thematic Area:</span>
            <span
              onClick={() => viewDetails(record.id, record)}
              className="cursor-pointer text-primary"
            >
              {record.thematic_area}
            </span>{" "}
            <br />
            <div className="d-md-none">
              Description : {record.description} <br />
              Actions:
              <TableButtonActions
                record={record}
                confirmDelete={confirmDelete}
                deleteLoading={strategicPlansStore.deletingTimeSheet}
                viewDetails={viewDetails}
                updateRecord={updateRecord}
                deleteId={deleteId}
                deleteRecord={deleteRecord}
              />
            </div>
          </div>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      responsive: ["md"],
    },
    {
      title: "Actions",
      key: "actions",
      responsive: ["md"],
      render: (record) => {
        return (
          <TableButtonActions
            record={record}
            confirmDelete={confirmDelete}
            deleteLoading={
              strategicPlansStore.deletingStrategicPlanThematicArea
            }
            viewDetails={viewDetails}
            updateRecord={canEdit && updateRecord}
            deleteId={deleteId}
            deleteRecord={canEdit && deleteRecord}
          />
        );
      },
    },
  ];

  const openAddModal = () => {
    strategicPlansStore.setShowAddStrategicPlanThematicArea(true, true, {});
  };

  const refreshTable = () => {
    strategicPlansStore.getStrategicPlanDetails(
      strategicPlansStore.strategicPlanDetails.id
    );
  };

  //   const exp

  const rowExpanded = useCallback(
    (record) => (
      <StrategicPlanActivities
        activities={record.strategicPlansProposedActivities}
        canEdit
        hideAdd
      />
    ),
    []
  );
  return (
    <div>
      <AntdTable
        title={() => (
          <TableTitle
            setFilterTableNull={setFilterTableNull}
            openAddModal={canEdit && openAddModal}
            refreshTable={refreshTable}
            search={search}
            exportRecords={null}
          />
        )}
        expandable={{
          expandRowByClick: true,
          columnWidth: 40,
          expandedRowRender: rowExpanded,
        }}
        pageSize={20}
        data={filterTable === null ? thematicAreas || [] : filterTable}
        columns={columns}
      />
    </div>
  );
};

export default ThematicAreasTable;
