import { Button, message, Popover, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";
import { chartOfAccountsActions } from "../../../../config/actions/finance/chartOfAccounts/chartOfAccounts.actions";
import generatePDF from "../../../../config/services/generatePdf";
import tableButtonActions from "../../../shared/Tables/TableButtonActions";
import AccountsTable from "./AccountsTable";

const ChartOfAccountsTable = ({
  handleDeleteAccountType,
  handleDeleteAccount,
  handleViewAccountTypeDetails,
  handleViewAccountDetails,
}) => {
  const [deleteId, setDeleteId] = useState("");
  const {
    success: logDetailsSuccess,
    error: logDetailsError,
    records,
  } = useSelector((state) => state.logDetailsState.addLogDetails);
  const {
    chartOfAccounts: data,
    chartOfAccountsLoading,
    deleteAccountTypeLoading: deleteLoading,
    deleteAccountTypeMessage,
    deleteAccountTypeSuccess,
    deleteAccountTypeError,
    deleteAccountLoading,
    deleteAccountSuccess,
    deleteAccountError,
    deleteAccountMessage,
  } = useSelector((state) => state.chartOfAccountsState);
  const dispatch = useDispatch();
  const columns = [
    {
      title: "Code",
      key: "account_number",
      render: (text) => (
        <div>
          <span className="d-md-none">Code:</span> {text.account_number} <br />
          <div className="d-md-none">
            Account Type: {text.name} <br />
            Details: {text.details} <br />
            <Popover
              className=""
              trigger="click"
              placement="bottom"
              content={() =>
                tableButtonActions(text, {
                  viewDetails,
                  updateRecord,
                  confirmDelete,
                  deleteRecord,
                  deleteLoading,
                  deleteId,
                })
              }
            >
              Actions: <Button type="link">Actions</Button>
            </Popover>
          </div>
        </div>
      ),
    },
    {
      title: "Account Type",
      dataIndex: "name",
      key: "account name",
      responsive: ["md"],
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
      responsive: ["lg"],
    },
    {
      title: "Action",
      key: "operation",
      responsive: ["md"],
      render: (text) => (
        <Popover
          trigger="click"
          placement="bottom"
          content={() =>
            tableButtonActions(text, {
              viewDetails,
              updateRecord,
              confirmDelete,
              deleteRecord,
              deleteLoading,
              deleteId,
            })
          }
        >
          <Button>Actions</Button>
        </Popover>
      ),
    },
  ];
  const deleteRecord = (e) => setDeleteId(e.target.id);
  const confirmDelete = () => {
    const deleteObjectArray = data.filter((r) => r.id === deleteId);
    handleDeleteAccountType(deleteId, deleteObjectArray[0]?.name);
  };
  const updateRecord = (e) => {
    const updateRecordArray = data.filter((r) => r.id === e.target.id);
    dispatch(
      chartOfAccountsActions.setEditAccountTypeData(updateRecordArray[0])
    );
    dispatch(appUiActions.toggleEditChartOfAccountsAccountTypeModal(true));
  };
  const viewDetails = (e) => {
    handleViewAccountTypeDetails(e.target.id);
    dispatch(appUiActions.toggleChartOfAccountsAccountTypeDetailsModal(true));
  };

  const accountsTableConfig = {
    deleteRecord,
    viewDetails: (id) => {
      dispatch(appUiActions.toggleChartOfAccountsAccountDetailsModal(true));
      handleViewAccountDetails(id);
    },
    confirmDeleteAccount: (record) => {
      const updateRecordArray = data.filter((r) => r.id === record.id);
      const accounts = updateRecordArray[0].accounts;
      const account = accounts.filter((account) => {
        return account.id === deleteId;
      });
      handleDeleteAccount(deleteId, account[0].name);
    },
    updateAccount: (record, id) => {
      //filter through main object to get record
      //then filter through accounts using the id of the account to get that account
      const updateRecordArray = data.filter((r) => r.id === record.id);
      const accounts = updateRecordArray[0].accounts;
      const account = accounts.filter((account) => {
        return account.id === id;
      });
      dispatch(appUiActions.toggleEditChartOfAccountsAccountModal(true));
      dispatch(chartOfAccountsActions.setEditAccountData(account[0]));
    },
    deleteAccountLoading,
    deleteId,
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const generateRecordsPdf = () => {
    const pdfColumns = ["Code", "Account", "Details"];
    let pdfRows = [];
    data.forEach((record) => {
      const row = [record.account_number, record.name, record.details];
      pdfRows.push(row);
      record.accounts.forEach((r) => {
        const row = [r.account_number, r.name, r.details];
        pdfRows.push(row);
      });
    });
    generatePDF(
      pdfRows,
      pdfColumns,
      `Amani Intitiative Chart Of Accounts - ${new Date().toLocaleDateString()} `,
      "Amani Intiative Chart Of Accounts"
    );
  };

  /** use effects */
  useEffect(() => {
    if (deleteAccountTypeSuccess && deleteAccountTypeMessage)
      message.success(deleteAccountTypeMessage);
    else if (!deleteAccountTypeSuccess && deleteAccountTypeError)
      message.error(deleteAccountTypeError);
    if (deleteAccountSuccess && deleteAccountMessage)
      message.success(deleteAccountMessage);
    else if (!deleteAccountSuccess && deleteAccountError)
      message.error(deleteAccountError);
    if (logDetailsSuccess && records === "chartOfAccounts") {
      message.info("Started download");
      generateRecordsPdf();
    } else if (!logDetailsSuccess && logDetailsError)
      message.error(logDetailsError);
  }, [
    deleteAccountTypeSuccess,
    deleteAccountTypeMessage,
    deleteAccountTypeError,
    deleteAccountSuccess,
    deleteAccountMessage,
    deleteAccountError,
    logDetailsSuccess,
    records,
    logDetailsError,
    generateRecordsPdf,
  ]);
  return (
    <div className="mt-1">
      <Table
        bordered
        rowKey={(r) => r.id}
        dataSource={data}
        pagination={false}
        loading={chartOfAccountsLoading}
        columns={columns}
        expandable={{
          expandedRowRender: (record) =>
            AccountsTable(record, accountsTableConfig),
          defaultExpandAllRows: true,
        }}
      />
    </div>
  );
};

export default ChartOfAccountsTable;
