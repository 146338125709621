import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Space,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";
//import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  getCurrency,
  numberWithCommas,
} from "../../../../config/helpers/app/numberFormater";
import TextArea from "antd/lib/input/TextArea";
import { budgetActions } from "../../../../config/actions/finance/budget/budget.actions";
import AppInput from "../../../common/AppInput";

const { Option, OptGroup } = Select;

const EditBudgetLine = ({ handleEditRecord }) => {
  const {
    editBudgetLineSuccess,
    editBudgetLineLoading,
    editBudgetLineError,
    editBudgetLineMessage,
    metaData,
    metaDataLoading,
    budgetDetails,
    editBudgetLineFormData: data,
  } = useSelector((state) => state.budgetState);
  const { editBudgetLineModal: visible } = useSelector((state) => state.AppUi);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [formListDisplay, setFormListDisplay] = useState("horizontal");
  const budgetLineForm = document.getElementById("budgetLineEditForm");

  const closeModal = () => {
    dispatch(appUiActions.toggleEditBudgetLineModal(false));
  };
  const onFinish = (values) => {
    let budgetSubTotal = 0;
    dispatch(
      budgetActions.editBudgetLineData({
        name: values.name,
        id: data.id,
        expense_code: values.expense_code,
        requires_concept_note: values.requires_concept_note,
        activities: values.activitiesArray,
      })
    );
    values.activities = values.activitiesArray.map((activity) => {
      budgetSubTotal +=
        parseInt(activity?.units) * parseInt(activity?.unit_cost);
      const {
        unit_cost,
        chart_of_accounts_account,
        units,
        name,
        budget_notes,
        id,
      } = activity;
      return [
        name,
        chart_of_accounts_account,
        units,
        unit_cost,
        budget_notes,
        id,
        "~+~",
      ];
    });
    values.updated_at = new Date();
    values.id = data.id;

    values.budget = budgetDetails.id;
    values.budgetSubTotal = budgetSubTotal;
    handleEditRecord(values);
  };
  useEffect(() => {
    if (editBudgetLineSuccess && editBudgetLineMessage) {
      message.success(editBudgetLineMessage);
      dispatch(appUiActions.toggleEditBudgetLineModal(false));
    }
    if (budgetLineForm?.clientWidth > 800) {
      setFormListDisplay("horizontal");
    } else {
      setFormListDisplay("vertical");
    }
  }, [
    budgetLineForm?.clientWidth,
    dispatch,
    editBudgetLineMessage,
    editBudgetLineSuccess,
  ]);
  const currency = getCurrency(budgetDetails, "approved_budget");
  const formActivities = data.activities?.map((activity) => {
    activity.units = parseInt(activity.units);
    activity.unit_cost = parseInt(activity.unit_cost);
    return activity;
  });
  const fields = [
    { name: "name", value: data.name },
    { name: "expense_code", value: data.expense_code },
    { name: "requires_concept_note", value: data.requires_concept_note },
    {
      name: "activitiesArray",
      value: formActivities,
    },
  ];

  return (
    <div id="budgetLineEditForm">
      <Modal
        className="w-100"
        open={visible}
        onCancel={closeModal}
        footer={null}
        title="EDIT BUDGET LINE"
      >
        <Form fields={fields} form={form} onFinish={onFinish} layout="vertical">
          {!editBudgetLineSuccess && editBudgetLineError && (
            <Alert
              className="my-2"
              showIcon
              type="error"
              message={editBudgetLineError}
            />
          )}
          <Form.Item
            name="name"
            label="Budget Line Name"
            rules={[
              { required: true, message: "Please add a budget line name" },
            ]}
          >
            <Input />
          </Form.Item>
          <Row gutter={4}>
            <Col span={12}>
              <AppInput
                name="expense_code"
                label="Expense Code"
                rules={[
                  { required: true, message: "Please enter an expense code" },
                ]}
              />
            </Col>
            <Col span={12}>
              <AppInput
                name="requires_concept_note"
                label="Requires Concept Note"
                type="select"
                options={[
                  { label: "Yes", value: true },
                  { label: "No", value: false },
                ]}
                rules={[{ required: true, message: "Please select an option" }]}
              />
            </Col>
          </Row>
          <Form.List name="activitiesArray">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Space
                    key={key}
                    direction={formListDisplay}
                    className="w-100"
                  >
                    <Form.Item
                      name={[name, "name"]}
                      label="Activity"
                      rules={[
                        {
                          required: true,
                          message: "Please add a budget activity name",
                        },
                      ]}
                    >
                      <TextArea />
                    </Form.Item>
                    <Form.Item
                      className=""
                      label="Account"
                      {...restField}
                      name={[name, "chart_of_accounts_account"]}
                      rules={[
                        { required: true, message: "Chart Of Accounts " },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.key
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        loading={metaDataLoading}
                        className="w-100"
                        allowClear
                        placeholder="Chart Of Accounts Account"
                      >
                        {metaData?.chartOfAccountsAccounts?.map(
                          (accountType) => {
                            return (
                              <OptGroup
                                label={accountType.name}
                                key={`${accountType.name} - ${accountType.account_number}`}
                              >
                                {accountType.accounts?.map((account) => {
                                  const shortenedName =
                                    account.name?.slice(0, 30) + "...";
                                  return (
                                    <Option
                                      key={`${account.name} - ${account.account_number}`}
                                      value={account.id}
                                    >
                                      {account.name?.length > 30
                                        ? `${shortenedName} - ${account.account_number}`
                                        : `${account.name} - ${account.account_number}`}
                                    </Option>
                                  );
                                })}
                              </OptGroup>
                            );
                          }
                        )}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label="Units"
                      name={[name, "units"]}
                      hasFeedback
                      rules={[{ required: true, message: "Missing Units" }]}
                    >
                      <InputNumber precision={0} placeholder="Units" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label="Unit Cost"
                      name={[name, "unit_cost"]}
                      hasFeedback
                      rules={[{ required: true, message: "Missing Unit Cost" }]}
                    >
                      <Input placeholder="Unit Cost" />
                    </Form.Item>
                    <Form.Item label="Total" shouldUpdate>
                      {({ getFieldValue }) => {
                        const getAllActivities =
                          getFieldValue("activitiesArray");
                        const activity = getAllActivities[fieldKey];
                        const total_cost =
                          parseInt(activity?.units) *
                          parseInt(activity?.unit_cost);
                        return (
                          <Input
                            readOnly
                            value={
                              !isNaN(total_cost)
                                ? `${
                                    currency === "D" ? "USD" : "UGX"
                                  } ${numberWithCommas(total_cost)}`
                                : 0
                            }
                          ></Input>
                        );
                      }}
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label="Budget Notes"
                      name={[name, "budget_notes"]}
                    >
                      <Input placeholder="Budget Notes" />
                    </Form.Item>
                  </Space>
                ))}
              </>
            )}
          </Form.List>
          <Form.Item shouldUpdate>
            {({ getFieldValue }) => {
              const allActivities = getFieldValue("activitiesArray");
              let subTotal = 0;
              allActivities?.forEach((activity) => {
                subTotal +=
                  parseInt(activity?.units) * parseInt(activity?.unit_cost);
              });

              return (
                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <td>SubTotal</td>
                      <td>
                        {!isNaN(subTotal)
                          ? `${
                              currency === "D" ? "USD" : "UGX"
                            } ${numberWithCommas(subTotal)}`
                          : 0}
                      </td>
                    </tr>
                  </tbody>
                </table>
              );
            }}
          </Form.Item>

          <Form.Item>
            <Button
              loading={editBudgetLineLoading}
              type="primary"
              htmlType="submit"
              className="w-100"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default EditBudgetLine;
