import axios from "axios";

const errorResponseObject = {
  success: false,
  error: "Sorry there seems to be an error",
};

const budgetApiRequests = {
  getBudgets: async () => {
    try {
      const res = await axios.get(`/finance/budgets/`);
      return res;
    } catch (error) {
      console.error("Fetch budgets error", error);
      return errorResponseObject;
    }
  },
  getApprovedBudget: async (id) => {
    try {
      const res = await axios.get(`/finance/budgets/approvedBudget/${id}`);
      return res;
    } catch (error) {
      console.error("Approved budget request error", error);
      return errorResponseObject;
    }
  },
  getBudgetMonitoringStatement: async (id) => {
    try {
      const res = await axios.get(
        `/finance/budgets/budgetMonitoringStatement/${id}`
      );
      return res;
    } catch (error) {
      console.error("Budget monitoring statement error", error);
      return errorResponseObject;
    }
  },
  addBudget: async (body) => {
    try {
      const res = await axios.post(`/finance/budgets`, body);
      return res;
    } catch (error) {
      console.error("Add budget error: ", error);
      return errorResponseObject;
    }
  },
  addBudgetLine: async (body) => {
    try {
      const res = await axios.post(`/finance/budgets/budgetLines`, body);
      return res;
    } catch (error) {
      console.error("Add budget line error:", error);
      return errorResponseObject;
    }
  },
  addBudgetActivity: async (body) => {
    try {
      const res = await axios.post(`/finance/budgets/budgetActivities`, body);
      return res;
    } catch (error) {
      console.error("Add budget activity error:", error);
      return errorResponseObject;
    }
  },
  editBudgetLine: async (body) => {
    try {
      const res = await axios.put(
        `/finance/budgets/budgetLines/${body.id}`,
        body
      );
      return res;
    } catch (error) {
      console.error("Edit Budget line error", error);
      return errorResponseObject;
    }
  },
  editBudgetActivity: async (body) => {
    try {
      const res = await axios.put(
        `/finance/budgets/budgetActivities/${body.id}`,
        body
      );
      return res;
    } catch (error) {
      console.error("Edit Budget activity error", error);
      return errorResponseObject;
    }
  },
  deleteBudget: async (id, project) => {
    try {
      const res = await axios.delete(`/finance/budgets/${project}/${id}`);
      return res;
    } catch (error) {
      console.error(error);
      return errorResponseObject;
    }
  },
  deleteBudgetLine: async (id, budget) => {
    try {
      const res = await axios.delete(
        `/finance/budgets/budgetLines/${budget}/${id}`
      );
      return res;
    } catch (error) {
      console.error(error);
      return errorResponseObject;
    }
  },
  deleteBudgetActivity: async (id, budget) => {
    try {
      const res = await axios.delete(
        `/finance/budgets/budgetActivities/${budget}/${id}`
      );
      return res;
    } catch (error) {
      console.error(error);
      return errorResponseObject;
    }
  },
  approveBudget: async (id) => {
    try {
      const res = await axios.patch(`/finance/budgets/approve/${id}`, {
        approvedAt: new Date(),
      });
      return res;
    } catch (error) {
      console.error(error);
      return errorResponseObject;
    }
  },
  checkBudget: async (id) => {
    try {
      const res = await axios.patch(`/finance/budgets/check/${id}`, {
        checkedAt: new Date(),
      });
      return res;
    } catch (error) {
      console.error(error);
      return errorResponseObject;
    }
  },
  submitBudget: async (id) => {
    try {
      const res = await axios.patch(`/finance/budgets/submit/${id}`);
      return res;
    } catch (error) {
      console.error(error);
      return errorResponseObject;
    }
  },
  metaData: async () => {
    try {
      const res = await axios.get(`/finance/budgets/metaData`);
      return res;
    } catch (error) {
      console.error(error);
      return errorResponseObject;
    }
  },
  getDetails: async (path) => {
    try {
      const res = await axios.get(`/finance/${path}`);
      return res;
    } catch (error) {
      console.error(error);
      return errorResponseObject;
    }
  },
};

export default budgetApiRequests;
