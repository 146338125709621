import { Tag } from "antd";
import React from "react";
import {
  AiFillCheckCircle,
  AiFillExclamationCircle,
  AiFillIeCircle,
  AiFillMinusCircle,
  AiOutlineExclamationCircle,
} from "react-icons/ai";
import appConfig from "../../../../config/config.json";

const BidEvaluationOtherDetails = ({ data }) => {
  return (
    <div>
      <table
        id="paymentVoucherDetails"
        className="table table-striped table-light  table-hover"
      >
        <thead>
          <tr>
            <th scope="col">Column</th>
            <th scope="col">Data</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Bid Evaluation Form No.</td>
            <td>{data.id}</td>
          </tr>
          <tr>
            <td>Rfq No.</td>
            <td>{data.rfqDetails?.rfqno}</td>
          </tr>
          <tr>
            <td>Subject Of procurement</td>
            <td>{data.rfqDetails?.subject}</td>
          </tr>
          <tr>
            <td>Evaluator 1</td>
            <td>
              {data?.evaluator1Name?.first_name}{" "}
              {data?.evaluator1Name?.other_names}
            </td>
          </tr>
          <tr>
            <td>Evaluator 2</td>
            <td>
              {data?.evaluator2Name?.first_name}{" "}
              {data?.evaluator2Name?.other_names}
            </td>
          </tr>
          <tr>
            <td>Evaluator 3</td>
            <td>
              {data?.evaluator3Name?.first_name}{" "}
              {data?.evaluator3Name?.other_names}
            </td>
          </tr>
          <tr>
            <td>Status</td>
            <td>
              {data.status === appConfig.status.approved && (
                <Tag icon={<AiFillCheckCircle />} color="success">
                  {data.status}
                </Tag>
              )}
              {data.status === appConfig.status.checked && (
                <Tag icon={<AiFillMinusCircle />} color="processing">
                  {data.status}
                </Tag>
              )}
              {data.status === appConfig.status.created && (
                <Tag icon={<AiFillExclamationCircle />} color="warning">
                  Pending
                </Tag>
              )}
              {data.status === appConfig.status.pending && (
                <Tag icon={<AiOutlineExclamationCircle />} color="warning">
                  Pending
                </Tag>
              )}
              {data.status === appConfig.status.rejected && (
                <Tag icon={<AiFillIeCircle />} color="error">
                  Rejected
                </Tag>
              )}
            </td>
          </tr>
          <tr>
            <td>Created By</td>
            <td>{`${data?.creator?.first_name} ${data?.creator?.other_names}`}</td>
          </tr>
          <tr>
            <td>Created At</td>
            <td>
              {`${new Date(data.created_at).toDateString()} ${new Date(
                data.created_at
              ).toLocaleTimeString()}
                                   `}
            </td>
          </tr>
          <tr>
            <td>Last Updated By</td>
            <td>
              {data.updator &&
                `${data?.updator?.first_name} ${data?.updator?.other_names}`}
            </td>
          </tr>
          <tr>
            <td>Last Updated At</td>
            <td>
              {data.updated_at &&
                `${new Date(data.updated_at).toDateString()} ${new Date(
                  data.updated_at
                ).toLocaleTimeString()}`}
            </td>
          </tr>
          <tr>
            <td>Checked By</td>
            <td>
              {data.checker &&
                `${data?.checker?.first_name} ${data?.checker?.other_names}`}
            </td>
          </tr>
          <tr>
            <td>Checked At</td>
            <td>
              {data.checked_at &&
                `${new Date(data.checked_at).toDateString()} ${new Date(
                  data.checked_at
                ).toLocaleTimeString()}
                                   `}
            </td>
          </tr>
          <tr>
            <td>Approved By</td>
            <td>
              {data.approver &&
                `${data?.approver?.first_name} ${data?.approver?.other_names}`}
            </td>
          </tr>
          <tr>
            <td>Approved At</td>
            <td>
              {data.approved_at &&
                `${new Date(data.approved_at).toDateString()} ${new Date(
                  data.approved_at
                ).toLocaleTimeString()}`}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default BidEvaluationOtherDetails;
