import { Button, Form, Input, Modal, Select } from "antd";
import React, { useEffect } from "react";
import { FcHighPriority } from "react-icons/fc";
import { useDispatch, useSelector } from "react-redux";
import { staffRecordsActions } from "../../../../config/actions/administration/staffRecords/staffRecords.actions";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";
import permissionsThunks from "../../../../config/thunks/settings/permissions.thunks";
import appConfig from "../../../../config/config.json";

const { Option } = Select;

const EditStaffRecordsForm = ({ handleEditStaffRecord }) => {
  const dispatch = useDispatch();
  //use selector to get state from redux state
  const {
    success: rolesSuccess,
    loading: rolesLoading,
    data: rolesArray,
  } = useSelector((state) => state.permissionsState.roles);
  const { loading: usersLoading, data: users } = useSelector(
    (state) => state.staffRecordsState.staffRecords
  );
  const { error, success, loading, data } = useSelector(
    (state) => state.staffRecordsState.updateStaffRecords
  );
  const visible = useSelector(
    (state) => state.AppUi.admisnistration.staffRecords.editStaffModal
  );

  //submit form
  const onFinish = (values) => {
    values.updatedAt = new Date();
    values.id = data.id;
    dispatch(staffRecordsActions.setUpdateStaffData(values));
    handleEditStaffRecord(values);
  };
  //close modal
  const closeAddRoleModal = () => {
    dispatch(appUiActions.toggleEditStaffRecordsModal(false));
  };
  //use effect
  useEffect(() => {
    if (!rolesSuccess) dispatch(permissionsThunks.getRoles());
  }, [rolesSuccess, dispatch]);
  //form fields
  const fields = [
    { name: "first_name", value: data.first_name },
    { name: "other_names", value: data.other_names },
    { name: "role_id", value: data.role_id },
    { name: "gender", value: data.gender },
    { name: "contact", value: data.contact },
    { name: "department", value: data.department },
    { name: "email", value: data.email },
    { name: "supervised_by", value: data.supervised_by },
  ];

  return (
    <div>
      <Modal
        onCancel={closeAddRoleModal}
        open={visible}
        title="ADD NEW STAFF"
        footer={null}
        validateTrigger="onBlur"
      >
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          validateTrigger="onBlur"
          layout="horizontal"
          onFinish={onFinish}
          fields={fields}
        >
          {!success && error && (
            <div className="alert alert-danger">
              <FcHighPriority /> &nbsp; {error}
            </div>
          )}
          <Form.Item
            name="first_name"
            label="First Name"
            rules={[{ required: true, message: "Please add a first name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="other_names"
            label="Other_names"
            rules={[{ required: true, message: "Please add a first name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Location"
            name="gender"
            rules={[{ required: true, message: "Please add a location" }]}
          >
            <Select>
              <Option value="M">Male</Option>
              <Option value="F">Female</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Contact"
            name="contact"
            rules={[
              {
                required: true,
                message: "Please  add a contact",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Department"
            name="department"
            rules={[{ required: true, message: "Please add a department" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Please add an email" },
              {
                pattern:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: "Please enter a valid email",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: "Please select a role" }]}
            name="role_id"
            label="Role"
          >
            <Select loading={rolesLoading}>
              {rolesArray.map((e, idx) => {
                return (
                  <Option value={e.id} key={idx}>
                    {e.role}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Please a person to supervise the user",
              },
            ]}
            name="supervised_by"
            label="Supervised By"
          >
            <Select
              disabled={data.role === "Admin"}
              defaultValue=""
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .join("")
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              loading={usersLoading}
            >
              <Option key="#" value="none">
                None
              </Option>
              {users.map((user, id) => {
                return (
                  <Option value={user.id} key={user.id}>
                    {`${user.first_name} ${user.other_names}`}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item className={"mt-3"} wrapperCol={{ offset: 8, span: 16 }}>
            <Button
              disabled={data.role === appConfig.systemAdminAccount}
              loading={loading}
              htmlType="submit"
              type="primary"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default EditStaffRecordsForm;
