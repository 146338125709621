import {
  Modal,
  Form,
  Button,
  message,
  Alert,
  Input,
  InputNumber,
  Select,
} from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  getCurrency,
  numberWithCommas,
} from "../../../../config/helpers/app/numberFormater";

const { Option } = Select;

const AddRequsitionMemoActivity = ({
  visible,
  handleAddRecord,
  handleCloseModal,
}) => {
  const {
    addRequisitionMemosActivitySuccess,
    addRequisitionMemosactivityLoading,
    addRequisitionMemosActitvityError,
    addRequisitionMemosActivityMessage,
  } = useSelector((state) => state.requisitionMemoState);
  const { budgetDetails, approvedBudget, approvedBudgetLoading } = useSelector(
    (state) => state.budgetState
  );
  const { requisitionMemosDetails } = useSelector(
    (state) => state.requisitionMemoState
  );
  const budgetActivities = approvedBudget.filter(
    (item) => item.id === requisitionMemosDetails.budget_line
  );
  const currency = getCurrency(budgetDetails, "approved_budget");
  const closeModal = () => handleCloseModal(false);
  const [form] = Form.useForm();
  const onFinish = (values) => {
    values.created_at = new Date();
    values.requisition_memo = requisitionMemosDetails.id;
    values.budget_line = requisitionMemosDetails.budget_line;
    handleAddRecord(values);
  };
  useEffect(() => {
    if (
      addRequisitionMemosActivitySuccess &&
      addRequisitionMemosActivityMessage
    ) {
      message.success(addRequisitionMemosActivityMessage);
      form.resetFields();
    }
  }, [
    addRequisitionMemosActivitySuccess,
    addRequisitionMemosActivityMessage,
    form,
  ]);
  return (
    <div>
      <Modal
        title="ADD REQUISITION FORM COST DRIVER"
        onCancel={closeModal}
        footer={null}
        open={visible}
      >
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          layout="horizontal"
          form={form}
          onFinish={onFinish}
        >
          {!addRequisitionMemosActivitySuccess &&
            addRequisitionMemosActitvityError && (
              <Alert
                className="m-2"
                showIcon
                type="error"
                message={addRequisitionMemosActitvityError}
              />
            )}
          <Form.Item
            label="Cost Driver"
            name={"activity"}
            fieldKey={"activity"}
            rules={[{ required: true, message: "Please select an activity" }]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              loading={approvedBudgetLoading}
              className="w-100"
              allowClear
            >
              {budgetActivities[0]?.activities?.map((item) => {
                return (
                  <Option value={item.id} key={item.id}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Units"
            name={"units"}
            fieldKey={"units"}
            hasFeedback
            rules={[{ required: true, message: "Please add Units" }]}
          >
            <InputNumber precision={0} className="w-100" placeholder="Units" />
          </Form.Item>
          <Form.Item
            label="Unit Cost"
            name={"unit_cost"}
            fieldKey={"unit_cost"}
            hasFeedback
            rules={[{ required: true, message: "Please add a Unit Cost" }]}
          >
            <InputNumber
              precision={0}
              className="w-100"
              placeholder="Unit Cost"
            />
          </Form.Item>
          <Form.Item label="Total" shouldUpdate>
            {({ getFieldValue }) => {
              const unitCost = getFieldValue("unit_cost");
              const units = getFieldValue("units");
              const total_cost = parseInt(unitCost) * parseInt(units);
              return (
                <Input
                  readOnly
                  value={
                    !isNaN(total_cost)
                      ? `${currency === "D" ? "USD" : "UGX"} ${numberWithCommas(
                          total_cost
                        )}`
                      : 0
                  }
                ></Input>
              );
            }}
          </Form.Item>
          <Form.Item name="details" label="Details">
            <Input.TextArea />
          </Form.Item>
          <Form.Item className={"mt-3"} wrapperCol={{ offset: 8, span: 16 }}>
            <Button
              loading={addRequisitionMemosactivityLoading}
              htmlType="submit"
              type="primary"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddRequsitionMemoActivity;
