import { Card, Space, Tabs } from "antd";
import React from "react";
import { useStrategicPlansStore } from "../../../../config/stores";
import AppButton from "../../../../components/common/AppButton";
import { AiOutlineArrowLeft, AiOutlineReload } from "react-icons/ai";
import AppLoader from "../../../../components/common/AppLoader";
import StrategicPlanOtherDetails from "./StrategicPlanOtherDetails";
import RecordStatus from "../../../../components/common/RecordStatus";
import ThematicAreasTable from "./thematicAreas/ThematicAreasTable";
import ThematicAreaForm from "./thematicAreas/ThematicAreaForm";
import ThematicAreaDetails from "./thematicAreas/ThematicAreaDetails";
import ActivitiesForm from "./thematicAreas/activities/ActivitiesForm";
import IndicatorsForm from "./thematicAreas/activities/IndicatorsForm";
import RecordStatusButtons from "../../../../components/common/FormStatusButtons";

const StrategicPlanDetails = () => {
  const strategicPlanStore = useStrategicPlansStore();
  const { strategicPlanDetails } = strategicPlanStore;

  const closeDetails = () => {
    strategicPlanStore.setShowViewStrategicPlanDetails(false);
  };

  const reload = () => {
    strategicPlanStore.getStrategicPlanDetails(strategicPlanDetails.id);
  };

  return (
    <Card
      title={
        !strategicPlanStore.gettingStrategicPlanDetails ? (
          <Space>
            <p className="mb-0 fw-bold">{strategicPlanDetails.title}</p>
            <RecordStatus status={strategicPlanDetails.status} />
          </Space>
        ) : (
          "Strategic Plan"
        )
      }
      type="inner"
      extra={
        <Space>
          <AppButton
            type="default"
            iconBefore={<AiOutlineReload />}
            text="Reload"
            onClick={reload}
            loading={strategicPlanStore.gettingStrategicPlanDetails}
            loadingText="Loading..."
          />
          <AppButton
            danger
            type="default"
            iconBefore={<AiOutlineArrowLeft />}
            text="View Strategic Plans"
            onClick={closeDetails}
          />
        </Space>
      }
    >
      {strategicPlanStore.gettingStrategicPlanDetails ? (
        <AppLoader message="Loading Strategic Plan" />
      ) : (
        <>
          <StrategicPlanOtherDetails />
          <Tabs
            type="card"
            tabBarStyle={{ marginBottom: 0 }}
            tabBarExtraContent={
              <RecordStatusButtons
                data={strategicPlanDetails}
                storeActions={{
                  check: strategicPlanStore.checkStrategicPlan,
                  checking: strategicPlanStore.checkingStrategicPlan,
                  approve: strategicPlanStore.approveStrategicPlan,
                  approving: strategicPlanStore.approvingStrategicPlan,
                  reject: strategicPlanStore.rejectStrategicPlan,
                  rejecting: strategicPlanStore.rejectingStrategicPlan,
                }}
              />
            }
            items={[
              {
                label: "MANAGE STRATEGIC PLAN",
                key: "manageStrategicPlan",
                children: (
                  <>
                    <ThematicAreaForm />
                    <ThematicAreaDetails />
                    <ActivitiesForm />
                    <IndicatorsForm />
                    <ThematicAreasTable
                      thematicAreas={
                        strategicPlanDetails?.strategicPlanThematicAreas
                      }
                      canEdit
                    />
                  </>
                ),
              },
              {
                label: "STRATEGIC PLAN REPORT",
                key: "strategicPlanReport",
                children: <></>,
              },
            ]}
          />
        </>
      )}
    </Card>
  );
};

export default StrategicPlanDetails;
