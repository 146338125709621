import { Button, message, Popover, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import generatePDF from "../../../../config/services/generatePdf";
import { localPurchaseOrdersActions } from "../../../../config/actions/procument/localPurchaseOrder/localPuchaseOrder.actions";
import tableButtonActions from "../../../shared/Tables/TableButtonActions";
import TableTitle from "../../../shared/Tables/TableTitle";

const LocalPurchaseOrdersTable = ({
  handleDelete,
  handleRefreshTable,
  handleExportRecords,
  handleOpenAddModal,
  handleOpenEditModal,
  handleViewDetails,
}) => {
  const [deleteId, setDeleteId] = useState("");
  const [filterTable, setfilterTable] = useState(null);
  const {
    localPurchaseOrders: data,
    localPurchaseOrdersLoading: loading,
    localPurchaseOrdersSuccess: success,
    localPurchaseOrdersError: error,
    deleteLocalPurchaseOrdersLoading: deleteLoading,
    deleteLocalPurchaseOrdersSuccess: deleteSuccess,
    deleteLocalPurchaseOrdersError: deleteError,
    deleteLocalPurchaseOrdersMessage: deleteMessage,
  } = useSelector((state) => state.localPurchaseOrdersState);
  const {
    success: logDetailsSuccess,
    error: logDetailsError,
    records,
  } = useSelector((state) => state.logDetailsState.addLogDetails);
  const dispatch = useDispatch();
  const columns = [
    {
      title: "Local Purchase Order No.",
      key: "rfqno",
      render: (text) => (
        <div>
          <span className="d-md-none">Local Purchase Order No.:</span> {text.id}{" "}
          <br />
          <div className="d-md-none">
            RFQ No.: {text.rfqno} <br />
            <Popover
              className=""
              trigger="click"
              placement="bottom"
              content={() =>
                tableButtonActions(text, {
                  viewDetails,
                  updateRecord,
                  confirmDelete,
                  deleteRecord,
                  deleteLoading,
                  deleteId,
                })
              }
            >
              Actions: <Button type="link">Actions</Button>
            </Popover>
          </div>
        </div>
      ),
    },
    {
      title: "RFQ No.",
      key: "rfqno",
      dataIndex: "rfqno",
      responsive: ["md"],
    },
    {
      title: "Action",
      key: "operation",
      responsive: ["md"],
      render: (text) => (
        <Popover
          trigger="click"
          placement="bottom"
          content={() =>
            tableButtonActions(text, {
              viewDetails,
              updateRecord,
              confirmDelete,
              deleteRecord,
              deleteLoading,
              deleteId,
            })
          }
        >
          <Button>Actions</Button>
        </Popover>
      ),
    },
  ];
  /* Functions */
  const deleteRecord = (e) => setDeleteId(e.target.id);
  const confirmDelete = () => {
    const localPurchaseOrdersArray = data.filter((e) => e.id !== deleteId);
    handleDelete(deleteId, localPurchaseOrdersArray);
  };
  const refreshTable = () => {
    handleRefreshTable();
    if (!success && error) message.error(error);
  };
  const updateRecord = (e) => {
    const localPurchaseOrdersArray = data.filter((el) => el.id === e.target.id);
    dispatch(
      localPurchaseOrdersActions.editLocalPurchaseOrdersData(
        localPurchaseOrdersArray[0]
      )
    );
    handleOpenEditModal(true);
  };
  const viewDetails = (e) => handleViewDetails(e.target.id);

  const search = (value) => {
    const filterTable = data.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(value.toLowerCase())
      )
    );
    setfilterTable(filterTable);
  };
  const setfilterTableNull = (e) => {
    if (!e.target.value) setfilterTable(null);
  };

  const showTotal = (total) => `Total: ${total}`;
  const openAddModal = () => {
    handleOpenAddModal(true);
  };
  const exportRecords = () => {
    message.loading("Processing document for export please wait ", 3);
    handleExportRecords();
  };
  const generateRecordsPdf = () => {
    const pdfColumns = ["Local Purchase Order No.", "RFQ No."];
    let pdfRows = [];
    data?.forEach((record) => {
      const row = [record.id, record.rfqno];
      pdfRows.push(row);
    });
    generatePDF(
      pdfRows,
      pdfColumns,
      `Amani Initiative Local Purchase Orders - ${new Date().toLocaleDateString()}`,
      "Amani Initiative Local Purchase Orders.pdf"
    );
  };
  /* Use effect */
  useEffect(() => {
    if (logDetailsSuccess && records === "localPurchaseOrders") {
      message.info("Started download");
      generateRecordsPdf();
    } else if (!logDetailsSuccess && logDetailsError)
      message.error(logDetailsError);
    if (deleteSuccess && deleteMessage) message.success(deleteMessage);
    else if (!deleteSuccess && deleteError) message.error(deleteError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    logDetailsSuccess,
    logDetailsError,
    deleteSuccess,
    deleteMessage,
    deleteError,
  ]);

  return (
    <div>
      <Table
        pagination={{ total: data.length, showSizeChanger: true, showTotal }}
        loading={loading}
        title={() =>
          TableTitle({
            search,
            setfilterTableNull,
            refreshTable,
            exportRecords,
            openAddModal,
          })
        }
        bordered={true}
        dataSource={filterTable === null ? data : filterTable}
        columns={columns}
      />
    </div>
  );
};

export default LocalPurchaseOrdersTable;
