export const noticeOfBestBiddersConstants = {
  SET_NOTICE_OF_BEST_BIDDER_SUCCESS: "SET_NOTICE_OF_BEST_BIDDER_SUCCESS",
  SET_NOTICE_OF_BEST_BIDDER_LOADING: "SET_NOTICE_OF_BEST_BIDDER_LOADING",
  SET_NOTICE_OF_BEST_BIDDER_ERROR: "SET_NOTICE_OF_BEST_BIDDER_ERROR",

  SET_NOTICE_OF_BEST_BIDDER_DETAILS_SUCCESS:
    "SET_NOTICE_OF_BEST_BIDDER_DETAILS_SUCCESS",
  SET_NOTICE_OF_BEST_BIDDER_DETAILS_LOADING:
    "SET_NOTICE_OF_BEST_BIDDER_DETAILS_LOADING",
  SET_NOTICE_OF_BEST_BIDDER_DETAILS_ERROR:
    "SET_NOTICE_OF_BEST_BIDDER_DETAILS_ERROR",

  SET_NOTICE_OF_BEST_BIDDER_META_DATA: "SET_NOTICE_OF_BEST_BIDDER_META_DATA",
  SET_NOTICE_OF_BEST_BIDDER_META_DATA_LOADING:
    "SET_NOTICE_OF_BEST_BIDDER_META_DATA_LOADING",

  ADD_NOTICE_OF_BEST_BIDDER_LOADING: "ADD_NOTICE_OF_BEST_BIDDER_LOADING",
  ADD_NOTICE_OF_BEST_BIDDER_ERROR: "ADD_NOTICE_OF_BEST_BIDDER_ERROR",
  ADD_NOTICE_OF_BEST_BIDDER_SUCCESS: "ADD_NOTICE_OF_BEST_BIDDER_SUCCESS",
  ADD_NOTICE_OF_BEST_BIDDER_COMPLETE: "ADD_NOTICE_OF_BEST_BIDDER_COMPLETE",

  EDIT_NOTICE_OF_BEST_BIDDER_REQUEST: "EDIT_NOTICE_OF_BEST_BIDDER_REQUEST",
  EDIT_NOTICE_OF_BEST_BIDDER_SUCCESS: "EDIT_NOTICE_OF_BEST_BIDDER_SUCCESS",
  EDIT_NOTICE_OF_BEST_BIDDER_ERROR: "EDIT_NOTICE_OF_BEST_BIDDER_ERROR",
  EDIT_NOTICE_OF_BEST_BIDDER_COMPLETE: "EDIT_NOTICE_OF_BEST_BIDDER_COMPLETE",
  EDIT_NOTICE_OF_BEST_BIDDER_DATA: "EDIT_NOTICE_OF_BEST_BIDDER_DATA",

  DELETE_NOTICE_OF_BEST_BIDDER_REQUEST: "DELETE_NOTICE_OF_BEST_BIDDER_REQUEST",
  DELETE_NOTICE_OF_BEST_BIDDER_SUCCESS: "DELETE_NOTICE_OF_BEST_BIDDER_SUCCESS",
  DELETE_NOTICE_OF_BEST_BIDDER_ERROR: "DELETE_NOTICE_OF_BEST_BIDDER_ERROR",
  DELETE_NOTICE_OF_BEST_BIDDER_COMPLETE:
    "DELETE_NOTICE_OF_BEST_BIDDER_COMPLETE",

  APPROVE_NOTICE_OF_BEST_BIDDER_SUCCESS:
    "APPROVE_NOTICE_OF_BEST_BIDDER_SUCCESS",
  APPROVE_NOTICE_OF_BEST_BIDDER_REQUEST:
    "APPROVE_NOTICE_OF_BEST_BIDDER_REQUEST",
  APPROVE_NOTICE_OF_BEST_BIDDER_ERROR: "APPROVE_NOTICE_OF_BEST_BIDDER_ERROR",
  APPROVE_NOTICE_OF_BEST_BIDDER_COMPLETE:
    "APPROVE_NOTICE_OF_BEST_BIDDER_COMPLETE",

  CHECK_NOTICE_OF_BEST_BIDDER_REQUEST: "CHECK_NOTICE_OF_BEST_BIDDER_REQUEST",
  CHECK_NOTICE_OF_BEST_BIDDER_SUCCESS: "CHECK_NOTICE_OF_BEST_BIDDER_SUCCESS",
  CHECK_NOTICE_OF_BEST_BIDDER_ERROR: "CHECK_NOTICE_OF_BEST_BIDDER_ERROR",
  CHECK_NOTICE_OF_BEST_BIDDER_COMPLETE: "CHECK_NOTICE_OF_BEST_BIDDER_COMPLETE",
};

export const noticeOfBestBiddersActions = {
  setNoticeOfBestBiddersLoading: () => ({
    type: noticeOfBestBiddersConstants.SET_NOTICE_OF_BEST_BIDDER_LOADING,
  }),
  setNoticeOfBestBiddersSuccess: (payload) => ({
    type: noticeOfBestBiddersConstants.SET_NOTICE_OF_BEST_BIDDER_SUCCESS,
    payload,
  }),
  setNoticeOfBestBiddersError: (payload) => ({
    type: noticeOfBestBiddersConstants.SET_NOTICE_OF_BEST_BIDDER_ERROR,
    payload,
  }),
  setNoticeOfBestBiddersMetaDataLoading: () => ({
    type: noticeOfBestBiddersConstants.SET_NOTICE_OF_BEST_BIDDER_META_DATA_LOADING,
  }),
  setNoticeOfBestBiddersMetaData: (payload) => ({
    type: noticeOfBestBiddersConstants.SET_NOTICE_OF_BEST_BIDDER_META_DATA,
    payload,
  }),
  addNoticeOfBestBiddersRequest: () => ({
    type: noticeOfBestBiddersConstants.ADD_NOTICE_OF_BEST_BIDDER_LOADING,
  }),
  addNoticeOfBestBiddersSuccess: (payload) => ({
    type: noticeOfBestBiddersConstants.ADD_NOTICE_OF_BEST_BIDDER_SUCCESS,
    payload,
  }),
  addNoticeOfBestBiddersError: (payload) => ({
    type: noticeOfBestBiddersConstants.ADD_NOTICE_OF_BEST_BIDDER_ERROR,
    payload,
  }),
  addNoticeOfBestBiddersComplete: () => ({
    type: noticeOfBestBiddersConstants.ADD_NOTICE_OF_BEST_BIDDER_COMPLETE,
  }),
  setNoticeOfBestBiddersDetailsRequest: () => ({
    type: noticeOfBestBiddersConstants.SET_NOTICE_OF_BEST_BIDDER_DETAILS_LOADING,
  }),
  setNoticeOfBestBiddersDetailsSuccess: (payload) => ({
    type: noticeOfBestBiddersConstants.SET_NOTICE_OF_BEST_BIDDER_DETAILS_SUCCESS,
    payload,
  }),
  setNoticeOfBestBiddersDetailsError: (payload) => ({
    type: noticeOfBestBiddersConstants.SET_NOTICE_OF_BEST_BIDDER_DETAILS_ERROR,
    payload,
  }),

  editNoticeOfBestBiddersRequest: () => ({
    type: noticeOfBestBiddersConstants.EDIT_NOTICE_OF_BEST_BIDDER_REQUEST,
  }),
  editNoticeOfBestBiddersSuccess: (payload) => ({
    type: noticeOfBestBiddersConstants.EDIT_NOTICE_OF_BEST_BIDDER_SUCCESS,
    payload,
  }),
  editNoticeOfBestBiddersError: (payload) => ({
    type: noticeOfBestBiddersConstants.EDIT_NOTICE_OF_BEST_BIDDER_ERROR,
    payload,
  }),
  editNoticeOfBestBiddersComplete: () => ({
    type: noticeOfBestBiddersConstants.EDIT_NOTICE_OF_BEST_BIDDER_COMPLETE,
  }),
  editNoticeOfBestBiddersData: (payload) => ({
    type: noticeOfBestBiddersConstants.EDIT_NOTICE_OF_BEST_BIDDER_DATA,
    payload,
  }),
  deleteNoticeOfBestBiddersRequest: () => ({
    type: noticeOfBestBiddersConstants.DELETE_NOTICE_OF_BEST_BIDDER_REQUEST,
  }),
  deleteNoticeOfBestBiddersSuccess: (payload) => ({
    type: noticeOfBestBiddersConstants.DELETE_NOTICE_OF_BEST_BIDDER_SUCCESS,
    payload,
  }),
  deleteNoticeOfBestBiddersError: (payload) => ({
    type: noticeOfBestBiddersConstants.DELETE_NOTICE_OF_BEST_BIDDER_ERROR,
    payload,
  }),
  deleteNoticeOfBestBiddersComplete: () => ({
    type: noticeOfBestBiddersConstants.DELETE_NOTICE_OF_BEST_BIDDER_COMPLETE,
  }),
  checkNoticeOfBestBiddersRequest: () => ({
    type: noticeOfBestBiddersConstants.CHECK_NOTICE_OF_BEST_BIDDER_REQUEST,
  }),
  checkNoticeOfBestBiddersSuccess: (payload) => ({
    type: noticeOfBestBiddersConstants.CHECK_NOTICE_OF_BEST_BIDDER_SUCCESS,
    payload,
  }),
  checkNoticeOfBestBiddersError: (payload) => ({
    type: noticeOfBestBiddersConstants.CHECK_NOTICE_OF_BEST_BIDDER_ERROR,
    payload,
  }),
  checkNoticeOfBestBiddersComplete: () => ({
    type: noticeOfBestBiddersConstants.CHECK_NOTICE_OF_BEST_BIDDER_COMPLETE,
  }),
  approveNoticeOfBestBiddersRequest: () => ({
    type: noticeOfBestBiddersConstants.APPROVE_NOTICE_OF_BEST_BIDDER_REQUEST,
  }),
  approveNoticeOfBestBiddersSuccess: (payload) => ({
    type: noticeOfBestBiddersConstants.APPROVE_NOTICE_OF_BEST_BIDDER_SUCCESS,
    payload,
  }),
  approveNoticeOfBestBiddersError: (payload) => ({
    type: noticeOfBestBiddersConstants.APPROVE_NOTICE_OF_BEST_BIDDER_ERROR,
    payload,
  }),
  approveNoticeOfBestBiddersComplete: () => ({
    type: noticeOfBestBiddersConstants.APPROVE_NOTICE_OF_BEST_BIDDER_COMPLETE,
  }),
};
