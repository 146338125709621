import { Tag } from "antd";
import React from "react";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import appConfig from "../../../../config/config.json";

const DonorsInfoDetails = ({ data }) => {
  const getCurrency = (data) => {
    try {
      return data?.budget[data?.budget?.length - 1];
    } catch (error) {
      console.error(error);
      return "";
    }
  };
  const currency = getCurrency(data);
  return (
    <div>
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th scope="col">Column</th>
            <th scope="col">Data</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Name</td>
            <td>{data.name}</td>
          </tr>
          <tr>
            <td>Status</td>
            <td>
              {data.status === appConfig.status.approved && (
                <Tag icon={<CheckCircleOutlined />} color="success">
                  {data.status}
                </Tag>
              )}
              {data.status === appConfig.status.checked && (
                <Tag icon={<MinusCircleOutlined />} color="processing">
                  {data.status}
                </Tag>
              )}
              {data.status === appConfig.status.created && (
                <Tag icon={<ExclamationCircleOutlined />} color="warning">
                  Pending
                </Tag>
              )}
            </td>
          </tr>
          <tr>
            <td>Proposal Status</td>
            <td>
              {data.proposal_status === appConfig.status.approved && (
                <Tag icon={<CheckCircleOutlined />} color="success">
                  {data.proposal_status}
                </Tag>
              )}
              {data.proposal_status === appConfig.status.rejected && (
                <Tag icon={<MinusCircleOutlined />} color="error">
                  {data.proposal_status}
                </Tag>
              )}
              {data.proposal_status === appConfig.status.submitted && (
                <Tag icon={<ExclamationCircleOutlined />} color="processing">
                  {data.proposal_status}
                </Tag>
              )}
              {!data.proposal_status && (
                <Tag icon={<ExclamationCircleOutlined />} color="warning">
                  {"Pending"}
                </Tag>
              )}
            </td>
          </tr>
          <tr>
            <td>Person Incharge Of Proposal Process</td>
            <td>
              {data.personInchargeName &&
                `${data?.personInchargeName?.first_name} ${data?.personInchargeName?.other_names}`}
            </td>
          </tr>
          <tr>
            <td>Focus</td>
            <td>{data.focus}</td>
          </tr>
          <tr>
            <td>Thematic Area</td>
            <td>{data.thematic_area}</td>
          </tr>
          <tr>
            <td>Budget (Upper limit)</td>
            <td>{`${currency === "D" ? "USD" : "UGX"} ${parseInt(
              data.budget
            )}`}</td>
          </tr>
          <tr>
            <td>Eligibility status/0-10</td>
            <td>{data.eligibility_status}</td>
          </tr>
          <tr>
            <td>Deadline Of Submission</td>
            <td>{new Date(data.deadline_of_submission).toDateString()}</td>
          </tr>
          <tr>
            <td>Link To Application Documents</td>
            <td>{data.link}</td>
          </tr>
          <tr>
            <td>Created By</td>
            <td>{`${data?.creator?.first_name} ${data?.creator?.other_names}`}</td>
          </tr>
          <tr>
            <td>Created At</td>
            <td>
              {`${new Date(data.created_at).toDateString()} ${new Date(
                data.created_at
              ).toLocaleTimeString()}
                        `}
            </td>
          </tr>
          <tr>
            <td>Last Updated By</td>
            <td>
              {data.updator &&
                `${data?.updator?.first_name} ${data?.updator?.other_names}`}
            </td>
          </tr>
          <tr>
            <td>Last Updated At</td>
            <td>
              {data.updated_at &&
                `${new Date(data.updated_at).toDateString()} ${new Date(
                  data.updated_at
                ).toLocaleTimeString()}`}
            </td>
          </tr>
          <tr>
            <td>Checked By</td>
            <td>
              {data.checker &&
                `${data?.checker?.first_name} ${data?.checker?.other_names}`}
            </td>
          </tr>
          <tr>
            <td>Checked At</td>
            <td>
              {data.checked_at &&
                `${new Date(data.checked_at).toDateString()} ${new Date(
                  data.checked_at
                ).toLocaleTimeString()}
                        `}
            </td>
          </tr>
          <tr>
            <td>Approved By</td>
            <td>
              {data.approver &&
                `${data?.approver?.first_name} ${data?.approver?.other_names}`}
            </td>
          </tr>
          <tr>
            <td>Approved At</td>
            <td>
              {data.approved_at &&
                `${new Date(data.approved_at).toDateString()} ${new Date(
                  data.approved_at
                ).toLocaleTimeString()}`}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default DonorsInfoDetails;
