import React, { useEffect } from "react";
import TimeSheetsTable from "./MangeMyTimeSheets/TimeSheetsTable";
import { useTimeSheetStore } from "../../../config/stores";
import { isEmpty } from "lodash";
import TimeSheetsForm from "./MangeMyTimeSheets/TimeSheetsForm";
import TimeSheetDetails from "./MangeMyTimeSheets/TimeSheetDetails";

const MyTimeSheets = () => {
  const timeSheetsStore = useTimeSheetStore();
  useEffect(() => {
    if (isEmpty(timeSheetsStore.myTimeSheets)) {
      timeSheetsStore.getMyTimeSheets();
    }
  }, []);

  return (
    <div className="container-fluid p-2">
      <h5 className="fw-bold">MY TIME SHEETS</h5>
      {timeSheetsStore.showTimeSheetDetails ? (
        <TimeSheetDetails />
      ) : (
        <TimeSheetsTable
          timeSheets={timeSheetsStore.myTimeSheets?.data}
          canEdit
        />
      )}

      <TimeSheetsForm />
    </div>
  );
};

export default MyTimeSheets;
