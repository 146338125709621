export const permissionsConstants = {
  SET_PERMISSIONS_REQUEST: "SET_PERMISSIONS_REQUEST",
  SET_PERMISSIONS_SUCCESS: "SET_PERMISSIONS_SUCCESS",
  SET_PERMISSIONS_ERROR: "SET_PERMISSIONS_ERROR",

  SET_PERMISSION_REQUEST: "SET_PERMISSION_REQUEST",
  SET_PERMISSION_SUCCESS: "SET_PERMISSION_SUCCESS",
  SET_PERMISSION_ERROR: "SET_PERMISSION_ERROR",

  CREATE_PERMISIONS_SUCCESS: "CREATE_PERMISIONS_SUCCESS",
  CREATE_PERMISIONS_ERROR: "CREATE_PERMISIONS_ERROR",
  CREATE_PERMISIONS_REQUEST: "CREATE_PERMISIONS_REQUEST",
  CREATE_PERMISIONS_COMPLETED: "CREATE_PERMISIONS_COMPLETED",

  DELETE_PERMISSION_REQUEST: "DELETE_PERMISSION_REQUEST",
  DELETE_PERMISSION_ERROR: "DELETE_PERMISSION_ERROR",
  DELETE_PERMISSION_SUCCESS: "DELETE_PERMISSION_SUCCESS",
  DELETE_PERMISSION_COMPLETED: "DELETE_PERMISSION_COMPLETED",

  SET_UPDATE_PERMISSIONS_DATA: "SET_UPDATE_PERMISSIONS_DATA",
  UPDATE_PERMISSIONS_REQUEST: "UPDATE_PERMISSIONS_REQUEST",
  UPDATE_PERMISSIONS_SUCCESS: "UPDATE_PERMISSIONS_SUCCESS",
  UPDATE_PERMISSIONS_ERROR: "UPDATE_PERMISSIONS_ERROR",
  UPDATE_PERMISSIONS_COMPLETED: "UPDATE_PERMISSIONS_COMPLETED",

  SET_ROLES_REQUEST: "SET_ROLES_REQUEST",
  SET_ROLES_SUCCESS: "SET_ROLES_SUCCESS",
  SET_ROLES_ERROR: "SET_ROLES_ERROR",
};

export const permissionsActions = {
  setRolesRequest: () => ({
    type: permissionsConstants.SET_ROLES_REQUEST,
  }),
  setRolesSuccess: (payload) => ({
    type: permissionsConstants.SET_ROLES_SUCCESS,
    payload,
  }),
  setRolesError: (payload) => ({
    payload,
    type: permissionsConstants.SET_ROLES_ERROR,
  }),
  setPermissionsRequest: () => ({
    type: permissionsConstants.SET_PERMISSIONS_REQUEST,
  }),
  setPermissionsSuccess: (payload) => ({
    type: permissionsConstants.SET_PERMISSIONS_SUCCESS,
    payload,
  }),
  setPermissionsError: (payload) => ({
    payload,
    type: permissionsConstants.SET_PERMISSIONS_ERROR,
  }),
  createPermissionsRequest: () => ({
    type: permissionsConstants.CREATE_PERMISIONS_REQUEST,
  }),
  createPermissionsSuccess: (payload) => ({
    type: permissionsConstants.CREATE_PERMISIONS_SUCCESS,
    payload,
  }),
  createPermissionsError: (payload) => ({
    type: permissionsConstants.CREATE_PERMISIONS_ERROR,
    payload,
  }),
  createPermissionsCompleted: () => ({
    type: permissionsConstants.CREATE_PERMISIONS_COMPLETED,
  }),
  deletePermissionsRequest: () => ({
    type: permissionsConstants.DELETE_PERMISSION_REQUEST,
  }),
  deletePermissionsSuccess: (payload) => ({
    type: permissionsConstants.DELETE_PERMISSION_SUCCESS,
    payload,
  }),
  deletePermissionsError: (payload) => ({
    type: permissionsConstants.DELETE_PERMISSION_ERROR,
    payload,
  }),
  deletePermissionsCompleted: () => ({
    type: permissionsConstants.DELETE_PERMISSION_COMPLETED,
  }),
  updatePermissionsRequest: () => ({
    type: permissionsConstants.UPDATE_PERMISSIONS_REQUEST,
  }),
  updatePermissionsSuccess: (payload) => ({
    type: permissionsConstants.UPDATE_PERMISSIONS_SUCCESS,
    payload,
  }),
  updatePermissionsError: (payload) => ({
    type: permissionsConstants.UPDATE_PERMISSIONS_ERROR,
    payload,
  }),
  updatePermissionsCompleted: () => ({
    type: permissionsConstants.UPDATE_PERMISSIONS_COMPLETED,
  }),
  setUpdatePermissionsData: (payload) => ({
    type: permissionsConstants.SET_UPDATE_PERMISSIONS_DATA,
    payload,
  }),
  setPermissionRequest: () => ({
    type: permissionsConstants.SET_PERMISSION_REQUEST,
  }),
  setPermissionSuccess: (payload) => ({
    type: permissionsConstants.SET_PERMISSION_SUCCESS,
    payload,
  }),
  setPermissionError: (payload) => ({
    type: permissionsConstants.SET_PERMISSION_ERROR,
    payload,
  }),
};
