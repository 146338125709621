import { Modal, Form, Button, Alert, message, InputNumber } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { advanceAccountabilityFormActions } from "../../../../config/actions/finance/advanceAccountabilityForm/advanceAccountabilityForm.actions";

const EditAdvanceAccountabilityFormExpenditure = ({
  visible,
  handleCloseModal,
  handleAddRecord,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const {
    editAdvanceAccountabilityFormsExpenditureLoading,
    editAdvanceAccountabilityFormsExpenditureSuccess,
    editAdvanceAccountabilityFormsExpenditureError,
    editAdvanceAccountabilityFormsExpenditureMessage,
    advanceAccountabilityFormsDetails,
    editAdvanceAccountabilityFormsExpenditureFormData: data,
  } = useSelector((state) => state.advanceAccountabilityFormsState);
  const fields = [
    { name: "actual_expenditure", value: data.actual_expenditure },
  ];
  const onFinish = (values) => {
    values.updated_at = new Date();
    values.advance_accountability_form = advanceAccountabilityFormsDetails.id;
    values.id = data.id;
    values.old_actual_expenditure = data.actual_expenditure;
    values.item = data.item;
    dispatch(
      advanceAccountabilityFormActions.editAdvanceAccountabilityFormsExpenditureData(
        values
      )
    );
    handleAddRecord(values);
  };

  useEffect(() => {
    if (
      editAdvanceAccountabilityFormsExpenditureSuccess &&
      editAdvanceAccountabilityFormsExpenditureMessage
    ) {
      message.success(editAdvanceAccountabilityFormsExpenditureMessage);
      handleCloseModal(false);
    }
  }, [
    editAdvanceAccountabilityFormsExpenditureMessage,
    editAdvanceAccountabilityFormsExpenditureSuccess,
    handleCloseModal,
  ]);
  return (
    <div>
      <Modal
        onCancel={() => handleCloseModal(false)}
        open={visible}
        footer={null}
        title={`EDIT ${data.item}`.toUpperCase()}
      >
        <Form fields={fields} form={form} layout="vertical" onFinish={onFinish}>
          {!editAdvanceAccountabilityFormsExpenditureSuccess &&
            editAdvanceAccountabilityFormsExpenditureError && (
              <Alert
                type="error"
                showIcon
                message={editAdvanceAccountabilityFormsExpenditureError}
              />
            )}
          <Form.Item
            label="Actual Expenditure"
            name="actual_expenditure"
            rules={[{ required: true, message: "Please add details" }]}
          >
            <InputNumber className="w-100" />
          </Form.Item>
          <Form.Item>
            <Button
              loading={editAdvanceAccountabilityFormsExpenditureLoading}
              htmlType="submit"
              type="primary"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default EditAdvanceAccountabilityFormExpenditure;
