import { requisitionMemosActions } from "../../../actions/finance/requisitionMemo/requsitionMemo.actions";
import apiRequests from "../../../api/api";

const requisitionMemoThunks = {
  getRequisitionMemos: () => async (dispatch) => {
    dispatch(requisitionMemosActions.setRequisitionMemosLoading());
    const res = await apiRequests.getRequest("/finance/requisitionMemos/");
    if (res.success)
      dispatch(requisitionMemosActions.setRequisitionMemosSuccess(res.data));
    else dispatch(requisitionMemosActions.setRequisitionMemosError(res.error));
  },
  getRequisitionMemosDetails: (id) => async (dispatch) => {
    dispatch(requisitionMemosActions.setRequisitionMemosDetailsLoading());
    const res = await apiRequests.getRequest(`/finance/requisitionMemos/${id}`);
    if (res.success)
      dispatch(
        requisitionMemosActions.setRequisitionMemosDetailsSucess(res.data)
      );
    else
      dispatch(
        requisitionMemosActions.setRequisitionMemosDetailsError(res.error)
      );
  },
  addRequisitionMemos: (body, requistionMemos) => async (dispatch) => {
    dispatch(requisitionMemosActions.addRequisitionMemosLoading());
    const res = await apiRequests.postRequest(
      "/finance/requisitionMemos/",
      body
    );
    if (res.success) {
      dispatch(
        requisitionMemosActions.addRequisitionMemosSuccess({
          message: res.message,
          data: [res.data, ...requistionMemos],
        })
      );
      dispatch(requisitionMemoThunks.getProjectRequisitionMemos(body.project));
    } else
      dispatch(requisitionMemosActions.addRequisitionMemosError(res.error));
    dispatch(requisitionMemosActions.addRequisitionMemosComplete());
  },
  editRequisitionMemos: (body, requisitionMemos) => async (dispatch) => {
    dispatch(requisitionMemosActions.editRequisitionMemosLoading());
    const res = await apiRequests.patchRequest(
      `/finance/requisitionMemos/${body.id}`,
      body
    );
    if (res.success) {
      dispatch(
        requisitionMemosActions.editRequisitionMemosSuccess({
          message: res.message,
          data: [res.data, ...requisitionMemos],
        })
      );
      dispatch(requisitionMemoThunks.getProjectRequisitionMemos(body.project));
    } else
      dispatch(requisitionMemosActions.editRequisitionMemosError(res.error));
    dispatch(requisitionMemosActions.editRequisitionMemosComplete());
  },
  deleteRequisitionMemos: (id, requisitionMemos) => async (dispatch) => {
    dispatch(requisitionMemosActions.deleteRequisitionMemosLoading());
    const res = await apiRequests.deleteRequest(
      `/finance/requisitionMemos/${id}`
    );
    if (res.success)
      dispatch(
        requisitionMemosActions.deleteRequisitionMemosSuccess({
          message: res.message,
          data: requisitionMemos,
        })
      );
    else
      dispatch(requisitionMemosActions.deleteRequisitionMemosError(res.error));
    dispatch(requisitionMemosActions.deleteRequisitionMemosComplete());
  },
  checkRequisitionMemos: (id) => async (dispatch) => {
    dispatch(requisitionMemosActions.checkRequisitionMemosLoading());
    const res = await apiRequests.patchRequest(
      `/finance/requisitionMemos/check/${id}`,
      { checkedAt: new Date() }
    );
    if (res.success) {
      dispatch(
        requisitionMemosActions.checkRequisitionMemosSuccess(res.message)
      );
      dispatch(requisitionMemoThunks.getRequisitionMemosDetails(id));
    } else
      dispatch(requisitionMemosActions.checkRequisitionMemosError(res.error));
    dispatch(requisitionMemosActions.checkRequisitionMemosComplete());
  },
  submitRequisitionMemo: (id) => async (dispatch) => {
    dispatch(requisitionMemosActions.submitRequisitionMemosLoading());
    const res = await apiRequests.patchRequest(
      `/finance/requisitionMemos/submit/${id}`
    );
    if (res.success) {
      dispatch(
        requisitionMemosActions.submitRequisitionMemosSuccess(res.message)
      );
      dispatch(requisitionMemoThunks.getRequisitionMemosDetails(id));
    } else
      dispatch(requisitionMemosActions.submitRequisitionMemosError(res.error));
    dispatch(requisitionMemosActions.submitRequisitionMemosComplete());
  },
  approveRequisitionMemos: (id) => async (dispatch) => {
    dispatch(requisitionMemosActions.approveRequisitionMemosLoading());
    const res = await apiRequests.patchRequest(
      `/finance/requisitionMemos/approve/${id}`,
      { approvedAt: new Date() }
    );
    if (res.success) {
      dispatch(
        requisitionMemosActions.approveRequisitionMemosSuccess(res.message)
      );
      dispatch(requisitionMemoThunks.getRequisitionMemosDetails(id));
    } else
      dispatch(requisitionMemosActions.approveRequisitionMemosError(res.error));
    dispatch(requisitionMemosActions.approveRequisitionMemosComplete());
  },
  requisitionMemosMetaData: () => async (dispatch) => {
    dispatch(requisitionMemosActions.metaDataLoading());
    const res = await apiRequests.getRequest(
      "/finance/requisitionMemos/approve/metaData"
    );
    dispatch(requisitionMemosActions.metaData(res.data || {}));
  },
  getProjectRequisitionMemos: (id) => async (dispatch) => {
    dispatch(requisitionMemosActions.setProjectRequisitionMemosLoading());
    const res = await apiRequests.getRequest(
      `/finance/requisitionMemos/${id}/projectRequisitionMemos`
    );
    if (res.success)
      dispatch(
        requisitionMemosActions.setProjectRequisitionMemosSuccess(res.data)
      );
    else
      dispatch(
        requisitionMemosActions.setProjectRequisitionMemosError(res.error)
      );
  },
  addRequisitionMemoActivity: (values) => async (dispatch) => {
    dispatch(requisitionMemosActions.addRequisitionMemosActivityLoading());
    const res = await apiRequests.postRequest(
      "/finance/requisitionMemos/activities",
      values
    );
    if (res.success) {
      dispatch(
        requisitionMemosActions.addRequisitionMemosActivitySuccess(res.message)
      );
      dispatch(
        requisitionMemoThunks.getRequisitionMemosDetails(
          values.requisition_memo
        )
      );
    } else
      dispatch(
        requisitionMemosActions.addRequisitionMemosActivityError(res.error)
      );
    dispatch(requisitionMemosActions.addRequisitionMemosActivityComplete());
  },
  editRequisitionMemoActivity: (values) => async (dispatch) => {
    dispatch(requisitionMemosActions.editRequisitionMemosAcitvityLoading());
    const res = await apiRequests.putRequest(
      `/finance/requisitionMemos/activities/${values.id}`,
      values
    );
    if (res.success) {
      dispatch(
        requisitionMemosActions.editRequisitionMemosAcitvitySuccess(res.message)
      );
      dispatch(
        requisitionMemoThunks.getRequisitionMemosDetails(
          values.requisition_memo
        )
      );
    } else
      dispatch(
        requisitionMemosActions.editRequisitionMemosAcitvityError(res.error)
      );
    dispatch(requisitionMemosActions.editRequisitionMemosAcitvityComplete());
  },
  deleteRequisitionMemoActivity: (id, requisitioMemo) => async (dispatch) => {
    dispatch(requisitionMemosActions.deleteRequisitionMemosActivityLoading());
    const res = await apiRequests.deleteRequest(
      `/finance/requisitionMemos/activities/${requisitioMemo}/${id}`
    );
    if (res.success) {
      dispatch(
        requisitionMemosActions.deleteRequisitionMemosActivitySuccess(
          res.message
        )
      );
      dispatch(
        requisitionMemoThunks.getRequisitionMemosDetails(requisitioMemo)
      );
    } else
      dispatch(
        requisitionMemosActions.deleteRequisitionMemosActivityError(res.error)
      );
    dispatch(requisitionMemosActions.deleteRequisitionMemosActivityComplete());
  },
  getRequisitionMemoActivityDetails: (id) => async (dispatch) => {
    dispatch(
      requisitionMemosActions.setRequisitionMemosActivityDetailsLoading()
    );
    const res = await apiRequests.getRequest(
      `/finance/requisitionMemos/activities/${id}`
    );
    if (res.success)
      dispatch(
        requisitionMemosActions.setRequisitionMemosActivityDetailsSucess(
          res.data
        )
      );
    else
      dispatch(
        requisitionMemosActions.setRequisitionMemosActivityDetailsError(
          res.error
        )
      );
  },
};

export default requisitionMemoThunks;
