import { message } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ManualsAndPoliciesDetails from "../../../components/administration/ManualsAndPolicies/Details/ManualsAndPoliciesDetails";
import AddManualsAndPolicies from "../../../components/administration/ManualsAndPolicies/Forms/AddManualsAndPoliciesForm";
import EditManualsAndPolicies from "../../../components/administration/ManualsAndPolicies/Forms/EditManualsAndPoliciesForm";
import ManualsAndPoliciesTable from "../../../components/administration/ManualsAndPolicies/Tables/ManualsAndPoliciesTable";
import AddRejections from "../../../components/shared/Rejections/AddRejections";
import { appUiActions } from "../../../config/actions/app/appUi.actions";
import routes from "../../../config/routes/routes";
import manualsAndPoliciesThunks from "../../../config/thunks/administration/manualsAndPolicies/manualsAndPolicies.thunks";
import logDetailThunks from "../../../config/thunks/settings/logDetails.thunks";

const ManualsAndPolicies = () => {
  const {
    error: getManualsAndPoliciesError,
    success: getManualsAndPoliciesSuccess,
    data: manualsAndPoliciesArray,
  } = useSelector((state) => state.manualsAndPoliciesState.manualsAndPolicies);
  const {
    success: deleteManualsAndPoliciesSuccess,
    error: deleteManualsAndPoliciesError,
    message: deleteManualsAndPoliciesMessage,
  } = useSelector(
    (state) => state.manualsAndPoliciesState.deleteManualsAndPolicies
  );
  const {
    success: downloadManualsAndPoliciesSuccess,
    error: downloadManualsAndPoliciesError,
    message: downloadManualsAndPoliciesMessage,
  } = useSelector(
    (state) => state.manualsAndPoliciesState.downloadManualsAndPolicies
  );
  const {
    success: approveManualsAndPoliciesSuccess,
    error: approveManualsAndPoliciesError,
    message: approveManualsAndPoliciesMessage,
  } = useSelector(
    (state) => state.manualsAndPoliciesState.approveManualsAndPolicies
  );
  const {
    success: checkManualsAndPoliciesSuccess,
    error: checkManualsAndPoliciesError,
    message: checkManualsAndPoliciesMessage,
  } = useSelector(
    (state) => state.manualsAndPoliciesState.checkManualsAndPolicies
  );
  const {
    message: addManualsAndPoliciesMessage,
    success: addManualsAndPoliciesSuccess,
  } = useSelector(
    (state) => state.manualsAndPoliciesState.addManualsAndPolicies
  );
  const {
    message: editManualsAndPolciesMessage,
    success: editManualsAndPolciesSuccess,
  } = useSelector(
    (state) => state.manualsAndPoliciesState.updateManualsAndPolicies
  );
  const exportRecords = () => {
    dispatch(
      logDetailThunks.addLogDetails({
        created_at: new Date(),
        activity: "Exported Staff Manuals And Policies Records",
        records: "manualsAndPolicies",
      })
    );
  };

  const dispatch = useDispatch();

  /** functions */
  const refreshTable = () => {
    dispatch(manualsAndPoliciesThunks.getManualsAndPolicies());
    if (!getManualsAndPoliciesSuccess && getManualsAndPoliciesError)
      message.error(getManualsAndPoliciesError);
  };
  const deleteManualsAndPolicies = (id) => {
    const manualsAndPolicies = manualsAndPoliciesArray.filter(
      (e) => e.id !== id
    );
    dispatch(
      manualsAndPoliciesThunks.deleteManualsAndPolicies(id, manualsAndPolicies)
    );
  };
  const addManualAndPolicies = (values) => {
    dispatch(manualsAndPoliciesThunks.addManualsAndPolicies(values));
  };
  const editManualAndPolices = (values) => {
    const manualsAndPolicies = manualsAndPoliciesArray.filter(
      (e) => e.id !== values.id
    );
    dispatch(
      manualsAndPoliciesThunks.updateManualsAndPolicies(
        values,
        manualsAndPolicies
      )
    );
  };
  const approveManualsAndPolicies = (id) =>
    dispatch(manualsAndPoliciesThunks.approveManualsAndPolicies(id));
  const checkManualsAndPolicies = (id) =>
    dispatch(manualsAndPoliciesThunks.checkManualsAndPolicies(id));
  const viewDetails = (id) => {
    dispatch(appUiActions.toggleManualsAndPoliciesDetailsModal(true));
    dispatch(manualsAndPoliciesThunks.getManualsAndPoliciesDetails(id));
  };
  useEffect(() => {
    document.title = routes.administration.manualsAndPolicies.title;
    //get manuals and policies
    if (!getManualsAndPoliciesSuccess)
      dispatch(manualsAndPoliciesThunks.getManualsAndPolicies());
    //delete manuals and policies
    if (deleteManualsAndPoliciesSuccess)
      message.success(deleteManualsAndPoliciesMessage);
    else if (!deleteManualsAndPoliciesSuccess && deleteManualsAndPoliciesError)
      message.error(deleteManualsAndPoliciesError);
    //add manuals and policies
    if (addManualsAndPoliciesSuccess && addManualsAndPoliciesMessage)
      message.success(addManualsAndPoliciesMessage);
    //edit manuals and policies
    if (editManualsAndPolciesSuccess && editManualsAndPolciesMessage)
      message.success(editManualsAndPolciesMessage);
    //check
    if (checkManualsAndPoliciesSuccess)
      message.success(checkManualsAndPoliciesMessage);
    else if (!checkManualsAndPoliciesSuccess && checkManualsAndPoliciesError)
      message.error(checkManualsAndPoliciesError);
    //approve
    if (approveManualsAndPoliciesSuccess)
      message.success(approveManualsAndPoliciesMessage);
    else if (
      !approveManualsAndPoliciesSuccess &&
      approveManualsAndPoliciesError
    )
      message.error(approveManualsAndPoliciesError);
    //delete manuals and policies
    if (downloadManualsAndPoliciesSuccess && downloadManualsAndPoliciesMessage)
      message.info(downloadManualsAndPoliciesMessage);
    else if (
      !downloadManualsAndPoliciesSuccess &&
      downloadManualsAndPoliciesError
    )
      message.error(downloadManualsAndPoliciesError);
  }, [
    getManualsAndPoliciesSuccess,
    dispatch,
    deleteManualsAndPoliciesSuccess,
    deleteManualsAndPoliciesMessage,
    deleteManualsAndPoliciesError,
    addManualsAndPoliciesSuccess,
    addManualsAndPoliciesMessage,
    editManualsAndPolciesSuccess,
    editManualsAndPolciesMessage,
    checkManualsAndPoliciesSuccess,
    checkManualsAndPoliciesMessage,
    checkManualsAndPoliciesError,
    approveManualsAndPoliciesSuccess,
    approveManualsAndPoliciesMessage,
    approveManualsAndPoliciesError,
    downloadManualsAndPoliciesSuccess,
    downloadManualsAndPoliciesMessage,
    downloadManualsAndPoliciesError,
  ]);

  return (
    <div className="container-fluid p-2">
      <h3>Manuals And Policies</h3>
      <ManualsAndPoliciesTable
        handleManualsAndPoliciesRecords={deleteManualsAndPolicies}
        handleRefreshTable={refreshTable}
        handleViewDetails={viewDetails}
        handleExportRecords={exportRecords}
      />
      <AddRejections />
      <AddManualsAndPolicies
        handleAddManualsAndPolicies={addManualAndPolicies}
      />
      <EditManualsAndPolicies
        handleEditManualsAndPolicies={editManualAndPolices}
      />
      <ManualsAndPoliciesDetails
        handleApprove={approveManualsAndPolicies}
        handleCheck={checkManualsAndPolicies}
      />
    </div>
  );
};

export default ManualsAndPolicies;
