import { recordOfBiddersActions } from "../../../actions/procument/recordOfBidders/recordOfBidders.actions";
import apiRequests from "../../../api/api";

const recordOfBiddersThunks = {
  getRecordOfBidders: () => async (dispatch) => {
    dispatch(recordOfBiddersActions.setRecordOfBiddersRequest());
    const res = await apiRequests.getRequest("/procurement/recordOfBidders/");
    if (res.success)
      dispatch(recordOfBiddersActions.setRecordOfBiddersSuccess(res.data));
    else dispatch(recordOfBiddersActions.setRecordOfBiddersError(res.error));
  },
  getBidders: (id) => async (dispatch) => {
    dispatch(recordOfBiddersActions.setBiddersRequest());
    const res = await apiRequests.getRequest(
      `/procurement/recordOfBidders/bidders/${id}`
    );
    if (res.success)
      dispatch(recordOfBiddersActions.setBiddersSuccess(res.data));
    else dispatch(recordOfBiddersActions.setBiddersError(res.error));
  },
  getRecordOfBiddersDetails: (id) => async (dispatch) => {
    dispatch(recordOfBiddersActions.setRecordOfBiddersDetailsRequest());
    const res = await apiRequests.getRequest(
      `/procurement/recordOfBidders/${id}`
    );
    if (res.success)
      dispatch(
        recordOfBiddersActions.setRecordOfBiddersDetailsSuccess(res.data)
      );
    else
      dispatch(
        recordOfBiddersActions.setRecordOfBiddersDetailsError(res.error)
      );
  },
  getBiddersDetails: (id) => async (dispatch) => {
    dispatch(recordOfBiddersActions.setBiddersDetailsRequest());
    const res = await apiRequests.getRequest(
      `/procurement/recordOfBidders/bidders/details/${id}`
    );
    if (res.success)
      dispatch(recordOfBiddersActions.setBiddersDetailsSuccess(res.data));
    else dispatch(recordOfBiddersActions.setBiddersDetailsError(res.error));
  },
  addRecordOfBidders: (body, recordOfBidders) => async (dispatch) => {
    dispatch(recordOfBiddersActions.addRecordOfBiddersRequest());
    const res = await apiRequests.postRequest(
      "/procurement/recordOfBidders/",
      body
    );
    if (res.success)
      dispatch(
        recordOfBiddersActions.addRecordOfBiddersSuccess({
          data: [res.data, ...recordOfBidders],
          message: res.message,
        })
      );
    else dispatch(recordOfBiddersActions.addRecordOfBiddersError(res.error));
    dispatch(recordOfBiddersActions.addRecordOfBiddersComplete());
  },
  addBidders: (body, bidders) => async (dispatch) => {
    dispatch(recordOfBiddersActions.addBiddersRequest());
    const res = await apiRequests.postRequest(
      "/procurement/recordOfBidders/bidders/",
      body
    );
    if (res.success)
      dispatch(
        recordOfBiddersActions.addBiddersSuccess({
          data: [res.data, ...bidders],
          message: res.message,
        })
      );
    else dispatch(recordOfBiddersActions.addBiddersError(res.error));
    dispatch(recordOfBiddersActions.addBiddersComplete());
  },
  editBidders: (body, bidders) => async (dispatch) => {
    dispatch(recordOfBiddersActions.editBiddersRequest());
    const res = await apiRequests.putRequest(
      `/procurement/recordOfBidders/bidders/${body.id}`,
      body
    );
    if (res.success)
      dispatch(
        recordOfBiddersActions.editBiddersSuccess({
          data: [res.data, ...bidders],
          message: res.message,
        })
      );
    else dispatch(recordOfBiddersActions.editBiddersError(res.error));
    dispatch(recordOfBiddersActions.editBiddersComplete());
  },
  deleteRecordOfBidders: (id, recordOfBidders) => async (dispatch) => {
    dispatch(recordOfBiddersActions.deleteRecordOfBiddersRequest());
    const res = await apiRequests.deleteRequest(
      `/procurement/recordOfBidders/${id}`
    );
    if (res.success)
      dispatch(
        recordOfBiddersActions.deleteRecordOfBiddersSuccess({
          message: res.message,
          data: recordOfBidders,
        })
      );
    else dispatch(recordOfBiddersActions.deleteRecordOfBiddersError(res.error));
    dispatch(recordOfBiddersActions.deleteRecordOfBiddersComplete());
  },
  deleteBidders: (id, bidders) => async (dispatch) => {
    dispatch(recordOfBiddersActions.deleteBiddersRequest());
    const res = await apiRequests.deleteRequest(
      `/procurement/recordOfBidders/bidders/${id}`
    );
    if (res.success)
      dispatch(
        recordOfBiddersActions.deleteBiddersSuccess({
          message: res.message,
          data: bidders,
        })
      );
    else dispatch(recordOfBiddersActions.deleteBiddersError(res.error));
    dispatch(recordOfBiddersActions.deleteBiddersError());
  },
  checkRecordOfBidders: (id) => async (dispatch) => {
    dispatch(recordOfBiddersActions.checkRecordOfBiddersRequest());
    const res = await apiRequests.patchRequest(
      `/procurement/recordOfBidders/check/${id}`,
      { checkedAt: new Date() }
    );
    if (res.success) {
      dispatch(recordOfBiddersActions.checkRecordOfBiddersSuccess(res.message));
      dispatch(recordOfBiddersThunks.getRecordOfBiddersDetails(id));
    } else
      dispatch(recordOfBiddersActions.checkRecordOfBiddersError(res.error));
    dispatch(recordOfBiddersActions.checkRecordOfBiddersComplete());
  },
  approveRecordOfBidders: (id) => async (dispatch) => {
    dispatch(recordOfBiddersActions.approveRecordOfBiddersRequest());
    const res = await apiRequests.patchRequest(
      `/procurement/recordOfBidders/approve/${id}`,
      { approvedAt: new Date() }
    );
    if (res.success) {
      dispatch(
        recordOfBiddersActions.approveRecordOfBiddersSuccess(res.message)
      );
      dispatch(recordOfBiddersThunks.getRecordOfBiddersDetails(id));
    } else
      dispatch(recordOfBiddersActions.approveRecordOfBiddersError(res.error));
    dispatch(recordOfBiddersActions.approveRecordOfBiddersComplete());
  },
  submitRecordOfBidders: (id) => async (dispatch) => {
    dispatch(recordOfBiddersActions.submitRecordOfBiddersRequest());
    const res = await apiRequests.patchRequest(
      `/procurement/recordOfBidders/submit/${id}`,
      { approvedAt: new Date() }
    );
    if (res.success) {
      dispatch(
        recordOfBiddersActions.submitRecordOfBiddersSuccess(res.message)
      );
      dispatch(recordOfBiddersThunks.getRecordOfBiddersDetails(id));
    } else
      dispatch(recordOfBiddersActions.submitRecordOfBiddersError(res.error));
    dispatch(recordOfBiddersActions.submitRecordOfBiddersComplete());
  },
  recordOfBiddersMetaData: () => async (dispatch) => {
    dispatch(recordOfBiddersActions.setRecordOfBiddersMetaDataRequest());
    const res = await apiRequests.getRequest(
      "/procurement/recordOfBidders/metaData"
    );
    dispatch(recordOfBiddersActions.setRecordOfBiddersMetaData(res.data || {}));
  },
};

export default recordOfBiddersThunks;
