export const bidEvaluationsConstants = {
  SET_BID_EVALUATIONS_SUCCESS: "SET_BID_EVALUATIONS_SUCCESS",
  SET_BID_EVALUATIONS_LOADING: "SET_BID_EVALUATIONS_LOADING",
  SET_BID_EVALUATIONS_ERROR: "SET_BID_EVALUATIONS_ERROR",

  SET_ELIGIBILITY_EVALUATIONS_SUCCESS: "SET_ELIGIBILITY_EVALUATIONS_SUCCESS",
  SET_ELIGIBILITY_EVALUATIONS_LOADING: "SET_ELIGIBILITY_EVALUATIONS_LOADING",
  SET_ELIGIBILITY_EVALUATIONS_ERROR: "SET_ELIGIBILITY_EVALUATIONS_ERROR",

  SET_BID_EVALUATIONS_META_DATA: "SET_BID_EVALUATIONS_META_DATA",
  SET_BID_EVALUATIONS_META_DATA_LOADING:
    "SET_BID_EVALUATIONS_META_DATA_LOADING",

  SET_BID_EVALUATION_DETAILS_SUCCESS: "SET_BID_EVALUATION_DETAILS_SUCCESS",
  SET_BID_EVALUATION_DETAILS_LOADING: "SET_BID_EVALUATION_DETAILS_LOADING",
  SET_BID_EVALUATION_DETAILS_ERROR: "SET_BID_EVALUATION_DETAILS_ERROR",

  SET_ELIGIBILITY_EVALUATION_CRITERIA_DETAILS_SUCCESS:
    "SET_ELIGIBILITY_EVALUATION_CRITERIA_DETAILS_SUCCESS",
  SET_ELIGIBILITY_EVALUATION_CRITERIA_DETAILS_LOADING:
    "SET_ELIGIBILITY_EVALUATION_CRITERIA_DETAILS_LOADING",
  SET_ELIGIBILITY_EVALUATION_CRITERIA_DETAILS_ERROR:
    "SET_ELIGIBILITY_EVALUATION_CRITERIA_DETAILS_ERROR",

  ADD_BID_EVALUATIONS_FORM_LOADING: "ADD_BID_EVALUATIONS_FORM_LOADING",
  ADD_BID_EVALUATIONS_FORM_ERROR: "ADD_BID_EVALUATIONS_FORM_ERROR",
  ADD_BID_EVALUATIONS_FORM_SUCCESS: "ADD_BID_EVALUATIONS_FORM_SUCCESS",
  ADD_BID_EVALUATIONS_FORM_COMPLETE: "ADD_BID_EVALUATIONS_FORM_COMPLETE",

  ADD_ELIGIBILITY_EVALUATIONS_CRITERIA_FORM_LOADING:
    "ADD_ELIGIBILITY_EVALUATIONS_CRITERIA_FORM_LOADING",
  ADD_ELIGIBILITY_EVALUATIONS_CRITERIA_FORM_ERROR:
    "ADD_ELIGIBILITY_EVALUATIONS_CRITERIA_FORM_ERROR",
  ADD_ELIGIBILITY_EVALUATIONS_CRITERIA_FORM_SUCCESS:
    "ADD_ELIGIBILITY_EVALUATIONS_CRITERIA_FORM_SUCCESS",
  ADD_ELIGIBILITY_EVALUATIONS_CRITERIA_FORM_COMPLETE:
    "ADD_ELIGIBILITY_EVALUATIONS_CRITERIA_FORM_COMPLETE",

  EDIT_ELIGIBILITY_EVALUATIONS_CRITERIA_LOADING:
    "EDIT_ELIGIBILITY_EVALUATIONS_CRITERIA_LOADING",
  EDIT_ELIGIBILITY_EVALUATIONS_CRITERIA_ERROR:
    "EDIT_ELIGIBILITY_EVALUATIONS_CRITERIA_ERROR",
  EDIT_ELIGIBILITY_EVALUATIONS_CRITERIA_SUCCESS:
    "EDIT_ELIGIBILITY_EVALUATIONS_CRITERIA_SUCCESS",
  EDIT_ELIGIBILITY_EVALUATIONS_CRITERIA_COMPLETE:
    "EDIT_ELIGIBILITY_EVALUATIONS_CRITERIA_COMPLETE",
  EDIT_ELIGIBILITY_EVALUATIONS_CRITERIA_DATA:
    "EDIT_ELIGIBILITY_EVALUATIONS_CRITERIA_DATA",

  EDIT_ELIGIBILITY_EVALUATIONS_LOADING: "EDIT_ELIGIBILITY_EVALUATIONS_LOADING",
  EDIT_ELIGIBILITY_EVALUATIONS_ERROR: "EDIT_ELIGIBILITY_EVALUATIONS_ERROR",
  EDIT_ELIGIBILITY_EVALUATIONS_SUCCESS: "EDIT_ELIGIBILITY_EVALUATIONS_SUCCESS",
  EDIT_ELIGIBILITY_EVALUATIONS_COMPLETE:
    "EDIT_ELIGIBILITY_EVALUATIONS_COMPLETE",
  EDIT_ELIGIBILITY_EVALUATIONS_DATA: "EDIT_ELIGIBILITY_EVALUATIONS_DATA",

  DELETE_ELIGIBILITY_EVALUATIONS_CRITERIA_LOADING:
    "DELETE_ELIGIBILITY_EVALUATIONS_CRITERIA_LOADING",
  DELETE_ELIGIBILITY_EVALUATIONS_CRITERIA_ERROR:
    "DELETE_ELIGIBILITY_EVALUATIONS_CRITERIA_ERROR",
  DELETE_ELIGIBILITY_EVALUATIONS_CRITERIA_SUCCESS:
    "DELETE_ELIGIBILITY_EVALUATIONS_CRITERIA_SUCCESS",
  DELETE_ELIGIBILITY_EVALUATIONS_CRITERIA_COMPLETE:
    "DELETE_ELIGIBILITY_EVALUATIONS_CRITERIA_COMPLETE",

  ADD_TECHNICAL_EVALUATIONS_FORM_LOADING:
    "ADD_TECHNICAL_EVALUATIONS_FORM_LOADING",
  ADD_TECHNICAL_EVALUATIONS_FORM_ERROR: "ADD_TECHNICAL_EVALUATIONS_FORM_ERROR",
  ADD_TECHNICAL_EVALUATIONS_FORM_SUCCESS:
    "ADD_TECHNICAL_EVALUATIONS_FORM_SUCCESS",
  ADD_TECHNICAL_EVALUATIONS_FORM_COMPLETE:
    "ADD_TECHNICAL_EVALUATIONS_FORM_COMPLETE",

  SET_TECHNICAL_EVALUATIONS_ITEMS_SUCCESS:
    "SET_TECHNICAL_EVALUATIONS_ITEMS_SUCCESS",
  SET_TECHNICAL_EVALUATIONS_ITEMS_LOADING:
    "SET_TECHNICAL_EVALUATIONS_ITEMS_LOADING",
  SET_TECHNICAL_EVALUATIONS_ITEMS_ERROR:
    "SET_TECHNICAL_EVALUATIONS_ITEMS_ERROR",

  SET_TECHNICAL_EVALUATIONS_DATA_SUCCESS:
    "SET_TECHNICAL_EVALUATIONS_DATA_SUCCESS",
  SET_TECHNICAL_EVALUATIONS_DATA_LOADING:
    "SET_TECHNICAL_EVALUATIONS_DATA_LOADING",
  SET_TECHNICAL_EVALUATIONS_DATA_ERROR: "SET_TECHNICAL_EVALUATIONS_DATA_ERROR",

  EDIT_TECHNICAL_EVALUATIONS_LOADING: "EDIT_TECHNICAL_EVALUATIONS_FORM_LOADING",
  EDIT_TECHNICAL_EVALUATIONS_ERROR: "EDIT_TECHNICAL_EVALUATIONS_ERROR",
  EDIT_TECHNICAL_EVALUATIONS_SUCCESS: "EDIT_TECHNICAL_EVALUATIONS_SUCCESS",
  EDIT_TECHNICAL_EVALUATIONS_COMPLETE: "EDIT_TECHNICAL_EVALUATIONS_COMPLETE",
  EDIT_TECHNICAL_EVALUATIONS_DATA: "EDIT_TECHNICAL_EVALUATIONS_DATA",

  SET_FINANCIAL_EVALUATIONS_ITEMS_SUCCESS:
    "SET_FINANCIAL_EVALUATIONS_ITEMS_SUCCESS",
  SET_FINANCIAL_EVALUATIONS_ITEMS_LOADING:
    "SET_FINANCIAL_EVALUATIONS_ITEMS_LOADING",
  SET_FINANCIAL_EVALUATIONS_ITEMS_ERROR:
    "SET_FINANCIAL_EVALUATIONS_ITEMS_ERROR",

  SET_FINANCIAL_EVALUATIONS_DATA_SUCCESS:
    "SET_FINANCIAL_EVALUATIONS_DATA_SUCCESS",
  SET_FINANCIAL_EVALUATIONS_DATA_LOADING:
    "SET_FINANCIAL_EVALUATIONS_DATA_LOADING",
  SET_FINANCIAL_EVALUATIONS_DATA_ERROR: "SET_FINANCIAL_EVALUATIONS_DATA_ERROR",

  ADD_FINANCIAL_EVALUATIONS_FORM_LOADING:
    "ADD_FINANCIAL_EVALUATIONS_FORM_LOADING",
  ADD_FINANCIAL_EVALUATIONS_FORM_ERROR: "ADD_FINANCIAL_EVALUATIONS_FORM_ERROR",
  ADD_FINANCIAL_EVALUATIONS_FORM_SUCCESS:
    "ADD_FINANCIAL_EVALUATIONS_FORM_SUCCESS",
  ADD_FINANCIAL_EVALUATIONS_FORM_COMPLETE:
    "ADD_FINANCIAL_EVALUATIONS_FORM_COMPLETE",

  EDIT_FINANCIAL_EVALUATIONS_LOADING: "EDIT_FINANCIAL_EVALUATIONS_FORM_LOADING",
  EDIT_FINANCIAL_EVALUATIONS_ERROR: "EDIT_FINANCIAL_EVALUATIONS_ERROR",
  EDIT_FINANCIAL_EVALUATIONS_SUCCESS: "EDIT_FINANCIAL_EVALUATIONS_SUCCESS",
  EDIT_FINANCIAL_EVALUATIONS_COMPLETE: "EDIT_FINANCIAL_EVALUATIONS_COMPLETE",
  EDIT_FINANCIAL_EVALUATIONS_DATA: "EDIT_FINANCIAL_EVALUATIONS_DATA",

  EDIT_BID_EVALUATIONS_FORM_LOADING: "EDIT_BID_EVALUATIONS_FORM_LOADING",
  EDIT_BID_EVALUATIONS_FORM_ERROR: "EDIT_BID_EVALUATIONS_FORM_ERROR",
  EDIT_BID_EVALUATIONS_FORM_SUCCESS: "EDIT_BID_EVALUATIONS_FORM_SUCCESS",
  EDIT_BID_EVALUATIONS_FORM_COMPLETE: "EDIT_BID_EVALUATIONS_FORM_COMPLETE",
  EDIT_BID_EVALUATIONS_FORM_DATA: "EDIT_BID_EVALUATIONS_FORM_DATA",

  DELETE_BID_EVALUATIONS_FORM_LOADING: "DELETE_BID_EVALUATIONS_FORM_LOADING",
  DELETE_BID_EVALUATIONS_FORM_ERROR: "DELETE_BID_EVALUATIONS_FORM_ERROR",
  DELETE_BID_EVALUATIONS_FORM_SUCCESS: "DELETE_BID_EVALUATIONS_FORM_SUCCESS",
  DELETE_BID_EVALUATIONS_FORM_COMPLETE: "DELETE_BID_EVALUATIONS_FORM_COMPLETE",

  SUBMIT_BID_EVALUATIONS_FORM_LOADING: "SUBMIT_BID_EVALUATIONS_FORM_LOADING",
  SUBMIT_BID_EVALUATIONS_FORM_ERROR: "SUBMIT_BID_EVALUATIONS_FORM_ERROR",
  SUBMIT_BID_EVALUATIONS_FORM_SUCCESS: "SUBMIT_BID_EVALUATIONS_FORM_SUCCESS",
  SUBMIT_BID_EVALUATIONS_FORM_COMPLETE: "SUBMIT_BID_EVALUATIONS_FORM_COMPLETE",

  CHECK_BID_EVALUATIONS_FORM_LOADING: "CHECK_BID_EVALUATIONS_FORM_LOADING",
  CHECK_BID_EVALUATIONS_FORM_ERROR: "CHECK_BID_EVALUATIONS_FORM_ERROR",
  CHECK_BID_EVALUATIONS_FORM_SUCCESS: "CHECK_BID_EVALUATIONS_FORM_SUCCESS",
  CHECK_BID_EVALUATIONS_FORM_COMPLETE: "CHECK_BID_EVALUATIONS_FORM_COMPLETE",

  APPROVE_BID_EVALUATIONS_FORM_LOADING: "APPROVE_BID_EVALUATIONS_FORM_LOADING",
  APPROVE_BID_EVALUATIONS_FORM_ERROR: "APPROVE_BID_EVALUATIONS_FORM_ERROR",
  APPROVE_BID_EVALUATIONS_FORM_SUCCESS: "APPROVE_BID_EVALUATIONS_FORM_SUCCESS",
  APPROVE_BID_EVALUATIONS_FORM_COMPLETE:
    "APPROVE_BID_EVALUATIONS_FORM_COMPLETE",

  REJECT_BID_EVALUATIONS_FORM_LOADING: "REJECT_BID_EVALUATIONS_FORM_LOADING",
  REJECT_BID_EVALUATIONS_FORM_ERROR: "REJECT_BID_EVALUATIONS_FORM_ERROR",
  REJECT_BID_EVALUATIONS_FORM_SUCCESS: "REJECT_BID_EVALUATIONS_FORM_SUCCESS",
  REJECT_BID_EVALUATIONS_FORM_COMPLETE: "REJECT_BID_EVALUATIONS_FORM_COMPLETE",

  REMOVE_BIDDER_FROM_EVALUATION_LOADING:
    "REMOVE_BIDDER_FROM_EVALUATION_LOADING",
  REMOVE_BIDDER_FROM_EVALUATION_ERROR: "REMOVE_BIDDER_FROM_EVALUATION_ERROR",
  REMOVE_BIDDER_FROM_EVALUATION_SUCCESS:
    "REMOVE_BIDDER_FROM_EVALUATION_SUCCESS",
  REMOVE_BIDDER_FROM_EVALUATION_COMPLETE:
    "REMOVE_BIDDER_FROM_EVALUATION_COMPLETE",
};

export const bidEvaluationsActions = {
  setBidEvaluationsLoading: () => ({
    type: bidEvaluationsConstants.SET_BID_EVALUATIONS_LOADING,
  }),
  setBidEvaluationsSuccess: (payload) => ({
    type: bidEvaluationsConstants.SET_BID_EVALUATIONS_SUCCESS,
    payload,
  }),
  setBidEvaluationsError: (payload) => ({
    type: bidEvaluationsConstants.SET_BID_EVALUATIONS_ERROR,
    payload,
  }),
  setEligibilityEvaluationsLoading: () => ({
    type: bidEvaluationsConstants.SET_ELIGIBILITY_EVALUATIONS_LOADING,
  }),
  setEligibilityEvaluationsSuccess: (payload) => ({
    type: bidEvaluationsConstants.SET_ELIGIBILITY_EVALUATIONS_SUCCESS,
    payload,
  }),
  setEligibilityEvaluationsError: (payload) => ({
    type: bidEvaluationsConstants.SET_ELIGIBILITY_EVALUATIONS_ERROR,
    payload,
  }),
  setEligibilityEvaluationDetailsLoading: () => ({
    type: bidEvaluationsConstants.SET_ELIGIBILITY_EVALUATION_CRITERIA_DETAILS_LOADING,
  }),
  setEligibilityEvaluationDetailsSuccess: (payload) => ({
    type: bidEvaluationsConstants.SET_ELIGIBILITY_EVALUATION_CRITERIA_DETAILS_SUCCESS,
    payload,
  }),
  setEligibilityEvaluationDetailsError: (payload) => ({
    type: bidEvaluationsConstants.SET_ELIGIBILITY_EVALUATION_CRITERIA_DETAILS_ERROR,
    payload,
  }),
  setBidEvaluationDetailsLoading: () => ({
    type: bidEvaluationsConstants.SET_BID_EVALUATION_DETAILS_LOADING,
  }),
  setBidEvaluationDetailsSuccess: (payload) => ({
    type: bidEvaluationsConstants.SET_BID_EVALUATION_DETAILS_SUCCESS,
    payload,
  }),
  setBidEvaluationDetailsError: (payload) => ({
    type: bidEvaluationsConstants.SET_BID_EVALUATION_DETAILS_ERROR,
    payload,
  }),
  setBidEvaluationsMetaDataLoading: () => ({
    type: bidEvaluationsConstants.SET_BID_EVALUATIONS_META_DATA_LOADING,
  }),
  setBidEvaluationsMetaData: (payload) => ({
    type: bidEvaluationsConstants.SET_BID_EVALUATIONS_META_DATA,
    payload,
  }),
  addBidEvaluationsRequest: () => ({
    type: bidEvaluationsConstants.ADD_BID_EVALUATIONS_FORM_LOADING,
  }),
  addBidEvaluationsSuccess: (payload) => ({
    type: bidEvaluationsConstants.ADD_BID_EVALUATIONS_FORM_SUCCESS,
    payload,
  }),
  addBidEvaluationsError: (payload) => ({
    type: bidEvaluationsConstants.ADD_BID_EVALUATIONS_FORM_ERROR,
    payload,
  }),
  addBidEvaluationsComplete: () => ({
    type: bidEvaluationsConstants.ADD_BID_EVALUATIONS_FORM_COMPLETE,
  }),
  addEligibilityEvaluationsCriteriaRequest: () => ({
    type: bidEvaluationsConstants.ADD_ELIGIBILITY_EVALUATIONS_CRITERIA_FORM_LOADING,
  }),
  addEligibilityEvaluationsCriteriaSuccess: (payload) => ({
    type: bidEvaluationsConstants.ADD_ELIGIBILITY_EVALUATIONS_CRITERIA_FORM_SUCCESS,
    payload,
  }),
  addEligibilityEvaluationsCriteriaError: (payload) => ({
    type: bidEvaluationsConstants.ADD_ELIGIBILITY_EVALUATIONS_CRITERIA_FORM_ERROR,
    payload,
  }),
  addEligibilityEvaluationsCriteriaComplete: () => ({
    type: bidEvaluationsConstants.ADD_ELIGIBILITY_EVALUATIONS_CRITERIA_FORM_COMPLETE,
  }),
  deleteEligibilityEvaluationsCriteriaRequest: () => ({
    type: bidEvaluationsConstants.DELETE_ELIGIBILITY_EVALUATIONS_CRITERIA_LOADING,
  }),
  deleteEligibilityEvaluationsCriteriaSuccess: (payload) => ({
    type: bidEvaluationsConstants.DELETE_ELIGIBILITY_EVALUATIONS_CRITERIA_SUCCESS,
    payload,
  }),
  deleteEligibilityEvaluationsCriteriaError: (payload) => ({
    type: bidEvaluationsConstants.DELETE_ELIGIBILITY_EVALUATIONS_CRITERIA_ERROR,
    payload,
  }),
  deleteEligibilityEvaluationsCriteriaComplete: () => ({
    type: bidEvaluationsConstants.DELETE_ELIGIBILITY_EVALUATIONS_CRITERIA_COMPLETE,
  }),
  editEligibilityEvaluationsCriteriaRequest: () => ({
    type: bidEvaluationsConstants.EDIT_ELIGIBILITY_EVALUATIONS_CRITERIA_LOADING,
  }),
  editEligibilityEvaluationsCriteriaSuccess: (payload) => ({
    type: bidEvaluationsConstants.EDIT_ELIGIBILITY_EVALUATIONS_CRITERIA_SUCCESS,
    payload,
  }),
  editEligibilityEvaluationsCriteriaError: (payload) => ({
    type: bidEvaluationsConstants.EDIT_ELIGIBILITY_EVALUATIONS_CRITERIA_ERROR,
    payload,
  }),
  editEligibilityEvaluationsCriteriaComplete: () => ({
    type: bidEvaluationsConstants.EDIT_ELIGIBILITY_EVALUATIONS_CRITERIA_COMPLETE,
  }),
  editEligibilityEvaluationsCriteriaData: (payload) => ({
    type: bidEvaluationsConstants.EDIT_ELIGIBILITY_EVALUATIONS_CRITERIA_DATA,
    payload,
  }),
  editEligibilityEvaluationsRequest: () => ({
    type: bidEvaluationsConstants.EDIT_ELIGIBILITY_EVALUATIONS_LOADING,
  }),
  editEligibilityEvaluationsSuccess: (payload) => ({
    type: bidEvaluationsConstants.EDIT_ELIGIBILITY_EVALUATIONS_SUCCESS,
    payload,
  }),
  editEligibilityEvaluationsError: (payload) => ({
    type: bidEvaluationsConstants.EDIT_ELIGIBILITY_EVALUATIONS_ERROR,
    payload,
  }),
  editEligibilityEvaluationsComplete: () => ({
    type: bidEvaluationsConstants.EDIT_ELIGIBILITY_EVALUATIONS_COMPLETE,
  }),
  editEligibilityEvaluationsData: (payload) => ({
    type: bidEvaluationsConstants.EDIT_ELIGIBILITY_EVALUATIONS_DATA,
    payload,
  }),
  editBidEvaluationsRequest: () => ({
    type: bidEvaluationsConstants.EDIT_BID_EVALUATIONS_FORM_LOADING,
  }),
  editBidEvaluationsSuccess: (payload) => ({
    type: bidEvaluationsConstants.EDIT_BID_EVALUATIONS_FORM_SUCCESS,
    payload,
  }),
  editBidEvaluationsError: (payload) => ({
    type: bidEvaluationsConstants.EDIT_BID_EVALUATIONS_FORM_ERROR,
    payload,
  }),
  editBidEvaluationsComplete: () => ({
    type: bidEvaluationsConstants.EDIT_BID_EVALUATIONS_FORM_COMPLETE,
  }),
  editBidEvaluationsData: (payload) => ({
    type: bidEvaluationsConstants.EDIT_BID_EVALUATIONS_FORM_DATA,
    payload,
  }),
  deleteBidEvaluationsRequest: () => ({
    type: bidEvaluationsConstants.DELETE_BID_EVALUATIONS_FORM_LOADING,
  }),
  deleteBidEvaluationsSuccess: (payload) => ({
    type: bidEvaluationsConstants.DELETE_BID_EVALUATIONS_FORM_SUCCESS,
    payload,
  }),
  deleteBidEvaluationsError: (payload) => ({
    type: bidEvaluationsConstants.DELETE_BID_EVALUATIONS_FORM_ERROR,
    payload,
  }),
  deleteBidEvaluationsComplete: () => ({
    type: bidEvaluationsConstants.DELETE_BID_EVALUATIONS_FORM_COMPLETE,
  }),
  deleteEligibilityEvaluationsRequest: () => ({
    type: bidEvaluationsConstants.DELETE_ELIGIBILITY_EVALUATIONS_CRITERIA_LOADING,
  }),
  deleteEligibilityEvaluationsSuccess: (payload) => ({
    type: bidEvaluationsConstants.DELETE_ELIGIBILITY_EVALUATIONS_CRITERIA_SUCCESS,
    payload,
  }),
  deleteEligibilityEvaluationsError: (payload) => ({
    type: bidEvaluationsConstants.DELETE_ELIGIBILITY_EVALUATIONS_CRITERIA_ERROR,
    payload,
  }),
  deleteEligibilityEvaluationsComplete: () => ({
    type: bidEvaluationsConstants.DELETE_ELIGIBILITY_EVALUATIONS_CRITERIA_COMPLETE,
  }),
  setEvaluationItemsRequest: () => ({
    type: bidEvaluationsConstants.SET_TECHNICAL_EVALUATIONS_ITEMS_LOADING,
  }),
  setEvaluationItemsSuccess: (payload) => ({
    type: bidEvaluationsConstants.SET_TECHNICAL_EVALUATIONS_ITEMS_SUCCESS,
    payload,
  }),
  setEvaluationItemsError: (payload) => ({
    type: bidEvaluationsConstants.SET_TECHNICAL_EVALUATIONS_ITEMS_ERROR,
    payload,
  }),
  setTechnicalEvaluationDataRequest: () => ({
    type: bidEvaluationsConstants.SET_TECHNICAL_EVALUATIONS_DATA_LOADING,
  }),
  setTechnicalEvaluationDataSuccess: (payload) => ({
    type: bidEvaluationsConstants.SET_TECHNICAL_EVALUATIONS_DATA_SUCCESS,
    payload,
  }),
  setTechnicalEvaluationDataError: (payload) => ({
    type: bidEvaluationsConstants.SET_TECHNICAL_EVALUATIONS_DATA_ERROR,
    payload,
  }),
  setFinancialEvaluationItemsRequest: () => ({
    type: bidEvaluationsConstants.SET_FINANCIAL_EVALUATIONS_ITEMS_LOADING,
  }),
  setFinancialEvaluationItemsSuccess: (payload, financialEvaluations) => ({
    type: bidEvaluationsConstants.SET_FINANCIAL_EVALUATIONS_ITEMS_SUCCESS,
    payload,
    financialEvaluations,
  }),
  setFinancialEvaluationItemsError: (payload) => ({
    type: bidEvaluationsConstants.SET_FINANCIAL_EVALUATIONS_ITEMS_ERROR,
    payload,
  }),
  setFinancialEvaluationDataRequest: () => ({
    type: bidEvaluationsConstants.SET_FINANCIAL_EVALUATIONS_DATA_LOADING,
  }),
  setFinancialEvaluationDataSuccess: (payload) => ({
    type: bidEvaluationsConstants.SET_FINANCIAL_EVALUATIONS_DATA_SUCCESS,
    payload,
  }),
  setFinancialEvaluationDataError: (payload) => ({
    type: bidEvaluationsConstants.SET_FINANCIAL_EVALUATIONS_DATA_ERROR,
    payload,
  }),
  addTechnicalEvaluationsFormRequest: () => ({
    type: bidEvaluationsConstants.ADD_TECHNICAL_EVALUATIONS_FORM_LOADING,
  }),
  addTechnicalEvaluationsFormSuccess: (payload) => ({
    type: bidEvaluationsConstants.ADD_TECHNICAL_EVALUATIONS_FORM_SUCCESS,
    payload,
  }),
  addTechnicalEvaluationsFormError: (payload) => ({
    type: bidEvaluationsConstants.ADD_TECHNICAL_EVALUATIONS_FORM_ERROR,
    payload,
  }),
  addTechnicalEvaluationsFormComplete: () => ({
    type: bidEvaluationsConstants.ADD_TECHNICAL_EVALUATIONS_FORM_COMPLETE,
  }),
  addFinancialEvaluationsRequest: () => ({
    type: bidEvaluationsConstants.ADD_FINANCIAL_EVALUATIONS_FORM_LOADING,
  }),
  addFinancialEvaluationsSuccess: (payload) => ({
    type: bidEvaluationsConstants.ADD_FINANCIAL_EVALUATIONS_FORM_SUCCESS,
    payload,
  }),
  addFinancialEvaluationsError: (payload) => ({
    type: bidEvaluationsConstants.ADD_FINANCIAL_EVALUATIONS_FORM_ERROR,
    payload,
  }),
  addFinancialEvaluationsComplete: () => ({
    type: bidEvaluationsConstants.ADD_FINANCIAL_EVALUATIONS_FORM_COMPLETE,
  }),
  editTechnicalEvaluationsRequest: () => ({
    type: bidEvaluationsConstants.EDIT_TECHNICAL_EVALUATIONS_LOADING,
  }),
  editTechnicalEvaluationsSuccess: (payload) => ({
    type: bidEvaluationsConstants.EDIT_TECHNICAL_EVALUATIONS_SUCCESS,
    payload,
  }),
  editTechnicalEvaluationsError: (payload) => ({
    type: bidEvaluationsConstants.EDIT_TECHNICAL_EVALUATIONS_ERROR,
    payload,
  }),
  editTechnicalEvaluationsComplete: () => ({
    type: bidEvaluationsConstants.EDIT_TECHNICAL_EVALUATIONS_COMPLETE,
  }),
  editTechnicalEvaluationsData: (payload) => ({
    type: bidEvaluationsConstants.EDIT_TECHNICAL_EVALUATIONS_DATA,
    payload,
  }),
  editFinancialEvaluationsRequest: () => ({
    type: bidEvaluationsConstants.EDIT_FINANCIAL_EVALUATIONS_LOADING,
  }),
  editFinancialEvaluationsSuccess: (payload) => ({
    type: bidEvaluationsConstants.EDIT_FINANCIAL_EVALUATIONS_SUCCESS,
    payload,
  }),
  editFinancialEvaluationsError: (payload) => ({
    type: bidEvaluationsConstants.EDIT_FINANCIAL_EVALUATIONS_ERROR,
    payload,
  }),
  editFinancialEvaluationsComplete: () => ({
    type: bidEvaluationsConstants.EDIT_FINANCIAL_EVALUATIONS_COMPLETE,
  }),
  editFinancialEvaluationsData: (payload) => ({
    type: bidEvaluationsConstants.EDIT_FINANCIAL_EVALUATIONS_DATA,
    payload,
  }),
  submitBidEvaluationsFormRequest: () => ({
    type: bidEvaluationsConstants.SUBMIT_BID_EVALUATIONS_FORM_LOADING,
  }),
  submitBidEvaluationsFormSuccess: (payload) => ({
    type: bidEvaluationsConstants.SUBMIT_BID_EVALUATIONS_FORM_SUCCESS,
    payload,
  }),
  submitBidEvaluationsFormError: (payload) => ({
    type: bidEvaluationsConstants.SUBMIT_BID_EVALUATIONS_FORM_ERROR,
    payload,
  }),
  submitBidEvaluationsFormComplete: () => ({
    type: bidEvaluationsConstants.SUBMIT_BID_EVALUATIONS_FORM_COMPLETE,
  }),
  removeBidderFromBidEvaluationFormRequest: () => ({
    type: bidEvaluationsConstants.REMOVE_BIDDER_FROM_EVALUATION_LOADING,
  }),
  removeBidderFromBidEvaluationFormSuccess: (payload) => ({
    type: bidEvaluationsConstants.REMOVE_BIDDER_FROM_EVALUATION_SUCCESS,
    payload,
  }),
  removeBidderFromBidEvaluationFormError: (payload) => ({
    type: bidEvaluationsConstants.REMOVE_BIDDER_FROM_EVALUATION_ERROR,
    payload,
  }),
  removeBidderFromBidEvaluationFormComplete: () => ({
    type: bidEvaluationsConstants.REMOVE_BIDDER_FROM_EVALUATION_COMPLETE,
  }),
  checkBidEvaluationsFormRequest: () => ({
    type: bidEvaluationsConstants.CHECK_BID_EVALUATIONS_FORM_LOADING,
  }),
  checkBidEvaluationsFormSuccess: (payload) => ({
    type: bidEvaluationsConstants.CHECK_BID_EVALUATIONS_FORM_SUCCESS,
    payload,
  }),
  checkBidEvaluationsFormError: (payload) => ({
    type: bidEvaluationsConstants.CHECK_BID_EVALUATIONS_FORM_ERROR,
    payload,
  }),
  checkBidEvaluationsFormComplete: () => ({
    type: bidEvaluationsConstants.CHECK_BID_EVALUATIONS_FORM_COMPLETE,
  }),
  rejectBidEvaluationsFormRequest: () => ({
    type: bidEvaluationsConstants.REJECT_BID_EVALUATIONS_FORM_LOADING,
  }),
  rejectBidEvaluationsFormSuccess: (payload) => ({
    type: bidEvaluationsConstants.REJECT_BID_EVALUATIONS_FORM_SUCCESS,
    payload,
  }),
  rejectBidEvaluationsFormError: (payload) => ({
    type: bidEvaluationsConstants.REJECT_BID_EVALUATIONS_FORM_ERROR,
    payload,
  }),
  rejectBidEvaluationsFormComplete: () => ({
    type: bidEvaluationsConstants.REJECT_BID_EVALUATIONS_FORM_COMPLETE,
  }),
  approveBidEvaluationsFormRequest: () => ({
    type: bidEvaluationsConstants.APPROVE_BID_EVALUATIONS_FORM_LOADING,
  }),
  approveBidEvaluationsFormSuccess: (payload) => ({
    type: bidEvaluationsConstants.APPROVE_BID_EVALUATIONS_FORM_SUCCESS,
    payload,
  }),
  approveBidEvaluationsFormError: (payload) => ({
    type: bidEvaluationsConstants.APPROVE_BID_EVALUATIONS_FORM_ERROR,
    payload,
  }),
  approveBidEvaluationsFormComplete: () => ({
    type: bidEvaluationsConstants.APPROVE_BID_EVALUATIONS_FORM_COMPLETE,
  }),
};
