import { Alert, Button, Form, message, Modal, Select } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";
import projectThunks from "../../../../config/thunks/businessDevAndPartnerships/projects/projects.thunks";

const SetPersonIncharge = ({ donorId, handleSetPersonIncharge }) => {
  const { projectsMetaData, projectsMetaDataLoading } = useSelector(
    (state) => state.projectsState
  );
  const {
    setPersonInchargeLoading,
    setPersonInchargeSuccess,
    setPersonInchargeMessage,
    setPersonInchargeError,
  } = useSelector((state) => state.donorsState);
  const { setPersonInchargeModal: visible } = useSelector(
    (state) => state.AppUi
  );
  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch(appUiActions.toggleSetPersonInchargeModal(false));
  };
  const onFinish = (values) => {
    if (donorId) {
      values.id = donorId;
      values.updated_at = new Date();
      handleSetPersonIncharge(values);
    } else message.error("Donor Id not set");
  };
  useEffect(() => {
    dispatch(projectThunks.projectsMetaData());
    if (setPersonInchargeSuccess && setPersonInchargeMessage) {
      message.success(setPersonInchargeMessage);
      dispatch(appUiActions.toggleSetPersonInchargeModal(false));
    }
  }, [dispatch, setPersonInchargeMessage, setPersonInchargeSuccess]);
  return (
    <div>
      <Modal
        open={visible}
        onCancel={closeModal}
        footer={null}
        title="SET PERSON INCHARGE OF PROPOSAL WRITTING"
      >
        <Form onFinish={onFinish} layout="vertical">
          {!setPersonInchargeSuccess && setPersonInchargeError && (
            <Alert
              className="my-2"
              showIcon
              type="error"
              message={setPersonInchargeError}
            />
          )}
          <Form.Item
            name="person_incharge"
            label="Person Incharge Of Proposal Writing"
            rules={[
              { required: true, message: "Please select a person incharge" },
            ]}
          >
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              loading={projectsMetaDataLoading}
            >
              {projectsMetaData?.users?.map((user, id) => {
                return (
                  <Select.Option value={user.id} key={user.id}>
                    {`${user.first_name} ${user.other_names}`}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              loading={setPersonInchargeLoading}
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default SetPersonIncharge;
