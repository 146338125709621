import { create } from "zustand";
import { produce } from "immer";
import { devtools } from "zustand/middleware";
import axios from "axios";

const initialState = {
  strategicPlans: {},
  gettingStrategicPlans: false,
  strategicPlansError: null,

  strategicPlanDetails: {},
  gettingStrategicPlanDetails: false,
  strategicPlanDetailsError: null,
  showStrategicPlanDetails: false,

  creatingStrategicPlan: false,
  createdStrategicPlan: false,
  createStrategicPlanError: null,
  showCreateStrategicPlan: false,

  creating: false,

  updatingStrategicPlan: false,
  updatedStrategicPlan: false,
  updateStrategicPlanError: null,
  strategicPlanToUpdate: {},

  deletingStrategicPlan: false,
  deletedStrategicPlan: false,
  deleteStrategicPlanError: null,

  checkingStrategicPlan: false,
  checkedStrategicPlan: false,
  checkStrategicPlanError: {},

  approvingStrategicPlan: false,
  approvedStrategicPlan: false,
  approveStrategicPlanError: {},

  rejectingStrategicPlan: false,
  rejectedStrategicPlan: false,
  rejectStrategicPlanError: {},

  undoingStrategicPlanApproval: false,
  undoneStrategicPlanApproval: false,
  undoStrategicPlanApprovalError: {},

  creatingStrategicPlanActivity: false,
  createdStrategicPlanActivity: false,
  createStrategicPlanActivityError: {},
  showAddStrategicPlanActivity: false,

  addingStrategicPlanActivity: false,

  updatingStrategicPlanActivity: false,
  updatedStrategicPlanActivity: false,
  updateStrategicPlanActivityError: {},
  strategicPlanActivityToUpdate: {},

  deletingStrategicPlanActivity: false,
  deletedStrategicPlanActivity: false,
  deleteStrategicPlanActivityError: {},

  creatingStrategicPlanActivityIndicator: false,
  createdStrategicPlanActivityIndicator: false,
  createStrategicPlanActivityIndicatorError: {},
  showAddStrategicPlanActivityIndicator: false,

  updatingStrategicPlanActivityIndicator: false,
  updatedStrategicPlanActivityIndicator: false,
  updateStrategicPlanActivityIndicatorError: {},
  strategicPlanActivityIndicatorToUpdate: {},

  deletingStrategicPlanActivityIndicator: false,
  deletedStrategicPlanActivityIndicator: false,
  deleteStrategicPlanActivityIndicatorError: {},

  addingStrategicPlanActivityIndicator: false,

  creatingStrategicPlanThematicArea: false,
  createdStrategicPlanThematicArea: false,
  createStrategicPlanThematicAreaError: {},

  showAddStrategicPlanThematicArea: false,

  addingStrategicPlanThematicArea: false,

  updatingStrategicPlanThematicArea: false,
  updatedStrategicPlanThematicArea: false,
  updateStrategicPlanThematicAreaError: {},
  thematicAreaToUpdate: {},

  deletingStrategicPlanThematicArea: false,
  deletedStrategicPlanThematicArea: false,
  deleteStrategicPlanThematicAreaError: {},

  showViewThemeAreaDetails: {},

  activityToAddIndicators: {},
};

const store = (set, get) => ({
  ...initialState,

  // actions
  getStrategicPlans: async () => {
    set(
      produce((state) => {
        state.gettingStrategicPlans = true;
      })
    );
    await axios({
      method: "GET",
      url: "/programs-mgt/strategic-plans",
    })
      .then((res) => {
        set(
          produce((state) => {
            state.strategicPlans = res;
          })
        );
      })
      .catch((err) => {
        set(
          produce((state) => {
            state.strategicPlansError = err;
          })
        );
      })
      .finally(() => {
        set(
          produce((state) => {
            state.gettingStrategicPlans = false;
          })
        );
      });
  },

  async getStrategicPlanDetails(id) {
    set(
      produce((state) => {
        state.gettingStrategicPlanDetails = true;
      })
    );
    await axios({
      method: "GET",
      url: `/programs-mgt/strategic-plans/${id}`,
    })
      .then((res) => {
        set(
          produce((state) => {
            state.strategicPlanDetails = res.data;
          })
        );
      })
      .catch((err) => {
        set(
          produce((state) => {
            state.strategicPlanDetailsError = err;
          })
        );
      })
      .finally(() => {
        set(
          produce((state) => {
            state.gettingStrategicPlanDetails = false;
          })
        );
      });
  },

  async createStrategicPlan(data) {
    set(
      produce((state) => {
        state.creatingStrategicPlan = true;
        state.createStrategicPlanError = {};
      })
    );
    await axios({
      method: "POST",
      url: "/programs-mgt/strategic-plans",
      data,
    })
      .then((res) => {
        set(
          produce((state) => {
            state.createdStrategicPlan = res.data;
            state.strategicPlans.data.push(res.data);
            state.showStrategicPlanDetails = true;
            state.showCreateStrategicPlan = false;
          })
        );
        get().getStrategicPlanDetails(res.data.id);
      })
      .catch((err) => {
        set(
          produce((state) => {
            state.createStrategicPlanError = err;
          })
        );
      })
      .finally(() => {
        set(
          produce((state) => {
            state.creatingStrategicPlan = false;
          })
        );
      });
  },

  async updateStrategicPlan(id, data) {
    set(
      produce((state) => {
        state.updatingStrategicPlan = true;
        state.updateStrategicPlanError = {};
      })
    );
    await axios({
      method: "PUT",
      url: `/programs-mgt/strategic-plans/${id}`,
      data,
    })
      .then((res) => {
        set(
          produce((state) => {
            state.updatedStrategicPlan = res.data;
            state.strategicPlans = state.strategicPlans.map((plan) =>
              plan.id === res.data.id ? res.data : plan
            );
          })
        );
      })
      .catch((err) => {
        set(
          produce((state) => {
            state.updateStrategicPlanError = err;
          })
        );
      })
      .finally(() => {
        set(
          produce((state) => {
            state.updatingStrategicPlan = false;
          })
        );
      });
  },

  async deleteStrategicPlan(id) {
    set(
      produce((state) => {
        state.deletingStrategicPlan = true;
      })
    );
    await axios({
      method: "DELETE",
      url: `/programs-mgt/strategic-plans/${id}`,
    })
      .then((res) => {
        set(
          produce((state) => {
            state.deletedStrategicPlan = true;
            state.strategicPlans = state.strategicPlans.data.filter(
              (plan) => plan.id !== id
            );
          })
        );
      })
      .catch((err) => {
        set(
          produce((state) => {
            state.deleteStrategicPlanError = err;
          })
        );
      })
      .finally(() => {
        set(
          produce((state) => {
            state.deletingStrategicPlan = false;
          })
        );
      });
  },

  async checkStrategicPlan(id) {
    set(
      produce((state) => {
        state.checkingStrategicPlan = true;
      })
    );
    await axios({
      method: "PATCH",
      url: `/programs-mgt/strategic-plans/check/${id}`,
    })
      .then((res) => {
        set(
          produce((state) => {
            state.checkedStrategicPlan = true;
            state.strategicPlans = state.strategicPlans.map((plan) =>
              plan.id === id ? res.data : plan
            );
          })
        );
      })
      .catch((err) => {
        set(
          produce((state) => {
            state.checkStrategicPlanError = err;
          })
        );
        get().getStrategicPlanDetails(id);
      })
      .finally(() => {
        set(
          produce((state) => {
            state.checkingStrategicPlan = false;
          })
        );
      });
  },

  async approveStrategicPlan(id) {
    set(
      produce((state) => {
        state.approvingStrategicPlan = true;
      })
    );
    await axios({
      method: "PATCH",
      url: `/programs-mgt/strategic-plans/approve/${id}`,
    })
      .then((res) => {
        set(
          produce((state) => {
            state.approvedStrategicPlan = true;
            state.strategicPlans = state.strategicPlans.map((plan) =>
              plan.id === id ? res.data : plan
            );
          })
        );
        get().getStrategicPlanDetails(id);
      })
      .catch((err) => {
        set(
          produce((state) => {
            state.approveStrategicPlanError = err;
          })
        );
      })
      .finally(() => {
        set(
          produce((state) => {
            state.approvingStrategicPlan = false;
          })
        );
      });
  },

  async rejectStrategicPlan(id) {
    set(
      produce((state) => {
        state.rejectingStrategicPlan = true;
      })
    );
    await axios({
      method: "PATCH",
      url: `/programs-mgt/strategic-plans/reject/${id}`,
    })
      .then((res) => {
        set(
          produce((state) => {
            state.rejectedStrategicPlan = true;
            state.strategicPlans = state.strategicPlans.map((plan) =>
              plan.id === id ? res.data : plan
            );
          })
        );
      })
      .catch((err) => {
        set(
          produce((state) => {
            state.rejectStrategicPlanError = err;
          })
        );
      })
      .finally(() => {
        set(
          produce((state) => {
            state.rejectingStrategicPlan = false;
          })
        );
      });
  },

  async undoStrategicPlanApproval(id) {
    set(
      produce((state) => {
        state.undoingStrategicPlanApproval = true;
      })
    );
    await axios({
      method: "PATCH",
      url: `/programs-mgt/strategic-plans/undo-approval/${id}`,
    })
      .then((res) => {
        set(
          produce((state) => {
            state.undoneStrategicPlanApproval = true;
            state.strategicPlans = state.strategicPlans.map((plan) =>
              plan.id === id ? res.data : plan
            );
          })
        );
      })
      .catch((err) => {
        set(
          produce((state) => {
            state.undoStrategicPlanApprovalError = err;
          })
        );
      })
      .finally(() => {
        set(
          produce((state) => {
            state.undoingStrategicPlanApproval = false;
          })
        );
      });
  },

  async createStrategicPlanActivity(data) {
    set(
      produce((state) => {
        state.creatingStrategicPlanActivity = true;
        state.createdStrategicPlanActivity = null;
      })
    );
    await axios({
      method: "POST",
      url: "/programs-mgt/strategic-plans/activities",
      data,
    })
      .then((res) => {
        set(
          produce((state) => {
            state.createdStrategicPlanActivity = res.data;
            state.strategicPlanDetails.strategicPlansProposedActivities.push(
              res.data
            );
          })
        );
      })
      .catch((err) => {
        set(
          produce((state) => {
            state.createStrategicPlanActivityError = err;
          })
        );
      })
      .finally(() => {
        set(
          produce((state) => {
            state.creatingStrategicPlanActivity = false;
          })
        );
      });
  },

  async updateStrategicPlanActivity(id, data) {
    set(
      produce((state) => {
        state.updatingStrategicPlanActivity = true;
      })
    );
    await axios({
      method: "PUT",
      url: `/programs-mgt/strategic-plans/activities/${id}`,
      data,
    })
      .then((res) => {
        set(
          produce((state) => {
            state.updatedStrategicPlanActivity = true;
            state.strategicPlanDetails.strategicPlansProposedActivities =
              state.strategicPlanDetails.strategicPlansProposedActivities.map(
                (activity) => (activity.id === id ? res.data : activity)
              );
          })
        );
        get().setAddingStrategicPlanActivity();
      })
      .catch((err) => {
        set(
          produce((state) => {
            state.updateStrategicPlanActivityError = err;
          })
        );
      })
      .finally(() => {
        set(
          produce((state) => {
            state.updatingStrategicPlanActivity = false;
          })
        );
      });
  },

  async deleteStrategicPlanActivity(id) {
    set(
      produce((state) => {
        state.deletingStrategicPlanActivity = true;
      })
    );
    await axios({
      method: "DELETE",
      url: `/programs-mgt/strategic-plans/activities/${id}`,
    })
      .then((res) => {
        set(
          produce((state) => {
            state.deletedStrategicPlanActivity = true;
            state.strategicPlanDetails.strategicPlansProposedActivities =
              state.strategicPlanDetails.strategicPlansProposedActivities.filter(
                (activity) => activity.id !== id
              );
          })
        );
      })
      .catch((err) => {
        set(
          produce((state) => {
            state.deleteStrategicPlanActivityError = err;
          })
        );
      })
      .finally(() => {
        set(
          produce((state) => {
            state.deletingStrategicPlanActivity = false;
          })
        );
      });
  },

  async createStrategicPlanActivityIndicator(data) {
    set(
      produce((state) => {
        state.creatingStrategicPlanActivityIndicator = true;
        state.createdStrategicPlanActivityIndicator = null;
      })
    );
    await axios({
      method: "POST",
      url: "/programs-mgt/strategic-plans/activities/indicators",
      data,
    })
      .then((res) => {
        set(
          produce((state) => {
            state.createdStrategicPlanActivityIndicator = res.data;
          })
        );
        get().getStrategicPlanDetails(get().strategicPlanDetails.id);
      })
      .catch((err) => {
        set(
          produce((state) => {
            state.createStrategicPlanActivityIndicatorError = err;
          })
        );
      })
      .finally(() => {
        set(
          produce((state) => {
            state.creatingStrategicPlanActivityIndicator = false;
          })
        );
      });
  },

  async deleteStrategicPlanThematicArea(id) {
    set(
      produce((state) => {
        state.deletingStrategicPlanThematicArea = true;
      })
    );
    await axios({
      method: "DELETE",
      url: `/programs-mgt/strategic-plans/thematic-areas/${id}`,
    })
      .then((res) => {
        set(
          produce((state) => {
            state.deletedStrategicPlanThematicArea = true;
            state.strategicPlanDetails.strategicPlanThematicAreas =
              state.strategicPlanDetails.strategicPlanThematicAreas.filter(
                (area) => area.id !== id
              );
          })
        );
      })
      .catch((err) => {
        set(
          produce((state) => {
            state.deleteStrategicPlanThematicAreaError = err;
          })
        );
      })
      .finally(() => {
        set(
          produce((state) => {
            state.deletingStrategicPlanThematicArea = false;
          })
        );
      });
  },

  async updateStrategicPlanActivityIndicator(id, data) {
    set(
      produce((state) => {
        state.updatingStrategicPlanActivityIndicator = true;
      })
    );
    await axios({
      method: "PUT",
      url: `/programs-mgt/strategic-plans/activities/indicators/${id}`,
      data,
    })
      .then((res) => {
        set(
          produce((state) => {
            state.updatedStrategicPlanActivityIndicator = true;
            state.strategicPlanDetails.strategicPlansProposedActivitiesIndicators =
              state.strategicPlanDetails.strategicPlansProposedActivitiesIndicators.map(
                (indicator) => (indicator.id === id ? res.data : indicator)
              );
          })
        );
      })
      .catch((err) => {
        set(
          produce((state) => {
            state.updateStrategicPlanActivityIndicatorError = err;
          })
        );
      })
      .finally(() => {
        set(
          produce((state) => {
            state.updatingStrategicPlanActivityIndicator = false;
          })
        );
      });
  },

  async deleteStrategicPlanActivityIndicator(id) {
    set(
      produce((state) => {
        state.deletingStrategicPlanActivityIndicator = true;
      })
    );
    await axios({
      method: "DELETE",
      url: `/programs-mgt/strategic-plans/activities/indicators/${id}`,
    })
      .then((res) => {
        set(
          produce((state) => {
            state.deletedStrategicPlanActivityIndicator = true;
            state.strategicPlanDetails.strategicPlansProposedActivitiesIndicators =
              state.strategicPlanDetails.strategicPlansProposedActivitiesIndicators.filter(
                (indicator) => indicator.id !== id
              );
          })
        );
      })
      .catch((err) => {
        set(
          produce((state) => {
            state.deleteStrategicPlanActivityIndicatorError = err;
          })
        );
      })
      .finally(() => {
        set(
          produce((state) => {
            state.deletingStrategicPlanActivityIndicator = false;
          })
        );
      });
  },

  async createStrategicPlanThematicArea(data) {
    set(
      produce((state) => {
        state.creatingStrategicPlanThematicArea = true;
      })
    );
    await axios({
      method: "POST",
      url: "/programs-mgt/strategic-plans/thematic-areas",
      data,
    })
      .then((res) => {
        set(
          produce((state) => {
            state.createdStrategicPlanThematicArea = true;
            state.strategicPlanDetails.strategicPlansThematicAreas.push(
              res.data
            );
          })
        );
      })
      .then((err) => {
        set(
          produce((state) => {
            state.createStrategicPlanThematicAreaError = err;
          })
        );
      })
      .catch((err) => {
        set(
          produce((state) => {
            state.createStrategicPlanThematicAreaError = err;
          })
        );
      })
      .finally(() => {
        set(
          produce((state) => {
            state.creatingStrategicPlanThematicArea = false;
          })
        );
      });
  },

  async updateStrategicPlanThematicArea(id, data) {
    set(
      produce((state) => {
        state.updatingStrategicPlanThematicArea = true;
      })
    );
    await axios({
      method: "PUT",
      url: `/programs-mgt/strategic-plans/thematic-areas/${id}`,
      data,
    })
      .then((res) => {
        set(
          produce((state) => {
            state.updatedStrategicPlanThematicArea = true;
            state.strategicPlanDetails.updatedStrategicPlanThematicArea =
              state.strategicPlanDetails.updatedStrategicPlanThematicArea.map(
                (area) => (area.id === id ? res.data : area)
              );
          })
        );
        get().setShowAddStrategicPlanThematicArea();
      })
      .catch((err) => {
        set(
          produce((state) => {
            state.updateStrategicPlanThematicAreaError = err;
          })
        );
      })
      .finally(() => {
        set(
          produce((state) => {
            state.updatingStrategicPlanThematicArea = false;
          })
        );
      });
  },

  setCreating: (creating = false, show = false, updateData = {}) => {
    set(
      produce((state) => {
        state.creating = creating;
        state.showCreateStrategicPlan = show;
        state.strategicPlanToUpdate = updateData;
      })
    );
  },

  setAddingStrategicPlanActivity: (
    creating = false,
    show = false,
    updateData
  ) => {
    set(
      produce((state) => {
        state.addingStrategicPlanActivity = creating;
        state.showAddStrategicPlanActivity = show;
        state.strategicPlanActivityToUpdate = updateData;
      })
    );
  },

  setAddingStrategicPlanActivityIndicator: (
    creating = false,
    show = false,
    updateData
  ) => {
    set(
      produce((state) => {
        state.addingStrategicPlanActivityIndicator = creating;
        state.showAddStrategicPlanActivityIndicator = show;
        state.strategicPlanActivityIndicatorToUpdate = updateData;
      })
    );
  },

  setShowViewStrategicPlanDetails: (show = false) => {
    set(
      produce((state) => {
        state.showStrategicPlanDetails = show;
      })
    );
  },

  setShowAddStrategicPlanThematicArea: (
    creating = false,
    show = false,
    updateData = {}
  ) => {
    set(
      produce((state) => {
        state.showAddStrategicPlanThematicArea = show;
        state.addingStrategicPlanThematicArea = creating;
        state.thematicAreaToUpdate = updateData;
      })
    );
  },

  setShowViewThemeAreaDetails: (data = { open: false }) => {
    set(
      produce((state) => {
        state.showViewThemeAreaDetails = data;
      })
    );
  },

  setActivityToAddIndicators: (data = {}) => {
    set(
      produce((state) => {
        state.activityToAddIndicators = data;
      })
    );
  },
});

const storeName = "STRATEGIC_PLANS_STORE";

const useStrategicPlansStore = create(devtools(store, { name: storeName }), {
  name: storeName,
});

export default useStrategicPlansStore;
