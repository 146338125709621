import { Button, Form, Input, Modal, Select } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";
import permissionsThunks from "../../../../config/thunks/settings/permissions.thunks";
import AppInput from "../../../common/AppInput";
import { map } from "lodash";

const { Option } = Select;

const AddStaffRecordsForm = ({ handleAddStaffRecord }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  //use selector to get state from redux state
  const {
    success: rolesSuccess,
    loading: rolesLoading,
    data: rolesArray,
  } = useSelector((state) => state.permissionsState.roles);
  const { success, loading } = useSelector(
    (state) => state.staffRecordsState.addStaffRecords
  );
  const { loading: usersLoading, data: users } = useSelector(
    (state) => state.staffRecordsState.staffRecords
  );
  const visible = useSelector(
    (state) => state.AppUi.admisnistration.staffRecords.addStaffModal
  );
  //submit form
  const onFinish = (values) => {
    values.createdAt = new Date();
    handleAddStaffRecord(values);
  };
  //close modal
  const closeAddRoleModal = () => {
    dispatch(appUiActions.toggleAddStaffRecordsModal(false));
  };
  //use effect
  useEffect(() => {
    if (success) form.resetFields();
    if (!rolesSuccess) dispatch(permissionsThunks.getRoles());
  }, [success, form, rolesSuccess, dispatch]);

  return (
    <div>
      <Modal
        onCancel={closeAddRoleModal}
        open={visible}
        title="ADD NEW STAFF"
        footer={null}
        width={800}
      >
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          validateTrigger="onBlur"
          layout="horizontal"
          form={form}
          onFinish={onFinish}
        >
          <div className="d-md-flex">
            <div className="w-50 w-sm-100">
              <Form.Item
                name="first_name"
                label="First Name"
                rules={[{ required: true, message: "Please add a first name" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="other_names"
                label="Other_names"
                rules={[{ required: true, message: "Please add a first name" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Gender"
                name="gender"
                rules={[{ required: true, message: "Please add a location" }]}
              >
                <Select>
                  <Option value="M">Male</Option>
                  <Option value="F">Female</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Contact"
                name="contact"
                rules={[
                  {
                    required: true,
                    message: "Please  add a contact",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            <div className="w-50 w-sm-100">
              <Form.Item
                label="Department"
                name="department"
                rules={[{ required: true, message: "Please add a department" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "Please add an email" },
                  {
                    pattern:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Please enter a valid email",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <AppInput
                name="supervised_by"
                label="Supervised By"
                rules={[
                  {
                    required: true,
                    message: "Please a person to supervise the user",
                  },
                ]}
                type="select"
                inputAttributes={{
                  loading: usersLoading,
                }}
                options={map(users, (user) => {
                  return {
                    value: user.id,
                    label: `${user.first_name} ${user.other_names}`,
                  };
                })}
              />
              <Form.Item
                rules={[{ required: true, message: "Please select a role" }]}
                name="role_id"
                label="Role"
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  loading={rolesLoading}
                >
                  {rolesArray.map((e, idx) => {
                    return (
                      <Option value={e.id} key={idx}>
                        {e.role}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
          </div>
          <Form.Item className={"mt-3"} wrapperCol={{ offset: 4, span: 16 }}>
            <Button loading={loading} htmlType="submit" type="primary">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddStaffRecordsForm;
