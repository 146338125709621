export const assetRegisterConstants = {
  FETCH_ASSETS_REQUEST: "FETCH_ASSET_REGISTER_REQUEST",
  FETCH_ASSETS_ERROR: "FETCH_ASSET_REGISTER_ERROR",
  FETCH_ASSETS_SUCCESS: "FETCH_ASSET_REGISTER_SUCCESS",

  SET_ASSET_REGISTER_GRAPH_DATA_REQUEST:
    "SET_ASSET_REGISTER_GRAPH_DATA_REGISTER_REQUEST",
  SET_ASSET_REGISTER_GRAPH_DATA_ERROR:
    "SET_ASSET_REGISTER_GRAPH_DATA_REGISTER_ERROR",
  SET_ASSET_REGISTER_GRAPH_DATA_SUCCESS:
    "SET_ASSET_REGISTER_GRAPH_DATA_REGISTER_SUCCESS",

  SET_ASSET_DETAILS_REQUEST: "SET_ASSET_DETAILS_REQUEST",
  SET_ASSET_DETAILS_SUCCESS: "SET_ASSET_DETAILS_SUCCESS",
  SET_ASSET_DETAILS_ERROR: "SET_ASSET_DETAILS_ERROR",

  STAFF_RECORDS_FORM_DATA_REQUEST: "STAFF_RECORDS_FORM_DATA_REQUEST",
  STAFF_RECORDS_FORM_DATA: "STAFF_RECORDS_FORM_DATA",

  ADD_ASSET_REQUEST: "ADD_ASSET_REQUEST",
  ADD_ASSET_ERROR: "ADD_ASSET_ERROR",
  ADD_ASSET_SUCCESS: "ADD_ASSET_SUCCESS",
  ADD_ASSET_COMPLETED: "ADD_ASSET_COMPLETED",

  UPDATE_ASSET_REQUEST: "UPDATE_ASSET_REQUEST",
  UPDATE_ASSET_ERROR: "UPDATE_ASSET_ERROR",
  UPDATE_ASSET_SUCCESS: "UPDATE_ASSET_SUCCESS",
  SET_UPDATE_ASSET_DATA: "SET_UPDATE_ASSET_DATA",
  UPDATE_ASSET_COMPLETED: "UPDATE_ASSET_COMPLETED",

  DELETE_ASSET_REQUEST: "DELETE_ASSET_REQUEST",
  DELETE_ASSET_ERROR: "DELETE_ASSET_ERROR",
  DELETE_ASSET_SUCCESS: "DELETE_ASSET_SUCCESS",
  DELETE_ASSET_COMPLETED: "DELETE_ASSET_COMPLETED",

  ADD_ASSET_CATEGORIES_REQUEST: "ADD_ASSET_CATEGORIES_REQUEST",
  ADD_ASSET_CATEGORIES_ERROR: "ADD_ASSET_CATEGORIES_ERROR",
  ADD_ASSET_CATEGORIES_SUCCESS: "ADD_ASSET_CATEGORIES_SUCCESS",
  ADD_ASSET_CATGORY_COMPLETED: "ADD_ASSET_CATGORY_COMPLETED",

  DELETE_ASSET_CATEGORY_REQUEST: "DELETE_ASSET_CATEGORY_REQUEST",
  DELETE_ASSET_CATEGORY_ERROR: "DELETE_ASSET_CATEGORY_ERROR",
  DELETE_ASSET_CATEGORY_SUCCESS: "DELETE_ASSET_CATEGORY_SUCCESS",
  DELETE_ASSET_CATEGORY_COMPLETED: "DELETE_ASSET_CATEGORY_COMPLETED",

  UPDATE_ASSET_CATEGORY_REQUEST: "UPDATE_ASSET_CATEGORY_REQUEST",
  UPDATE_ASSET_CATEGORY_ERROR: "UPDATE_ASSET_CATEGORY_ERROR",
  UPDATE_ASSET_CATEGORY_SUCCESS: "UPDATE_ASSET_CATEGORY_SUCCESS",
  UPDATE_ASSET_CATEGORY_COMPLETED: "UPDATE_ASSET_CATEGORY_COMPLETED",
  SET_UPDATE_ASSET_CATEGORY_DATA: "SET_UPDATE_ASSET_CATEGORY_DATA",

  FETCH_ASSET_CATEGORY_REQUEST: "FETCH_ASSET_CATEGORY_REQUEST",
  FETCH_ASSET_CATEGORY_ERROR: "FETCH_ASSET_CATEGORY_ERROR",
  FETCH_ASSET_CATEGORY_SUCCESS: "FETCH_ASSET_CATEGORY_SUCCESS",

  SET_ASSET_TYPES_DETAILS_REQUEST: "SET_ASSET_TYPES_TYPES_DETAILS_REQUEST",
  SET_ASSET_TYPES_DETAILS_SUCCESS: "SET_ASSET_TYPES_TYPES_DETAILS_SUCCESS",
  SET_ASSET_TYPES_DETAILS_ERROR: "SET_ASSET_TYPES_TYPES_DETAILS_ERROR",

  APPROVE_ASSET_REQUEST: "APPROVE_ASSET_REQUEST",
  APPROVE_ASSET_SUCCESS: "APPROVE_ASSET_SUCCESS",
  APPROVE_ASSET_ERROR: "APPROVE_ASSET_ERROR",
  APPROVE_ASSET_COMPLETED: "APPROVE_ASSET_COMPLETED",

  APPROVE_ASSET_TYPE_REQUEST: "APPROVE_ASSET_TYPE_REQUEST",
  APPROVE_ASSET_TYPE_SUCCESS: "APPROVE_ASSET_TYPE_SUCCESS",
  APPROVE_ASSET_TYPE_ERROR: "APPROVE_ASSET_TYPE_ERROR",
  APPROVE_ASSET_TYPE_COMPLETED: "APPROVE_ASSET_TYPE_COMPLETED",

  CHECK_ASSET_REQUEST: "CHECK_ASSET_REQUEST",
  CHECK_ASSET_SUCCESS: "CHECK_ASSET_SUCCESS",
  CHECK_ASSET_ERROR: "CHECK_ASSET_ERROR",
  CHECK_ASSET_COMPLETED: "CHECK_ASSET_COMPLETED",

  CHECK_ASSET_TYPE_REQUEST: "CHECK_ASSET_TYPE_REQUEST",
  CHECK_ASSET_TYPE_SUCCESS: "CHECK_ASSET_TYPE_SUCCESS",
  CHECK_ASSET_TYPE_ERROR: "CHECK_ASSET_TYPE_ERROR",
  CHECK_ASSET_TYPE_COMPLETED: "CHECK_ASSET_TYPE_COMPLETED",
};

export const assetRegisterActions = {
  fetchAssetsRequest: () => ({
    type: assetRegisterConstants.FETCH_ASSETS_REQUEST,
  }),
  fetchAssetsSuccess: (payload) => ({
    type: assetRegisterConstants.FETCH_ASSETS_SUCCESS,
    payload,
  }),
  fetchAssetsError: (payload) => ({
    type: assetRegisterConstants.FETCH_ASSETS_ERROR,
    payload,
  }),
  setAssetRegisterGraphDataRequest: () => ({
    type: assetRegisterConstants.SET_ASSET_REGISTER_GRAPH_DATA_REQUEST,
  }),
  setAssetRegisterGraphDataSuccess: (payload) => ({
    type: assetRegisterConstants.SET_ASSET_REGISTER_GRAPH_DATA_SUCCESS,
    payload,
  }),
  setAssetRegisterGraphDataError: (payload) => ({
    type: assetRegisterConstants.SET_ASSET_REGISTER_GRAPH_DATA_ERROR,
    payload,
  }),
  addAssetRequest: () => ({
    type: assetRegisterConstants.ADD_ASSET_REQUEST,
  }),
  addAssetError: (payload) => ({
    type: assetRegisterConstants.ADD_ASSET_ERROR,
    payload,
  }),
  addAssetSuccess: (payload) => ({
    type: assetRegisterConstants.ADD_ASSET_SUCCESS,
    payload,
  }),
  addAssetCompleted: () => ({
    type: assetRegisterConstants.ADD_ASSET_COMPLETED,
  }),
  deleteAssetRequest: () => ({
    type: assetRegisterConstants.DELETE_ASSET_REQUEST,
  }),
  deleteAssetError: (payload) => ({
    type: assetRegisterConstants.DELETE_ASSET_ERROR,
    payload,
  }),
  deleteAssetSuccess: (payload) => ({
    type: assetRegisterConstants.DELETE_ASSET_SUCCESS,
    payload,
  }),
  deleteAssetCompleted: () => ({
    type: assetRegisterConstants.DELETE_ASSET_COMPLETED,
  }),
  updateAssetCompleted: () => ({
    type: assetRegisterConstants.UPDATE_ASSET_COMPLETED,
  }),
  updateAssetRequest: () => ({
    type: assetRegisterConstants.UPDATE_ASSET_REQUEST,
  }),
  updateAssetError: (payload) => ({
    type: assetRegisterConstants.UPDATE_ASSET_ERROR,
    payload,
  }),
  updateAssetSuccess: (payload) => ({
    type: assetRegisterConstants.UPDATE_ASSET_SUCCESS,
    payload,
  }),
  setUpdateAssetData: (payload) => ({
    payload,
    type: assetRegisterConstants.SET_UPDATE_ASSET_DATA,
  }),
  addAssetCategoryRequest: () => ({
    type: assetRegisterConstants.ADD_ASSET_CATEGORIES_REQUEST,
  }),
  addAssetCategoryError: (payload) => ({
    type: assetRegisterConstants.ADD_ASSET_CATEGORIES_ERROR,
    payload,
  }),
  addAssetCategorySuccess: (payload) => ({
    type: assetRegisterConstants.ADD_ASSET_CATEGORIES_SUCCESS,
    payload,
  }),
  addAssetCategoryCompleted: () => ({
    type: assetRegisterConstants.ADD_ASSET_CATGORY_COMPLETED,
  }),
  updateAssetCategoryRequest: () => ({
    type: assetRegisterConstants.UPDATE_ASSET_CATEGORY_REQUEST,
  }),
  updateAssetCategoryError: (payload) => ({
    type: assetRegisterConstants.UPDATE_ASSET_CATEGORY_ERROR,
    payload,
  }),
  updateAssetCategorySuccess: (payload) => ({
    type: assetRegisterConstants.UPDATE_ASSET_CATEGORY_SUCCESS,
    payload,
  }),
  setUpdateAssetCategoryData: (payload) => ({
    payload,
    type: assetRegisterConstants.SET_UPDATE_ASSET_CATEGORY_DATA,
  }),
  updateAssetCategoryCompleted: () => ({
    type: assetRegisterConstants.UPDATE_ASSET_CATEGORY_COMPLETED,
  }),
  deleteAssetCategoryRequest: () => ({
    type: assetRegisterConstants.DELETE_ASSET_CATEGORY_REQUEST,
  }),
  deleteAssetCategoryError: (payload) => ({
    type: assetRegisterConstants.DELETE_ASSET_CATEGORY_ERROR,
    payload,
  }),
  deleteAssetCategorySuccess: (payload) => ({
    type: assetRegisterConstants.DELETE_ASSET_CATEGORY_SUCCESS,
    payload,
  }),
  deleteAssetCategoryCompleted: () => ({
    type: assetRegisterConstants.DELETE_ASSET_CATEGORY_COMPLETED,
  }),
  fetchAssetsCategoryRequest: () => ({
    type: assetRegisterConstants.FETCH_ASSET_CATEGORY_REQUEST,
  }),
  fetchAssetsCategorySuccess: (payload) => ({
    type: assetRegisterConstants.FETCH_ASSET_CATEGORY_SUCCESS,
    payload,
  }),
  fetchAssetsCategoryError: (payload) => ({
    type: assetRegisterConstants.FETCH_ASSET_CATEGORY_ERROR,
    payload,
  }),
  setAssetTypesDetailsRequest: () => ({
    type: assetRegisterConstants.SET_ASSET_TYPES_DETAILS_REQUEST,
  }),
  setAssetTypesDetailsSuccess: (payload) => ({
    type: assetRegisterConstants.SET_ASSET_TYPES_DETAILS_SUCCESS,
    payload,
  }),
  setAssetTypesDetailsError: (payload) => ({
    type: assetRegisterConstants.SET_ASSET_TYPES_DETAILS_ERROR,
    payload,
  }),
  setAssetDetailsRequest: () => ({
    type: assetRegisterConstants.SET_ASSET_DETAILS_REQUEST,
  }),
  setAssetDetailsSuccess: (payload) => ({
    type: assetRegisterConstants.SET_ASSET_DETAILS_SUCCESS,
    payload,
  }),
  setAssetDetailsError: (payload) => ({
    type: assetRegisterConstants.SET_ASSET_DETAILS_ERROR,
    payload,
  }),
  approveAssetRequest: () => ({
    type: assetRegisterConstants.APPROVE_ASSET_REQUEST,
  }),
  approveAssetSuccess: (payload) => ({
    type: assetRegisterConstants.APPROVE_ASSET_SUCCESS,
    payload,
  }),
  approveAssetError: (payload) => ({
    type: assetRegisterConstants.APPROVE_ASSET_ERROR,
    payload,
  }),
  approveAssetCompleted: () => ({
    type: assetRegisterConstants.APPROVE_ASSET_COMPLETED,
  }),
  approveAssetTypeRequest: () => ({
    type: assetRegisterConstants.APPROVE_ASSET_TYPE_REQUEST,
  }),
  approveAssetTypeSuccess: (payload) => ({
    type: assetRegisterConstants.APPROVE_ASSET_TYPE_SUCCESS,
    payload,
  }),
  approveAssetTypeError: (payload) => ({
    type: assetRegisterConstants.APPROVE_ASSET_TYPE_ERROR,
    payload,
  }),
  approveAssetTypeCompleted: () => ({
    type: assetRegisterConstants.APPROVE_ASSET_TYPE_COMPLETED,
  }),
  checkAssetRequest: () => ({
    type: assetRegisterConstants.CHECK_ASSET_REQUEST,
  }),
  checkAssetSuccess: (payload) => ({
    type: assetRegisterConstants.CHECK_ASSET_SUCCESS,
    payload,
  }),
  checkAssetError: (payload) => ({
    type: assetRegisterConstants.CHECK_ASSET_ERROR,
    payload,
  }),
  checkAssetCompleted: () => ({
    type: assetRegisterConstants.CHECK_ASSET_COMPLETED,
  }),
  checkAssetTypesRequest: () => ({
    type: assetRegisterConstants.CHECK_ASSET_TYPE_REQUEST,
  }),
  checkAssetTypesSuccess: (payload) => ({
    type: assetRegisterConstants.CHECK_ASSET_TYPE_SUCCESS,
    payload,
  }),
  checkAssetTypesError: (payload) => ({
    type: assetRegisterConstants.CHECK_ASSET_TYPE_ERROR,
    payload,
  }),
  checkAssetTypesCompleted: () => ({
    type: assetRegisterConstants.CHECK_ASSET_TYPE_COMPLETED,
  }),
  staffRecordsFormDataRequest: () => ({
    type: assetRegisterConstants.STAFF_RECORDS_FORM_DATA_REQUEST,
  }),
  staffRecordsFormData: (payload) => ({
    type: assetRegisterConstants.STAFF_RECORDS_FORM_DATA,
    payload,
  }),
};
