import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Popover, Space, Input, Select } from "antd";
import { FcExport, FcRefresh } from "react-icons/fc";
import { AiOutlinePlus, AiOutlineSearch } from "react-icons/ai";

const TableTitle = ({
  search = () => null,
  openAddModal = () => null,
  setFilterTableNull = () => null,
  refreshTable,
  exportRecords,
  searchOptions,
  searchLoading,
  extraActions,
  addNewText,
}) => {
  const [selectedSearchField, setSelectedSearchField] = useState("");
  const [searchSelect, setSearchSelect] = useState("");

  const changeSearchSelect = (value) => setSearchSelect(value);

  const changeSelectedField = (value) => {
    setSelectedSearchField(value);
    setSearchSelect("");
  };

  const searchRecords = (value) => search(value, selectedSearchField);
  const searchSelectButton = () => search(searchSelect, selectedSearchField);

  const onChangeInput = (e) => {
    if (e.target.value.length < 1) setFilterTableNull(null);
  };

  const SearchOptionsComponent = (
    <div className="d-flex flex-row w-100">
      <Select
        placeholder="Search By"
        className="w-25"
        onChange={changeSelectedField}
        allowClear
      >
        {searchOptions?.options?.map((option) => (
          <Select.Option value={option.id} key={option.id}>
            {option.value}
          </Select.Option>
        ))}
      </Select>
      {typeof searchOptions?.[selectedSearchField] === "object" ? (
        <div className="d-flex flex-row w-75">
          <Select
            allowClear
            className="w-100"
            placeholder="search"
            onChange={changeSearchSelect}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {searchOptions[selectedSearchField]?.map((field) => (
              <Select.Option key={field.id}>{field.value}</Select.Option>
            ))}
          </Select>
          <Button
            onClick={searchSelectButton}
            type="primary"
            loading={searchLoading}
          >
            <AiOutlineSearch />
          </Button>
        </div>
      ) : (
        <Input.Search
          className="w-75"
          onChange={onChangeInput}
          placeholder="Search..."
          enterButton
          loading={searchLoading}
          onSearch={searchRecords}
        ></Input.Search>
      )}
    </div>
  );

  const titleAction = (
    <Space direction="vertical">
      {refreshTable && (
        <Button className="w-100" onClick={refreshTable}>
          <Space>
            <FcRefresh /> Reload
          </Space>
        </Button>
      )}
      {exportRecords && (
        <Button onClick={exportRecords} className="bg-light w-100">
          <Space>
            <FcExport />
            Export
          </Space>
        </Button>
      )}
      {extraActions}
      <Popover
        content={() => (
          <div className="w-100">
            {searchOptions ? (
              SearchOptionsComponent
            ) : (
              <Input.Search
                onChange={onChangeInput}
                placeholder="Search"
                enterButton
                onSearch={search}
              ></Input.Search>
            )}
          </div>
        )}
      >
        {search && (
          <Button className="w-100 d-md-none">
            <Space>
              <AiOutlineSearch /> Search
            </Space>
          </Button>
        )}
      </Popover>
    </Space>
  );
  return (
    <div className="d-flex w-100">
      <Space className="w-50" direction="horizontal">
        {openAddModal && (
          <Button onClick={openAddModal} type="primary">
            <Space>
              <AiOutlinePlus /> {addNewText ? addNewText : "ADD NEW"}
            </Space>
          </Button>
        )}
        <Popover content={titleAction} placement="bottom">
          <Button>Actions</Button>
        </Popover>
      </Space>
      {search && (
        <div className="w-50 d-none d-md-block">
          {searchOptions ? (
            SearchOptionsComponent
          ) : (
            <Input.Search
              onChange={onChangeInput}
              placeholder="Search..."
              enterButton
              onSearch={search}
            ></Input.Search>
          )}
        </div>
      )}
    </div>
  );
};

TableTitle.propTypes = {
  search: PropTypes.func,
  setFilterTableNull: PropTypes.func,
  refreshTable: PropTypes.func,
  exportRecords: PropTypes.func,
  openAddModal: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  searchOptions: PropTypes.object,
  searchLoading: PropTypes.bool,
  extraActions: PropTypes.any,
  addNewText: PropTypes.string,
  sort: PropTypes.func,
  sortOptions: PropTypes.any,
  sortData: PropTypes.object,
  sortLoading: PropTypes.bool,
};

export default TableTitle;
