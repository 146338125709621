const initialState = {
  noticeOfBestBidders: [],
  noticeOfBestBiddersLoading: false,
  noticeOfBestBiddersError: "",
  noticeOfBestBiddersSuccess: false,

  addNoticeOfBestBiddersLoading: false,
  addNoticeOfBestBiddersMessage: "",
  addNoticeOfBestBiddersSuccess: false,
  addNoticeOfBestBiddersError: "",

  noticeOfBestBiddersDetails: {},
  noticeOfBestBiddersDetailsSuccess: false,
  noticeOfBestBiddersDetailsLoading: false,
  noticeOfBestBiddersDetailsError: "",

  editNoticeOfBestBiddersSuccess: false,
  editNoticeOfBestBiddersLoading: false,
  editNoticeOfBestBiddersData: {},
  editNoticeOfBestBiddersError: "",
  editNoticeOfBestBiddersMessage: "",

  deleteNoticeOfBestBiddersLoading: false,
  deleteNoticeOfBestBiddersSuccess: false,
  deleteNoticeOfBestBiddersMessage: "",
  deleteNoticeOfBestBiddersError: "",

  approveNoticeOfBestBiddersLoading: false,
  approveNoticeOfBestBiddersError: "",
  approveNoticeOfBestBiddersSuccess: false,
  approveNoticeOfBestBiddersMessage: "",

  checkNoticeOfBestBiddersLoading: false,
  checkNoticeOfBestBiddersSuccess: false,
  checkNoticeOfBestBiddersMessage: "",
  checkNoticeOfBestBiddersError: "",

  noticeOfBestBiddersMetaDataLoading: false,
  noticeOfBestBiddersMetaData: {},
};

export default initialState;
