import React, { useMemo } from "react";
import { useAssetIncidentFormStore } from "../../../../../config/stores";
import dayjs from "dayjs";
import { capitalize, forEach } from "lodash";
import RecordStatus from "../../../../../components/common/RecordStatus";

const FormPrintOut = () => {
  const assetIncidentFormStore = useAssetIncidentFormStore();
  const { assetIncidentFormDetails } = assetIncidentFormStore;
  let natureOfSurvey = "";

  const natureOfSurveyCheckboxOptions = useMemo(
    () => [
      { label: "Wear", value: "wear", key: "wear" },
      {
        label: "Scheduled Replacement",
        value: "scheduled_replacement",
        key: "scheduled_replacement",
      },
      { label: "Surplus", value: "surplus", key: "surplus" },
      { label: "Loss", value: "loss", key: "loss" },
      { label: "Damage", value: "damage", key: "damage" },
      { label: "Theft", value: "theft" },
      {
        label: "Vehicle Accident",
        name: "vehicle_accident",
        value: "vehicle_accident",
        key: "vehicle_accident",
      },
    ],
    []
  );
  forEach(natureOfSurveyCheckboxOptions, (option) => {
    if (assetIncidentFormDetails[option.value] === true) {
      natureOfSurvey = capitalize(option.value?.replaceAll(/_/g, " "));
    }
  });

  const disposalOptions = useMemo(
    () => [
      { label: "Transfer", value: "transfer" },
      { label: "Destruction", value: "destruction" },
      { label: "Discard", value: "discard" },
      { label: "Re-use of spare parts", value: "reuse_of_spare_parts" },
      { label: "Trade off", value: "trade_off" },
      { label: "Sale", value: "sale" },
    ],
    []
  );

  let disposalOption = "";
  forEach(disposalOptions, (option) => {
    if (assetIncidentFormDetails[option.value] === true) {
      disposalOption = capitalize(option.value?.replaceAll(/_/g, " "));
    }
  });

  return (
    <div>
      <p className="text-center fw-bold text-uppercase">
        Request for Asset Repair, Disposal or Theft Investigation Form
      </p>
      <table className="table table-sm table-bordered">
        <tbody>
          <tr>
            <td className="w-25 fw-bold">Requesting Unit/Location:</td>
            <td className="w-25">
              {assetIncidentFormDetails?.asset?.location}
            </td>
            <td className="w-25 ">
              <div className="d-flex fw-bold align-items-center h-100">
                Date Of Incident
              </div>
            </td>
            <td className="w-25">
              {dayjs(assetIncidentFormDetails?.date_of_incident).format(
                "DD/MM/YYYY"
              )}
            </td>
          </tr>
          <tr>
            <td className="fw-bold w-25">Staff responsible of asset</td>
            <td className="w-25">
              {assetIncidentFormDetails?.staffWithAsset?.first_name}{" "}
              {assetIncidentFormDetails?.staffWithAsset?.other_names}
            </td>
            <td className="fw-bold w-25">Staff Contact</td>
            <td>{assetIncidentFormDetails?.staffWithAsset?.contact}</td>
          </tr>
          <tr>
            <td className="w-25 fw-bold">Asset Name</td>
            <td className="w-25">{assetIncidentFormDetails?.asset?.name}</td>
            <td className="w-25 fw-bold">Asset Code</td>
            <td className="w-25">{assetIncidentFormDetails?.asset?.code}</td>
          </tr>
          <tr>
            <td className="w-25 fw-bold">Year Of Purchase</td>
            <td className="w-25">
              {dayjs(assetIncidentFormDetails?.asset?.procurement_date).year()}
            </td>
            <td className="w-25 fw-bold">Purchase Value</td>
            <td className="w-25">
              UGX{" "}
              {assetIncidentFormDetails?.asset?.purchase_ammount?.toLocaleString()}
            </td>
          </tr>
          <tr>
            <td colSpan={2} className="w-50 fw-bold">
              Status
            </td>
            <td colSpan={2}>
              <RecordStatus status={assetIncidentFormDetails.status} />
            </td>
          </tr>
          <tr>
            <td colSpan={2} className="w-50 fw-bold">
              Nature Of Survey Case
            </td>
            <td colSpan={2}>
              {natureOfSurvey || assetIncidentFormDetails.others}
            </td>
          </tr>
          {assetIncidentFormDetails.recommendation === "repair" && (
            <tr>
              <td colSpan={2} className=" fw-bold">
                Estimated Repair Cost
              </td>
              <td colSpan={2} className="">
                UGX{" "}
                {assetIncidentFormDetails?.estimated_repair_cost?.toLocaleString()}
              </td>
            </tr>
          )}
          {assetIncidentFormDetails.recommendation === "disposal" && (
            <tr>
              <td colSpan={2} className=" fw-bold">
                Recommended Disposition
              </td>
              <td colSpan={2} className="">
                {disposalOption ||
                  assetIncidentFormDetails.other_disposal_option}
              </td>
            </tr>
          )}
          <tr>
            <td className="w-25 fw-bold">
              General Incidence Report and Recommendation{" "}
            </td>
            <td colSpan={3}>{assetIncidentFormDetails?.general_report}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default FormPrintOut;
