import { Button, message, Popover, Space } from "antd";
import React from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { FcExport, FcRefresh, FcSearch } from "react-icons/fc";

const ChartOfAccountsActions = ({
  refreshTable,
  handleExportRecords,
  openAddAccounts,
  openAddAccountTypes,
  openSearchModal,
}) => {
  const exportRecords = () => {
    message.loading("Proccessing document for export please wait ", 3);
    handleExportRecords();
  };
  const actions = (
    <Space direction="vertical">
      <Button onClick={exportRecords} className="bg-light w-100">
        <Space>
          <FcExport />
          Export
        </Space>
      </Button>
      <Button className="w-100" onClick={refreshTable}>
        <Space>
          <FcRefresh /> Refresh
        </Space>
      </Button>
      <Button className="w-100" onClick={openSearchModal}>
        <Space>
          <FcSearch /> Search
        </Space>
      </Button>
    </Space>
  );
  const addNew = (
    <Space direction="vertical">
      <Button onClick={openAddAccountTypes} className=" w-100">
        Account Type
      </Button>
      <Button className="w-100" onClick={openAddAccounts}>
        Account
      </Button>
    </Space>
  );
  return (
    <div className=" w-100">
      <Space className="" direction="horizontal">
        <Popover content={addNew} trigger="click" placement="bottom">
          <Button type="primary">
            <Space>
              <AiOutlinePlus /> Add New
            </Space>
          </Button>
        </Popover>
        <Popover content={actions} trigger="click" placement="bottom">
          <Button>Actions</Button>
        </Popover>
      </Space>
    </div>
  );
};

export default ChartOfAccountsActions;
