import { budgetConstants } from "../../../actions/finance/budget/budget.actions";
import initialState from "../../../initialState/finance/budget/budget";

const budgetState = (state = initialState, { type, payload }) => {
  switch (type) {
    case budgetConstants.SET_BUDGETS_LOADING:
      return {
        ...state,
        budgetsLoading: true,
      };
    case budgetConstants.SET_BUDGETS_SUCCESS:
      return {
        ...state,
        budgetsSuccess: true,
        budgetsLoading: false,
        budgetsError: "",
        budgets: payload,
      };
    case budgetConstants.SET_BUDGETS_ERROR:
      return {
        ...state,
        budgetsError: payload,
        budgetsLoading: false,
        budgetsSuccess: false,
      };
    case budgetConstants.SET_BUDGETS_GRAPH_DATA_LOADING:
      return {
        ...state,
        budgetsGraphDataLoading: true,
      };
    case budgetConstants.SET_BUDGETS_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        budgetsGraphDataSuccess: true,
        budgetsGraphDataLoading: false,
        budgetsGraphDataError: "",
        budgetsGraphData: payload,
      };
    case budgetConstants.SET_BUDGETS_GRAPH_DATA_ERROR:
      return {
        ...state,
        budgetsGraphDataError: payload,
        budgetsGraphDataLoading: false,
        budgetsGraphDataSuccess: false,
      };
    case budgetConstants.SET_APPROVED_BUDGET_LOADING:
      return {
        ...state,
        approvedBudgetLoading: true,
      };
    case budgetConstants.SET_APPROVED_BUDGET_SUCCESS:
      return {
        ...state,
        approvedBudgetSuccess: true,
        approvedBudgetLoading: false,
        approvedBudgetError: "",
        approvedBudget: payload,
      };
    case budgetConstants.SET_APPROVED_BUDGET_ERROR:
      return {
        ...state,
        approvedBudgetError: payload,
        approvedBudgetLoading: false,
        approvedBudgetSuccess: false,
        approvedBudget: [],
      };
    case budgetConstants.SET_BUDGET_MONITORING_STATEMENT_LOADING:
      return {
        ...state,
        budgetMonitoringStatementLoading: true,
      };
    case budgetConstants.SET_BUDGET_MONITORING_STATEMENT_SUCCESS:
      return {
        ...state,
        budgetMonitoringStatementSuccess: true,
        budgetMonitoringStatementLoading: false,
        budgetMonitoringStatementError: "",
        budgetMonitoringStatement: payload,
      };
    case budgetConstants.SET_BUDGET_MONITORING_STATEMENT_ERROR:
      return {
        ...state,
        budgetMonitoringStatementError: payload,
        budgetMonitoringStatementLoading: false,
        budgetMonitoringStatementSuccess: false,
        budgetMonitoringStatement: {},
      };
    case budgetConstants.SET_BUDGET_DETAILS_LOADING:
      return {
        ...state,
        budgetDetailsLoading: true,
      };
    case budgetConstants.SET_BUDGET_DETAILS_SUCCESS:
      return {
        ...state,
        budgetDetailsSuccess: true,
        budgetDetailsLoading: false,
        budgetDetailsError: "",
        budgetDetails: payload,
      };
    case budgetConstants.SET_BUDGET_DETAILS_ERROR:
      return {
        ...state,
        budgetsError: payload,
        budgetsLoading: false,
        budgetsSuccess: false,
      };
    case budgetConstants.SET_BUDGET_ACTIVITY_DETAILS_LOADING:
      return {
        ...state,
        budgetActivityDetailsLoading: true,
      };
    case budgetConstants.SET_BUDGET_ACTIVITY_DETAILS_SUCCESS:
      return {
        ...state,
        budgetActivityDetailsSuccess: true,
        budgetActivityDetailsLoading: false,
        budgetActivityDetailsError: "",
        budgetActivityDetails: payload,
      };
    case budgetConstants.SET_BUDGET_ACTIVITY_DETAILS_ERROR:
      return {
        ...state,
        budgetActivityDetailsError: payload,
        budgetActivityDetailsLoading: false,
        budgetActivityDetailsSuccess: false,
      };
    case budgetConstants.SET_BUDGET_LINE_DETAILS_LOADING:
      return {
        ...state,
        budgetLineDetailsLoading: true,
      };
    case budgetConstants.SET_BUDGET_LINE_DETAILS_SUCCESS:
      return {
        ...state,
        budgetLineDetailsSuccess: true,
        budgetLineDetailsLoading: false,
        budgetLineDetailsError: "",
        budgetLineDetails: payload,
      };
    case budgetConstants.SET_BUDGET_LINE_DETAILS_ERROR:
      return {
        ...state,
        budgetLineDetailsError: payload,
        budgetLineDetailsLoading: false,
        budgetLineDetailsSuccess: false,
      };
    case budgetConstants.ADD_BUDGET_LOADING:
      return {
        ...state,
        addBudgetLoading: true,
      };
    case budgetConstants.ADD_BUDGET_SUCCESS:
      return {
        ...state,
        addBudgetSuccess: true,
        addBudgetLoading: false,
        addBudgetError: "",
        addBudgetMessage: payload,
      };
    case budgetConstants.ADD_BUDGET_ERROR:
      return {
        ...state,
        addBudgetLoading: false,
        addBudgetSuccess: false,
        addBudgetError: payload,
      };
    case budgetConstants.ADD_BUDGET_COMPLETE:
      return {
        ...state,
        addBudgetSuccess: false,
      };
    case budgetConstants.ADD_BUDGET_LINE_LOADING:
      return {
        ...state,
        addBudgetLineLoading: true,
      };
    case budgetConstants.ADD_BUDGET_LINE_SUCCESS:
      return {
        ...state,
        addBudgetLineSuccess: true,
        addBudgetLineLoading: false,
        addBudgetLineError: "",
        addBudgetLineMessage: payload,
      };
    case budgetConstants.ADD_BUDGET_LINE_ERROR:
      return {
        ...state,
        addBudgetLineLoading: false,
        addBudgetLineSuccess: false,
        addBudgetLineError: payload,
      };
    case budgetConstants.ADD_BUDGET_LINE_COMPLETE:
      return {
        ...state,
        addBudgetLineSuccess: false,
      };
    case budgetConstants.ADD_BUDGET_ACTIVITY_LOADING:
      return {
        ...state,
        addBudgetactivityLoading: true,
      };
    case budgetConstants.ADD_BUDGET_ACTIVITY_SUCCESS:
      return {
        ...state,
        addBudgetActivitySuccess: true,
        addBudgetactivityLoading: false,
        addBudgetActitvityError: "",
        addBudgetActivityMessage: payload,
      };
    case budgetConstants.ADD_BUDGET_ACTIVITY_ERROR:
      return {
        ...state,
        addBudgetactivityLoading: false,
        addBudgetActivitySuccess: false,
        addBudgetActitvityError: payload,
      };
    case budgetConstants.ADD_BUDGET_ACTIVITY_COMPLETE:
      return {
        ...state,
        addBudgetActivitySuccess: false,
      };
    case budgetConstants.EDIT_BUDGET_LINE_LOADING:
      return {
        ...state,
        editBudgetLineLoading: true,
      };
    case budgetConstants.EDIT_BUDGET_LINE_SUCCESS:
      return {
        ...state,
        editBudgetLineSuccess: true,
        editBudgetLineLoading: false,
        editBudgetLineError: "",
        editBudgetLineMessage: payload,
      };
    case budgetConstants.EDIT_BUDGET_LINE_ERROR:
      return {
        ...state,
        editBudgetLineLoading: false,
        editBudgetLineSuccess: false,
        editBudgetLineError: payload,
      };
    case budgetConstants.EDIT_BUDGET_LINE_COMPLETE:
      return {
        ...state,
        editBudgetLineSuccess: false,
      };
    case budgetConstants.EDIT_BUDGET_LINE_DATA:
      return {
        ...state,
        editBudgetLineFormData: payload,
      };
    case budgetConstants.EDIT_BUDGET_ACTIVITY_LOADING:
      return {
        ...state,
        editBudgetActitvityLoading: true,
      };
    case budgetConstants.EDIT_BUDGET_ACTIVITY_SUCCESS:
      return {
        ...state,
        editBudgetActitvitySuccess: true,
        editBudgetActitvityLoading: false,
        editBudgetActitvityError: "",
        editBudgetActitvityMessage: payload,
      };
    case budgetConstants.EDIT_BUDGET_ACTIVITY_ERROR:
      return {
        ...state,
        editBudgetActitvityLoading: false,
        editBudgetActitvitySuccess: false,
        editBudgetActitvityError: payload,
      };
    case budgetConstants.EDIT_BUDGET_ACTIVITY_COMPLETE:
      return {
        ...state,
        editBudgetActitvitySuccess: false,
      };
    case budgetConstants.EDIT_BUDGET_ACTIVITY_DATA:
      return {
        ...state,
        editBudgetActitvityData: payload,
      };
    case budgetConstants.DELETE_BUDGET_LOADING:
      return {
        ...state,
        deleteBudgetLoading: true,
      };
    case budgetConstants.DELETE_BUDGET_SUCCESS:
      return {
        ...state,
        deleteBudgetSuccess: true,
        deleteBudgetLoading: false,
        deleteBudgetError: "",
        deleteBudgetMessage: payload.message,
        budgets: payload.data,
      };
    case budgetConstants.DELETE_BUDGET_ERROR:
      return {
        ...state,
        deleteBudgetLoading: false,
        deleteBudgetSuccess: false,
        deleteBudgetError: payload,
      };
    case budgetConstants.DELETE_BUDGET_COMPLETE:
      return {
        ...state,
        deleteBudgetSuccess: false,
        deleteBudgetError: "",
      };
    case budgetConstants.DELETE_BUDGET_LINE_LOADING:
      return {
        ...state,
        deleteBudgetLineLoading: true,
      };
    case budgetConstants.DELETE_BUDGET_LINE_SUCCESS:
      return {
        ...state,
        deleteBudgetLineSuccess: true,
        deleteBudgetLineLoading: false,
        deleteBudgetLineError: "",
        deleteBudgetLineMessage: payload,
      };
    case budgetConstants.DELETE_BUDGET_LINE_ERROR:
      return {
        ...state,
        deleteBudgetLineLoading: false,
        deleteBudgetLineSuccess: false,
        deleteBudgetLineError: payload,
      };
    case budgetConstants.DELETE_BUDGET_LINE_COMPLETE:
      return {
        ...state,
        deleteBudgetLineSuccess: false,
        deleteBudgetLineError: "",
      };
    case budgetConstants.DELETE_BUDGET_ACTIVITY_LOADING:
      return {
        ...state,
        deleteBudgetActivityLoading: true,
      };
    case budgetConstants.DELETE_BUDGET_ACTIVITY_SUCCESS:
      return {
        ...state,
        deleteBudgetActivitySuccess: true,
        deleteBudgetActivityLoading: false,
        deleteBudgetActivityError: "",
        deleteBudgetActivityMessage: payload,
      };
    case budgetConstants.DELETE_BUDGET_ACTIVITY_ERROR:
      return {
        ...state,
        deleteBudgetActivityLoading: false,
        deleteBudgetActivitySuccess: false,
        deleteBudgetActivityError: payload,
      };
    case budgetConstants.DELETE_BUDGET_ACTIVITY_COMPLETE:
      return {
        ...state,
        deleteBudgetActivitySuccess: false,
        deleteBudgetActivityError: "",
      };
    case budgetConstants.APPROVE_BUDGET_LOADING:
      return {
        ...state,
        approveBudgetLoading: true,
      };
    case budgetConstants.APPROVE_BUDGET_SUCCESS:
      return {
        ...state,
        approveBudgetSuccess: true,
        approveBudgetLoading: false,
        approveBudgetError: "",
        approveBudgetMessage: payload,
      };
    case budgetConstants.APPROVE_BUDGET_ERROR:
      return {
        ...state,
        approveBudgetLoading: false,
        approveBudgetSuccess: false,
        approveBudgetError: payload,
      };
    case budgetConstants.APPROVE_BUDGET_COMPLETE:
      return {
        ...state,
        approveBudgetSuccess: false,
        approveBudgetError: "",
      };
    case budgetConstants.CHECK_BUDGET_LOADING:
      return {
        ...state,
        checkBudgetLoading: true,
      };
    case budgetConstants.CHECK_BUDGET_SUCCESS:
      return {
        ...state,
        checkBudgetSuccess: true,
        checkBudgetLoading: false,
        checkBudgetError: "",
        checkBudgetMessage: payload,
      };
    case budgetConstants.CHECK_BUDGET_ERROR:
      return {
        ...state,
        checkBudgetLoading: false,
        checkBudgetSuccess: false,
        checkBudgetError: payload,
      };
    case budgetConstants.CHECK_BUDGET_COMPLETE:
      return {
        ...state,
        checkBudgetSuccess: false,
        checkBudgetError: "",
      };
    case budgetConstants.SUBMIT_BUDGET_LOADING:
      return {
        ...state,
        submitBudgetLoading: true,
      };
    case budgetConstants.SUBMIT_BUDGET_SUCCESS:
      return {
        ...state,
        submitBudgetSuccess: true,
        submitBudgetLoading: false,
        submitBudgetError: "",
        submitBudgetMessage: payload,
      };
    case budgetConstants.SUBMIT_BUDGET_ERROR:
      return {
        ...state,
        submitBudgetLoading: false,
        submitBudgetSuccess: false,
        submitBudgetError: payload,
      };
    case budgetConstants.SUBMIT_BUDGET_COMPLETE:
      return {
        ...state,
        submitBudgetSuccess: false,
        submitBudgetError: "",
      };
    case budgetConstants.META_DATA:
      return {
        ...state,
        metaDataLoading: false,
        metaData: payload,
      };
    case budgetConstants.META_DATA_LOADING:
      return {
        ...state,
        metaDataLoading: false,
      };
    default:
      return state;
  }
};

export default budgetState;
