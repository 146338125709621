import { Alert, Button, message, Popover, Space, Spin, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import DonorProjects from "../../../components/BusinessDevelopmentAndPartnerships/donors/Tables/DonorProjects";
import DonorsInfoDetails from "../../../components/BusinessDevelopmentAndPartnerships/donors/Tables/DonorsInfoDetails";
import { getIdFromPath } from "../../../config/helpers/app/url";
import donorsThunks from "../../../config/thunks/businessDevAndPartnerships/donors/donors.thunks";
import appConfig from "../../../config/config.json";
import AddRejections from "../../../components/shared/Rejections/AddRejections";
import { rejectionsActions } from "../../../config/actions/rejections/rejections.actions";
import SetPersonIncharge from "../../../components/BusinessDevelopmentAndPartnerships/donors/Forms/SetPersonIncharge";
import { appUiActions } from "../../../config/actions/app/appUi.actions";
import { FcRefresh } from "react-icons/fc";
import { AiOutlineArrowLeft } from "react-icons/ai";
import AppButton from "../../../components/common/AppButton";

const { TabPane } = Tabs;

const DonorsDetails = () => {
  //state,variable, decalrations and initialization
  const [donorId, setDonorId] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const id = getIdFromPath(history.location.pathname);
  const { loading, success, error, data } = useSelector(
    (state) => state.donorsState.donorDetails
  );
  const {
    loading: approveLoading,
    success: approveSuccess,
    error: approveError,
    message: approveMessage,
  } = useSelector((state) => state.donorsState.approveDonor);
  const {
    loading: checkLoading,
    success: checkSuccess,
    error: checkError,
    message: checkMessage,
  } = useSelector((state) => state.donorsState.checkDonor);
  const {
    submitProposalStatusLoading,
    submitProposalStatusSuccess,
    submitProposalStatusError,
    submitProposalStatusMessage,
  } = useSelector((state) => state.donorsState);
  const approveRecord = () => dispatch(donorsThunks.approveDonor(id));

  const checkRecord = () => dispatch(donorsThunks.checkDonor(id));
  const reject = () => {
    dispatch(
      rejectionsActions.toggleRejectionsModal({
        visible: true,
        data: {
          user_to_id: data?.created_by,
          item_application: "Donors",
          item_id: data?.id,
          itemName: data.name,
        },
      })
    );
  };
  const openSetPersonInchargeModal = () => {
    setDonorId(data.id);
    dispatch(appUiActions.toggleSetPersonInchargeModal(true));
  };
  const setPersonIncharge = (values) =>
    dispatch(donorsThunks.setPersonIncharge(values));
  const submitProposalStatus = (proposalStatus) => {
    const body = {
      proposalSubmittedAt: new Date(),
      proposal_status: proposalStatus,
      id,
    };
    dispatch(donorsThunks.submitProposalStatus(body));
    message.loading(
      "Processing your request please wait for a confirmation",
      2
    );
  };
  const reload = () => dispatch(donorsThunks.getDonorsDetails(id));

  //user effects
  useEffect(() => {
    dispatch(donorsThunks.getDonorsDetails(id));
    //check donor
    if (checkSuccess && checkMessage) message.success(checkMessage);
    else if (!checkSuccess && checkError) message.error(checkError);
    //approve donor
    if (approveSuccess && approveMessage) message.success(approveMessage);
    else if (!approveSuccess && approveError) message.error(approveError);
    //submit proposal status
    if (submitProposalStatusSuccess && submitProposalStatusMessage) {
      message.success(submitProposalStatusMessage, 5);
    } else if (!submitProposalStatusSuccess && submitProposalStatusError)
      message.error(submitProposalStatusError, 5);
  }, [
    approveError,
    approveMessage,
    approveSuccess,
    checkError,
    checkMessage,
    checkSuccess,
    dispatch,
    id,
    submitProposalStatusError,
    submitProposalStatusLoading,
    submitProposalStatusMessage,
    submitProposalStatusSuccess,
  ]);
  return (
    <div className="container-fluid p-2">
      {loading ? (
        <div className="m-5 d-flex justify-content-center">
          <Spin size="large" />
        </div>
      ) : (
        <div>
          {!success ? (
            <Alert type="error" showIcon message={error} />
          ) : (
            <div>
              <h3>{data?.name}</h3>
              <Space direction="horizontal">
                {data.status !== appConfig.status.approved && (
                  <Space className="my-2">
                    <Button
                      onClick={approveRecord}
                      loading={approveLoading}
                      type="primary"
                    >
                      Approve
                    </Button>
                    {data.status !== appConfig.status.checked && (
                      <Button onClick={checkRecord} loading={checkLoading}>
                        Check
                      </Button>
                    )}
                    <Button onClick={reject} danger>
                      Reject
                    </Button>
                  </Space>
                )}
                {data.proposal_status !== appConfig.status.approved &&
                  data.proposal_status !== appConfig.status.rejected &&
                  data.status === appConfig.status.approved && (
                    <Space>
                      <Button
                        onClick={openSetPersonInchargeModal}
                        type="primary"
                      >
                        Set Person Incharge
                      </Button>
                      <Popover
                        placement="bottom"
                        content={() => (
                          <Space direction="vertical">
                            {data.person_incharge && (
                              <Space direction="vertical">
                                <AppButton
                                  onClick={() =>
                                    submitProposalStatus(
                                      appConfig.status.approved
                                    )
                                  }
                                  text="Proposal Approved"
                                />
                                {data.proposal_status !==
                                  appConfig.status.submitted && (
                                  <AppButton
                                    onClick={() =>
                                      submitProposalStatus(
                                        appConfig.status.submitted
                                      )
                                    }
                                    text="Proposal Submitted"
                                    type="default"
                                  />
                                )}
                                {data.proposal_status !==
                                  appConfig.status.rejected && (
                                  <AppButton
                                    onClick={() =>
                                      submitProposalStatus(
                                        appConfig.status.rejected
                                      )
                                    }
                                    text="Proposal Rejected"
                                    danger
                                  />
                                )}
                              </Space>
                            )}
                          </Space>
                        )}
                        trigger="click"
                      >
                        <Button>Proposal Actions</Button>
                      </Popover>
                    </Space>
                  )}
                <Button onClick={reload}>
                  <Space>
                    <FcRefresh /> <span>Reload </span>
                  </Space>
                </Button>
                <Button
                  className="d-none d-md-block"
                  onClick={() => history.goBack()}
                  danger
                >
                  <Space>
                    <AiOutlineArrowLeft /> <span>Return </span>
                  </Space>
                </Button>
              </Space>
              <Tabs size="large" defaultActiveKey="1">
                <TabPane tab="Donor Details" key="1">
                  <DonorsInfoDetails data={data} />
                </TabPane>
                <TabPane tab="Projects" key="2">
                  <DonorProjects data={data.projects} />
                </TabPane>
              </Tabs>
              <SetPersonIncharge
                handleSetPersonIncharge={setPersonIncharge}
                donorId={donorId}
              />
              <AddRejections />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DonorsDetails;
