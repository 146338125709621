import React, { useState } from "react";
import { useTimeSheetStore } from "../../../../config/stores";
import { searchTable } from "../../../../config/constants";
import TableButtonActions from "../../../../components/common/TableButtonActions";
import dayjs from "dayjs";
import { toUpper } from "lodash";
import AntdTable from "../../../../components/common/AntdTable";
import TableTitle from "../../../../components/common/TableTitle";
import RecordStatus from "../../../../components/common/RecordStatus";

const TimeSheetsTable = ({ timeSheets = [], canEdit = false }) => {
  const [filterTable, setFilterTableNull] = useState(null);
  const [deleteId, setDeleteId] = useState(null);

  const timeSheetsStore = useTimeSheetStore();

  const search = (value) => {
    const filterTable = searchTable(timeSheets, value);
    setFilterTableNull(filterTable);
  };

  const confirmDelete = () => {
    timeSheetsStore.deleteTimeSheet(deleteId);
    setDeleteId(null);
  };
  const viewDetails = (id) => {
    timeSheetsStore.setShowViewTimeSheetDetails(true);
    timeSheetsStore.getTimeSheetDetails(id);
  };

  const deleteRecord = (id) => setDeleteId(id);

  const updateRecord = (record) => {
    timeSheetsStore.setAddingTimeSheet(false, true, record);
  };

  const columns = [
    {
      title: "Staff",
      render: (record) => {
        return (
          <div>
            <span className="d-md-none">month:</span>
            {`${record.staff?.first_name} ${record.staff?.other_names}`} <br />
            <div className="d-md-none">
              Year : {record.year} <br />
              Status: {record.status} <br />
              Actions:
              <TableButtonActions
                record={record}
                confirmDelete={confirmDelete}
                deleteLoading={timeSheetsStore.deletingTimeSheet}
                viewDetails={viewDetails}
                updateRecord={updateRecord}
                deleteId={deleteId}
                deleteRecord={deleteRecord}
              />
            </div>
          </div>
        );
      },
    },
    {
      title: "Month",
      dataIndex: "month",
      responsive: ["md"],
      render: (month) => dayjs(`${month}`).format("MMMM"),
    },
    {
      title: "Year",
      dataIndex: "year",
      responsive: ["md"],
      render: (text) => toUpper(text),
    },
    {
      title: "Status",
      dataIndex: "status",
      responsive: ["md"],
      render: (text) => <RecordStatus status={text} />,
    },
    {
      title: "Actions",
      key: "actions",
      responsive: ["md"],
      render: (record) => {
        return (
          <TableButtonActions
            record={record}
            confirmDelete={confirmDelete}
            deleteLoading={timeSheetsStore.deletingTimeSheet}
            viewDetails={viewDetails}
            updateRecord={canEdit && updateRecord}
            deleteId={deleteId}
            deleteRecord={canEdit && deleteRecord}
          />
        );
      },
    },
  ];

  const openAddModal = () => {
    timeSheetsStore.setAddingTimeSheet(true, true, {});
  };

  const refreshTable = () => {
    timeSheetsStore.getMyTimeSheets();
    timeSheetsStore.getTimeSheets();
  };
  return (
    <div>
      <AntdTable
        loading={timeSheetsStore.gettingTimeSheets}
        title={() => (
          <TableTitle
            setFilterTableNull={setFilterTableNull}
            openAddModal={canEdit && openAddModal}
            refreshTable={refreshTable}
            search={search}
            exportRecords={null}
          />
        )}
        pageSize={20}
        data={filterTable === null ? timeSheets || [] : filterTable}
        columns={columns}
      />
    </div>
  );
};

export default TimeSheetsTable;
