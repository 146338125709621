import { Button, message, Popover, Table } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import rolesThunks from "../../../../config/thunks/settings/roles.thunks";
import { rolesActions } from "../../../../config/actions/settings/roles.actions";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";
import tableButtonActions from "../../../shared/Tables/TableButtonActions";
import TableTitle from "../../../shared/Tables/TableTitle";
import appConfig from "../../../../config/config.json";

const RolesTable = ({ handleDeleteRole }) => {
  const [deleteId, setDeleteId] = useState("");
  const deleteLoading = useSelector(
    (state) => state.rolesState.deleteRole.loading
  );
  const { loading, data, error, success } = useSelector(
    (state) => state.rolesState.getRoles
  );
  const [filterTable, setfilterTable] = useState(null);
  const dispatch = useDispatch();
  const search = (value) => {
    const filterTable = data.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(value.toLowerCase())
      )
    );
    setfilterTable(filterTable);
  };
  const deleteRecord = (e) => {
    const roles = data.filter((ev) => ev.id === e.target.id);
    if (roles[0].role === appConfig.systemAdminAccount)
      message.info("You cannot delete this role");
    setDeleteId(e.target.id);
  };
  const confirmDelete = () => {
    handleDeleteRole(deleteId);
  };
  const viewDetails = (e) => {
    dispatch(rolesThunks.getRolesDetails(e.target.id));
    dispatch(appUiActions.toggleViewRolesModal(true));
  };
  const updateRecord = (e) => {
    const updateRecordArray = data.filter((r) => r.id === e.target.id);
    dispatch(rolesActions.setUpdateRolesData(updateRecordArray[0]));
    dispatch(appUiActions.toggleEditRolesModal(true));
  };
  const columns = [
    {
      title: "Role",
      key: "name",
      render: (text) => (
        <div>
          <span className="d-md-none">Role: </span>
          {text.role} <br />
          <div className="d-md-none">
            <Popover
              className=""
              trigger="click"
              placement="bottom"
              content={() =>
                tableButtonActions(text, {
                  viewDetails,
                  updateRecord,
                  confirmDelete,
                  deleteRecord,
                  deleteLoading,
                  deleteId,
                })
              }
            >
              Actions: <Button type="link">Actions</Button>
            </Popover>
          </div>
        </div>
      ),
    },
    {
      title: "Action",
      key: "operation",
      responsive: ["md"],
      render: (text) => (
        <Popover
          trigger="click"
          placement="bottom"
          content={() =>
            tableButtonActions(text, {
              viewDetails,
              updateRecord,
              confirmDelete,
              deleteRecord,
              deleteLoading,
              deleteId,
            })
          }
        >
          <Button>Actions</Button>
        </Popover>
      ),
    },
  ];
  const setfilterTableNull = (e) => {
    if (!e.target.value) setfilterTable(null);
  };

  const refreshTable = () => {
    dispatch(rolesThunks.getAllRoles());
    if (error && !success) message.error(error);
  };
  const openAddModal = () => {
    dispatch(appUiActions.toggleAddRolesModal(true));
  };
  const exportRecords = () => message.info("Export Unavailable");

  return (
    <div className="">
      <Table
        loading={loading}
        title={() =>
          TableTitle({
            search,
            setfilterTableNull,
            refreshTable,
            exportRecords,
            openAddModal,
          })
        }
        bordered={true}
        dataSource={filterTable === null ? data : filterTable}
        columns={columns}
      />
    </div>
  );
};

export default RolesTable;
