export const recordOfBiddersConstants = {
  SET_RECORD_OF_BIDDERS_REQUEST: "SET_RECORD_OF_BIDDERS_REQUEST",
  SET_RECORD_OF_BIDDERS_SUCCESS: "SET_RECORD_OF_BIDDERS_SUCCESS",
  SET_RECORD_OF_BIDDERS_ERROR: "SET_RECORD_OF_BIDDERS_ERROR",

  SET_BIDDERS_REQUEST: "SET_BIDDERS_REQUEST",
  SET_BIDDERS_SUCCESS: "SET_BIDDERS_SUCCESS",
  SET_BIDDERS_ERROR: "SET_BIDDERS_ERROR",

  SET_RECORD_OF_BIDDERS_DETAILS_REQUEST:
    "SET_RECORD_OF_BIDDERS_DETAILS_REQUEST",
  SET_RECORD_OF_BIDDERS_DETAILS_SUCCESS:
    "SET_RECORD_OF_BIDDERS_DETAILS_SUCCESS",
  SET_RECORD_OF_BIDDERS_DETAILS_ERROR: "SET_RECORD_OF_BIDDERS_DETAILS_ERROR",

  SET_BIDDERS_DETAILS_REQUEST: "SET_BIDDERS_DETAILS_REQUEST",
  SET_BIDDERS_DETAILS_SUCCESS: "SET_BIDDERS_DETAILS_SUCCESS",
  SET_BIDDERS_DETAILS_ERROR: "SET_BIDDERS_DETAILS_ERROR",

  ADD_RECORD_OF_BIDDERS_REQUEST: "ADD_RECORD_OF_BIDDERS_REQUEST",
  ADD_RECORD_OF_BIDDERS_SUCCESS: "ADD_RECORD_OF_BIDDERS_SUCCESS",
  ADD_RECORD_OF_BIDDERS_ERROR: "ADD_RECORD_OF_BIDDERS_ERROR",
  ADD_RECORD_OF_BIDDERS_COMPLETE: "ADD_RECORD_OF_BIDDERS_COMPLETE",

  ADD_BIDDERS_REQUEST: "ADD_BIDDERS_REQUEST",
  ADD_BIDDERS_SUCCESS: "ADD_BIDDERS_SUCCESS",
  ADD_BIDDERS_ERROR: "ADD_BIDDERS_ERROR",
  ADD_BIDDERS_COMPLETE: "ADD_BIDDERS_COMPLETE",

  EDIT_RECORD_OF_BIDDERS_REQUEST: "EDIT_RECORD_OF_BIDDERS_REQUEST",
  EDIT_RECORD_OF_BIDDERS_SUCCESS: "EDIT_RECORD_OF_BIDDERS_SUCCESS",
  EDIT_RECORD_OF_BIDDERS_ERROR: "EDIT_RECORD_OF_BIDDERS_ERROR",
  EDIT_RECORD_OF_BIDDERS_COMPLETE: "EDIT_RECORD_OF_BIDDERS_COMPLETE",
  EDIT_RECORD_OF_BIDDERS_DATA: "EDIT_RECORD_OF_BIDDERS_DATA",

  EDIT_BIDDERS_REQUEST: "EDIT_BIDDERS_REQUEST",
  EDIT_BIDDERS_SUCCESS: "EDIT_BIDDERS_SUCCESS",
  EDIT_BIDDERS_ERROR: "EDIT_BIDDERS_ERROR",
  EDIT_BIDDERS_COMPLETE: "EDIT_BIDDERS_COMPLETE",
  EDIT_BIDDERS_DATA: "EDIT_BIDDERS_DATA",

  DELETE_RECORD_OF_BIDDERS_REQUEST: "DELETE_RECORD_OF_BIDDERS_REQUEST",
  DELETE_RECORD_OF_BIDDERS_SUCCESS: "DELETE_RECORD_OF_BIDDERS_SUCCESS",
  DELETE_RECORD_OF_BIDDERS_ERROR: "DELETE_RECORD_OF_BIDDERS_ERROR",
  DELETE_RECORD_OF_BIDDERS_COMPLETE: "DELETE_RECORD_OF_BIDDERS_COMPLETE",

  DELETE_BIDDERS_REQUEST: "DELETE_BIDDERS_REQUEST",
  DELETE_BIDDERS_SUCCESS: "DELETE_BIDDERS_SUCCESS",
  DELETE_BIDDERS_ERROR: "DELETE_BIDDERS_ERROR",
  DELETE_BIDDERS_COMPLETE: "DELETE_BIDDERS_COMPLETE",

  APPROVE_RECORD_OF_BIDDERS_SUCCESS: "APPROVE_RECORD_OF_BIDDERS_SUCCESS",
  APPROVE_RECORD_OF_BIDDERS_REQUEST: "APPROVE_RECORD_OF_BIDDERS_REQUEST",
  APPROVE_RECORD_OF_BIDDERS_ERROR: "APPROVE_RECORD_OF_BIDDERS_ERROR",
  APPROVE_RECORD_OF_BIDDERS_COMPLETE: "APPROVE_RECORD_OF_BIDDERS_COMPLETE",

  CHECK_RECORD_OF_BIDDERS_REQUEST: "CHECK_RECORD_OF_BIDDERS_REQUEST",
  CHECK_RECORD_OF_BIDDERS_SUCCESS: "CHECK_RECORD_OF_BIDDERS_SUCCESS",
  CHECK_RECORD_OF_BIDDERS_ERROR: "CHECK_RECORD_OF_BIDDERS_ERROR",
  CHECK_RECORD_OF_BIDDERS_COMPLETE: "CHECK_RECORD_OF_BIDDERS_COMPLETE",

  SUBMIT_RECORD_OF_BIDDERS_REQUEST: "SUBMIT_RECORD_OF_BIDDERS_REQUEST",
  SUBMIT_RECORD_OF_BIDDERS_SUCCESS: "SUBMIT_RECORD_OF_BIDDERS_SUCCESS",
  SUBMIT_RECORD_OF_BIDDERS_ERROR: "SUBMIT_RECORD_OF_BIDDERS_ERROR",
  SUBMIT_RECORD_OF_BIDDERS_COMPLETE: "SUBMIT_RECORD_OF_BIDDERS_COMPLETE",

  SET_RECORD_OF_BIDDERS_META_DATA_REQUEST:
    "SET_RECORD_OF_BIDDERS_META_DATA_REQUEST",
  SET_RECORD_OF_BIDDERS_META_DATA: "SET_RECORD_OF_BIDDERS_META_DATA",
};

export const recordOfBiddersActions = {
  setRecordOfBiddersRequest: () => ({
    type: recordOfBiddersConstants.SET_RECORD_OF_BIDDERS_REQUEST,
  }),
  setRecordOfBiddersSuccess: (payload) => ({
    type: recordOfBiddersConstants.SET_RECORD_OF_BIDDERS_SUCCESS,
    payload,
  }),
  setRecordOfBiddersError: (payload) => ({
    type: recordOfBiddersConstants.SET_RECORD_OF_BIDDERS_ERROR,
    payload,
  }),
  setBiddersRequest: () => ({
    type: recordOfBiddersConstants.SET_BIDDERS_REQUEST,
  }),
  setBiddersSuccess: (payload) => ({
    type: recordOfBiddersConstants.SET_BIDDERS_SUCCESS,
    payload,
  }),
  setBiddersError: (payload) => ({
    type: recordOfBiddersConstants.SET_BIDDERS_ERROR,
    payload,
  }),
  setRecordOfBiddersDetailsRequest: () => ({
    type: recordOfBiddersConstants.SET_RECORD_OF_BIDDERS_DETAILS_REQUEST,
  }),
  setRecordOfBiddersDetailsSuccess: (payload) => ({
    type: recordOfBiddersConstants.SET_RECORD_OF_BIDDERS_DETAILS_SUCCESS,
    payload,
  }),
  setRecordOfBiddersDetailsError: (payload) => ({
    type: recordOfBiddersConstants.SET_RECORD_OF_BIDDERS_DETAILS_ERROR,
    payload,
  }),
  setBiddersDetailsRequest: () => ({
    type: recordOfBiddersConstants.SET_BIDDERS_DETAILS_REQUEST,
  }),
  setBiddersDetailsSuccess: (payload) => ({
    type: recordOfBiddersConstants.SET_BIDDERS_DETAILS_SUCCESS,
    payload,
  }),
  setBiddersDetailsError: (payload) => ({
    type: recordOfBiddersConstants.SET_BIDDERS_DETAILS_ERROR,
    payload,
  }),
  addRecordOfBiddersRequest: () => ({
    type: recordOfBiddersConstants.ADD_RECORD_OF_BIDDERS_REQUEST,
  }),
  addRecordOfBiddersSuccess: (payload) => ({
    type: recordOfBiddersConstants.ADD_RECORD_OF_BIDDERS_SUCCESS,
    payload,
  }),
  addRecordOfBiddersError: (payload) => ({
    type: recordOfBiddersConstants.ADD_RECORD_OF_BIDDERS_ERROR,
    payload,
  }),
  addRecordOfBiddersComplete: () => ({
    type: recordOfBiddersConstants.ADD_RECORD_OF_BIDDERS_COMPLETE,
  }),
  addBiddersRequest: () => ({
    type: recordOfBiddersConstants.ADD_BIDDERS_REQUEST,
  }),
  addBiddersSuccess: (payload) => ({
    type: recordOfBiddersConstants.ADD_BIDDERS_SUCCESS,
    payload,
  }),
  addBiddersError: (payload) => ({
    type: recordOfBiddersConstants.ADD_BIDDERS_ERROR,
    payload,
  }),
  addBiddersComplete: () => ({
    type: recordOfBiddersConstants.ADD_BIDDERS_COMPLETE,
  }),
  editRecordOfBiddersRequest: () => ({
    type: recordOfBiddersConstants.EDIT_RECORD_OF_BIDDERS_REQUEST,
  }),
  editRecordOfBiddersSuccess: (payload) => ({
    type: recordOfBiddersConstants.EDIT_RECORD_OF_BIDDERS_SUCCESS,
    payload,
  }),
  editRecordOfBiddersError: (payload) => ({
    type: recordOfBiddersConstants.EDIT_RECORD_OF_BIDDERS_ERROR,
    payload,
  }),
  editRecordOfBiddersComplete: () => ({
    type: recordOfBiddersConstants.EDIT_RECORD_OF_BIDDERS_COMPLETE,
  }),
  editRecordOfBiddersData: (payload) => ({
    type: recordOfBiddersConstants.EDIT_RECORD_OF_BIDDERS_DATA,
    payload,
  }),
  editBiddersRequest: () => ({
    type: recordOfBiddersConstants.EDIT_BIDDERS_REQUEST,
  }),
  editBiddersSuccess: (payload) => ({
    type: recordOfBiddersConstants.EDIT_BIDDERS_SUCCESS,
    payload,
  }),
  editBiddersError: (payload) => ({
    type: recordOfBiddersConstants.EDIT_BIDDERS_ERROR,
    payload,
  }),
  editBiddersComplete: () => ({
    type: recordOfBiddersConstants.EDIT_BIDDERS_COMPLETE,
  }),
  editBiddersData: (payload) => ({
    type: recordOfBiddersConstants.EDIT_BIDDERS_DATA,
    payload,
  }),
  deleteRecordOfBiddersRequest: () => ({
    type: recordOfBiddersConstants.DELETE_RECORD_OF_BIDDERS_REQUEST,
  }),
  deleteRecordOfBiddersSuccess: (payload) => ({
    type: recordOfBiddersConstants.DELETE_RECORD_OF_BIDDERS_SUCCESS,
    payload,
  }),
  deleteRecordOfBiddersError: (payload) => ({
    type: recordOfBiddersConstants.DELETE_RECORD_OF_BIDDERS_ERROR,
    payload,
  }),
  deleteRecordOfBiddersComplete: () => ({
    type: recordOfBiddersConstants.DELETE_RECORD_OF_BIDDERS_COMPLETE,
  }),
  deleteBiddersRequest: () => ({
    type: recordOfBiddersConstants.DELETE_BIDDERS_REQUEST,
  }),
  deleteBiddersSuccess: (payload) => ({
    type: recordOfBiddersConstants.DELETE_BIDDERS_SUCCESS,
    payload,
  }),
  deleteBiddersError: (payload) => ({
    type: recordOfBiddersConstants.DELETE_BIDDERS_ERROR,
    payload,
  }),
  deleteBiddersComplete: () => ({
    type: recordOfBiddersConstants.DELETE_BIDDERS_COMPLETE,
  }),
  submitRecordOfBiddersRequest: () => ({
    type: recordOfBiddersConstants.SUBMIT_RECORD_OF_BIDDERS_REQUEST,
  }),
  submitRecordOfBiddersSuccess: (payload) => ({
    type: recordOfBiddersConstants.SUBMIT_RECORD_OF_BIDDERS_SUCCESS,
    payload,
  }),
  submitRecordOfBiddersError: (payload) => ({
    type: recordOfBiddersConstants.SUBMIT_RECORD_OF_BIDDERS_ERROR,
    payload,
  }),
  submitRecordOfBiddersComplete: () => ({
    type: recordOfBiddersConstants.SUBMIT_RECORD_OF_BIDDERS_COMPLETE,
  }),
  checkRecordOfBiddersRequest: () => ({
    type: recordOfBiddersConstants.CHECK_RECORD_OF_BIDDERS_REQUEST,
  }),
  checkRecordOfBiddersSuccess: (payload) => ({
    type: recordOfBiddersConstants.CHECK_RECORD_OF_BIDDERS_SUCCESS,
    payload,
  }),
  checkRecordOfBiddersError: (payload) => ({
    type: recordOfBiddersConstants.CHECK_RECORD_OF_BIDDERS_ERROR,
    payload,
  }),
  checkRecordOfBiddersComplete: () => ({
    type: recordOfBiddersConstants.CHECK_RECORD_OF_BIDDERS_COMPLETE,
  }),
  approveRecordOfBiddersRequest: () => ({
    type: recordOfBiddersConstants.APPROVE_RECORD_OF_BIDDERS_REQUEST,
  }),
  approveRecordOfBiddersSuccess: (payload) => ({
    type: recordOfBiddersConstants.APPROVE_RECORD_OF_BIDDERS_SUCCESS,
    payload,
  }),
  approveRecordOfBiddersError: (payload) => ({
    type: recordOfBiddersConstants.APPROVE_RECORD_OF_BIDDERS_ERROR,
    payload,
  }),
  approveRecordOfBiddersComplete: () => ({
    type: recordOfBiddersConstants.APPROVE_RECORD_OF_BIDDERS_COMPLETE,
  }),
  setRecordOfBiddersMetaDataRequest: () => ({
    type: recordOfBiddersConstants.SET_RECORD_OF_BIDDERS_META_DATA_REQUEST,
  }),
  setRecordOfBiddersMetaData: (payload) => ({
    type: recordOfBiddersConstants.SET_RECORD_OF_BIDDERS_META_DATA,
    payload,
  }),
};
