import axios from "axios";

// Fetch all permissions
export const fetchPermissionsRequest = async () => {
  try {
    const res = await axios.get("/settings/permissions/");
    return res;
  } catch (error) {
    console.error("Fetch permissions request error:", error);
  }
};

// Create a new permission
export const createPermissionsRequest = async (body) => {
  try {
    const res = await axios.post("/settings/permissions/", body);
    return res;
  } catch (error) {
    console.error("Create permissions request error:", error);
  }
};

// Update an existing permission
export const updatePermissionsRequest = async (body, id) => {
  try {
    const res = await axios.patch(`/settings/permissions/${id}`, body);
    return res;
  } catch (error) {
    console.error("Update permissions request error:", error);
  }
};

// Delete a permission
export const deletePermissionsRequest = async (id) => {
  try {
    const res = await axios.delete(`/settings/permissions/${id}`);
    return res;
  } catch (error) {
    console.error("Delete permissions request error:", error);
  }
};

// Fetch all roles
export const fetchRolesRequest = async () => {
  try {
    const res = await axios.get("/settings/permissions/permissionRolesForm");
    return res;
  } catch (error) {
    console.error("Fetch roles request error:", error);
  }
};

// Fetch a single permission by ID
export const fetchPermissionRequest = async (id) => {
  try {
    const res = await axios.get(`/settings/permissions/${id}`);
    return res;
  } catch (error) {
    console.error("Fetch permission request error:", error);
  }
};
