import { Alert, Modal, Spin } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import FinancialBidderEvaluationsTable from "../Tables/FinancialBiddersEvaluationTable";

const FinancialEvaluationItemData = ({
  visible,
  handleCloseModal,
  handleOpenEvaluationModal,
}) => {
  const closeModal = () => handleCloseModal(false);
  const {
    financialEvaluationItemData,
    financialEvaluationItemDataSuccess,
    financialEvaluationItemDataLoading,
    financialEvaluationItemDataError,
  } = useSelector((state) => state.bidEvaluationState);
  return (
    <div>
      <Modal
        title={`${financialEvaluationItemData?.itemName} FINANCIAL EVALUATION`.toUpperCase()}
        open={visible}
        footer={null}
        onCancel={closeModal}
        width={800}
      >
        {financialEvaluationItemDataLoading ? (
          <div className="d-flex justify-content-center m-5">
            <Spin size="large" />
          </div>
        ) : (
          <div>
            {financialEvaluationItemDataSuccess ? (
              <div>
                <FinancialBidderEvaluationsTable
                  handleOpenEvaluationModal={handleOpenEvaluationModal}
                  evaluationItemDetails={financialEvaluationItemData}
                />
              </div>
            ) : (
              <Alert
                showIcon
                type="error"
                message={financialEvaluationItemDataError}
              />
            )}
          </div>
        )}
      </Modal>
    </div>
  );
};

export default FinancialEvaluationItemData;
