import { Menu } from "antd";
import { filter, map, orderBy } from "lodash";
import React, { createElement } from "react";
import routes from "../../config/routes/routes";
import { FcEmptyBattery } from "react-icons/fc";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
// import { useSelector } from "react-redux";

const SideBar = () => {
  const history = useHistory();
  const {
    data: { viewPermissions },
  } = useSelector((state) => state.usersState.currentUserInfo);
  const routePaths = orderBy(
    filter(Object.values(routes), (route) => {
      return route.protected;
    }),
    "order",
    "asc"
  );

  const menuItems = map(routePaths, (path) => {
    const childRoutes = map(path.childRoutes, (childRoute, idx) => {
      return {
        key: `${childRoute.path}`,
        label: childRoute.name,
        disabled: childRoute.allAccess
          ? false
          : !viewPermissions?.[childRoute?.permissionKey],
        icon: createElement(childRoute.icon || FcEmptyBattery),
      };
    });
    return {
      key: path.path,
      label: path.name,
      icon: createElement(path.icon || FcEmptyBattery),
      children: childRoutes.length > 0 ? childRoutes : null,
    };
  });

  return (
    <div>
      <Menu
        theme="light"
        defaultSelectedKeys={["1"]}
        mode="vertical"
        items={menuItems}
        className=" border-0"
        onClick={(e) => {
          history.push(e.key);
        }}
      />
    </div>
  );
};

export default SideBar;
