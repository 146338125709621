const initialState = {
  approvedBudgetLoading: false,
  approvedBudgetSuccess: false,
  approvedBudget: [],
  approvedBudgetError: "",

  budgetMonitoringStatementSuccess: false,
  budgetMonitoringStatementLoading: false,
  budgetMonitoringStatementError: "",
  budgetMonitoringStatement: {},

  budgets: [],
  budgetsLoading: false,
  budgetsSuccess: false,
  budgetsError: "",

  budgetsGraphData: {},
  budgetsGraphDataLoading: false,
  budgetsGraphDataSuccess: false,
  budgetsGraphDataError: "",

  budgetDetailsLoading: false,
  budgetDetailsSuccess: false,
  budgetDetailsError: "",
  budgetDetails: {},

  budgetLineDetailsLoading: false,
  budgetLineDetailsSuccess: false,
  budgetLineDetailsError: "",
  budgetLineDetails: {},

  budgetActivityDetailsLoading: false,
  budgetActivityDetailsSuccess: false,
  budgetActivityDetailsError: "",
  budgetActivityDetails: {},

  addBudgetSuccess: false,
  addBudgetLoading: false,
  addBudgetError: "",
  addBudgetMessage: "",

  addBudgetActivitySuccess: false,
  addBudgetActitvityError: "",
  addBudgetactivityLoading: false,
  addBudgetActivityMessage: "",

  addBudgetLineSuccess: false,
  addBudgetLineError: "",
  addBudgetLineLoading: false,
  addBudgetLineMessage: "",

  editBudgetLineSuccess: false,
  editBudgetLineError: "",
  editBudgetLineLoading: false,
  editBudgetLineMessage: "",
  editBudgetLineFormData: {},

  editBudgetActitvitySuccess: false,
  editBudgetActitvityError: "",
  editBudgetActitvityLoading: false,
  editBudgetActitvityMessage: "",
  editBudgetActitvityData: {},

  deleteBudgetLineLoading: false,
  deleteBudgetLineSuccess: false,
  deleteBudgetLineError: "",
  deleteBudgetLineMessage: "",

  deleteBudgetActivityLoading: false,
  deleteBudgetActivitySuccess: false,
  deleteBudgetActivityError: "",
  deleteBudgetActivityMessage: "",

  deleteBudgetLoading: false,
  deleteBudgetSuccess: false,
  deleteBudgetError: "",
  deleteBudgetMessage: "",

  approveBudgetLoading: false,
  approveBudgetSuccess: false,
  approveBudgetError: "",
  approveBudgetMessage: "",

  checkBudgetLoading: false,
  checkBudgetSuccess: false,
  checkBudgetError: "",
  checkBudgetMessage: "",

  submitBudgetLoading: false,
  submitBudgetSuccess: false,
  submitBudgetError: "",
  submitBudgetMessage: "",

  metaData: {},
  metaDataLoading: false,
};

export default initialState;
