import { Button, message, Popover, Table } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";
import permissionsThunks from "../../../../config/thunks/settings/permissions.thunks";
import { permissionsActions } from "../../../../config/actions/settings/permissions.actions";
import tableButtonActions from "../../../shared/Tables/TableButtonActions";
import TableTitle from "../../../shared/Tables/TableTitle";

const PermissionsTable = ({
  handleDeletePermissions,
  handleViewPermissionDetails,
}) => {
  const [deleteId, setDeleteId] = useState("");
  const [filterTable, setfilterTable] = useState(null);

  const deleteLoading = useSelector(
    (state) => state.permissionsState.deletePermission.loading
  );
  const { loading, data, error, success } = useSelector(
    (state) => state.permissionsState.permissions
  );

  const dispatch = useDispatch();

  const search = (value) => {
    const filterTable = data.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(value.toLowerCase())
      )
    );
    setfilterTable(filterTable);
  };
  const deleteRecord = (e) => {
    const newPermissionsArray = data.filter((ev) => ev.id === e.target.id);
    if (newPermissionsArray[0].name === "ADMIN PERMISSIONS PERMISSIONS")
      message.info("You cannot delete this permission");
    else setDeleteId(e.target.id);
  };
  const confirmDelete = () => {
    handleDeletePermissions(deleteId);
  };
  const updateRecord = (e) => {
    const updateRecordArray = data.filter((r) => r.id === e.target.id);
    dispatch(permissionsActions.setUpdatePermissionsData(updateRecordArray[0]));
    dispatch(appUiActions.toggleEditPermissionsModal(true));
  };
  const setfilterTableNull = (e) => {
    if (!e.target.value) setfilterTable(null);
  };
  const openAddModal = () => {
    dispatch(appUiActions.toggleAddPermissionsModal(true));
  };
  const exportRecords = () => message.info("Export Unavailable");
  const viewDetails = (e) => {
    handleViewPermissionDetails(e.target.id);
    dispatch(appUiActions.togglePermissionDetails(true));
  };
  const refreshTable = () => {
    dispatch(permissionsThunks.getPermissions());
    if (error && !success) message.error(error);
  };
  const showTotal = (total) => `Total: ${total}`;

  const columns = [
    {
      title: "Permission Name",
      key: "function",
      render: (text) => (
        <div>
          <span className="d-md-none">Permission: </span>
          {text.name} <br />
          <div className="d-md-none">
            <Popover
              className=""
              trigger="click"
              placement="bottom"
              content={() =>
                tableButtonActions(text, {
                  viewDetails,
                  updateRecord,
                  confirmDelete,
                  deleteRecord,
                  deleteLoading,
                  deleteId,
                })
              }
            >
              Actions: <Button type="link">Actions</Button>
            </Popover>
          </div>
        </div>
      ),
    },
    {
      title: "Action",
      key: "operation",
      responsive: ["md"],
      render: (text) => (
        <Popover
          trigger="click"
          placement="bottom"
          content={() =>
            tableButtonActions(text, {
              viewDetails,
              updateRecord,
              confirmDelete,
              deleteRecord,
              deleteLoading,
              deleteId,
            })
          }
        >
          <Button>Actions</Button>
        </Popover>
      ),
    },
  ];

  return (
    <div className="">
      <Table
        pagination={{ total: data.length, showSizeChanger: true, showTotal }}
        loading={loading}
        title={() =>
          TableTitle({
            search,
            setfilterTableNull,
            refreshTable,
            exportRecords,
            openAddModal,
          })
        }
        bordered={true}
        dataSource={filterTable === null ? data : filterTable}
        columns={columns}
      />
    </div>
  );
};

export default PermissionsTable;
