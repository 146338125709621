import { rfqsConstants } from "../../../actions/procument/rfq/rfq.actions";
import initialState from "../../../initialState/procurement/rfq/rfq";

const rfqsState = (state = initialState, { type, payload }) => {
  switch (type) {
    case rfqsConstants.SET_RFQS_REQUEST:
      return {
        ...state,
        rfqsLoading: true,
      };
    case rfqsConstants.SET_RFQS_SUCCESS:
      return {
        ...state,
        rfqsLoading: false,
        rfqs: payload,
        rfqsSuccess: true,
      };
    case rfqsConstants.SET_RFQS_ERROR:
      return {
        ...state,
        rfqsSuccess: false,
        rfqsError: payload,
        rfqsLoading: false,
      };
    case rfqsConstants.SET_RFQS_DETAILS_REQUEST:
      return {
        ...state,
        rfqsDetailsLoading: true,
      };
    case rfqsConstants.SET_RFQS_DETAILS_SUCCESS:
      return {
        ...state,
        rfqsDetailsSuccess: true,
        rfqsDetails: payload,
        rfqsDetailsError: "",
        rfqsDetailsLoading: false,
      };
    case rfqsConstants.SET_RFQS_DETAILS_ERROR:
      return {
        ...state,
        rfqsDetailsSuccess: false,
        rfqsDetailsError: payload,
        rfqsDetailsLoading: false,
      };
    case rfqsConstants.ADD_RFQS_REQUEST:
      return {
        ...state,
        addRfqsLoading: true,
      };
    case rfqsConstants.ADD_RFQS_SUCCESS:
      return {
        ...state,
        addRfqsLoading: false,
        addRfqsSuccess: true,
        addRfqsError: "",
        addRfqsMessage: payload.message,
        rfqs: payload.data,
      };
    case rfqsConstants.ADD_RFQS_ERROR:
      return {
        ...state,
        addRfqsLoading: false,
        addRfqsSuccess: false,
        addRfqsMessage: "",
        addRfqsError: payload,
      };
    case rfqsConstants.ADD_RFQS_COMPLETE:
      return {
        ...state,
        addRfqsSuccess: false,
      };
    case rfqsConstants.EDIT_RFQS_DATA:
      return {
        ...state,
        editRfqsData: payload,
      };
    case rfqsConstants.EDIT_RFQS_REQUEST:
      return {
        ...state,
        editRfqsLoading: true,
      };
    case rfqsConstants.EDIT_RFQS_SUCCESS:
      return {
        ...state,
        editRfqsSuccess: true,
        editRfqsLoading: false,
        editRfqsMessage: payload.message,
        editRfqsError: "",
        rfqs: payload.data,
      };
    case rfqsConstants.EDIT_RFQS_ERROR:
      return {
        ...state,
        editRfqsSuccess: false,
        editRfqsLoading: false,
        editRfqsMessage: "",
        editRfqsError: payload,
      };
    case rfqsConstants.EDIT_RFQS_COMPLETE:
      return {
        ...state,
        editRfqsSuccess: false,
      };
    case rfqsConstants.DELETE_RFQS_REQUEST:
      return {
        ...state,
        deleteRfqsLoading: true,
      };
    case rfqsConstants.DELETE_RFQS_SUCCESS:
      return {
        ...state,
        deleteRfqsLoading: false,
        deleteRfqsSuccess: true,
        deleteRfqsMessage: payload.message,
        rfqs: payload.data,
      };
    case rfqsConstants.DELETE_RFQS_ERROR:
      return {
        ...state,
        deleteRfqsLoading: false,
        deleteRfqsSuccess: false,
        deleteRfqsError: payload,
      };
    case rfqsConstants.DELETE_RFQS_COMPLETE:
      return {
        ...state,
        deleteRfqsSuccess: false,
        deleteRfqsError: "",
      };
    case rfqsConstants.CHECK_RFQS_REQUEST:
      return {
        ...state,
        checkRfqsLoading: true,
      };
    case rfqsConstants.CHECK_RFQS_SUCCESS:
      return {
        ...state,
        checkRfqsLoading: false,
        checkRfqsSuccess: true,
        checkRfqsMessage: payload,
      };
    case rfqsConstants.CHECK_RFQS_ERROR:
      return {
        ...state,
        checkRfqsError: payload,
        checkRfqsLoading: false,
        checkRfqsSuccess: false,
      };
    case rfqsConstants.CHECK_RFQS_COMPLETE:
      return {
        ...state,
        checkRfqsError: "",
        checkRfqsSuccess: false,
      };
    case rfqsConstants.APPROVE_RFQS_REQUEST:
      return {
        ...state,
        approveRfqsLoading: true,
      };
    case rfqsConstants.APPROVE_RFQS_SUCCESS:
      return {
        ...state,
        approveRfqsSuccess: true,
        approveRfqsLoading: false,
        approveRfqsMessage: payload,
      };
    case rfqsConstants.APPROVE_RFQS_ERROR:
      return {
        ...state,
        approveRfqsError: payload,
        approveRfqsLoading: false,
        approveRfqsSuccess: false,
      };
    case rfqsConstants.APPROVE_RFQS_COMPLETE:
      return {
        ...state,
        approveRfqsSuccess: false,
        approveRfqsError: "",
      };
    case rfqsConstants.SET_RFQS_META_DATA_REQUEST:
      return {
        ...state,
        rfqsMetaDataLoading: true,
      };
    case rfqsConstants.SET_RFQS_META_DATA:
      return {
        ...state,
        rfqsMetaDataLoading: false,
        rfqsMetaData: payload,
      };
    default:
      return state;
  }
};

export default rfqsState;
