import { appUiActions } from "../../actions/app/appUi.actions";
import { rolesActions } from "../../actions/settings/roles.actions";
import {
  addNewRoleRequest,
  approveRolesRequest,
  checkRolesRequest,
  deleteRoleRequest,
  getAllRolesRequest,
  getRolesDetailsRequest,
  updateNewRoleRequest,
} from "../../api/settings/roles.api";

const errorMessage =
  "Sorry there seems to be an error please try again in a few minutes";
const rolesThunks = {
  getAllRoles: () => async (dispatch) => {
    dispatch(rolesActions.getRolesRequest());
    const res = await getAllRolesRequest();
    try {
      if (res.success) dispatch(rolesActions.getRolesSuccess(res.data));
      else dispatch(rolesActions.getRolesError(res.error));
    } catch (error) {
      console.error(error);
      dispatch(rolesActions.getRolesError(errorMessage));
    }
  },
  addRole: (body) => async (dispatch) => {
    dispatch(rolesActions.addRolesRequest());
    const res = await addNewRoleRequest(body);
    try {
      if (res.success) {
        dispatch(rolesActions.addRoleSuccess(res.data[0]));
        dispatch(rolesActions.addRoleComplete());
      } else {
        dispatch(rolesActions.addRolesError(res.error));
      }
    } catch (error) {
      console.error(error);
      dispatch(rolesActions.addRolesError(errorMessage));
    }
  },
  updateRole: (body, id, roles) => async (dispatch) => {
    dispatch(rolesActions.updateRolesRequest());
    const res = await updateNewRoleRequest(body, id);
    try {
      if (res.success) {
        dispatch(appUiActions.toggleEditRolesModal(false));
        dispatch(rolesActions.updateRolesSuccess(roles));
        dispatch(rolesActions.updateRolesCompleted());
      } else dispatch(rolesActions.updateRolesError(res.error));
    } catch (error) {
      console.error(error);
      dispatch(rolesActions.updateRolesError(errorMessage));
    }
  },
  deleteRole: (id, payload) => async (dispatch) => {
    dispatch(rolesActions.deleteRolesRequest());
    const res = await deleteRoleRequest(id);
    try {
      if (res.success) dispatch(rolesActions.deleteRolesSuccess(payload));
      else dispatch(rolesActions.deleteRolesError(res.error));
      dispatch(rolesActions.deleteRoleCompleted());
    } catch (error) {
      console.error(error);
      dispatch(rolesActions.deleteRolesError(errorMessage));
    }
  },
  getRolesDetails: (id) => async (dispatch) => {
    try {
      dispatch(rolesActions.getSingleRoleRequest());
      const res = await getRolesDetailsRequest(id);
      if (res.success) dispatch(rolesActions.getSingleRoleSuccess(res.data));
      else dispatch(rolesActions.getSingleRoleError(res.error));
    } catch (error) {
      console.error("roles details thunk error:", error);
      dispatch(rolesActions.getSingleRoleError(errorMessage));
    }
  },
  approveRoles: (id) => async (dispatch) => {
    try {
      dispatch(rolesActions.approveRolesRequest());
      const res = await approveRolesRequest(id);
      if (res.success) {
        dispatch(rolesActions.approveRolesSuccess(res.message));
        dispatch(rolesThunks.getRolesDetails(id));
      } else dispatch(rolesActions.approveRolesError(res.error));
    } catch (error) {
      console.error("Approve roles thunk error:", error);
      dispatch(rolesActions.approveRolesError(errorMessage));
    }
    dispatch(rolesActions.approveRolesCompleted());
  },
  checkRoles: (id) => async (dispatch) => {
    try {
      dispatch(rolesActions.checkRolesRequest());
      const res = await checkRolesRequest(id);
      if (res.success) {
        dispatch(rolesActions.checkRolesSuccess(res.message));
        dispatch(rolesThunks.getRolesDetails(id));
      } else dispatch(rolesActions.checkRolesError(res.error));
    } catch (error) {
      console.error("Check roles thunk error:", error);
      dispatch(rolesActions.checkRolesError(errorMessage));
    }
    dispatch(rolesActions.checkRolesCompleted());
  },
};

export default rolesThunks;
