import { paymentVoucherConstants } from "../../../actions/finance/paymentVouchers/paymentVouchers.actions";
import initialState from "../../../initialState/finance/paymentVoucher/paymentVoucher";

const paymentVouchersState = (state = initialState, { type, payload }) => {
  switch (type) {
    case paymentVoucherConstants.SET_PAYMENT_VOUCHERS_REQUEST:
      return {
        ...state,
        paymentVouchersLoading: true,
      };
    case paymentVoucherConstants.SET_PAYMENT_VOUCHERS_SUCCESS:
      return {
        ...state,
        paymentVouchersLoading: false,
        paymentVouchers: payload,
        paymentVouchersSuccess: true,
      };
    case paymentVoucherConstants.SET_PAYMENT_VOUCHERS_ERROR:
      return {
        ...state,
        paymentVouchersSuccess: false,
        paymentVouchersError: payload,
        paymentVouchersLoading: false,
      };
    case paymentVoucherConstants.SET_PAYMENT_VOUCHERS_DETAILS_REQUEST:
      return {
        ...state,
        paymentVouchersDetailsLoading: true,
      };
    case paymentVoucherConstants.SET_PAYMENT_VOUCHERS_DETAILS_SUCCESS:
      return {
        ...state,
        paymentVouchersDetailsSuccess: true,
        paymentVouchersDetails: payload,
        paymentVouchersDetailsError: "",
        paymentVouchersDetailsLoading: false,
      };
    case paymentVoucherConstants.SET_PAYMENT_VOUCHERS_DETAILS_ERROR:
      return {
        ...state,
        paymentVouchersDetailsSuccess: false,
        paymentVouchersDetailsError: payload,
        paymentVouchersDetailsLoading: false,
      };
    case paymentVoucherConstants.ADD_PAYMENT_VOUCHERS_REQUEST:
      return {
        ...state,
        addPaymentVouchersLoading: true,
      };
    case paymentVoucherConstants.ADD_PAYMENT_VOUCHERS_SUCCESS:
      return {
        ...state,
        addPaymentVouchersLoading: false,
        addPaymentVouchersSuccess: true,
        addPaymentVouchersError: "",
        addPaymentVouchersMessage: payload.message,
        paymentVouchers: payload.data,
      };
    case paymentVoucherConstants.ADD_PAYMENT_VOUCHERS_ERROR:
      return {
        ...state,
        addPaymentVouchersLoading: false,
        addPaymentVouchersSuccess: false,
        addPaymentVouchersMessage: "",
        addPaymentVouchersError: payload,
      };
    case paymentVoucherConstants.ADD_PAYMENT_VOUCHERS_COMPLETE:
      return {
        ...state,
        addPaymentVouchersSuccess: false,
      };
    case paymentVoucherConstants.EDIT_PAYMENT_VOUCHERS_DATA:
      return {
        ...state,
        editPaymentVouchersData: payload,
      };
    case paymentVoucherConstants.EDIT_PAYMENT_VOUCHERS_REQUEST:
      return {
        ...state,
        editPaymentVouchersLoading: true,
      };
    case paymentVoucherConstants.EDIT_PAYMENT_VOUCHERS_SUCCESS:
      return {
        ...state,
        editPaymentVouchersSuccess: true,
        editPaymentVouchersLoading: false,
        editPaymentVouchersMessage: payload,
        editPaymentVouchersError: "",
      };
    case paymentVoucherConstants.EDIT_PAYMENT_VOUCHERS_ERROR:
      return {
        ...state,
        editPaymentVouchersSuccess: false,
        editPaymentVouchersLoading: false,
        editPaymentVouchersMessage: "",
        editPaymentVouchersError: payload,
      };
    case paymentVoucherConstants.EDIT_PAYMENT_VOUCHERS_COMPLETE:
      return {
        ...state,
        editPaymentVouchersSuccess: false,
      };
    case paymentVoucherConstants.DELETE_PAYMENT_VOUCHERS_REQUEST:
      return {
        ...state,
        deletePaymentVouchersLoading: true,
      };
    case paymentVoucherConstants.DELETE_PAYMENT_VOUCHERS_SUCCESS:
      return {
        ...state,
        deletePaymentVouchersLoading: false,
        deletePaymentVouchersSuccess: true,
        deletePaymentVouchersMessage: payload.message,
        paymentVouchers: payload.data,
      };
    case paymentVoucherConstants.DELETE_PAYMENT_VOUCHERS_ERROR:
      return {
        ...state,
        deletePaymentVouchersLoading: false,
        deletePaymentVouchersSuccess: false,
        deletePaymentVouchersError: payload,
      };
    case paymentVoucherConstants.DELETE_PAYMENT_VOUCHERS_COMPLETE:
      return {
        ...state,
        deletePaymentVouchersSuccess: false,
        deletePaymentVouchersError: "",
      };
    case paymentVoucherConstants.CHECK_PAYMENT_VOUCHERS_REQUEST:
      return {
        ...state,
        checkPaymentVouchersLoading: true,
      };
    case paymentVoucherConstants.CHECK_PAYMENT_VOUCHERS_SUCCESS:
      return {
        ...state,
        checkPaymentVouchersLoading: false,
        checkPaymentVouchersSuccess: true,
        checkPaymentVouchersMessage: payload,
      };
    case paymentVoucherConstants.CHECK_PAYMENT_VOUCHERS_ERROR:
      return {
        ...state,
        checkPaymentVouchersError: payload,
        checkPaymentVouchersLoading: false,
        checkPaymentVouchersSuccess: false,
      };
    case paymentVoucherConstants.CHECK_PAYMENT_VOUCHERS_COMPLETE:
      return {
        ...state,
        checkPaymentVouchersError: "",
        checkPaymentVouchersSuccess: false,
      };
    case paymentVoucherConstants.APPROVE_PAYMENT_VOUCHERS_REQUEST:
      return {
        ...state,
        approvePaymentVouchersLoading: true,
      };
    case paymentVoucherConstants.APPROVE_PAYMENT_VOUCHERS_SUCCESS:
      return {
        ...state,
        approvePaymentVouchersSuccess: true,
        approvePaymentVouchersLoading: false,
        approvePaymentVouchersMessage: payload,
      };
    case paymentVoucherConstants.APPROVE_PAYMENT_VOUCHERS_ERROR:
      return {
        ...state,
        approvePaymentVouchersError: payload,
        approvePaymentVouchersLoading: false,
        approvePaymentVouchersSuccess: false,
      };
    case paymentVoucherConstants.APPROVE_PAYMENT_VOUCHERS_COMPLETE:
      return {
        ...state,
        approvePaymentVouchersSuccess: false,
        approvePaymentVouchersError: "",
      };
    case paymentVoucherConstants.SET_PAYMENT_VOUCHERS_META_DATA_REQUEST:
      return {
        ...state,
        paymentVouchersMetaDataLoading: true,
      };
    case paymentVoucherConstants.SET_PAYMENT_VOUCHERS_META_DATA:
      return {
        ...state,
        paymentVouchersMetaDataLoading: false,
        paymentVouchersMetaData: payload,
      };
    default:
      return state;
  }
};

export default paymentVouchersState;
