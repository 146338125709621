import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NoticeOfBestBidderDetails from "../../../components/Procurement/noticeOfBestBidder/Details/NoticeOfBestBidderDetails";
import AddNoticeOfBestBidderForm from "../../../components/Procurement/noticeOfBestBidder/Forms/AddNoticeOfBestBidder";
import EditNoticeOfBestBidderForm from "../../../components/Procurement/noticeOfBestBidder/Forms/EditNoticeOfBestBidder";
import NoticeOfBestBiddersTable from "../../../components/Procurement/noticeOfBestBidder/Tables/NoticeOfBestBidderTable";
import AddProcurementRejections from "../../../components/shared/Rejections/procuremnet/AddProcurementRejections";
import routes from "../../../config/routes/routes";
import noticeOfBestBidderThunks from "../../../config/thunks/procurement/noticeOfBestBidder/noticeOfBesBidder.thunks";
import logDetailThunks from "../../../config/thunks/settings/logDetails.thunks";

const NoticeOfBestBidder = () => {
  //reducer state intialization variables
  const { noticeOfBestBiddersSuccess } = useSelector(
    (state) => state.noticeOfBestBidderState
  );

  const dispatch = useDispatch();
  //ui state
  const [addNoitceOfBestBidderModalVisible, setAddNoticeOfBestBidderVisible] =
    useState(false);
  const [editNoitceOfBestBidderModalVisible, setEditNoticeOfBestBidderVisible] =
    useState(false);
  const [
    noitceOfBestBidderDetailsModalVisible,
    setNoticeOfBestBidderDetailsVisible,
  ] = useState(false);
  const [addRejectionModalVisible, setAddRejectionModalVisible] =
    useState(false);
  //thunks
  const refreshNoticeOfBestBidders = () => {
    dispatch(noticeOfBestBidderThunks.getNoticeOfBestBidders());
    dispatch(noticeOfBestBidderThunks.getNoticeOfBestBiddersMetaData());
  };
  const exportRecords = () => {
    dispatch(
      logDetailThunks.addLogDetails({
        created_at: new Date(),
        activity: `Exported Notice Of Best Bidders`,
        records: "noticeOfBestBidders",
      })
    );
  };
  const addNoticeOfBestBidder = (values, noticeOfBestBidders) =>
    dispatch(
      noticeOfBestBidderThunks.addNoticeOfBestBidders(
        values,
        noticeOfBestBidders
      )
    );
  const editNoticeOfBestBidder = (values, noticeOfBestBidders) =>
    dispatch(
      noticeOfBestBidderThunks.editNoticeOfBestBidders(
        values,
        noticeOfBestBidders
      )
    );
  const deleteNoticeOfBestBidder = (id, noticeOfBestBidders) =>
    dispatch(
      noticeOfBestBidderThunks.deleteNoticeOfBestBidders(
        id,
        noticeOfBestBidders
      )
    );
  const getNoticeOfBestBidderDetails = (id) => {
    setNoticeOfBestBidderDetailsVisible(true);
    dispatch(noticeOfBestBidderThunks.getNoticeOfBestBiddersDetails(id));
  };
  const checkNoticeOfBestBidder = (id) =>
    dispatch(noticeOfBestBidderThunks.checkNoticeOfBestBidders(id));
  const approveNoticeOfBestBidder = (id) =>
    dispatch(noticeOfBestBidderThunks.approveNoticeOfBestBidders(id));
  //useEffects
  useEffect(() => {
    document.title = routes.procurement.noticeOfBestBidder.title;
    if (!noticeOfBestBiddersSuccess)
      dispatch(noticeOfBestBidderThunks.getNoticeOfBestBidders());
    dispatch(noticeOfBestBidderThunks.getNoticeOfBestBiddersMetaData());
  }, [noticeOfBestBiddersSuccess, dispatch]);
  return (
    <div className="p-2 container-fluid">
      <h5 className="fw-bold text-uppercase">Notice Of Best Bidder</h5>
      <NoticeOfBestBiddersTable
        handleViewDetails={getNoticeOfBestBidderDetails}
        handleDelete={deleteNoticeOfBestBidder}
        handleOpenAddModal={setAddNoticeOfBestBidderVisible}
        handleExportRecords={exportRecords}
        handleRefreshTable={refreshNoticeOfBestBidders}
        handleOpenEditModal={setEditNoticeOfBestBidderVisible}
      />
      <AddNoticeOfBestBidderForm
        visible={addNoitceOfBestBidderModalVisible}
        handleAddRecord={addNoticeOfBestBidder}
        handleCloseModal={setAddNoticeOfBestBidderVisible}
      />
      <EditNoticeOfBestBidderForm
        handleEditRecord={editNoticeOfBestBidder}
        visible={editNoitceOfBestBidderModalVisible}
        handleCloseModal={setEditNoticeOfBestBidderVisible}
      />
      <NoticeOfBestBidderDetails
        handleRejection={setAddRejectionModalVisible}
        handleCheck={checkNoticeOfBestBidder}
        handleApprove={approveNoticeOfBestBidder}
        visible={noitceOfBestBidderDetailsModalVisible}
        handleCloseModal={setNoticeOfBestBidderDetailsVisible}
      />
      <AddProcurementRejections
        handleCloseModal={setAddRejectionModalVisible}
        visible={addRejectionModalVisible}
      />
    </div>
  );
};

export default NoticeOfBestBidder;
