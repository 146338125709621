import { Button, Form, Input, InputNumber, Modal } from "antd";
import React from "react";
import { FcHighPriority } from "react-icons/fc";
import { useDispatch, useSelector } from "react-redux";
import { assetRegisterActions } from "../../../../config/actions/administration/assetRegister/assetRegister.actions";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";

const EditAssetTypeForm = ({ handledEditAssetType }) => {
  const dispatch = useDispatch();
  const { error, success, loading, data } = useSelector(
    (state) => state.assetRegisterState.editAssetTypes
  );

  const visible = useSelector(
    (state) => state.AppUi.admisnistration.assetsRegister.editAssetTypesModal
  );
  const onFinish = (values) => {
    values.updatedAt = new Date();
    values.id = data.id;
    dispatch(assetRegisterActions.setUpdateAssetCategoryData(values));
    handledEditAssetType(values);
  };
  const closeAddRoleModal = () => {
    dispatch(appUiActions.toggleEditAssetTypesModal(false));
  };

  const fields = [
    { name: "name", value: data.name },
    { name: "depreciation", value: data.depreciation },
  ];
  return (
    <div>
      <Modal
        onCancel={closeAddRoleModal}
        open={visible}
        title="EDIT ASSET TYPE"
        footer={null}
        validateTrigger="onBlur"
      >
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          validateTrigger="onBlur"
          layout="horizontal"
          fields={fields}
          onFinish={onFinish}
        >
          {!success && error && (
            <div className="alert alert-danger">
              <FcHighPriority /> &nbsp; {error}
            </div>
          )}
          <Form.Item
            name="name"
            label="Asset Type Name"
            rules={[
              { required: true, message: "Please add an asset type name" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Depreciation %"
            name="depreciation"
            rules={[{ required: true, message: "Please add a depreciation" }]}
          >
            <InputNumber precision={0} className="w-100" max={100} />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button loading={loading} htmlType="submit" type="primary">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default EditAssetTypeForm;
