import { noticeOfBestBiddersActions } from "../../../actions/procument/noticeOfBestBidder/noticeOfBestBidder.actions";
import apiRequests from "../../../api/api";

const noticeOfBestBidderThunks = {
  getNoticeOfBestBidders: () => async (dispatch) => {
    dispatch(noticeOfBestBiddersActions.setNoticeOfBestBiddersLoading());
    const res = await apiRequests.getRequest(
      "/procurement/noticeOfBestBidders/"
    );
    if (res.success)
      dispatch(
        noticeOfBestBiddersActions.setNoticeOfBestBiddersSuccess(res.data)
      );
    else
      dispatch(
        noticeOfBestBiddersActions.setNoticeOfBestBiddersError(res.error)
      );
  },
  getNoticeOfBestBiddersMetaData: () => async (dispatch) => {
    dispatch(
      noticeOfBestBiddersActions.setNoticeOfBestBiddersMetaDataLoading()
    );
    const res = await apiRequests.getRequest(
      `/procurement/noticeOfBestBidders/metaData`
    );
    dispatch(
      noticeOfBestBiddersActions.setNoticeOfBestBiddersMetaData(res.data || {})
    );
  },
  getNoticeOfBestBiddersDetails: (id) => async (dispatch) => {
    dispatch(noticeOfBestBiddersActions.setNoticeOfBestBiddersDetailsRequest());
    const res = await apiRequests.getRequest(
      `/procurement/noticeOfBestBidders/${id}`
    );
    if (res.success)
      dispatch(
        noticeOfBestBiddersActions.setNoticeOfBestBiddersDetailsSuccess(
          res.data
        )
      );
    else
      dispatch(
        noticeOfBestBiddersActions.setNoticeOfBestBiddersDetailsError(res.error)
      );
  },
  addNoticeOfBestBidders: (body, noticeOfBestBidders) => async (dispatch) => {
    dispatch(noticeOfBestBiddersActions.addNoticeOfBestBiddersRequest());
    const res = await apiRequests.postRequest(
      "/procurement/noticeOfBestBidders/",
      body
    );
    if (res.success)
      dispatch(
        noticeOfBestBiddersActions.addNoticeOfBestBiddersSuccess({
          data: [res.data, ...noticeOfBestBidders],
          message: res.message,
        })
      );
    else
      dispatch(
        noticeOfBestBiddersActions.addNoticeOfBestBiddersError(res.error)
      );
    dispatch(noticeOfBestBiddersActions.addNoticeOfBestBiddersComplete());
  },
  editNoticeOfBestBidders: (body, noticeOfBestBidders) => async (dispatch) => {
    dispatch(noticeOfBestBiddersActions.editNoticeOfBestBiddersRequest());
    const res = await apiRequests.putRequest(
      `/procurement/noticeOfBestBidders/${body.id}`,
      body
    );
    if (res.success)
      dispatch(
        noticeOfBestBiddersActions.editNoticeOfBestBiddersSuccess({
          data: [res.data, ...noticeOfBestBidders],
          message: res.message,
        })
      );
    else
      dispatch(
        noticeOfBestBiddersActions.editNoticeOfBestBiddersError(res.error)
      );
    dispatch(noticeOfBestBiddersActions.editNoticeOfBestBiddersComplete());
  },
  deleteNoticeOfBestBidders: (id, noticeOfBestBidders) => async (dispatch) => {
    dispatch(noticeOfBestBiddersActions.deleteNoticeOfBestBiddersRequest());
    const res = await apiRequests.deleteRequest(
      `/procurement/noticeOfBestBidders/${id}`
    );
    if (res.success)
      dispatch(
        noticeOfBestBiddersActions.deleteNoticeOfBestBiddersSuccess({
          message: res.message,
          data: noticeOfBestBidders,
        })
      );
    else
      dispatch(
        noticeOfBestBiddersActions.deleteNoticeOfBestBiddersError(res.error)
      );
    dispatch(noticeOfBestBiddersActions.deleteNoticeOfBestBiddersComplete());
  },
  checkNoticeOfBestBidders: (id) => async (dispatch) => {
    dispatch(noticeOfBestBiddersActions.checkNoticeOfBestBiddersRequest());
    const res = await apiRequests.patchRequest(
      `/procurement/noticeOfBestBidders/check/${id}`,
      { checkedAt: new Date() }
    );
    if (res.success) {
      dispatch(
        noticeOfBestBiddersActions.checkNoticeOfBestBiddersSuccess(res.message)
      );
      dispatch(noticeOfBestBidderThunks.getNoticeOfBestBiddersDetails(id));
    } else
      dispatch(
        noticeOfBestBiddersActions.checkNoticeOfBestBiddersError(res.error)
      );
    dispatch(noticeOfBestBiddersActions.checkNoticeOfBestBiddersComplete());
  },
  approveNoticeOfBestBidders: (id) => async (dispatch) => {
    dispatch(noticeOfBestBiddersActions.approveNoticeOfBestBiddersRequest());
    const res = await apiRequests.patchRequest(
      `/procurement/noticeOfBestBidders/approve/${id}`,
      { approvedAt: new Date() }
    );
    if (res.success) {
      dispatch(
        noticeOfBestBiddersActions.approveNoticeOfBestBiddersSuccess(
          res.message
        )
      );
      dispatch(noticeOfBestBidderThunks.getNoticeOfBestBiddersDetails(id));
    } else
      dispatch(
        noticeOfBestBiddersActions.approveNoticeOfBestBiddersError(res.error)
      );
    dispatch(noticeOfBestBiddersActions.approveNoticeOfBestBiddersComplete());
  },
};

export default noticeOfBestBidderThunks;
