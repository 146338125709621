import React, { useEffect } from "react";
import { useTimeSheetStore } from "../../config/stores";
import { isEmpty } from "lodash";
import TimeSheetDetails from "./MyTimeSheets/MangeMyTimeSheets/TimeSheetDetails";
import TimeSheetsTable from "./MyTimeSheets/MangeMyTimeSheets/TimeSheetsTable";
const TimeSheets = () => {
  const timeSheetsStore = useTimeSheetStore();
  useEffect(() => {
    if (isEmpty(timeSheetsStore.t)) {
      timeSheetsStore.getMyTimeSheets();
    }
  }, []);

  return (
    <div className="container-fluid p-2">
      <h5 className="fw-bold">TIME SHEETS</h5>
      {timeSheetsStore.showTimeSheetDetails ? (
        <TimeSheetDetails />
      ) : (
        <TimeSheetsTable timeSheets={timeSheetsStore.myTimeSheets?.data} />
      )}
    </div>
  );
};

export default TimeSheets;
