import { rolesConstants } from "../../actions/settings/roles.actions";
import initialState from "../../initialState/settings/roles";

const rolesState = (state = initialState, { type, payload }) => {
  switch (type) {
    case rolesConstants.GET_ROLES_REQUEST:
      return {
        ...state,
        getRoles: {
          ...state.getRoles,
          loading: true,
        },
      };
    case rolesConstants.GET_ROLES_SUCCESS:
      return {
        ...state,
        getRoles: {
          ...state.getRoles,
          loading: false,
          success: true,
          data: payload,
        },
      };
    case rolesConstants.GET_ROLES_ERROR:
      return {
        ...state,
        getRoles: {
          ...state.getRoles,
          loading: false,
          success: false,
          error: payload,
        },
      };
    case rolesConstants.ADD_ROLES_REQUEST:
      return {
        ...state,
        addNewRole: {
          ...state.addNewRole,
          loading: true,
        },
      };
    case rolesConstants.ADD_ROLES_SUCCESS:
      return {
        ...state,
        addNewRole: {
          ...state.addNewRole,
          loading: false,
          success: true,
        },
        getRoles: {
          ...state.getRoles,
          data: [payload, ...state.getRoles.data],
        },
      };
    case rolesConstants.ADD_ROLES_ERROR:
      return {
        ...state,
        addNewRole: {
          ...state.addNewRole,
          loading: false,
          success: false,
          error: payload,
        },
      };
    case rolesConstants.ADD_ROLE_COMPETE:
      return {
        ...state,
        addNewRole: {
          ...state.addNewRole,
          success: false,
          error: "",
        },
      };
    case rolesConstants.DELETE_ROLES_REQUEST:
      return {
        ...state,
        deleteRole: {
          ...state.deleteRole,
          loading: true,
        },
      };
    case rolesConstants.DELETE_ROLES_SUCCESS:
      return {
        ...state,
        deleteRole: {
          ...state.deleteRole,
          loading: false,
          success: true,
        },
        getRoles: {
          ...state.getRoles,
          data: payload,
        },
      };
    case rolesConstants.DELETE_ROLES_COMPLETED:
      return {
        ...state,
        deleteRole: {
          ...state.deleteRole,
          success: false,
          error: "",
        },
      };
    case rolesConstants.DELETE_ROLES_ERROR:
      return {
        ...state,
        deleteRole: {
          error: payload,
          loading: false,
          success: false,
        },
      };
    case rolesConstants.UPDATE_ROLES_DATA:
      return {
        ...state,
        updateRole: {
          ...state.updateRole,
          data: payload,
        },
      };
    case rolesConstants.UPDATE_ROLE_REQUEST:
      return {
        ...state,
        updateRole: {
          ...state.updateRole,
          loading: true,
        },
      };
    case rolesConstants.UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        updateRole: {
          ...state.updateRole,
          loading: false,
          success: true,
        },
        getRoles: {
          ...state,
          data: payload,
        },
      };
    case rolesConstants.UPDATE_ROLE_ERROR:
      return {
        ...state,
        updateRole: {
          ...state.updateRole,
          error: payload,
          loading: false,
          success: false,
        },
      };
    case rolesConstants.UPDATE_ROLES_COMPLETED:
      return {
        ...state,
        updateRole: {
          ...state.updateRole,
          error: "",
          success: false,
        },
      };
    case rolesConstants.CHECK_ROLES_REQUEST:
      return {
        ...state,
        checkRoles: { ...state.checkRoles, loading: true },
      };
    case rolesConstants.CHECK_ROLES_SUCCESS:
      return {
        ...state,
        checkRoles: {
          loading: false,
          error: "",
          success: true,
          message: payload,
        },
      };
    case rolesConstants.CHECK_ROLES_ERROR:
      return {
        ...state,
        checkRoles: {
          ...state.checkRoles,
          success: false,
          error: payload,
          loading: false,
        },
      };
    case rolesConstants.CHECK_ROLES_COMPLETED:
      return {
        ...state,
        checkRoles: {
          success: false,
          error: "",
        },
      };
    case rolesConstants.APPROVE_ROLES_REQUEST:
      return {
        ...state,
        approveRoles: {
          ...state.approveRoles,
          loading: true,
        },
      };
    case rolesConstants.APPROVE_ROLES_SUCCESS:
      return {
        ...state,
        approveRoles: {
          error: "",
          message: payload,
          success: true,
          loading: false,
        },
      };
    case rolesConstants.APPROVE_ROLES_ERROR:
      return {
        ...state,
        approveRoles: {
          error: payload,
          success: false,
          loading: false,
          message: "",
        },
      };
    case rolesConstants.APPROVE_ROLES_COMPLETED:
      return {
        ...state,
        approveRoles: {
          ...state.approveRoles,
          success: false,
          error: "",
        },
      };
    case rolesConstants.GET_SINGLE_ROLES_REQUEST:
      return {
        ...state,
        getSingleRole: { ...state.getSingleRole, loading: true },
      };
    case rolesConstants.GET_SINGLE_ROLES_SUCCESS:
      return {
        ...state,
        getSingleRole: {
          loading: false,
          success: true,
          data: payload,
          error: "",
        },
      };
    case rolesConstants.GET_SINGLE_ROLES_ERROR:
      return {
        ...state,
        getSingleRole: {
          ...state.getSingleRole,
          loading: false,
          error: payload,
          success: false,
        },
      };
    default:
      return state;
  }
};

export default rolesState;
