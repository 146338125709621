import { userConstants } from "../../actions/users/users.actions";
import intialState from "../../initialState/users/users";

const usersState = (state = intialState, { type, payload }) => {
  switch (type) {
    case userConstants.CURRRENT_USER_INFO_REQUEST:
      return {
        ...state,
        currentUserInfo: {
          ...state.currentUserInfo,
          loading: true,
        },
      };
    case userConstants.CURRRENT_USER_INFO_SUCCESS:
      return {
        ...state,
        currentUserInfo: {
          ...state.currentUserInfo,
          loading: false,
          success: true,
          data: payload,
        },
      };
    case userConstants.CURRRENT_USER_INFO_ERROR:
      return {
        ...state,
        currentUserInfo: {
          loading: false,
          success: true,
          data: payload.data,
          error: payload.error,
        },
      };
    case userConstants.SET_PROFILE_REQUEST:
      return {
        ...state,
        profile: { ...state.profile, loading: true },
      };
    case userConstants.SET_PROFILE_SUCCESS:
      return {
        ...state,
        profile: {
          data: payload,
          error: "",
          success: true,
          loading: false,
        },
      };
    case userConstants.SET_PROFILE_ERROR:
      return {
        ...state,
        profile: {
          ...state.profile,
          loading: false,
          success: false,
          error: payload,
        },
      };
    case userConstants.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        changePassword: { ...state.changePassword, loading: true },
      };
    case userConstants.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePassword: {
          error: "",
          loading: false,
          success: true,
          message: payload,
        },
      };
    case userConstants.CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          loading: false,
          error: payload,
          success: false,
        },
      };
    case userConstants.CHANGE_PASSWORD_COMPLETED:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          success: false,
        },
      };
    default:
      return state;
  }
};

export default usersState;
