import { Modal, Form, Button, message, Alert, Select, DatePicker } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { noticeOfBestBiddersActions } from "../../../../config/actions/procument/noticeOfBestBidder/noticeOfBestBidder.actions";
import moment from "moment";

const { Option } = Select;

const EditNoticeOfBestBidderForm = ({
  visible,
  handleEditRecord,
  handleCloseModal,
}) => {
  const {
    editNoticeOfBestBiddersLoading,
    editNoticeOfBestBiddersMessage,
    editNoticeOfBestBiddersSuccess,
    editNoticeOfBestBiddersError,
    editNoticeOfBestBiddersData,
    noticeOfBestBidders,
    noticeOfBestBiddersMetaData,
    noticeOfBestBiddersMetaDataLoading,
  } = useSelector((state) => state.noticeOfBestBidderState);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const fields = [
    { name: "rfq", value: editNoticeOfBestBiddersData.rfqno },
    {
      name: "date_displayed",
      value: moment(editNoticeOfBestBiddersData.date_displayed),
    },
    {
      name: "date_removed",
      value: moment(editNoticeOfBestBiddersData.date_removed),
    },
  ];

  const closeModal = () => handleCloseModal(false);
  const onFinish = (values) => {
    values.updated_at = new Date();
    values.id = editNoticeOfBestBiddersData.id;
    values.rfqno = editNoticeOfBestBiddersData.rfqno;
    dispatch(noticeOfBestBiddersActions.editNoticeOfBestBiddersData(values));
    const noticeOfBestBiddersArray = noticeOfBestBidders.filter(
      (form) => form.id !== editNoticeOfBestBiddersData.id
    );
    handleEditRecord(values, noticeOfBestBiddersArray);
  };

  useEffect(() => {
    if (editNoticeOfBestBiddersSuccess && editNoticeOfBestBiddersMessage) {
      message.success(editNoticeOfBestBiddersMessage);
      handleCloseModal(false);
    }
  }, [
    handleCloseModal,
    editNoticeOfBestBiddersSuccess,
    editNoticeOfBestBiddersMessage,
  ]);

  return (
    <div>
      <Modal
        footer={null}
        onCancel={closeModal}
        title="GENERATE NOTICE OF BEST BIDDER FORM"
        open={visible}
      >
        <Form
          fields={fields}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          layout="horizontal"
          form={form}
          onFinish={onFinish}
        >
          {editNoticeOfBestBiddersError && !editNoticeOfBestBiddersSuccess && (
            <Alert
              type="error"
              message={editNoticeOfBestBiddersError}
              showIcon
              className="m-2"
            />
          )}
          <Form.Item
            rules={[
              {
                required: true,
                message: "Please select a Request For Qoutation No.",
              },
            ]}
            label="Rfq No."
            name="rfq"
          >
            <Select
              disabled
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              loading={noticeOfBestBiddersMetaDataLoading}
            >
              {noticeOfBestBiddersMetaData?.rfqs?.map((rfq) => (
                <Option key={rfq.id} value={rfq.id}>
                  {rfq.rfqno}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="date_displayed"
            label="Date Displayed"
            rules={[
              { required: true, message: "Please Select Date Displayed" },
            ]}
          >
            <DatePicker className="w-100" />
          </Form.Item>
          <Form.Item
            name="date_removed"
            label="Date Removed 1"
            rules={[{ required: true, message: "Please Select  Date Removed" }]}
          >
            <DatePicker className="w-100" />
          </Form.Item>
          <Form.Item className={"mt-3"} wrapperCol={{ offset: 6 }}>
            <Button
              loading={editNoticeOfBestBiddersLoading}
              htmlType="submit"
              type="primary"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default EditNoticeOfBestBidderForm;
