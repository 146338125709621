import { staffRecordsActions } from "../../../actions/administration/staffRecords/staffRecords.actions";
import { appUiActions } from "../../../actions/app/appUi.actions";
import staffRecordsApiRequests from "../../../api/administration/staffRecords/staffRecords.api";
import apiRequests from "../../../api/api";

const errorMessage = "Sorry there seems to be an error, please try again";

const staffRecordsThunks = {
  getStaffRecords: () => async (dispatch) => {
    try {
      dispatch(staffRecordsActions.setStaffRecordsRequest());
      const res = await staffRecordsApiRequests.fetchStaffRecords();
      if (res.success) {
        dispatch(staffRecordsActions.setStaffRecordsSuccess(res.data));
      } else dispatch(staffRecordsActions.setStaffRecordsError(res.error));
    } catch (error) {
      console.error("Staff records thunk error: ", error);
      dispatch(staffRecordsActions.setStaffRecordsError(errorMessage));
    }
  },
  addStaffRecord: (body) => async (dispatch) => {
    try {
      dispatch(staffRecordsActions.addStaffRecordRequest());
      const res = await staffRecordsApiRequests.addStaffRecords(body);
      if (res.success) {
        dispatch(
          staffRecordsActions.addStaffRecordSuccess({
            message: res.message,
            data: res.data,
          })
        );
        dispatch(staffRecordsActions.addStaffRecordCompleted());
      } else dispatch(staffRecordsActions.addStaffRecordError(res.error));
    } catch (error) {
      console.error("Add staff thunk error", error);
      dispatch(staffRecordsActions.addStaffRecordError(errorMessage));
    }
  },
  updateStaffRecords: (body, staffArray) => async (dispatch) => {
    try {
      dispatch(staffRecordsActions.updateStaffRequest());
      const res = await staffRecordsApiRequests.updateStaffRecord(body);
      if (res.success) {
        dispatch(
          staffRecordsActions.updateStaffSuccess({
            message: res.message,
            data: [res.data, ...staffArray],
          })
        );
        dispatch(appUiActions.toggleEditStaffRecordsModal(false));
        dispatch(staffRecordsActions.updateStaffCompleted());
      } else dispatch(staffRecordsActions.updateStaffError(res.error));
    } catch (error) {
      console.error("Update Staff Records thunk error:", error);
      dispatch(staffRecordsActions.updateStaffError(errorMessage));
    }
  },
  deleteStaffRecords: (id, staffArray) => async (dispatch) => {
    try {
      dispatch(staffRecordsActions.deleteStaffRequest());
      const res = await staffRecordsApiRequests.deleteStaffRecord(id);
      if (res.success) {
        dispatch(
          staffRecordsActions.deleteStaffSuccess({
            message: res.message,
            data: staffArray,
          })
        );
        dispatch(staffRecordsActions.deleteStaffCompleted());
      } else dispatch(staffRecordsActions.deleteStaffError(res.error));
    } catch (error) {
      console.error("Delete staff records thunk error:", error);
      dispatch(staffRecordsActions.deleteStaffError(errorMessage));
      dispatch(staffRecordsActions.deleteStaffCompleted());
    }
  },
  getStaffDetails: (id) => async (dispatch) => {
    try {
      dispatch(staffRecordsActions.setStaffDetailsRequest());
      const res = await staffRecordsApiRequests.fetchStaffDetails(id);
      if (res.success) {
        dispatch(staffRecordsActions.setStaffDetailsSuccess(res.data));
      } else dispatch(staffRecordsActions.setStaffDetailsError(res.error));
    } catch (error) {
      console.error("Staff details thunk error:", error);
      dispatch(staffRecordsActions.setStaffDetailsError(errorMessage));
    }
  },
  activateStaffAccount: (id) => async (dispatch, state) => {
    try {
      dispatch(staffRecordsActions.staffAccountStatusSuccess());
      const res = await staffRecordsApiRequests.activateAccount(id);
      if (res.success) {
        dispatch(staffRecordsActions.staffAccountStatusSuccess(res.message));
        dispatch(staffRecordsThunks.getStaffDetails(id));
      } else dispatch(staffRecordsActions.staffAccountStatusError(res.error));
    } catch (error) {
      console.error(" Activate Account status thunk error: ", error);
      dispatch(staffRecordsActions.staffAccountStatusError(errorMessage));
    }
    dispatch(staffRecordsActions.staffAccountStatusCompleted());
  },
  deactivateStaffAccount: (id) => async (dispatch) => {
    try {
      dispatch(staffRecordsActions.staffAccountStatusRequest());
      const res = await staffRecordsApiRequests.deactivateAccount(id);
      if (res.success) {
        dispatch(staffRecordsActions.staffAccountStatusSuccess(res.message));
        dispatch(staffRecordsThunks.getStaffDetails(id));
      } else dispatch(staffRecordsActions.staffAccountStatusError(res.error));
    } catch (error) {
      console.error("Activate Account status thunk error: ", error);
      dispatch(staffRecordsActions.staffAccountStatusError(errorMessage));
    }
    dispatch(staffRecordsActions.staffAccountStatusCompleted());
  },
  updateProfilePicture: (id, body) => async (dispatch) => {
    try {
      dispatch(staffRecordsActions.updateProfilePictureRequest());
      const res = await staffRecordsApiRequests.updateProfilePicture(id, body);
      if (res.success) {
        dispatch(staffRecordsActions.updateProfilePictureSuccess(res.message));
        dispatch(staffRecordsActions.updateProfilePictureCompleted());
        dispatch(appUiActions.toggleChangeProfilePictureModal(false));
        dispatch(staffRecordsThunks.getStaffDetails(id));
      } else dispatch(staffRecordsActions.updateProfilePictureError(res.error));
    } catch (error) {
      console.error("Update profile picuture thunk error", error);
      dispatch(staffRecordsActions.updateProfilePictureError(errorMessage));
    }
  },
  generateNewConfirmToken: (body) => async (dispatch) => {
    dispatch(staffRecordsActions.generateNewConfirmTokenRequest());
    const res = await staffRecordsApiRequests.generateNewConfirmToken(body);
    if (res.success)
      dispatch(staffRecordsActions.generateNewConfirmTokenSuccess(res.message));
    else dispatch(staffRecordsActions.generateNewConfirmTokenError(res.error));
    dispatch(staffRecordsActions.generateNewConfirmTokenComplete());
  },
  uploadStaffSignature: (body, staffId) => async (dispatch) => {
    dispatch(staffRecordsActions.uploadStaffSignatureRequest());
    const res = await apiRequests.filePostRequest(
      `/administration/staffRecords/signatures/${staffId}`,
      body
    );
    if (res.success)
      dispatch(staffRecordsActions.uploadStaffSignatureSuccess(res.message));
    else dispatch(staffRecordsActions.uploadStaffSignatureError(res.error));
    dispatch(staffRecordsActions.uploadStaffSignatureComplete());
  },
};
export default staffRecordsThunks;
