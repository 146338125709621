import React, { useEffect } from "react";
import { Divider, Form, message } from "antd";
import { flatMap } from "lodash";
import { useActivityNoteStore } from "../../../../config/stores";
import usePrevious from "../../../../components/hooks/usePrevious";
import AntdModal from "../../../../components/common/AntdModal";
import AppButton from "../../../../components/common/AppButton";
import AppInput from "../../../../components/common/AppInput";
import dayjs from "dayjs";

const ActivitiesForm = () => {
  const activityNotesStore = useActivityNoteStore();
  const {
    createdActivity,
    creatingActivities,
    creatingActivity,
    activityToUpdate,
    activityNoteDetails,
    showActivityForm,
    updatingActivities,
  } = activityNotesStore;
  const [form] = Form.useForm();
  const prevState = usePrevious({ createdActivity });

  const closeModal = () => {
    activityNotesStore.setShowActivitiesForm();
  };

  const onSubmit = () => {
    form
      .validateFields()
      .then((data) => {
        if (creatingActivity) {
          data.activity_concept_note_id = activityNoteDetails.id;
          activityNotesStore.createActivities(data, activityNoteDetails.id);
        } else {
          activityNotesStore.updateActivities(
            activityToUpdate.id,
            data,
            activityNoteDetails.id
          );
        }
      })
      .catch((err) => {
        const errorMessages = flatMap(err.errorFields, "errors").join(", ");
        message.error(errorMessages);
      });
  };

  useEffect(() => {
    if (
      prevState &&
      createdActivity &&
      prevState.createdActivity !== createdActivity
    ) {
      form.resetFields();
    }
  }, [prevState, form, createdActivity]);

  useEffect(() => {
    if (creatingActivity) {
      form.resetFields();
    } else {
      form.setFieldsValue({
        ...activityToUpdate,
        start: dayjs(activityToUpdate.start),
        end: dayjs(activityToUpdate.end),
      });
    }
  }, [creatingActivity, activityToUpdate, form]);

  return (
    <AntdModal
      title={creatingActivity ? "ADD ACTIVITY" : "EDIT ACTIVITY"}
      zIndex={1000}
      open={showActivityForm}
      closeModal={closeModal}
      footer={[
        <AppButton
          text={creatingActivity ? "CREATE ACTIVITY" : "UPDATE ACTIVITY"}
          key={"create"}
          loading={creatingActivities || updatingActivities}
          onClick={onSubmit}
        />,
        <AppButton text="CANCEL" key={"cancel"} onClick={closeModal} danger />,
      ]}
    >
      <Form layout="vertical" form={form}>
        <AppInput
          label="Session"
          name={"session"}
          rules={[
            {
              required: true,
              message: "Please input this field",
            },
          ]}
        />
        <AppInput
          label="Person Responsible"
          name={"person_responsible"}
          rules={[
            {
              required: true,
              message: "Please input this field",
            },
          ]}
        />
        <AppInput
          label="Start"
          name="start"
          type="date"
          inputAttributes={{
            showTime: true,
          }}
          rules={[
            {
              required: true,
              message: "Start is required",
            },
          ]}
        />
        <AppInput
          label="End"
          name="end"
          type="date"
          inputAttributes={{
            showTime: true,
          }}
          rules={[
            {
              required: true,
              message: "End is required",
            },
          ]}
        />
      </Form>
      <Divider className="m-1" />
    </AntdModal>
  );
};

export default ActivitiesForm;
