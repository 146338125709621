import React from "react";
import { useReceiptsStore } from "../../../../config/stores";
import AntdModal from "../../../../components/common/AntdModal";
import AntdTable from "../../../../components/common/AntdTable";
import dayjs from "dayjs";

const ReceiptDetails = () => {
  const receiptsStore = useReceiptsStore();
  const { receiptDetails } = receiptsStore;

  const closeModal = () => {
    receiptsStore.setShowViewReceiptDetails(false);
  };

  return (
    <div>
      <AntdModal
        open={receiptsStore.showReceiptDetails}
        title={"RECEIPT DETAILS"}
        footer={null}
        closeModal={closeModal}
      >
        <AntdTable
          showHeader={false}
          bordered={false}
          loading={receiptsStore.gettingReceiptDetails}
          columns={[
            {
              dataIndex: "title",
            },
            {
              dataIndex: "value",
              render: (value) => <span className="fw-bold">{value}</span>,
            },
          ]}
          data={[
            {
              key: 1,
              title: "Date",
              value: dayjs(receiptDetails?.date).format("DD/MM/YYYY"),
            },
            {
              key: 2,
              title: "Amount",
              value: receiptDetails?.amount?.toLocaleString(),
            },
            {
              key: 3,
              title: "Account",
              value: receiptDetails?.account?.name,
            },
            {
              key: 3,
              title: "Donor",
              value: receiptDetails?.donor?.name,
            },
            {
              key: 4,
              title: "Project",
              value: receiptDetails?.project?.name,
            },
            {
              key: 5,
              title: "Created By",
              value: `${receiptDetails?.created_by?.first_name || ""} ${
                receiptDetails?.created_by?.other_names || ""
              }`,
            },
            {
              key: 1,
              title: "Created At",
              value: dayjs(receiptDetails?.created_at).format(
                "DD/MM/YYYY HH:mm"
              ),
            },
            {
              key: 5,
              title: "Updated By",
              value: `${receiptDetails?.updated_by?.first_name || ""} ${
                receiptDetails?.updated_by?.other_names || ""
              }`,
            },
            {
              key: 1,
              title: "Updated At",
              value: dayjs(receiptDetails?.updated_at).format(
                "DD/MM/YYYY HH:mm"
              ),
            },
          ]}
        />
      </AntdModal>
    </div>
  );
};

export default ReceiptDetails;
