import { create } from "zustand";
import { produce } from "immer";
import { devtools } from "zustand/middleware";
import axios from "axios";
import { findIndex } from "lodash";

const initialState = {
  assetIncidentForms: {},
  gettingAssetIncidentForms: false,
  gettingAssetIncidentFormsError: null,

  creating: false,
  showAssetIncidentForm: false,
  createdAssetIncidentForm: null,
  createAssetIncidentFormError: null,
  creatingAssetIncidentForm: false,

  assetIncidentFormToUpdate: {},
  updatingAssetIncidentForm: false,
  updateAssetIncidentFormError: null,
  showUpdateAssetIncidentForm: false,
  updatedAssetIncidentForm: null,

  deletingAssetIncidentForm: false,
  deleteAssetIncidentFormError: null,

  gettingAssetIncidentFormDetails: false,
  gettingAssetIncidentFormDetailsError: null,
  assetIncidentFormDetails: {},
  showAssetIncidentFormDetails: false,

  approvingAssetIncidentForm: false,
  approveAssetIncidentFormError: null,

  metaData: false,
  gettingMetaData: false,

  checkingAssetIncidentForm: false,
  checkAssetIncidentFormError: null,

  submittingAssetIncidentForm: false,
  submitAssetIncidentFormError: null,
};

const store = (set, get) => ({
  ...initialState,

  // actions
  async getAssetIncidentForms() {
    try {
      set(
        produce((state) => {
          state.gettingAssetIncidentForms = true;
        })
      );

      const res = await axios({
        method: "GET",
        url: "/administration/asset-incident-forms",
      });

      set(
        produce((state) => {
          state.assetIncidentForms = res;
        })
      );
    } catch (error) {
      set(
        produce((state) => {
          state.gettingAssetIncidentFormsError = error;
        })
      );
    } finally {
      set(
        produce((state) => {
          state.gettingAssetIncidentForms = false;
        })
      );
    }
  },

  async createAssetIncidentForm(data) {
    set(
      produce((state) => {
        state.creatingAssetIncidentForm = true;
        state.createdAssetIncidentForm = null;
      })
    );
    await axios({
      data,
      method: "POST",
      url: "/administration/asset-incident-forms",
    })
      .then((res) => {
        set(
          produce((state) => {
            state.createdAssetIncidentForm = res;
          })
        );
        get().getAssetIncidentForms();
      })
      .catch((error) => {
        set(
          produce((state) => {
            state.createAssetIncidentFormError = error;
          })
        );
      })
      .finally(() => {
        set(
          produce((state) => {
            state.creatingAssetIncidentForm = false;
          })
        );
      });
  },

  async getAssetIncidentFormDetails(id) {
    try {
      set(
        produce((state) => {
          state.gettingAssetIncidentFormDetails = true;
          state.gettingAssetIncidentFormDetailsError = null;
        })
      );

      const res = await axios({
        method: "GET",
        url: `/administration/asset-incident-forms/${id}`,
      });

      set(
        produce((state) => {
          state.assetIncidentFormDetails = res;
        })
      );
    } catch (error) {
      set(
        produce((state) => {
          state.gettingAssetIncidentFormDetailsError = error;
        })
      );
    } finally {
      set(
        produce((state) => {
          state.gettingAssetIncidentFormDetails = false;
        })
      );
    }
  },
  async updateAssetIncidentForm(data, id) {
    set(
      produce((state) => {
        state.updatingAssetIncidentForm = true;
      })
    );

    await axios({
      data,
      method: "PUT",
      url: `/administration/asset-incident-forms/${id}`,
    })
      .then((res) => {
        set(
          produce((state) => {
            state.updatedAssetIncidentForm = res;
          })
        );
        get().getAssetIncidentForms();
        get().setCreating();
      })
      .catch((error) => {
        set(
          produce((state) => {
            state.updateAssetIncidentFormError = error;
          })
        );
      })
      .finally(() => {
        set(
          produce((state) => {
            state.updatingAssetIncidentForm = false;
          })
        );
      });
  },

  async deleteAssetIncidentForm(id) {
    set(
      produce((state) => {
        state.deletingAssetIncidentForm = true;
      })
    );

    await axios({
      method: "DELETE",
      url: `/administration/asset-incident-forms/${id}`,
    })
      .then(() => {
        get().getAssetIncidentForms();
      })
      .catch((error) => {
        set(
          produce((state) => {
            state.deleteAssetIncidentFormError = error;
          })
        );
      })
      .finally(() => {
        set(
          produce((state) => {
            state.deletingAssetIncidentForm = false;
          })
        );
      });
  },

  async approveAssetIncidentForm(id) {
    set(
      produce((state) => {
        state.approvingAssetIncidentForm = true;
      })
    );

    await axios({
      method: "PATCH",
      url: `/administration/asset-incident-forms/approve/${id}`,
    })
      .then(() => {
        get().getAssetIncidentFormDetails(id);
        set(
          produce((state) => {
            const formIndex = findIndex(get().assetIncidentForms?.data, {
              id,
            });
            if (formIndex >= 0)
              state.assetIncidentForms.data[formIndex].status = "approved";
          })
        );
      })
      .catch((error) => {
        set(
          produce((state) => {
            state.approveAssetIncidentFormError = error;
          })
        );
      })
      .finally(() => {
        set(
          produce((state) => {
            state.approvingAssetIncidentForm = false;
          })
        );
      });
  },

  async getMetaData() {
    set(
      produce((state) => {
        state.gettingMetaData = true;
      })
    );

    await axios({
      method: "GET",
      url: "/administration/asset-incident-forms/meta-data",
    })
      .then((res) => {
        set(
          produce((state) => {
            state.metaData = res;
          })
        );
      })
      .catch((error) => {
        set(
          produce((state) => {
            state.gettingMetaDataError = error;
          })
        );
      })
      .finally(() => {
        set(
          produce((state) => {
            state.gettingMetaData = false;
          })
        );
      });
  },

  async submitAssetIncidentForm(id) {
    set(
      produce((state) => {
        state.submittingAssetIncidentForm = true;
      })
    );

    await axios({
      method: "PATCH",
      url: `/administration/asset-incident-forms/submit/${id}`,
    })
      .then(() => {
        get().getAssetIncidentFormDetails(id);
      })
      .catch((error) => {
        set(
          produce((state) => {
            state.submitAssetIncidentFormError = error;
          })
        );
      })
      .finally(() => {
        set(
          produce((state) => {
            state.submittingAssetIncidentForm = false;
          })
        );
      });
  },

  async checkAssetIncidentForm(id) {
    set(
      produce((state) => {
        state.checkingAssetIncidentForm = true;
      })
    );

    await axios({
      method: "PATCH",
      url: `/administration/asset-incident-forms/check/${id}`,
    })
      .then(() => {
        get().getAssetIncidentFormDetails(id);
      })
      .catch((error) => {
        set(
          produce((state) => {
            state.checkAssetIncidentFormError = error;
            const formIndex = findIndex(get().assetIncidentForms?.data, {
              id,
            });
            if (formIndex >= 0)
              state.assetIncidentForms.data[formIndex].status = "checked";
          })
        );
      })
      .finally(() => {
        set(
          produce((state) => {
            state.checkingAssetIncidentForm = false;
          })
        );
      });
  },

  setShowAssetIncidentFormDetails(show) {
    set(
      produce((state) => {
        state.showAssetIncidentFormDetails = show;
      })
    );
  },

  setCreating(creating = false, show = false, editData = {}) {
    set(
      produce((state) => {
        state.creating = creating;
        state.showAssetIncidentForm = show;
        state.assetIncidentFormToUpdate = editData;
      })
    );
  },
});
const storeName = "ASSET INCIDENT FORMS";
const useAssetIncidentFormStore = create(devtools(store, { name: storeName }), {
  name: storeName,
});

export default useAssetIncidentFormStore;
