import React, { useEffect } from "react";
import { Button, Space, Form, Select, Input, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { rejectionsActions } from "../../../../config/actions/rejections/rejections.actions";
import appConfig from "../../../../config/config.json";

const SubmitBidEvaluationForm = ({
  handleSubmit,
  handleApprove,
  handleCheck,
  handleRejection,
}) => {
  const {
    bidEvaluationsDetails,
    bidEvaluationsMetaDataLoading,
    bidEvaluationsMetaData,
    submitBidEvaluationsLoading,
    submitBidEvaluationsSuccess,
    submitBidEvaluationsMessage,
    submitBidEvaluationsError,
    approveBidEvaluationsLoading,
    approveBidEvaluationsError,
    approveBidEvaluationsSuccess,
    approveBidEvaluationsMessage,
    checkBidEvaluationsLoading,
    checkBidEvaluationsSuccess,
    checkBidEvaluationsMessage,
    checkBidEvaluationsError,
  } = useSelector((state) => state.bidEvaluationState);
  const dispatch = useDispatch();

  const onFinish = (values) => {
    values.updated_at = new Date();
    values.id = bidEvaluationsDetails.id;
    handleSubmit(values);
  };
  const check = () => handleCheck(bidEvaluationsDetails.id);
  const approve = () => handleApprove(bidEvaluationsDetails.id);
  const reject = () => {
    handleRejection(true);
    dispatch(
      rejectionsActions.addProcurementRejectionsData({
        user_to_id: bidEvaluationsDetails?.created_by,
        item_application: "Bid Evaluation Form",
        item_id: bidEvaluationsDetails?.id,
        itemName: `Bid Evaluation Form ${bidEvaluationsDetails.id}`,
        applicationPath: "bidEvaluations",
      })
    );
  };

  const fields = [
    { name: "details", value: bidEvaluationsDetails.details },
    { name: "bestBidder", value: bidEvaluationsMetaData.bestBidder?.id },
  ];

  useEffect(() => {
    if (submitBidEvaluationsMessage && submitBidEvaluationsSuccess)
      message.success(submitBidEvaluationsMessage);
    else if (!submitBidEvaluationsSuccess && submitBidEvaluationsError)
      message.error(submitBidEvaluationsError);

    if (checkBidEvaluationsMessage && checkBidEvaluationsSuccess)
      message.success(checkBidEvaluationsMessage);
    else if (!checkBidEvaluationsSuccess && checkBidEvaluationsError)
      message.error(checkBidEvaluationsError);

    if (approveBidEvaluationsMessage && approveBidEvaluationsSuccess)
      message.success(approveBidEvaluationsMessage);
    else if (!approveBidEvaluationsSuccess && approveBidEvaluationsError)
      message.error(approveBidEvaluationsError);
  }, [
    submitBidEvaluationsError,
    submitBidEvaluationsMessage,
    submitBidEvaluationsSuccess,
    approveBidEvaluationsError,
    approveBidEvaluationsSuccess,
    approveBidEvaluationsMessage,
    checkBidEvaluationsSuccess,
    checkBidEvaluationsMessage,
    checkBidEvaluationsError,
  ]);

  return (
    <div>
      {bidEvaluationsDetails.submitted &&
        bidEvaluationsDetails.status !== appConfig.status.approved && (
          <Space className="my-2">
            <Button
              loading={approveBidEvaluationsLoading}
              onClick={approve}
              type="primary"
            >
              Approve
            </Button>
            {bidEvaluationsDetails.status !== appConfig.status.checked && (
              <Button loading={checkBidEvaluationsLoading} onClick={check}>
                Check
              </Button>
            )}
            <Button onClick={reject} danger>
              Reject
            </Button>
          </Space>
        )}
      <Form
        fields={fields}
        className="bg-white p-2"
        layout="vertical"
        onFinish={onFinish}
      >
        {bidEvaluationsDetails.submitted ? (
          <h6>Bid Evaluation Form Status</h6>
        ) : (
          <h6>Submit Form</h6>
        )}
        <Form.Item
          rules={[{ required: true, message: "Please Recomend a best bidder" }]}
          name="bestBidder"
          label="Recomended Best Bidder"
        >
          <Select
            loading={bidEvaluationsMetaDataLoading}
            defaultValue=""
            allowClear
          >
            <Select.Option value={"None"} key="none*">
              None
            </Select.Option>
            {bidEvaluationsMetaData?.bidders?.map((bidder) => {
              return (
                <Select.Option value={bidder.id} key={bidder.id}>
                  {bidder.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: "Please Recomend a best bidder" }]}
          name="details"
          label="Details"
        >
          <Input.TextArea rows={5} />
        </Form.Item>
        <Form.Item>
          <Button
            disabled={
              bidEvaluationsDetails.status === appConfig.status.approved
                ? true
                : bidEvaluationsDetails.status === appConfig.status.rejected
                ? true
                : false
            }
            loading={submitBidEvaluationsLoading}
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SubmitBidEvaluationForm;
