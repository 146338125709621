import React from "react";
import { Doughnut } from "react-chartjs-2";
import { useSelector } from "react-redux";

const ProjectsPieChart = () => {
  const { projectsGraphData } = useSelector((state) => state.projectsState);

  const data = {
    labels: ["Completed Projects", "Active Projects"],
    datasets: [
      {
        barThickness: 25,
        label: "Approved Budget",
        data: [
          projectsGraphData.runningProjectsGraph?.completedProjects,
          projectsGraphData.runningProjectsGraph?.runningProjects,
        ],
        backgroundColor: ["rgb(54, 162, 235,1)", "rgb(255, 99, 132,1)"],
        borderColor: ["rgb(54, 162, 235,1)", "rgb(255, 99, 132,1)"],
        borderWidth: 1,
      },
    ],
  };
  return (
    <div className="w-25 m-1 bar-graph shadow p-2 w-sm-100">
      <h5>
        {projectsGraphData.runningProjectsGraph?.totalProjects} Total Projects
      </h5>
      <div className="d-flex align-items-center h-100">
        <Doughnut
          data={data}
          options={{
            plugins: {
              legend: { display: true, position: "top", align: "start" },
            },
          }}
        />
      </div>
    </div>
  );
};

export default ProjectsPieChart;
