import { Button, Popover, Table, Typography } from "antd";
import React from "react";
import {
  getCurrency,
  numberWithCommas,
} from "../../../../config/helpers/app/numberFormater";
import tableButtonActions from "../../../shared/Tables/TableButtonActions";

const { Text } = Typography;

const BudgetLines = (record, config, budgetDetails) => {
  const {
    deleteId,
    deleteBudgetActivityLoading: deleteLoading,
    deleteRecord,
  } = config;
  const viewDetails = (e) => config.viewDetails(e.target.id);
  const confirmDelete = () => config.confirmDelete();
  const updateRecord = (e) =>
    config.updateRecord(e.target.id, record?.activities);

  const currency = getCurrency(budgetDetails, "approved_budget");
  const columns = [
    {
      key: "name",
      title: "Cost Drivers",
      render: (text) => (
        <div>
          <span className="d-sm-none">Activity:</span> {text.name} <br />
          <div className="d-sm-none">
            Units: {text.units} <br />
            Unit Cost:{" "}
            {`${currency === "D" ? "USD" : "UGX"} ${numberWithCommas(
              parseInt(text.unit_cost)
            )}`}{" "}
            <br />
            Total Cost:{" "}
            {`${currency === "D" ? "USD" : "UGX"} ${numberWithCommas(
              parseInt(text.total_cost)
            )}`}{" "}
            <br />
            <Popover
              className=""
              trigger="click"
              placement="bottom"
              content={() =>
                tableButtonActions(text, {
                  viewDetails,
                  updateRecord,
                  confirmDelete,
                  deleteRecord,
                  deleteLoading,
                  deleteId,
                })
              }
            >
              Actions: <Button type="link">Actions</Button>
            </Popover>
          </div>
        </div>
      ),
    },
    { key: "units", title: "Units", dataIndex: "units", responsive: ["md"] },
    {
      key: "unit_cost",
      title: "Unit Cost",
      dataIndex: "unit_cost",
      render: (text) => {
        return `${currency === "D" ? "USD" : "UGX"} ${numberWithCommas(
          parseInt(text)
        )}`;
      },
      responsive: ["md"],
    },
    {
      key: "total_cost",
      title: "Activity Total Cost",
      dataIndex: "total_cost",
      render: (text) => {
        return `${currency === "D" ? "USD" : "UGX"} ${numberWithCommas(
          parseInt(text)
        )}`;
      },
      responsive: ["md"],
    },
    {
      title: "Action",
      key: "operation",
      render: (text) => (
        <Popover
          trigger="click"
          placement="bottom"
          content={() =>
            tableButtonActions(text, {
              viewDetails,
              updateRecord,
              confirmDelete,
              deleteRecord,
              deleteLoading,
              deleteId,
            })
          }
        >
          <Button>Actions</Button>
        </Popover>
      ),
      responsive: ["md"],
    },
  ];
  return (
    <div>
      <Table
        dataSource={record?.activities}
        columns={columns}
        bordered
        rowKey={(r) => r.id}
        summary={(records) => {
          let subTotal = 0;
          records.forEach((record) => {
            subTotal += record.total_cost;
          });
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell>
                  <Text type="warning">
                    Sub Total:{" "}
                    <span className="">
                      {`${currency === "D" ? "USD" : "UGX"} ${numberWithCommas(
                        subTotal
                      )}`}
                    </span>
                  </Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </div>
  );
};

export default BudgetLines;
