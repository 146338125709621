import { noticeOfBestBiddersConstants } from "../../../actions/procument/noticeOfBestBidder/noticeOfBestBidder.actions";
import initialState from "../../../initialState/procurement/noticeOfBestBidder/noticeOfBestBidder";

const noticeOfBestBidderState = (state = initialState, { type, payload }) => {
  switch (type) {
    case noticeOfBestBiddersConstants.SET_NOTICE_OF_BEST_BIDDER_LOADING:
      return {
        ...state,
        noticeOfBestBiddersLoading: true,
      };
    case noticeOfBestBiddersConstants.SET_NOTICE_OF_BEST_BIDDER_SUCCESS:
      return {
        ...state,
        noticeOfBestBiddersSuccess: true,
        noticeOfBestBiddersLoading: false,
        noticeOfBestBiddersError: "",
        noticeOfBestBidders: payload,
      };
    case noticeOfBestBiddersConstants.SET_NOTICE_OF_BEST_BIDDER_ERROR:
      return {
        ...state,
        noticeOfBestBiddersError: payload,
        noticeOfBestBiddersLoading: false,
        noticeOfBestBiddersSuccess: false,
      };
    case noticeOfBestBiddersConstants.ADD_NOTICE_OF_BEST_BIDDER_LOADING:
      return {
        ...state,
        addNoticeOfBestBiddersLoading: true,
      };
    case noticeOfBestBiddersConstants.ADD_NOTICE_OF_BEST_BIDDER_SUCCESS:
      return {
        ...state,
        addNoticeOfBestBiddersLoading: false,
        addNoticeOfBestBiddersSuccess: true,
        addNoticeOfBestBiddersError: "",
        addNoticeOfBestBiddersMessage: payload.message,
        noticeOfBestBidders: payload.data,
      };
    case noticeOfBestBiddersConstants.ADD_NOTICE_OF_BEST_BIDDER_ERROR:
      return {
        ...state,
        addNoticeOfBestBiddersLoading: false,
        addNoticeOfBestBiddersSuccess: false,
        addNoticeOfBestBiddersMessage: "",
        addNoticeOfBestBiddersError: payload,
      };
    case noticeOfBestBiddersConstants.ADD_NOTICE_OF_BEST_BIDDER_COMPLETE:
      return {
        ...state,
        addNoticeOfBestBiddersSuccess: false,
      };
    case noticeOfBestBiddersConstants.SET_NOTICE_OF_BEST_BIDDER_META_DATA_LOADING:
      return {
        ...state,
        noticeOfBestBiddersMetaDataLoading: true,
      };
    case noticeOfBestBiddersConstants.SET_NOTICE_OF_BEST_BIDDER_META_DATA:
      return {
        ...state,
        noticeOfBestBiddersMetaData: payload,
        noticeOfBestBiddersMetaDataLoading: false,
      };

    case noticeOfBestBiddersConstants.SET_NOTICE_OF_BEST_BIDDER_DETAILS_LOADING:
      return {
        ...state,
        noticeOfBestBiddersDetailsLoading: true,
      };
    case noticeOfBestBiddersConstants.SET_NOTICE_OF_BEST_BIDDER_DETAILS_SUCCESS:
      return {
        ...state,
        noticeOfBestBiddersDetailsSuccess: true,
        noticeOfBestBiddersDetails: payload,
        noticeOfBestBiddersDetailsError: "",
        noticeOfBestBiddersDetailsLoading: false,
      };
    case noticeOfBestBiddersConstants.SET_NOTICE_OF_BEST_BIDDER_DETAILS_ERROR:
      return {
        ...state,
        noticeOfBestBiddersDetailsSuccess: false,
        noticeOfBestBiddersDetailsError: payload,
        noticeOfBestBiddersDetailsLoading: false,
      };
    case noticeOfBestBiddersConstants.EDIT_NOTICE_OF_BEST_BIDDER_DATA:
      return {
        ...state,
        editNoticeOfBestBiddersData: payload,
      };
    case noticeOfBestBiddersConstants.EDIT_NOTICE_OF_BEST_BIDDER_REQUEST:
      return {
        ...state,
        editNoticeOfBestBiddersLoading: true,
      };
    case noticeOfBestBiddersConstants.EDIT_NOTICE_OF_BEST_BIDDER_SUCCESS:
      return {
        ...state,
        editNoticeOfBestBiddersSuccess: true,
        editNoticeOfBestBiddersLoading: false,
        editNoticeOfBestBiddersMessage: payload.message,
        editNoticeOfBestBiddersError: "",
        noticeOfBestBidders: payload.data,
      };
    case noticeOfBestBiddersConstants.EDIT_NOTICE_OF_BEST_BIDDER_ERROR:
      return {
        ...state,
        editNoticeOfBestBiddersSuccess: false,
        editNoticeOfBestBiddersLoading: false,
        editNoticeOfBestBiddersMessage: "",
        editNoticeOfBestBiddersError: payload,
      };
    case noticeOfBestBiddersConstants.EDIT_NOTICE_OF_BEST_BIDDER_COMPLETE:
      return {
        ...state,
        editNoticeOfBestBiddersSuccess: false,
      };
    case noticeOfBestBiddersConstants.DELETE_NOTICE_OF_BEST_BIDDER_REQUEST:
      return {
        ...state,
        deleteNoticeOfBestBiddersLoading: true,
      };
    case noticeOfBestBiddersConstants.DELETE_NOTICE_OF_BEST_BIDDER_SUCCESS:
      return {
        ...state,
        deleteNoticeOfBestBiddersLoading: false,
        deleteNoticeOfBestBiddersSuccess: true,
        deleteNoticeOfBestBiddersMessage: payload.message,
        noticeOfBestBidders: payload.data,
      };
    case noticeOfBestBiddersConstants.DELETE_NOTICE_OF_BEST_BIDDER_ERROR:
      return {
        ...state,
        deleteNoticeOfBestBiddersLoading: false,
        deleteNoticeOfBestBiddersSuccess: false,
        deleteNoticeOfBestBiddersError: payload,
      };
    case noticeOfBestBiddersConstants.DELETE_NOTICE_OF_BEST_BIDDER_COMPLETE:
      return {
        ...state,
        deleteNoticeOfBestBiddersSuccess: false,
        deleteNoticeOfBestBiddersError: "",
      };
    case noticeOfBestBiddersConstants.CHECK_NOTICE_OF_BEST_BIDDER_REQUEST:
      return {
        ...state,
        checkNoticeOfBestBiddersLoading: true,
      };
    case noticeOfBestBiddersConstants.CHECK_NOTICE_OF_BEST_BIDDER_SUCCESS:
      return {
        ...state,
        checkNoticeOfBestBiddersLoading: false,
        checkNoticeOfBestBiddersSuccess: true,
        checkNoticeOfBestBiddersMessage: payload,
      };
    case noticeOfBestBiddersConstants.CHECK_NOTICE_OF_BEST_BIDDER_ERROR:
      return {
        ...state,
        checkNoticeOfBestBiddersError: payload,
        checkNoticeOfBestBiddersLoading: false,
        checkNoticeOfBestBiddersSuccess: false,
      };
    case noticeOfBestBiddersConstants.CHECK_NOTICE_OF_BEST_BIDDER_COMPLETE:
      return {
        ...state,
        checkNoticeOfBestBiddersError: "",
        checkNoticeOfBestBiddersSuccess: false,
      };
    case noticeOfBestBiddersConstants.APPROVE_NOTICE_OF_BEST_BIDDER_REQUEST:
      return {
        ...state,
        approveNoticeOfBestBiddersLoading: true,
      };
    case noticeOfBestBiddersConstants.APPROVE_NOTICE_OF_BEST_BIDDER_SUCCESS:
      return {
        ...state,
        approveNoticeOfBestBiddersSuccess: true,
        approveNoticeOfBestBiddersLoading: false,
        approveNoticeOfBestBiddersMessage: payload,
      };
    case noticeOfBestBiddersConstants.APPROVE_NOTICE_OF_BEST_BIDDER_ERROR:
      return {
        ...state,
        approveNoticeOfBestBiddersError: payload,
        approveNoticeOfBestBiddersLoading: false,
        approveNoticeOfBestBiddersSuccess: false,
      };
    case noticeOfBestBiddersConstants.APPROVE_NOTICE_OF_BEST_BIDDER_COMPLETE:
      return {
        ...state,
        approveNoticeOfBestBiddersSuccess: false,
        approveNoticeOfBestBiddersError: "",
      };
    default:
      return state;
  }
};
export default noticeOfBestBidderState;
