import axios from "axios";

// Get all roles
export const getAllRolesRequest = async () => {
  try {
    const res = await axios.get("/roles/");
    return res;
  } catch (error) {
    console.error("Get all roles request error:", error);
  }
};

// Add a new role
export const addNewRoleRequest = async (body) => {
  try {
    const res = await axios.post("/roles/create", body, {
      headers: {
        accept: "application/json",
      },
    });
    return res;
  } catch (error) {
    console.error("Add new role request error:", error);
  }
};

// Update an existing role
export const updateNewRoleRequest = async (body, id) => {
  try {
    const res = await axios.patch(`/roles/update/${id}`, body);
    return res;
  } catch (error) {
    console.error("Update role request error:", error);
  }
};

// Delete a role
export const deleteRoleRequest = async (id) => {
  try {
    const res = await axios.delete(`/roles/delete/${id}`);
    return res;
  } catch (error) {
    console.error("Delete role request error:", error);
  }
};

// Get role details by ID
export const getRolesDetailsRequest = async (id) => {
  try {
    const res = await axios.get(`/roles/${id}`);
    return res;
  } catch (error) {
    console.error("Get role details request error:", error);
  }
};

// Approve a role
export const approveRolesRequest = async (id) => {
  try {
    const res = await axios.patch(`/roles/approve/${id}`, {
      approvedAt: new Date(),
    });
    return res;
  } catch (error) {
    console.error("Approve role request error:", error);
  }
};

// Check a role
export const checkRolesRequest = async (id) => {
  try {
    const res = await axios.patch(`/roles/check/${id}`, {
      checkedAt: new Date(),
    });
    return res;
  } catch (error) {
    console.error("Check role request error:", error);
  }
};
