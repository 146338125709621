import React, { useEffect } from "react";
import AntdModal from "../../../../components/common/AntdModal";
import { useAccountsStore } from "../../../../config/stores";
import { Form } from "antd";
import AppInput from "../../../../components/common/AppInput";
import { inputNumberFormatter } from "../../../../config/constants";
import AppButton from "../../../../components/common/AppButton";
import usePrevious from "../../../../components/hooks/usePrevious";
import { isEmpty } from "lodash";
const AccountForm = () => {
  const accountsStore = useAccountsStore();
  const { createdAccount, accountToEdit, creating } = accountsStore;

  const [form] = Form.useForm();

  const onSubmit = (values) => {
    if (accountsStore.creating) {
      accountsStore.createAccount(values);
    } else {
      accountsStore.updateAccount(values, accountToEdit.id);
    }
  };
  const prevState = usePrevious({ createdAccount });

  const closeModal = () => {
    accountsStore.setCreating(false);
  };

  useEffect(() => {
    if (
      !isEmpty(prevState) &&
      !isEmpty(createdAccount) &&
      prevState.createdAccount !== createdAccount
    ) {
      form.resetFields();
      accountsStore.setCreating(true, true, {});
    }
  }, [prevState, createdAccount, form, accountsStore]);

  useEffect(() => {
    if (!isEmpty(accountToEdit) && !creating) {
      form.setFieldsValue({
        name: accountToEdit.name,
        type: accountToEdit.type,
        account_number: accountToEdit.account_number,
        starting_balance: accountToEdit.starting_balance,
      });
    }
  }, [accountToEdit, form, creating]);
  return (
    <div>
      <AntdModal
        open={accountsStore.showCreateAccount}
        title={accountsStore.creating ? "CREATE ACCOUNT" : "UPDATE ACCOUNT"}
        footer={null}
        closeModal={closeModal}
      >
        <Form
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 16 }}
          layout="horizontal"
          onFinish={onSubmit}
          form={form}
        >
          <AppInput
            name="name"
            label="Account Name"
            rules={[
              {
                required: "This field is required",
              },
            ]}
          />
          <AppInput
            name="type"
            type="select"
            options={[
              {
                label: "Bank",
                value: "Bank",
              },
              {
                label: "Cash",
                value: "Cash",
              },
            ]}
            label="Account Type"
            rules={[
              {
                required: "This field is required",
              },
            ]}
          />
          <AppInput
            name="account_number"
            type="number"
            label="Account Number"
            rules={[
              {
                required: "This field is required",
              },
            ]}
          />
          <AppInput
            name="starting_balance"
            type="number"
            label="Starting Balance"
            rules={[
              {
                required: "This field is required",
              },
            ]}
            inputAttributes={{
              formatter: inputNumberFormatter,
            }}
          />
          <Form.Item className={"mt-3"} wrapperCol={{ offset: 5 }}>
            <AppButton
              text={
                accountsStore.creating ? "Create Account" : "Update Account"
              }
              loading={
                accountsStore.creatingAccount || accountsStore.updatingAccount
              }
              type="submit"
            />
          </Form.Item>
        </Form>
      </AntdModal>
    </div>
  );
};

export default AccountForm;
