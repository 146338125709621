import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddDonor from "../../../components/BusinessDevelopmentAndPartnerships/donors/Forms/AddDonor";
import EditDonor from "../../../components/BusinessDevelopmentAndPartnerships/donors/Forms/EditDonor";
import DonorsTable from "../../../components/BusinessDevelopmentAndPartnerships/donors/Tables/DonorsTable";
import routes from "../../../config/routes/routes";
import donorsThunks from "../../../config/thunks/businessDevAndPartnerships/donors/donors.thunks";
import logDetailThunks from "../../../config/thunks/settings/logDetails.thunks";

const Donors = () => {
  const dispatch = useDispatch();
  const {
    success: donorsSuccess,
    error: DonorsError,
    data: donors,
  } = useSelector((state) => state.donorsState.donors);
  const refreshTable = () => dispatch(donorsThunks.getDonors());
  const addDonor = (values) => dispatch(donorsThunks.addDonor(values, donors));
  const deleteRecord = (id, name, donors) =>
    dispatch(donorsThunks.deleteDonor(id, donors, name));
  const editDonor = (values) => {
    const updateDonorsArray = donors.filter((e) => e.id !== values.id);
    dispatch(donorsThunks.updateDonor(values, updateDonorsArray));
  };
  const exportRecords = () => {
    dispatch(
      logDetailThunks.addLogDetails({
        created_at: new Date(),
        activity: "Exported Donors Records",
        records: "donors",
      })
    );
  };
  //useEffects
  useEffect(() => {
    document.title = routes.businessDevelopmentAndPartnerships.donors.title;
    //get donors
    if (!donorsSuccess) dispatch(donorsThunks.getDonors());
  }, [donorsSuccess, DonorsError, dispatch]);
  return (
    <div className="container-fluid p-2">
      <h5 className="fw-bold">DONORS</h5>
      <DonorsTable
        handleExportRecords={exportRecords}
        handleRefreshTable={refreshTable}
        handleDelete={deleteRecord}
      />
      <AddDonor handleAddRecord={addDonor} />
      <EditDonor handleEditRecord={editDonor} />
    </div>
  );
};

export default Donors;
