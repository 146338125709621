import React from "react";
import AntdTable from "../../../../../components/common/AntdTable";
import { useAssetIncidentFormStore } from "../../../../../config/stores";
import dayjs from "dayjs";

const OtherDetails = () => {
  const assetIncidentFormStore = useAssetIncidentFormStore();
  const { assetIncidentFormDetails } = assetIncidentFormStore;

  const approvedAt = dayjs(assetIncidentFormStore.approved_at);
  return (
    <div>
      <AntdTable
        showHeader={false}
        bordered={false}
        loading={assetIncidentFormStore.gettingAssetIncidentFormDetails}
        columns={[
          {
            dataIndex: "title",
            render: (value) => <span className="text-uppercase">{value}</span>,
          },
          {
            dataIndex: "value",
            render: (value) => <span className="fw-bold">{value}</span>,
          },
        ]}
        data={[
          {
            key: 1,
            title: "Date",
            value: dayjs(assetIncidentFormDetails?.date_of_incident).format(
              "DD/MM/YYYY"
            ),
          },
          {
            key: 2,
            title: "Asset",
            value: assetIncidentFormDetails?.asset?.name,
          },
          {
            key: 5,
            title: "Created By",
            value: `${assetIncidentFormDetails?.created_by?.first_name || ""} ${
              assetIncidentFormDetails?.created_by?.other_names || ""
            }`,
          },
          {
            key: 1,
            title: "Created At",
            value: dayjs(assetIncidentFormDetails?.created_at).format(
              "DD/MM/YYYY HH:mm"
            ),
          },
          {
            title: "APPROVED AT",
            value: approvedAt.isValid()
              ? approvedAt.format("DD/MM/YYYY HH:mm")
              : "NOT APPROVED",
          },
          {
            title: "APPROVED BY",
            value: `${
              assetIncidentFormDetails?.approved_by?.first_name || "NOT"
            } ${
              assetIncidentFormDetails?.approved_by?.other_names || " APPROVED"
            }`,
          },
          {
            key: 5,
            title: "Updated By",
            value: `${assetIncidentFormDetails?.updated_by?.first_name || ""} ${
              assetIncidentFormDetails?.updated_by?.other_names || ""
            }`,
          },
          {
            key: 1,
            title: "Updated At",
            value: dayjs(assetIncidentFormDetails?.updated_at).format(
              "DD/MM/YYYY HH:mm"
            ),
          },
        ]}
      />
    </div>
  );
};

export default OtherDetails;
