import { Spin } from "antd";
import React from "react";
import PropTypes from "prop-types";

const AppLoader = ({ message }) => {
  return (
    <div className="w-100 d-flex p-1 flex-column">
      <Spin className="my-2" size="default" />
      <p className="text-center">{message}</p>
    </div>
  );
};

AppLoader.propTypes = {
  message: PropTypes.string,
};

export default AppLoader;
