const initialState = {
  manualsAndPolicies: {
    loading: false,
    success: false,
    error: "",
    data: [],
  },
  addManualsAndPolicies: {
    loading: false,
    success: false,
    error: "",
    message: "",
  },
  deleteManualsAndPolicies: {
    loading: false,
    success: false,
    error: "",
    message: "",
  },
  downloadManualsAndPolicies: {
    loading: false,
    success: false,
    error: "",
    message: "",
  },
  approveManualsAndPolicies: {
    loading: false,
    success: false,
    error: "",
    message: "",
  },
  checkManualsAndPolicies: {
    loading: false,
    success: false,
    error: "",
    message: "",
  },
  manualsAndPoliciesDetails: {
    loading: false,
    success: false,
    error: "",
    message: "",
    data: {},
  },
  updateManualsAndPolicies: {
    loading: false,
    success: false,
    error: "",
    message: "",
    data: {},
  },
};

export default initialState;
