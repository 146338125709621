import { Alert, Modal, Spin, Tabs } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import AdvanceAccountabilityFormDocumentsTable from "./AdvanceAccountabilityFormDocumentsTable";
import AdvanceAccountabilityFormExpenditureOtherDetails from "./AdvanceAccountabilityFormExpenditureOtherDetails";

const { TabPane } = Tabs;

const AdvanceAccountabilityFormExpenditureDetails = ({
  handleCloseModal,
  visible,
  handleDelete,
}) => {
  const {
    advanceAccountabilityFormsExpenditureDetailsLoading,
    advanceAccountabilityFormsExpenditureDetailsSuccess,
    advanceAccountabilityFormsExpenditureDetailsError,
    advanceAccountabilityFormsExpenditureDetails,
  } = useSelector((state) => state.advanceAccountabilityFormsState);
  return (
    <div>
      <Modal
        title={
          advanceAccountabilityFormsExpenditureDetails.name || "LOADING..."
        }
        open={visible}
        footer={null}
        onCancel={() => handleCloseModal(false)}
      >
        {advanceAccountabilityFormsExpenditureDetailsLoading ? (
          <div className="justify-content-center d-flex m-5">
            <Spin size="large" />
          </div>
        ) : (
          <div>
            {!advanceAccountabilityFormsExpenditureDetailsSuccess ? (
              <Alert
                showIcon
                message={advanceAccountabilityFormsExpenditureDetailsError}
                type="error"
              />
            ) : (
              <Tabs defaultActiveKey="1">
                <TabPane key="1" tab="Other Details">
                  <AdvanceAccountabilityFormExpenditureOtherDetails
                    data={advanceAccountabilityFormsExpenditureDetails}
                  />
                </TabPane>
                <TabPane key="2" tab={"Documents"}>
                  <AdvanceAccountabilityFormDocumentsTable
                    handleDelete={handleDelete}
                    data={
                      advanceAccountabilityFormsExpenditureDetails.expenseDocuments
                    }
                  />
                </TabPane>
              </Tabs>
            )}
          </div>
        )}
      </Modal>
    </div>
  );
};

export default AdvanceAccountabilityFormExpenditureDetails;
