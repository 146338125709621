import { projectActions } from "../../../actions/businesDevAndPartnerships/projects/projects.actions";
import apiRequests from "../../../api/api";
import projectsApiRequests from "../../../api/businessDevAndPartnerships/projects/projects.api";

const projectThunks = {
  getProjects: () => async (dispatch) => {
    dispatch(projectActions.setProjectsRequest());
    const res = await projectsApiRequests.fetchProjects();
    if (res.success) dispatch(projectActions.setProjectsSuccess(res.data));
    else dispatch(projectActions.setProjectsError(res.error));
  },
  getProjectsGraphData: () => async (dispatch) => {
    dispatch(projectActions.setProjectsGraphDataRequest());
    const res = await apiRequests.getRequest(
      "/businessDevelopmentAndPartnerships/projects/graphData"
    );
    if (res.success)
      dispatch(projectActions.setProjectsGraphDataSuccess(res.data));
    else dispatch(projectActions.setProjectsGraphDataError(res.error));
  },
  getProjectDetails: (id) => async (dispatch) => {
    dispatch(projectActions.setProjectDetailsRequest());
    const res = await projectsApiRequests.fetchProjectDetails(id);
    if (res.success)
      dispatch(projectActions.setProjectDetailsSuccess(res.data));
    else dispatch(projectActions.setProjectDetailsError(res.error));
  },
  addProjects: (body, projects) => async (dispatch) => {
    dispatch(projectActions.addProjectRequest());
    const res = await projectsApiRequests.addProject(body);
    if (res.success)
      dispatch(
        projectActions.addProjectSuccess({
          data: [res.data, ...projects],
          message: res.message,
        })
      );
    else dispatch(projectActions.addProjectError(res.error));
    dispatch(projectActions.addProjectComplete());
  },
  editProjects: (body, projects) => async (dispatch) => {
    dispatch(projectActions.editProjectRequest());
    const res = await projectsApiRequests.editProject(body);
    if (res.success)
      dispatch(
        projectActions.editProjectSuccess({
          data: [res.data, ...projects],
          message: res.message,
        })
      );
    else dispatch(projectActions.editProjectError(res.error));
    dispatch(projectActions.editProjectComplete());
  },
  deleteProject: (id, name, projects) => async (dispatch) => {
    dispatch(projectActions.deleteProjectRequest());
    const res = await projectsApiRequests.deleteProject(id, name);
    if (res.success)
      dispatch(
        projectActions.deleteProjectSuccess({
          message: res.message,
          data: projects,
        })
      );
    else dispatch(projectActions.deleteProjectError(res.error));
    dispatch(projectActions.deleteProjectComplete());
  },
  checkProject: (id) => async (dispatch) => {
    dispatch(projectActions.checkProjectRequest());
    const res = await projectsApiRequests.checkProject(id);
    if (res.success) {
      dispatch(projectActions.checkProjectSuccess(res.message));
      dispatch(projectThunks.getProjectDetails(id));
    } else dispatch(projectActions.checkProjectError(res.error));
    dispatch(projectActions.checkProjectComplete());
  },
  approveProject: (id) => async (dispatch) => {
    dispatch(projectActions.approveProjectRequest());
    const res = await projectsApiRequests.approveProject(id);
    if (res.success) {
      dispatch(projectActions.approveProjectSuccess(res.message));
      dispatch(projectThunks.getProjectDetails(id));
    } else dispatch(projectActions.approveProjectError(res.error));
    dispatch(projectActions.approveProjectComplete());
  },
  projectsMetaData: () => async (dispatch) => {
    dispatch(projectActions.setProjectsMetaDataRequest());
    const res = await projectsApiRequests.projectsMetaData();
    dispatch(projectActions.setProjectsMetaData(res.data || {}));
  },
  getMyProjects: () => async (dispatch) => {
    dispatch(projectActions.setMyProjectsRequest());
    const res = await apiRequests.getRequest(
      "/businessDevelopmentAndPartnerships/projects/myProjects"
    );
    if (res.success) dispatch(projectActions.setMyProjectsSuccess(res.data));
    else dispatch(projectActions.setMyProjectsError(res.error));
  },
};

export default projectThunks;
