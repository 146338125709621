import React, { useEffect } from "react";
import { Col, Divider, Form, Row, message } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import usePrevious from "../../../../../components/hooks/usePrevious";
import AppButton from "../../../../../components/common/AppButton";
import AntdModal from "../../../../../components/common/AntdModal";
import AppInput from "../../../../../components/common/AppInput";
import { useStrategicPlansStore } from "../../../../../config/stores";
import { flatMap } from "lodash";

const ThematicAreaForm = () => {
  const strategicPlansStore = useStrategicPlansStore();
  const {
    addingStrategicPlanThematicArea,
    thematicAreaToUpdate,
    creatingStrategicPlanThematicArea,
    createdStrategicPlanThematicArea,
    showAddStrategicPlanThematicArea,
    updatingStrategicPlanThematicArea,
    strategicPlanDetails,
  } = strategicPlansStore;
  const [form] = Form.useForm();
  const prevState = usePrevious({ createdStrategicPlanThematicArea });

  const closeModal = () => {
    strategicPlansStore.setShowAddStrategicPlanThematicArea();
  };

  const onSubmit = () => {
    form
      .validateFields()
      .then((data) => {
        if (addingStrategicPlanThematicArea) {
          data.strategic_plan_id = strategicPlanDetails.id;
          strategicPlansStore.createStrategicPlanThematicArea(data);
        } else {
          strategicPlansStore.updateStrategicPlanThematicArea(
            thematicAreaToUpdate.id,
            data
          );
        }
      })
      .catch((err) => {
        const errorMessages = flatMap(err.errorFields, "errors").join(", ");
        message.error(errorMessages);
      });
  };

  useEffect(() => {
    if (
      prevState &&
      createdStrategicPlanThematicArea &&
      prevState.createdStrategicPlanThematicArea !==
        createdStrategicPlanThematicArea
    ) {
      form.resetFields();
    }
  }, [prevState, form, createdStrategicPlanThematicArea]);

  useEffect(() => {
    if (addingStrategicPlanThematicArea) {
      form.setFieldsValue({
        strategicPlansProposedActivities: [{ activity: "" }],
      });
    } else {
      form.setFieldsValue(thematicAreaToUpdate);
    }
  }, [addingStrategicPlanThematicArea, thematicAreaToUpdate, form]);

  return (
    <AntdModal
      title={
        addingStrategicPlanThematicArea
          ? "ADD THEMATIC AREA"
          : "EDIT THEMATIC AREA"
      }
      open={showAddStrategicPlanThematicArea}
      closeModal={closeModal}
      size="large"
      footer={[
        <AppButton
          text={
            addingStrategicPlanThematicArea
              ? "CREATE THEMATIC AREA"
              : "UPDATE THEMATIC AREA"
          }
          key={"create"}
          loading={
            creatingStrategicPlanThematicArea ||
            updatingStrategicPlanThematicArea
          }
          onClick={onSubmit}
        />,
        <AppButton text="CANCEL" key={"cancel"} onClick={closeModal} danger />,
      ]}
    >
      <Form layout="vertical" form={form}>
        <AppInput
          label="Thematic Area"
          name="thematic_area"
          rules={[
            {
              required: true,
              message: "Title is required",
            },
          ]}
        />

        <AppInput label="Description" name="description" type="textarea" />
        {addingStrategicPlanThematicArea && (
          <>
            <Divider className="fw-bold">THEMATIC AREA ACTIVITIES</Divider>
            <Form.List
              name="strategicPlansProposedActivities"
              rules={[
                {
                  validator: async (_, names) => {
                    if (!names || names.length < 1) {
                      return Promise.reject(
                        new Error("At least 1 thematic area")
                      );
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <Row gutter={4} key={field.key}>
                      <Col span={22}>
                        <AppInput
                          label="Activity"
                          name={[field.name, "activity"]}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: "Please input activity",
                            },
                          ]}
                        />
                      </Col>
                      <Col span={2}>
                        <Form.Item label=" ">
                          {fields.length > 1 ? (
                            <span className="text-danger mx-1">
                              <MinusCircleOutlined
                                className="dynamic-delete-button"
                                onClick={() => remove(field.name)}
                              />
                            </span>
                          ) : null}
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                  <Form.Item>
                    <AppButton
                      type="dashed"
                      text="Add Activity"
                      block
                      onClick={() => add()}
                      iconBefore={<PlusOutlined />}
                    />

                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          </>
        )}
      </Form>
      <Divider className="m-1" />
    </AntdModal>
  );
};

export default ThematicAreaForm;
