import { Alert, Button, Form, Input, message, Modal } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";

const AddNewRole = ({ handledAddRole }) => {
  const dispatch = useDispatch();
  const { error, success, loading } = useSelector(
    (state) => state.rolesState.addNewRole
  );
  const [form] = Form.useForm();
  const onFinish = (values) => {
    values.createdAt = new Date();
    handledAddRole(values);
  };
  const visible = useSelector((state) => state.AppUi.settings.addRolesModal);
  const closeAddRoleModal = () => {
    dispatch(appUiActions.toggleAddRolesModal(false));
  };

  useEffect(() => {
    if (success) {
      message.success("Role has been added");
      form.resetFields();
    }
    //if (updateRoleSuccess) message.success("Role has been updated");
  }, [success, form]);

  return (
    <div>
      <Modal
        onCancel={closeAddRoleModal}
        open={visible}
        title="ADD NEW ROLE"
        footer={null}
      >
        <Form
          form={form}
          validateTrigger="onBlur"
          layout="vertical"
          onFinish={onFinish}
        >
          {!success && error && (
            <Alert type="error" message={error} showIcon className="m-2" />
          )}
          <Form.Item
            name="role"
            label="Role"
            rules={[{ required: true, message: "Please add a role" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button loading={loading} htmlType="submit" type="primary">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddNewRole;
