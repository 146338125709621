import { getAuthToken } from "../../helpers/auth/authToken";
import appConfig from "../../config.json";

const authorization = `Bearer ${getAuthToken()}`;

export const currentUserInfoRequest = async () => {
  try {
    const res = await fetch(
      `${appConfig.server_api_url}/users/currentUserInfo`,
      {
        headers: { authorization },
      }
    );
    return res.json();
  } catch (error) {
    console.error(error);
  }
};

export const fetchUserProfile = async () => {
  try {
    const res = await fetch(`${appConfig.server_api_url}/users/profile`, {
      headers: { authorization },
    });
    return res.json();
  } catch (error) {
    console.error(error);
  }
};

export const changePasswordRequest = async (body) => {
  try {
    const res = await fetch(
      `${appConfig.server_api_url}/users/changePassword`,
      {
        method: "PATCH",
        headers: {
          accept: "application/json",
          "Content-type": "application/json",
          authorization,
        },
        body: JSON.stringify(body),
      }
    );
    return res.json();
  } catch (error) {
    console.error(error);
  }
};
