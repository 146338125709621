import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Alert,
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";
import assetRegisterThunk from "../../../../config/thunks/administration/assetsRegister/assetsRegister.thunks";

const { Option } = Select;

const AddIssueForm = ({ handleAddRecord, item }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  //use selector to get state from redux state
  const { error, success, loading } = useSelector(
    (state) => state.storesRegisterState.addStoresRegisterIssue
  );
  const {
    success: usersSuccess,
    loading: usersLoading,
    data: metaData,
  } = useSelector((state) => state.assetRegisterState.metaData);
  const fields = [{ name: "item", value: item.name }];
  //submit form
  const onFinish = (values) => {
    if (item.id) {
      values.itemId = item.id;
      values.createdAt = new Date();
      handleAddRecord(values);
    } else message.error("Item has not been set");
  };
  //close modal
  const closeModal = () => {
    dispatch(appUiActions.toggleAddStoresRegisterIssuesModal(false));
  };
  //use effect
  useEffect(() => {
    if (success) form.resetFields();
    if (!usersSuccess) dispatch(assetRegisterThunk.getMetaData());
  }, [form, success, usersSuccess, dispatch]);
  const visible = useSelector(
    (state) =>
      state.AppUi.admisnistration.storesRegister.addStoresRegisterIssuesModal
  );

  return (
    <div>
      <Modal
        onCancel={closeModal}
        footer={null}
        title={`ISSUE ${item.name}`.toUpperCase()}
        open={visible}
      >
        <Form
          fields={fields}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          validateTrigger="onBlur"
          layout="horizontal"
          form={form}
          onFinish={onFinish}
        >
          {!success && error && (
            <Alert type="error" showIcon message={error} className="m-2" />
          )}
          <Form.Item
            label="Item"
            name="item"
            rules={[
              {
                required: true,
                message: "Please  add an issue date",
              },
            ]}
          >
            <Input readOnly defaultValue={item.name} className="w-100" />
          </Form.Item>
          <Form.Item
            name="issued_to"
            label="Issued To"
            rules={[{ required: true, message: "Please add a first name" }]}
          >
            <Select
              defaultValue=""
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              loading={usersLoading}
            >
              {metaData?.users?.map((user, id) => {
                return (
                  <Option value={user.id} key={user.id}>
                    {`${user.first_name} ${user.other_names}`}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Issue Date"
            name="issue_date"
            rules={[
              {
                required: true,
                message: "Please  add an issue date",
              },
            ]}
          >
            <DatePicker className="w-100" />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: "Please add a quantity" }]}
            name="quantity"
            label="Quantity"
          >
            <InputNumber precision={0} className="w-100" />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: "Please add remarks" }]}
            name="remarks"
            label={"Remarks"}
          >
            <TextArea />
          </Form.Item>
          <Form.Item className={"mt-3"} wrapperCol={{ offset: 8, span: 16 }}>
            <Button loading={loading} htmlType="submit" type="primary">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddIssueForm;
