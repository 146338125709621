import { Tag } from "antd";
import React from "react";
import appConfig from "../../../../config/config.json";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { AiFillIeCircle } from "react-icons/ai";

const RequisitionMemoOtherDetails = ({ data }) => {
  return (
    <div className="details_div">
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th scope="col">Column</th>
            <th scope="col">Data</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Memo Number</td>
            <td>{data.memo_number}</td>
          </tr>
          <tr>
            <td>Source Of Funds</td>
            <td>{data.donorDetails?.name}</td>
          </tr>
          <tr>
            <td>Project Details</td>
            <td>{data.projectDetails?.name}</td>
          </tr>
          <tr>
            <td>Budget Line</td>
            <td>{data.budgetLineDetails?.name}</td>
          </tr>
          <tr>
            <td>Budget Code</td>
            <td>{data.budgetLineDetails?.expense_code}</td>
          </tr>
          <tr>
            <td>Details</td>
            <td>{data.details}</td>
          </tr>
          <tr>
            <td>Status</td>
            <td>
              {data.status === appConfig.status.approved && (
                <Tag icon={<CheckCircleOutlined />} color="success">
                  {data.status}
                </Tag>
              )}
              {data.status === appConfig.status.checked && (
                <Tag icon={<MinusCircleOutlined />} color="processing">
                  {data.status}
                </Tag>
              )}
              {data.status === appConfig.status.created && (
                <Tag icon={<ExclamationCircleOutlined />} color="warning">
                  Pending
                </Tag>
              )}
              {data.status === appConfig.status.pending && (
                <Tag icon={<ExclamationCircleOutlined />} color="warning">
                  Pending
                </Tag>
              )}
              {data.status === appConfig.status.rejected && (
                <Tag icon={<AiFillIeCircle />} color="error">
                  Rejected
                </Tag>
              )}
            </td>
          </tr>

          <tr>
            <td>Created By</td>
            <td>{`${data?.creator?.first_name} ${data?.creator?.other_names}`}</td>
          </tr>
          <tr>
            <td>Created At</td>
            <td>
              {`${new Date(data.created_at).toDateString()} ${new Date(
                data.created_at
              ).toLocaleTimeString()}
                          `}
            </td>
          </tr>
          <tr>
            <td>Last Updated By</td>
            <td>
              {data.updator &&
                `${data?.updator?.first_name} ${data?.updator?.other_names}`}
            </td>
          </tr>
          <tr>
            <td>Last Updated At</td>
            <td>
              {data.updated_at &&
                `${new Date(data.updated_at).toDateString()} ${new Date(
                  data.updated_at
                ).toLocaleTimeString()}`}
            </td>
          </tr>
          <tr>
            <td>Checked By</td>
            <td>
              {data.checker &&
                `${data?.checker?.first_name} ${data?.checker?.other_names}`}
            </td>
          </tr>
          <tr>
            <td>Checked At</td>
            <td>
              {data.checked_at &&
                `${new Date(data.checked_at).toDateString()} ${new Date(
                  data.checked_at
                ).toLocaleTimeString()}
                          `}
            </td>
          </tr>
          <tr>
            <td>Approved By</td>
            <td>
              {data.approver &&
                `${data?.approver?.first_name} ${data?.approver?.other_names}`}
            </td>
          </tr>
          <tr>
            <td>Approved At</td>
            <td>
              {data.approved_at &&
                `${new Date(data.approved_at).toDateString()} ${new Date(
                  data.approved_at
                ).toLocaleTimeString()}`}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default RequisitionMemoOtherDetails;
