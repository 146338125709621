export const staffRecordsConstants = {
  SET_STAFF_RECORDS_REQUEST: "SET_STAFF_RECORDS_REQUEST",
  SET_STAFF_RECORDS_SUCCESS: "SET_STAFF_RECORDS_SUCCESS",
  SET_STAFF_RECORDS_ERROR: "SET_STAFF_RECORDS_ERROR",

  ADD_STAFF_REQUEST: "ADD_STAFF_REQUEST",
  ADD_STAFF_SUCCESS: "ADD_STAFF_SUCCESS",
  ADD_STAFF_ERROR: "ADD_STAFF_ERROR",
  ADD_STAFF_COMPLETED: "ADD_STAFF_COMPLETED",

  UPDATE_STAFF_SUCCESS: "UPDATE_STAFF_SUCCESS",
  UPDATE_STAFF_REQUEST: "UPDATE_STAFF_REQUEST",
  UPDATE_STAFF_ERROR: "UPDATE_STAFF_ERROR",
  UPDATE_STAFF_COMPLETED: "UPDATE_STAFF_COMPLETED",
  SET_UPDATE_STAFF_DATA: "SET_UPDATE_STAFF_DATA",

  DELETE_STAFF_REQUEST: "DELETE_STAFF_REQUEST",
  DELETE_STAFF_SUCCESS: "DELETE_STAFF_SUCCESS",
  DELETE_STAFF_ERROR: "DELETE_STAFF_ERROR",
  DELETE_STAFF_COMPLETED: "DELETE_STAFF_COMPLETED",

  SET_STAFF_DETAILS_REQUEST: "SET_STAFF_DETAILS_REQUEST",
  SET_STAFF_DETAILS_SUCCESS: "SET_STAFF_DETAILS_SUCCESS",
  SET_STAFF_DETAILS_ERROR: "SET_STAFF_DETAILS_ERROR",

  STAFF_ACCOUNT_STATUS_REQUEST: "STAFF_ACCOUNT_STATUS_REQUEST",
  STAFF_ACCOUNT_STATUS_SUCCESS: "STAFF_ACCOUNT_STATUS_SUCCESS",
  STAFF_ACCOUNT_STATUS_ERROR: "STAFF_ACCOUNT_STATUS_ERROR",
  STAFF_ACCOUNT_STATUS_COMPLETED: "STAFF_ACCOUNT_STATUS_COMPLETED",

  UPDATE_PROFILE_PICTURE_REQUEST: "UPDATE_PROFILE_PICTURE_REQUEST",
  UPDATE_PROFILE_PICTURE_ERROR: "UPDATE_PROFILE_PICTURE_ERROR",
  UPDATE_PROFILE_PICTURE_SUCCESS: "UPDATE_PROFILE_PICTURE_SUCCESS",
  UPDATE_PROFILE_PICTURE_COMPLETED: "UPDATE_PROFILE_PICTURE_COMPLETED",

  GENERATE_NEW_CONFIRM_TOKEN_REQUEST: "GENERATE_NEW_CONFIRM_TOKEN_REQUEST",
  GENERATE_NEW_CONFIRM_TOKEN_SUCCESS: "GENERATE_NEW_CONFIRM_TOKEN_SUCCESS",
  GENERATE_NEW_CONFIRM_TOKEN_ERROR: "GENERATE_NEW_CONFIRM_TOKEN_ERROR",
  GENERATE_NEW_CONFIRM_TOKEN_COMPLETE: "GENERATE_NEW_CONFIRM_TOKEN_COMPLETE",

  UPLOAD_STAFF_SIGNATURE_REQUEST: "UPLOAD_STAFF_SIGNATURE_REQUEST",
  UPLOAD_STAFF_SIGNATURE_SUCCESS: "UPLOAD_STAFF_SIGNATURE_SUCCESS",
  UPLOAD_STAFF_SIGNATURE_ERROR: "UPLOAD_STAFF_SIGNATURE_ERROR",
  UPLOAD_STAFF_SIGNATURE_COMPLETE: "UPLOAD_STAFF_SIGNATURE_COMPLETE",
};

export const staffRecordsActions = {
  setStaffRecordsRequest: () => ({
    type: staffRecordsConstants.SET_STAFF_RECORDS_REQUEST,
  }),
  setStaffRecordsSuccess: (payload) => ({
    type: staffRecordsConstants.SET_STAFF_RECORDS_SUCCESS,
    payload,
  }),
  setStaffRecordsError: (payload) => ({
    type: staffRecordsConstants.SET_STAFF_RECORDS_ERROR,
    payload,
  }),
  addStaffRecordRequest: () => ({
    type: staffRecordsConstants.ADD_STAFF_REQUEST,
  }),
  addStaffRecordSuccess: (payload) => ({
    type: staffRecordsConstants.ADD_STAFF_SUCCESS,
    payload,
  }),
  addStaffRecordError: (payload) => ({
    type: staffRecordsConstants.ADD_STAFF_ERROR,
    payload,
  }),
  addStaffRecordCompleted: () => ({
    type: staffRecordsConstants.ADD_STAFF_COMPLETED,
  }),
  updateStaffRequest: () => ({
    type: staffRecordsConstants.UPDATE_STAFF_REQUEST,
  }),
  updateStaffSuccess: (payload) => ({
    type: staffRecordsConstants.UPDATE_STAFF_SUCCESS,
    payload,
  }),
  updateStaffError: (payload) => ({
    type: staffRecordsConstants.UPDATE_STAFF_ERROR,
    payload,
  }),
  updateStaffCompleted: () => ({
    type: staffRecordsConstants.UPDATE_STAFF_COMPLETED,
  }),
  setUpdateStaffData: (payload) => ({
    type: staffRecordsConstants.SET_UPDATE_STAFF_DATA,
    payload,
  }),
  deleteStaffRequest: () => ({
    type: staffRecordsConstants.DELETE_STAFF_REQUEST,
  }),
  deleteStaffSuccess: (payload) => ({
    type: staffRecordsConstants.DELETE_STAFF_SUCCESS,
    payload,
  }),
  deleteStaffError: (payload) => ({
    type: staffRecordsConstants.DELETE_STAFF_ERROR,
    payload,
  }),
  deleteStaffCompleted: () => ({
    type: staffRecordsConstants.DELETE_STAFF_COMPLETED,
  }),
  setStaffDetailsRequest: () => ({
    type: staffRecordsConstants.SET_STAFF_DETAILS_REQUEST,
  }),
  setStaffDetailsSuccess: (payload) => ({
    type: staffRecordsConstants.SET_STAFF_DETAILS_SUCCESS,
    payload,
  }),
  setStaffDetailsError: (payload) => ({
    type: staffRecordsConstants.SET_STAFF_DETAILS_ERROR,
    payload,
  }),
  staffAccountStatusRequest: () => ({
    type: staffRecordsConstants.STAFF_ACCOUNT_STATUS_REQUEST,
  }),
  staffAccountStatusSuccess: (payload) => ({
    type: staffRecordsConstants.STAFF_ACCOUNT_STATUS_SUCCESS,
    payload,
  }),
  staffAccountStatusError: (payload) => ({
    type: staffRecordsConstants.STAFF_ACCOUNT_STATUS_ERROR,
    payload,
  }),
  staffAccountStatusCompleted: () => ({
    type: staffRecordsConstants.STAFF_ACCOUNT_STATUS_COMPLETED,
  }),
  updateProfilePictureRequest: () => ({
    type: staffRecordsConstants.UPDATE_PROFILE_PICTURE_REQUEST,
  }),
  updateProfilePictureSuccess: (payload) => ({
    type: staffRecordsConstants.UPDATE_PROFILE_PICTURE_SUCCESS,
    payload,
  }),
  updateProfilePictureError: (payload) => ({
    type: staffRecordsConstants.UPDATE_PROFILE_PICTURE_ERROR,
    payload,
  }),
  updateProfilePictureCompleted: (payload) => ({
    type: staffRecordsConstants.UPDATE_PROFILE_PICTURE_COMPLETED,
    payload,
  }),
  generateNewConfirmTokenRequest: () => ({
    type: staffRecordsConstants.GENERATE_NEW_CONFIRM_TOKEN_REQUEST,
  }),
  generateNewConfirmTokenSuccess: (payload) => ({
    type: staffRecordsConstants.GENERATE_NEW_CONFIRM_TOKEN_SUCCESS,
    payload,
  }),
  generateNewConfirmTokenError: (payload) => ({
    type: staffRecordsConstants.GENERATE_NEW_CONFIRM_TOKEN_ERROR,
    payload,
  }),
  generateNewConfirmTokenComplete: () => ({
    type: staffRecordsConstants.GENERATE_NEW_CONFIRM_TOKEN_COMPLETE,
  }),
  uploadStaffSignatureRequest: () => ({
    type: staffRecordsConstants.UPLOAD_STAFF_SIGNATURE_REQUEST,
  }),
  uploadStaffSignatureSuccess: (payload) => ({
    type: staffRecordsConstants.UPLOAD_STAFF_SIGNATURE_SUCCESS,
    payload,
  }),
  uploadStaffSignatureError: (payload) => ({
    type: staffRecordsConstants.UPLOAD_STAFF_SIGNATURE_ERROR,
    payload,
  }),
  uploadStaffSignatureComplete: () => ({
    type: staffRecordsConstants.UPLOAD_STAFF_SIGNATURE_COMPLETE,
  }),
};
