export const searchTable = (data, value) => {
  return data?.filter((o) => {
    for (const key in o) {
      if (typeof o[key] === "object") {
        for (const subKey in o[key]) {
          if (
            typeof o[key][subKey] === "string" &&
            String(o[key][subKey]).toLowerCase().includes(value.toLowerCase())
          ) {
            return true;
          }
        }
      } else if (
        typeof o[key] === "string" &&
        String(o[key]).toLowerCase().includes(value.toLowerCase())
      ) {
        return true;
      }
    }
    return false;
  });
};

export const inputNumberFormatter = (value) =>
  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
