import { Button, message, Popover, Table, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { localPurchaseOrdersActions } from "../../../../config/actions/procument/localPurchaseOrder/localPuchaseOrder.actions";
import { numberWithCommas } from "../../../../config/helpers/app/numberFormater";
import tableButtonActions from "../../../shared/Tables/TableButtonActions";
import TableTitle from "../../../shared/Tables/TableTitle";
import PrintLocalPurchaseOrder from "../Details/PrintLocalPurchaseOrder";
import { useReactToPrint } from "react-to-print";

const { Text } = Typography;

const LocalPurchaseOrdersItemsTable = ({
  handleDelete,
  handleRefreshTable,
  handleExportRecords,
  handleOpenAddModal,
  handleOpenEditModal,
  handleViewDetails,
}) => {
  const [deleteId, setDeleteId] = useState("");
  const [filterTable, setfilterTable] = useState(null);
  const {
    lpoItems: data,
    lpoItemsLoading: loading,
    lpoItemsSuccess: success,
    lpoItemsError: error,
    deleteLpoItemsLoading: deleteLoading,
    deleteLpoItemsSuccess: deleteSuccess,
    deleteLpoItemsError: deleteError,
    deleteLpoItemsMessage: deleteMessage,
    localPurchaseOrdersDetails,
  } = useSelector((state) => state.localPurchaseOrdersState);

  const dispatch = useDispatch();
  const {
    success: logDetailsSuccess,
    error: logDetailsError,
    records,
  } = useSelector((state) => state.logDetailsState.addLogDetails);
  const columns = [
    {
      title: "Item",
      key: "id",
      dataIndex: "name",
    },
    {
      title: "Units",
      key: "units",
      dataIndex: "units",
      render: (text) => numberWithCommas(parseInt(text)),
      responsive: ["md"],
    },
    {
      title: "Unit Cost",
      key: "unit_cost",
      dataIndex: "unit_cost",
      render: (text) => numberWithCommas(parseInt(text)),
    },
    {
      title: "Total Cost",
      key: "total_cost",
      render: (text) =>
        numberWithCommas(parseInt(text.units) * parseInt(text.unit_cost)),
    },
    {
      title: "Action",
      key: "operation",
      render: (text) => (
        <Popover
          trigger="click"
          placement="bottom"
          content={() =>
            tableButtonActions(text, {
              viewDetails,
              updateRecord,
              confirmDelete,
              deleteRecord,
              deleteLoading,
              deleteId,
            })
          }
        >
          <Button>Actions</Button>
        </Popover>
      ),
    },
  ];
  /* Functions */
  const deleteRecord = (e) => setDeleteId(e.target.id);
  const confirmDelete = () => {
    const lpoItemsArray = data.filter((e) => e.id === deleteId);
    handleDelete(deleteId, lpoItemsArray[0].local_purchase_order);
  };
  const refreshTable = () => {
    handleRefreshTable();
    if (!success && error) message.error(error);
  };
  const updateRecord = (e) => {
    const lpoItemsArray = data.filter((bidder) => bidder.id === e.target.id);
    handleOpenEditModal(true);
    dispatch(localPurchaseOrdersActions.editLpoItemsData(lpoItemsArray[0]));
  };
  const viewDetails = (e) => handleViewDetails(e.target.id);

  const search = (value) => {
    const filterTable = data.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(value.toLowerCase())
      )
    );
    setfilterTable(filterTable);
  };
  const setfilterTableNull = (e) => {
    if (!e.target.value) setfilterTable(null);
  };

  const showTotal = (total) => `Total: ${total}`;
  const openAddModal = () => {
    handleOpenAddModal(true);
  };

  /* Use effect */
  useEffect(() => {
    if (logDetailsSuccess && records === "lpoItems") {
      message.info("Started download");
      handlePrint();
    } else if (!logDetailsSuccess && logDetailsError)
      message.error(logDetailsError);
    if (deleteSuccess && deleteMessage) message.success(deleteMessage);
    else if (!deleteSuccess && deleteError) message.error(deleteError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    logDetailsSuccess,
    logDetailsError,
    deleteSuccess,
    deleteMessage,
    deleteError,
  ]);

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div>
      <div className="d-none">
        <div className="p-2" ref={componentRef}>
          <PrintLocalPurchaseOrder
            data={{ ...localPurchaseOrdersDetails, lpoItems: data }}
          />
        </div>
      </div>
      <Table
        scroll={{ x: true }}
        pagination={{ total: data.length, showSizeChanger: true, showTotal }}
        loading={loading}
        title={() =>
          TableTitle({
            search,
            setfilterTableNull,
            refreshTable,
            exportRecords: handlePrint,
            openAddModal,
          })
        }
        bordered={true}
        dataSource={filterTable === null ? data : filterTable}
        columns={columns}
        summary={(records) => {
          let subTotal = 0;
          records.forEach((record) => {
            subTotal += parseInt(record.units) * parseInt(record.unit_cost);
          });
          const discount =
            subTotal - (localPurchaseOrdersDetails.discount / 100) * subTotal;
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell>
                  <Text type="warning">Sub Total:</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Text type="warning">{`${numberWithCommas(
                    parseInt(subTotal)
                  )}`}</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell>
                  <Text type="warning">Discount:</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  {localPurchaseOrdersDetails.discount} %
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Text type="warning">Total Net Cost:</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Text type="warning">{numberWithCommas(discount)}</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </div>
  );
};

export default LocalPurchaseOrdersItemsTable;
