import { localPurchaseOrdersConstants } from "../../../actions/procument/localPurchaseOrder/localPuchaseOrder.actions";
import initialState from "../../../initialState/procurement/localPurchaseOrder/localPurchaseOrder";

const localPurchaseOrdersState = (state = initialState, { type, payload }) => {
  switch (type) {
    case localPurchaseOrdersConstants.SET_LOCAL_PURCHASE_ORDERS_REQUEST:
      return {
        ...state,
        localPurchaseOrdersLoading: true,
      };
    case localPurchaseOrdersConstants.SET_LOCAL_PURCHASE_ORDERS_SUCCESS:
      return {
        ...state,
        localPurchaseOrdersLoading: false,
        localPurchaseOrders: payload,
        localPurchaseOrdersSuccess: true,
      };
    case localPurchaseOrdersConstants.SET_LOCAL_PURCHASE_ORDERS_ERROR:
      return {
        ...state,
        localPurchaseOrdersSuccess: false,
        localPurchaseOrdersError: payload,
        localPurchaseOrdersLoading: false,
      };
    case localPurchaseOrdersConstants.SET_LPO_ITEMS_REQUEST:
      return {
        ...state,
        lpoItemsLoading: true,
      };
    case localPurchaseOrdersConstants.SET_LPO_ITEMS_SUCCESS:
      return {
        ...state,
        lpoItemsLoading: false,
        lpoItems: payload,
        lpoItemsSuccess: true,
      };
    case localPurchaseOrdersConstants.SET_LPO_ITEMS_ERROR:
      return {
        ...state,
        lpoItemsSuccess: false,
        lpoItemsError: payload,
        lpoItemsLoading: false,
      };
    case localPurchaseOrdersConstants.SET_LOCAL_PURCHASE_ORDERS_DETAILS_REQUEST:
      return {
        ...state,
        localPurchaseOrdersDetailsLoading: true,
      };
    case localPurchaseOrdersConstants.SET_LOCAL_PURCHASE_ORDERS_DETAILS_SUCCESS:
      return {
        ...state,
        localPurchaseOrdersDetailsSuccess: true,
        localPurchaseOrdersDetails: payload,
        localPurchaseOrdersDetailsError: "",
        localPurchaseOrdersDetailsLoading: false,
      };
    case localPurchaseOrdersConstants.SET_LOCAL_PURCHASE_ORDERS_DETAILS_ERROR:
      return {
        ...state,
        localPurchaseOrdersDetailsSuccess: false,
        localPurchaseOrdersDetailsError: payload,
        localPurchaseOrdersDetailsLoading: false,
      };
    case localPurchaseOrdersConstants.SET_LPO_ITEMS_DETAILS_REQUEST:
      return {
        ...state,
        lpoItemsDetailsLoading: true,
      };
    case localPurchaseOrdersConstants.SET_LPO_ITEMS_DETAILS_SUCCESS:
      return {
        ...state,
        lpoItemsDetailsSuccess: true,
        lpoItemsDetails: payload,
        lpoItemsDetailsError: "",
        lpoItemsDetailsLoading: false,
      };
    case localPurchaseOrdersConstants.SET_LPO_ITEMS_DETAILS_ERROR:
      return {
        ...state,
        lpoItemsDetailsSuccess: false,
        lpoItemsDetailsError: payload,
        lpoItemsDetailsLoading: false,
      };
    case localPurchaseOrdersConstants.ADD_LOCAL_PURCHASE_ORDERS_REQUEST:
      return {
        ...state,
        addLocalPurchaseOrdersLoading: true,
      };
    case localPurchaseOrdersConstants.ADD_LOCAL_PURCHASE_ORDERS_SUCCESS:
      return {
        ...state,
        addLocalPurchaseOrdersLoading: false,
        addLocalPurchaseOrdersSuccess: true,
        addLocalPurchaseOrdersError: "",
        addLocalPurchaseOrdersMessage: payload.message,
        localPurchaseOrders: payload.data,
      };
    case localPurchaseOrdersConstants.ADD_LOCAL_PURCHASE_ORDERS_ERROR:
      return {
        ...state,
        addLocalPurchaseOrdersLoading: false,
        addLocalPurchaseOrdersSuccess: false,
        addLocalPurchaseOrdersMessage: "",
        addLocalPurchaseOrdersError: payload,
      };
    case localPurchaseOrdersConstants.ADD_LOCAL_PURCHASE_ORDERS_COMPLETE:
      return {
        ...state,
        addLocalPurchaseOrdersSuccess: false,
      };
    case localPurchaseOrdersConstants.ADD_LPO_ITEMS_REQUEST:
      return {
        ...state,
        addLpoItemsLoading: true,
      };
    case localPurchaseOrdersConstants.ADD_LPO_ITEMS_SUCCESS:
      return {
        ...state,
        addLpoItemsLoading: false,
        addLpoItemsSuccess: true,
        addLpoItemsError: "",
        addLpoItemsMessage: payload,
      };
    case localPurchaseOrdersConstants.ADD_LPO_ITEMS_ERROR:
      return {
        ...state,
        addLpoItemsLoading: false,
        addLpoItemsSuccess: false,
        addLpoItemsError: payload,
      };
    case localPurchaseOrdersConstants.ADD_LPO_ITEMS_COMPLETE:
      return {
        ...state,
        addLpoItemsSuccess: false,
      };
    case localPurchaseOrdersConstants.EDIT_LOCAL_PURCHASE_ORDERS_DATA:
      return {
        ...state,
        editLocalPurchaseOrdersData: payload,
      };
    case localPurchaseOrdersConstants.EDIT_LOCAL_PURCHASE_ORDERS_REQUEST:
      return {
        ...state,
        editLocalPurchaseOrdersLoading: true,
      };
    case localPurchaseOrdersConstants.EDIT_LOCAL_PURCHASE_ORDERS_SUCCESS:
      return {
        ...state,
        editLocalPurchaseOrdersSuccess: true,
        editLocalPurchaseOrdersLoading: false,
        editLocalPurchaseOrdersMessage: payload.message,
        editLocalPurchaseOrdersError: "",
        localPurchaseOrders: payload.data,
      };
    case localPurchaseOrdersConstants.EDIT_LOCAL_PURCHASE_ORDERS_ERROR:
      return {
        ...state,
        editLocalPurchaseOrdersSuccess: false,
        editLocalPurchaseOrdersLoading: false,
        editLocalPurchaseOrdersMessage: "",
        editLocalPurchaseOrdersError: payload,
      };
    case localPurchaseOrdersConstants.EDIT_LOCAL_PURCHASE_ORDERS_COMPLETE:
      return {
        ...state,
        editLocalPurchaseOrdersSuccess: false,
      };
    case localPurchaseOrdersConstants.EDIT_LPO_ITEMS_DATA:
      return {
        ...state,
        editLpoItemsData: payload,
      };
    case localPurchaseOrdersConstants.EDIT_LPO_ITEMS_REQUEST:
      return {
        ...state,
        editLpoItemsLoading: true,
      };
    case localPurchaseOrdersConstants.EDIT_LPO_ITEMS_SUCCESS:
      return {
        ...state,
        editLpoItemsSuccess: true,
        editLpoItemsLoading: false,
        editLpoItemsMessage: payload,
        editLpoItemsError: "",
      };
    case localPurchaseOrdersConstants.EDIT_LPO_ITEMS_ERROR:
      return {
        ...state,
        editLpoItemsSuccess: false,
        editLpoItemsLoading: false,
        editLpoItemsError: payload,
      };
    case localPurchaseOrdersConstants.EDIT_LPO_ITEMS_COMPLETE:
      return {
        ...state,
        editLpoItemsSuccess: false,
      };
    case localPurchaseOrdersConstants.DELETE_LOCAL_PURCHASE_ORDERS_REQUEST:
      return {
        ...state,
        deleteLocalPurchaseOrdersLoading: true,
      };
    case localPurchaseOrdersConstants.DELETE_LOCAL_PURCHASE_ORDERS_SUCCESS:
      return {
        ...state,
        deleteLocalPurchaseOrdersLoading: false,
        deleteLocalPurchaseOrdersSuccess: true,
        deleteLocalPurchaseOrdersMessage: payload.message,
        localPurchaseOrders: payload.data,
      };
    case localPurchaseOrdersConstants.DELETE_LOCAL_PURCHASE_ORDERS_ERROR:
      return {
        ...state,
        deleteLocalPurchaseOrdersLoading: false,
        deleteLocalPurchaseOrdersSuccess: false,
        deleteLocalPurchaseOrdersError: payload,
      };
    case localPurchaseOrdersConstants.DELETE_LOCAL_PURCHASE_ORDERS_COMPLETE:
      return {
        ...state,
        deleteLocalPurchaseOrdersSuccess: false,
        deleteLocalPurchaseOrdersError: "",
      };
    case localPurchaseOrdersConstants.DELETE_LPO_ITEMS_REQUEST:
      return {
        ...state,
        deleteLpoItemsLoading: true,
      };
    case localPurchaseOrdersConstants.DELETE_LPO_ITEMS_SUCCESS:
      return {
        ...state,
        deleteLpoItemsLoading: false,
        deleteLpoItemsSuccess: true,
        deleteLpoItemsMessage: payload,
        deleteLpoItemsError: "",
      };
    case localPurchaseOrdersConstants.DELETE_LPO_ITEMS_ERROR:
      return {
        ...state,
        deleteLpoItemsLoading: false,
        deleteLpoItemsSuccess: false,
        deleteLpoItemsError: payload,
      };
    case localPurchaseOrdersConstants.DELETE_LPO_ITEMS_COMPLETE:
      return {
        ...state,
        deleteLpoItemsSuccess: false,
        deleteLpoItemsError: "",
      };
    case localPurchaseOrdersConstants.SUBMIT_LOCAL_PURCHASE_ORDERS_REQUEST:
      return {
        ...state,
        submitLocalPurchaseOrdersLoading: true,
      };
    case localPurchaseOrdersConstants.SUBMIT_LOCAL_PURCHASE_ORDERS_SUCCESS:
      return {
        ...state,
        submitLocalPurchaseOrdersLoading: false,
        submitLocalPurchaseOrdersSuccess: true,
        submitLocalPurchaseOrdersMessage: payload,
      };
    case localPurchaseOrdersConstants.SUBMIT_LOCAL_PURCHASE_ORDERS_ERROR:
      return {
        ...state,
        submitLocalPurchaseOrdersError: payload,
        submitLocalPurchaseOrdersLoading: false,
        submitLocalPurchaseOrdersSuccess: false,
      };
    case localPurchaseOrdersConstants.SUBMIT_LOCAL_PURCHASE_ORDERS_COMPLETE:
      return {
        ...state,
        submitLocalPurchaseOrdersError: "",
        submitLocalPurchaseOrdersSuccess: false,
      };
    case localPurchaseOrdersConstants.CHECK_LOCAL_PURCHASE_ORDERS_REQUEST:
      return {
        ...state,
        checkLocalPurchaseOrdersLoading: true,
      };
    case localPurchaseOrdersConstants.CHECK_LOCAL_PURCHASE_ORDERS_SUCCESS:
      return {
        ...state,
        checkLocalPurchaseOrdersLoading: false,
        checkLocalPurchaseOrdersSuccess: true,
        checkLocalPurchaseOrdersMessage: payload,
      };
    case localPurchaseOrdersConstants.CHECK_LOCAL_PURCHASE_ORDERS_ERROR:
      return {
        ...state,
        checkLocalPurchaseOrdersError: payload,
        checkLocalPurchaseOrdersLoading: false,
        checkLocalPurchaseOrdersSuccess: false,
      };
    case localPurchaseOrdersConstants.CHECK_LOCAL_PURCHASE_ORDERS_COMPLETE:
      return {
        ...state,
        checkLocalPurchaseOrdersError: "",
        checkLocalPurchaseOrdersSuccess: false,
      };
    case localPurchaseOrdersConstants.APPROVE_LOCAL_PURCHASE_ORDERS_REQUEST:
      return {
        ...state,
        approveLocalPurchaseOrdersLoading: true,
      };
    case localPurchaseOrdersConstants.APPROVE_LOCAL_PURCHASE_ORDERS_SUCCESS:
      return {
        ...state,
        approveLocalPurchaseOrdersSuccess: true,
        approveLocalPurchaseOrdersLoading: false,
        approveLocalPurchaseOrdersMessage: payload,
      };
    case localPurchaseOrdersConstants.APPROVE_LOCAL_PURCHASE_ORDERS_ERROR:
      return {
        ...state,
        approveLocalPurchaseOrdersError: payload,
        approveLocalPurchaseOrdersLoading: false,
        approveLocalPurchaseOrdersSuccess: false,
      };
    case localPurchaseOrdersConstants.APPROVE_LOCAL_PURCHASE_ORDERS_COMPLETE:
      return {
        ...state,
        approveLocalPurchaseOrdersSuccess: false,
        approveLocalPurchaseOrdersError: "",
      };
    case localPurchaseOrdersConstants.SET_LOCAL_PURCHASE_ORDERS_META_DATA_REQUEST:
      return {
        ...state,
        localPurchaseOrdersMetaDataLoading: true,
      };
    case localPurchaseOrdersConstants.SET_LOCAL_PURCHASE_ORDERS_META_DATA:
      return {
        ...state,
        localPurchaseOrdersMetaDataLoading: false,
        localPurchaseOrdersMetaData: payload,
      };
    default:
      return state;
  }
};

export default localPurchaseOrdersState;
